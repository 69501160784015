import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FaMapMarkerAlt, FaClock } from "react-icons/fa";
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";
import small from "../../assets/images/books-box.png";
import medium from "../../assets/images/shoes-box.png";
import large from "../../assets/images/moving-box.png";
import { userInfoUserStore } from "../../store/useUserInfoStore";
import { useTokenStoreStorage } from "../../store/useTokenStore";
import { BASE_URL } from "../../api/config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GiPathDistance } from "react-icons/gi";
import "./TripStepSummary.css";

import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import { ArrowLeft, ChevronLeft, Loader2 } from "lucide-react";
import { LuPackage2 } from "react-icons/lu";

interface Coordinates {
  lat: number | null;
  lng: number | null;
}

interface TripStepSummaryProps {
  fromInputValue: string;
  finalDestination: string;
  stops: string[];
  distanceMatrixResults: any[];
  selectedSizesCount: { [key: string]: number };
  selectedTime: string | null;
  originCoordinates: Coordinates | null; // Add this
  destinationCoordinates: Coordinates | null; // Add this
  showSummary: boolean;
  setShowSummary: Dispatch<SetStateAction<boolean>>;
}

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 40.7128, // Default center
  lng: -74.006,
};

export const TripStepSummary: React.FC<TripStepSummaryProps> = ({
  fromInputValue,
  finalDestination,
  stops,
  distanceMatrixResults,
  selectedSizesCount,
  selectedTime,
  setShowSummary,
  showSummary,
  originCoordinates,
  destinationCoordinates,
}) => {
  const navigate = useNavigate();
  const [directionsResponse, setDirectionsResponse] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const dlat = localStorage.getItem("dlat");
  const dlng = localStorage.getItem("dlng");
  const olat = localStorage.getItem("olat");
  const olng = localStorage.getItem("olng");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  // Center map based on originCoordinates or a default location
  const mapCenter = originCoordinates
    ? { lat: originCoordinates.lat, lng: originCoordinates.lng }
    : center;

  useEffect(() => {
    const loadGoogleMaps = () => {
      if (typeof window.google !== "undefined") {
        setIsLoaded(true);
        return;
      }

      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_GOOGLE_MAPS_API_KEY&libraries=places`;
      script.onload = () => setIsLoaded(true);
      document.body.appendChild(script);
    };
    loadGoogleMaps();
  }, []);

  const allStops = [fromInputValue, ...stops, finalDestination];

  const calculateRoute = useCallback(async () => {
    if (!fromInputValue || !finalDestination) return;

    const directionsService = new window.google.maps.DirectionsService();

    const waypoints = stops.map((stop) => ({
      location: stop,
      stopover: true,
    }));

    const result = await directionsService.route({
      origin: fromInputValue,
      destination: finalDestination,
      waypoints,
      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    setDirectionsResponse(result);
  }, [fromInputValue, finalDestination, stops]);

  useEffect(() => {
    if (isLoaded) {
      calculateRoute();
    }
  }, [isLoaded, calculateRoute]);

  const handleSubmit = () => {
    if (!originCoordinates || !destinationCoordinates) {
      console.error("Las coordenadas no están definidas");
      toast.error("Error: Las coordenadas no están definidas"); // Error toast
      return;
    }
    setLoadingSubmit(true);

    const durationInSeconds = distanceMatrixResults[0]?.duration?.value; // Duración en segundos
    const hours = Math.floor(durationInSeconds / 3600); // Calcular horas
    const minutes = Math.floor((durationInSeconds % 3600) / 60); // Calcular minutos

    const formattedDuration = `${hours}h ${minutes}m`; // Formatear la duración

    const url = `${BASE_URL}route/create?user_id=${user_id}&price_per_size=['small' => '10', 'medium' => '20', 'large' => '30']&price=10&origin_address=${fromInputValue}&origin_city=${fromInputValue}&package_weight=12&delivery_distance=${
      distanceMatrixResults[0]?.distance?.value / 1000
    }&delivery_duration=${formattedDuration}&destination_address=${finalDestination}&destination_city=${finalDestination}&origin_zipcode=23456&destination_zipcode=12345&start_date=2024-04-20 15:00:00&end_date=2024-04-20 20:00:00&origin_lat=${olat}&origin_lng=${olng}&destination_lat=${dlat}&destination_lng=${dlng}&package_size=small`;

    fetch(url, {
      method: "POST",
      headers: {
        AccessToken: token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        toast.success("Trip Created Successfully!"); // Success toast
        navigate("/carrier/trips"); // Redirect to /carrier/trips
      })
      .catch((error) => {
        console.error(error);
        toast.error("Error: Could not create trip."); // Error toast
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  if (!isLoaded) return <div>Loading...</div>;

  const packageImages: { [key: string]: string } = {
    small: small,
    medium: medium,
    large: large,
  };

  return (
    <>
      <div
        onClick={() => setShowSummary(!showSummary)}
        className="flex items-center space-x-2 cursor-pointer mb-2 text-blue-500 hover:underline"
      >
        <ChevronLeft className="h-4 w-4" />
        <p className="text-sm">Back</p>
      </div>
      <div className="p-4 bg-white rounded-md shadow-md">
        <h2 className="font-bold mb-4">Trip Summary</h2>
        <div className="flex gap-4">
          <div className="w-[60%] h-[400px]">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={10}
            >
              {directionsResponse && (
                <DirectionsRenderer directions={directionsResponse} />
              )}
            </GoogleMap>
          </div>
          <div className="w-[40%] h-[400px]">
            <ol className="  rounded-t rounded-b h-[360px] overflow-y-auto flex flex-col justify-between">
              <div className="h-[360px] w-2 bg-blue-600 absolute left-2 rounded-t-lg rounded-b-lg" />
              {allStops
                .filter((stop) => stop !== "")
                .map((stop, index) => {
                  return (
                    <div
                      key={index}
                      className="mb-6  relative z-20 animate-fadeIn"
                      style={{ animationDelay: `${index * 0.2}s` }}
                    >
                      <div className="flex items-center ">
                        {index - 1 === stops.length || index === 0 ? (
                          <>
                            <div className="bg-white h-6 w-6 flex items-center justify-center rounded-lg border shadow transition-all animate-bounceIn">
                              <FaMapMarkerAlt className="text-blue-600 " />
                            </div>
                            <span className="font-medium text-lg ml-2 transition-all">
                              {stop}
                            </span>
                          </>
                        ) : (
                          <>
                            <div className="bg-white h-5 w-5 flex items-center justify-center rounded-lg  border shadow transition-all animate-bounceIn">
                              <LuPackage2 className="fill-blue-600  text-white" />
                            </div>
                            <span className="font-medium text-lg ml-2 transition-all">
                              {stop}
                            </span>
                          </>
                        )}
                      </div>
                      {index < distanceMatrixResults.length && (
                        <div className="ml-6  text-gray-600">
                          <GiPathDistance className="inline-block mr-1 h-5 w-5" />
                          <span>
                            Distance:{" "}
                            {distanceMatrixResults[index].distance?.text}
                          </span>
                          <br />
                          <FaClock className="inline-block mr-1 h-5 w-5" />
                          <span>
                            Duration:{" "}
                            {distanceMatrixResults[index].duration?.text}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
            </ol>
          </div>
        </div>

        <div className="mt-4">
          <h3 className="font-bold">Package Sizes:</h3>
          <div className="flex flex-wrap mt-2">
            {Object.entries(selectedSizesCount).map(([size, count]) => (
              <div key={size} className="flex items-center mr-4 mb-2">
                <>
                  {size.charAt(0).toUpperCase() + size.slice(1)}:{" "}
                  <span className="text-gray-500 ml-2">{count}x</span>
                </>
                <img
                  src={packageImages[size as keyof typeof packageImages]}
                  alt={size}
                  className="w-10 h-10 mr-2"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-end justify-end">
          <button
            onClick={handleSubmit}
            className="bg-green-600 text-white rounded-md p-2 mt-4 flex items-center justify-center  px-6 w-40"
          >
            {loadingSubmit ? (
              <>
                <Loader2 className="h-5 w-5 animate-spin" />
              </>
            ) : (
              <p>Submit Trip</p>
            )}
          </button>
        </div>
      </div>
    </>
  );
};
