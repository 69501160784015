import React, { useState } from "react";
import { FaPlay } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";
import "../../styles/videopromo.css";
//@ts-ignore
import phone from "../../assets/images/iPhone_Mockup_2.png";
//@ts-ignore
import phone2 from "../../assets/images/iPhone_Mockup_1.png";
//@ts-ignore
import bgmap from "../../assets/images/bgmap.png";

export const VideoPromo = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [modal, setModal] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const openModal = () => {
    setModal(!modal);
  };

  const closeModal = () => {
    setModal(false);
  };

  const spinner = () => {
    setVideoLoading(false);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bgmap})`,
        backgroundSize: "cover",
      }}
      className="bg-white sm:h-[400px] "
    >
      {modal && (
        <section className="modal__bg">
          <div className="modal__align">
            <div className="modal__content">
              <IoCloseOutline
                className="modal__close"
                aria-label="Close modal"
                onClick={closeModal}
              />
              <div className="modal__video-align">
                {videoLoading && (
                  <div className="modal__spinner">
                    <BiLoaderAlt className="modal__spinner-style" />
                  </div>
                )}
                <iframe
                  className="modal__video-style"
                  onLoad={spinner}
                  width="800"
                  height="500"
                  src="https://www.youtube.com/embed/smrGz38JR1Q?si=1ip4B3DfZkdIy2mw"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      )}
      {/*       <div className="flex justify-center mb-20 p-4">
        <h2 className="text-white font-bold text-4xl">WHAT IS RUNNME</h2>
      </div> */}
      <div className="flex justify-center">
        <div className="h-auto pt-10 overflow-none sm:grid grid-cols-2">
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="relative sm:block hidden pt-10"
          >
            <img
              src={phone}
              alt="Phone Mockup"
              className={`absolute transition-opacity duration-500 ${
                isHovered ? "opacity-0" : "opacity-100"
              }`}
            />
            <img
              src={phone2}
              alt="Phone Mockup"
              className={` absolute transition-opacity duration-500 ${
                isHovered ? "opacity-100" : "opacity-0"
              }`}
            />
          </div>
          <div className="pb-12 px-4">
            <h2 className="text-freightBlue font-bold text-3xl">
              WATCH OUR PROMO VIDEO
            </h2>
            <div className="flex gap-4 mb-4">
              <button
                onClick={openModal}
                className="h-[70px] w-[100px] mt-4 flex justify-center items-center bg-[#ff380f] shadow-lg hover:bg-[#f4775efb] hover:shadow-4xl text-white font-bold rounded-2xl transition duration-300 ease-in-out"
              >
                <FaPlay size={30} />
              </button>
            </div>
            <div className=" rounded-3xl bg-freightBlue text-white max-w-[500px] p-4">
              <p className=" max-w-[500px]">
                Discover the latest features and updates of the Runnme app!
                Watch our promo video to see how Runnme can make you save time
                and money with an excelent customer experience, track your
                orders, and much moer. Don't miss out on the exciting new
                functionalities we've added just for you!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
