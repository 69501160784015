import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { Check, Package, PackageCheck, Truck } from "lucide-react";
import { Button } from "../ui/button";
import { BASE_URL, MARK_INSTANT_IN_TRANSIT } from "src/api/config";
import toast from "react-hot-toast";
import { useInstantFreightInTransit } from "src/hooks/use-instant-freight-in-transit";

interface ModalInstantFreightInTransitProps {
  refetch: () => void;
}

const ModalInstantFreightInTransit: React.FC<
  ModalInstantFreightInTransitProps
> = ({ refetch }) => {
  const { user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const modal = useInstantFreightInTransit();

  const setFreightInTransit = async () => {
    const response = await fetch(`${BASE_URL}${MARK_INSTANT_IN_TRANSIT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        instant_freight_id: modal.freightId,
        user_id: user_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Instant freight updated successfully") {
          refetch();
          modal.onClose();
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  };
  return (
    <Dialog open={modal.isOpen} onOpenChange={modal.onClose}>
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-center">
            <div className="h-20 w-20 border border-border rounded-full flex items-center justify-center">
              <Package className="h-10 w-10 text-statusInTransit" />
            </div>
            <div className="mt-4 flex flex-col items-center pb-10">
              <h1 className="text-xl font-medium">Are you sure? </h1>
              <p className="text-lg">You want to set this freight to transit</p>
            </div>
          </div>
        </DialogHeader>
        <div className="w-full justify-end items-end flex space-x-2">
          <Button onClick={() => modal.onClose()} variant={"outline"}>
            Cancel
          </Button>
          <Button onClick={() => setFreightInTransit()} variant={"freightBlue"}>
            Ok
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalInstantFreightInTransit;
