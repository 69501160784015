import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import * as React from "react";
//@ts-ignore

import TabContext from "@mui/lab/TabContext";
//@ts-ignore

import TabList from "@mui/lab/TabList";
//@ts-ignore

import TabPanel from "@mui/lab/TabPanel";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "../skeleton/Skeleton";
import { FourTab } from "./FourTab";
import { SecondTab } from "./SecondTab";
import { ThirdTab } from "./ThirdTab";

export default function Tabs() {
  const [value, setValue] = React.useState("1");
  const navigate = useNavigate();
  const [changingNewTab, setChangingNewTab] = React.useState(false);
  const [hideTabs, setHideTabs] = React.useState(false);

  React.useEffect(() => {
    setValue("2");
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setHideTabs(true);
    setTimeout(() => {
      setHideTabs(false);
    }, 1300);
    // setChangingNewTab(true);
  };

  return (
    <div>
      <TabContext value={value}>
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <TabList onChange={handleChange}>
            <Tab label="Send Now" value="2" />

            <Tab label=" Trips" value="3" />

            <Tab
              onClick={() => {
                navigate("/preregister");
              }}
              label="Join Us"
              value="4"
            />
          </TabList>
        </Box>
        {!hideTabs ? (
          <>
            <TabPanel value="1">{/* <FirstTab /> */}</TabPanel>
            <TabPanel value="2">
              <SecondTab />{" "}
            </TabPanel>
            <TabPanel value="3">
              <ThirdTab />
            </TabPanel>
            <TabPanel value="4">
              <FourTab />
            </TabPanel>
          </>
        ) : (
          <div className="bg-white bg-opacity-100 border rounded-xl shadow-xl mt-4 mr-6 lg:h-[400px] w-[400px] py-10 px-10 overflow-hidden sm:w-[800px] sm:flex sm:flex-col">
            <div className=" w-full">
              <div className="flex flex-col space-y-3">
                <Skeleton className="h-10 w-52" />
              </div>
              <div className="flex items-start justify-between w-full">
                <div className="flex flex-col">
                  <div className="flex flex-col space-y-4 mt-10">
                    <Skeleton className="h-8 w-72" />
                    <Skeleton className="h-6 w-72" />
                  </div>
                  <div className="flex flex-col space-y-4 mt-10">
                    <Skeleton className="h-8 w-72" />
                    <Skeleton className="h-6 w-72" />
                  </div>
                </div>
                <div className="flex flex-col space-y-4 mt-10">
                  <Skeleton className="h-8 w-72" />
                  <Skeleton className="h-6 w-72" />
                </div>
              </div>
            </div>
          </div>
        )}
      </TabContext>
    </div>
  );
}
