import { CheckCircle2Icon } from "lucide-react";
import Header from "../components/header/Header";
import { Button } from "../components/ui/button";
import { useNavigate } from "react-router-dom";
import Footer from "../components/layout/Footer";

const Register = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-gray-50 h-screen">
      <Header />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 max-w-7xl mx-auto mt-10">
        <div className="bg-white p-2 mx-2 pb-4 lg:mx-0 lg:pb-0 border shadow border-border">
          <div className="flex flex-col ">
            <h1 className="text-xl font-medium text-center text-freightOrange">
              Customer Signup
            </h1>
            <ul className="space-y-4 min-h-52 mt-10 px-10">
              <div className="flex items-center space-x-2">
                <div className="w-7">
                  <CheckCircle2Icon className="w-5 h-5" />
                </div>
                <h1 className="w-96">List your Freight for FREE!</h1>
              </div>
              <div className="flex items-center space-x-2 ">
                <div className="w-7">
                  <CheckCircle2Icon className="h-5 w-5" />
                </div>
                <h1 className="w-96">
                  Get great online quotes from transport companies. No phone
                  calls! and no obligations to accept a Freight
                </h1>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-7">
                  <CheckCircle2Icon className="h-5 w-5" />
                </div>
                <h1 className="w-96">
                  Compare reviews and ratings of transporters
                </h1>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-7">
                  <CheckCircle2Icon className="h-5 w-5" />
                </div>
                <h1 className="w-96">Book your chosen transporter online</h1>
              </div>
            </ul>
          </div>
          <div className="flex items-center space-x-2 justify-center">
            {/* <Button variant={"outline"}>Learn More</Button> */}
            <Button
              onClick={() => navigate("/register/customer")}
              variant={"freightBlue"}
            >
              Get Quotes Now
            </Button>
          </div>
        </div>
        <div className="bg-white p-2  mx-2 pb-4 lg:mx-0 border shadow border-border">
          <div className="flex flex-col ">
            <h1 className="text-xl text-center font-medium">
              Transporter Signup
            </h1>
            <ul className="space-y-4 min-h-52 mt-10 px-10">
              <li className="flex items-start space-x-2">
                <div className="w-7">
                  <CheckCircle2Icon className="w-5 h-5" />
                </div>
                <h1>No signup or subscription fees</h1>
              </li>
              <li className="flex items-start space-x-2">
                <div className="w-7">
                  <CheckCircle2Icon className="w-5 h-5" />
                </div>
                <h1>Keep your vehicles running full</h1>
              </li>
              <li className="flex items-start space-x-2">
                <div className="w-7">
                  <CheckCircle2Icon className="w-5 h-5" />
                </div>
                <h1>Build a great reputation on Runnme</h1>
              </li>
              <li className="flex items-start space-x-2">
                <div className="w-7">
                  <CheckCircle2Icon className="w-5 h-5" />
                </div>
                <h1 className="w-96">
                  Keep a track all of your quotes and customer communication
                  with our great online tools.
                </h1>
              </li>
            </ul>

            <div className="flex items-center space-x-2 justify-center">
              <Button
                onClick={() => navigate("/register/carrier")}
                variant={"freightBlue"}
              >
                Become a transporter
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Register;
