import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { Info, Loader2 } from "lucide-react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import {
  BASE_URL,
  GETCATEGORY_SUBCATEGORY,
  GETYEARS,
  UPDATE_CARRIER_DOCS,
  UPDATE_CARRIER_PROFILE,
} from "../api/config";
import SidebarLayout from "../components/layout/SidebarLayout";
import { Button } from "../components/ui/button";
import { Checkbox } from "../components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form";
import { Input } from "../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { Switch } from "../components/ui/switch";
import { Textarea } from "../components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";
import { useAvailablePackages } from "../lib/getAvailablePackages";
import { cn } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { CategorySub } from "../types/types";

const formSchema = z.object({
  images: z.any().optional(),
  numVehicles: z.string().min(1).optional(),
  numDrivers: z.string().min(1).optional(),
  yearEstablished: z.string().min(1).optional(),
  numEmployees: z.string().min(1).optional(),
  companyName: z.string().min(1).optional(),
  companyDescription: z.string().min(1).optional(),
  accreditations: z.string().min(1).optional(),
  insuranceDetails: z.string().min(1).optional(),
  termsConditions: z.string().min(1).optional(),
  packageSizeAvailable: z.string().min(1).optional().or(z.literal("")),
  vehicleType: z.string().min(1).optional(),
  vehicleRegNo: z.string().min(1).optional(),
  availableNow: z.boolean(),
  instantServer: z.boolean(),
  deliverPackages: z.boolean().optional(),
  deliverPets: z.boolean().optional(),
});

const docFormSchema = z.object({
  doc1: z.any(),
  doc2: z.any(),
});

const numberOptions = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11-20",
  "21-30",
  "31-40",
  "41-50",
  "51-100",
  "100+",
];

const paymentMethods = [
  {
    id: 0,
    label: "Credit Card",
  },
  {
    id: 1,
    label: "Etfpos",
  },
  {
    id: 2,
    label: "Cheque",
  },
  {
    id: 3,
    label: "Cash",
  },
  {
    id: 4,
    label: "Electronic Transfer",
  },
  {
    id: 4,
    label: "Paypal",
  },
];

const paymentType = [
  {
    id: 0,
    label: "Payment before pickup",
  },
  {
    id: 1,
    label: "Payment on pickup",
  },
  {
    id: 2,
    label: "Payment on delivery",
  },
  {
    id: 3,
    label: "Part payment ( In addition to customer online deposit )",
  },
];
const UpdateCarrierProfile = () => {
  const { email, name, user_id, user_type, mobile } = userInfoUserStore(
    (state) => state
  );
  const navigate = useNavigate();
  const token = useTokenStoreStorage((state) => state.token);

  const availablePackages = useAvailablePackages();
  const [availablePackage, setAvailablePackage] = useState<
    { item: string; checked: boolean }[]
  >([]);
  const [vehicle, setVehicle] = useState("");

  const [selectionPackageType, setSelectionPackageType] = useState<any>({
    packages: [],
  });

  const [showDocuments, setShowDocuments] = useState(false);
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [doc1, setDoc1] = useState<any>();
  const [doc2, setDoc2] = useState<any>();

  const [loading, setLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      instantServer: false,
      numDrivers: "",
      numVehicles: "",
      numEmployees: "",
      yearEstablished: "",

      companyName: "",
      companyDescription: "",
      accreditations: "",
      insuranceDetails: "",
      termsConditions: "",
      packageSizeAvailable: "",
      vehicleType: "",
      vehicleRegNo: "",
      availableNow: false,
      deliverPackages: false,
      deliverPets: false,
    },
  });

  const docForm = useForm<z.infer<typeof docFormSchema>>({
    resolver: zodResolver(docFormSchema),
    defaultValues: {
      doc1: "",
      doc2: "",
    },
  });

  const getYears = async () => {
    const response = await fetch(`${BASE_URL}${GETYEARS}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
    }).then((res) => res.json());
    return response;
  };

  const { data: years } = useQuery({
    queryKey: ["years"],
    queryFn: getYears,
  });

  const getCategories = async () => {
    const response = await fetch(`${BASE_URL}${GETCATEGORY_SUBCATEGORY}`, {
      method: "GET",
      headers: {
        AccessToken: token,
      },
    }).then((res) => res.json());

    return response;
  };

  const { data: categories } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategories,
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setLoading(true);
    updateUserCarrierAccount(
      values.instantServer,
      values.availableNow,
      values.companyName,
      values.companyDescription,
      values.vehicleType,
      values.vehicleRegNo,
      values.deliverPackages,
      values.deliverPets
    );
  };

  const docsformData = new FormData();
  docsformData.append("user_id", user_id);

  const onSubmitDocs = async (values: z.infer<typeof docFormSchema>) => {
    docsformData.append("doc1", values.doc1);
    docsformData.append("doc2", values.doc2);
    const response = await fetch(`${BASE_URL}${UPDATE_CARRIER_DOCS}`, {
      method: "POST",
      headers: {
        AccessToken: token,
      },
      body: docsformData,
    }).then((res) => res.json());

    return response;
  };

  const formData = new FormData();
  formData.append("user_id", user_id);
  formData.append("firstname", name);
  formData.append("email", email);
  formData.append("phoneNumber", mobile);

  const updateUserCarrierAccount = async (
    instantServer: boolean,
    availableNow: boolean,
    companyName?: string,
    companyDescription?: string,

    vehicleType?: string,
    vehicleRegNo?: string,
    deliverPackages?: boolean,
    deliverPets?: boolean
  ) => {
    formData.append("company_name", companyName!);
    formData.append("company_description", companyDescription!);
    formData.append("is_instant_server", instantServer ? "1" : "0");
    formData.append("is_available_now", availableNow ? "1" : "0");
    // formData.append(
    //   "package_size_available",
    //   selectionPackageType?.packages?.map((item: any) => item)
    // );
    formData.append("vehicleType", vehicleType!);
    formData.append("vehicleRegNo", vehicleRegNo!);
    formData.append(
      "availableToDeliverPackages",
      deliverPackages === true ? "1" : "0"
    );
    formData.append("availableToDeliverPets", deliverPets === true ? "1" : "0");

    const response = await fetch(`${BASE_URL}${UPDATE_CARRIER_PROFILE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        firstname: name,
        email: email,
        phoneNumber: mobile,
        company_name: companyName,
        company_description: companyDescription,
        is_instant_server: instantServer ? "1" : "0",
        is_available_now: availableNow ? "1" : "0",
        vehicleType: vehicleType,
        vehicleRegNo: vehicleRegNo,
        availableToDeliverPackages: deliverPackages === true ? "1" : "0",
        availableToDeliverPets: deliverPets === true ? "1" : "0",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Account updated successfully.") {
          toast.success(data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

    return response;
  };

  const handleCheckboxChange = (event: any) => {
    let newArray = [...selectionPackageType.packages, event.target.id];
    if (selectionPackageType.packages.includes(event.target.id)) {
      newArray = newArray.filter((type) => type !== event.target.id);
    }
    setSelectionPackageType({
      packages: newArray,
    });
  };

  return (
    <SidebarLayout>
      <div>
        <div className="flex items-center justify-between w-full border-b border-border pb-3 mb-4">
          <h1 className="text-lg font-semibold ">Update Profile</h1>
        </div>
        <div className="flex items-start space-x-2 mb-4">
          <div
            onClick={() => {
              setShowDocuments(false);
              setShowAccountInfo(true);
              setShowProfileInfo(false);
            }}
            className={cn(
              "bg-[#e2e2e2] px-4 py-2 rounded cursor-pointer",
              !showDocuments && "bg-[#333] text-white"
            )}
          >
            <p className="text-sm font-medium">Account information</p>
          </div>
          <div
            onClick={() => {
              setShowDocuments(true);
              setShowAccountInfo(false);
              setShowProfileInfo(false);
            }}
            className={cn(
              "bg-[#e2e2e2] px-4 py-2 rounded cursor-pointer",
              showDocuments && "bg-[#333] text-white"
            )}
          >
            <p className="text-sm font-medium">Documents</p>
          </div>
          <div className=""></div>
        </div>
        {showDocuments && (
          <div className="bg-white shadow p-4">
            <div>
              <div className="flex flex-col items-start">
                <h1 className="text-lg font-semibold mb-10">
                  Update documents
                </h1>
                <Form {...docForm}>
                  <form
                    className="w-full flex flex-col"
                    onSubmit={docForm.handleSubmit(onSubmitDocs)}
                  >
                    <div>
                      <h1 className="text-sm font-medium mb-4">Document 1</h1>
                      <Controller
                        control={docForm.control}
                        name="doc1"
                        render={({ field }) => (
                          <Input
                            accept="image/png, image/jpeg, image/gif*"
                            {...field}
                            value={field.value?.fileName}
                            type="file"
                            onChange={(event: any) => {
                              field.onChange(event.target.files?.[0]);
                              setDoc1(
                                URL.createObjectURL(event.target.files?.[0])
                              );
                            }}
                          />
                        )}
                      />
                      <div className="flex items-start space-x-2 mt-4">
                        {doc1 && (
                          <div className="flex flex-col items-start">
                            <h1 className="text-sm font-medium">
                              New Document 1
                            </h1>
                            <img
                              src={doc1}
                              className=" w-32 object-contain rounded mt-2"
                              alt="doc1"
                            />
                          </div>
                        )}
                        <div className="flex flex-col items-start">
                          <h1 className="text-sm font-medium">
                            Original document 1
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <h1 className="text-sm font-medium mb-4">Document 2</h1>
                      <Controller
                        control={docForm.control}
                        name="doc2"
                        render={({ field }) => (
                          <Input
                            accept="image/png, image/jpeg, image/gif*"
                            {...field}
                            value={field.value?.fileName}
                            type="file"
                            onChange={(event: any) => {
                              field.onChange(event.target.files?.[0]);
                              setDoc2(
                                URL.createObjectURL(event.target.files?.[0])
                              );
                            }}
                          />
                        )}
                      />
                      <div className="flex items-start space-x-2 mt-4">
                        {doc2 && (
                          <div className="flex flex-col items-start">
                            <h1 className="text-sm font-medium">
                              New Document 2
                            </h1>
                            <img
                              src={doc2}
                              className=" w-32 object-contain rounded mt-2"
                              alt="doc2"
                            />
                          </div>
                        )}
                        <div className="flex flex-col items-start">
                          <h1 className="text-sm font-medium ">
                            Original document 2
                          </h1>
                        </div>
                      </div>
                    </div>
                    <Button
                      className="w-60 ml-auto mt-4"
                      variant={"freightBlue"}
                    >
                      Upload documents
                    </Button>
                  </form>
                </Form>
              </div>
            </div>
          </div>
        )}

        {!showDocuments && (
          <div className=" bg-white shadow p-4">
            <div className="">
              <div className="flex flex-col items-start">
                <Form {...form}>
                  <form
                    className="w-full flex flex-col "
                    onSubmit={form.handleSubmit(onSubmit)}
                  >
                    <Controller
                      control={form.control}
                      name="availableNow"
                      render={({ field }) => (
                        <div className="flex items-center justify-end mt-4 space-x-2">
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            color="#0022B6"
                          />
                          <h1 className="text-sm font-medium">Available now</h1>
                        </div>
                      )}
                    />

                    <Controller
                      control={form.control}
                      name="instantServer"
                      render={({ field }) => (
                        <div className="flex items-center justify-end mt-4 space-x-2">
                          <Switch
                            color="#0022B6"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                          <h1 className="text-sm font-medium">
                            Instant Service
                          </h1>
                        </div>
                      )}
                    />

                    <Controller
                      control={form.control}
                      name="deliverPackages"
                      render={({ field }) => (
                        <div className="flex items-center justify-end mt-4 space-x-2">
                          <Switch
                            color="#0022B6"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                          <h1>Deliver Packages</h1>
                        </div>
                      )}
                    />
                    <Controller
                      control={form.control}
                      name="deliverPets"
                      render={({ field }) => (
                        <div className="flex items-center justify-end mt-4 space-x-2">
                          <Switch
                            color="#0022B6"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                          <h1>Deliver Pets</h1>
                        </div>
                      )}
                    />

                    {form.getValues("instantServer") === true && (
                      <div className="flex items-center justify-start my-4 space-x-2">
                        <div className="flex flex-col space-y-2">
                          <h1 className="text-sm font-medium mb-2">
                            Package Type you can carry
                          </h1>
                          <div className="flex flex-col space-y-2">
                            {availablePackage?.map((item) => (
                              <div className="flex items-center space-x-2">
                                <input
                                  onChange={handleCheckboxChange}
                                  id={item.item}
                                  type="checkbox"
                                  value={item.item}
                                  checked={item.checked}
                                  color="#0022B6"
                                  className="h-5 w-5 rounded cursor-pointer"
                                />
                                <h1 className="capitalize text-sm font-medium text-[#333]">
                                  {item.item}
                                </h1>
                                <TooltipProvider delayDuration={0}>
                                  <Tooltip>
                                    <TooltipTrigger>
                                      <Info className="h-4 w-4 text-[#333]" />
                                    </TooltipTrigger>
                                    <TooltipContent>
                                      <div className="flex flex-col items-start">
                                        <p className="text-sm font-medium mb-2">
                                          Info
                                        </p>
                                        <div className="flex flex-col items-start bg-gray-50 border-border border w-full px-2 py-1">
                                          <h1 className="text-sm font-medium text-gray-500">
                                            Weight
                                          </h1>
                                          <p className="text-sm font-medium text-[#333]">
                                            {item.item === "small" && (
                                              <p>0-5kg</p>
                                            )}
                                            {item.item === "medium" && (
                                              <p>5-20kg</p>
                                            )}
                                            {item.item === "large" && (
                                              <p>20-30kg</p>
                                            )}
                                          </p>
                                        </div>
                                        <div className="flex flex-col items-start bg-gray-50 border-border border w-full px-2 py-1">
                                          <h1 className="text-sm font-medium text-gray-500">
                                            Dimensions
                                          </h1>
                                          <p className="text-sm font-medium text-[#333]">
                                            {item.item === "small" && (
                                              <p>aprox. 23 x 14 x 4cm</p>
                                            )}
                                            {item.item === "medium" && (
                                              <p>aprox. 35 x 20 x 15cm</p>
                                            )}
                                            {item.item === "large" && (
                                              <p>aprox. 75 x 35 x 35cm</p>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="flex items-center justify-start my-4 space-x-2">
                      <Controller
                        control={form.control}
                        name="vehicleType"
                        render={({ field, fieldState }) => (
                          <>
                            <Select
                              onValueChange={(val) => {
                                form.setValue("vehicleType", val);
                                setVehicle(val);
                              }}
                              {...field}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select vehicle type" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="car">Car</SelectItem>
                                <SelectItem value="motorbike">
                                  Motorbike
                                </SelectItem>
                                <SelectItem value="bike">Bike</SelectItem>
                              </SelectContent>
                            </Select>
                            {fieldState.error && (
                              <p className="text-sm font-medium text-red-500">
                                {fieldState.error.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                    {(vehicle === "car" || vehicle === "motorbike") && (
                      <div className="w-full mb-4 relative">
                        <FormField
                          control={form.control}
                          name="vehicleRegNo"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Vehicle Reg No</FormLabel>
                              <FormControl>
                                <Input {...field} name="vehicleRegNo" />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      </div>
                    )}

                    <FormField
                      control={form.control}
                      name="companyName"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-sm  pb-3 dark:text-black">
                            Company Name
                          </FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              name="companyName"
                              className="w-full dark:bg-transparent"
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <div className="mt-4">
                      <FormField
                        control={form.control}
                        name="companyDescription"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="text-sm pb-3 dark:text-black">
                              Company Description
                            </FormLabel>
                            <FormControl>
                              <Textarea
                                {...field}
                                name="companyDescription"
                                className="w-full dark:bg-transparent"
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>

                    <div className="flex items-center space-x-2 mt-4">
                      <h1 className="font-medium">Images</h1>
                      <Controller
                        name="images"
                        control={form.control}
                        render={({ field, fieldState }) => (
                          <div>
                            <Input
                              accept="image/png, image/jpeg, image/gif*"
                              {...field}
                              value={field.value?.fileName}
                              type="file"
                              onChange={(event) => {
                                field.onChange(event);
                              }}
                            />
                            {fieldState.error && (
                              <p className="text-red-500 text-sm">
                                {fieldState.error.message}
                              </p>
                            )}
                          </div>
                        )}
                      />
                    </div>
                    <div className=" sm:flex items-center space-x-2 mt-4">
                      <h1 className="font-medium w-60">Number of vehicles*</h1>
                      <Controller
                        control={form.control}
                        name="numVehicles"
                        render={({ field }) => (
                          <div>
                            <Select
                              onValueChange={(value) => {
                                form.setValue("numVehicles", value);
                              }}
                              {...field}
                            >
                              <SelectTrigger className="max-w-60">
                                <SelectValue placeholder="Select" />
                              </SelectTrigger>
                              <SelectContent className="h-60">
                                {numberOptions?.map((value) => (
                                  <SelectItem value={value}>{value}</SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </div>
                        )}
                      />
                      <Button
                        onClick={() => navigate("/carrier/manageFleet")}
                        variant={"outline"}
                      >
                        Manage
                      </Button>
                    </div>
                    <div className="sm:flex items-center space-x-2 mt-4">
                      <h1 className="font-medium w-60">Number of drivers*</h1>

                      <Controller
                        control={form.control}
                        name="numDrivers"
                        render={({ field }) => (
                          <Select
                            onValueChange={(value) => {
                              form.setValue("numDrivers", value);
                            }}
                            {...field}
                          >
                            <SelectTrigger className="max-w-60">
                              <SelectValue placeholder="Select" />
                            </SelectTrigger>
                            <SelectContent className="h-60">
                              {numberOptions?.map((value) => (
                                <SelectItem value={value}>{value}</SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      />
                      <Button
                        type="button"
                        onClick={() => navigate("/carrier/manageDriver")}
                        variant={"outline"}
                      >
                        Manage
                      </Button>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                      <h1 className="font-medium w-60">Year established*</h1>

                      <Controller
                        control={form.control}
                        name="yearEstablished"
                        render={({ field }) => (
                          <Select
                            onValueChange={(value) => {
                              form.setValue("yearEstablished", value);
                            }}
                            {...field}
                          >
                            <SelectTrigger className="w-60">
                              <SelectValue placeholder="Select" />
                            </SelectTrigger>
                            <SelectContent className="h-60">
                              {years?.data?.map((year: string) => (
                                <SelectItem value={year}>{year}</SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      />
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                      <h1 className="font-medium w-60">Number of Employees</h1>

                      <Controller
                        control={form.control}
                        name="numEmployees"
                        render={({ field }) => (
                          <Select
                            onValueChange={(value) => {
                              form.setValue("numEmployees", value);
                            }}
                            {...field}
                          >
                            <SelectTrigger className="w-60">
                              <SelectValue placeholder="Select" />
                            </SelectTrigger>
                            <SelectContent className="h-60">
                              {numberOptions.map((option) => (
                                <SelectItem value={option}>{option}</SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      />
                    </div>
                    <div className="sm:flex items-start space-x-2 mt-14 mb-4">
                      <h1 className="font-medium max-w-60">
                        Accepted Payment Methods:
                      </h1>
                      <div className="flex flex-col items-start space-y-2">
                        {paymentMethods?.map((payment) => (
                          <div className="flex items-center space-x-1">
                            <Checkbox />
                            <h1>{payment.label}</h1>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="sm:flex items-start space-x-2 my-4 mt-14">
                      <h1 className="font-medium w-60">Payment Terms</h1>
                      <div className="sm:grid grid-cols-2 space-y-2 gap-4">
                        {paymentType?.map((type) => (
                          <div
                            key={type.id}
                            className="flex items-center space-x-2"
                          >
                            <Checkbox />
                            <h1>{type.label}</h1>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="sm:flex items-start space-x-2 my-4 mt-14">
                      <h1 className="font-medium w-60">Serviced Categories</h1>

                      <div className="sm:grid space-y-2 grid-cols-2 gap-4">
                        {categories?.data?.map((category: CategorySub) => (
                          <div
                            className="flex items-start space-x-2"
                            key={category.catid}
                          >
                            <Checkbox />
                            <h1>{category.catname}</h1>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="sm:flex items-start space-x-2 my-4 mt-14">
                      <h1 className=" font-medium w-60">Serviced Locations</h1>
                    </div>
                    <FormField
                      control={form.control}
                      name="accreditations"
                      render={({ field }) => (
                        <FormItem className="mb-4">
                          <FormLabel className="text-sm  pb-3 dark:text-black">
                            Accreditations & Memberships
                          </FormLabel>
                          <FormControl>
                            <Textarea
                              {...field}
                              name="accreditations"
                              className="w-full dark:bg-transparent"
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="insuranceDetails"
                      render={({ field }) => (
                        <FormItem className="mb-4">
                          <FormLabel className="text-sm  pb-3 dark:text-black">
                            Insurance Details:
                          </FormLabel>
                          <FormControl>
                            <Textarea
                              {...field}
                              name="insuranceDetails"
                              className="w-full dark:bg-transparent"
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="termsConditions"
                      render={({ field }) => (
                        <FormItem className="mb-4">
                          <FormLabel className="text-sm  pb-3 dark:text-black">
                            Your Terms & Conditions:
                          </FormLabel>
                          <FormControl>
                            <Textarea
                              {...field}
                              name="termsConditions"
                              className="w-full dark:bg-transparent"
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <p className="text-xs text-gray-400 ">
                      The customer will be asked to accept your terms and
                      conditions if they wish to accept your quote, so please
                      make sure you have correctly entered your terms in the
                      field above.
                    </p>
                    <div className="mt-4">
                      <Button
                        disabled={loading}
                        className="w-60"
                        variant={"freightBlue"}
                      >
                        {loading ? (
                          <>
                            <Loader2 className="h-5 w-5 animate-spin" />
                          </>
                        ) : (
                          <p className="text-sm font-medium">Update Profile</p>
                        )}
                      </Button>
                    </div>
                  </form>
                </Form>
              </div>
            </div>
          </div>
        )}
      </div>
    </SidebarLayout>
  );
};

export default UpdateCarrierProfile;
