import { useQuery } from "@tanstack/react-query";
import { BASE_URL, FLEET_ALL } from "../api/config";
import Header from "../components/header/Header";
import CustomerSidebar from "../components/sidebar/CustomerSidebar";
import Sidebar from "../components/sidebar/Sidebar";
import { Button } from "../components/ui/button";
import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { useEffect } from "react";
import { useAddCarrierFleets } from "../hooks/use-add-carrier-fleets";
import ModalAddFleet from "../components/modal/ModalAddFleet";

const CarrierFleets = () => {
  const { user_type, user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const modalAddFleet = useAddCarrierFleets();
  const modalCarrierFleets = useAddCarrierFleets();

  const getAllFleets = async () => {
    const response = await fetch(`${BASE_URL}${FLEET_ALL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const { data: allFleets } = useQuery({
    queryKey: ["all_fleets"],
    queryFn: getAllFleets,
  });

  return (
    <div>
      <Header />
      <div className="grid grid-cols-7 mt-20 px-10">
        <div className="col-span-2">
          {user_type === "customer" ? <CustomerSidebar /> : <Sidebar />}
        </div>
        <div className="col-span-5">
          <div className="max-w-4xl mr-auto">
            <h1 className="text-lg font-semibold">Carrier Fleets</h1>
            <div className="flex items-center justify-end mb-4">
              <Button
                onClick={() => modalCarrierFleets.onOpen("addCarrierFleets")}
                variant={"freightBlue"}
              >
                Add Fleet
              </Button>
            </div>
            <Table className="border border-border">
              <TableHeader>
                <TableRow>
                  <TableHead>Image</TableHead>
                  <TableHead>Vehicle Register No. </TableHead>
                  <TableHead>Vehicle Type</TableHead>
                  <TableHead>Assign Driver</TableHead>
                  <TableHead>Action</TableHead>
                </TableRow>
              </TableHeader>
              {allFleets?.data?.length > 0 && (
                <h1 className="text-sm font-medium p-4">
                  {allFleets?.message}
                </h1>
              )}
              {/* {allFleets?.data.map((fleet)=>(

                  <TableBody>{}</TableBody>
              ))} */}
            </Table>
          </div>
        </div>
      </div>
      {modalAddFleet.isOpen && <ModalAddFleet />}
    </div>
  );
};

export default CarrierFleets;
