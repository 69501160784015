import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useQuery } from "@tanstack/react-query";
import {
  Ban,
  Check,
  ChevronLeft,
  ChevronRight,
  Grip,
  Loader2,
  RouteOff,
  Star,
} from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ALL_ROUTES,
  BASE_URL,
  GET_SINGLE_STOP,
  GET_STOP_REVIEW,
  GOOGLE_API_KEY,
} from "../api/config";
import Header from "../components/header/Header";
import ModalDeleteStop from "../components/modal/ModalDeleteStop";
import ModalSubmitStopTripReview from "../components/modal/ModalSubmitTripReview";
import ModalUpdateStatusStop from "../components/modal/ModalUpdateStatusCustomerStop";
import { Rating } from "../components/Rating";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import { useDeleteStop } from "../hooks/use-delete-stop";
import { useSendReviewStop } from "../hooks/use-send-review-stop";
import { useUpdateStatusStop } from "../hooks/use-update-status-stop";
import { cn } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { ReviewType, StopType } from "../types/types";

const containerStyle = {
  Maxwidth: "540px",
  height: "400px",
  borderRadius: "4px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const ViewStop = () => {
  const params = useParams();
  const [map, setMap] = useState(null);
  const navigate = useNavigate();
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const modalSendReviewStop = useSendReviewStop();
  const [directionResponse, setDirectionsResponse] = useState<any>(null);
  const [showOtp, setShowOtp] = useState(false);
  const modalDeleteStop = useDeleteStop();
  const modalUpdateStatusStop = useUpdateStatusStop();
  const [loadingRoute, setLoadingRoute] = useState(false);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });
  const [routeAddress, setRouteAddress] = useState<{
    originAddress: string;
    originZipcode: string;
    destinationAddress: string;
    destinationZipcode: string;
    transporterId: string;
  }>({
    originAddress: "",
    originZipcode: "",
    destinationAddress: "",
    destinationZipcode: "",
    transporterId: "",
  });

  const onLoad = useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const getStopReview = async () => {
    const response = await fetch(
      `${BASE_URL}${GET_STOP_REVIEW}?user_id=${user_id}&stop_id=${params.stopId}`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });
    return response;
  };

  const { data: stopReview, refetch: refetchReviews } = useQuery({
    queryKey: ["stop_review", params.stopId],
    queryFn: getStopReview,
  });

  const getStop = async () => {
    setLoadingRoute(true);

    const response = await fetch(
      `${BASE_URL}${GET_SINGLE_STOP}?user_id=${user_id}&stop_id=${params.stopId}`,
      {
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const {
    data: stop,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["stop", params.stopId],
    queryFn: getStop,
  });

  const getRoute = async (routeId: string) => {
    const response = await fetch(
      `${BASE_URL}${ALL_ROUTES}?route_id=${routeId}&sort=desc&page=1`,
      {
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setRouteAddress({
          originAddress: data.data.routes[0].origin_address,
          originZipcode: data.data.routes[0].origin_zipcode,
          destinationAddress: data.data.routes[0].destination_address,
          destinationZipcode: data.data.routes[0].destination_zipcode,
          transporterId: data.data.routes[0].transporter_id,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingRoute(false);
      });

    return response;
  };

  useEffect(() => {
    getRoute(stop?.data[0].transporter_route_id);
  }, [stop]);

  async function calculateRoute() {
    const directionsService = new google.maps.DirectionsService();

    //@ts-ignore
    const results = await directionsService.route({
      origin: {
        lat: Number(stop?.data[0]?.origin_lat),
        lng: Number(stop?.data[0]?.origin_lng),
      },
      destination: {
        lat: Number(stop?.data[0]?.destination_lat),
        lng: Number(stop?.data[0]?.destination_lng),
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionsResponse(results);
  }
  useEffect(() => {
    if (!isLoading) {
      calculateRoute();
    }
  }, [isLoading]);
  return (
    <div className="h-screen bg-gray-50">
      <Header />
      <div className="max-w-7xl mx-auto sm:p-16 h-[94vh] overflow-y-auto no-scrollbar">
        <div className="flex items-center justify-between">
          <div
            onClick={() => navigate("/customer/trips")}
            className="flex items-center space-x-2 group cursor-pointer mb-4"
          >
            <ChevronLeft className="text-freightBlueDark h-5 w-5" />
            <h1 className="text-freightBlueDark text-sm group-hover:underline ">
              Back
            </h1>
          </div>
          <div className="bg-white border border-border mb-4 rounded">
            <p className="capitalize text-sm font-medium text-[#333] px-4 py-2">
              {stop?.data[0].status}
            </p>
          </div>
        </div>

        {isLoading ? (
          <div className="bg-white shadow border border-border p-4 grid grid-cols-2 gap-4">
            <div>
              <div className="w-[540px] h-[400px] bg-gray-50 flex items-center justify-center">
                <Loader2 className="h-5 w-5 animate-spin" />
              </div>
              <div className="flex items-center justify-around mt-4">
                <Skeleton className=" h-20 w-20" />
                <Skeleton className=" h-20 w-20" />
                <Skeleton className=" h-20 w-20" />
              </div>
            </div>
            <div className="flex flex-col items-start">
              <div className="flex items-center justify-between w-full">
                <Skeleton className="h-10 w-10" />
                <Skeleton className="h-10 w-10" />
              </div>
            </div>
          </div>
        ) : (
          <div>
            {stop.data.map((route: StopType) => (
              <div className="bg-white shadow border-border border p-4 md:grid grid-cols-2 gap-4">
                <div>
                  {isLoaded ? (
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={{
                        lat: Number(route?.origin_lat),
                        lng: Number(route?.origin_lng),
                      }}
                      options={{
                        streetViewControl: false,
                      }}
                      zoom={15}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                    >
                      <Marker
                        position={{
                          lat: Number(route?.origin_lat),
                          lng: Number(route?.origin_lng),
                        }}
                      />
                      <Marker
                        position={{
                          lat: Number(route?.destination_lat),
                          lng: Number(route?.destination_lng),
                        }}
                      />
                      {directionResponse && (
                        <DirectionsRenderer directions={directionResponse} />
                      )}
                    </GoogleMap>
                  ) : null}
                  <div className="flex flex-col space-y-4">
                    <div className="flex items-center justify-between w-full mt-4 bg-gray-50 py-4 px-2 rounded border-border border">
                      <p className="text-sm font-medium">Distance</p>
                      <p className="text-sm font-medium text-gray-500 capitalize">
                        {route.delivery_distance}
                      </p>
                    </div>
                    <div className="flex items-center justify-between w-full bg-gray-50 py-4 px-2 rounded border-border border">
                      <p className="text-sm font-medium">Duration</p>
                      <p className="text-sm font-medium text-gray-500 capitalize ">
                        {route.delivery_duration}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start space-y-4">
                  <div className="flex items-center justify-end md:ml-auto">
                    {user_type === "carrier" && (
                      <Button
                        className="mr-3"
                        variant={"freightGreen"}
                        onClick={() =>
                          modalUpdateStatusStop.onOpen(
                            "updateStopStatus",
                            route.id.toString(),
                            route.status,
                            "",
                            ""
                          )
                        }
                      >
                        Update Status
                      </Button>
                    )}
                    {route.status !== "confirmed" &&
                      user_type === "carrier" && (
                        <Button
                          variant={"destructive"}
                          onClick={() =>
                            modalDeleteStop.onOpen(
                              "deleteStop",
                              route.id.toString()
                            )
                          }
                        >
                          <Ban className="text-white h-4 w-4 mr-2" />
                          <p className="text-sm font-medium text-white">
                            Delete Stop
                          </p>
                        </Button>
                      )}
                  </div>
                  <div className="flex items-center justify-between w-full bg-gray-50 border-border border py-2 px-4 rounded">
                    <p className="text-sm font-medium">Package</p>
                    <p className="text-sm font-medium text-gray-500 capitalize">
                      {route.package_size}
                    </p>
                  </div>
                  <div className="flex items-center justify-between w-full bg-gray-50 border-border border py-2 px-4 rounded">
                    <p className="text-sm font-medium">Weight</p>
                    <p className="text-sm font-medium text-gray-500 capitalize">
                      {route.package_weight}kg
                    </p>
                  </div>
                  <div className="flex items-start justify-between w-full bg-gray-50 border-border border py-2 px-4 rounded">
                    <p className="text-sm font-medium">Price</p>
                    <p className="text-sm font-medium text-gray-500">
                      {route.price.toFixed(2)}€
                    </p>
                  </div>
                  <div className="flex items-start justify-between w-full bg-gray-50 border-border border py-2 px-4 rounded">
                    <p className="text-sm font-medium">Origin</p>
                    <p className="text-sm font-medium text-gray-500">
                      {route.origin_address}
                    </p>
                  </div>
                  <div className="flex items-start justify-between w-full bg-gray-50 border-border border py-2 px-4 rounded">
                    <p className="text-sm font-medium">Destination</p>
                    <p className="text-sm font-medium text-gray-500">
                      {route.destination_address}
                    </p>
                  </div>
                  <div className="flex items-start space-x-4 justify-between">
                    <div className="flex flex-col items-start w-full  ">
                      <div className="flex flex-col items-center h-40 justify-between relative">
                        <div className="h-32 w-1 bg-gray-200 absolute bottom-0 z-0 top-3" />
                        <div className="h-7 z-10 flex items-center justify-center">
                          {(route.status === "booked" ||
                            route.status === "paid") && (
                            <Check className="text-white bg-green-500 h-6 w-6 rounded-full p-1 " />
                          )}
                          {route.status !== "paid" &&
                            route.status !== "created" &&
                            route.status !== "booked" && (
                              <div className="h-4 w-4 bg-green-500 rounded-full z-10" />
                            )}

                          {route.status === "created" && (
                            <div className="h-4 w-4 bg-gray-300 rounded-full z-10" />
                          )}
                        </div>
                        {/* transporting picking up the package */}
                        <div className="h-7 z-10 flex items-center justify-center">
                          {route.status === "picking-up" && (
                            <Check className="text-white bg-green-500 h-6 w-6 rounded-full p-1 " />
                          )}
                          {route.status !== "picking-up" &&
                            route.status !== "booked" &&
                            route.status !== "created" && (
                              <div className="h-4 w-4 bg-green-500 rounded-full z-10" />
                            )}
                          {(route.status === "created" ||
                            route.status === "booked") && (
                            <div className="h-4 w-4 bg-gray-300 rounded-full z-10" />
                          )}
                        </div>

                        {/* transporting the package */}
                        <div className="h-7 z-10 flex items-center justify-center">
                          {route.status === "in-transit" && (
                            <Check className="text-white bg-green-500 h-6 w-6 rounded-full p-1 " />
                          )}
                          {route.status !== "picking-up" &&
                            route.status !== "booked" &&
                            route.status !== "created" &&
                            route.status !== "paid" &&
                            route.status !== "in-transit" && (
                              <div className="h-4 w-4 bg-green-500 rounded-full z-10" />
                            )}
                          {(route.status === "created" ||
                            route.status === "picking-up" ||
                            route.status === "paid" ||
                            route.status === "booked") && (
                            <div className="h-4 w-4 bg-gray-300 rounded-full z-10" />
                          )}
                        </div>

                        {/* package has arrived */}
                        <div className="h-7 z-10 flex items-center justify-center">
                          {route.status === "confirmed" && (
                            <Check className="text-white bg-green-500 h-6 w-6 rounded-full p-1 " />
                          )}

                          {route.status !== "picking-up" &&
                            route.status !== "booked" &&
                            route.status !== "created" &&
                            route.status !== "paid" &&
                            route.status !== "confirmed" &&
                            route.status !== "in-transit" && (
                              <div className="h-4 w-4 bg-green-500 rounded-full z-10" />
                            )}

                          {(route.status === "created" ||
                            route.status === "booked" ||
                            route.status === "paid" ||
                            route.status === "picking-up" ||
                            route.status === "in-transit") && (
                            <>
                              <div className="h-4 w-4 bg-gray-300 rounded-full z-10"></div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-start space-y-4 h-40 justify-between ">
                      <div className="flex items-center space-x-2 h-7 ">
                        <p
                          className={cn(
                            "text-sm font-medium w-80",
                            route.status !== "created" &&
                              route.status !== "booked" &&
                              route.status !== "paid" &&
                              "text-gray-500"
                          )}
                        >
                          Order Accepted
                        </p>
                      </div>
                      <div className="flex items-center space-x-2 h-7">
                        <p
                          className={cn(
                            "text-sm font-medium w-80",
                            route.status !== "pick-up" && "text-gray-500"
                          )}
                        >
                          Transporter is coming to pick up the package
                        </p>
                      </div>
                      <div className="flex items-center space-x-2 h-7">
                        <p
                          className={cn(
                            "text-sm font-medium w-80",
                            route.status !== "in-transit" && "text-gray-500"
                          )}
                        >
                          Carrier is transporting the package
                        </p>
                      </div>
                      <div className="flex items-center space-x-2 h-7">
                        <p
                          className={cn(
                            "text-sm font-medium w-80",
                            route.status !== "confirmed" && "text-gray-500"
                          )}
                        >
                          Package has arrived to the destination
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col items-start w-full">
                    <p className="text-lg font-semibold border-b-2 mb-4 pb-2 w-full">
                      Routes
                    </p>
                    <div className="flex items-start justify-between">
                      {route.transporter_route_id === 0 ? (
                        <div className="flex items-center space-x-4 rounded bg-gray-50 py-4 px-2">
                          <RouteOff className="h-5 w-5 text-gray-500" />
                          <p className="text-sm font-medium text-gray-500">
                            No routes asigned
                          </p>
                        </div>
                      ) : (
                        <div>
                          <div className="">
                            <p className="text-sm font-medium">
                              Assigned to the route
                            </p>
                            <div className="flex items-center space-x-2 text-gray-500 mt-2">
                              <p className="text-sm font-medium">
                                {routeAddress.originAddress},{" "}
                                {routeAddress.originZipcode}
                              </p>
                              <ChevronRight className="h-4 w-4" />
                              <p className="text-sm font-medium">
                                {routeAddress.destinationAddress},{" "}
                                {routeAddress.destinationZipcode}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="ml-auto">
                    <div className="flex items-center space-x-2">
                      {route.status === "confirmed" &&
                        stopReview?.message ===
                          "Stop review data not found." && (
                          <Button
                            onClick={() =>
                              modalSendReviewStop.onOpen(
                                "sendReviewStop",
                                stop.data[0].id,
                                routeAddress.transporterId
                              )
                            }
                            variant={"outline"}
                          >
                            <Star className="h-4 w-4 mr-2" />
                            <p className="text-sm font-medium">Leave Review</p>
                          </Button>
                        )}
                      <Button
                        onClick={() => setShowOtp(!showOtp)}
                        variant={"outline"}
                        // className={
                        //   !showOtp
                        //     ? "w-40"
                        //     : "bg-[#e2e2e2] w-40 hover:bg-[#f8f8f8]"
                        // }
                      >
                        {/* {showOtp ? ( */}
                        <div className="flex items-center space-x-2">
                          <Grip className="h-5 w-5 text-[#333]" />
                          <p className="text-sm font-medium text-[#333]">
                            {route.otp_confirmation}
                          </p>
                        </div>
                        {/* // ) : (
                        //   <p className="text-sm font-medium">Show OTP</p>
                        // )} */}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {stopReview?.message !== "Stop review data not found." && (
              <div className="py-4">
                <h1 className="text-lg font-medium mb-4"> Reviews</h1>
                {stopReview?.data?.map((review: ReviewType) => (
                  <div className="flex items-end justify-between bg-white mb-4 p-2 py-4 rounded">
                    <div className="flex items-start space-x-4">
                      <div className="bg-gray-100 rounded-full h-10 w-10 flex items-center justify-center">
                        <img
                          src={require("../assets/images/user-icon-placeholder.jpg")}
                          className="h-8 w-8 rounded-full object-contain  z-10"
                          alt="user-icon-placeholder"
                        />
                      </div>
                      <div className="flex flex-col items-start">
                        <div className="pb-4">
                          <Rating
                            count={Number(review.rating)}
                            value={Number(review.rating)}
                            edit={false}
                          />
                        </div>
                        <p className="text-sm font-medium">{review.review}</p>
                      </div>
                    </div>

                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        {review.updated_at}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      {modalDeleteStop && <ModalDeleteStop refetch={refetch} />}
      {modalUpdateStatusStop && <ModalUpdateStatusStop refetch={refetch} />}
      {modalSendReviewStop && (
        <ModalSubmitStopTripReview
          refetch={refetch}
          refetchReviews={refetchReviews}
        />
      )}
    </div>
  );
};

export default ViewStop;
