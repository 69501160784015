import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { useDeleteRoute } from "src/hooks/use-delete-route";
import { AlertCircle } from "lucide-react";
import { Button } from "../ui/button";
import { useState } from "react";
import { BASE_URL, ROUTE_REMOVE } from "src/api/config";
import { useNavigate } from "react-router-dom";

const ModalDeleteRoute = () => {
  const { user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const [loadingDeletingRoute, setLoadingDeletingRoute] = useState(false);
  const navigate = useNavigate();

  const modalDeleteRoute = useDeleteRoute();

  const deleteRoute = async () => {
    setLoadingDeletingRoute(true);
    const response = await fetch(`${BASE_URL}${ROUTE_REMOVE}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        route_id: modalDeleteRoute.routeId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Route deleted successfully") {
          modalDeleteRoute.onClose();
          navigate("/carrier/trips");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingDeletingRoute(false);
      });

    return response;
  };

  return (
    <Dialog
      open={modalDeleteRoute.isOpen}
      onOpenChange={modalDeleteRoute.onClose}
    >
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-start  w-full space-y-10">
              <div className="flex flex-col items-center w-full">
                <AlertCircle className="text-red-500 h-20 w-20" />
                <h1 className="text-xl font-medium">Are you sure?</h1>
                <h3 className="text-sm ">You want to remove this route?</h3>
              </div>
              <div className="flex items-center justify-end space-x-2 w-full mt-4">
                <Button
                  variant={"outline"}
                  onClick={() => modalDeleteRoute.onClose()}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => deleteRoute()}
                  disabled={loadingDeletingRoute}
                >
                  Delete Route
                </Button>
              </div>
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalDeleteRoute;
