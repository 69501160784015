import { create } from "zustand";

export type ModalType = "sendMessage";

interface ModalStore {
  type: ModalType | null;

  data: {
    quoteId: number;
    customerId: number;
    carrierId: number;
    senderId: number;
    receiverId: number;
    freightId: number;
    transporterImage?: string;
    transporterName?: string;
    customerName?: string;
  };

  isOpen: boolean;
  onOpen: (
    type: ModalType,
    data: {
      quoteId: number;
      customerId: number;
      carrierId: number;
      senderId: number;
      receiverId: number;
      freightId: number;
      transporterImage?: string;
      transporterName?: string;
      customerName?: string;
    }
  ) => void;
  onClose: () => void;
}

export const useSendMessage = create<ModalStore>((set) => ({
  type: null,
  data: {
    carrierId: 0,
    customerId: 0,
    freightId: 0,
    quoteId: 0,
    receiverId: 0,
    senderId: 0,
    transporterImage: "",
    transporterName: "",
    customerName: "",
  },
  isOpen: false,
  onOpen: (
    type,
    {
      customerId,
      quoteId,
      carrierId,
      senderId,
      receiverId,
      freightId,
      transporterImage,
      transporterName,
      customerName,
    }
  ) =>
    set({
      isOpen: true,
      type,
      data: {
        quoteId,
        customerId,
        carrierId,
        senderId,
        receiverId,
        freightId,
        transporterImage,
        transporterName,
        customerName,
      },
    }),
  onClose: () => set({ type: null, isOpen: false }),
}));
