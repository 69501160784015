import Header from "../components/header/Header";
import { Elipses } from "../components/home/Elipses";
//@ts-ignore

import furgo from "../assets/images/furgo.png";
//@ts-ignore
import { useNavigate } from "react-router-dom";
import commission from "../assets/images/Commission.png";
import Footer from "../components/layout/Footer";
import FrequentQuestions from "../components/pricing/FrequentQuestions";
export const Pricing = () => {
  const navigate = useNavigate();

  return (
    <div className=" overflow-x-hidden bg-white">
      <div className="sticky top-0 z-10">
        {" "}
        <Header />
      </div>
      <div className="mt-32">
        <Elipses />
      </div>
      <div className="p-20 flex justify-center flex-wrap">
        <div className="max-w-[700px]">
          <div>
            <p className="text-4xl font-bold">PRICING</p>
          </div>
          <div className="mt-8 mr-4">
            <p className="text-xl mb-4">
              Runnme is based on meeting the needs of offline and online
              businesses in today's commerce. They have understood the
              importance of using mobile devices today, so they have decided to
              integrate it to meet the demands of delivering any product.
            </p>
            <p className="text-xl hidden sm:block">
              Need: To streamline the dynamics of businesses in different
              cities. To respond to the users' need to quickly obtain the
              products they need. This involves 2 decisive factors: speed and
              efficiency. To be able to transport any load no matter the size.
              Operation You need to download the app or search the Web and
              complete the registration. Then, you can request the delivery of
              anything you need that fits in the delivery person's suitcase
            </p>
          </div>
          <div className="mt-12">
            <button
              // onClick={() => navigate("/register")}
              onClick={() => navigate("/preregister")}
              className="mb-8 h-[40px] w-[200px] mt-20 bg-[#ff380f] shadow-lg hover:bg-[#ff846c] hover:shadow-4xl text-white font-bold rounded-2xl transition duration-300 ease-in-out"
            >
              Sign up
            </button>
          </div>
        </div>
        <img
          src={furgo}
          className="w-[auto] max-h-[500px] rounded-3xl"
          alt=""
        />
      </div>
      <div>
        <div className="flex justify-center pb-8">
          <p className="text-3xl font-bold">Simplified pricing system</p>
        </div>
        <div className="flex justify-center pb-8">
          <div className="bg-white bg-opacity-100 border z-0 rounded-xl shadow-xl p-10 lg:h-[500px] w-[600px] overflow-hidden sm:w-[800px]">
            <p className="text-2xl pb-8 font-bold">Transporter earnings</p>
            <p>
              As a transporter you will be able to fix a prize with the customer
              and recieve 90% of the total price as a commission
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>Unified prize.</li>
              <li>Flexible schedule. </li>
              <li> Earn extra money taking advantage of your trips.</li>
            </ul>
            <div className="flex justify-center">
              <img src={commission} className="h-[200px]" alt="" />
            </div>
            <div className="flex justify-center">
              <button
                onClick={() => navigate("/register/carrier")}
                className="mb-8 h-[40px] w-[200px] bg-[#ff380f] shadow-lg hover:bg-[#ff846c] hover:shadow-4xl text-white font-bold rounded-2xl transition duration-300 ease-in-out"
              >
                Become driver
              </button>
            </div>
          </div>
        </div>
        <div className="sm:px-40 max-w-[2000px] mx-auto">
          <div className="flex justify-center pb-8">
            <p className="text-3xl font-bold">Frequent questions</p>
          </div>
          <FrequentQuestions />
        </div>
      </div>

      <Footer />
    </div>
  );
};
