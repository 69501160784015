import {
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from "react-router-dom";

import DashboardCustomer from "../components/customer/Dashboard";
import { AboutUs } from "../screens/AboutUs";
import AcceptQuote from "../screens/AcceptQuote";
import Alerts from "../screens/Alerts";
import AssignInstantFreight from "../screens/AssignInstantFreight";
import AssignStopToRoute from "../screens/AssignStopToRoute";
import BookingsCompleted from "../screens/BookingsCompleted";
import BookNow from "../screens/BookNow";
import CalculateQuote from "../screens/CalculateQuote";
import CarrierB2bCompletedTrips from "../screens/CarrierB2bCompletedTrips";
import CarrierB2bTrips from "../screens/CarrierB2bTrips";
import CarrierBookings from "../screens/CarrierBookings";
import CarrierChat from "../screens/CarrierChat";
import CarrierDrivers from "../screens/CarrierDrivers";
import CarrierFeedback from "../screens/CarrierFeedback";
import CarrierFleets from "../screens/CarrierFleets";
import CarrierHistoryPayments from "../screens/CarrierHistoryPayments";
import CarrierInstantBookings from "../screens/CarrierInstantBookings";
import CarrierQuotes from "../screens/CarrierQuotes";
import CarrierStopEdit from "../screens/CarrierStopEdit";
import CarrierTrips from "../screens/CarrierTrips";
import CarrierViewFreight from "../screens/CarrierViewFreight";
import ChangePassword from "../screens/ChangePassword";
import { Claim } from "../screens/Claim";
import ConfirmBookingQuote from "../screens/ConfirmBookingQuote";
import { ContactUs } from "../screens/ContactUs";
import CustomerBookings from "../screens/CustomerBookings";
import CustomerChat from "../screens/CustomerChat";
import CustomerFeedback from "../screens/CustomerFeedback";
import CustomerFreight from "../screens/CustomerFreight";
import CustomerInstantFreights from "../screens/CustomerInstantFreights";
import CustomerStatistics from "../screens/CustomerStatistics";
import CustomerTrips from "../screens/CustomerTrips";
import Dashboard from "../screens/Dashboard";
import DeliveryRouteEdit from "../screens/DeliveryRouteEdit";
import DeliveryRouteEditPrices from "../screens/DeliveryRouteEditPrices";
import EditNewFreight from "../screens/EditNewFreight";
import Feedback from "../screens/Feedback";
import FindTrip from "../screens/FindTrip";
import HistoryPayments from "../screens/HistoryPayments";
import Home from "../screens/Home";
import { HowItWorks } from "../screens/HowItWorks";
import Inbox from "../screens/Inbox";
import InboxDetails from "../screens/InboxDetails";
import InstantFreightPackage from "../screens/InstantFreightPackage";
import InstantServiceEdit from "../screens/InstantServiceEdit";
import MethodPayments from "../screens/MethodPayments";
import NotFound from "../screens/NotFound";
import PackageDelivery from "../screens/PackageDelivery";
import PackageDeliverySelection from "../screens/PackageDeliverySelection";
import PackageDeliverySuccess from "../screens/PackageDeliverySuccess";
import PayInstantFreight from "../screens/PayInstantFreight";
import { Policy } from "../screens/Policy";
import { PreRegister } from "../screens/PreRegister";
import { Pricing } from "../screens/Pricing";
import ProvideQuote from "../screens/ProvideQuote";
import Register from "../screens/Register";
import RegisterCarrier from "../screens/RegisterCarrier";
import RegisterCustomer from "../screens/RegisterCustomer";
import RequestTicket from "../screens/RequestTicket";
import RoutePackagePayStop from "../screens/RoutePackagePayStop";
import RoutePackageSuccess from "../screens/RoutePackageSuccess";
import SearchFreight from "../screens/SearchFreight";
import SelectFreight from "../screens/SelectFreight";
import SelectionInstantFreights from "../screens/SelectionInstantFreights";
import SendNow from "../screens/SendNow";
import SendNowFreight from "../screens/SendNowFreight";
import SignIn from "../screens/SignIn";
import SingleEcomOrder from "../screens/SingleEcomOrder";
import SingleRouteTrip from "../screens/SingleRouteTrip";
import Statistics from "../screens/Statistics";
import Subscriptions from "../screens/Subscriptions";
import SuccessFreight from "../screens/SuccessFreight";
import { Terms } from "../screens/Terms";
import TripPackage from "../screens/TripPackage";
import TripPackagePrices from "../screens/TripPackagePrices";
import TripPackageStops from "../screens/TripPackageStops";
import TripPackageSuccess from "../screens/TripPackageSuccess";
import UpdateCarrierProfile from "../screens/UpdateCarrierProfile";
import UpdateProfile from "../screens/UpdateProfile";
import VerifyOtp from "../screens/VerifyOtp";
import ViewBooking from "../screens/ViewBooking";
import ViewCarrierBooking from "../screens/ViewCarrierBooking";
import ViewCarrierProfile from "../screens/ViewCarrierProfile";
import ViewCarrierQuote from "../screens/ViewCarrierQuote";
import ViewCustomerQuote from "../screens/ViewCustomerQuote";
import ViewFreight from "../screens/ViewFreight";
import ViewInstantBooking from "../screens/ViewInstantBooking";
import ViewInstantFreight from "../screens/ViewInstantFreight";
import ViewInstantFreightDetails from "../screens/ViewInstantFreightDetails";
import ViewRoute from "../screens/ViewRoute";
import ViewSearchFreight from "../screens/ViewSearchFreight";
import ViewStop from "../screens/ViewStop";

function ErrorBoundary() {
  const error = useRouteError();
  console.log(error);
  return <div>Dang!</div>;
}

const router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "/preregister", element: <PreRegister /> },
  { path: "/sign-in", element: <SignIn /> },
  { path: "/register", element: <Register /> },
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/dashboard-customer", element: <DashboardCustomer /> },
  { path: "/:userType/changePassword", element: <ChangePassword /> },
  { path: "/:userType/updateProfile", element: <UpdateProfile /> },
  { path: "/carrier/profileview/:userId", element: <ViewCarrierProfile /> },
  { path: "/tickets/raiseTicket", element: <RequestTicket /> },
  { path: "/freight/carrierFeedback/:type", element: <Feedback /> },
  { path: "/carrier/profileupdate/:userId", element: <UpdateCarrierProfile /> },
  { path: "/myinbox/:type", element: <Inbox /> },
  { path: "/quote/:type", element: <CarrierQuotes /> },
  { path: "/quote/viewDetails/:quoteId", element: <ViewCarrierQuote /> },
  { path: "/carrier/booking/transporters/:type", element: <CarrierBookings /> },
  { path: "/carrier/booking/feedback/:type", element: <CarrierFeedback /> },
  { path: "/getFreight", element: <SelectFreight /> },
  { path: "/successFreight/:id", element: <SuccessFreight /> },
  { path: "/searchFreight", element: <SearchFreight /> },
  { path: "/freight/freightDetails/:id", element: <ViewFreight /> },
  { path: "/freight/searchFreight/:id", element: <ViewSearchFreight /> },
  { path: "/freight/quoteFreight/:id", element: <ProvideQuote /> },
  { path: "/carrier/myAlerts", element: <Alerts /> },
  { path: "/statistics", element: <Statistics /> },
  { path: "/myFreight/:type", element: <CustomerFreight /> },
  { path: "/booking/carrier/:type", element: <CarrierBookings /> },
  { path: "/customer/freightDetails/:id", element: <CarrierViewFreight /> },
  { path: "/editFreight/details/:id", element: <EditNewFreight /> },
  { path: "/send-now", element: <SendNow /> },
  { path: "/register/customer", element: <RegisterCustomer /> },
  { path: "/register/verify", element: <VerifyOtp /> },
  { path: "/register/carrier", element: <RegisterCarrier /> },
  { path: "/myInbox/showdetail/:id", element: <InboxDetails /> },
  { path: "/booking/customer/:type", element: <CustomerBookings /> },
  { path: "/freight/customerFeedback", element: <CustomerFeedback /> },
  { path: "/customer/booking/:bookingId", element: <ViewBooking /> },
  { path: "/customer/statistics", element: <CustomerStatistics /> },
  { path: "/carrier/booking/getReviews", element: <ViewBooking /> },
  { path: "/carrier/manageFleet", element: <CarrierFleets /> },
  { path: "/carrier/manageDriver", element: <CarrierDrivers /> },
  { path: "/customer/method-payments", element: <MethodPayments /> },
  { path: "/customer/subscriptions", element: <Subscriptions /> },
  { path: "/customer/history-payments", element: <HistoryPayments /> },
  { path: "/carrier/history-payments", element: <CarrierHistoryPayments /> },
  { path: "/carrier/bookings/completed", element: <BookingsCompleted /> },
  { path: "/carrier/booking/:bookingId", element: <ViewCarrierBooking /> },
  // { path: "/shipping-calculator", element: <ShippingCalculator /> },
  { path: "/calculate-quote", element: <CalculateQuote /> },
  { path: "/shipping-package/:weight", element: <InstantFreightPackage /> },
  { path: "/book-now", element: <BookNow /> },
  { path: "/instant-freight", element: <SelectionInstantFreights /> },
  { path: "/my-instant-freights", element: <CustomerInstantFreights /> },
  { path: "/carrier/instant-freights", element: <CarrierInstantBookings /> },
  { path: "/instant-freights/:id", element: <ViewInstantFreight /> },
  {
    path: "/instant-freights-details/:id",
    element: <ViewInstantFreightDetails />,
  },
  { path: "*", element: <NotFound /> },
  { path: "/instant-bookings/:id", element: <ViewInstantBooking /> },
  { path: "/carrier/messages", element: <CarrierChat /> },
  { path: "/customer/messages", element: <CustomerChat /> },
  { path: "/send-now-freight/:location", element: <SendNowFreight /> },
  { path: "/pricing", element: <Pricing /> },
  { path: "/contact-us", element: <ContactUs /> },
  { path: "/how-it-works", element: <HowItWorks /> },
  { path: "/about-us", element: <AboutUs /> },
  { path: "/trip-package", element: <TripPackage /> },
  { path: "/trip-package/stops/:routeId", element: <TripPackageStops /> },
  { path: "/trip-package/prices", element: <TripPackagePrices /> },
  { path: "/trip-package/success", element: <TripPackageSuccess /> },
  { path: "/carrier/trips/:routeId", element: <ViewRoute /> },
  { path: "/carrier/trips", element: <CarrierTrips /> },
  { path: "/customer/trips", element: <CustomerTrips /> },
  { path: "/package-delivery", element: <PackageDelivery /> },
  { path: "/package-delivery/success", element: <PackageDeliverySuccess /> },
  { path: "/customer/trips/:stopId", element: <ViewStop /> },
  { path: "/claim", element: <Claim /> },
  { path: "/policy", element: <Policy /> },
  { path: "/terms", element: <Terms /> },
  {
    path: "/package-delivery/selection",
    element: <PackageDeliverySelection />,
  },
  { path: "/find-trip", element: <FindTrip /> },
  {
    path: "/carrier/assign-freight/:instantFreightId",
    element: <AssignInstantFreight />,
  },
  {
    path: "/customer/quoteDetails/:freightId/:quoteId",
    element: <ViewCustomerQuote />,
  },
  { path: "/carrier/trips/edit/:stopId", element: <CarrierStopEdit /> },
  { path: "/assign-stop/:routeId", element: <AssignStopToRoute /> },
  { path: "/routes/trips/:routeId", element: <SingleRouteTrip /> },
  { path: "/routes/trips/success/:routeId", element: <RoutePackageSuccess /> },
  { path: "/routes/trips/assign/:stopId", element: <RoutePackagePayStop /> },
  { path: "/carrier/trips/edit/:routeId", element: <DeliveryRouteEdit /> },
  {
    path: "/carrier/trips/prices/edit/:routeId",
    element: <DeliveryRouteEditPrices />,
  },
  {
    path: "/my-instant-freights/edit/:instantServiceId",
    element: <InstantServiceEdit />,
  },
  { path: "/accept-quote/:quoteId", element: <AcceptQuote /> },
  { path: "/complete-payment/:bookingId", element: <ConfirmBookingQuote /> },
  {
    path: "/pay-instant-freight/:instantFreightId",
    element: <PayInstantFreight />,
  },
  {
    path: "/carrier/b2b-trips",
    element: <CarrierB2bTrips />,
  },
  {
    path: "/carrier/completed-b2b-trips",
    element: <CarrierB2bCompletedTrips />,
  },
  {
    path: "/store-order/:orderId",
    element: <SingleEcomOrder />,
  },
]);

// 4️⃣ RouterProvider added
export default function Router() {
  return <RouterProvider router={router} />;
}
