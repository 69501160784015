import { create } from "zustand";

export type ModalType = "deleteRoute";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  routeId: string;
  onOpen: (type: ModalType, routeId: string) => void;
  onClose: () => void;
}

export const useDeleteRoute = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  routeId: "",
  onOpen: (type, routeId) => set({ isOpen: true, type, routeId }),
  onClose: () => set({ type: null, isOpen: false }),
}));
