import React from "react";
import { FaMapMarkedAlt } from "react-icons/fa";
import { IoChatbubbles } from "react-icons/io5";
import { FaShippingFast } from "react-icons/fa";
import { MdDashboardCustomize } from "react-icons/md";
//@ts-ignore
import phone from "../../assets/images/iPhone_Mockup_3.png";
//@ts-ignore
import phone2 from "../../assets/images/iPhone_Mockup_4.png";
import "../../styles/features.css";
export const Features = () => {
  return (
    <div className="bg-freightBlue flex justify-center">
      {/*       <p className="text-center text-4xl font-bold text-[#1889ca]">FEATURES</p>
       */}{" "}
      <div className="sm:grid grid-cols-2 gap-2 justify-evenly p-20 ">
        <div className="max-w-[500px] pb-20">
          <div className="flex justify-end">
            <img src={phone} className="max-h-[400px]" alt="" />
          </div>
          <h2 className="text-4xl font-bold pt-10 pb-2  text-white mb-4 flex pr-4 justify-end ">
            LIVE CHAT
          </h2>
          <div className="sm:mb-24 mb-10 rounded-3xl bg-white p-4">
            <p className="">
              Discover the efficiency of live chat, the perfect solution to
              communicate with your customers instantly.{" "}
              <span className="hidden sm:block">
                support and direct accessibility from your website, this
                interactive service combines automated responses and human With
                multilingual assistance to provide an exceptional user
                experience. Optimize your customer service with our live chat
                and transform every interaction into a satisfaction opportunity!
              </span>
            </p>
          </div>
        </div>
        <div className="max-w-[500px] sm:pr-20">
          <h2 className="text-4xl font-bold sm:pt-6 pb-2 text-white mb-4">
            LIVE TRACKING
          </h2>
          <div className="sm:mb-24 mb-10 rounded-3xl bg-white p-4">
            <p>
              We provide Live Tracking, an innovative real-time tracking tool
              that gives our customers peace of mind.
            </p>
            <p className="hidden sm:block">
              With the precision of GPS satellite tracking, they can monitor
              their packages from pick-up to final delivery. Our aim is to
              improve operational efficiency and boost customer confidence
              through unparalleled transparency.
            </p>
          </div>
          <div className="">
            <img src={phone2} className="max-h-[400px] sm:pl-4" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
