import { atom } from "jotai"
import { UserInfo } from "../types/types"

export const userId = atom<string>("")
export const userInfo = atom<UserInfo>({ access_token: "", email: "", mobile: "", name: "", user_id: 0, member_status: 0, user_type: "" })
export const hasAuth = atom<boolean>(false)



//FREIGHTS
export type Category = {
    catid: number;
    catname?: string

}
export const selectedCategory = atom<{ catid: number; catname?: string; sub?: Category[] }>({ catid: 0, catname: "", sub: [] })
export const selectedSubcategory = atom<Category>({ catid: 0, catname: "" })
export const selectedSubcategoryNew = atom<{ id: number; name: string } | null>(null);

export const detailsFreight = atom<{
    make: string; model: string; year: string; driveable: string; moreDetails: string; modifications: string;
    // additionalNotes: string;

    imageFreight: any
}>({
    make: "", model: "", year: "", driveable: "", moreDetails: "", modifications: "",
    // additionalNotes: "",
    imageFreight: {}
})

export const isDrivableFreight = atom<{ drivable: boolean; undrivableDetails?: string; modifications?: string }>({ drivable: false, undrivableDetails: "", modifications: "" })
export const expireQuote = atom<{ id: number; name: string; value: string }>({ id: 0, name: "", value: "" })

export const pickupDetailsFreight = atom<{ suburb: string; landmark: string; locationType: string; suburbDropoff: string; landmarkDropoff: string; locationTypeDropoff: string; deliveredTo: string; termsConditions: boolean }>({
    suburb: "",
    landmark: "",
    locationType: "",
    suburbDropoff: "",
    landmarkDropoff: "",
    locationTypeDropoff: "",
    deliveredTo: "",
    termsConditions: false
})

export const freightImageUpload = atom<any>({})


export const freightDate = atom<{ startDateFormat: string; endDateFormat: string }>({ startDateFormat: "", endDateFormat: "" })


export const paymentWithCustomer = atom<boolean>(false)

export const palletizedDetails = atom<{ Description: string; Packaging: string; Qty: string; Height: string; Width: string; Length: string; Weight: string }>({
    Description: "",
    Packaging: "",
    Qty: "",
    Height: "",
    Width: "",
    Length: "",
    Weight: ""
})

export const boatDetails = atom<{ onTailor: string; transporterType: string; length: string; width: string; weight: string; }>({
    onTailor: "",
    transporterType: "",
    length: "",
    width: "",
    weight: ""
})

export const heavyHaulageDetails = atom<{ Description: string; Height: string; Width: string; Length: string; Weight: string }>({
    Description: "",
    Height: "",
    Width: "",
    Length: "",
    Weight: ""

})

export const horsesDetails = atom<{ Description: string; numberOfMares: string; numberOfMaresWithFoal: string; numberOfGueldings: string; numberOfStallion: string; breed: string; travelExperience: string; accessType: string }>({
    Description: "",
    numberOfMares: "",
    numberOfMaresWithFoal: "",
    numberOfGueldings: "",
    numberOfStallion: "",
    breed: "",
    travelExperience: "",
    accessType: ""

})

export const petsDetails = atom<{ Description: string; Breed: string; Age: string; Weight: string; vaccinationDate: string; ownCageProvided: string }>({
    Description: "",
    Breed: "",
    Age: "",
    Weight: "",
    vaccinationDate: "",
    ownCageProvided: ""
})


export const livestockDetails = atom<{ Description: string; noLivestock: string; }>({
    Description: "",
    noLivestock: ""
})

export const foodDetails = atom<{ Description: string }>({
    Description: ""
})

export const specialCareDetails = atom<{ Description: string }>({
    Description: ""
})

export const heyDetails = atom<{ Description: string }>({
    Description: ""
})

export const buildingDetails = atom<{ Description: string }>({
    Description: ""
})

export const otherFreightDetails = atom<{ Description: string }>({
    Description: ""
})

export const parcelDetails = atom<{ Description: string }>({
    Description: ""
})

export const containerDetails = atom<{ Description: string; needContainer: string }>({
    Description: "",
    needContainer: ""
})

export const goodsDetails = atom<{ Description: string; }>({
    Description: ""
})

export const hasLoggedIn = atom<boolean>(false)
export const selectNormalAtom = atom<boolean>(true)
export const selectPetDeliveryAtom = atom<boolean>(false)

export const shippingInfo = atom<{ originCountry: string; originCity: string; originZipcode: string; destinationCountry: string; destinationCity: string; destinationZipcode: string }>({
    originCountry: "",
    originCity: "",
    originZipcode: "",
    destinationCountry: "",
    destinationCity: "",
    destinationZipcode: ""
})
export const dimensionsInfo = atom<{ width: string; length: string; height: string; weight: string }>({ width: "", length: "", height: "", weight: "" })
export const petInfo = atom<{ petImage: string; petWeight: string; petType: string; petHasBox: boolean; petForLocalImage: string }>({ petImage: "", petWeight: "", petType: "", petHasBox: false, petForLocalImage: "" })
