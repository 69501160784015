import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import Lottie from "lottie-react";
import React, { useEffect, useRef, useState } from "react";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { BASE_URL, GOOGLE_API_KEY } from "../../api/config";
import loadeanimation from "../../assets/Animations/LoadingBox.json";
import { Switch } from "../../components/ui/switch";
import { useTokenStoreStorage } from "../../store/useTokenStore";

import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EXPIRE_DAYS_URL } from "../../api/config";
import FreightDogsSummary from "./summaries/FreightDogsSummary";

interface ExpireOption {
  id: number;
  name: string;
  value: string;
}

interface FreightGeneralProps {
  onFreightDetailsClick: () => void;
  handleBackToGetFreight: () => void;
}

export const FreightPets: React.FC<FreightGeneralProps> = ({
  onFreightDetailsClick,
  handleBackToGetFreight,
}) => {
  const navigate = useNavigate();
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const [loadingCreationRoute, setLoadingCreationRoute] = useState(false);
  const [distance, setDistance] = useState<string | null>(null);
  const [duration, setDuration] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [expireOptions, setExpireOptions] = useState<ExpireOption[]>([]);
  const [isSummaryVisible, setIsSummaryVisible] = useState(false); // State to toggle between form and summary
  const [imagePreview, setImagePreview] = useState<string | null>(null); // State to store image preview
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Ref for file input
  const [formVisible, setFormVisible] = useState(false); // Track visibility of the remaining form fields

  const [formData, setFormData] = useState({
    description: "",
    start_date: "",
    end_date: "",
    expire_date: "",
    origin_address: "",
    origin_lat: null as number | null,
    origin_lng: null as number | null,
    origin_location_type: "",
    destination_address: "",
    destination_lat: null as number | null,
    destination_lng: null as number | null,
    destination_location_type: "",
    freight_type: 2,
    category_id: 2,
    subcategory_id: 21,
    has_cage: false,
    freight_weight: "",
    comments: "",
    animal_img: null as File | null,
    delivery_duration: "" as string | null,
    delivery_distance: "" as string | null,
  });

  const originAutocompleteRef = useRef<google.maps.places.Autocomplete | null>(
    null
  );
  const destinationAutocompleteRef =
    useRef<google.maps.places.Autocomplete | null>(null);
  const originInputRef = useRef<HTMLInputElement>(null);
  const destinationInputRef = useRef<HTMLInputElement>(null);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });
  async function calculateRoute() {
    setLoadingCreationRoute(true);
    const directionsService = new google.maps.DirectionsService();

    const results = await directionsService.route({
      origin: {
        lat: Number(formData.origin_lat),
        lng: Number(formData.origin_lng),
      },
      destination: {
        lat: Number(formData.destination_lat),
        lng: Number(formData.destination_lng),
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    const leg = results.routes[0].legs[0];

    // Check if distance and duration exist
    if (leg.distance && leg.duration) {
      setDistance(leg.distance.text); // Set distance only if it exists
      setDuration(leg.duration.text); // Set duration only if it exists

      setFormData((prevData) => ({
        ...prevData,
        delivery_distance: leg.distance?.text || null, // Update delivery_distance
        delivery_duration: leg.duration?.text || null, // Update delivery_duration
      }));
    }

    setTimeout(() => {
      setLoadingCreationRoute(false);
    }, 2000);
  }
  const handleNext = (e: React.FormEvent) => {
    e.preventDefault(); // Prevent the default form submission
    setIsSummaryVisible(true); // Show the summary
    calculateRoute();
  };

  const handleSwitchChange = (value: boolean) => {
    setFormData((prevData) => ({
      ...prevData,
      has_cage: value, // Update the has_cage value based on the switch state
    }));
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      setFormData((prevData) => ({
        ...prevData,
        animal_img: file,
      }));

      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
      setFormVisible(true); // Show the rest of the form fields when an image is selected
      onFreightDetailsClick();
    }
  };

  // Function to trigger the file input on image click
  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handlePlaceSelect = (
    autocompleteRef: React.RefObject<google.maps.places.Autocomplete>,
    type: "origin" | "destination"
  ) => {
    const place = autocompleteRef.current?.getPlace();
    if (place && place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const address = place.formatted_address || "";

      if (type === "origin") {
        setFormData((prevData) => ({
          ...prevData,
          origin_address: address,
          origin_lat: lat,
          origin_lng: lng,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          destination_address: address,
          destination_lat: lat,
          destination_lng: lng,
        }));
      }
    }
  };

  const getExpiredDays = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}${EXPIRE_DAYS_URL}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch expiration days");
      }
      const data = await response.json();
      setExpireOptions(data.data);
    } catch (error) {
      console.error("Error fetching expiration days:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getExpiredDays();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const myHeaders = new Headers();
      myHeaders.append("AccessToken", token);

      const formdata = new FormData();
      formdata.append("user_id", user_id);
      formdata.append("data[detail][description]", formData.description);
      formdata.append("data[detail][start_date]", formData.start_date);
      formdata.append("data[detail][end_date]", formData.end_date);
      formdata.append("data[detail][expire_date]", formData.expire_date);
      formdata.append("data[detail][origin_address]", formData.origin_address);
      formdata.append("data[detail][origin_lat]", String(formData.origin_lat));
      formdata.append("data[detail][origin_lng]", String(formData.origin_lng));
      formdata.append(
        "data[detail][origin_location_type]",
        formData.origin_location_type
      );
      formdata.append("data[detail][delivery_distance]", distance ?? "");
      formdata.append("data[detail][delivery_duration]", duration ?? "");
      formdata.append(
        "data[detail][destination_address]",
        formData.destination_address
      );
      formdata.append(
        "data[detail][destination_lat]",
        String(formData.destination_lat)
      );
      formdata.append(
        "data[detail][destination_lng]",
        String(formData.destination_lng)
      );
      formdata.append(
        "data[detail][destination_location_type]",
        formData.destination_location_type
      );
      formdata.append(
        "data[detail][freight_type]",
        String(formData.freight_type)
      );
      formdata.append(
        "data[detail][category_id]",
        String(formData.category_id)
      );
      formdata.append(
        "data[detail][subcategory_id]",
        String(formData.subcategory_id)
      );
      formdata.append("data[detail][has_cage]", formData.has_cage ? "1" : "0");
      formdata.append(
        "data[detail][freight_weight]",
        String(formData.freight_weight)
      );
      formdata.append("data[detail][comments]", formData.comments);

      if (formData.animal_img) {
        formdata.append("animal_img", formData.animal_img);
      }

      const response = await fetch(`${BASE_URL}freight/add`, {
        method: "POST",
        headers: myHeaders,
        body: formdata,
      });

      // Check if response is not OK and handle errors with specific messages
      if (!response.ok) {
        let errorMessage = "Error uploading";

        // Check for different status codes and handle them accordingly
        switch (response.status) {
          case 400:
            errorMessage = "Bad request. Please check your input.";
            break;
          case 401:
          case 403:
            errorMessage = "Unauthorized. Please check your access token.";
            break;
          case 422:
            errorMessage =
              "There was an issue uploading image. Please upload a jpg, jpeg or png files under 5MB.";
            break;
          case 500:
            errorMessage = "Server error. Please try again later.";
            break;
          default:
            errorMessage = `Unexpected error: ${response.status}`;
        }

        throw new Error(errorMessage);
      }

      // Success response, you can navigate or show a success message
      toast.success("Freight uploaded successfully!");
    } catch (error) {
      // Type narrowing for error object
      if (error instanceof Error) {
        // Display the error message in the toast
        toast.error(error.message || "Error uploading");
      } else {
        // Fallback for unknown error types
        toast.error("An unknown error occurred");
      }
    } finally {
      setLoading(false);
      navigate("/myFreight/active");
    }
  };

  const handleCreate = async () => {
    await handleSubmit(); // Call the submit function here
    // navigate("/dashboard-customer"); // or whatever navigation logic you prefer
  };
  // Condition to check if the API is ready
  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  // Function to calculate the difference in days
  const calculateDateDifference = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const differenceInTime = end.getTime() - start.getTime(); // Difference in milliseconds
    const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Convert to days
    return differenceInDays;
  };

  return (
    <div className="p-8 flex justify-center">
      <div className="w-[1000px]">
        {loading ? (
          <div className="flex justify-center items-center">
            <Lottie
              animationData={loadeanimation}
              className="h-[300px] w-[300px]"
            />
          </div>
        ) : (
          <>
            <button
              className="bg-gray-500 px-2 py-1 mb-3 rounded-md text-white hover:bg-gray-800"
              onClick={handleBackToGetFreight}
            >
              <div className="flex gap-2 items-center">
                <IoArrowBack />
                <p>Back to categories</p>
              </div>
            </button>
            {isSummaryVisible ? (
              <FreightDogsSummary
                formData={{
                  ...formData,
                  origin_latitude: formData.origin_lat,
                  origin_longitude: formData.origin_lng,
                  destination_latitude: formData.destination_lat,
                  destination_longitude: formData.destination_lng,
                  delivery_distance: distance,
                  delivery_duration: duration,
                }}
                onCreate={handleCreate} // Call handleCreate to submit
                onEdit={() => setIsSummaryVisible(false)} // Go back to the form
                imagePreview={imagePreview}
              />
            ) : (
              <form onSubmit={handleNext} className="flex flex-col gap-4">
                {" "}
                {/* Change onSubmit to handleNext */}
                <h3 className="text-xl font-semibold">
                  Complete Freight Details
                </h3>
                <div className="relative ">
                  <input
                    ref={fileInputRef} // Ref to the file input
                    type="file"
                    name="animal_img"
                    onChange={handleFileChange}
                    className="p-2 w-full border rounded-md opacity-0 absolute top-0 left-0 cursor-pointer h-full"
                    accept="image/*"
                  />
                  {imagePreview ? (
                    <img
                      src={imagePreview}
                      alt="Preview"
                      onClick={handleImageClick} // Clicking the image opens file input
                      className="h-48 w-48 object-cover border rounded-md cursor-pointer"
                    />
                  ) : (
                    <div
                      className="h-48 w-48 border rounded-md flex items-center justify-center cursor-pointer"
                      onClick={handleImageClick}
                    >
                      <p className="text-gray-500">Click to add image</p>
                    </div>
                  )}
                </div>
                <textarea
                  name="description"
                  placeholder="Description"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="p-2 border rounded-md w-full"
                  required
                />
                {formVisible && (
                  <>
                    <p className="font-semibold">Dates</p>
                    <div className="flex gap-4">
                      <div className="flex flex-column w-full">
                        <small>Available from</small>
                        <input
                          type="date"
                          name="start_date"
                          placeholder="Start Date"
                          value={formData.start_date}
                          onChange={handleInputChange}
                          onClick={(e) => e.currentTarget.showPicker()} // Opens the date picker
                          className="p-2 border rounded-md w-full"
                          required
                          min={new Date().toISOString().split("T")[0]} // Disable past dates
                          max={formData.end_date}
                        />
                      </div>
                      <div className="flex w-full flex-column">
                        <small>Available Until</small>
                        <input
                          type="date"
                          name="end_date"
                          placeholder="End Date"
                          value={formData.end_date}
                          onChange={handleInputChange}
                          onClick={(e) => e.currentTarget.showPicker()} // Opens the date picker
                          className="p-2 border rounded-md w-full"
                          required
                          disabled={formData.start_date === ""}
                          min={
                            formData.start_date ||
                            new Date().toISOString().split("T")[0]
                          } // Start from start_date or today's date
                        />
                      </div>
                    </div>

                    <p className="font-semibold">Address Information</p>

                    <div className="flex flex-col gap-4">
                      <Autocomplete
                        onLoad={(autocomplete) =>
                          (originAutocompleteRef.current = autocomplete)
                        }
                        onPlaceChanged={() =>
                          handlePlaceSelect(originAutocompleteRef, "origin")
                        }
                      >
                        <input
                          ref={originInputRef}
                          type="text"
                          value={formData.origin_address}
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              origin_address: e.target.value,
                              origin_lat: null,
                              origin_lng: null,
                            }))
                          }
                          placeholder="Origin Address"
                          className="p-2 border rounded-md w-full"
                        />
                      </Autocomplete>
                      <Autocomplete
                        onLoad={(autocomplete) =>
                          (destinationAutocompleteRef.current = autocomplete)
                        }
                        onPlaceChanged={() =>
                          handlePlaceSelect(
                            destinationAutocompleteRef,
                            "destination"
                          )
                        }
                      >
                        <input
                          ref={destinationInputRef}
                          type="text"
                          value={formData.destination_address}
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              destination_address: e.target.value,
                              destination_lat: null,
                              destination_lng: null,
                            }))
                          }
                          placeholder="Destination Address"
                          className="p-2 border rounded-md w-full"
                        />
                      </Autocomplete>
                    </div>

                    <div className="flex gap-4">
                      <input
                        type="number"
                        name="freight_weight"
                        placeholder="Freight Weight"
                        value={formData.freight_weight}
                        onChange={handleInputChange}
                        className="p-2 border rounded-md w-full"
                        required
                      />
                    </div>
                    <textarea
                      name="comments"
                      placeholder="Comments"
                      value={formData.comments}
                      onChange={handleInputChange}
                      className="p-2 border rounded-md w-full"
                      rows={4}
                    />
                  </>
                )}
                <div className="flex justify-between">
                  {/* Cage checkbox implementation */}
                  <div className="flex items-center space-x-2">
                    <Switch
                      checked={formData.has_cage} // Bind switch to formData.has_cage
                      onCheckedChange={handleSwitchChange} // Call handleSwitchChange on toggle
                      color="#0022B6"
                    />
                    <h1 className="text-sm font-medium">Pet has a cage</h1>
                  </div>
                  <button
                    type="submit"
                    className={`p-2 rounded-md w-[150px] ${
                      formData.origin_lat === null ||
                      formData.destination_lat === null
                        ? "bg-gray-400 text-gray-200 cursor-not-allowed"
                        : "bg-[#1889ca] text-white"
                    }`}
                    disabled={
                      formData.origin_lat === null ||
                      formData.destination_lat === null
                    }
                  >
                    Next
                  </button>
                </div>
              </form>
            )}
          </>
        )}
      </div>
    </div>
  );
};
