import { create } from "zustand";
import { CustomerPayment } from "../types/types";

export type ModalType = "viewPayment";

interface ModalStore {
  type: ModalType | null;
  payment: CustomerPayment | null;
  isOpen: boolean;
  onOpen: (type: ModalType, payment: CustomerPayment) => void;
  onClose: () => void;
}

export const useViewPayment = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  payment: null,
  onOpen: (type, payment) => set({ isOpen: true, type, payment }),
  onClose: () => set({ type: null, isOpen: false }),
}));
