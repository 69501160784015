import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom"; // Import useNavigate
import { CSSTransition } from "react-transition-group";
import { cn } from "src/lib/utils";
import Header from "../components/header/Header";
import { Elipses } from "../components/home/Elipses";
import { Elipses2 } from "../components/home/Elipses2";
import { Features } from "../components/home/Features";
import Tabs from "../components/home/Tabs";
import { TransportersCard } from "../components/home/TransportersCard";
import { VideoPromo } from "../components/home/VideoPromo";
import Footer from "../components/layout/Footer";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
//@ts-ignore
import Package1 from "../assets/home/pexels-artempodrez-5025517.jpg";
//@ts-ignore
import Package2 from "../assets/home/pexels-kampus-7843981.jpg";
//@ts-ignore
import Package3 from "../assets/home/happy-woman-taking-package-from-courier-while-receiving-home-delivery.jpg";
import ImageCarousel from "../components/carousel/ImageCarousel";
import "../styles/transitions.css";
import { PreRegister } from "./PreRegister";

const Home = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [inPropTabs, setInPropTabs] = useState(false);
  const [inPropFeatures, setInPropFeatures] = useState(false);
  const featuresRef = useRef<HTMLDivElement>(null);
  const [showContent, setShowContent] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const navigate = useNavigate(); // useNavigate hook to navigate

  const handleShowContent = () => {
    setShowContent(true);
  };

  const toggleSidebar = () => {
    setButtonClicked(true); // Trigger button animation
    if (windowWidth > 767) {
      // Adjust threshold as needed
      setSidebarOpen((prev) => !prev); // Toggle the sidebar open/close

      // Reset button animation after a short delay
      setTimeout(() => setButtonClicked(false), 300); // Duration should match the animation time
    } else {
      navigate("/preregister"); // Navigate to /preregister for mobile
    }
  };

  useEffect(() => {
    setInPropTabs(true);
    // Update the window width on resize
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const userType = userInfoUserStore((s) => s.user_type);
  const token = useTokenStoreStorage((state) => state.token);

  if (token !== "" && userType === "customer") {
    return <Navigate to={"/dashboard-customer"} replace />;
  }
  if (token !== "" && userType === "carrier") {
    return <Navigate to={"/dashboard"} replace />;
  }

  return (
    <>
      <Header />
      <div
        className={`h-screen w-screen overflow-x-hidden bg-white transition-all  ${
          sidebarOpen ? "lg:pr-[500px] delay-200" : ""
        }`}
      >
        <>
          <button
            onClick={toggleSidebar}
            className={cn(
              `border-none
    rounded cursor-pointer
    hover:bg-[#0056b3]
    transition-transform duration-300 transform
    ${
      windowWidth <= 767
        ? "bg-[#007bff] text-white fixed top-40 z-[10] left-1/2 transform -translate-x-1/2 py-2 px-4 text-base"
        : "bg-[#007bff] text-white fixed top-7 z-[1001] right-20 py-2 px-4 text-base"
    }`, // Apply different styles for mobile
              buttonClicked ? "scale-105 bg-[#0056b3] shadow-lg" : "scale-100",
              window.location.pathname === "/" &&
                "bg-white font-medium text-[#1889CA]"
            )}
          >
            Get Early Access
          </button>

          <div>
            <div className="pt-32">
              <Elipses />
              <div className="bg-white bg-center sm:w-full sm:flex flex-col items-center sm:max-w-7xl sm:mx-auto mx-6">
                <div className="flex flex-col items-center justify-center space-y-4 pb-20">
                  <div className="flex flex-col space-y-3 items-start">
                    <h1 className="text-black text-6xl font-extrabold">
                      Explore delivery in Runnme
                    </h1>
                    <div className="my-4">
                      <h2 className="text-lg font-medium text-[#333] ">
                        Runnme is a shipping platform that adapts to your needs.
                        Supporting both small and large-scale operations.
                      </h2>
                      <h2 className="text-lg font-medium text-[#333] hidden sm:block">
                        Our 3 delivery systems guarantee immediate, scheduled,
                        and specific delivery for each customer. We are
                        committed to providing you with fast and efficient
                        service at all times.
                      </h2>
                    </div>
                  </div>
                  <div className="sm:flex items-start space-x-10">
                    <div className="bg-white bg-center mb-20 flex flex-col items-center col-span-1">
                      <Tabs />
                    </div>
                    <div className="sm:mx-auto mx-5">
                      <ImageCarousel images={[Package1, Package2, Package3]} />
                    </div>
                  </div>
                </div>
              </div>
              <Elipses2 />
              <CSSTransition
                in={inPropFeatures}
                timeout={500}
                classNames="slide-in-right"
                nodeRef={featuresRef}
              >
                <div ref={featuresRef}>
                  <Features />
                </div>
              </CSSTransition>
              <div>
                <VideoPromo />
              </div>
              <div className="pt-20 flex justify-center bg-gradient-to-b from-freightBlue to-black pb-8">
                <TransportersCard />
              </div>
            </div>
          </div>
          <Footer />
        </>
        <CSSTransition
          in={sidebarOpen}
          timeout={300}
          classNames="sidebar"
          unmountOnExit
        >
          <div className="fixed top-0 right-0 w-1/2 h-full bg-white shadow-lg z-[1000]">
            <PreRegister toggleSideBar={toggleSidebar} />
          </div>
        </CSSTransition>
      </div>
    </>
  );
};

export default Home;
