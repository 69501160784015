import { create } from "zustand";

export type ModalType = "completeBooking";

interface ModalStore {
  type: ModalType | null;
  bookingId: number | null;
  isOpen: boolean;
  onOpen: (type: ModalType, bookingId: number | null) => void;
  onClose: () => void;
}

export const useCompleteBooking = create<ModalStore>((set) => ({
  type: null,
  bookingId: null,
  isOpen: false,
  onOpen: (type, bookingId) => set({ isOpen: true, type, bookingId }),
  onClose: () => set({ type: null, isOpen: false }),
}));
