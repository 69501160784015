import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/layout/Footer";

export const Terms = () => {
  return (
    <div>
      {" "}
      <div>
        <Header />
      </div>
      <div className="p-20">
        <div
          className="pb-8 text-5xl font-bold flex justify-center gap-2"
          style={{ fontFamily: "Franklin Gothic" }}
        >
          <h2>
            <span style={{ color: "#0022b6" }}>L</span>EGAL{" "}
          </h2>
          {" "}
          <h2>
            <span style={{ color: "#0022b6" }}>T</span>ERMS{" "}
          </h2>
        </div>
        <div className="flex justify-center flex-wrap">
          <div className=" max-w-[1000px] ">
            <p>
              <b>RUNNME</b>, the person responsible for the website, hereinafter
              the <b>CONTROLLER</b>, makes this document available to users,
              which is intended to comply with the obligations provided in Act
              34/2002 of 11 July, on Information Society and Electronic Commerce
              Services (LSSICE), Spanish Official State Journal No. 166, as well
              as informing all website users of the conditions of use.
            </p>
            <br />
            <p>
              Any person who accesses this website acquires the status of user,
              and as such undertakes to strictly observe and comply with the
              provisions provided herein, as well as any other applicable legal
              clause.
            </p>
            <br />
            <p>
              <b>RUNNME</b> reserves the right to modify any type of information
              that may appear on the website, and is not obliged to give prior
              notice or inform users of such obligations, with publication on
              the website of <b>RUNNME</b> being understood as sufficient.
            </p>
          </div>
          <div className=" max-w-[1000px] ">
            <p>
              The website, including but not limited to its programming,
              editing, compilation and other elements necessary for its
              operation, the designs, logos, text and/or graphics, are owned by
              the <b>CONTROLLER</b> or, if applicable, they expressly holds a
              license or an express authorisation by the authors. All website
              contents are duly protected by intellectual and industrial
              property laws, and are registered in the corresponding public
              registries. Regardless of their intended purpose, the total or
              partial reproduction, use, exploitation, distribution and
              commercialisation, requires in any case the prior written
              authorisation from the <b>CONTROLLER</b>. Any unauthorised use is
              considered a serious breach of the author’s intellectual or
              industrial property rights.
            </p>
          </div>
          <div className=" max-w-[1000px]">
            <p>
              The designs, logos, text and/or graphics not belonging to the
              <b> CONTROLLER</b> and which may appear on the website, belong to
              their respective owners who are liable for any possible dispute
              that may arise regarding them. The <b>CONTROLLER</b> expressly
              authorises third parties to redirect to the specific content of
              the website, and in any case to redirect to the main website of
              elitesports17.com.
            </p>
            <br />
            <p>
              The <b> CONTROLLER</b> acknowledges the corresponding intellectual
              and industrial property rights in favour of their owners, and any
              mention or appearance on the website does not imply the existence
              of any rights or responsibility whatsoever over them, nor does it
              imply any endorsement, sponsorship or recommendation by the
              website
            </p>
            <br />
            <p>
              Comments regarding any possible breach of intellectual or
              industrial property rights, as well as regarding the contents of
              the website, can be made by contacting adrimvalia@hotmail.com.
            </p>
            <br />
            <p>
              The <b>RESPONSIBLE</b> disclaims any liability for the information
              published on its website provided that it has no actual knowledge
              that this information has been manipulated or introduced by a
              third party external to it or, if it has, has acted diligently to
              remove the data or make it impossible to access them.
            </p>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
