import { useNavigate } from "react-router-dom";
import SidebarLayout from "../components/layout/SidebarLayout";
import { Button } from "../components/ui/button";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import {
  ArrowLeft,
  ArrowRight,
  Eye,
  Loader2,
  PackageCheck,
  PackageX,
  Plus,
  RouteOff,
} from "lucide-react";
import { ALL_ROUTES, BASE_URL } from "../api/config";
import { useQuery } from "@tanstack/react-query";
import { RouteType } from "../types/types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { cn } from "../lib/utils";
//@ts-ignore
import SmallBox from "../assets/images/Smallbox.png";
//@ts-ignore
import MediumBox from "../assets/images/Mediumbox.png";
//@ts-ignore
import LargeBox from "../assets/images/Largebox.png";

const CarrierTrips = () => {
  const navigate = useNavigate();
  const { user_type, user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("desc");

  const getRoute = async () => {
    const response = await fetch(
      `${BASE_URL}${ALL_ROUTES}?user_id=${user_id}&sort=${sortBy}&page=${page}`,
      {
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };
  const {
    data: routes,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["routes_user", page, sortBy],
    queryFn: getRoute,
  });

  useEffect(() => {
    refetch();
  }, []);

  const sortOptions = [
    {
      value: "ASC",
      label: "Ascendent",
    },
    {
      value: "DESC",
      label: "Descendent",
    },
  ];

  return (
    <SidebarLayout>
      <div className="flex items-center w-full justify-between border-b border-border pb-3 mb-4">
        <h1 className="text-lg font-semibold">My Trips</h1>
        <Button
          variant={"freightBlue"}
          onClick={() => navigate("/trip-package")}
        >
          <Plus className="h-4 w-4 text-white mr-2" />
          <p className="text-sm font-medium text-white"> Create trip</p>
        </Button>
      </div>
      {isLoading ? (
        <div className="bg-white shadow h-20 w-full flex items-center justify-center">
          <Loader2 className="animate-spin h-5 w-5" />
        </div>
      ) : (
        <>
          {routes?.message === "Error retrieving routes available" ||
          routes?.message === "No routes found" ? (
            <div className="bg-white shadow rounded p-4 flex items-center justify-center">
              <RouteOff className="h-5 w-5 text-gray-500" />
              <p className="text-sm font-medium text-gray-500 ml-2">
                {routes.message}
              </p>
            </div>
          ) : (
            <>
              <div className="mt-4 ml-auto w-full justify-end sm:flex space-x-2 mb-4">
                <>
                  <div className="text-sm font-medium flex items-center space-x-1">
                    <h1>Total</h1>
                    {!isLoading ? (
                      <span>{routes?.data?.total_items}</span>
                    ) : (
                      <span>
                        <Loader2 className="h-4 w-4 animate-spin" />
                      </span>
                    )}
                    <h1>Bookings</h1>
                  </div>
                </>
                <Select
                  onValueChange={(val: any) => {
                    setSortBy(val.toLowerCase());
                  }}
                >
                  <SelectTrigger className="w-40 h-8">
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent>
                    {sortOptions.map((sort) => (
                      <SelectItem key={sort.value} value={sort.value}>
                        {sort.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <div className="flex space-x-2">
                  <Button
                    disabled={page === 1}
                    onClick={() => setPage(1)}
                    className={cn(
                      "h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                      page === 1 && "bg-[#333] text-white"
                    )}
                  >
                    <p className="text-sm font-medium">1</p>
                  </Button>
                  <div
                    onClick={() => {
                      if (page > 1 && page !== routes?.data?.total_pages) {
                        setPage((page) => page - 1);
                      }
                    }}
                    className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
                  >
                    <ArrowLeft className="h-4 w-4 text-[#333]" />
                  </div>
                  <div
                    onClick={() => {
                      if (page !== routes?.data.total_pages) {
                        setPage((page) => page + 1);
                      }
                    }}
                    className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
                  >
                    <ArrowRight className="h-4 w-4 text-[#333]" />
                  </div>

                  <Button
                    disabled={page === routes?.data?.total_pages}
                    onClick={() => setPage(routes?.data?.total_pages)}
                    className={cn(
                      "h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                      page === routes?.data?.total_pages &&
                        "bg-[#333] text-white"
                    )}
                  >
                    {isLoading ? (
                      <p>
                        <Loader2 className="h-5 w-5 animate-spin" />
                      </p>
                    ) : (
                      <p className="text-sm font-medium">
                        {routes?.data?.total_pages}
                      </p>
                    )}
                  </Button>
                </div>
              </div>
              <Table className="bg-white border-border border">
                <TableHeader>
                  <TableRow>
                    <TableHead>Route Id</TableHead>
                    <TableHead>Origin/Destination</TableHead>
                    <TableHead>Start/End Date</TableHead>
                    <TableHead>Number of Stops</TableHead>
                    <TableHead>Status</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {routes?.data?.routes.map((route: RouteType) => (
                    <TableRow key={route.id}>
                      <TableCell>
                        <div className="flex flex-col items-start bg-gray-50 border border-border p-4">
                          <h1 className="text-sm font-medium">Stop Packages</h1>
                          <div className="flex flex-wrap  items-center  space-x-2 w-52 gap-4  py-2 rounded ">
                            {route.stops.length > 0 ? (
                              route.stops.map((item) => (
                                <div key={item.id} className="">
                                  {item.package_size === "small" && (
                                    <div className="flex flex-col items-center bg-white rounded p-1 shadow ">
                                      <img
                                        src={SmallBox}
                                        className="h-7 w-7"
                                        alt="smallbox"
                                      />
                                      <p className="text-xs text-[#333] font-medium">
                                        {item.price.toFixed(2)}€
                                      </p>
                                    </div>
                                  )}
                                  {item.package_size === "medium" && (
                                    <div className="flex flex-col items-center bg-white rounded p-1 shadow">
                                      <img
                                        src={MediumBox}
                                        className="h-7 w-7"
                                        alt="mediumbox"
                                      />
                                      <p className="text-xs text-[#333] font-medium">
                                        {item.price.toFixed(2)}€
                                      </p>
                                    </div>
                                  )}
                                  {item.package_size === "large" && (
                                    <div className="flex flex-col items-center bg-white rounded p-1 shadow">
                                      <img
                                        src={LargeBox}
                                        className="h-7 w-7"
                                        alt="largebox"
                                      />
                                      <p className="text-xs text-[#333] font-medium">
                                        {item.price.toFixed(2)}€
                                      </p>
                                    </div>
                                  )}
                                </div>
                              ))
                            ) : (
                              <div className="h-10">
                                <p className="text-lg font-medium text-gray-500">
                                  No stops available
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex flex-col space-y-4">
                          <div className=" flex flex-col space-y-2">
                            <p className="text-sm font-medium">Origin</p>
                            <p className="text-sm font-medium text-gray-500">
                              {route.origin_address}
                            </p>
                          </div>
                          <div className="flex flex-col space-y-2">
                            <p className="text-sm font-medium">Destination</p>
                            <p className="text-sm font-medium text-gray-500">
                              {route.destination_address}
                            </p>
                          </div>
                        </div>
                      </TableCell>

                      <TableCell>
                        <div className="flex flex-col items-start space-y-4">
                          <div className="flex flex-col items-start">
                            <p className="text-sm font-medium">Departure</p>
                            <p className="text-sm font-medium text-gray-500">
                              {new Date(route.start_date).toDateString()}{" "}
                              {new Date(route.start_date).getHours()}:
                              {new Date(route.start_date).getMinutes()}
                            </p>
                          </div>
                          <div className="flex flex-col items-start">
                            <p className="text-sm font-medium">Arrival</p>
                            <p className="text-sm font-medium text-gray-500">
                              {new Date(route.end_date).toDateString()}{" "}
                              {new Date(route.end_date).getHours()}:
                              {new Date(route.end_date).getMinutes()}
                            </p>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="">
                          <div className="bg-gray-100 rounded border-border border p-4 mb-4">
                            <div className="flex items-center space-x-2">
                              <h1 className="text-sm font-medium">Nº Stops</h1>
                              <p className="text-sm font-medium text-gray-500">
                                {route.stops?.length}
                              </p>
                            </div>
                          </div>
                          {route.is_deleted ? (
                            <div className="flex items-start space-x-2">
                              <PackageX className="h-5 w-5 text-gray-500 " />
                              <p className="text-sm font-medium text-gray-500 ">
                                Route deleted
                              </p>
                            </div>
                          ) : (
                            <div className="flex flex-col  justify-between">
                              <div className="flex mb-2 items-center space-x-2">
                                <PackageCheck className="h-5 w-5 text-green-500" />
                                <p className="text-sm font-medium text-[#333]">
                                  Active route
                                </p>
                                <div
                                  onClick={() =>
                                    navigate(`/carrier/trips/${route.id}`)
                                  }
                                  className="cursor-pointer w-7 h-7 flex items-center justify-center hover:bg-gray-200 rounded-full"
                                >
                                  <Eye className="h-5 w-5" />
                                </div>
                              </div>
                              <Button
                                onClick={() =>
                                  navigate(`/trip-package/stops/${route.id}`)
                                }
                                variant={"freightBlue"}
                              >
                                <Plus className="h-4 w-4 mr-2" />
                                Add Stop
                              </Button>
                            </div>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="bg-gray-100 px-4 py-2 rounded text-center text-sm font-medium capitalize">
                          {route.status}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </>
      )}
    </SidebarLayout>
  );
};

export default CarrierTrips;
