export const formatFStatus = (status: "DCC" | "FD" | "QR" | "FBCC" | "FB") => {
    if (status === "DCC") {
        return `Delivery completed`
    } else if (status === "FD") {
        return `Delivery deleted`
    } else if (status === "QR") {
        return `Quote Ready`
    } else if (status === "FBCC") {
        return `Freight Booked Cancelled`
    } else if (status === "FB") {
        return `Freight Booked`
    }
}