import { MessageType } from "src/types/types";
import DOMPurify from "dompurify";
import "./messageDetails.css";

interface MessageDetailsProps {
  message: MessageType;
}

const MessageDetails: React.FC<MessageDetailsProps> = ({ message }) => {
  const sanitizedHTMLContent = DOMPurify.sanitize(message.body);
  return (
    <>
      <div className="w-full">
        <div className="flex flex-col space-y-1 border-b-2 pb-2 w-full">
          <div className=" flex items-center space-x-2">
            <p className="font-medium text-sm">Subject:</p>
            <p className="text-sm font-medium text-gray-500">
              {" "}
              {message.subject}
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <p className="font-medium text-sm">Received:</p>
            <p className="text-sm font-medium text-gray-500">{message.date}</p>
          </div>
        </div>
        <div
          className=" my-4 pb-10"
          dangerouslySetInnerHTML={{ __html: sanitizedHTMLContent }}
        />
      </div>
    </>
  );
};

export default MessageDetails;
