import autoAnimate from "@formkit/auto-animate";
import { useEffect, useRef, useState } from "react";
import { Button } from "../ui/button";

import CategoriesFilterSub from "./CategoriesFilterSub";

const CategoryFilter = ({
  categories,
  onFilteredChange,
  onFilteredSubChange,
}: {
  categories: {
    id: string;
    checked: boolean;
    name: string;
    sub: {
      checked: boolean;
      id: string;
      catname: string;
    }[];
  }[];
  onFilteredChange: any;
  onFilteredSubChange: any;
}) => {
  const [checkedCategories, setCheckedCategories] = useState<
    {
      id: string;
      checked: boolean;
      sub: { checked: boolean; id: string; catname: string }[];
    }[]
  >([]);

  const [checkedSubcategories, setCheckedSubcategories] = useState<
    { id: string; checked: boolean; catname: string }[]
  >([]);

  const [trueSubcategories, setTrueSubcategories] = useState<
    { id: string; checked: boolean; catname: string }[]
  >([]);

  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  useEffect(() => {
    setCheckedCategories(categories);
  }, []);

  const handleCategoryChange = (id: any) => {
    setCheckedCategories((prevCheckedCategories: any) =>
      prevCheckedCategories.map((category: any) =>
        category.id === id
          ? { ...category, checked: !category.checked }
          : category
      )
    );
  };

  const onFilterSubChange = (categories: any) => {
    setCheckedSubcategories(categories);
  };

  useEffect(() => {
    if (checkedSubcategories?.length > 0) {
      setTrueSubcategories((prev: any) => [
        ...prev,
        checkedSubcategories.filter((cate) => cate.checked),
      ]);
    }
  }, [checkedSubcategories]);

  return (
    <div className="flex flex-col w-60 p-2">
      <ul>
        {checkedCategories.map((category: any, index: number) => (
          <CategoriesFilterSub
            category={category}
            handleCategoryChange={handleCategoryChange}
            onFilterSubChange={onFilterSubChange}
            checkedSubcategoriesSub={checkedSubcategories}
          />
        ))}
      </ul>
      <Button
        variant={"freightBlue"}
        onClick={() => {
          onFilteredChange(checkedCategories);
          onFilteredSubChange(
            trueSubcategories
              .flat()
              .filter(
                (value, index, self) =>
                  index ===
                  self.findIndex((t) => t.id === value.id && t.id === value.id)
              )
          );
        }}
        className="mt-4 mx-2"
      >
        Apply Filter
      </Button>
    </div>
  );
};

export default CategoryFilter;
