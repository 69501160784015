import { create } from "zustand";

export type ModalType = "assignOrder";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  orderId: string;
  orderState: "0" | "1" | "2" | null;
  onOpen: (
    type: ModalType,
    orderId: string,
    orderState: "0" | "1" | "2" | null
  ) => void;
  onClose: () => void;
}

export const useAssignOrder = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  orderState: null,
  orderId: "",
  onOpen: (type, orderId, orderState) =>
    set({ isOpen: true, type, orderId, orderState }),
  onClose: () => set({ type: null, isOpen: false }),
}));
