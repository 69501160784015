import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { IoChevronForward } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "src/hooks/use-local-storage";
import { z } from "zod";

export const ThirdTab = () => {
  const [dropOffDate, setDropOffDate] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const navigate = useNavigate();
  const [pickupDropoffTrip, setPickupDropoffTrip] = useLocalStorage(
    "trip_pickup_dropoff"
  );

  const formSchema = z.object({
    origin: z.any(),
    destination: z.any(),
    datePickup: z.any(),
    dateDropoff: z.any(),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      origin: "",
      destination: "",
      dateDropoff: "",
      datePickup: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    navigate("/trip-package");
    setPickupDropoffTrip({
      pickup: values.datePickup,
      dropoff: values.dateDropoff,
    });
  };

  const handlePostYourTrip = () => {
    navigate("/preregister");
    // if (!token) {
    //   setShowModal(true); // Mostrar modal si el token está vacío
    // } else {
    //   // Si el token está presente, proceder a la navegación
    //   setTimeout(() => {
    //     navigate("/trip-package");
    //   }, 1000);
    // }
  };

  return (
    <div className="bg-white bg-opacity-100 border rounded-xl shadow-xl lg:h-[400px] w-[400px] py-4 px-10 overflow-hidden sm:w-[800px] sm:flex sm:flex-col">
      <div className=" flex flex-col items-start w-full">
        <h3 className="text-3xl sm:text-4xl font-extrabold font-paragraph">
          Travelling somewhere?
        </h3>
        <h4 className="mt-2 text-2xl font-bold text-slate-500">
          Add your travelling route
        </h4>
        <p className="text-sm font-semibold text-gray-500 mt-4">
          Introducing Trip Delivery, the groundbreaking platform reshaping the
          shipping industry! Drawing inspiration from the collaborative concept
          of ride-sharing, Trip Delivery connects people traveling with
          available space in their vehicles with those who need to send
          packages. It's a mutually beneficial arrangement: travelers generate
          additional income, and recipients benefit from streamlined shipping
          services.
        </p>
        <button
          onClick={handlePostYourTrip}
          // type="submit"
          className="h-[45px] w-[250px] mt-14 lg:ml-auto   font-paragraph bg-[#ff380f] shadow-lg hover:bg-[#f4775efb] hover:shadow-4xl group text-white  rounded-2xl transition duration-300 ease-in-out flex items-center space-x-2 justify-center"
        >
          <p className="text-[15px]  font-semibold">Post your trip</p>
          <IoChevronForward className="h-5 w-5 group-hover:translate-x-2 transition " />
        </button>
        {/* <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col items-start w-full mt-8"
          >
            <FormField
              control={form.control}
              name="datePickup"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-lg  font-semibold font-paragraph">
                    Date of pickup
                  </FormLabel>
                  <br />

                  <FormControl>
                    <Input
                      {...field}
                      type="date"
                      className="focus-visible:ring-blue-500 focus-visible:ring-2 transition duration-200 ease-in-out outline-none border-[lightgray] w-[300px] border h-[38px] rounded px-2 font-paragraph text-sm"
                      // value={pickupDate}
                      // onChange={(e) => setPickupDate(e.target.value)}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <div className="">
              <FormField
                control={form.control}
                name="dateDropoff"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-lg mt-4 font-semibold font-paragraph">
                      Date of drop off
                    </FormLabel>
                    <br />
                    <FormControl>
                      <Input
                        {...field}
                        type="date"
                        className="focus-visible:ring-blue-500 focus-visible:ring-2 transition duration-200 ease-in-out outline-none border-[lightgray] w-[300px] border h-[38px] rounded px-2 font-paragraph text-sm"
                        // value={dropOffDate}
                        // onChange={(e) => setDropOffDate(e.target.value)}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            <button className="h-[40px] w-[250px] ml-auto mt-8 bg-[#ff380f] shadow-lg justify-end hover:bg-[#f4775efb] hover:shadow-4xl text-white font-bold rounded-2xl transition duration-300 ease-in-out ">
              Schedule a trip
            </button>
          </form>
        </Form> */}
      </div>
    </div>
  );
};
