import { AlertCircle, Loader2 } from "lucide-react";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { Button } from "../ui/button";
import { useDeleteAccount } from "src/hooks/use-delete-account";
import { BASE_URL, DELETE_ACCOUNT } from "src/api/config";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ModalDeleteAccount = () => {
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const modalDeleteAccount = useDeleteAccount();
  const setToken = useTokenStoreStorage((state) => state.addToken);
  const setUserInfo = userInfoUserStore((s) => s.addUserInfo);
  const navigate = useNavigate();
  const token = useTokenStoreStorage((state) => state.token);
  const [loadingDeletingAccount, setLoadingDeletingAccount] = useState(false);

  const deleteUserAccount = async () => {
    setLoadingDeletingAccount(true);
    const response = await fetch(`${BASE_URL}${DELETE_ACCOUNT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        user_type: user_type,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "User deleted successfully.") {
          setToken("");
          setUserInfo("", "", "", "", "");
          navigate("/sign-in");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingDeletingAccount(false);
      });

    return response;
  };
  return (
    <Dialog
      open={modalDeleteAccount.isOpen}
      onOpenChange={modalDeleteAccount.onClose}
    >
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-start space-y-4  w-full">
              <div className="flex flex-col items-center w-full">
                <AlertCircle className="h-28 w-28 text-red-400" />
                <h1 className="text-xl font-medium">Are you sure?</h1>
                <h3 className="text-sm">You want to remove this account?</h3>
              </div>
              <div className="flex items-center justify-end space-x-2 w-full ">
                <Button
                  onClick={() => modalDeleteAccount.onClose()}
                  variant={"outline"}
                >
                  Cancel
                </Button>
                <Button
                  disabled={loadingDeletingAccount}
                  onClick={() => deleteUserAccount()}
                  variant={"freightBlue"}
                >
                  {loadingDeletingAccount ? (
                    <>
                      <Loader2 className="h-5 w-5 animate-spin" />
                    </>
                  ) : (
                    <>Ok</>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalDeleteAccount;
