import { useAddQuote } from "src/hooks/use-add-quote";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { Check } from "lucide-react";

import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";

const ModalAddQuote = () => {
  const modalQuote = useAddQuote();
  const navigate = useNavigate();
  return (
    <Dialog open={modalQuote.isOpen} onOpenChange={modalQuote.onClose}>
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-center space-y-2 mb-10">
              <div className="h-20 w-20 border border-border rounded-full bg-green-500 flex items-center justify-center">
                <Check className="h-10 w-10 text-white" />
              </div>
              <h1 className="text-lg font-semibold ">Quote Added.</h1>
              <p className="text-xs font-medium text-gray-400">
                #{modalQuote.data?.reference}
              </p>
            </div>
            <div className="flex flex-col space-y-2 w-full">
              <div className="bg-gray-50 flex items-center justify-between p-4 w-full border rounded">
                <h1 className="text-sm font-medium">Amount</h1>
                <p className="text-sm font-medium text-gray-500">
                  {Number(modalQuote.data?.amount).toFixed(2)}€
                </p>
              </div>
              <div className="bg-gray-50 flex items-center justify-between p-4 w-full border rounded">
                <h1 className="text-sm font-medium">Pick up</h1>
                <div>
                  <p className="text-sm font-medium text-gray-500 capitalize text-right">
                    {modalQuote.data?.pickup_at}
                  </p>
                  <p className="text-sm font-medium">
                    <span className=" capitalize  text-gray-600">
                      {" "}
                      {modalQuote.data?.pickup_date_type}
                    </span>{" "}
                    <span className=" text-gray-500">
                      {modalQuote.data?.pickup_date1}
                    </span>
                  </p>
                </div>
              </div>
              <div className="bg-gray-50 flex items-center justify-between p-4 w-full border rounded">
                <h1 className="text-sm font-medium">Dropoff</h1>
                <div>
                  <p className="text-sm font-medium text-gray-500 capitalize text-right">
                    {modalQuote.data?.dropoff_at}
                  </p>
                  <p className="text-sm font-medium">
                    <span className=" capitalize  text-gray-500">
                      {" "}
                      {modalQuote.data?.dropoff_date_type}
                    </span>{" "}
                    <span className=" text-gray-500">
                      {modalQuote.data?.dropoff_date1}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </DialogHeader>
        <div className="w-full justify-end items-end flex space-x-2">
          <Button
            onClick={() => {
              navigate(`/freight/quoteFreight/${modalQuote.data?.freightid}`);
              modalQuote.onClose();
            }}
            variant={"outline"}
          >
            Back
          </Button>
          <Button
            onClick={() => navigate("/quote/active")}
            variant={"freightBlue"}
          >
            View
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAddQuote;
