import { useAddCarrierFleets } from "src/hooks/use-add-carrier-fleets";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel } from "../ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Input } from "../ui/input";
import { BASE_URL, FLEET_ADD, VEHICLE_TYPE } from "src/api/config";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { Button } from "../ui/button";
import { userInfoUserStore } from "src/store/useUserInfoStore";

const addFleetSchema = z.object({
  vehicleRegisteredNumber: z.string().min(1),
  image: z.any(),
  vehicleType: z.string().min(1),
});

const ModalAddFleet = () => {
  const modalCarrierFleets = useAddCarrierFleets();
  const token = useTokenStoreStorage((state) => state.token);
  const { email, user_id } = userInfoUserStore((state) => state);
  const navigate = useNavigate();

  const addFleetForm = useForm<z.infer<typeof addFleetSchema>>({
    resolver: zodResolver(addFleetSchema),
    defaultValues: {
      image: "",
      vehicleRegisteredNumber: "",
      vehicleType: "",
    },
  });

  const getVehicleType = async () => {
    const response = await fetch(`${BASE_URL}${VEHICLE_TYPE}`, {
      headers: {
        AccessToken: token,
      },
      method: "GET",
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
    return response;
  };

  const { data: vehicleType } = useQuery({
    queryKey: ["vehicle_type"],
    queryFn: getVehicleType,
  });

  const addFleetFormData = new FormData();

  const onSubmit = async (values: z.infer<typeof addFleetSchema>) => {
    fleetAdd(values.vehicleRegisteredNumber, values.vehicleType, values.image);
  };
  const fleetAdd = async (
    vehicleRegNo: string,
    vehicleType: string,
    fleetImage: string
  ) => {
    addFleetFormData.append("vehicleRegNo", vehicleRegNo);
    addFleetFormData.append("vehicleType", vehicleType);
    addFleetFormData.append("fleet_image", fleetImage);
    addFleetFormData.append("userId", user_id);
    const response = await fetch(`${BASE_URL}${FLEET_ADD}`, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        AccessToken: token,
      },
      body: addFleetFormData,
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };
  return (
    <Dialog
      open={modalCarrierFleets.isOpen}
      onOpenChange={modalCarrierFleets.onClose}
    >
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-start space-y-2 mb-10">
              <div className="flex items-center">
                <h1>Add Carrier Fleight</h1>
              </div>
              <div className="mt-4 flex flex-col w-full">
                <Form {...addFleetForm}>
                  <form
                    className="w-full dark:bg-transparent space-y-4"
                    onSubmit={addFleetForm.handleSubmit(onSubmit)}
                  >
                    <FormField
                      control={addFleetForm.control}
                      name="vehicleRegisteredNumber"
                      render={({ field }) => (
                        <FormControl {...field}>
                          <FormItem>
                            <FormLabel className="text-sm pb-3 dark:text-black">
                              Vehicle Register Number:*
                            </FormLabel>
                            <Input
                              {...field}
                              name="vehicleRegisteredNumber"
                              className="w-full dark:bg-transparent"
                            />
                          </FormItem>
                        </FormControl>
                      )}
                    />
                    <Controller
                      control={addFleetForm.control}
                      name="image"
                      render={({ field, fieldState }) => (
                        <div>
                          <h1 className="text-sm font-medium mb-2">Images:</h1>
                          <Input
                            accept="image/png image/jpeg, image/gif"
                            {...field}
                            value={field.value.fileName}
                            type="file"
                            onChange={(event) => {
                              field.onChange(event);
                            }}
                          />
                          {fieldState.error && (
                            <p className="text-red-500 text-sm mt-1">
                              {fieldState.error.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                    <Controller
                      control={addFleetForm.control}
                      name="vehicleType"
                      render={({ field, fieldState }) => (
                        <div>
                          <h1 className="text-sm font-medium mb-2">
                            Vehicle Type
                          </h1>
                          <Select
                            onValueChange={(value) => {
                              addFleetForm.setValue("vehicleType", value);
                            }}
                            {...field}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select" />
                            </SelectTrigger>
                            <SelectContent className="h-60">
                              {vehicleType?.data.map(
                                (type: { name: string }) => (
                                  <SelectItem value={type.name}>
                                    {type.name}
                                  </SelectItem>
                                )
                              )}
                              {/* {vehicleType?.da} */}
                            </SelectContent>
                          </Select>
                        </div>
                      )}
                    />
                    <Button
                      variant={"freightBlue"}
                      className="flex justify-end items-end"
                    >
                      Add Fleet
                    </Button>

                    {/* <FormField
                      name=""
                      render={({ field }) => (
                        <Select>
                          <SelectTrigger></SelectTrigger>
                        </Select>
                      )}
                    /> */}
                  </form>
                </Form>
              </div>
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAddFleet;
