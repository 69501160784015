import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { Loader } from "lucide-react";
import { Button } from "../ui/button";
import { useState } from "react";
import { BASE_URL, ORDERS_UPDATE } from "src/api/config";
import { useNavigate } from "react-router-dom";
import { useAssignOrder } from "src/hooks/use-assign-order";

interface ModalAssignOrderProps {
  refetchOrders: () => void;
}

const ModalAssignOrder: React.FC<ModalAssignOrderProps> = ({
  refetchOrders,
}) => {
  const { user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const [loadingAssignOrder, setLoadingAssignOrder] = useState(false);
  const navigate = useNavigate();

  const modalAssignOrder = useAssignOrder();

  const assignOrder = async () => {
    setLoadingAssignOrder(true);
    const response = await fetch(`${BASE_URL}${ORDERS_UPDATE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        order_id: modalAssignOrder.orderId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        refetchOrders();
        if (data.message === "Order state updated successfully") {
          setTimeout(() => {
            modalAssignOrder.onClose();
            navigate(`/store-order/${modalAssignOrder.orderId}`);
          }, 2000);
        }
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingAssignOrder(false);
      });

    return response;
  };

  return (
    <Dialog
      open={modalAssignOrder.isOpen}
      onOpenChange={modalAssignOrder.onClose}
    >
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-start  w-full space-y-10">
              <div className="flex flex-col items-center w-full py-4">
                {/* <AlertCircle className="text-red-500 h-20 w-20" /> */}
                <h1 className="text-xl font-medium">Are you sure?</h1>
                <h3 className="text-sm ">
                  {modalAssignOrder.orderState === "0" &&
                    "Do you want to assign this order to you?"}
                  {modalAssignOrder.orderState === "1" &&
                    "Do you want to complete this trip?"}
                </h3>
              </div>
              <div className="flex items-center justify-end space-x-2 w-full mt-4">
                <Button
                  variant={"outline"}
                  onClick={() => modalAssignOrder.onClose()}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => assignOrder()}
                  disabled={loadingAssignOrder}
                >
                  {loadingAssignOrder ? (
                    <>
                      <Loader className="h-5 w-5 animate-spin" />
                    </>
                  ) : (
                    <>
                      {modalAssignOrder.orderState === "0" && "Assign Trip"}
                      {modalAssignOrder.orderState === "1" && "Complete Trip"}
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAssignOrder;
