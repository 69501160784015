import { useExtendFreight } from "src/hooks/use-extend-freight";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { z } from "zod";
import { Form } from "../ui/form";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { BASE_URL, EXPIRE_DAYS_URL, FREIGHT_EXTEND } from "src/api/config";
import { useQuery } from "@tanstack/react-query";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { useEffect } from "react";
import { Button } from "../ui/button";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import toast from "react-hot-toast";

const extendFreightSchema = z.object({
  extendPeriod: z.string().min(1),
});

interface ModalExtendFreightProps {
  refetch: () => void;
}

const ModalExtendFreight: React.FC<ModalExtendFreightProps> = ({ refetch }) => {
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id, name } = userInfoUserStore((state) => state);
  const modalExtendFreight = useExtendFreight();

  const extendFreight = useForm<z.infer<typeof extendFreightSchema>>({
    resolver: zodResolver(extendFreightSchema),
    defaultValues: {
      extendPeriod: "",
    },
  });

  const onSubmit = (values: z.infer<typeof extendFreightSchema>) => {
    extendExpireDay(values.extendPeriod);
  };

  const getExpireQuoteDays = async () => {
    const response = await fetch(`${BASE_URL}${EXPIRE_DAYS_URL}`, {
      method: "GET",
      headers: {
        AccessToken: token,
      },
    }).then((res) => res.json());

    return response;
  };

  const { data: expireQuoteDays } = useQuery({
    queryKey: ["expire_quote"],
    queryFn: getExpireQuoteDays,
  });

  const extendExpireDay = async (expireDate: string) => {
    const response = await fetch(`${BASE_URL}${FREIGHT_EXTEND}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        freightid: modalExtendFreight.freightId,
        user_id: user_id,
        expire_date: expireDate,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Expired freight extended successfully.") {
          toast.success(data.message);
          modalExtendFreight.onClose();
        }
      });
    return response;
  };

  return (
    <Dialog
      open={modalExtendFreight.isOpen}
      onOpenChange={modalExtendFreight.onClose}
    >
      <DialogContent>
        <DialogHeader>
          <div className="">
            <h1>Extend Freight</h1>
            <div className="flex flex-col space-y-4">
              <h1>How long should this shipment stay open for quotes?</h1>
              <Form {...extendFreight}>
                <form
                  className="flex flex-col space-y-3"
                  onSubmit={extendFreight.handleSubmit(onSubmit)}
                >
                  <Controller
                    name="extendPeriod"
                    control={extendFreight.control}
                    render={({ field }) => (
                      <Select
                        onValueChange={(val) => {
                          extendFreight.setValue("extendPeriod", val);
                        }}
                        {...field}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select" />
                        </SelectTrigger>
                        <SelectContent>
                          {expireQuoteDays?.data.map(
                            (expireQuote: {
                              id: number;
                              name: string;
                              value: string;
                            }) => (
                              <SelectItem value={expireQuote.value}>
                                {expireQuote.name}
                              </SelectItem>
                            )
                          )}
                        </SelectContent>
                      </Select>
                    )}
                  />
                  <div className="flex  justify-end items-end space-x-2 border-t-2 pt-4">
                    <Button
                      onClick={() => {
                        modalExtendFreight.onClose();
                      }}
                      variant={"outline"}
                    >
                      Close
                    </Button>
                    <Button variant={"freightBlue"}>Extend</Button>
                  </div>
                </form>
              </Form>
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalExtendFreight;
