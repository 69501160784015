import { useSubmitReview } from "src/hooks/use-submit-review";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { Button } from "../ui/button";
import { useState } from "react";
import { Rating, Star } from "@smastrom/react-rating";
import "@smastrom/react-rating/style.css";
import { Textarea } from "../ui/textarea";
import { BASE_URL, SUBMIT_REVIEW } from "src/api/config";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import toast from "react-hot-toast";

interface ModalSubmitReviewProps {
  refetch: () => void;
  refetchReviews: () => void;
}

const ModalSubmitReview: React.FC<ModalSubmitReviewProps> = ({
  refetch,
  refetchReviews,
}) => {
  const modalSubmitReview = useSubmitReview();
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const [ratingService, setRatingService] = useState(0);
  const [ratingCommunication, setRatingCommunication] = useState(0);
  const [ratingCare, setRatingCare] = useState(0);
  const [ratingPunctuality, setRatingPunctuality] = useState(0);
  const [ratingCustomerService, setRatingCustomerService] = useState(0);
  const [review, setReview] = useState("");

  const starStyles = {
    itemShapes: Star,
    activeFillColor: "#ffb700",
    inactiveFillColor: "#fbf1a9",
  };

  const submitReview = async () => {
    const response = await fetch(`${BASE_URL}${SUBMIT_REVIEW}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        booking_id: modalSubmitReview.bookingId,
        rating_service: ratingService,
        rating_communication: ratingCommunication,
        rating_care: ratingCare,
        rating_punctuality: ratingPunctuality,
        rating_customer_service: ratingCustomerService,
        review: review,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Review submitted successfully.") {
          modalSubmitReview.onClose();
          toast.success(data.message);
          setRatingCare(0);
          setRatingCommunication(0);
          setRatingCustomerService(0);
          setRatingPunctuality(0);
          setRatingService(0);
          setReview("");
          refetch();
          refetchReviews();
        }
      });

    return response;
  };

  return (
    <Dialog
      open={modalSubmitReview.isOpen}
      onOpenChange={modalSubmitReview.onClose}
    >
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-start">
            <div className="flex flex-col items-center space-y-2 mb-10 font-semibold">
              Add Review
            </div>
          </div>
        </DialogHeader>
        <div className="flex items-center justify-between">
          <h1 className="text-sm font-medium text-gray-400">Service</h1>
          <Rating
            itemStyles={starStyles}
            style={{ maxWidth: 150 }}
            value={ratingService}
            onChange={setRatingService}
          />
        </div>
        <div className="flex items-center justify-between">
          <h1 className="text-sm font-medium text-gray-400">Care</h1>
          <Rating
            itemStyles={starStyles}
            style={{ maxWidth: 150 }}
            value={ratingCare}
            onChange={setRatingCare}
          />
        </div>
        <div className="flex items-center justify-between">
          <h1 className="text-sm font-medium text-gray-400">Communication</h1>
          <Rating
            itemStyles={starStyles}
            style={{ maxWidth: 150 }}
            value={ratingCommunication}
            onChange={setRatingCommunication}
          />
        </div>
        <div className="flex items-center justify-between">
          <h1 className="text-sm font-medium text-gray-400">Punctuality</h1>
          <Rating
            itemStyles={starStyles}
            style={{ maxWidth: 150 }}
            value={ratingPunctuality}
            onChange={setRatingPunctuality}
          />
        </div>
        <div className="flex items-center justify-between">
          <h1 className="text-sm font-medium text-gray-400">
            Customer Service
          </h1>
          <Rating
            itemStyles={starStyles}
            style={{ maxWidth: 150 }}
            value={ratingCustomerService}
            onChange={setRatingCustomerService}
          />
        </div>
        <div className="flex flex-col justify-between mt-4">
          <h1 className="text-sm font-medium pb-3">Tell us what you think</h1>
          <Textarea
            value={review}
            onChange={(e) => setReview(e.target.value)}
          />
        </div>
        <div className="w-full justify-end items-end flex space-x-2">
          <Button
            onClick={() => {
              modalSubmitReview.onClose();
            }}
            variant={"outline"}
          >
            Back
          </Button>
          <Button onClick={() => submitReview()} variant={"freightBlue"}>
            Submit review
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalSubmitReview;
