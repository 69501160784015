import { useNavigate } from "react-router-dom";
//@ts-ignore
import NotFoundImage from "../assets/images/New Lottie.gif";
import Header from "../components/header/Header";
import { Button } from "../components/ui/button";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen w-screen ">
      <Header />
      <div className="flex flex-col items-center justify-center">
        <img
          src={NotFoundImage}
          className=" w-[900px] object-contain"
          alt="not-found"
        />
        <Button
          onClick={() => navigate("/")}
          className="bg-freightBlueDark"
          variant={"freightBlue"}
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
