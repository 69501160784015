import { Loader2 } from "lucide-react"; // Spinner for the button
import React, { useState } from "react";
import { GOOGLE_API_KEY } from "../../../api/config";

interface FreightDogsSummaryProps {
  formData: {
    description: string;
    origin_address: string;
    destination_address: string;
    start_date: string;
    end_date: string;
    expire_date: string;
    origin_location_type: string;
    origin_latitude: any;
    origin_longitude: any;
    destination_latitude: any;
    destination_longitude: any;
    delivery_distance: any;
    delivery_duration: any;
    animal_img: any;
  };
  onCreate: () => void;
  onEdit: () => void;
  imagePreview: string | null;
}

const FreightDogsSummary: React.FC<FreightDogsSummaryProps> = ({
  formData,
  onCreate,
  onEdit,
  imagePreview,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleCreate = async () => {
    setIsLoading(true);
    try {
      await onCreate();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto relative">
      <h3 className="text-xl font-semibold pb-4">
        Review Your Freight Details
      </h3>
      <div className="bg-white border shadow-lg rounded-lg overflow-hidden">
        <div className="flex items-start p-6">
          <div className="w-1/2">
            <div className="relative mb-4">
              <img
                src={imagePreview ?? undefined}
                alt="Preview"
                className="h-48 w-48 object-cover border rounded-md cursor-pointer"
              />
              <p className="text-gray-700 mb-6">
                <strong>Description:</strong> {formData.description}
              </p>
              <div className="bg-gray-50 p-4 rounded-lg shadow-md mb-4">
                <div className="border-b pb-3 mb-3">
                  <p className="text-gray-700 font-semibold">From:</p>
                  <p className="text-gray-600">{formData.origin_address}</p>
                  <p className="text-sm text-gray-500">{formData.start_date}</p>
                </div>
                <div>
                  <p className="text-gray-700 font-semibold">To:</p>
                  <p className="text-gray-600">
                    {formData.destination_address}
                  </p>
                  <p className="text-sm text-gray-500">{formData.end_date}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/2 h-96 ml-10">
            <div className="w-full h-full rounded-lg shadow-md overflow-hidden">
              <Map
                origin={{
                  lat: formData.origin_latitude,
                  lng: formData.origin_longitude,
                }}
                destination={{
                  lat: formData.destination_latitude,
                  lng: formData.destination_longitude,
                }}
              />
            </div>
            <div className="mt-2 pt-2 border-t">
              <p>
                <strong>Delivery Distance:</strong> Aprox.{" "}
                {formData.delivery_distance}
              </p>
              <p>
                <strong>Delivery Duration:</strong> Aprox.{" "}
                {formData.delivery_duration}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-4 mt-4">
        <button
          onClick={onEdit}
          className="p-2 bg-gray-400 text-white rounded-md"
        >
          Edit
        </button>
        <button
          onClick={handleCreate}
          className="p-2 w-[150px] bg-[#1889ca] text-white rounded-md flex items-center justify-center"
          disabled={isLoading}
        >
          {isLoading ? <Loader2 className="animate-spin" /> : "Create"}
        </button>
      </div>
    </div>
  );
};

const Map: React.FC<{
  origin: { lat: number; lng: number };
  destination: { lat: number; lng: number };
}> = ({ origin, destination }) => {
  const googleMapsApiKey = GOOGLE_API_KEY; // Reemplaza con tu API key

  return (
    <div className="w-full h-full flex justify-center items-center">
      <iframe
        className="w-[950px] h-full rounded-md"
        frameBorder="0"
        src={`https://www.google.com/maps/embed/v1/directions?key=${googleMapsApiKey}&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}`}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default FreightDogsSummary;
