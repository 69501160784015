import { useNavigate } from "react-router-dom";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL, ORDERS_TRANSPORTER } from "../api/config";
import SidebarLayout from "../components/layout/SidebarLayout";
import { ArrowLeft, ArrowRight, Loader2, RouteOff } from "lucide-react";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { OrdersEcom } from "../types/types";

import CarrierB2BTripCard from "../components/card/CarrierB2BTripCard";
import { useViewItemsOrder } from "../hooks/use-view-items-order";
import ModalViewItemsOrder from "../components/modal/ModalViewItemsOrder";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { Button } from "../components/ui/button";
import { cn } from "../lib/utils";

const CarrierB2bTrips = () => {
  const navigate = useNavigate();
  const { user_type, user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("desc");
  const modalViewItemsOrder = useViewItemsOrder();

  const sortOptions = [
    {
      value: "ASC",
      label: "Ascendent",
    },
    {
      value: "DESC",
      label: "Descendent",
    },
  ];

  const getOrdersTransporter = async () => {
    const response = await fetch(
      `${BASE_URL}${ORDERS_TRANSPORTER}?user_id=${user_id}&date_sorted=${sortBy}&page=${page}&items_per_page=10`,
      {
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const {
    data: ordersTransporter,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["orders_transporter", sortBy, page],
    queryFn: getOrdersTransporter,
  });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <SidebarLayout>
      <div className="flex items-center w-full justify-between border-b border-border pb-3 mb-4">
        <h1 className="text-lg font-semibold">Ecommerce Trips</h1>
      </div>
      {isLoading ? (
        <div className="bg-white shadow h-20 w-full flex items-center justify-center">
          <Loader2 className="animate-spin h-5 w-5" />
        </div>
      ) : (
        <>
          {ordersTransporter?.message === "No orders found for this carrier" ? (
            <div className="bg-white shadow rounded p-4 flex items-center justify-center">
              <RouteOff className="h-5 w-5 text-gray-500" />
              <p className="text-sm font-medium text-gray-500 ml-2">
                {ordersTransporter.message}
              </p>
            </div>
          ) : (
            <>
              <div className="mt-4 ml-auto w-full justify-end sm:flex space-x-2 mb-4">
                <div className="text-sm font-medium flex items-center space-x-1">
                  <h1>
                    Total{" "}
                    {!isLoading ? (
                      <span>{ordersTransporter?.data?.orders?.length}</span>
                    ) : (
                      <span>
                        <Loader2 className="h-4 w-4 animate-spin" />
                      </span>
                    )}
                  </h1>

                  <h1>Ecom Orders</h1>
                </div>
                <Select
                  onValueChange={(val: any) => {
                    setSortBy(val.toLowerCase());
                  }}
                >
                  <SelectTrigger className="w-40 h-8">
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent>
                    {sortOptions.map((sort) => (
                      <SelectItem value={sort.value}>{sort.label}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <div className="flex space-x-2">
                  <Button
                    disabled={page === 1}
                    onClick={() => setPage(1)}
                    className={cn(
                      "h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                      page === 1 && "bg-[#333] text-white"
                    )}
                  >
                    <p className="text-sm font-medium">1</p>
                  </Button>
                  <div
                    onClick={() => {
                      if (
                        page > 1 &&
                        page !== ordersTransporter?.data?.total_pages
                      ) {
                        setPage((page) => page - 1);
                      }
                    }}
                    className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
                  >
                    <ArrowLeft className="h-4 w-4 text-[#333]" />
                  </div>
                  <div
                    onClick={() => {
                      if (page !== ordersTransporter?.data.total_pages) {
                        setPage((page) => page + 1);
                      }
                    }}
                    className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
                  >
                    <ArrowRight className="h-4 w-4 text-[#333]" />
                  </div>

                  <Button
                    disabled={page === ordersTransporter?.data?.total_pages}
                    onClick={() =>
                      setPage(ordersTransporter?.data?.total_pages)
                    }
                    className={cn(
                      "h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                      page === ordersTransporter?.data?.total_pages &&
                        "bg-[#333] text-white"
                    )}
                  >
                    {isLoading ? (
                      <p>
                        <Loader2 className="h-5 w-5 animate-spin" />
                      </p>
                    ) : (
                      <p className="text-sm font-medium">
                        {ordersTransporter?.data?.total_pages}
                      </p>
                    )}
                  </Button>
                </div>
              </div>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Order Id</TableHead>
                    <TableHead>Origin/Destination</TableHead>
                    <TableHead>Created at</TableHead>
                    <TableHead>Number of Products</TableHead>
                    <TableHead>Price</TableHead>
                    <TableHead>Status</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {ordersTransporter?.data?.orders.map((order: OrdersEcom) => (
                    <CarrierB2BTripCard order={order} />
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </>
      )}
      {modalViewItemsOrder.isOpen && <ModalViewItemsOrder />}
    </SidebarLayout>
  );
};

export default CarrierB2bTrips;
