import { create } from "zustand";

export type ModalType = "submitInstantReview";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  instantFreightId: string;
  carrierId: string;
  onOpen: (
    type: ModalType,
    instantFreightId: string,
    carrierId: string
  ) => void;
  onClose: () => void;
}

export const useSubmitInstantReview = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  instantFreightId: "",
  carrierId: "",
  onOpen: (type, instantFreightId, carrierId) =>
    set({ isOpen: true, type, instantFreightId, carrierId }),
  onClose: () => set({ type: null, isOpen: false }),
}));
