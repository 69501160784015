import { useQuery } from "@tanstack/react-query";
import { BASE_URL, DRIVERS_LIST, FLEET_DRIVERS } from "../api/config";
import Header from "../components/header/Header";
import CustomerSidebar from "../components/sidebar/CustomerSidebar";
import Sidebar from "../components/sidebar/Sidebar";
import { Button } from "../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { useAddCarrierDrivers } from "../hooks/use-add-carrier-drivers";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { useEffect } from "react";
import ModalAddDrivers from "../components/modal/ModalAddDrivers";
import { DriverType } from "../types/types";
import { useEditDriver } from "../hooks/use-edit-driver";
import ModalEditDriver from "../components/modal/ModalEditDriver";
import { useDeleteDrivers } from "../hooks/use-delete-driver";
import ModalDeleteDrivers from "../components/modal/ModalDeleteDrivers";

const CarrierDrivers = () => {
  const { user_type, user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const modalAddDrivers = useAddCarrierDrivers();
  const modalEditDrivers = useEditDriver();
  const modalDeleteDrivers = useDeleteDrivers();

  const fleetDriversFormData = new FormData();

  fleetDriversFormData.append("user_id", user_id);

  const getFleetDrivers = async () => {
    const response = await fetch(`${BASE_URL}${FLEET_DRIVERS}`, {
      method: "POST",
      headers: {
        AccessToken: token,
      },
      body: fleetDriversFormData,
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const driversListData = new FormData();
  driversListData.append("user_id", user_id);

  const getDriverList = async () => {
    const response = await fetch(`${BASE_URL}${DRIVERS_LIST}`, {
      method: "POST",
      headers: {
        AccessToken: token,
      },
      body: driversListData,
    }).then((res) => res.json());

    return response;
  };

  const { data: driversList, refetch: refetchDrivers } = useQuery({
    queryKey: ["driver_list"],
    queryFn: getDriverList,
  });

  const { data: fleetDrivers } = useQuery({
    queryKey: ["fleet_drivers"],
    queryFn: getFleetDrivers,
  });

  return (
    <div>
      <Header />
      <div className="grid grid-cols-7 mt-20 px-10">
        <div className="col-span-2">
          {user_type === "customer" ? <CustomerSidebar /> : <Sidebar />}
        </div>
        <div className="col-span-5">
          <div className="max-w-4xl mr-auto">
            <h1 className="text-lg font-semibold">Manage Driver</h1>
            <div className="flex items-center justify-end mb-4">
              <Button
                variant={"freightBlue"}
                onClick={() => modalAddDrivers.onOpen("addCarrierDrivers")}
              >
                Add driver
              </Button>
            </div>
            <Table className="border border-border">
              <TableHeader>
                <TableRow>
                  <TableHead>Photo</TableHead>
                  <TableHead>Driver Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Phone No</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {driversList?.data[0].map((driver: DriverType) => (
                  <TableRow>
                    <TableCell>
                      <img
                        src={require("../assets/user-icon.png")}
                        className="h-20 w-20 object-contain"
                      />
                    </TableCell>
                    <TableCell>
                      {driver.firstname} {driver.lastname}
                    </TableCell>
                    <TableCell>{driver.email}</TableCell>
                    <TableCell>{driver.phoneNumber}</TableCell>
                    <TableCell>{driver.status}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() =>
                          modalEditDrivers.onOpen("editDriver", driver)
                        }
                        className="w-20"
                        variant={"freightBlue"}
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() =>
                          modalDeleteDrivers.onOpen(
                            "deleteDrivers",
                            driver.id.toString()
                          )
                        }
                        variant={"outline"}
                        className="w-20 mt-2"
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
      {modalAddDrivers.isOpen && (
        <ModalAddDrivers refetchDrivers={refetchDrivers} />
      )}
      {modalEditDrivers.isOpen && (
        <ModalEditDriver refetchDrivers={refetchDrivers} />
      )}
      {modalDeleteDrivers.isOpen && (
        <ModalDeleteDrivers refetchDrivers={refetchDrivers} />
      )}
    </div>
  );
};

export default CarrierDrivers;
