import React, { useEffect, useRef, useState } from "react";
import "./ImageCarousel.css";

const ImageCarousel = ({ images }: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = useRef<any>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [images]);
  // const startAutoSlide = () => {
  //   timeoutRef.current = setTimeout(() => {
  //     setCurrentIndex((prevIndex) =>
  //       prevIndex === images.length - 1 ? 0 : prevIndex + 1
  //     );
  //   }, 3000);
  // };
  // const stopAutoSlide = () => {
  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //     timeoutRef.current = null;
  //   }
  // };

  // useEffect(() => {
  //   startAutoSlide();
  //   return () => stopAutoSlide();
  // }, [images]);

  return (
    <div className="image-carousel">
      <div className="carousel-container">
        <div className="carousel-image">
          <div
            className="carousel-slide"
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
              transition: "transform 0.5s ease-in-out",
            }}
          >
            {images.map((image: any, index: any) => (
              <img key={index} src={image} alt={`Slide ${index + 1}`} />
            ))}
          </div>
          <div className="carousel-dots">
            {images.map((_: any, index: any) => (
              <div
                key={index}
                className={`carousel-dot ${
                  index === currentIndex ? "active" : ""
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
