import { useQuery } from "@tanstack/react-query";
import { Mail } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { BASE_URL, GET_INBOX, RECENT_BOOKING_QUOTES } from "src/api/config";
import { useCancelBookingQuote } from "src/hooks/use-cancel-booking-quote";
import { cn } from "src/lib/utils";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import {
  MessageType,
  RecentBookingType,
  RecentQuoteType,
} from "src/types/types";
import SidebarLayout from "../layout/SidebarLayout";
import { Button } from "../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";

import { useAtom } from "jotai";
import { useEffect } from "react";
import { hasLoggedIn } from "src/atoms/atoms";
//@ts-ignore
import { ReactComponent as EmptySvg } from "../../assets/images/data-not-found.svg";
import { Skeleton } from "../skeleton/Skeleton";

const MainContainer = () => {
  const { user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const navigate = useNavigate();
  const modalCancelBookingQuote = useCancelBookingQuote();
  const [isLoggedIn, setIsLoggedIn] = useAtom(hasLoggedIn);

  const getInbox = async () => {
    const response = await fetch(`${BASE_URL}${GET_INBOX}`, {
      method: "POST",
      body: JSON.stringify({
        user_id: user_id,
        status: "unread",
      }),
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
    }).then((res) => res.json());
    return response;
  };

  const { data: messagesInbox, isLoading } = useQuery({
    queryKey: ["inbox"],
    queryFn: getInbox,
  });

  useEffect(() => {
    if (
      !isLoading &&
      messagesInbox.message === "Invalid access_token provided." &&
      !isLoggedIn
    ) {
      navigate("/sign-in");
    }
  }, [messagesInbox, isLoading, isLoggedIn]);

  const myRecentBookingQuotes = async () => {
    const response = await fetch(`${BASE_URL}${RECENT_BOOKING_QUOTES}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id,
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const { data: recentBookings, isLoading: isLoadingRecentQuotes } = useQuery({
    queryKey: ["booking_quotes"],
    queryFn: myRecentBookingQuotes,
  });

  return (
    <SidebarLayout>
      <div className="flex items-center justify-between w-full border-b border-border pb-3 mb-4">
        <h1 className="text-lg font-semibold">Dashboard</h1>
      </div>
      <div className="flex items-center justify-between ">
        <h1 className="text-sm font-semibold  pb-3 mb-4">
          Recent Unread Messages
        </h1>
      </div>
      <div className=" bg-white shadow ">
        {isLoading ? (
          <Table className="border border-gray-200 bg-white shadow-md">
            <TableHeader>
              <TableRow>
                <TableHead className="bg-gray-100 text-gray-600">
                  From
                </TableHead>
                <TableHead className="bg-gray-100 text-gray-600">
                  Subject
                </TableHead>
                <TableHead className="bg-gray-100 text-gray-600">
                  Received
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Skeleton className="h-5 w-20" />
                </TableCell>

                <TableCell>
                  <Skeleton className="h-5 w-20" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-5 w-20" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <>
            {messagesInbox?.data && (
              <Table className="border border-border">
                <TableHeader>
                  <TableRow>
                    <TableHead>From</TableHead>
                    <TableHead>Subject</TableHead>
                    <TableHead>Received</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {messagesInbox?.data?.map((message: MessageType) => (
                    <TableRow>
                      <TableCell>
                        <div className="flex items-center space-x-2">
                          <Mail className="h-4 w-4" />
                          <p className="font-semibold text-sm">Hector</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <p
                          onClick={() =>
                            navigate(`/myInbox/showdetail/${message.id}`)
                          }
                          className={cn(
                            message.status === "unread"
                              ? "text-freightBlue"
                              : "text-gray-400",
                            "text-sm font-medium cursor-pointer hover:underline"
                          )}
                        >
                          {message.subject}
                        </p>
                      </TableCell>
                      <TableCell>
                        <p className="font-medium">{message.date}</p>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </>
        )}

        {messagesInbox?.message === "My inbox." && !messagesInbox?.data && (
          <div className=" h-full w-full flex flex-col items-center justify-center py-2">
            <EmptySvg />

            <p className="text-sm font-medium text-gray-500">
              No recent booking quotes found.
            </p>
          </div>
        )}
      </div>
      <h1 className="text-sm font-semibold pb-3 mt-10 border-b mb-4">
        Recent Quotes
      </h1>
      {isLoadingRecentQuotes ? (
        <div className="bg-white shadow">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>My Quote</TableHead>
                <TableHead>Freight</TableHead>
                <TableHead>Quote Date</TableHead>
                <TableHead>Quote Status</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Skeleton className="h-5 w-20" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-5 w-20" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-5 w-20" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-5 w-20" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      ) : (
        <>
          <div className=" bg-white shadow ">
            {recentBookings?.data?.myRecentQuotes?.length > 0 && (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>My Quote</TableHead>
                    <TableHead>Freight</TableHead>
                    <TableHead>Quote Date</TableHead>
                    <TableHead>Quote Status</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {recentBookings?.data?.myRecentQuotes.map(
                    (quote: RecentQuoteType) => (
                      <TableRow>
                        <TableCell>{quote.amount}€</TableCell>
                        <TableCell>
                          <p
                            onClick={() =>
                              navigate(`/quote/viewDetails/${quote.quote_id}`)
                            }
                            className="hover:underline text-freightBlueDark cursor-pointer"
                          >
                            {quote.title}
                          </p>
                        </TableCell>
                        <TableCell>{quote.quotedate}</TableCell>
                        <TableCell>
                          <p
                            className={cn(
                              "text-sm font-medium w-40 text-white text-center py-2 capitalize rounded",
                              quote.status === "active" && "bg-statusCreated",
                              quote.status === "accepted" && "bg-statusPaid"
                            )}
                          >
                            {quote.status}
                          </p>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            )}
            {recentBookings?.message === "No records were found." && (
              <div className=" h-full w-full flex flex-col items-center justify-center py-2">
                <EmptySvg />

                <p className="text-sm font-medium text-gray-500">
                  No recent booking quotes found.
                </p>
              </div>
            )}
          </div>
        </>
      )}

      <div className="flex items-center justify-between mt-10 border-b mb-4">
        <h1 className="text-sm font-semibold  pb-3 mb-4">My Bookings</h1>
      </div>
      <div className="  bg-white shadow   w-full">
        {recentBookings?.message === "No records were found." && (
          <div className=" h-full w-full flex flex-col items-center justify-center py-2">
            <EmptySvg />
            <p className="text-sm font-medium text-gray-500">
              No bookings found
            </p>
          </div>
        )}
        {isLoadingRecentQuotes ? (
          <div className="bg-white shadow">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Quote Amount</TableHead>
                  <TableHead>Freight</TableHead>
                  <TableHead>Customer</TableHead>
                  <TableHead>Date Booked</TableHead>
                  <TableHead>Status / Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Skeleton className="h-5 w-20" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-20" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-20" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-20" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-20" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ) : (
          <>
            {recentBookings?.data?.myRecentBooking?.length > 0 && (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Quote Amount</TableHead>
                    <TableHead>Freight</TableHead>
                    <TableHead>Customer</TableHead>
                    <TableHead>Date Booked</TableHead>
                    <TableHead>Status / Action</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {recentBookings?.data?.myRecentBooking.map(
                    (booking: RecentBookingType) => (
                      <TableRow>
                        <TableCell>
                          {Number(booking.quote_amount).toFixed(2)}€
                        </TableCell>
                        <TableCell>{booking.title}</TableCell>
                        <TableCell>{booking.customer_email}</TableCell>
                        <TableCell>{booking.date_booked}</TableCell>
                        <TableCell>
                          <div className="flex flex-col items-center space-y-3">
                            <p className="text-sm font-semibold text-right">
                              {booking.fstatus === "DCC" && "Completed"}
                              {booking.fstatus === "FD" && "Deleted"}
                              {booking.fstatus === "QR" && "Quote Ready"}
                              {booking.fstatus === "FBCC" &&
                                "Freight Cancelled"}
                              {booking.fstatus === "FB" && "Freight Booked"}
                            </p>
                            <Button className="w-28" variant={"freightBlue"}>
                              Edit
                            </Button>
                            <Button
                              onClick={() =>
                                modalCancelBookingQuote.onOpen(
                                  "cancelBookingQuote",
                                  booking.booking_id.toString()
                                )
                              }
                              className="w-28"
                              variant={"outline"}
                            >
                              Cancel
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            )}
          </>
        )}
      </div>
    </SidebarLayout>
  );
};

export default MainContainer;
