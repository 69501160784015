import { useViewQuoteDetails } from "src/hooks/use-view-quote";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useState } from "react";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";

const formSendMessageSchema = z.object({
  message: z.string().min(1),
});

const ModalViewQuoteDetails = () => {
  const modal = useViewQuoteDetails();
  const navigate = useNavigate();

  return (
    <Dialog open={modal.isOpen} onOpenChange={modal.onClose}>
      <DialogContent>
        <DialogHeader>
          <div>
            <h1 className="text-lg font-medium border-b-2 border-border mb-2">
              Quote Details
            </h1>
            <div className="">
              <div className="flex flex-col ">
                <div className="flex items-center border-b py-2 justify-between">
                  <h1 className="text-sm font-medium">Quote Amount:</h1>
                  <p className="text-sm font-medium text-gray-500">
                    {Number(modal.data?.amount).toFixed(2)}€
                  </p>
                </div>
                <div className="flex items-center border-b py-2 justify-between">
                  <h1 className="text-sm font-medium">Quote Date:</h1>
                  <p className="text-sm font-medium text-gray-500 capitalize">
                    {modal.data?.pickup_date1}
                  </p>
                </div>
                <div className="flex items-center border-b py-2 justify-between">
                  <h1 className="text-sm font-medium">Quote Expiry Date:</h1>
                  <p className="text-sm font-medium text-gray-500 capitalize">
                    {modal.data?.expire_date}
                  </p>
                </div>
                <div className="flex items-center border-b py-2 justify-between">
                  <h1 className="text-sm font-medium">Quote Status:</h1>
                  <p className="text-sm font-medium text-gray-500 capitalize">
                    {modal.data?.status}
                  </p>
                </div>
                <div className="flex items-center border-b py-2 justify-between">
                  <h1 className="text-sm font-medium">Shipment Detail:</h1>
                  <p className="text-sm font-medium text-gray-500 capitalize">
                    {modal.data?.frt_title}
                  </p>
                </div>
                <div className="flex items-center border-b py-2 justify-between">
                  <h1 className="text-sm font-medium">Vehicle Type:</h1>
                  <p className="text-sm font-medium text-gray-500 capitalize">
                    {modal.data?.vehicle_type}
                  </p>
                </div>
                <div className="border-b py-2">
                  <div className="flex items-center justify-between">
                    <h1 className="text-sm font-medium">Available Pickup:</h1>
                    <p className="text-sm font-medium text-gray-500 capitalize">
                      {modal.data?.pickup_at}
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <h1 className="text-sm font-medium">Date:</h1>
                    <p className="text-sm font-medium text-gray-500  capitalize">
                      {modal.data?.pickup_date1}
                    </p>
                  </div>
                </div>
                <div className="border-b py-2">
                  <div className="flex items-center justify-between">
                    <h1 className="text-sm font-medium">Available Dropoff:</h1>
                    <p className="text-sm font-medium text-gray-500  capitalize">
                      {modal.data?.dropoff_at}
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <h1 className="text-sm font-medium">Date:</h1>
                    <p className="text-sm font-medium text-gray-500  capitalize">
                      {modal.data?.dropoff_date1}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between border-b py-2">
                  <h1 className="text-sm font-medium">
                    Other Quote Information:
                  </h1>
                  <p className="text-sm font-medium text-gray-500  capitalize">
                    {modal.data?.details}
                  </p>
                </div>
                <div className="mt-10 w-full flex items-end">
                  <Button
                    className="justify-end ml-auto"
                    variant={"freightBlue"}
                    onClick={() =>
                      navigate(
                        `/customer/quoteDetails/${modal.data?.freightid}/${modal.data?.id}`
                      )
                    }
                  >
                    View in detail
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalViewQuoteDetails;
