import { useTokenStoreStorage } from "src/store/useTokenStore";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { useCompleteBooking } from "src/hooks/use-complete-booking";
import { Check } from "lucide-react";
import { Button } from "../ui/button";
import { BASE_URL, JOB_COMPLETE } from "src/api/config";
import { userInfoUserStore } from "src/store/useUserInfoStore";

interface ModalCompleteBookingProps {
  refetch: () => void;
}

const ModalCompleteBooking: React.FC<ModalCompleteBookingProps> = ({
  refetch,
}) => {
  const { user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const modal = useCompleteBooking();

  const completeJob = async () => {
    const response = await fetch(`${BASE_URL}${JOB_COMPLETE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        booking_status: "completed",
        bookingId: modal.bookingId,
        completed_by: "customer",
        job_completed: true,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Job completed successfully.") {
          modal.onClose();
          refetch();
        }
      });

    return response;
  };
  return (
    <Dialog open={modal.isOpen} onOpenChange={modal.onClose}>
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-center">
            <div className="h-20 w-20 border border-border rounded-full flex items-center justify-center">
              <Check className="h-10 w-10 text-green-500" />
            </div>
            <div className="mt-4 flex flex-col items-center pb-10">
              <h1 className="text-xl font-medium">Are you sure? </h1>
              <p className="text-lg">You want to complete this booking</p>
            </div>
          </div>
        </DialogHeader>
        <div className="w-full justify-end items-end flex space-x-2">
          <Button onClick={() => modal.onClose()} variant={"outline"}>
            Cancel
          </Button>
          <Button onClick={() => completeJob()} variant={"freightBlue"}>
            Ok
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalCompleteBooking;
