import { useQuery } from "@tanstack/react-query";
import { ArrowLeft, ArrowRight, Loader2 } from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, GET_IMAGE, TRANSPORTERBOOKINGSLIST } from "../api/config";
import small from "../assets/images/books-box.png";
import large from "../assets/images/moving-box.png";
import medium from "../assets/images/shoes-box.png";
import SidebarLayout from "../components/layout/SidebarLayout";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { cn } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { BookingType } from "../types/types";

const CarrierBookings = () => {
  const { user_type, user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const navigate = useNavigate();
  const params = useParams();
  const [sortBy, setSortBy] = useState("desc");
  const [page, setPage] = useState(1);

  const getBookingsTransporter = async () => {
    const response = await fetch(`${BASE_URL}${TRANSPORTERBOOKINGSLIST}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        status: params.type,
        page: page,
        items_per_page: 5,
        sort: sortBy,
      }),
    }).then((res) => res.json());
    return response;
  };

  const { data: bookingsTransporter, isLoading } = useQuery({
    queryKey: ["bookings_transporter", params.type, page, sortBy],
    queryFn: getBookingsTransporter,
  });
  const sortOptions = [
    {
      value: "ASC",
      label: "Ascendent",
    },
    {
      value: "DESC",
      label: "Descendent",
    },
  ];

  return (
    <SidebarLayout>
      <div className="">
        <div className="flex items-center justify-between w-full border-border border-b pb-3 space-x-2 mb-4">
          <h1 className="text-lg font-semibold">Bookings</h1>
          <p className="bg-gray-100 px-4 py-2 capitalize text-sm font-medium">
            {params.type!.slice(0, 1).toUpperCase() +
              params.type!.slice(1).toLowerCase()}
          </p>
        </div>
        {bookingsTransporter?.message !== "No records were found." && (
          <div className="mt-4 ml-auto w-full justify-end flex space-x-2 mb-4">
            <>
              <div className="text-sm font-medium flex items-center space-x-1">
                <h1>Total</h1>
                {!isLoading ? (
                  <span>{bookingsTransporter?.data?.total_items}</span>
                ) : (
                  <span>
                    <Loader2 className="h-4 w-4 animate-spin" />
                  </span>
                )}
                <h1>Bookings</h1>
              </div>
            </>
            <Select
              onValueChange={(val: any) => {
                setSortBy(val);
              }}
              value={sortBy}
            >
              <SelectTrigger className="w-40 h-8">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent>
                {sortOptions.map((sort) => (
                  <SelectItem value={sort.value}>{sort.label}</SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Button
              disabled={page === 1}
              onClick={() => setPage(1)}
              className={cn(
                "h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                page === 1 && "bg-[#333] text-white"
              )}
            >
              <p className="text-sm font-medium">1</p>
            </Button>
            <div
              onClick={() => {
                if (
                  page > 1
                  // page !== bookingCustomer?.data?.total_pages
                ) {
                  setPage((page) => page - 1);
                }
              }}
              className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
            >
              <ArrowLeft className="h-4 w-4 text-[#333]" />
            </div>
            <div
              onClick={() => {
                if (page !== bookingsTransporter?.data.total_pages) {
                  setPage((page) => page + 1);
                }
              }}
              className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
            >
              <ArrowRight className="h-4 w-4 text-[#333]" />
            </div>
            <Button
              disabled={page === bookingsTransporter?.data?.total_pages}
              onClick={() => setPage(bookingsTransporter?.data?.total_pages)}
              className={cn(
                "h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                page === bookingsTransporter?.data?.total_pages &&
                  "bg-[#333] text-white"
              )}
            >
              {isLoading ? (
                <p>
                  <Loader2 className="h-5 w-5 animate-spin" />
                </p>
              ) : (
                <p className="text-sm font-medium">
                  {bookingsTransporter?.data?.total_pages}
                </p>
              )}
            </Button>
          </div>
        )}
        <Table className="border border-border bg-white shadow">
          <TableHeader>
            <TableRow>
              <TableHead>ID</TableHead>
              <TableHead>Freight</TableHead>
              <TableHead>Quote Amount</TableHead>
              <TableHead>Customer</TableHead>
              <TableHead>Date Booked</TableHead>
              <TableHead>Status</TableHead>
            </TableRow>
          </TableHeader>
          {isLoading ? (
            <TableBody>
              <TableRow>
                <TableCell>
                  <Skeleton className="h-20 w-20 rounded" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-5 w-28 rounded" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-5 w-28 rounded" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-5 w-36 rounded" />
                </TableCell>

                <TableCell>
                  <Skeleton className="h-5 w-40 rounded" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-5 w-10 rounded" />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {bookingsTransporter?.message === "No data found." && (
                <div className="p-4">
                  <p className="text-sm font-medium text-gray-500">
                    No bookings found
                  </p>
                </div>
              )}
              {bookingsTransporter?.data?.data.map((booking: BookingType) => (
                <TableRow key={booking.booking_id}>
                  <TableCell>
                    {booking?.animal_img.length > 0 ? (
                      <img
                        src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${booking?.animal_img[0].freight_img}&image_type=freight`}
                        alt="freight"
                        className=" w-32 object-contain rounded"
                      />
                    ) : (
                      <div className="flex items-center justify-center w-40 h-40 rounded-lg shadow-sm">
                        {booking?.freight_type_info?.freight_size.id === 1 && (
                          <img
                            src={small}
                            className="h-22 w-22 object-contain cursor-pointer"
                            alt="small package"
                            onClick={() =>
                              navigate(
                                `/customer/freightDetails/${booking?.booking_id}`
                              )
                            }
                          />
                        )}
                        {booking?.freight_type_info?.freight_size.id === 2 && (
                          <img
                            src={medium}
                            className="h-22 w-22 object-contain cursor-pointer"
                            alt="medium package"
                            onClick={() =>
                              navigate(
                                `/customer/freightDetails/${booking?.booking_id}`
                              )
                            }
                          />
                        )}
                        {booking?.freight_type_info?.freight_size.id === 3 && (
                          <img
                            src={large}
                            className="h-22 w-22 object-contain cursor-pointer"
                            alt="large package"
                            onClick={() =>
                              navigate(
                                `/customer/freightDetails/${booking?.booking_id}`
                              )
                            }
                          />
                        )}
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <div>
                      <p
                        onClick={() => {
                          navigate(`/carrier/booking/${booking.booking_id}`);
                        }}
                        className="hover:underline text-freightBlueDark cursor-pointer"
                      >
                        {booking.title}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell>
                    {Number(booking.quote_amount).toFixed(2)}€
                  </TableCell>

                  <TableCell>{booking.customer_email}</TableCell>
                  <TableCell>{booking.date_booked}</TableCell>
                  <TableCell>
                    {" "}
                    <div
                      className={cn(
                        "rounded px-4 py-2 w-36 text-sm font-medium ",
                        booking.status === "complete" && "bg-statusConfirmed",
                        // booking.fstatus === "FS" && "bg-gray-700",
                        booking.status === "booked" && "bg-statusAssigned",

                        booking.status === "cancelled" && "bg-gray-500"
                        // booking.fstatus === "FBCC" && "bg-gray-500",
                        // booking.fstatus === "FB" && "bg-statusPickingUp"
                      )}
                    >
                      {/* <p className="text-sm font-medium text-white  text-center">
                        {booking.fstatus === "DCC" && "Completed"}
                        {booking.fstatus === "FD" && "Deleted"}
                        {booking.fstatus === "QR" && "Quote Ready"}
                        {booking.fstatus === "FBCC" && "Freight Cancelled"}
                        {booking.fstatus === "FB" && "Freight Booked"}
                      </p> */}
                      <p className="text-sm font-medium text-white  text-center">
                        {booking.status.toUpperCase()}
                      </p>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </div>
    </SidebarLayout>
  );
};

export default CarrierBookings;
