import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import ShoesBox from "src/assets/images/shoes-box.png";

import { useViewItemsOrder } from "src/hooks/use-view-items-order";
import { cn } from "src/lib/utils";

const ModalViewItemsOrder = () => {
  const modalViewItemsOrder = useViewItemsOrder();

  return (
    <Dialog
      open={modalViewItemsOrder.isOpen}
      onOpenChange={modalViewItemsOrder.onClose}
    >
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-start  w-full space-y-10">
              <div className="flex flex-col items-start w-full py-4">
                <div className="flex items-center justify-between w-full pb-3 border-b">
                  <p className="text-sm font-medium">
                    {" "}
                    Order {modalViewItemsOrder.order?.id}
                  </p>{" "}
                  <p className="font-medium text-gray-400">
                    {modalViewItemsOrder.order?.price}€
                  </p>
                </div>
                <div className="flex items-start justify-between w-full py-2">
                  <div className="flex flex-col items-start  space-y-2">
                    <p className="text-sm text-gray-400">
                      Origin:{" "}
                      <span className="text-[#333]">
                        {modalViewItemsOrder.order?.origin}
                      </span>
                    </p>
                    <p className="text-sm text-gray-400">
                      Destination:{" "}
                      <span className="text-[#333]">
                        {" "}
                        {modalViewItemsOrder.order?.destination}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p className="text-sm">
                      <span className="text-gray-400">Nº of products:</span>{" "}
                      {modalViewItemsOrder.order?.products.length}
                    </p>
                  </div>
                </div>

                <div className={cn("max-h-60 overflow-y-auto  mt-2   w-full ")}>
                  {modalViewItemsOrder.order?.products.map((product) => (
                    <div className="flex items-start justify-between  px-4 py-2  w-ful border-b">
                      <div className="relative h-14 w-24 ">
                        <img
                          alt="package-dimensions"
                          src={ShoesBox}
                          className="h-full w-full"
                        />
                        <p className="text-[10px] absolute right-14 top-10 font-medium">
                          {Math.round(Number(product.width))}cm(w)
                        </p>
                        <p className="text-[10px] absolute right-20 top-4 font-medium">
                          {Math.round(Number(product.height))}cm(h)
                        </p>
                        <p className="text-[10px] absolute right-0 top-10 font-medium">
                          {Math.round(Number(product.length))}cm(l)
                        </p>
                      </div>
                      <div className="">
                        <p className="text-xs"> Quantity: {product.quantity}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalViewItemsOrder;
