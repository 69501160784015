//@ts-ignore
import {
  ArrowLeft,
  ArrowRight,
  ChevronDown,
  Loader2,
  Search,
} from "lucide-react";
import FreigthAllCard from "../components/card/FreightAllCard";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { Input } from "../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { cn } from "../lib/utils";
import { FreightItem } from "../types/types";

//@ts-ignore
import { ReactComponent as EmptySvg } from "../assets/images/data-not-found.svg";
import CategoryFilter from "../components/filters/CategoriesFilter";
import { SearchFreightFreightsProps } from "../types/types";

const sortOptions = [
  {
    id: 1,
    label: "Date Listed (Newest to Oldest)",
  },
  {
    id: 2,
    label: "Date Listed (Oldest to Newest)",
  },
  {
    id: 3,
    label: "Distance (Longest to Shortest)",
  },
  {
    id: 4,
    label: "Distance (Shortest to Longest)",
  },
  {
    id: 5,
    label: "Origin (A to Z)",
  },
  {
    id: 6,
    label: "Origin (Z to A)",
  },
  {
    id: 7,
    label: "Destination (A to Z)",
  },
  {
    id: 8,
    label: "Destination (Z to A)",
  },
];

const SearchFreightFreights: React.FC<SearchFreightFreightsProps> = ({
  allFreight,
  ordersType,
  onFilteredSubChange,
  allCategories,
  onFilteredChange,
  inputValue,
  handleInputChange,
  page,
  setPage,
  isLoading,
}) => {
  return (
    <>
      <div className="  mt-20  max-w-7xl mx-auto">
        {allFreight?.data?.freights?.length === 0 && (
          <div className="pb-4">
            <div className="flex items-center justify-between">
              <div className="flex items-end space-x-4">
                <h1 className="text-xl font-medium">Top Results</h1>
                <p className="text-sm ">Showing 1 - 1 of 0 results</p>
              </div>
            </div>
          </div>
        )}

        <div className="pb-4">
          <div className="flex items-center justify-between">
            <div className="flex items-end space-x-4">
              <h1 className="text-xl font-medium">Top Results</h1>
              <p className="text-sm">
                Showing 1 - {allFreight?.data?.freights?.length || 0} of{" "}
                {allFreight?.data?.total_items || 0}
              </p>
            </div>
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2 ">
                {ordersType === "Freights" && (
                  <div>
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <div className=" h-10  w-60 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 flex items-center justify-between">
                          <p>Freight Category</p>
                          <ChevronDown className="h-5 w-5" />
                        </div>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <CategoryFilter
                          onFilteredSubChange={onFilteredSubChange}
                          categories={allCategories}
                          onFilteredChange={onFilteredChange}
                        />
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                )}
                <Select>
                  <SelectTrigger className="w-60">
                    <SelectValue placeholder="Sort by: Latest" />
                  </SelectTrigger>
                  <SelectContent>
                    {sortOptions.map((sort) => (
                      <SelectItem value={sort.label}>{sort.label}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="relative">
                <Search className="h-4 w-4 absolute top-3 left-2 " />
                <Input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Search for loads"
                  className="pl-10"
                />
              </div>
            </div>
          </div>
          <div className="mt-4 ml-auto w-full justify-end flex space-x-2">
            <Button
              disabled={page === 1}
              onClick={() => setPage(1)}
              className={cn(
                "h-6 w-6 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                page === 1 && "bg-[#333] text-white"
              )}
            >
              <p className="text-sm font-medium">1</p>
            </Button>
            <div
              onClick={() => {
                if (page > 1) {
                  setPage((page) => page - 1);
                }
              }}
              className="h-6 w-6 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
            >
              <ArrowLeft className="h-4 w-4 text-[#333]" />
            </div>
            <div
              onClick={() => setPage((page) => page + 1)}
              className="h-6 w-6 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
            >
              <ArrowRight className="h-4 w-4 text-[#333]" />
            </div>
            <Button
              disabled={page === allFreight?.data?.total_pages}
              onClick={() => setPage(allFreight?.data?.total_pages ?? 1)} // Default to 1 if undefined
              className={cn(
                "h-6 w-6 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                page === allFreight?.data?.total_pages && "bg-[#333] text-white"
              )}
            >
              {isLoading ? (
                <p>
                  <Loader2 className="h-5 w-5 animate-spin" />
                </p>
              ) : (
                <p className="text-sm font-medium">
                  {allFreight?.data?.total_pages}
                </p>
              )}
            </Button>
          </div>
          {isLoading ? (
            <Table className="border border-gray-200 mt-4 bg-white shadow-sm">
              <TableHeader>
                <TableRow>
                  <TableHead className="py-3 px-4 text-left text-sm font-semibold text-gray-600">
                    Image
                  </TableHead>
                  <TableHead className="py-3 px-4 text-left text-sm font-semibold text-gray-600">
                    Freight Id
                  </TableHead>
                  <TableHead className="py-3 px-4 text-left text-sm font-semibold text-gray-600">
                    Freight/Date
                  </TableHead>
                  <TableHead className="py-3 px-4 text-left text-sm font-semibold text-gray-600">
                    Origin/Destination
                  </TableHead>
                  <TableHead className="py-3 px-4 text-left text-sm font-semibold text-gray-600">
                    Est. Duration
                  </TableHead>
                  <TableHead className="py-3 px-4 text-left text-sm font-semibold text-gray-600">
                    Distance
                  </TableHead>
                  <TableHead className="py-3 px-4 text-left text-sm font-semibold text-gray-600">
                    Listed on
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell className="py-4 px-4">
                    <Skeleton className="h-20 w-20 rounded-md bg-gray-200" />
                  </TableCell>
                  <TableCell className="py-4 px-4">
                    <Skeleton className="h-5 w-20 bg-gray-200" />
                  </TableCell>
                  <TableCell className="py-4 px-4">
                    <Skeleton className="h-5 w-20 bg-gray-200" />
                  </TableCell>
                  <TableCell className="py-4 px-4">
                    <Skeleton className="h-5 w-20 bg-gray-200" />
                  </TableCell>
                  <TableCell className="py-4 px-4">
                    <Skeleton className="h-5 w-20 bg-gray-200" />
                  </TableCell>
                  <TableCell className="py-4 px-4">
                    <Skeleton className="h-5 w-10 bg-gray-200" />
                    <Skeleton className="h-5 w-10 mt-4 bg-gray-200" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <>
              {allFreight?.data?.freights &&
              allFreight.data.freights.length > 0 ? (
                <Table className="border border-gray-200 mt-4 bg-white shadow-sm">
                  <TableHeader>
                    <TableRow>
                      <TableHead className="py-3 px-4 text-left text-sm font-semibold text-gray-600">
                        Image
                      </TableHead>
                      <TableHead className="py-3 px-4 text-left text-sm font-semibold text-gray-600">
                        Freight/Date
                      </TableHead>
                      <TableHead className="py-3 px-4 text-left text-sm font-semibold text-gray-600">
                        Origin/Destination
                      </TableHead>
                      <TableHead className="py-3 px-4 text-left text-sm font-semibold text-gray-600">
                        Est. Duration
                      </TableHead>
                      <TableHead className="py-3 px-4 text-left text-sm font-semibold text-gray-600">
                        Distance
                      </TableHead>
                      <TableHead className="py-3 px-4 text-left text-sm font-semibold text-gray-600">
                        Listed on
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {allFreight?.data.freights.map((freight: FreightItem) => (
                      <FreigthAllCard key={freight.id} freight={freight} />
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <div className="p-4 flex flex-col items-center justify-center bg-white border border-gray-200 mt-4 rounded-md shadow-sm">
                  <EmptySvg className="h-16 w-16 text-gray-400" />
                  <p className="text-sm font-medium text-gray-600 mt-2">
                    No freights found
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchFreightFreights;
