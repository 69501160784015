import { cn } from "src/lib/utils";
import { ChatData } from "src/types/types";
import { User2 } from "lucide-react";
import { Dispatch, SetStateAction } from "react";

interface ChatMessageCardProps {
  chatMessage: ChatData;
  selectedChat: ChatData;
  setSelectedChat: Dispatch<SetStateAction<ChatData>>;
}

const ChatMessageCard: React.FC<ChatMessageCardProps> = ({
  chatMessage,
  selectedChat,
  setSelectedChat,
}) => {
  return (
    <div
      onClick={() => setSelectedChat(chatMessage)}
      className={cn(
        "flex items-center space-x-2 py-3 bg-white border-b px-2 cursor-pointer",
        selectedChat?.id === chatMessage.id && "bg-gray-100"
      )}
    >
      <div className="flex items-center bg-gray-50 h-10 w-10 rounded-full justify-center">
        <User2 className="h-5 w-5 " />
      </div>
      <div className="flex flex-col  w-full relative">
        <div className="flex flex-col items-start">
          <h1 className="text-sm font-semibold">Ismael</h1>
          <h1 className={cn("text-sm  line-clamp-1 ")}>
            {chatMessage.message}
          </h1>
        </div>
        <h1 className="text-xs italic font-medium text-gray-500 text-right absolute -top-2 right-1">
          {chatMessage.time}
        </h1>
      </div>
    </div>
  );
};

export default ChatMessageCard;
