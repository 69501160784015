import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const useTokenStoreStorage = create(
    persist<{ token: string; addToken: (token: string) => void }>(
        (set,) => ({
            token: "",
            addToken: (token: string) => set({ token }),
        }),
        {
            name: "token_user",
            storage: createJSONStorage(() => localStorage),
        }
    )
);

export { useTokenStoreStorage };

const useGuidTokenStorage = create(
    persist<{ guidToken: string; addGuid: (token: string) => void }>(
        (set,) => ({
            guidToken: "",
            addGuid: (guidToken: string) => set({ guidToken })
        }),
        {
            name: "guid_token_user",
            storage: createJSONStorage(() => localStorage)
        }
    )
)

export { useGuidTokenStorage }


const imageFreightStorage = create(
    persist<{ image: string, addImage: (img: string) => void }>(
        (set,) => ({
            image: "",
            addImage: (image: string) => set({ image })
        }), {
        name: "image_freight",
        storage: createJSONStorage(() => localStorage)
    }
    )
)

export { imageFreightStorage }