import { useNavigate, useParams } from "react-router-dom";
import {
  ASSIGN_STOP_TO_ROUTE,
  BASE_URL,
  GET_CUSTOMER_STOPS,
  GET_STOPS_BY_ROUTE,
} from "../api/config";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Header from "../components/header/Header";
import { ChevronLeft, Plus } from "lucide-react";
import { RouteItem, StopType } from "../types/types";
//@ts-ignore
import SmallBox from "../assets/images/books-box.png";
//@ts-ignore
import MediumBox from "../assets/images/shoes-box.png";
//@ts-ignore
import LargeBox from "../assets/images/moving-box.png";
import { Button } from "../components/ui/button";
import { cn } from "../lib/utils";

const AssignStopToRoute = () => {
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const params = useParams();
  const navigate = useNavigate();
  const [selectedStop, setSelectedStop] = useState<StopType>();

  const getStopsByRouteId = async () => {
    const response = await fetch(
      `${BASE_URL}${GET_STOPS_BY_ROUTE}?user_id=${user_id}&route_id=${params.routeId}`,
      {
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });
    return response;
  };

  const { data: stopsByRoute } = useQuery({
    queryKey: ["stops_by_route"],
    queryFn: getStopsByRouteId,
  });

  const assignStopToRuote = async () => {
    const response = await fetch(`${BASE_URL}${ASSIGN_STOP_TO_ROUTE}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        route_id: params.routeId,
        stop_id: selectedStop?.id,
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const getRoutesByCustomerId = async () => {
    const response = await fetch(
      `${BASE_URL}${GET_CUSTOMER_STOPS}?user_id=${user_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return response;
  };
  const { data: stops, isLoading } = useQuery({
    queryKey: ["stops_customer"],
    queryFn: getRoutesByCustomerId,
  });

  return (
    <div className="h-screen bg-gray-50">
      <Header />
      <div className="max-w-7xl mx-auto p-16 h-[94vh] overflow-y-auto no-scrollbar">
        <div className="flex items-center justify-between">
          <div
            onClick={() => navigate("/find-trip")}
            className="flex items-center space-x-2 group cursor-pointer mb-4"
          >
            <ChevronLeft className="text-freightBlueDark h-5 w-5" />
            <h1 className="text-freightBlueDark text-sm group-hover:underline ">
              Back
            </h1>
          </div>
        </div>
        <div>
          {stopsByRoute?.data[0].length > 0 ? (
            <div className="mt-10">
              <h1 className="text-lg font-semibold">Delivery Stops</h1>
              {stopsByRoute?.data[0].map((route: StopType) => (
                <div className="bg-white border-border border my-4 p-4 flex items-center justify-between rounded">
                  <div className="bg-statusCreated w-16 rounded px-2 py-2">
                    <p className="text-sm font-medium text-white text-center">
                      {route.id}
                    </p>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <div className="flex flex-col items-start">
                      <p className="text-sm font-medium">Origin</p>
                      <div className="text-sm font-medium text-gray-500">
                        {route.origin_address ?? "Unknown"}
                      </div>
                    </div>
                    <div className="flex flex-col items-start">
                      <p className="text-sm font-medium">Destination</p>
                      <div className="text-sm font-medium text-gray-500">
                        {route.destination_address ?? "Unknown"}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <div className="flex flex-col items-start">
                      <p className="text-sm font-medium">Distance</p>
                      <p className="text-sm font-medium text-gray-500">
                        {route.delivery_distance ?? "Unknown"}
                      </p>
                    </div>
                    <div className="flex flex-col items-start">
                      <p className="text-sm font-medium">Duration</p>
                      <p className="text-sm font-medium text-gray-500">
                        {route.delivery_duration ?? "Unknown"}
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col space-y-2">
                    {route.package_size === "small" && (
                      <img
                        src={SmallBox}
                        className="h-20 w-20 object-contain rounded"
                        alt="package"
                      />
                    )}
                    {route.package_size === "medium" && (
                      <img
                        src={MediumBox}
                        className="h-20 w-20 object-contain rounded"
                        alt="package"
                      />
                    )}
                    {route.package_size === "large" && (
                      <img
                        src={LargeBox}
                        className="h-20 w-20 object-contain rounded"
                        alt="package"
                      />
                    )}
                    <div className="flex items-center space-x-2">
                      <div>
                        <p className="text-sm font-medium">Price</p>
                        <p className="text-sm font-medium text-gray-500">
                          {route.price}€
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium">Weight</p>
                        <p className="text-sm font-medium text-gray-500">
                          {route.package_weight}kg
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="bg-white shadow p-4">
              <p className="text-sm font-medium text-gray-500">
                No stops found.
              </p>
            </div>
          )}
        </div>
        <div className="mt-10">
          <h1 className="text-lg font-semibold">Your Packages</h1>
          <div>
            {stops?.data[0].length > 0 ? (
              <div>
                {stops?.data[0]
                  .filter((stop: StopType) => stop.status === "created")
                  .map((stop: StopType) => (
                    <div
                      onClick={() => setSelectedStop(stop)}
                      className={cn(
                        "bg-white shadow my-4 p-4 rounded flex flex-col w-full cursor-pointer hover:shadow-lg transition",
                        selectedStop?.id === stop.id &&
                          " shadow-freightBlueDark"
                      )}
                    >
                      <div className="flex items-start justify-between w-full">
                        <div className="flex flex-col space-y-2">
                          <div className="flex flex-col items-start">
                            <p className="text-sm font-medium">Origin</p>
                            <div className="text-sm font-medium text-gray-500">
                              {stop.origin_address ?? "Unknown"}
                            </div>
                          </div>
                          <div className="flex flex-col items-start">
                            <p className="text-sm font-medium">Destination</p>
                            <div className="text-sm font-medium text-gray-500">
                              {stop.destination_address ?? "Unknown"}
                            </div>
                          </div>
                        </div>
                        <div>
                          <p className="text-sm font-medium">Weight </p>
                          <p className="text-sm font-medium text-gray-500">
                            {stop.package_weight}kg
                          </p>
                        </div>{" "}
                        <div>
                          {stop.package_size === "small" && (
                            <img
                              src={SmallBox}
                              className="h-20 w-20 object-contain rounded"
                              alt="package"
                            />
                          )}
                          {stop.package_size === "medium" && (
                            <img
                              src={MediumBox}
                              className="h-20 w-20 object-contain rounded"
                              alt="package"
                            />
                          )}
                          {stop.package_size === "large" && (
                            <img
                              src={LargeBox}
                              className="h-20 w-20 object-contain rounded"
                              alt="package"
                            />
                          )}
                          <div className="bg-statusCreated px-4 py-2 mb-4 rounded">
                            <p className="text-sm font-medium text-white text-center ">
                              {stop.price.toFixed(2)}€
                            </p>
                          </div>
                        </div>
                      </div>
                      {selectedStop?.id === stop.id && (
                        <Button
                          onClick={() => assignStopToRuote()}
                          variant={"freightBlue"}
                          className="ml-auto justify-end"
                        >
                          <Plus className="h-4 w-4 text-white" />
                          <p className="text-sm font-medium">Assign Route</p>
                        </Button>
                      )}
                    </div>
                  ))}
              </div>
            ) : (
              <div>
                <p className="text-sm font-medium text-gray-500">
                  No packages found
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignStopToRoute;
