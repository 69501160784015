import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { IoChevronForward, IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "src/hooks/use-local-storage";
import { z } from "zod";
import { useTokenStoreStorage } from "../../store/useTokenStore";

interface State {
  id: number;
  state_name: string;
}

const formSchema = z.object({
  origin: z.any(),
  destination: z.any(),
  weight: z.string().min(1),
});

export const SecondTab = () => {
  const token = useTokenStoreStorage((s) => s.token); // Obtener token
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [dropOffDate, setDropOffDate] = useState("");
  const [states, setStates] = useState<State[]>([]);
  const [selectedOrigin, setSelectedOrigin] = useState<any[]>([]);
  const [selectedDestination, setSelectedDestination] = useState<any[]>([]);
  const [locationFreight, setLocationFreight] = useLocalStorage(
    "instant_freight_location"
  );
  const modalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false); // Estado para controlar el modal

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      origin: "",
      destination: "",
      weight: "",
    },
  });

  const handleLoginRedirect = () => {
    navigate("/sign-in"); // Redirige a la página de inicio de sesión
  };

  const handleGetInstantDelivery = () => {
    navigate("/preregister");
    // if (!token) {
    //   setShowModal(true); // Mostrar modal si el token está vacío
    // } else {
    //   // Si el token está presente, proceder a la navegación
    //   setTimeout(() => {
    //     navigate("/send-now");
    //   }, 1000);
    // }
  };
  const handleScheduleDeliveryClick = () => {
    navigate("/preregister");
    // if (!token) {
    //   setShowModal(true); // Mostrar modal si el token está vacío
    // } else {
    //   // Si el token está presente, proceder a la navegación
    //   setTimeout(() => {
    //     navigate("/getFreight");
    //   }, 1000);
    // }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setShowModal(false); // Close modal if click is outside
      }
    };

    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal]);

  return (
    <>
      {/* Modal que solo aparece cuando no hay token y el usuario hace clic */}
      {showModal && (
        <div
          className={`fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 z-50 transition-opacity duration-300 ease-out ${
            showModal ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            ref={modalRef}
            className={`bg-white p-6 rounded-lg shadow-lg w-[400px] transition-all duration-300 ease-out transform ${
              showModal
                ? "scale-100 opacity-100 animate-fadeIn"
                : "scale-95 opacity-0"
            }`}
          >
            <div className="flex items-start justify-between">
              <h3 className="text-lg font-semibold mb-4">
                You must be logged in
              </h3>
              <button
                className="outline-none hover:opacity-85 transition"
                onClick={() => setShowModal(false)}
              >
                <IoClose className="h-5 w-5" />
              </button>
            </div>
            <p className="mb-4">You must be logged in to post a delivery.</p>
            <button
              onClick={handleLoginRedirect}
              className="bg-blue-500 text-white px-4 py-2 rounded-md w-full"
            >
              Log in here
            </button>
          </div>
        </div>
      )}

      {/* Contenido normal del componente */}
      <div className="bg-white bg-opacity-100 border rounded-xl shadow-xl lg:h-[400px] w-[400px] py-4 px-10 overflow-hidden sm:w-[800px] sm:flex sm:flex-col">
        <div className="flex flex-col">
          <h3 className="text-2xl font-extrabold font-paragraph">
            Instant Freight
          </h3>
          <h4 className="mt-2 text-[15px] font-medium text-slate-500 w-[60%] max-w-[500px]">
            In a world where time is crucial, Instant Delivery ensures your
            products are sent immediately with a single click.
          </h4>
          <div className="h-[40px] mb-10">
            <button
              onClick={handleGetInstantDelivery}
              className="h-[40px] w-[250px] mt-4 lg:ml-auto font-paragraph bg-[#ff380f] shadow-lg hover:bg-[#f4775efb] hover:shadow-4xl group text-white rounded-2xl transition duration-300 ease-in-out flex items-center space-x-2 justify-center"
            >
              <p className="text-[15px] font-semibold">Get INSTANT Delivery</p>
              <IoChevronForward className="h-5 w-5 group-hover:translate-x-2 transition " />
            </button>
          </div>
        </div>

        <div className="flex flex-col pb-4">
          <h3 className="text-2xl font-extrabold font-paragraph mt-4">
            Program your delivery
          </h3>
          <h4 className="mt-2 text-[15px] font-medium text-slate-500 w-[60%] ">
            Our delivery management system organizes and structures all stages
            of the process, from collection to final delivery, ensuring the
            efficient and timely arrival of each package.
          </h4>
          <div className="h-[45px]">
            <button
              onClick={handleScheduleDeliveryClick} // Comprobar token al hacer clic
              className="h-[45px] w-[250px] mt-4 lg:ml-auto font-paragraph bg-[#ff380f] shadow-lg hover:bg-[#f4775efb] hover:shadow-4xl group text-white rounded-2xl transition duration-300 ease-in-out flex items-center space-x-2 justify-center"
            >
              <p className="text-[15px] font-semibold">
                Schedule your delivery
              </p>
              <IoChevronForward className="h-5 w-5 group-hover:translate-x-2 transition " />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
