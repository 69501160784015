import { useNavigate } from "react-router-dom";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { BASE_URL, GET_CUSTOMER_STOPS } from "../api/config";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import SidebarLayout from "../components/layout/SidebarLayout";
import { Button } from "../components/ui/button";
import { ArrowLeft, ArrowRight, Eye, Loader2 } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { StopType } from "../types/types";

//@ts-ignore
import SmallBox from "../assets/images/Smallbox.png";
//@ts-ignore
import MediumBox from "../assets/images/Mediumbox.png";
//@ts-ignore
import LargeBox from "../assets/images/Largebox.png";
import { cn } from "../lib/utils";
import { useDeleteStop } from "../hooks/use-delete-stop";
import ModalDeleteStop from "../components/modal/ModalDeleteStop";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";

const CustomerTrips = () => {
  const navigate = useNavigate();
  const { user_type, user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const modalDeleteStop = useDeleteStop();
  const [sortBy, setSortBy] = useState("desc");
  const [page, setPage] = useState(1);

  const getRoutesByCustomerId = async () => {
    const response = await fetch(
      `${BASE_URL}${GET_CUSTOMER_STOPS}?user_id=${user_id}&sort=${sortBy}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return response;
  };
  const {
    data: routes,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["routes_customer", page, sortBy],
    queryFn: getRoutesByCustomerId,
  });

  const sortOptions = [
    {
      value: "ASC",
      label: "Ascendent",
    },
    {
      value: "DESC",
      label: "Descendent",
    },
  ];
  return (
    <SidebarLayout>
      <div className="flex items-center w-full justify-between border-b border-border pb-3 mb-4">
        <h1 className="text-lg font-semibold">My Stops</h1>
      </div>
      {isLoading ? (
        <div className="bg-white shadow h-20 w-full flex items-center justify-center">
          <Loader2 className="animate-spin h-5 w-5" />
        </div>
      ) : (
        <div>
          {routes?.data.stops?.length > 0 ? (
            <>
              <div className="mt-4 ml-auto w-full justify-end sm:flex sm:space-x-2 mb-4">
                <>
                  <div className="text-sm font-medium flex items-center space-x-1">
                    <h1>Total</h1>
                    {!isLoading ? (
                      <span>{routes?.data?.total_items}</span>
                    ) : (
                      <span>
                        <Loader2 className="h-4 w-4 animate-spin" />
                      </span>
                    )}
                    <h1>Bookings</h1>
                  </div>
                </>
                <Select
                  onValueChange={(val: any) => {
                    setSortBy(val.toLowerCase());
                  }}
                >
                  <SelectTrigger className="w-40 h-8">
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent>
                    {sortOptions.map((sort) => (
                      <SelectItem value={sort.value}>{sort.label}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <div className="flex">
                  <Button
                    disabled={page === 1}
                    onClick={() => setPage(1)}
                    className={cn(
                      "h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                      page === 1 && "bg-[#333] text-white"
                    )}
                  >
                    <p className="text-sm font-medium">1</p>
                  </Button>
                  <div
                    onClick={() => {
                      if (page > 1 && page !== routes?.data?.total_pages) {
                        setPage((page) => page - 1);
                      }
                    }}
                    className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
                  >
                    <ArrowLeft className="h-4 w-4 text-[#333]" />
                  </div>
                  <div
                    onClick={() => {
                      if (page !== routes?.data.total_pages) {
                        setPage((page) => page + 1);
                      }
                    }}
                    className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
                  >
                    <ArrowRight className="h-4 w-4 text-[#333]" />
                  </div>
                  <Button
                    disabled={page === routes?.data?.total_pages}
                    onClick={() => setPage(routes?.data?.total_pages)}
                    className={cn(
                      "h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                      page === routes?.data?.total_pages &&
                        "bg-[#333] text-white"
                    )}
                  >
                    {isLoading ? (
                      <p>
                        <Loader2 className="h-5 w-5 animate-spin" />
                      </p>
                    ) : (
                      <p className="text-sm font-medium">
                        {routes?.data?.total_pages}
                      </p>
                    )}
                  </Button>
                </div>
              </div>
              <Table className="bg-white border-border border">
                <TableHeader>
                  <TableRow>
                    <TableHead>Stop Id</TableHead>
                    <TableHead>Origin/Destination</TableHead>
                    <TableHead>Size</TableHead>
                    <TableHead>Weight</TableHead>
                    <TableHead>Price</TableHead>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHeader>
                {routes.data.stops.map((route: StopType) => (
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <>
                          {route.package_size === "small" && (
                            <div
                              onClick={() =>
                                navigate(`/customer/trips/${route.id}`)
                              }
                            >
                              <img
                                src={SmallBox}
                                className="h-16 w-16 object-contain"
                                alt="small"
                              />
                            </div>
                          )}
                          {route.package_size === "medium" && (
                            <div
                              onClick={() =>
                                navigate(`/customer/trips/${route.id}`)
                              }
                            >
                              <img
                                src={MediumBox}
                                className="h-16 w-16 object-contain"
                                alt="medium"
                              />
                            </div>
                          )}
                          {route.package_size === "large" && (
                            <div
                              onClick={() =>
                                navigate(`/customer/trips/${route.id}`)
                              }
                            >
                              <img
                                src={LargeBox}
                                className="h-16 w-16 object-contain"
                                alt="large"
                              />
                            </div>
                          )}
                        </>
                      </TableCell>
                      <TableCell>
                        <div className="flex flex-col space-y-4">
                          <div className="flex flex-col space-y-2">
                            <p className="text-sm font-medium">Origin</p>
                            <p className="text-sm font-medium text-gray-500">
                              {route.origin_address}
                            </p>
                          </div>
                          <div className="flex flex-col space-y-2">
                            <p className="text-sm font-medium">Destination</p>
                            <p className="text-sm font-medium text-gray-500">
                              {route.destination_address}
                            </p>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="bg-gray-50 px-4 py-2 font-medium capitalize text-center">
                          {route.package_size}
                        </div>
                      </TableCell>
                      <TableCell>{route.package_weight}kg</TableCell>
                      <TableCell>{route.price}€</TableCell>
                      <TableCell>
                        <div
                          className={cn(
                            "bg-gray-50 flex items-center justify-center py-2 rounded",
                            route.status === "created" && "bg-statusCreated",
                            route.status === "confirmed" &&
                              "bg-statusConfirmed",
                            route.status === "booked" && "bg-statusPaid",
                            route.status === "picking-up" &&
                              "bg-statusPickingUp",
                            route.status === "in-transit" &&
                              "bg-statusInTransit"
                          )}
                        >
                          <p className="text-sm font-medium text-white capitalize">
                            {route.status}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center space-x-3">
                          <Eye
                            onClick={() =>
                              navigate(`/customer/trips/${route.id}`)
                            }
                            className="h-5 w-5 cursor-pointer"
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </>
          ) : (
            <p className="text-sm font-medium">No stops found</p>
          )}

          {modalDeleteStop.isOpen && <ModalDeleteStop refetch={refetch} />}
        </div>
      )}
    </SidebarLayout>
  );
};

export default CustomerTrips;
