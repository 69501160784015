import { useEffect, useState } from "react";
import { BASE_URL, CUSTOMER_PAYMENT_METHODS, USER_EXISTS } from "../api/config";

import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";

import { useQuery } from "@tanstack/react-query";
import { CustomerPaymentMethod } from "../types/types";

import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import { useAddPaymentMethod } from "../hooks/use-add-payment-method";

import { userStripeCustomerStore } from "../store/useStripeStore";
import SidebarLayout from "../components/layout/SidebarLayout";

const MethodPayments = () => {
  const { user_id, user_type, email } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const [customerId, setCustomerId] = useState("");
  // const [listPayments, setListPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [predCard, setPredCard] = useState();
  const [userNotRegistered, setUserNotRegistered] = useState(true);
  const addCustomerId = userStripeCustomerStore((state) => state.addCustomerId);

  const modalAddPaymentMethod = useAddPaymentMethod();

  const doesUserExist = async () => {
    setIsLoading(true);
    const response = await fetch(`${BASE_URL}${USER_EXISTS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        email: email,
        user_id: user_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "User is not registered in stripe.") {
          setUserNotRegistered(true);
        } else {
          setCustomerId(data.data[0][0].id);
          // getListPayments(data.data[0][0].id);
          setPredCard(data.data[0][0].invoice_settings.default_payment_method);
          setUserNotRegistered(false);
          addCustomerId(data.data[0][0].id);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return response;
  };

  useEffect(() => {
    doesUserExist();
  }, []);

  const getCustomerPaymentMethods = async () => {
    const data = await fetch(
      `${BASE_URL}${CUSTOMER_PAYMENT_METHODS}?customer_id=${customerId}&user_id=${user_id}`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    ).then((res) => res.json());

    return data;
  };
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = useQuery(
    {
      queryKey: ["payment_methods", customerId],
      queryFn: getCustomerPaymentMethods,
      enabled: customerId !== "",
    }
  );

  return (
    <SidebarLayout>
      <div className="flex items-center justify-between space-x-2 border-b border-border pb-3 mb-4">
        <h1 className="text-lg font-semibold">My Payments</h1>

        <Button
          className="mt-4"
          onClick={() => modalAddPaymentMethod.onOpen("addPaymentMethod")}
          variant={"freightBlue"}
        >
          + Add Payment Method
        </Button>
      </div>
      <div className=" bg-white p-4 shadow rounded">
        {paymentMethods?.message === "All validated payment methods" &&
          !isLoadingPaymentMethods &&
          paymentMethods?.data?.length === 0 && (
            <div>
              <h1 className="text-lg font-medium">Nothing found</h1>
              <p className="text-sm text-gray-400">
                Right now, you don't have any list of payments
              </p>
            </div>
          )}
        {isLoadingPaymentMethods ? (
          <div className="flex items-center justify-between">
            <Skeleton className="h-5 w-20" />
            <Skeleton className="h-5 w-20" />
            <Skeleton className="h-5 w-20" />
            <Skeleton className="h-5 w-20" />
          </div>
        ) : (
          <>
            {paymentMethods?.data?.length > 0 &&
              paymentMethods?.data?.map((payment: CustomerPaymentMethod) => (
                <div className="flex items-center justify-between h-16  px-2 border border-border">
                  <div className="flex items-center space-x-2 ">
                    {payment.card.display_brand === "visa" && (
                      <img
                        alt="visa"
                        src={require("../assets/visa.png")}
                        className=" h-10 w-10 object-contain rounded-lg"
                      />
                    )}
                    {payment.card.brand === "amex" && (
                      <img
                        alt="visa"
                        src={require("../assets/amex.png")}
                        className=" h-10 w-10 object-contain rounded-lg"
                      />
                    )}
                    <p className="font-semibold text-sm uppercase">
                      {payment.card.display_brand}
                    </p>
                    <div className="flex items-center">
                      <p className="text-sm font-semibold">****</p>
                      <p className="text-sm font-medium">
                        {payment.card.last4}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">
                      {payment.card.exp_month}/{payment.card.exp_year}
                    </p>
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
    </SidebarLayout>
  );
};

export default MethodPayments;
