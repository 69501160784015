import { useQuery } from "@tanstack/react-query";
import {
  ArrowLeft,
  Ban,
  Grip,
  Mail,
  MapPin,
  Phone,
  Settings,
  Wallet,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BASE_URL,
  BOOKING_REVIEWS,
  BOOKINGBYID,
  GET_IMAGE,
  LIST_ALL_PAYMENTS,
  USER_EXISTS,
} from "../api/config";
import small from "../assets/images/books-box.png";
import large from "../assets/images/moving-box.png";
import medium from "../assets/images/shoes-box.png";
import SidebarLayout from "../components/layout/SidebarLayout";
import ModalCancelBookingQuote from "../components/modal/ModalCancelBookingQuote";
import ModalCompleteBooking from "../components/modal/ModalCompleteBooking";
import ModalCompletePayment from "../components/modal/ModalCompletePayment";
import ModalConfirmBooking from "../components/modal/ModalConfirmBooking";
import ModalSendMessage from "../components/modal/ModalSendMessage";
import ModalSubmitReview from "../components/modal/ModalSubmitReview";
import { Rating } from "../components/Rating";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheets";
import { useCancelBookingQuote } from "../hooks/use-cancel-booking-quote";
import { useCompleteBooking } from "../hooks/use-complete-booking";
import { useCompletePayment } from "../hooks/use-complete-payment";
import { useConfirmBooking } from "../hooks/use-confirm-booking";
import { useSendMessage } from "../hooks/use-send-message";
import { useSubmitReview } from "../hooks/use-submit-review";
import { cn } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { BookingReview } from "../types/types";

const ViewBooking = () => {
  const params = useParams();
  const { user_id, user_type, email } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const navigate = useNavigate();
  const modalCancelBookingQuote = useCancelBookingQuote();
  const modalCompleteJob = useCompleteBooking();
  const modalSubmitReview = useSubmitReview();
  const modalConfirmBooking = useConfirmBooking();
  const modalCompletePayment = useCompletePayment();
  const [customerId, setCustomerId] = useState("");
  const [filteredReviews, setFilteredReviews] = useState<BookingReview[]>([]);
  const modalSendMessage = useSendMessage();

  const doesUserExist = async () => {
    const response = await fetch(`${BASE_URL}${USER_EXISTS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        email: email,
        user_id: user_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setCustomerId(data.data[0][0].id);
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  useEffect(() => {
    doesUserExist();
  }, []);

  const getBookingReviews = async () => {
    const response = await fetch(`${BASE_URL}${BOOKING_REVIEWS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        user_type: user_type,
        booking_id: params.bookingId,
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const {
    data: bookingReviews,
    isLoading: isLoadingReviews,
    refetch: refetchReviews,
  } = useQuery({
    queryKey: ["booking_reviews"],
    queryFn: getBookingReviews,
  });

  useEffect(() => {
    setFilteredReviews(
      bookingReviews?.data?.filter(
        (booking: BookingReview) =>
          booking.bookingid === Number(params.bookingId)
      )
    );
  }, [bookingReviews, params.bookingId]);

  const getAllListPayments = async () => {
    const response = await fetch(
      `${BASE_URL}${LIST_ALL_PAYMENTS}?user_id=${user_id}&freight_id=${bookingCustomer?.data?.freightid}&booking_id=${params.bookingId}&stripe_customer_id=${customerId}`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const { data: listPayments, isLoading: isLoadingPayment } = useQuery({
    queryKey: ["list_payments", customerId],
    queryFn: getAllListPayments,
  });

  const getBookingsCustomer = async () => {
    const response = await fetch(`${BASE_URL}${BOOKINGBYID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        booking_id: params.bookingId,
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });
    return response;
  };

  const {
    data: bookingCustomer,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["booking_customer", params.booking_id],
    queryFn: getBookingsCustomer,
  });

  const booking = bookingCustomer?.data;

  useEffect(() => {
    refetch();
  }, [params.bookingId]);

  return (
    <SidebarLayout>
      <div className="sm:flex items-center justify-between">
        <div
          onClick={() => {
            if (user_type === "customer") {
              navigate("/booking/customer/booked");
            } else {
              navigate("/booking/carrier/active");
            }
          }}
          className="flex items-center space-x-4 pl-4 py-4"
        >
          <ArrowLeft className="h-4 w-4 text-freightBlueDark cursor-pointer" />
          <h1 className="text-sm hover:underline text-freightBlueDark cursor-pointer">
            Back to Bookings
          </h1>
        </div>
        <p
          className={cn(
            "text-sm font-medium rounded px-4 py-2 capitalize text-white",
            booking?.booking_status === "booked" && "bg-statusCreated",
            booking?.booking_status === "assigned" && "bg-statusAssigned",
            booking?.booking_status === "paid" && "bg-statusPaid",
            booking?.booking_status === "picking-up" && "bg-statusPickingUp",
            booking?.booking_status === "in-transit" && "bg-statusInTransit",
            booking?.booking_status === "finished" && "bg-statusFinished",
            booking?.booking_status === "complete" && "bg-statusConfirmed",
            booking?.booking_status === "booked" && "bg-freightBlueDark",
            booking?.booking_status === "cancelled" && "bg-gray-500 text-[#333]"
          )}
        >
          {booking?.booking_status}
        </p>
      </div>

      <div className="">
        {isLoading || isRefetching ? (
          <div className="flex items-start space-x-4 bg-white p-4 border-border border justify-between">
            <div className="flex flex-col items-start bg-gray-50 rounded border p-4">
              <Skeleton className="h-5 w-20" />
              <Skeleton className="h-5 w-28 mt-4" />
              <Skeleton className="h-5 w-28 mt-4" />
            </div>
            <div className="flex flex-col items-start">
              <Skeleton className="h-5 w-20" />
              <Skeleton className="h-5 w-40 mt-4" />
              <Skeleton className="h-5 w-40 mt-4" />
            </div>
            <Skeleton className="h-5 w-40" />
          </div>
        ) : (
          <div className="flex items-start space-x-4 bg-white p-4 border-border border justify-between">
            <div className="flex flex-col items-start w-full relative space-y-2">
              <Sheet>
                <SheetTrigger asChild>
                  <Button variant="outline" className="ml-auto">
                    <Settings className="h-4 w-4 mr-2" />
                    Settings
                  </Button>
                </SheetTrigger>
                <SheetContent className="flex flex-col justify-between">
                  <SheetHeader>
                    <SheetTitle>Booking Settings</SheetTitle>
                    <SheetDescription>
                      Make changes to your booking here.
                    </SheetDescription>
                    <div className="grid gap-4 py-4">
                      {booking?.status === "booked" && (
                        <Button
                          className="col-span-4"
                          onClick={() =>
                            modalCancelBookingQuote.onOpen(
                              "cancelBookingQuote",
                              booking.id.toString()
                            )
                          }
                          variant="destructive"
                        >
                          <Ban className="h-4 w-4 mr-2" />
                          Cancel Booking Quote
                        </Button>
                      )}
                    </div>
                    <div className="grid gap-4 py-4">
                      {listPayments?.data?.[0]?.is_freight_amount_paid !== 1 &&
                        booking?.status === "booked" && (
                          <Button
                            onClick={() =>
                              navigate(`/complete-payment/${booking.id}`)
                            }
                            variant="freightGreen"
                          >
                            <Wallet className="h-4 w-4 mr-2" />
                            Complete Payment
                          </Button>
                        )}
                      {booking?.status === "complete" && (
                        <Button
                          onClick={() =>
                            modalSubmitReview.onOpen(
                              "submitReview",
                              booking.id.toString()
                            )
                          }
                          variant="freightBlue"
                        >
                          Add review
                        </Button>
                      )}
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      {user_type === "customer" && (
                        <Button className="col-span-4" variant={"outline"}>
                          <Grip className="h-4 w-4 mr-2" />
                          {booking.otp_confirmation}
                        </Button>
                      )}
                    </div>
                  </SheetHeader>

                  <SheetFooter>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Button className="col-span-1" variant="outline">
                        <Phone className="h-4 w-4" />
                      </Button>
                      <Button
                        className="col-span-3"
                        onClick={() =>
                          modalSendMessage.onOpen("sendMessage", {
                            quoteId: Number(booking?.quoteid),
                            receiverId: Number(user_id),
                            freightId: booking?.freightid,
                            transporterImage: booking?.trans_img,
                            transporterName: booking?.transporter_name,
                            carrierId: Number(booking?.carrier_id),
                            customerId: Number(booking?.userId),
                            senderId: Number(user_id),
                          })
                        }
                        variant="outline"
                      >
                        <Mail className="h-4 w-4 mr-2" />
                        <p>Send Message</p>
                      </Button>
                    </div>
                  </SheetFooter>
                </SheetContent>
              </Sheet>

              <div className="flex items-start space-x-2 flex-grow">
                <div className="bg-gray-50 rounded border p-4 flex flex-col items-start space-y-4">
                  <div className="flex w-full items-start space-x-10">
                    <div className="flex flex-col items-start">
                      <h1 className="font-medium text-sm">Freight</h1>
                      <p className="hover:underline text-freightBlueDark cursor-pointer">
                        {booking?.title}
                      </p>
                    </div>
                    <div className="flex flex-col items-start space-y-2 w-40">
                      <div className="flex flex-col items-start space-y-1">
                        <p className="font-medium text-sm">Pickup date</p>
                        <p className="text-sm font-medium text-gray-500">
                          {booking?.start_date}
                        </p>
                      </div>
                      <div className="flex flex-col items-start space-y-1">
                        <p className="font-medium text-sm">Dropoff date</p>
                        <p className="text-sm font-medium text-gray-500">
                          {booking?.end_date}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="flex items-center space-x-2">
                        <div className="w-6">
                          <MapPin className="text-green-500 h-5 w-5" />
                        </div>
                        <div className="">
                          <p className="text-sm font-medium">Origin</p>
                          <p className="text-sm font-medium text-gray-500">
                            {booking?.origin_address}, {booking?.origin_city}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <MapPin className="text-freightBlueDark h-5 w-5" />
                        <div className="">
                          <p className="text-sm font-medium">Destination</p>
                          <p className="text-sm font-medium text-gray-500">
                            {booking?.destination_address},{" "}
                            {booking?.destination_city}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-t border-border w-full pt-4">
                    {booking?.animal_img ? (
                      <img
                        src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${booking.animal_img}&image_type=freight`}
                        className="w-40 object-contain rounded"
                        alt="freightImage"
                      />
                    ) : (
                      <>
                        {booking?.freight_type_info?.freight_category?.id ===
                          1 && (
                          <img
                            src={small}
                            className="h-22 w-22 object-contain cursor-pointer"
                            alt="small package"
                          />
                        )}
                        {booking?.freight_type_info?.freight_category?.id ===
                          2 && (
                          <img
                            src={medium}
                            className="h-22 w-22 object-contain cursor-pointer"
                            alt="medium package"
                          />
                        )}
                        {booking?.freight_type_info?.freight_category?.id ===
                          3 && (
                          <img
                            src={large}
                            className="h-22 w-22 object-contain cursor-pointer"
                            alt="large package"
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="flex flex-col items-start w-80 bg-gray-50 border-border border rounded">
                  <div className="p-4 flex items-center justify-between w-full">
                    <h1 className="font-medium text-sm">Quote Amount</h1>
                    <p className="text-sm font-medium text-gray-500">
                      {Number(booking?.quote_amount).toFixed(2)}€
                    </p>
                  </div>
                  <div className="p-4 flex items-center justify-between w-full">
                    <h1 className="font-medium text-sm">Deliver to</h1>
                    <p className="text-sm font-medium text-gray-500">
                      {booking?.deliver_to}
                    </p>
                  </div>
                  <div className="p-4 flex items-center justify-between w-full">
                    <h1 className="font-medium text-sm">Booked date</h1>
                    <p className="text-sm font-medium text-gray-500">
                      {booking?.date_booked}
                    </p>
                  </div>
                  <div className="p-4 flex items-center justify-between w-full">
                    <h1 className="text-sm font-medium">Booking Status</h1>
                    <p className="text-sm font-medium text-gray-500 capitalize">
                      {booking?.booking_status}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {bookingReviews?.message === "No records were found." && (
          <div className="bg-white p-4 border border-border mt-4">
            <p className="text-sm font-medium">No reviews found</p>
          </div>
        )}

        {isLoadingReviews ? (
          <div className="flex flex-col bg-white p-4 mt-4">
            <Skeleton className="h-5 w-20" />
            <Skeleton className="h-5 w-20 mt-4" />
            <Skeleton className="h-5 w-20 mt-4" />
            <Skeleton className="h-5 w-20 mt-4" />
            <Skeleton className="h-5 w-20 mt-4" />
          </div>
        ) : (
          <>
            {filteredReviews?.length > 0 && (
              <div className="bg-white border border-border mt-4 p-4">
                <h1 className="text-lg font-medium">Reviews</h1>
                {filteredReviews.map((review) => (
                  <div
                    key={review.id}
                    className="flex flex-col items-start space-y-2 mt-4"
                  >
                    <div className="bg-gray-50 rounded border px-4 py-2 flex items-center justify-between w-80">
                      <h1 className="text-gray-500 font-medium text-sm">
                        Care
                      </h1>
                      <Rating
                        count={review.rating_care}
                        value={review.rating_care}
                        edit={false}
                      />
                    </div>
                    <div className="bg-gray-50 rounded border px-4 py-2 flex items-center justify-between w-80">
                      <h1 className="text-gray-500 font-medium text-sm">
                        Communication
                      </h1>
                      <Rating
                        count={review.rating_communication}
                        value={review.rating_communication}
                        edit={false}
                      />
                    </div>
                    <div className="bg-gray-50 rounded border px-4 py-2 flex items-center justify-between w-80">
                      <h1 className="text-gray-500 font-medium text-sm">
                        Service
                      </h1>
                      <Rating
                        count={review.rating_service}
                        value={review.rating_service}
                        edit={false}
                      />
                    </div>
                    <div className="bg-gray-50 rounded border px-4 py-2 flex items-center justify-between w-80">
                      <h1 className="text-gray-500 font-medium text-sm">
                        Punctuality
                      </h1>
                      <Rating
                        count={review.rating_punctuality}
                        value={review.rating_punctuality}
                        edit={false}
                      />
                    </div>
                    <div className="bg-gray-50 rounded border px-4 py-2 flex items-center justify-between w-80">
                      <h1 className="text-gray-500 font-medium text-sm">
                        Customer Service
                      </h1>
                      <Rating
                        count={review.rating_customer_service}
                        value={review.rating_customer_service}
                        edit={false}
                      />
                    </div>
                    <div className="bg-gray-50 rounded border px-4 py-2 flex items-center justify-between w-80">
                      <h1 className="text-gray-500 font-medium text-sm">
                        Average Rating
                      </h1>
                      <Rating
                        count={review.rating_avg}
                        value={review.rating_avg}
                        edit={false}
                      />
                    </div>
                    <div className="border-t pt-2 w-full">
                      <h1 className="text-xs font-medium text-freightBlue">
                        Message
                      </h1>
                      <p className="text-sm font-medium text-gray-500">
                        {review.review}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>

      {modalCancelBookingQuote.isOpen && <ModalCancelBookingQuote />}
      {modalCompleteJob.isOpen && <ModalCompleteBooking refetch={refetch} />}
      {modalSendMessage.isOpen && <ModalSendMessage />}
      {modalSubmitReview.isOpen && user_type === "customer" && (
        <ModalSubmitReview refetch={refetch} refetchReviews={refetchReviews} />
      )}
      {modalConfirmBooking.isOpen && <ModalConfirmBooking refetch={refetch} />}
      {modalCompletePayment.isOpen && user_type === "customer" && (
        <ModalCompletePayment refetch={refetch} />
      )}
    </SidebarLayout>
  );
};

export default ViewBooking;
