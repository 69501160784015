import { useQuery } from "@tanstack/react-query";
import { BASE_URL, GET_REVIEW } from "../api/config";
import Header from "../components/header/Header";
import CustomerSidebar from "../components/sidebar/CustomerSidebar";
import Sidebar from "../components/sidebar/Sidebar";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { FeedbackType } from "../types/types";
import SidebarLayout from "../components/layout/SidebarLayout";

//@ts-ignore
import { ReactComponent as EmptySvg } from "src/assets/images/data-not-found.svg";

const CarrierFeedback = () => {
  const token = useTokenStoreStorage((state) => state.token);
  const { email, name, user_id, user_type } = userInfoUserStore(
    (state) => state
  );
  const getBookingReviews = async () => {
    const response = await fetch(`${BASE_URL}${GET_REVIEW}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        user_type: user_type,
      }),
    }).then((res) => res.json());

    return response;
  };

  const { data: carrierFeedback } = useQuery({
    queryKey: ["carrier_reviews"],
    queryFn: getBookingReviews,
  });

  return (
    <SidebarLayout>
      {/* <Header />
      <div className="grid grid-cols-7 mt-20 px-10">
        <div className="col-span-2">
          {user_type === "customer" ? <CustomerSidebar /> : <Sidebar />}
        </div>
        <div className="col-span-5"> */}
      <div className="flex items-center justify-between w-full border-border border-b pb-3 space-x-2 mb-4">
        <h1 className="text-lg font-semibold ">Feedback List</h1>
      </div>
      <div className=" bg-white shadow p-4">
        {!carrierFeedback?.data ? (
          <div className="flex flex-col items-center w-full mx-auto space-y-4 justify-center py-3">
            <EmptySvg />
            <p className="text-sm font-medium text-gray-500 ">No Feedback</p>
          </div>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Feedback</TableHead>
                <TableHead>Detailed Ratings</TableHead>
                <TableHead>Freight</TableHead>
                <TableHead>Customer</TableHead>
                <TableHead>Date</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {carrierFeedback?.data?.map((feedback: FeedbackType) => (
                <TableRow>
                  <TableCell>{feedback.review_response}</TableCell>
                  <TableCell>
                    Communication - {feedback.rating_communication}
                    <br />
                    Customer Service - {feedback.rating_customer_service}
                    <br />
                    Punctuality - {feedback.rating_punctuality}
                    <br />
                    Service - {feedback.rating_service}
                  </TableCell>
                  <TableCell>{feedback.title}</TableCell>
                  <TableCell>{feedback.customer}</TableCell>
                  <TableCell>{feedback.created_at}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    </SidebarLayout>
  );
};

export default CarrierFeedback;
