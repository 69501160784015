import { z } from "zod";
import SidebarLayout from "../components/layout/SidebarLayout";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import React, { useEffect, useState } from "react";
// import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { ASSIGN_CARRIER, BASE_URL } from "../api/config";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { useNavigate, useParams } from "react-router-dom";
import { userInfoUserStore } from "../store/useUserInfoStore";
import toast from "react-hot-toast";
import { Loader2, PackageCheck } from "lucide-react";
import { TimePicker } from "../components/ui/time-picker";

const assignFreightSchema = z.object({
  hourPickupEstimated: z.any(),
  hourDeliveryEstimated: z.any(),
  vehicleType: z.string().min(1),
});

const AssignInstantFreight = () => {
  const params = useParams();
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const [pickupvalue, onChangePickup] = useState<any>("10:00");
  const [deliveryValue, onChangeDelivery] = useState<any>("10:00");
  const [vehicleValue, onChangeVehicle] = useState("car");
  const token = useTokenStoreStorage((state) => state.token);
  const [successAssignation, setSuccessAssignation] = useState(false);
  const minuteRef = React.useRef<HTMLInputElement>(null);
  const hourRef = React.useRef<HTMLInputElement>(null);
  const secondRef = React.useRef<HTMLInputElement>(null);
  const [hoursValue, setHoursValue] = useState({
    pickup: "",
    delivery: "",
    vehicleType: "",
  });
  const navigate = useNavigate();

  const assignFreight = useForm<z.infer<typeof assignFreightSchema>>({
    resolver: zodResolver(assignFreightSchema),
    defaultValues: {
      hourPickupEstimated: new Date(),
      hourDeliveryEstimated: new Date(),
      vehicleType: "",
    },
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user_type !== "carrier") {
      navigate("/sign-in");
    }
  }, [token, user_type]);

  const onSubmit = (values: z.infer<typeof assignFreightSchema>) => {
    setLoading(true);

    assignCarrierToInstantFreight(
      new Date(values.hourPickupEstimated).toLocaleTimeString().slice(0, 5),
      new Date(values.hourDeliveryEstimated).toLocaleTimeString().slice(0, 5)
    );

    setHoursValue({
      delivery: new Date(values.hourDeliveryEstimated)
        .toLocaleTimeString()
        .slice(0, 5),
      pickup: new Date(values.hourPickupEstimated)
        .toLocaleTimeString()
        .slice(0, 5),
      vehicleType: values.vehicleType,
    });
  };
  const assignCarrierToInstantFreight = async (
    pickupTime: any,
    deliveryTime: any
  ) => {
    const response = await fetch(`${BASE_URL}${ASSIGN_CARRIER}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        carrier_id: user_id,
        instant_freight_id: params.instantFreightId,
        estimated_pickup_schedule: pickupTime,
        estimated_deliver_schedule: deliveryTime,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Instant freight updated successfully") {
          toast.success(data.message);
          setSuccessAssignation(true);
          setTimeout(() => {
            navigate(`/carrier/instant-freights`);
          }, 2000);
        }
        if (
          data.message ===
          "Carrier already assigned to this instant freight, next time be quicker :)"
        ) {
          navigate(`/carrier/instant-freights`);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
    return response;
  };

  return (
    <SidebarLayout>
      <div className="max-w-7xl mr-auto bg-white shadow rounded p-4">
        <h1 className="text-sm font-semibold border-b-2 pb-3 mb-4">
          Assign Instant Freight
        </h1>
        {successAssignation ? (
          <div className="flex flex-col w-full">
            <div className="flex flex-col  my-2">
              <h1 className="text-lg font-semibold">Successfully assigned!</h1>
              <PackageCheck className="h-16 w-16 text-green-500 mt-2" />
            </div>

            <div className="flex flex-col my-4 w-40 space-y-2">
              <div className="flex flex-col items-start bg-gray-50 border border-border py-2 px-2">
                <p className="text-sm font-medium">Pick up package at </p>
                <p className="text-gray-500">{hoursValue.pickup}</p>
              </div>
              <div className="flex flex-col items-start bg-gray-50 border border-border py-2 px-2">
                <p className="text-sm font-medium">
                  Delivery package by{" "}
                  <p className="text-gray-500">{hoursValue.delivery}</p>
                </p>
              </div>
              <div className="flex flex-col items-start bg-gray-50 border border-border py-2 px-2">
                <p className="text-sm font-medium">Vehicle </p>
                <p className="capitalize text-gray-500">
                  {hoursValue.vehicleType}
                </p>
              </div>
            </div>
            <Button
              variant={"freightBlue"}
              onClick={() =>
                navigate(`/instant-bookings/${params.instantFreightId}`)
              }
              className="w-60 ml-auto"
            >
              View Instant Freight
            </Button>
          </div>
        ) : (
          <Form {...assignFreight}>
            <form
              className="flex flex-col w-full"
              onSubmit={assignFreight.handleSubmit(onSubmit)}
            >
              <div className="w-full mb-4">
                <h1 className="text-sm font-medium mb-2">Hour Pickup</h1>

                <Controller
                  control={assignFreight.control}
                  name="hourPickupEstimated"
                  render={({ field }) => (
                    <div className="">
                      <TimePicker date={field.value} setDate={field.onChange} />
                      {/* <TimePicker
                        className={"h-10 outline-none"}
                        // format="h:m:s a"
                        {...field}
                        onChange={(value: any) => {
                          assignFreight.setValue("hourPickupEstimated", value);
                          onChangePickup(value);
                        }}
                        value={pickupvalue}
                      /> */}
                    </div>
                  )}
                />
              </div>

              <div className="w-full mb-4">
                <h1 className="text-sm font-medium mb-2">Hour Delivery</h1>
                <Controller
                  control={assignFreight.control}
                  name="hourDeliveryEstimated"
                  render={({ field }) => (
                    <div>
                      <TimePicker date={field.value} setDate={field.onChange} />
                      {/* <TimePicker
                        className={"h-10 outline-none"}
                        // format="h:m:s a"
                        minTime={pickupvalue}
                        {...field}
                        onChange={(value: any) => {
                          assignFreight.setValue(
                            "hourDeliveryEstimated",
                            value
                          );
                          onChangeDelivery(value);
                        }}
                        value={deliveryValue}
                      /> */}
                    </div>
                  )}
                />
              </div>
              <div className="w-full mb-4">
                <h1 className="text-sm font-medium mb-2">Vehicle Type</h1>
                <Controller
                  control={assignFreight.control}
                  name="vehicleType"
                  render={({ field }) => (
                    <div>
                      <Select
                        {...field}
                        onValueChange={(val) => {
                          assignFreight.setValue("vehicleType", val);
                          onChangeVehicle(val);
                        }}
                      >
                        <SelectTrigger className="w-60">
                          <SelectValue placeholder="Select vehicle type" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="car">Car</SelectItem>
                          <SelectItem value="bike">Bike</SelectItem>
                          <SelectItem value="truck">Truck</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                  )}
                />
              </div>

              <Button
                disabled={loading}
                className="ml-auto  w-40"
                variant={"freightBlue"}
              >
                {loading ? (
                  <>
                    <Loader2 className="h-5 w-5 animate-spin" />
                  </>
                ) : (
                  <p className="text-sm font-medium">Accept Freight</p>
                )}
              </Button>
            </form>
          </Form>
        )}
      </div>
    </SidebarLayout>
  );
};

export default AssignInstantFreight;
