import { zodResolver } from "@hookform/resolvers/zod";
import { Package } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { BASE_URL, UPDATE_STOP_STATUS } from "src/api/config";
import { useUpdateStatusStop } from "src/hooks/use-update-status-stop";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { z } from "zod";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel } from "../ui/form";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "../ui/input-otp";
const updateStatusOptions = [
  {
    id: 0,
    name: "Picking up",
    value: "picking-up",
  },
  {
    id: 1,
    name: "In Transit",
    value: "in-transit",
  },
  {
    id: 2,
    name: "Confirmed",
    value: "confirmed",
  },
  {
    id: 3,
    name: "Cancelled",
    value: "cancelled",
  },
];

const formStatusSchema = z.object({
  status: z.string().min(1),
});

interface ModalUpdateStatusStopsProp {
  refetch: () => void;
}
const confirmStopSchema = z.object({
  optNumber: z.string().min(1),
});

const ModalUpdateStatusStop: React.FC<ModalUpdateStatusStopsProp> = ({
  refetch,
}) => {
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const [status, setStatus] = useState("");
  const modalUpdateStatusStop = useUpdateStatusStop();
  const [loadingChangingStatus, setLoadingChangingStatus] = useState(false);

  const confirmStopReason = useForm<z.infer<typeof confirmStopSchema>>({
    resolver: zodResolver(confirmStopSchema),
    defaultValues: {
      optNumber: "",
    },
  });

  const updateStopStatus = async (otpNumber: string) => {
    setLoadingChangingStatus(true);

    // Ensure the URL is properly concatenated
    const apiUrl = `${BASE_URL}${UPDATE_STOP_STATUS}`;
    console.log("API URL:", apiUrl); // Debugging log
    console.log("token:", token);
    console.log("user_id: ", user_id);
    console.log("stop_id: ", modalUpdateStatusStop.stopId);
    console.log("stop status: ", modalUpdateStatusStop.status);
    console.log("stop status changes to: ", modalUpdateStatusStop.statusChange);

    try {
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
        body: JSON.stringify({
          user_id: user_id,
          stop_id: modalUpdateStatusStop.stopId,
          status: modalUpdateStatusStop.statusChange,
          otp: otpNumber,
        }),
      });

      const data = await response.json();
      console.log("Response Data:", data);

      if (data.message === "Customer stop status updated successfully") {
        modalUpdateStatusStop.onClose();
        refetch();
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      console.error("Error while updating stop status:", err);
      toast.error("An error occurred.");
    } finally {
      setLoadingChangingStatus(false);
    }
  };

  useEffect(() => {
    formStatus.reset({
      status: modalUpdateStatusStop.status,
    });
  }, [modalUpdateStatusStop.status]);

  const formStatus = useForm<z.infer<typeof formStatusSchema>>({
    resolver: zodResolver(formStatusSchema),
    defaultValues: {
      status: "",
    },
  });
  const onSubmit = async (values: z.infer<typeof confirmStopSchema>) => {
    updateStopStatus(values.optNumber);
    // console.log("optNumber", values.optNumber);
  };

  // const onSubmit = async (values: z.infer<typeof formStatusSchema>) => {};
  return (
    <Dialog
      open={modalUpdateStatusStop.isOpen}
      onOpenChange={modalUpdateStatusStop.onClose}
    >
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-center">
            <div className="h-20 w-20 border border-border rounded-full flex items-center justify-center">
              <Package className="h-10 w-10 text-statusInTransit" />
            </div>
            <div className="mt-4 flex flex-col items-center pb-10">
              <h1 className="text-xl font-medium">Are you sure? </h1>
              <p className="text-lg">
                You want to set this freight to{" "}
                {modalUpdateStatusStop.statusChangeDisplay}
              </p>
            </div>
          </div>
          {modalUpdateStatusStop.statusChangeDisplay === "Complete" && (
            <Form {...confirmStopReason}>
              <form
                onSubmit={confirmStopReason.handleSubmit(onSubmit)}
                className="w-full flex flex-col justify-center items-center"
              >
                <FormField
                  name="optNumber"
                  control={confirmStopReason.control}
                  render={({ field }) => (
                    <FormControl className="">
                      <FormItem className="">
                        <FormLabel>Verify OTP Number</FormLabel>
                        <InputOTP {...field} maxLength={6}>
                          <InputOTPGroup>
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                            <InputOTPSlot index={2} />
                          </InputOTPGroup>
                          <InputOTPSeparator />
                          <InputOTPGroup>
                            <InputOTPSlot index={3} />
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                          </InputOTPGroup>
                        </InputOTP>
                        {/* <Input
                          {...field}
                          name="otpNumber"
                          className="w-full dark:bg-transparent"
                        /> */}
                      </FormItem>
                    </FormControl>
                  )}
                />
              </form>
            </Form>
          )}
        </DialogHeader>
        <div className="w-full justify-end items-end flex space-x-2">
          {/* Cancel Button */}
          <Button onClick={modalUpdateStatusStop.onClose} variant={"outline"}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (modalUpdateStatusStop.statusChangeDisplay === "Complete") {
                // Trigger OTP form submission only if statusChange is complete
                confirmStopReason.handleSubmit(onSubmit)();
              } else {
                // Call updateStopStatus with an empty OTP if statusChange is not complete
                updateStopStatus("");
              }
            }}
            variant={"freightBlue"}
            disabled={loadingChangingStatus} // Disable while loading
          >
            {loadingChangingStatus ? "Updating..." : "Ok"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalUpdateStatusStop;
