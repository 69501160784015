// createVanSVG.js
export function CreateSvg(percentage, options = {}) {
  const height = 84; // Total height of the SVG
  const fillHeight = Math.max(0, Math.min((percentage / 100) * height, height)); // Calculate fill height based on percentage
  const yPosition = height - fillHeight; // Calculate the y position for the fill
  console.log("height", height);

  const {
    vanBodyColor = "#444", // Default van body color
    fillColor = "#444", // Default fill color
    width = 300, // Default width
    svgHeight = 150, // Default height
  } = options;

  return `
 <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="${width}"
      height="${svgHeight}"
      viewBox="0 0 150 ${height}"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,84.000000) scale(0.100000,-0.100000)"
        fill="none"
        stroke="none"
      >
        {/* Van Body background filled with a customizable color */}
        <path
          d="M242 575 c-19 -24 -42 -102 -42 -143 0 -9 -7 -30 -15 -46 -17 -32
          -20 -102 -5 -111 6 -3 10 -14 10 -24 0 -38 32 -51 122 -51 72 0 88 -3 103 -20
          23 -26 67 -26 90 0 17 19 31 20 297 20 265 0 281 -1 305 -20 14 -11 36 -20 48
          -20 12 0 34 9 48 20 14 11 40 20 60 20 36 0 61 20 69 56 5 18 2 20 -20 16 -20
          -4 -24 -2 -19 12 4 9 2 16 -3 16 -6 0 -10 -5 -10 -11 0 -7 -14 -9 -42 -4 -50
          7 -92 7 -205 -1 l-82 -5 -4 28 -4 28 -2 -27 -1 -28 -214 0 c-133 0 -217 4
          -221 10 -3 6 -24 10 -45 10 -21 0 -42 -4 -45 -10 -3 -5 -46 -10 -96 -10 l-89
          0 0 45 c0 25 -5 54 -11 64 -9 14 -9 32 2 77 l14 59 330 3 c182 2 363 0 403 -3
          74 -7 74 -7 131 -65 45 -46 66 -59 99 -65 52 -8 106 -44 110 -72 2 -12 9 -24
          17 -27 11 -4 12 0 4 22 -16 43 -47 69 -97 83 -33 9 -65 31 -116 80 -126 118
          -105 114 -507 117 l-347 3 -20 -26z m734 -15 l41 -25 -286 3 c-157 1 -333 3
          -391 5 l-105 2 23 23 23 23 327 -3 327 -3 41 -25z m-478 -292 c15 -15 15 -61
          0 -76 -15 -15 -61 -15 -76 0 -17 17 -15 54 4 72 18 19 55 21 72 4z m697 -8
          c35 -39 -11 -103 -57 -79 -10 5 -23 19 -29 30 -9 17 -8 20 2 14 9 -6 10 -3 6
          10 -16 41 45 61 78 25z m-807 -32 c-7 -20 -138 -23 -163 -4 -18 14 -12 15 73
          16 71 0 93 -3 90 -12z m690 0 c-4 -10 -66 -13 -268 -13 -202 0 -264 3 -267 13
          -4 9 54 12 267 12 213 0 271 -3 268 -12z"
          fill="${vanBodyColor}" // Fill the van body background with a customizable color
        />

        {/* Dynamic fill based on the percentage */}
        <path
          d="M242 575 c-19 -24 -42 -102 -42 -143 0 -9 -7 -30 -15 -46 -17 -32
          -20 -102 -5 -111 6 -3 10 -14 10 -24 0 -38 32 -51 122 -51 72 0 88 -3 103 -20
          23 -26 67 -26 90 0 17 19 31 20 297 20 265 0 281 -1 305 -20 14 -11 36 -20 48
          -20 12 0 34 9 48 20 14 11 40 20 60 20 36 0 61 20 69 56 5 18 2 20 -20 16 -20
          -4 -24 -2 -19 12 4 9 2 16 -3 16 -6 0 -10 -5 -10 -11 0 -7 -14 -9 -42 -4 -50
          7 -92 7 -205 -1 l-82 -5 -4 28 -4 28 -2 -27 -1 -28 -214 0 c-133 0 -217 4
          -221 10 -3 6 -24 10 -45 10 -21 0 -42 -4 -45 -10 -3 -5 -46 -10 -96 -10 l-89
          0 0 45 c0 25 -5 54 -11 64 -9 14 -9 32 2 77 l14 59 330 3 c182 2 363 0 403 -3
          74 -7 74 -7 131 -65 45 -46 66 -59 99 -65 52 -8 106 -44 110 -72 2 -12 9 -24
          17 -27 11 -4 12 0 4 22 -16 43 -47 69 -97 83 -33 9 -65 31 -116 80 -126 118
          -105 114 -507 117 l-347 3 -20 -26z"
          fill="${fillColor}" // Fill with a customizable color
          opacity="${percentage / 100}" // Adjust opacity based on percentage
        />
      </g>
    </svg>
  `;
}
