import { useEffect, useState } from "react";
import Header from "../components/header/Header";
import useLocalStorage from "../hooks/use-local-storage";

//@ts-ignore
import { useJsApiLoader } from "@react-google-maps/api";
import { Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Package from "src/assets/images/package.png";
import { GOOGLE_API_KEY } from "../api/config";

const SendNowFreight = () => {
  const [instantItem, setInstantItem] = useLocalStorage("instant_item");
  const [originLocation, setOriginLocation] = useState<{
    longitude: number;
    latitude: number;
  }>();
  const [destinationLocation, setDestinationLocation] = useState<{
    longitude: number;
    latitude: number;
  }>();
  const [locationFreight, setLocationFreight] = useLocalStorage(
    "instant_freight_location"
  );

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });
  const navigate = useNavigate();
  async function calculateRuote() {
    const directionsService = new google.maps.DirectionsService();

    const results = await directionsService.route({
      origin: {
        lat: originLocation?.latitude!,
        lng: originLocation?.longitude!,
      },
      destination: {
        lat: destinationLocation?.latitude!,
        lng: destinationLocation?.longitude!,
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setInstantItem((prev: any) => ({
      ...prev,
      distance: results.routes[0].legs[0].distance?.text,
      origin_address: results.routes[0].legs[0].start_address,
      //@ts-ignore
      origin_zipcode: `${results.routes[0].legs[0].start_address}`.match(
        /\b\d{5}\b/g
      )[0],
      origin_city: locationFreight.origin.label,
      origin_lat: originLocation?.latitude,
      origin_lng: originLocation?.longitude,
      destination_address: results.routes[0].legs[0].end_address,
      //@ts-ignore
      destination_zipcode: `${results.routes[0].legs[0].end_address}`.match(
        /\b\d{5}\b/g
      )[0],
      destination_city: locationFreight.destination.label,
      destination_lat: destinationLocation?.latitude,
      destination_lng: destinationLocation?.longitude,
    }));

    navigate(`/shipping-package/${locationFreight.weight}`);
  }

  function getCityOriginCoordinates() {
    const address = locationFreight.origin.label;

    const geocoder = new google.maps.Geocoder();

    geocoder.geocode(
      {
        address: address,
      },
      function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          const Lat = results?.[0].geometry.location.lat();
          const Lng = results?.[0].geometry.location.lng();
          setOriginLocation({
            latitude: Lat!,
            longitude: Lng!,
          });
        }
      }
    );
  }
  function getCityDestinationCoordinates() {
    const address = locationFreight.destination.label;

    const geocoder = new google.maps.Geocoder();

    geocoder.geocode(
      {
        address: address,
      },
      function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          const Lat = results?.[0].geometry.location.lat();
          const Lng = results?.[0].geometry.location.lng();

          setDestinationLocation({
            latitude: Lat!,
            longitude: Lng!,
          });
        }
      }
    );
  }
  useEffect(() => {
    if (isLoaded) {
      getCityOriginCoordinates();
      getCityDestinationCoordinates();
    }
  }, [isLoaded]);

  useEffect(() => {
    if (originLocation?.latitude && destinationLocation?.latitude && isLoaded) {
      calculateRuote();
    }
  }, [originLocation, destinationLocation]);

  useEffect(() => {
    if (
      locationFreight.origin.label !== "" &&
      locationFreight.destination.label !== ""
    )
      calculateRuote();
  }, [locationFreight]);
  return (
    <div className="bg-gray-50 h-screen">
      <Header />
      <div className="flex items-center justify-between w-full border-b border-border pb-3 mb-4 max-w-7xl mx-auto mt-10">
        <h1 className="text-4xl font-medium ">
          Send <span className="text-2xl font-medium">small packages</span>
        </h1>
        <img src={Package} className="h-14 w-14 object-contain" alt="package" />
      </div>

      <div className="flex flex-col items-center justify-center">
        <Loader2 className="animate-spin h-10 w-10" />
        <p className="text-sm font-medium mt-4">Searching Location...</p>
      </div>
    </div>
  );
};

export default SendNowFreight;
