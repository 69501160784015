import { useCancelQuoteModal } from "src/hooks/use-cancel-quote";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { Check } from "lucide-react";
import { Button } from "../ui/button";
import { BASE_URL, TRANSPORTERCANCELQUOTE } from "src/api/config";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";

interface ModalCancelQuoteProps {
  refetch: () => void;
}

const ModalCancelQuote: React.FC<ModalCancelQuoteProps> = ({ refetch }) => {
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const modal = useCancelQuoteModal();

  const cancelQuote = async () => {
    const response = await fetch(`${BASE_URL}${TRANSPORTERCANCELQUOTE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        quote_id: modal.quoteId,
        user_id: user_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Quote cancelled successfully.") {
          modal.onClose();
          refetch();
        }
      });
    return response;
  };
  return (
    <Dialog open={modal.isOpen} onOpenChange={modal.onClose}>
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-center">
            <div className="h-20 w-20 border border-border rounded-full flex items-center justify-center">
              <Check className="h-10 w-10 text-green-500" />
            </div>
            <div className="mt-4 flex flex-col items-center pb-10">
              <h1 className="text-xl font-medium">Are you sure? </h1>
              <p className="text-lg">You want to cancel this quote</p>
            </div>
          </div>
        </DialogHeader>
        <div className="w-full justify-end items-end flex space-x-2">
          <Button onClick={() => modal.onClose()} variant={"outline"}>
            Cancel
          </Button>
          <Button onClick={() => cancelQuote()} variant={"freightBlue"}>
            Ok
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalCancelQuote;
