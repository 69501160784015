import { useNavigate, useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";
import { BASE_URL, GET_IMAGE, TRANSPORTERMYQUOTE } from "../api/config";
import small from "../assets/images/books-box.png";
import large from "../assets/images/moving-box.png";
import medium from "../assets/images/shoes-box.png";
import SidebarLayout from "../components/layout/SidebarLayout";
import ModalCancelQuote from "../components/modal/ModalCancelQuote";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";
import { useCancelQuoteModal } from "../hooks/use-cancel-quote";
import { formatFStatus } from "../lib/formatFStatus";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { BookingQuote } from "../types/types";

const CarrierQuotes = () => {
  const { user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const params = useParams();
  const modalCancelQuote = useCancelQuoteModal();
  console.log(params);
  const navigate = useNavigate();

  const getQuotes = async () => {
    const response = await fetch(`${BASE_URL}${TRANSPORTERMYQUOTE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id.toString(),
        status: params.type,
      }),
    }).then((res) => res.json());

    return response;
  };

  const {
    data: quotes,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["quotes", params.type, user_id],
    queryFn: getQuotes,
  });

  if (params.type !== "")
    return (
      <SidebarLayout>
        <div className="flex items-center w-full justify-between border-b border-border pb-3 mb-4">
          <h1 className="text-lg font-semibold">My Quotes </h1>
          <p className="bg-gray-100 px-4 py-2 capitalize text-sm font-medium">
            {params.type!.slice(0, 1).toUpperCase() +
              params.type!.slice(1).toLowerCase()}
          </p>
        </div>
        <div className=" bg-white shadow">
          <Table className="border border-border">
            <TableHeader>
              <TableRow>
                <TableHead>Freight</TableHead>
                <TableHead>My Quote</TableHead>
                <TableHead>Freight</TableHead>
                <TableHead>Quote Status</TableHead>
              </TableRow>
            </TableHeader>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Skeleton className="h-20 w-20 rounded" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-28 rounded" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-28 rounded" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-36 rounded" />
                  </TableCell>

                  <TableCell>
                    <Skeleton className="h-5 w-40 rounded" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-10 rounded" />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {quotes?.data?.map((quote: BookingQuote) => (
                  <TableRow key={quote.quote_id}>
                    <TableCell className="">
                      <div className="flex flex-col ">
                        <div className="flex items-center space-x-2">
                          {quote.animal_img.length > 0 ? (
                            <img
                              src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${quote.animal_img[0].freight_img}&image_type=freight`}
                              alt="freight"
                              className=" w-32 object-contain rounded"
                            />
                          ) : (
                            <div className="flex items-center justify-center w-40 h-40 rounded-lg shadow-sm">
                              {quote?.freight_type_info?.freight_size.id ===
                                1 && (
                                <img
                                  src={small}
                                  className="h-22 w-22 object-contain cursor-pointer"
                                  alt="small package"
                                  onClick={() =>
                                    navigate(
                                      `/customer/freightDetails/${quote?.quote_id}`
                                    )
                                  }
                                />
                              )}
                              {quote?.freight_type_info?.freight_size.id ===
                                2 && (
                                <img
                                  src={medium}
                                  className="h-22 w-22 object-contain cursor-pointer"
                                  alt="medium package"
                                  onClick={() =>
                                    navigate(
                                      `/customer/freightDetails/${quote?.quote_id}`
                                    )
                                  }
                                />
                              )}
                              {quote?.freight_type_info?.freight_size.id ===
                                3 && (
                                <img
                                  src={large}
                                  className="h-22 w-22 object-contain cursor-pointer"
                                  alt="large package"
                                  onClick={() =>
                                    navigate(
                                      `/customer/freightDetails/${quote?.quote_id}`
                                    )
                                  }
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{quote.amount}€</TableCell>
                    <TableCell>
                      <div className="flex flex-col items-start">
                        <p
                          onClick={() =>
                            navigate(`/quote/viewDetails/${quote.quote_id}`)
                          }
                          className="text-sm font-medium hover:underline cursor-pointer text-freightBlueDark"
                        >
                          {quote.title}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell>
                      {quote.status.toUpperCase().slice(0, 1) +
                        quote.status.toLowerCase().slice(1)}{" "}
                      -{" "}
                      <span className="text-sm font-medium text-gray-500">
                        {formatFStatus(quote.fstatus)}
                      </span>
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-col items-start">
                        {quote.status === "active" && (
                          <Button variant={"freightBlue"} className="w-28">
                            Edit
                          </Button>
                        )}
                        {quote.status === "active" && (
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <Button
                                  onClick={() =>
                                    modalCancelQuote.onOpen(
                                      "cancelQuote",
                                      quote.quote_id
                                    )
                                  }
                                  variant={"outline"}
                                  className="w-28 mt-1"
                                >
                                  Cancel
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p className="text-sm font-medium text-gray-500">
                                  The transporter quote will be cancelled
                                </p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {!isLoading && quotes?.message === "No records were found." && (
            <div className="p-4 bg-white border-border border">
              <h1 className="font-medium text-sm text-gray-400">
                No quotes available.
              </h1>
            </div>
          )}
        </div>
        {modalCancelQuote.isOpen && <ModalCancelQuote refetch={refetch} />}
      </SidebarLayout>
    );
};

export default CarrierQuotes;
