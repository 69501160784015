import { useSendReviewStop } from "src/hooks/use-send-review-stop";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { Rating, Star } from "@smastrom/react-rating";
import { useState } from "react";
import { Textarea } from "../ui/textarea";
import { Button } from "../ui/button";
import { BASE_URL, SUBMIT_STOP_REVIEW } from "src/api/config";
import toast from "react-hot-toast";

interface ModalSubmitStopTripReviewProps {
  refetch: () => void;
  refetchReviews: () => void;
}
const starStyles = {
  itemShapes: Star,
  activeFillColor: "#ffb700",
  inactiveFillColor: "#fbf1a9",
};
const ModalSubmitStopTripReview: React.FC<ModalSubmitStopTripReviewProps> = ({
  refetch,
  refetchReviews,
}) => {
  const modalSubmitTripStopReview = useSendReviewStop();
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");

  const submitReview = async () => {
    const response = await fetch(`${BASE_URL}${SUBMIT_STOP_REVIEW}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        reviewed_by: user_id,
        reviewed_for: modalSubmitTripStopReview.transporterId,
        instant_freight_id: modalSubmitTripStopReview.stopId,
        rating: rating.toString(),
        review: review,
        user_id: user_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          modalSubmitTripStopReview.onClose();
          toast.success(data.message);
          refetchReviews();
          setRating(0);
          setReview("");
          refetch();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  };
  return (
    <Dialog
      open={modalSubmitTripStopReview.isOpen}
      onOpenChange={modalSubmitTripStopReview.onClose}
    >
      <DialogContent>
        <DialogHeader>
          {" "}
          <div className="flex flex-col items-start">
            <div className="flex flex-col items-center space-y-2 mb-10 font-semibold">
              Add Review
            </div>
          </div>
        </DialogHeader>
        <div className="flex items-center justify-between">
          <h1 className="text-sm font-medium text-gray-400">Rating</h1>
          <Rating
            itemStyles={starStyles}
            style={{ maxWidth: 150 }}
            value={rating}
            onChange={setRating}
          />
        </div>
        <div className="flex flex-col justify-between mt-4">
          <h1 className="text-sm font-medium pb-3">Tell us what you think</h1>
          <Textarea
            value={review}
            onChange={(e) => setReview(e.target.value)}
          />
        </div>
        <div className="w-full justify-end items-end flex space-x-2">
          <Button
            onClick={() => {
              modalSubmitTripStopReview.onClose();
            }}
            variant={"outline"}
          >
            Back
          </Button>
          <Button onClick={() => submitReview()} variant={"freightBlue"}>
            Submit review
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalSubmitStopTripReview;
