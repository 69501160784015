import { create } from "zustand";

export type ModalType = "confirmBooking";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  freightId: string;
  onOpen: (type: ModalType, freightId: string) => void;
  onClose: () => void;
}

export const useConfirmBooking = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  freightId: "",
  onOpen: (type, freightId) => set({ isOpen: true, type, freightId }),
  onClose: () => set({ type: null, isOpen: false }),
}));
