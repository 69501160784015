import { useParams } from "react-router-dom";
import Header from "../components/header/Header";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import Sidebar from "../components/sidebar/Sidebar";
import CustomerSidebar from "../components/sidebar/CustomerSidebar";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import SidebarLayout from "../components/layout/SidebarLayout";

const CustomerFeedback = () => {
  const { user_type, user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const params = useParams();
  return (
    <SidebarLayout>
      <div className="flex items-center justify-between w-full border-border border-b pb-3 space-x-2 mb-4">
        <h1 className="text-lg font-semibold ">Feedback List</h1>
      </div>
      <div className=" bg-white shadow p-4">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Feedback</TableHead>
              <TableHead>Details Rating</TableHead>
              <TableHead>Freight</TableHead>
              <TableHead>Carrier Name</TableHead>
              <TableHead>Date</TableHead>
              <TableHead>Edit</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody></TableBody>
        </Table>
      </div>
    </SidebarLayout>
    //   </div>
    // </div>
  );
};

export default CustomerFeedback;
