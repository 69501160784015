import { Ban } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { BASE_URL, GET_IMAGE } from "src/api/config";
import { useCancelBookingQuote } from "src/hooks/use-cancel-booking-quote";
import { cn } from "src/lib/utils";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { BookingCustomerType } from "src/types/types";
import small from "../../assets/images/books-box.png";
import large from "../../assets/images/moving-box.png";
import medium from "../../assets/images/shoes-box.png";
import { Button } from "../ui/button";
import { TableCell, TableRow } from "../ui/table";
interface CustomerBookingCardProps {
  booking: BookingCustomerType;
}

const CustomerBookingCard: React.FC<CustomerBookingCardProps> = ({
  booking,
}) => {
  const modalCancelBookingQuote = useCancelBookingQuote();
  const navigate = useNavigate();
  const { user_id } = userInfoUserStore((state) => state);

  return (
    <TableRow>
      <TableCell className="w-40">
        <div className="flex flex-col w-40">
          {booking?.animal_img.length > 0 ? (
            <img
              src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${booking?.animal_img[0].freight_img}&image_type=freight`}
              alt="freight"
              className=" w-32 object-contain rounded"
            />
          ) : (
            <div className="flex items-center justify-center w-40 h-40 rounded-lg shadow-sm">
              {booking?.freight_type_info?.freight_size.id === 1 && (
                <img
                  src={small}
                  className="h-22 w-22 object-contain cursor-pointer"
                  alt="small package"
                  onClick={() =>
                    navigate(`/customer/freightDetails/${booking?.booking_id}`)
                  }
                />
              )}
              {booking?.freight_type_info?.freight_size.id === 2 && (
                <img
                  src={medium}
                  className="h-22 w-22 object-contain cursor-pointer"
                  alt="medium package"
                  onClick={() =>
                    navigate(`/customer/freightDetails/${booking?.booking_id}`)
                  }
                />
              )}
              {booking?.freight_type_info?.freight_size.id === 3 && (
                <img
                  src={large}
                  className="h-22 w-22 object-contain cursor-pointer"
                  alt="large package"
                  onClick={() =>
                    navigate(`/customer/freightDetails/${booking?.booking_id}`)
                  }
                />
              )}
            </div>
          )}
        </div>
      </TableCell>
      <TableCell>
        <div>
          <p
            onClick={() => navigate(`/customer/booking/${booking?.booking_id}`)}
            className="hover:underline text-freightBlueDark cursor-pointer"
          >
            {booking.title}
          </p>
        </div>
      </TableCell>
      <TableCell>{Number(booking.quote_amount).toFixed(2)}€</TableCell>

      <TableCell>{booking.transporter_name}</TableCell>
      <TableCell>
        <Button
          className={cn(
            "rounded px-4 py-2 w-36 text-sm font-medium text-white",
            booking.status === "complete" && "bg-statusConfirmed",
            booking.status === "booked" && "bg-statusPaid",
            booking.status === "cancelled" && "bg-gray-500"
          )}
        >
          {booking.status.toUpperCase()}
        </Button>
      </TableCell>
      <TableCell className="w-60">
        {booking.status === "cancel_pending" ? (
          <div className="">
            <p className="text-sn font-medium">Cancelled on</p>
            <p className="text-sm font-medium text-gray-500">
              {booking.date_cancel_pending}
            </p>
          </div>
        ) : (
          <>
            {booking.booking_status === "booked" && (
              <div className="flex flex-col space-y-3 ">
                <Button
                  variant={"freightBlue"}
                  className="w-60"
                  onClick={() =>
                    modalCancelBookingQuote.onOpen(
                      "cancelBookingQuote",
                      booking.booking_id.toString()
                    )
                  }
                >
                  <Ban className="h-4 w-4 mr-2" />
                  Cancel Booking
                </Button>
              </div>
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default CustomerBookingCard;
