import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"

const userStripeCustomerStore = create(persist<{ customerId: string; addCustomerId: (customerId: string) => void }>((set) => ({
    customerId: "",
    addCustomerId: (customerId) => set({ customerId: customerId })
}),
    {
        name: "customer_id",
        storage: createJSONStorage(() => localStorage)
    }))


export { userStripeCustomerStore }