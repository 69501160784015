import { useForm } from "react-hook-form";
import Header from "../components/header/Header";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form";
import { BASE_URL, RESEND_OTP, VERIFY_OTP } from "../api/config";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { Button } from "../components/ui/button";
import { userInfoUserStore } from "../store/useUserInfoStore";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Loader2 } from "lucide-react";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "../components/ui/input-otp";

const verifyOtpSchema = z.object({
  otpNumber: z.string().min(1),
});

const VerifyOtp = () => {
  const navigate = useNavigate();
  const { user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const formVerify = useForm<z.infer<typeof verifyOtpSchema>>({
    resolver: zodResolver(verifyOtpSchema),
    defaultValues: {
      otpNumber: "",
    },
  });
  const [loadingVerify, setLoadingVerify] = useState(false);

  const onSubmit = (values: z.infer<typeof verifyOtpSchema>) => {
    setLoadingVerify(true);
    verifyOtp(values.otpNumber);
  };

  const resendOtp = async () => {
    const response = await fetch(
      `${BASE_URL}${RESEND_OTP}?user_id=${user_id}`,
      {
        method: "POST",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "OTP sent successfully.") {
          toast.success(data.message);
        }
      });

    return response;
  };

  const verifyOtp = async (otpVerify: string) => {
    const response = await fetch(
      `${BASE_URL}${VERIFY_OTP}?user_id=${user_id}&otp=${otpVerify}`,
      {
        method: "POST",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "OTP verified successfully") {
          toast.success(`${data.message}`);
          navigate("/sign-in");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingVerify(false);
      });

    return response;
  };

  return (
    <div className="h-screen bg-gray-50">
      <Header />
      <div className=" ">
        <div className="max-w-4xl mx-auto pt-20">
          <div className="flex flex-col bg-white p-10 shadow">
            <Form {...formVerify}>
              <form
                className="flex flex-col w-full dark:bg-transparent space-y-4"
                onSubmit={formVerify.handleSubmit(onSubmit)}
              >
                <FormField
                  name="otpNumber"
                  control={formVerify.control}
                  render={({ field }) => (
                    <FormControl>
                      <FormItem>
                        <FormLabel>Otp number</FormLabel>
                        {/* <Input
                          {...field}
                          name="otpNumber"
                          className="w-full dark:bg-transparent"
                        /> */}
                        <InputOTP {...field} maxLength={6}>
                          <InputOTPGroup>
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                            <InputOTPSlot index={2} />
                          </InputOTPGroup>
                          <InputOTPSeparator />
                          <InputOTPGroup>
                            <InputOTPSlot index={3} />
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                          </InputOTPGroup>
                        </InputOTP>
                      </FormItem>
                    </FormControl>
                  )}
                />
                <p
                  onClick={() => resendOtp()}
                  className="text-sm font-medium text-freightBlueDark mt-4 cursor-pointer hover:underline"
                >
                  Resend code?
                </p>
                <Button
                  className="ml-auto w-60"
                  disabled={loadingVerify}
                  variant={"freightBlue"}
                >
                  {loadingVerify ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                    </>
                  ) : (
                    <p className="text-sm font-medium">Verify OTP Number</p>
                  )}
                </Button>
              </form>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
