import React, { useState } from "react";
import Header from "../components/header/Header";
import { Elipses } from "../components/home/Elipses";
import Footer from "../components/layout/Footer";
import { BASE_URL } from "../api/config";
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showProgress, setShowProgress] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault();
    setShowProgress(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, subject, message }),
    };

    try {
      const response = await fetch(`${BASE_URL}contact`, requestOptions);
      if (response.ok) {
        // Manejar la respuesta aquí
        console.log("Mensaje enviado exitosamente.");
        setShowToast(true);
      } else {
        // Manejar errores de la respuesta aquí
        console.error("Error al enviar el mensaje:", response.statusText);
      }
    } catch (error) {
      // Manejar errores de la solicitud aquí
      console.error("Error al enviar la solicitud:", error);
    } finally {
      // Ocultar LinearProgress después de 2 segundos
      setTimeout(() => {
        setShowProgress(false);
      }, 2000);
    }
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  return (
    <div className="overflow-x-hidden">
      <div>
        <Header />
      </div>
      <div className="pt-32">
        <Elipses />
      </div>
      <div>
        <section className="bg-white dark:bg-gray-900">
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
              Contact Us
            </h2>
            <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
              Got a technical issue? Want to send feedback about a beta feature?
              Need details about our Business plan? Let us know.
            </p>
            <form onSubmit={handleSubmit} className="space-y-8">
              <div>
                <label
                  id="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Your email
                </label>
                <input
                  name="email"
                  id="email"
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="Loremipsum@runnme.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="Let us know how we can help you"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
              </div>
              <div className="sm:col-span-2">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                  Your message
                </label>
                <textarea
                  id="message"
                  className="h-[400px] block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Leave a comment..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                {showProgress && <LinearProgress />}
              </div>
              <button
                type="submit"
                className="h-[40px] w-[250px] mt-14 bg-[#ff380f] shadow-lg hover:bg-[#f4775efb] hover:shadow-4xl text-white font-bold rounded-2xl transition duration-300 ease-in-out ml-auto"
              >
                Send message
              </button>
              <Snackbar
                open={showToast}
                autoHideDuration={2000}
                onClose={handleCloseToast}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MuiAlert
                  onClose={handleCloseToast}
                  severity="success"
                  elevation={6}
                  variant="filled"
                >
                  Message sent correctly
                </MuiAlert>
              </Snackbar>
            </form>
          </div>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
