import { create } from "zustand";

export type ModalType = "assignInstant";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  instantId: string;
  onOpen: (type: ModalType, instantId: string) => void;
  onClose: () => void;
}

export const useAssignInstant = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  instantState: null,
  instantId: "",
  onOpen: (type, instantId) => set({ isOpen: true, type, instantId }),
  onClose: () => set({ type: null, isOpen: false }),
}));
