import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { ArrowLeft, ArrowRight, ChevronDown, Loader2 } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { ALL_ROUTES, BASE_URL } from "../api/config";
import Header from "../components/header/Header";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import { Form } from "../components/ui/form";
import { Input } from "../components/ui/input";
import { cn } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { RouteType } from "../types/types";

import { BiSlider } from "react-icons/bi";
import { LuArrowRightLeft } from "react-icons/lu";
import { useDebounce } from "use-debounce";
import CategoryFilter from "../components/filters/CategoriesFilter";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";

import { useJsApiLoader } from "@react-google-maps/api";
import { FaSpinner } from "react-icons/fa";
import { GOOGLE_API_KEY } from "../api/config";
//@ts-ignore
import { ReactComponent as EmptySvg } from "../assets/images/data-not-found.svg";
import RouteAllCard from "../components/card/RouteAllCard";
import ModalBookTripStop from "../components/modal/ModalBookTripStop";
import { useAssignOrder } from "../hooks/use-assign-order";
import { useBookTripStop } from "../hooks/use-book-trip-stop";

const sortOptions = [
  {
    value: "asc",
    label: "Date Listed (Newest to Oldest)",
  },
  {
    value: "desc",
    label: "Date Listed (Oldest to Newest)",
  },
  // {
  //   id: 3,
  //   label: "Distance (Longest to Shortest)",
  // },
  // {
  //   id: 4,
  //   label: "Distance (Shortest to Longest)",
  // },
  // {
  //   id: 5,
  //   label: "Origin (A to Z)",
  // },
  // {
  //   id: 6,
  //   label: "Origin (Z to A)",
  // },
  // {
  //   id: 7,
  //   label: "Destination (A to Z)",
  // },
  // {
  //   id: 8,
  //   label: "Destination (Z to A)",
  // },
];

const radiusOptions = [
  {
    id: 1,
    value: "10km",
  },
  {
    id: 2,
    value: "20km",
  },
  {
    id: 3,
    value: "50km",
  },
  {
    id: 4,
    value: "100km",
  },
  {
    id: 5,
    value: "150km",
  },
  {
    id: 6,
    value: "200km",
  },
  {
    id: 7,
    value: "300km",
  },
  {
    id: 8,
    value: "400km",
  },
  {
    id: 9,
    value: "500km",
  },
];

const originDestinationFormSchema = z.object({
  originLocation: z.any().optional(),
  originRadius: z.string().min(1).optional(),
  destinationLocation: z.any().optional(),
  destinationRadius: z.string().min(1).optional(),
  readyOnPickup: z.any(),
  readyonDropoff: z.any(),
});

const FindTrip = () => {
  const token = useTokenStoreStorage((state) => state.token);
  const modalBookTripStop = useBookTripStop();
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [originInputValue, setOriginInputValue] = useState("");
  const [destinationInputValue, setDestinationInputValue] = useState("");
  const [startDateInputValue, setStartDateInputValue] = useState("");
  const [endDateInputValue, setEndDateInputValue] = useState("");
  const [distanceInputValue, setDistanceInputValue] = useState("");
  const [durationInputValue, setDurationInputValue] = useState("");
  const [debouncedInputValue] = useDebounce(inputValue, 500);
  const [debounceOriginInputValue] = useDebounce(originInputValue, 500);
  const [deboundeDestinationInputValue] = useDebounce(
    destinationInputValue,
    500
  );
  const [ordersType, setOrdersType] = useState("ecom");
  useEffect(() => {
    modalBookTripStop.onClose();
  }, []);
  const [debouncedStartDateInputValue] = useDebounce(startDateInputValue, 500);
  const [debouncedEndDateInputValue] = useDebounce(endDateInputValue, 500);
  const [debouncedDistanceInputValue] = useDebounce(distanceInputValue, 500);
  const [debouncedDurationInputValue] = useDebounce(durationInputValue, 500);
  const modalAssignOrder = useAssignOrder();
  const [durationDistanceFilters, setDurationDistanceFilters] = useState({
    distance: "",
    duration: "",
  });

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleOriginInputChange = (event: any) => {
    setOriginInputValue(event.target.value);
  };
  const handleDestinationInputChange = (event: any) => {
    setDestinationInputValue(event.target.value);
  };
  const handleChangeStartDateChange = (event: any) => {
    setStartDateInputValue(event.target.value);
  };
  const handleChangeEndDateChange = (event: any) => {
    setEndDateInputValue(event.target.value);
  };

  const handleDistanceChange = (event: any) => {
    setDistanceInputValue(event.target.value);
  };

  const handleDurationChange = (event: any) => {
    setDurationInputValue(event.target.value);
  };

  const handleChangeDurationDistance = (
    distanceValue: string,
    durationValue: string
  ) => {
    setDurationDistanceFilters({
      distance: distanceValue,
      duration: durationValue,
    });
  };

  const [directionsResponse, setDirectionsRespnose] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [page, setPage] = useState(1);

  const [allCategories, setAllCategories] = useState<any[]>([]);
  const [allCategoriesChecked, setAllCategoriesChecked] = useState<any[]>([]);

  const [allSubcategoriesChecked, setAllSubcategoriesChecked] = useState<any[]>(
    []
  );

  const [sortBy, setSortBy] = useState("desc");

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });
  const [position, setPostition] = useState<{
    latitude: number | null;
    longitude: number | null;
  }>({
    latitude: null,
    longitude: null,
  });

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPostition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  const [selectedCategories, setSelectedCategories] = useState<
    { catname: string; catid: number }[]
  >([]);

  const originRef = useRef<any>();
  const destinationRef = useRef<any>();

  async function calculateRuote() {
    if (originRef.current.value === "" || destinationRef.current.value === "") {
      return;
    }

    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destinationRef.current.value,
      travelMode: google.maps.TravelMode.DRIVING,
    });
    //@ts-ignore
    setDirectionsRespnose(results);
    //@ts-ignore
    setDistance(results.routes[0].legs[0].distance.text);
    //@ts-ignore
    setDuration(results.routes[0].legs[0].duration?.text);
  }

  function clearRoute() {
    setDirectionsRespnose(null);
    setDistance("");
    setDuration("");
    originRef.current.value = "";
    destinationRef.current.value = "";
  }

  const originDestination = useForm<
    z.infer<typeof originDestinationFormSchema>
  >({
    resolver: zodResolver(originDestinationFormSchema),
    defaultValues: {
      destinationLocation: "",
      destinationRadius: "",
      originLocation: "",
      originRadius: "",
      readyonDropoff: "",
      readyOnPickup: "",
    },
  });

  const onSubmit = async (
    values: z.infer<typeof originDestinationFormSchema>
  ) => {
    calculateRuote();
  };

  const getRoute = async () => {
    const response = await fetch(
      `${BASE_URL}${ALL_ROUTES}?sort=${sortBy}&page=${page}&items_per_page=10&origin=${debounceOriginInputValue}&destination=${deboundeDestinationInputValue}&distance=${debouncedDistanceInputValue}&duration=${debouncedDurationInputValue}&hasStops=true`,

      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };
  const {
    data: routes,
    isLoading,
    refetch: refetchRoutes,
  } = useQuery({
    queryKey: [
      "routes_user",
      page,
      debounceOriginInputValue,
      deboundeDestinationInputValue,
      debouncedDistanceInputValue,
      debouncedDurationInputValue,
    ],
    queryFn: getRoute,
  });

  const onFilteredChange = (filteredCategories: any) => {
    setAllCategories(filteredCategories);
    setAllCategoriesChecked(
      filteredCategories.filter((category: any) => category.checked === true)
    );
  };

  const onFilteredSubChange = (filteredCategories: any) => {
    setAllSubcategoriesChecked(filteredCategories);
  };
  return (
    <div className="bg-gray-50 min-h-screen overflow-x-hidden">
      <Header />
      <div className="w-full bg-freightBlueDark">
        <div className="   max-w-7xl  mx-auto p-4 py-10 ">
          <h1 className="text-2xl font-medium text-white">Browse Routes</h1>
          <div className="sm:flex sm:items-center">
            <Form {...originDestination}>
              <form
                className="sm:flex items-center sm:space-x-10 mt-6"
                onSubmit={originDestination.handleSubmit(onSubmit)}
              >
                <div className="flex items-end space-x-4">
                  <div className="text-white">
                    <div className="flex flex-col  items-start ">
                      <h1 className="text-sm font-medium mb-2">Origin: </h1>
                      <Input
                        type="text"
                        className="text-sm text-black"
                        value={originInputValue}
                        onChange={handleOriginInputChange}
                        placeholder="Origin"
                      />
                    </div>
                  </div>
                  <LuArrowRightLeft className="h-4 w-4 text-freightBlue  mb-3" />

                  <div className="flex items-center">
                    <div className="flex flex-col">
                      <h1 className="text-sm font-medium mb-2 text-white">
                        Destination
                      </h1>
                      <div className="flex items-center space-x-2">
                        <div className="flex items-center space-x-10">
                          <Input
                            type="text"
                            value={destinationInputValue}
                            onChange={handleDestinationInputChange}
                            placeholder="Destination"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <p className="text-sm font-medium mb-2 text-white">
                    Vehicle type
                  </p>

                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <div className=" h-10  w-40 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 flex items-center justify-between">
                        <p>Vehicle type</p>
                        <ChevronDown className="h-5 w-5" />
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <CategoryFilter
                        onFilteredSubChange={onFilteredSubChange}
                        categories={allCategories}
                        onFilteredChange={onFilteredChange}
                      />
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
                <div>
                  <p className="text-sm font-medium mb-2 text-white">
                    Ready on
                  </p>
                  <div className="flex items-center">
                    <Input
                      type="date"
                      className="focus-visible:ring-blue-500 focus-visible:ring-2 mr-2 w-36 transition duration-200 ease-in-out outline-none border-[lightgray]  border h-[38px] rounded px-2 font-paragraph text-sm"
                      value={startDateInputValue}
                      onChange={handleChangeStartDateChange}
                    />

                    <Input
                      type="date"
                      value={endDateInputValue}
                      onChange={handleChangeEndDateChange}
                      className="focus-visible:ring-blue-500 focus-visible:ring-2 w-36 transition duration-200 ease-in-out outline-none border-[lightgray]  border h-[38px] rounded px-2 font-paragraph text-sm"
                    />
                  </div>
                </div>
                <div>
                  <p className="text-sm font-medium mb-2 text-white">
                    More Filters
                  </p>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <div className=" h-10  w-40 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 flex items-center justify-between">
                        <BiSlider className="h-4 w-4" />

                        <p>More Filters</p>
                        <ChevronDown className="h-5 w-5" />
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <div className="flex flex-col w-60 p-2">
                        <div className="flex flex-col items-start py-4">
                          <h1 className="text-sm font-medium mb-2">Distance</h1>
                          <Input
                            type="text"
                            placeholder="Distance (kms)"
                            value={distanceInputValue}
                            onChange={handleDistanceChange}
                          />
                        </div>
                        <div className="flex flex-col items-start pb-4">
                          <h1 className="text-sm font-medium mb-2">Duration</h1>
                          <Input
                            type="text"
                            placeholder="Duration (*h **min)"
                            value={durationInputValue}
                            onChange={handleDurationChange}
                          />
                        </div>
                        <Button
                          onClick={() =>
                            handleChangeDurationDistance(
                              debouncedDistanceInputValue,
                              debouncedDurationInputValue
                            )
                          }
                          variant={"freightBlue"}
                        >
                          Apply Filters
                        </Button>
                      </div>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
                <div className="text-white  text-sm font-medium ">Reset</div>
              </form>
            </Form>
          </div>
        </div>
      </div>
      {isLoaded && <div id="map" className="h-full"></div>}
      <div className="  mt-20  max-w-7xl mx-auto">
        <div className="pb-4">
          <div className="flex items-center justify-between">
            <div className="flex items-end space-x-4">
              <h1 className="text-xl font-medium">
                {isLoading ? (
                  <div className="flex items-center space-x-1">
                    <p>Top</p>
                    <FaSpinner className="h-5 w-5 animate-spin mx-2" />
                    <p>Results</p>
                  </div>
                ) : (
                  <>{`Top ${routes?.data?.total_items ?? "0"} Results`}</>
                )}
              </h1>
            </div>
          </div>

          <>
            <div className="mt-4 ml-auto w-full justify-end flex space-x-2">
              <Button
                disabled={page === 1}
                onClick={() => setPage(1)}
                className={cn(
                  "h-6 w-6 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                  page === 1 && "bg-[#333] text-white"
                )}
              >
                <p className="text-sm font-medium">1</p>
              </Button>
              <div
                onClick={() => {
                  if (page > 1) {
                    setPage((page) => page - 1);
                  }
                }}
                className="h-6 w-6 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
              >
                <ArrowLeft className="h-4 w-4 text-[#333]" />
              </div>
              <div className="h-6 w-6 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition">
                {" "}
                <p className="text-sm font-medium">{page}</p>
              </div>
              <div
                onClick={() => {
                  if (page !== routes?.data.total_pages) {
                    setPage((page) => page + 1);
                  }
                }}
                className="h-6 w-6 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
              >
                <ArrowRight className="h-4 w-4 text-[#333]" />
              </div>
              <Button
                onClick={() => setPage(routes?.data?.total_pages)}
                className={cn(
                  "h-6 w-6 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                  page === routes?.data?.total_pages && "bg-[#333] text-white"
                )}
              >
                {isLoading ? (
                  <p>
                    <Loader2 className="h-5 w-5 animate-spin" />
                  </p>
                ) : (
                  <p className="text-sm font-medium">
                    {routes?.data?.total_pages}
                  </p>
                )}
              </Button>
            </div>
            {isLoading ? (
              <div className="flex flex-col space-y-4 mt-4">
                {new Array(4).fill("").map((_, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between bg-white border border-gray-200 p-4 rounded-lg shadow-md animate-pulse"
                  >
                    <div className="flex flex-col space-y-3">
                      <Skeleton className="h-5 w-24 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-md" />
                      <Skeleton className="h-5 w-24 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-md" />
                    </div>
                    <div className="flex flex-col space-y-4">
                      <Skeleton className="h-5 w-32 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-md" />
                      <Skeleton className="h-5 w-32 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-md" />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                {routes?.data?.routes?.length > 0 ? (
                  <div className="flex flex-col space-y-4 mt-4">
                    {routes.data.routes.map((route: RouteType) => (
                      <RouteAllCard key={route.id} route={route} />
                    ))}
                  </div>
                ) : (
                  <div className="p-4 flex flex-col items-center justify-center bg-white border border-gray-200 mt-4 rounded-lg shadow-md">
                    <EmptySvg className="h-16 w-16 text-gray-400" />
                    <p className="text-sm font-medium text-gray-600 mt-2">
                      No Routes found
                    </p>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
      {modalBookTripStop.isOpen && (
        <ModalBookTripStop refetchRoutes={refetchRoutes} />
      )}
    </div>
  );
};

export default FindTrip;
