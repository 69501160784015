import { create } from "zustand";
import { DriverType } from "../types/types";

export type ModalType = "editDriver";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  driver: DriverType | null;
  onOpen: (type: ModalType, driver: DriverType) => void;
  onClose: () => void;
}

export const useEditDriver = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  driver: null,
  onOpen: (type, driver) => set({ isOpen: true, type, driver }),
  onClose: () => set({ type: null, isOpen: false }),
}));
