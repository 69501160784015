import { create } from "zustand";
import { BookingCustomerType, CustomerQuoteType } from "../types/types";

export type ModalType = "completePayment";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  data: BookingCustomerType | null;
  onOpen: (type: ModalType, data: BookingCustomerType) => void;
  onClose: () => void;
}

export const useCompletePayment = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  data: null,
  onOpen: (type, data) => set({ isOpen: true, type, data }),
  onClose: () => set({ type: null, isOpen: false }),
}));
