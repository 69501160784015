import { toast } from "react-hot-toast";
import { FaSpinner } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import {
  BASE_URL,
  GET_COUNT,
  GET_INBOX,
  READ_MESSAGE,
  UPDATE_STATUS_MESSAGES,
} from "../api/config";

import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { Check } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import SidebarLayout from "../components/layout/SidebarLayout";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import { Checkbox } from "../components/ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";

import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { MessageType } from "../types/types";
//@ts-ignore
import { ReactComponent as EmptySvg } from "../assets/images/data-not-found.svg";
import { cn } from "../lib/utils";

const options = [
  {
    id: 0,
    label: "Select",
    value: "unselected",
  },
  {
    id: 1,
    label: "All Messages",
    value: "all",
  },
  {
    id: 2,
    label: "Read Messages",
    value: "read",
  },
  {
    id: 3,
    label: "Unread Messages",
    value: "unread",
  },
  {
    id: 4,
    label: "Archived Messages",
    value: "archived",
  },
];

const actions = [
  {
    id: 0,
    label: "Select",
    value: "unselect",
  },
  {
    id: 1,
    label: "Mark as Read",
    value: "read",
  },
  {
    id: 2,
    label: "Mark as Unread",
    value: "unread",
  },
  {
    id: 3,
    label: "Mark as Archived",
    value: "archived",
  },
  {
    id: 4,
    label: "Delete",
    value: "deleted",
  },
];

const actionMessageSchema = z.object({
  action: z.string().min(1),
});

const Inbox = () => {
  const { user_type, user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const params = useParams();
  const navigate = useNavigate();
  const [selectedMessageIds, setSelectedMessageIds] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate(`/sign-in`);
    }
  }, [token]);

  const getInbox = async () => {
    const response = await fetch(`${BASE_URL}${GET_INBOX}`, {
      method: "POST",
      body: JSON.stringify({
        user_id: user_id,
        status: params.type,
      }),
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
    }).then((res) => res.json());

    return response;
  };

  const {
    data: inboxMessages,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ["inbox_messages", params.type],
    queryFn: getInbox,
  });

  useEffect(() => {
    refetch();
  }, [params.type]);

  const actionMessageForm = useForm<z.infer<typeof actionMessageSchema>>({
    resolver: zodResolver(actionMessageSchema),
    defaultValues: {
      action: "unselect",
    },
  });

  const getCount = async () => {
    const response = await fetch(`${BASE_URL}${GET_COUNT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },

      body: JSON.stringify({
        user_id: user_id,
        user_type: user_type,
      }),
    }).then((res) => res.json());

    return response;
  };

  const {
    data: count,
    isLoading: isLoadingCount,
    refetch: refetchCount,
  } = useQuery({
    queryKey: ["count"],
    queryFn: getCount,
    enabled: user_id !== "",
  });

  const readMessage = async (messageId: number) => {
    const response = await fetch(`${BASE_URL}${READ_MESSAGE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        messageId: messageId,
        status: "read",
      }),
    }).then((res) => res.json());

    return response;
  };

  const updateStatusMessages = async (messageIds: number[], status: string) => {
    setIsUpdatingStatus(true);
    try {
      // Perform the fetch request to update the message statuses
      const response = await fetch(`${BASE_URL}${UPDATE_STATUS_MESSAGES}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
        body: JSON.stringify({
          user_id: user_id,
          messageIds: messageIds, // sending array of message IDs
          status: status, // sending the selected action as the status
        }),
      });

      // Check if the response is successful (status 200 OK)
      if (response.ok) {
        // Clear the selected message IDs and reset the state

        // Refetch the count and inbox messages before showing success
        await refetchCount();
        // Success: Display success toast after refetching
        toast.success(`Messages marked as ${status}`);
        setIsUpdatingStatus(false);

        await refetch();
        setSelectedMessageIds([]); // Clear the selection
        setSelectAll(false); // Reset the select-all checkbox
        actionMessageForm.setValue("action", "unselect");
      } else {
        // If response status is not OK (not 200), show an error toast
        toast.error("Failed to update message status. Please try again.");
      }
    } catch (error) {
      // Handle any fetch or logic errors
      toast.error("An error occurred while performing the action.");
    }
  };

  const onSubmit = async (values: z.infer<typeof actionMessageSchema>) => {
    if (selectedMessageIds.length === 0) {
      toast.error("Please select at least one message.");
      return;
    }

    // Call the updateStatusMessages function with the selected message IDs and the chosen action
    await updateStatusMessages(selectedMessageIds, values.action);
  };

  // Function to toggle selection of all messages
  const handleSelectAllChange = (isChecked: boolean) => {
    setSelectAll(isChecked);
    if (isChecked) {
      // If select all is checked, add all message IDs to selectedMessageIds
      const allIds =
        inboxMessages?.data?.map((inbox: MessageType) => inbox.id) || [];
      setSelectedMessageIds(allIds);
    } else {
      // If select all is unchecked, clear selectedMessageIds
      setSelectedMessageIds([]);
    }
  };

  // Function to handle individual checkbox selection
  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    setSelectedMessageIds((prevSelectedIds) => {
      const updatedIds = isChecked
        ? [...prevSelectedIds, id] // Add ID if checked
        : prevSelectedIds.filter((selectedId) => selectedId !== id); // Remove ID if unchecked

      // If all messages are selected, set selectAll to true; otherwise, set to false
      setSelectAll(updatedIds.length === inboxMessages?.data?.length);
      return updatedIds;
    });
  };

  // Filter out options based on params.type
  const filteredOptions = options.filter((option) => {
    if (params.type === "read" && option.value === "read") return false;
    if (params.type === "unread" && option.value === "unread") return false;
    if (params.type === "archived" && option.value === "archived") return false;
    return true;
  });

  // Filter out actions based on params.type
  const filteredActions = actions.filter((action) => {
    if (params.type === "read" && action.value === "read") return false;
    if (params.type === "unread" && action.value === "unread") return false;
    if (params.type === "archived" && action.value === "archived") return false;
    return true;
  });

  return (
    <SidebarLayout>
      <div className="flex items-center justify-between w-full border-b border-border pb-3 mb-4">
        <p className="text-lg font-semibold">Messages</p>
        <p className="bg-gray-100 px-4 py-2 capitalize text-sm font-medium">
          {params.type}
        </p>
      </div>
      <div className=" bg-white shadow p-4">
        <div className="md:flex items-center md:space-x-10">
          <form onSubmit={actionMessageForm.handleSubmit(onSubmit)}>
            <div className="flex items-center space-x-2 pb-2">
              <h1>Action</h1>
              <Select
                value={actionMessageForm.watch("action")}
                onValueChange={(val) => {
                  actionMessageForm.setValue("action", val);
                }}
              >
                <SelectTrigger className="w-60">
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent>
                  {filteredActions.map((action) => (
                    <SelectItem key={action.value} value={action.value}>
                      {action.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              {actionMessageForm.watch("action") !== "unselect" && (
                <Button type="submit">
                  {isUpdatingStatus ? (
                    <FaSpinner className="h-5 w-5 animate-spin" />
                  ) : (
                    <Check className="w-6 h-6" />
                  )}
                </Button>
              )}
            </div>
          </form>
        </div>
        {!isRefetching &&
          inboxMessages?.message === "My inbox." &&
          !inboxMessages?.data && (
            <div className="flex flex-col items-center w-full mx-auto space-y-4 justify-center py-3">
              <EmptySvg />
              <p className="text-lg font-medium">Nothing found</p>
              <p className="text-gray-500 font-medium">
                No messages in your inbox
              </p>
            </div>
          )}
        {isLoading || isRefetching ? (
          <div className="bg-white  mt-4">
            <Table className="w-full border border-gray-200">
              <TableHeader className="bg-gray-100 text-gray-600">
                <TableRow>
                  <TableHead className="p-3">
                    <Checkbox />
                  </TableHead>
                  <TableHead className="p-3">From</TableHead>
                  <TableHead className="p-3">Subject</TableHead>
                  <TableHead className="p-3 text-right">Received</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell className="p-3">
                    <Skeleton className="h-4 w-4" />
                  </TableCell>
                  <TableCell className="p-3">
                    <Skeleton className="h-4 w-20" />
                  </TableCell>
                  <TableCell className="p-3">
                    <Skeleton className="h-4 w-60" />
                  </TableCell>
                  <TableCell className="p-3">
                    <Skeleton className="h-4 w-24" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ) : (
          <>
            {inboxMessages?.data?.length > 0 ? (
              <div className="bg-white mt-4">
                <Table className="w-full border border-gray-200">
                  <TableHeader className="bg-gray-100 text-gray-600">
                    <TableRow>
                      <TableHead className="p-3">
                        <Checkbox
                          checked={selectAll}
                          onCheckedChange={(isChecked: boolean) =>
                            handleSelectAllChange(isChecked)
                          }
                        />
                      </TableHead>
                      <TableHead className="p-3">From</TableHead>
                      <TableHead className="p-3">Subject</TableHead>
                      <TableHead className="p-3 text-right">Received</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {inboxMessages?.data?.map((inbox: MessageType) => (
                      <TableRow
                        key={inbox.id}
                        className="hover:bg-gray-50 transition-colors duration-200"
                      >
                        <TableCell className="p-3">
                          <Checkbox
                            checked={
                              selectAll || selectedMessageIds.includes(inbox.id)
                            }
                            onCheckedChange={(isChecked: boolean) =>
                              handleCheckboxChange(inbox.id, isChecked)
                            }
                          />
                        </TableCell>
                        <TableCell className="p-3">
                          <p className="text-sm font-medium">
                            {inbox.sender_id === 0 ? "Runnme" : "Other"}
                          </p>
                        </TableCell>
                        <TableCell className="p-3">
                          <p
                            onClick={() => {
                              if (
                                params.type !== "archived" &&
                                params.type !== "read"
                              ) {
                                readMessage(inbox.id);
                              }
                              navigate(`/myInbox/showdetail/${inbox.id}`);
                            }}
                            className={cn(
                              "hover:underline text-blue-600 cursor-pointer",
                              inbox.status === "read" && "text-gray-800"
                            )}
                          >
                            {inbox.subject}
                          </p>
                        </TableCell>
                        <TableCell className="p-3 text-right">
                          <p className="text-sm font-medium">{inbox.date}</p>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : null}
          </>
        )}
      </div>
    </SidebarLayout>
  );
};

export default Inbox;
