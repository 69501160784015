import { create } from "zustand";

export type ModalType = "ComplteInstantFreight";

interface ModalStore {
  type: ModalType | null;
  freightId: number | null;
  isOpen: boolean;
  onOpen: (type: ModalType, freightId: number | null) => void;
  onClose: () => void;
}

export const useComplteInstantFreight = create<ModalStore>((set) => ({
  type: null,
  freightId: null,
  isOpen: false,
  onOpen: (type, freightId) => set({ isOpen: true, type, freightId }),
  onClose: () => set({ type: null, isOpen: false }),
}));
