import { BASE_URL, UPDATE_STOP_STATUS } from "src/api/config";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { useState } from "react";
import { useUpdateStatusStop } from "src/hooks/use-update-status-stop";
import { Button } from "../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "../ui/form";
import { Loader2 } from "lucide-react";

const updateStatusOptions = [
  {
    id: 0,
    name: "Paid",
    value: "paid",
  },
  {
    id: 1,
    name: "Confirmed",
    value: "confirmed",
  },
  {
    id: 2,
    name: "Cancelled",
    value: "cancelled",
  },
];

const formStatusSchema = z.object({
  status: z.string().min(1),
});

interface ModalUpdateStatusStopsProp {
  refetch: () => void;
}

const ModalUpdateStatusStop: React.FC<ModalUpdateStatusStopsProp> = ({
  refetch,
}) => {
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const [status, setStatus] = useState("");
  const modalUpdateStatusStop = useUpdateStatusStop();
  const [loadingChangingStatus, setLoadingChangingStatus] = useState(false);

  const updateStopStatus = async () => {
    setLoadingChangingStatus(true);
    const response = await fetch(`${BASE_URL}${UPDATE_STOP_STATUS}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        stop_id: modalUpdateStatusStop.stopId,
        status: status,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Customer stop status updated successfully") {
          modalUpdateStatusStop.onClose();
          refetch();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingChangingStatus(false);
      });

    return response;
  };

  const formStatus = useForm<z.infer<typeof formStatusSchema>>({
    resolver: zodResolver(formStatusSchema),
    defaultValues: {
      status: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formStatusSchema>) => {};
  return (
    <Dialog
      open={modalUpdateStatusStop.isOpen}
      onOpenChange={modalUpdateStatusStop.onClose}
    >
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-center">
            <Form {...formStatus}>
              <form
                className="flex flex-col w-full"
                onSubmit={formStatus.handleSubmit(onSubmit)}
              >
                <div className="flex flex-col items-start w-full space-y-10">
                  <p className="text-sm font-medium">Update Stop Status</p>
                  <Select
                    onValueChange={(value) => {
                      setStatus(value);
                    }}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Change status" />
                    </SelectTrigger>
                    <SelectContent>
                      {updateStatusOptions.map((status) => (
                        <SelectItem value={status.value}>
                          {status.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex items-center justify-end space-x-2 w-full mt-4">
                  <Button
                    variant={"outline"}
                    onClick={() => modalUpdateStatusStop.onClose()}
                  >
                    Back
                  </Button>
                  <Button
                    disabled={status === "" || loadingChangingStatus}
                    variant={"freightBlue"}
                    className="w-40 "
                    onClick={() => updateStopStatus()}
                  >
                    {loadingChangingStatus ? (
                      <>
                        <Loader2 className="animate-spin h-5 w-5" />
                      </>
                    ) : (
                      <p className="text-sm font-medium">Change Status</p>
                    )}
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalUpdateStatusStop;
