import { useQuery } from "@tanstack/react-query";
import {
  BASE_URL,
  BOOKING_STATISTICS,
  BOOKING_VS_CANCEL,
  BOOKING_VS_QUOTES,
  SALES_FREIGHT,
} from "../api/config";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { useEffect, useState } from "react";
import Header from "../components/header/Header";
import CustomerSidebar from "../components/sidebar/CustomerSidebar";
import Sidebar from "../components/sidebar/Sidebar";
import { formatDate, pickupFormatDate } from "../lib/utils";
import { Button } from "../components/ui/button";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "../components/ui/form";
import { CalendarDays } from "lucide-react";
import { Calendar } from "../components/ui/calendar";
import SidebarLayout from "../components/layout/SidebarLayout";

const bookingsChartSchema = z.object({
  fromDate: z.date(),
  toDate: z.date(),
});

const Statistics = () => {
  const { user_type, user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const [showFormDate, setShowFormDate] = useState(false);
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [showToDate, setShowToDate] = useState(false);
  const [toDate, setToDate] = useState<Date>(new Date());

  const [showBookingVsQuotes, setShowBookingVsQuotes] = useState(false);
  const [showBookingStatistics, setShowBookingStatistics] = useState(false);
  const [showBookingVsCancelled, setShowBookingVsCancelled] = useState(false);
  const [showSalesFreight, setShowSalesFreight] = useState(false);

  const getStatistics = async () => {
    const response = await fetch(`${BASE_URL}`);
  };

  const bookingChartDetails = useForm<z.infer<typeof bookingsChartSchema>>({
    resolver: zodResolver(bookingsChartSchema),
    defaultValues: {
      fromDate: new Date(),
      toDate: new Date(),
    },
  });

  const getBookingStatistics = async () => {
    const response = await fetch(`${BASE_URL}${BOOKING_STATISTICS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        from_date: pickupFormatDate(formatDate(fromDate)),
        to_date: pickupFormatDate(formatDate(toDate)),
      }),
    }).then((res) => res.json());
    return response;
  };

  const getBookingQuotes = async () => {
    const response = await fetch(`${BASE_URL}${BOOKING_VS_QUOTES}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        from_date: pickupFormatDate(formatDate(fromDate)),
        to_date: pickupFormatDate(formatDate(toDate)),
      }),
    }).then((res) => res.json());

    return response;
  };

  const getBookingCancel = async () => {
    const response = await fetch(`${BASE_URL}${BOOKING_VS_CANCEL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        from_date: pickupFormatDate(formatDate(fromDate)),
        to_date: pickupFormatDate(formatDate(toDate)),
      }),
    }).then((res) => res.json());

    return response;
  };

  const getSalesFreight = async () => {
    const response = await fetch(`${BASE_URL}${SALES_FREIGHT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        from_date: pickupFormatDate(formatDate(fromDate)),
        to_date: pickupFormatDate(formatDate(toDate)),
      }),
    });

    return response;
  };

  const { data: bookingVsQuotes } = useQuery({
    queryKey: ["booking_quotes"],
    queryFn: getBookingQuotes,
  });

  const { data: bookingStatistics } = useQuery({
    queryKey: ["booking_statistics"],
    queryFn: getBookingStatistics,
  });

  const { data: bookingCancel } = useQuery({
    queryKey: ["booking_cancel"],
    queryFn: getBookingCancel,
  });

  const { data: salesFreight } = useQuery({
    queryKey: ["sales_freight"],
    queryFn: getSalesFreight,
  });

  const onSubmit = async (values: z.infer<typeof bookingsChartSchema>) => {
    setFromDate(values.fromDate);
    setToDate(values.toDate);
  };

  return (
    <SidebarLayout>
      {/* <Header />
      <div className="grid grid-cols-7 mt-20 px-10">
        <div className="col-span-2">
          {user_type === "customer" ? <CustomerSidebar /> : <Sidebar />}
        </div>
        <div className="col-span-5"> */}
      <div className="max-w-4xl mr-auto bg-white shadow p-4">
        <div className="sm:flex items-center sm:space-x-2">
          <Button
            className="mb-2 mr-2"
            onClick={() => {
              setShowBookingStatistics(!showBookingStatistics);

              setShowBookingVsQuotes(false);
              setShowSalesFreight(false);
              setShowBookingVsCancelled(false);
            }}
            variant={!showBookingStatistics ? "freightBlue" : "freightGreen"}
          >
            My Bookings
          </Button>
          <Button
            className="mb-2 mr-2"
            onClick={() => {
              setShowBookingVsQuotes(!showBookingVsQuotes);
              setShowBookingStatistics(false);
              setShowSalesFreight(false);
              setShowBookingVsCancelled(false);
            }}
            variant={!showBookingVsQuotes ? "freightBlue" : "freightGreen"}
          >
            Quote Vs Bookings
          </Button>
          <Button
            className="mb-2 mr-2"
            onClick={() => {
              setShowBookingVsCancelled(!showBookingVsCancelled);
              setShowBookingStatistics(false);
              setShowSalesFreight(false);
              setShowBookingVsQuotes(false);
            }}
            variant={!showBookingVsCancelled ? "freightBlue" : "freightGreen"}
          >
            My Bookings Vs Cancelled
          </Button>
          <Button
            className="mb-2 mr-2"
            onClick={() => {
              setShowSalesFreight(!showSalesFreight);

              setShowBookingStatistics(false);
              setShowBookingVsCancelled(false);
              setShowBookingVsQuotes(false);
            }}
            variant={!showSalesFreight ? "freightBlue" : "freightGreen"}
          >
            Sales Freight
          </Button>
        </div>
        <div className="flex flex-wrap items-center gap-2 sm:space-x-2 mt-4">
          <Button variant={"freightBlue"}>1D</Button>
          <Button variant={"freightBlue"}>1W</Button>
          <Button variant={"freightBlue"}>1M</Button>
          <Button variant={"freightBlue"}>1Y</Button>
        </div>
        <div className="flex items-center space-x-2 mt-4">
          <Form {...bookingChartDetails}>
            <form onSubmit={bookingChartDetails.handleSubmit(onSubmit)}>
              <div className="sm:flex items-center sm:space-x-4">
                <div className="relative flex items-center space-x-4">
                  <div
                    onClick={() => setShowFormDate(!showFormDate)}
                    className="border border-border sm:w-60 flex items-center rounded py-2 px-2 justify-between"
                  >
                    <h3>{formatDate(fromDate)}</h3>
                    <CalendarDays />
                  </div>

                  {showFormDate && (
                    <Controller
                      name="fromDate"
                      control={bookingChartDetails.control}
                      render={({ field }) => (
                        <div className="absolute top-10 bg-white shadow">
                          <Calendar
                            {...field}
                            mode="single"
                            selected={fromDate}
                            onSelect={(val: any) => {
                              bookingChartDetails.setValue("fromDate", val);
                              setFromDate(val);
                              setShowFormDate(false);
                            }}
                            className="rounded border bg-white border-border"
                          />
                        </div>
                      )}
                    />
                  )}
                </div>
                <div className="relative flex items-center space-x-4">
                  <div
                    className="border border-border sm:w-60 flex items-center rounded py-2 px-2 justify-between"
                    onClick={() => setShowToDate(!showToDate)}
                  >
                    <h3>{formatDate(toDate)}</h3>

                    <CalendarDays />
                  </div>
                  {showToDate && (
                    <Controller
                      control={bookingChartDetails.control}
                      name="toDate"
                      render={({ field }) => (
                        <div className="absolute top-10 bg-white shadow">
                          <Calendar
                            {...field}
                            mode="single"
                            selected={toDate}
                            onSelect={(val: any) => {
                              bookingChartDetails.setValue("toDate", val);
                              setToDate(val);
                              setShowToDate(false);
                            }}
                            className="rounded border bg-white border-border"
                          />
                        </div>
                      )}
                    />
                  )}
                </div>
                <Button >Submit</Button>
              </div>
            </form>
          </Form>
        </div>
        <div className="mt-4">
          <h1 className="text-xl text-center font-semibold">
            My Bookings Chart View
          </h1>
        </div>
      </div>
    </SidebarLayout>
    //   </div>
    // </div>
  );
};

export default Statistics;
