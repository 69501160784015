import { useCancelBookingQuote } from "src/hooks/use-cancel-booking-quote";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { Button } from "../ui/button";
import { BASE_URL, BOOKING_CANCEL_QUOTE } from "src/api/config";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { Check } from "lucide-react";

const ModalCancelBookingQuote = () => {
  const modalCancelBookingQuote = useCancelBookingQuote();
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);

  const cancelBookingQuote = async () => {
    const response = await fetch(`${BASE_URL}${BOOKING_CANCEL_QUOTE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        bookingId: modalCancelBookingQuote.bookingId,
        cancelled_by: user_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Booking cancelled successfully.") {
          modalCancelBookingQuote.onClose();
        }
      });

    return response;
  };
  return (
    <Dialog
      open={modalCancelBookingQuote.isOpen}
      onOpenChange={modalCancelBookingQuote.onClose}
    >
      <DialogContent className=" p-6">
        <DialogHeader>
          <div className="flex flex-col items-start w-full">
            <h1 className="text-sm font-medium">Cancel Booking Quote</h1>

            <div className="mt-4 flex flex-col items-start pb-10">
              <h1 className="text-xl font-medium">Are you sure? </h1>
              <p className="text-lg">You want to cancel this booking quote</p>
            </div>
          </div>
        </DialogHeader>
        <div className="w-full justify-end items-end flex space-x-2">
          <Button
            onClick={() => modalCancelBookingQuote.onClose()}
            variant={"outline"}
          >
            Cancel
          </Button>
          <Button onClick={() => cancelBookingQuote()} variant={"freightBlue"}>
            Ok
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalCancelBookingQuote;
