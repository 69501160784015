import { Ban, Pencil, Plus, Settings } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { BASE_URL, GET_IMAGE } from "src/api/config";
import { useCancelFreightModal } from "src/hooks/use-cancel-freight";
import { useExtendFreight } from "src/hooks/use-extend-freight";
import useLocalStorage from "src/hooks/use-local-storage";
import { cn } from "src/lib/utils";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { FreightItem } from "src/types/types";
import small from "../../assets/images/books-box.png";
import large from "../../assets/images/moving-box.png";
import medium from "../../assets/images/shoes-box.png";
import { Button } from "../ui/button";
//@ts-ignore
//@ts-ignore
//@ts-ignore
//@ts-ignore
//@ts-ignore
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { TableCell, TableRow } from "../ui/table";

interface CustomerFreightCardProps {
  freight: FreightItem;
}

const CustomerFreightCard: React.FC<CustomerFreightCardProps> = ({
  freight,
}) => {
  const navigate = useNavigate();
  const [categoryhome, setCategoryHome] = useLocalStorage("category_home");
  const modalCancelFreight = useCancelFreightModal();
  const modalExtendFreight = useExtendFreight();
  const { user_id } = userInfoUserStore((state) => state);

  return (
    <TableRow className="transition-transform duration-200 ease-in-out hover:bg-gray-50">
      <TableCell className="w-80 py-4">
        <div className="flex flex-col space-y-4">
          <div className="flex items-start space-x-2">
            <div className="bg-gray-100 text-gray-700 font-medium text-center py-2 px-4 rounded-lg w-full">
              Category{" "}
              <span className="text-gray-500">
                {freight.title === "Normal package freight"
                  ? "Normal package"
                  : `${freight.category}/${freight.sub_category}`}
              </span>
            </div>
          </div>
          <div className="relative">
            {freight.animal_img ? (
              <img
                className="h-40 object-cover rounded-lg shadow-sm"
                alt={freight.title}
                src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${freight.animal_img}&image_type=freight`}
              />
            ) : (
              <div className="flex items-center justify-center w-40 h-40 rounded-lg shadow-sm">
                {freight.freight_type_info?.freight_size.id === 1 && (
                  <img
                    src={small}
                    className="h-22 w-22 object-contain cursor-pointer"
                    alt="small package"
                    onClick={() =>
                      navigate(`/customer/freightDetails/${freight.id}`)
                    }
                  />
                )}
                {freight.freight_type_info?.freight_size.id === 2 && (
                  <img
                    src={medium}
                    className="h-22 w-22 object-contain cursor-pointer"
                    alt="medium package"
                    onClick={() =>
                      navigate(`/customer/freightDetails/${freight.id}`)
                    }
                  />
                )}
                {freight.freight_type_info?.freight_size.id === 3 && (
                  <img
                    src={large}
                    className="h-22 w-22 object-contain cursor-pointer"
                    alt="large package"
                    onClick={() =>
                      navigate(`/customer/freightDetails/${freight.id}`)
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </TableCell>
      <TableCell className="py-4">
        <div className="flex flex-col space-y-2 w-40">
          <p
            onClick={() => navigate(`/customer/freightDetails/${freight.id}`)}
            className="text-freightBlueDark hover:underline cursor-pointer text-lg font-semibold"
          >
            {freight.title}
          </p>
          <p className="text-gray-600">
            {freight.start_date} - {freight.end_date}
          </p>
          <div>
            <p className="text-sm font-medium text-gray-500">Origin</p>
            <p className="text-gray-800 text-sm">{freight.origin_address}</p>
          </div>
          <div>
            <p className="text-sm font-medium text-gray-500">Destination</p>
            <p className="text-gray-800 text-sm">
              {freight.destination_address}
            </p>
          </div>
        </div>
      </TableCell>
      <TableCell className="w-40 py-4">
        <div className="text-sm text-gray-600">{freight.expire_date}</div>
      </TableCell>
      <TableCell className="py-4">
        <div className="flex flex-col space-y-2">
          <p
            className={cn(
              "text-sm font-medium text-white py-1 px-3 rounded-lg text-center",
              freight.status === "active" && "bg-green-500",
              freight.status === "cancelled" && "bg-gray-500",
              freight.status === "booked" && "bg-freightBlue"
            )}
          >
            {freight.status.toUpperCase()}
          </p>
          <Button
            variant="outline"
            className="text-xs font-medium capitalize text-gray-500"
          >
            {freight.fstatus === "DCC" && "Delivery Completed"}
            {freight.fstatus === "FD" && "Freight Deleted"}
            {freight.fstatus === "QR" && "Quote Ready"}
            {freight.fstatus === "FBCC" && "Freight Booked Cancelled"}
            {freight.fstatus === "FB" && "Freight Booked"}
          </Button>
        </div>
      </TableCell>
      <TableCell className="py-4">
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Settings className="h-4 w-4 text-gray-600 hover:text-gray-800 transition" />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuLabel>Freight Settings</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {freight.status === "active" && (
              <DropdownMenuItem
                onClick={() => {
                  navigate(`/editFreight/details/${freight.id}`);
                  setCategoryHome({
                    catid: freight.catid,
                    catname: freight.category,
                  });
                }}
              >
                <Pencil className="h-4 w-4 mr-2" />
                Edit Freight
              </DropdownMenuItem>
            )}
            <DropdownMenuSeparator />
            {freight.status !== "cancelled" && (
              <DropdownMenuItem
                onClick={() =>
                  modalCancelFreight.onOpen(
                    "cancelFreight",
                    freight.id.toString()
                  )
                }
              >
                <Ban className="h-4 w-4 mr-2" />
                Cancel Freight
              </DropdownMenuItem>
            )}
            <DropdownMenuSeparator />
            {freight.status !== "cancelled" && freight.status === "active" && (
              <DropdownMenuItem
                onClick={() =>
                  modalExtendFreight.onOpen(
                    "extendFreight",
                    freight.id.toString()
                  )
                }
              >
                <Plus className="h-4 w-4 mr-2" />
                Extend Freight
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  );
};

export default CustomerFreightCard;
