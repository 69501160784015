import { BASE_URL, GET_COUNT } from "src/api/config";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { FaArrowDown, FaTruck } from "react-icons/fa";
import { useTokenStoreStorage } from "src/store/useTokenStore";

import { userInfoUserStore } from "src/store/useUserInfoStore";
import { BookingMessage, InboxMessage, QuoteMessage } from "src/types/types";
import { useNavigate } from "react-router-dom";
import { CarFront, Loader2, Plus, Truck, Zap } from "lucide-react";
import {
  BiSolidHome,
  BiSolidMessage,
  BiSolidTruck,
  BiUser,
} from "react-icons/bi";
import { MdFeedback, MdReceipt } from "react-icons/md";
import { TbReportMoney } from "react-icons/tb";
import { IoChatbox, IoTicket } from "react-icons/io5";
import { AiOutlineProfile } from "react-icons/ai";
import { ImStatsBars } from "react-icons/im";
import { cn } from "src/lib/utils";
import useLocalStorage from "src/hooks/use-local-storage";

const Sidebar = () => {
  const navigate = useNavigate();
  const token = useTokenStoreStorage((s) => s.token);
  const [inboxMessages, setInboxMessages] = useState<InboxMessage>({
    archiveCnt: 0,
    readCnt: 0,
    unreadCnt: 0,
  });
  const [quotesMessages, setQuotesMessages] = useState<QuoteMessage>({
    activeCnt: 0,
    acceptedCnt: 0,
    declinedCnt: 0,
    cancelledCnt: 0,
    expiredCnt: 0,
  });
  const [bookingMessages, setBookingMessages] = useState<BookingMessage>({
    bookedCnt: 0,
    cancelledCnt: 0,
    completedCnt: 0,
    //Pick up
    fpsCnt: 0,
    //Transit
    fpuCnt: 0,
    //delivered
    fdCnt: 0,
  });

  const { email, name, user_id, user_type } = userInfoUserStore(
    (state) => state
  );
  const [showAccount, setShowAccount] = useLocalStorage("show_account");
  const [showInbox, setShowInbox] = useLocalStorage("show_messages");
  const [showQuotes, setShowQuotes] = useLocalStorage("show_quotes");
  const [showBookings, setShowBookings] = useLocalStorage("show_booking");
  const [showFeedback, setShowFeedback] = useLocalStorage("show_feedbacks");
  const [showProfile, setShowProfile] = useLocalStorage("show_profile");
  const [showTickets, setShowTickets] = useLocalStorage("show_ticket");
  const [showStatistics, setShowStatictics] =
    useLocalStorage("show_statistics");
  const [showPayments, setShowPayments] = useLocalStorage("show_payments");
  const [showTrips, setShowTrips] = useLocalStorage("show_trips");
  const [showChats, setShowChats] = useLocalStorage("show_chats");
  const [showB2bTrips, setShowB2bTrips] = useLocalStorage("show_b2b_trips");

  const getCount = async () => {
    const response = await fetch(`${BASE_URL}${GET_COUNT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },

      body: JSON.stringify({
        user_id: user_id,
        user_type: user_type,
      }),
    }).then((res) => res.json());

    return response;
  };

  const {
    data: count,
    isLoading,
    refetch,
  } = useQuery<any>({
    queryKey: ["count"],
    queryFn: getCount,
    enabled: user_id !== "",
  });

  useEffect(() => {
    setInboxMessages(count?.data?.my_inbox_cnt);
    setQuotesMessages(count?.data?.my_quotes_cnt);
    setBookingMessages(count?.data?.my_bookings_cnt);
  }, [count]);

  return (
    <div className="h-full min-h-[95vh] bg-gray-100 w-[300px]">
      <div className="flex flex-col space-y-2 mb-2 mt-2 ">
        <div
          className="flex items-center justify-between  border-b py-2 cursor-pointer px-4 bg-[#4a4a4a] text-gray-50 mx-2 rounded"
          onClick={() => navigate("/trip-package")}
        >
          <div className="flex items-center space-x-1">
            <FaTruck className="h-4 w-4 mr-2" />
            <h1 className="text-sm  font-medium">Create Route</h1>
          </div>
          <Plus className="h-4 w-4" />
        </div>
      </div>
      <div
        onClick={() => navigate("/dashboard")}
        className={cn(
          "flex items-center px-4 py-2 space-x-2 cursor-pointer",

          window.location.pathname === "/dashboard" && "bg-white"
        )}
      >
        <BiSolidHome className="h-4 w-4 text-[#333]" />
        <h1 className="text-[#333] font-medium text-sm">Transporter Account</h1>
      </div>
      <div className="">
        <div
          onClick={() => {
            setShowInbox(!showInbox);
            setShowPayments(false);
            setShowAccount(false);
            setShowBookings(false);
            setShowFeedback(false);
            setShowProfile(false);
            setShowQuotes(false);
            setShowStatictics(false);
            setShowTickets(false);
            setShowTrips(false);
            setShowB2bTrips(false);
          }}
          className="flex p-1 items-center w-full px-4 py-2 justify-between cursor-pointer "
        >
          {isLoading ? (
            <div className="flex items-center space-x-2">
              <BiSolidMessage className="h-4 w-4 text-[#333]" />

              <h3>My Messages </h3>
              <div className="flex items-center ml-1">
                (<Loader2 className="h-3 w-3 animate-spin" />)
              </div>
            </div>
          ) : (
            <div className="flex items-center space-x-2">
              <BiSolidMessage className="h-4 w-4 text-[#333]" />

              <h3 className="text-sm font-medium text-[#333]">My Messages </h3>
              {Number(inboxMessages?.unreadCnt) !== 0 && (
                <div className="bg-[#333] h-6 w-6 shadow rounded-full flex items-center justify-center">
                  <p className="font-semibold text-xs text-white">
                    {inboxMessages?.unreadCnt}
                  </p>
                </div>
              )}
            </div>
          )}
          <div>
            <FaArrowDown
              className={`h-3  transition ${
                showInbox
                  ? "animate-toArrowUp rotate-180  "
                  : "animate-toArrowDown"
              } `}
            />
          </div>
        </div>
        {showInbox && (
          <div className="flex flex-col px-4 bg-white  transition m-2 border rounded ">
            <div
              onClick={() => navigate("/myinbox/unread")}
              className="flex items-center space-x-1 border-b py-2 cursor-pointer"
            >
              <h1 className="text-sm font-medium text-gray-500">
                Unread Messages
              </h1>

              {Number(inboxMessages?.unreadCnt) > 0 && (
                <div className="bg-red-500 h-6 w-6 shadow rounded-full flex items-center justify-center">
                  <p className="font-semibold text-xs text-white">
                    {inboxMessages?.unreadCnt}
                  </p>
                </div>
              )}
            </div>
            <div
              onClick={() => navigate("/myinbox/archived")}
              className="flex items-center space-x-1 border-b py-2 cursor-pointer"
            >
              <h1 className="text-sm font-medium text-gray-500">Archived </h1>
              {Number(inboxMessages?.archiveCnt) > 0 && (
                <div className="bg-red-500 h-6 w-6 shadow rounded-full flex items-center justify-center">
                  <p className="font-semibold text-xs text-white">
                    {inboxMessages?.archiveCnt}
                  </p>
                </div>
              )}
            </div>
            <div
              onClick={() => navigate("/myinbox/read")}
              className="flex items-center space-x-1 border-b py-2 cursor-pointer"
            >
              <h1 className="text-sm font-medium text-gray-500">
                Read Messages{" "}
              </h1>
              {Number(inboxMessages?.readCnt) > 0 && (
                <div className="bg-red-500 h-6 w-6 shadow rounded-full flex items-center justify-center">
                  <p className="font-semibold text-white">
                    {inboxMessages?.readCnt}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="">
          <div
            onClick={() => {
              setShowPayments(!showPayments);
              setShowAccount(false);
              setShowBookings(false);
              setShowFeedback(false);
              setShowInbox(false);
              setShowProfile(false);
              setShowQuotes(false);
              setShowStatictics(false);
              setShowTickets(false);
              setShowTrips(false);
              setShowB2bTrips(false);
            }}
            className="flex p-1  items-center w-full px-4 py-2 justify-between cursor-pointer "
          >
            <div className="flex items-center space-x-2">
              <MdReceipt className="h-4 w-4 text-[#333]" />
              <h1 className="text-sm font-medium text-[#333]">Activity</h1>
            </div>
            <div>
              <FaArrowDown
                className={`h-3 transition ${
                  showPayments
                    ? "animate-toArrowUp rotate-180"
                    : "animate-toArrowDown"
                }`}
              />
            </div>
          </div>
          {showPayments && (
            <div className="flex flex-col px-4 bg-white transition m-2 border rounded">
              <div
                className="flex items-center space-x-1 border-b py-2 cursor-pointer"
                onClick={() => navigate("/carrier/history-payments")}
              >
                <h1 className="text-sm font-medium text-gray-500">
                  My Earnings
                </h1>
              </div>
            </div>
          )}
        </div>

        <div className="">
          <div
            className="flex p-1  items-center w-full px-4 py-2 justify-between cursor-pointer "
            onClick={() => {
              setShowQuotes(!showQuotes);
              setShowInbox(false);
              setShowPayments(false);
              setShowAccount(false);
              setShowBookings(false);
              setShowFeedback(false);
              setShowProfile(false);
              setShowStatictics(false);
              setShowTickets(false);
              setShowTrips(false);
              setShowB2bTrips(false);
            }}
          >
            <div className="flex items-start space-x-2">
              <TbReportMoney className="h-4 w-4 text-[#333]" />
              <h3 className="text-sm font-medium ">My Quotes </h3>
            </div>
            <div>
              <FaArrowDown
                className={`h-3 transition ${
                  showQuotes
                    ? "animate-toArrowUp rotate-180  "
                    : "animate-toArrowDown"
                } `}
              />
            </div>
          </div>
          {showQuotes && (
            <div className="flex flex-col px-4 bg-white transition m-2 border rounded">
              <div
                onClick={() => navigate("/quote/active")}
                className="flex items-center space-x-4 border-b py-2 cursor-pointer"
              >
                <h1 className="text-sm font-medium text-gray-500">Active</h1>
                {Number(quotesMessages?.activeCnt) > 0 && (
                  <div className="bg-red-500 h-5 w-5 ml-4 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white text-sm">
                      {quotesMessages?.activeCnt}
                    </p>
                  </div>
                )}
              </div>
              <div
                onClick={() => navigate("/quote/accepted")}
                className="flex items-center space-x-4 border-b py-2 cursor-pointer"
              >
                <h1 className="text-sm font-medium text-gray-500">Accepted</h1>
                {Number(quotesMessages?.acceptedCnt) > 0 && (
                  <div className="bg-red-500 h-5 w-5 ml-4 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white text-sm">
                      {quotesMessages?.acceptedCnt}
                    </p>
                  </div>
                )}
              </div>

              <div
                onClick={() => navigate("/quote/cancelled")}
                className="flex items-center space-x-4 border-b py-2 cursor-pointer"
              >
                <h1 className="text-sm font-medium text-gray-500">Cancelled</h1>
                {Number(quotesMessages?.cancelledCnt) > 0 && (
                  <div className="bg-red-500 h-5 w-5 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white text-sm">
                      {quotesMessages?.cancelledCnt}
                    </p>
                  </div>
                )}
              </div>
              <div
                onClick={() => navigate("/quote/expired")}
                className="flex items-center space-x-4 border-b py-2 cursor-pointer"
              >
                <h1 className="text-sm font-medium text-gray-500">Expired</h1>
                {Number(quotesMessages?.expiredCnt) > 0 && (
                  <div className="bg-red-500 h-5 w-5 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white text-sm">
                      {quotesMessages?.expiredCnt}
                    </p>
                  </div>
                )}
              </div>

              <div
                onClick={() => navigate("/quote/declined")}
                className="flex items-center space-x-4 border-b py-2 cursor-pointer"
              >
                <h1 className="text-sm font-medium text-gray-500">Declined</h1>

                {Number(quotesMessages?.declinedCnt) > 0 && (
                  <div className="bg-red-500 h-5 w-5 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white text-sm">
                      {quotesMessages?.declinedCnt}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="">
          <div
            className="flex p-1 items-center w-full px-4 py-2 justify-between transition cursor-pointer "
            onClick={() => {
              setShowBookings(!showBookings);
              setShowQuotes(false);
              setShowInbox(false);
              setShowPayments(false);
              setShowAccount(false);
              setShowFeedback(false);
              setShowProfile(false);
              setShowStatictics(false);
              setShowTickets(false);
              setShowTrips(false);
              setShowB2bTrips(false);
            }}
          >
            <div className="flex items-center space-x-2">
              <BiSolidTruck className="h-4 w-4 text-[#333]" />
              <h3 className="text-sm font-medium ">My Bookings</h3>
            </div>
            <div>
              <FaArrowDown
                className={`h-3 transition ${
                  showBookings
                    ? "animate-toArrowUp rotate-180"
                    : "animate-toArrowDown"
                }`}
              />
            </div>
          </div>
          {showBookings && (
            <div className="flex flex-col px-4 bg-white transition m-2 border rounded">
              <div
                onClick={() => navigate("/carrier/instant-freights")}
                className="flex items-center justify-between space-x-1 border-b py-2 cursor-pointer"
              >
                <h1 className="text-sm font-medium text-gray-500">
                  Instant Freights
                </h1>
                <Zap className="h-4 w-4 text-freightBlueDark" />
              </div>
              <div
                onClick={() => navigate("/carrier/booking/transporters/booked")}
                className="flex items-center space-x-4 border-b py-2 cursor-pointer"
              >
                <h1 className="text-sm font-medium text-gray-500">Booked</h1>
                {Number(bookingMessages?.bookedCnt) > 0 && (
                  <div className="bg-red-500 h-5 w-5 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white text-sm">
                      {bookingMessages?.bookedCnt}
                    </p>
                  </div>
                )}
              </div>
              {/* <div
                onClick={() =>
                  navigate("/carrier/booking/transporters/picking-up")
                }
                className="flex items-center space-x-4 border-b py-2 cursor-pointer"
              >
                <h1 className="text-sm font-medium text-gray-500">PickUp</h1>
                {Number(bookingMessages?.fpsCnt) > 0 && (
                  <div className="bg-red-500 h-5 w-5 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white text-sm">
                      ({bookingMessages?.fpsCnt})
                    </p>
                  </div>
                )}
              </div> */}
              {/* <div
                onClick={() =>
                  navigate("/carrier/booking/transporters/in-transit")
                }
                className="flex items-center space-x-4 border-b py-2 cursor-pointer"
              >
                <h1 className="text-sm font-medium text-gray-500">
                  In Transit
                </h1>
                {Number(bookingMessages?.fpuCnt) > 0 && (
                  <div className="bg-red-500 h-5 w-5 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white text-sm">
                      ({bookingMessages?.fpuCnt})
                    </p>
                  </div>
                )}
              </div> */}
              {/* <div
                onClick={() =>
                  navigate("/carrier/booking/transporters/delivered")
                }
                className="flex items-center space-x-4 border-b py-2 cursor-pointer"
              >
                <h1 className="text-sm font-medium text-gray-500">Delivered</h1>
                {Number(bookingMessages?.fpsCnt) > 0 && (
                  <div className="bg-red-500 h-5 w-5 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white text-sm">
                      ({bookingMessages?.fpsCnt})
                    </p>
                  </div>
                )}
              </div> */}
              <div
                onClick={() =>
                  navigate("/carrier/booking/transporters/complete")
                }
                className="flex items-center space-x-4 border-b py-2 cursor-pointer"
              >
                <h1 className="text-sm font-medium text-gray-500">Completed</h1>
                {Number(bookingMessages?.completedCnt) > 0 && (
                  <div className="bg-red-500 h-5 w-5 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white text-sm">
                      {bookingMessages?.completedCnt}
                    </p>
                  </div>
                )}
              </div>
              <div
                onClick={() =>
                  navigate("/carrier/booking/transporters/cancelled")
                }
                className="flex items-center space-x-4 border-b py-2 cursor-pointer"
              >
                <h1 className="text-sm font-medium text-gray-500">Cancelled</h1>
                {Number(bookingMessages?.cancelledCnt) > 0 && (
                  <div className="bg-red-500 h-5 w-5 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white text-sm">
                      {bookingMessages?.cancelledCnt}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div>
          <div
            onClick={() => {
              setShowTrips(!showTrips);
              setShowBookings(false);
              setShowQuotes(false);
              setShowInbox(false);
              setShowPayments(false);
              setShowAccount(false);
              setShowFeedback(false);
              setShowProfile(false);
              setShowStatictics(false);
              setShowTickets(false);
              setShowB2bTrips(false);
            }}
            className="flex p-1  items-center w-full px-4 py-2 justify-between transition cursor-pointer"
          >
            <div className="flex items-center space-x-2">
              <CarFront className="h-4 w-4 text-[#333]" />
              <h3 className="text-sm font-medium text-[#333]">My Trips</h3>
            </div>
            <FaArrowDown
              className={`h-3 transition ${
                showTrips
                  ? "animate-toArrowUp rotate-180"
                  : "animate-toArrowDown"
              }`}
            />
          </div>
          {showTrips && (
            <div className="flex flex-col px-4 bg-white transition m-2 border rounded">
              <div
                className="flex items-center space-x-1 px-4 border-b py-2 cursor-pointer hover:bg-gray-50 transition duration-300 ease-in-out"
                onClick={() => navigate(`/carrier/trips`)}
              >
                <h1 className="text-sm font-medium text-gray-500">
                  View Trips
                </h1>
              </div>
            </div>
          )}
        </div>
        <div>
          <div
            onClick={() => {
              setShowB2bTrips(!showB2bTrips);
              setShowTrips(false);
              setShowBookings(false);
              setShowQuotes(false);
              setShowInbox(false);
              setShowPayments(false);
              setShowAccount(false);
              setShowFeedback(false);
              setShowProfile(false);
              setShowStatictics(false);
              setShowTickets(false);
            }}
            className="flex p-1  items-center w-full px-4 py-2 justify-between transition cursor-pointer"
          >
            <div className="flex items-center space-x-2">
              <Truck className="h-4 w-4 text-[#333]" />
              <h3 className="text-sm font-medium text-[#333]"> B2B Trips</h3>
            </div>
            <FaArrowDown
              className={`h-3 transition ${
                showB2bTrips
                  ? "animate-toArrowUp rotate-180"
                  : "animate-toArrowDown"
              }`}
            />
          </div>
          {showB2bTrips && (
            <div className="flex flex-col px-4 bg-white transition m-2 border rounded">
              <div
                className="flex items-center space-x-1 px-4 border-b py-2 cursor-pointer hover:bg-gray-50 transition duration-300 ease-in-out"
                onClick={() => navigate(`/carrier/b2b-trips`)}
              >
                <h1 className="text-sm font-medium text-gray-500">
                  View Trips
                </h1>
              </div>
              <div
                className="flex items-center space-x-1 px-4 border-b py-2 cursor-pointer hover:bg-gray-50 transition duration-300 ease-in-out"
                onClick={() => navigate(`/carrier/completed-b2b-trips`)}
              >
                <h1 className="text-sm font-medium text-gray-500">
                  Trips History
                </h1>
              </div>
            </div>
          )}
        </div>
        <div className="">
          <div
            onClick={() => {
              setShowProfile(!showProfile);
              setShowBookings(false);
              setShowQuotes(false);
              setShowInbox(false);
              setShowPayments(false);
              setShowAccount(false);
              setShowFeedback(false);
              setShowStatictics(false);
              setShowTrips(false);
              setShowB2bTrips(false);

              setShowTickets(false);
            }}
            className="flex p-1  items-center w-full px-4 py-2 justify-between transition cursor-pointer"
          >
            <div className="flex items-center space-x-2">
              <BiUser className="h-4 w-4 text-[#333]" />
              <h3 className="text-sm font-medium text-[#333]">My Profile</h3>
            </div>
            <FaArrowDown
              className={`h-3 transition ${
                showProfile
                  ? "animate-toArrowUp rotate-180"
                  : "animate-toArrowDown"
              }`}
            />
          </div>
          {showProfile && (
            <div className="flex flex-col px-4 bg-white transition m-2 border rounded">
              <div
                onClick={() => navigate(`/carrier/profileview/${user_id}`)}
                className="flex items-center space-x-1 px-4 border-b py-2 cursor-pointer hover:bg-gray-50 transition duration-300 ease-in-out"
              >
                <h1 className="text-sm font-medium text-gray-500">
                  View My Profile
                </h1>
              </div>
              <div
                onClick={() => navigate(`/carrier/profileupdate/${user_id}`)}
                className="flex items-center space-x-1 px-4 border-b py-2 cursor-pointer hover:bg-gray-50 transition duration-300 ease-in-out"
              >
                <h1 className="text-sm font-medium text-gray-500">
                  Update My Profile
                </h1>
              </div>
            </div>
          )}
        </div>
        <div className="">
          <div
            onClick={() => {
              setShowFeedback(!showFeedback);
              setShowB2bTrips(false);

              setShowTrips(false);
              setShowBookings(false);
              setShowQuotes(false);
              setShowInbox(false);
              setShowPayments(false);
              setShowAccount(false);
              setShowProfile(false);
              setShowStatictics(false);
              setShowTickets(false);
            }}
            className="flex p-1 items-center w-full px-4 py-2 justify-between transition cursor-pointer"
          >
            <div className="flex items-center space-x-2">
              <MdFeedback className="h-4 w-4 text-[#333]" />
              <h3 className="text-sm font-medium text-[#333]">
                Feedback Received
              </h3>
            </div>
            <div>
              <FaArrowDown
                className={`h-3 transition ${
                  showBookings
                    ? "animate-toArrowUp rotate-180"
                    : "animate-toArrowDown"
                }`}
              />
            </div>
          </div>
          {showFeedback && (
            <div className="flex flex-col px-4 bg-white transition m-2 border rounded">
              <div
                onClick={() => navigate("/carrier/booking/feedback/positive")}
                className="flex items-center space-x-1 px-4 border-b py-2 cursor-pointer hover:bg-gray-50 transition duration-300 ease-in-out"
              >
                <h1 className="text-sm font-medium text-gray-500">Positive</h1>
              </div>
              <div
                onClick={() => navigate("/carrier/booking/feedback/neutral")}
                className="flex items-center space-x-1 px-4 border-b py-2 cursor-pointer hover:bg-gray-50 transition duration-300 ease-in-out"
              >
                <h1 className="text-sm font-medium text-gray-500">Neutral</h1>
              </div>
              <div
                onClick={() => navigate("/carrier/booking/feedback/negative")}
                className="flex items-center space-x-1 px-4 border-b py-2 cursor-pointer hover:bg-gray-50 transition duration-300 ease-in-out"
              >
                <h1 className="text-sm font-medium text-gray-500">Negative</h1>
              </div>
            </div>
          )}
        </div>
        <div className="">
          <div
            onClick={() => {
              setShowTickets(!showTickets);
              setShowFeedback(false);
              setShowTrips(false);
              setShowBookings(false);
              setShowQuotes(false);
              setShowInbox(false);
              setShowPayments(false);
              setShowAccount(false);
              setShowProfile(false);
              setShowStatictics(false);
              setShowB2bTrips(false);
            }}
            className="flex p-1 items-center w-full px-4 py-2 justify-between transition cursor-pointer"
          >
            <div className="flex items-center space-x-2">
              <IoTicket className="h-4 w-4 text-[#333]" />
              <h3 className="text-sm font-medium text-[#333]">Raise Ticket</h3>
            </div>
            <FaArrowDown
              className={`h-3 transition ${
                showBookings
                  ? "animate-toArrowUp rotate-180"
                  : "animate-toArrowDown"
              }`}
            />
          </div>
          {showTickets && (
            <div className="flex flex-col bg-white  transition m-2 py-2 px-4 border rounded">
              <div
                onClick={() => navigate("/tickets/raiseTicket")}
                className="flex items-center space-x-1 px-4 border-b py-2 cursor-pointer hover:bg-gray-50 transition duration-300 ease-in-out"
              >
                <h1 className="text-sm font-medium text-gray-500">
                  Request Ticket
                </h1>
              </div>
            </div>
          )}
        </div>

        <div>
          <div
            onClick={() => {
              setShowAccount(!showAccount);
              setShowFeedback(false);
              setShowTrips(false);
              setShowBookings(false);
              setShowQuotes(false);
              setShowInbox(false);
              setShowPayments(false);
              setShowProfile(false);
              setShowStatictics(false);
              setShowTickets(false);
              setShowB2bTrips(false);
            }}
            className="flex p-1 items-center w-full px-4 py-2 justify-between cursor-pointer"
          >
            <div className="flex items-center space-x-2">
              <AiOutlineProfile />
              <h1 className="text-sm font-medium">My Account</h1>
            </div>
            <FaArrowDown
              className={`h-3  transition ${
                showAccount
                  ? "animate-toArrowUp rotate-180  "
                  : "animate-toArrowDown"
              } `}
            />
          </div>
          {showAccount && (
            <div className="flex flex-col bg-white transition m-2 border rounded">
              <div
                onClick={() => navigate(`/${user_type}/updateProfile`)}
                className="flex items-center space-x-1 px-4 border-b py-2 cursor-pointer hover:bg-gray-50 transition duration-300 ease-in-out"
              >
                <h1 className="text-sm font-medium text-gray-500">
                  Update Account Details
                </h1>
              </div>
              <div
                onClick={() => navigate(`/${user_type}/changePassword`)}
                className="flex items-center space-x-1 px-4 border-b py-2 cursor-pointer hover:bg-gray-50 transition duration-300 ease-in-out"
              >
                <h1 className="text-sm font-medium text-gray-500">
                  Change password
                </h1>
              </div>
            </div>
          )}
        </div>
        <div className="">
          <div
            onClick={() => {
              setShowStatictics(!showStatistics);
              setShowAccount(false);
              setShowFeedback(false);
              setShowTrips(false);
              setShowBookings(false);
              setShowQuotes(false);
              setShowInbox(false);
              setShowPayments(false);
              setShowProfile(false);
              setShowTickets(false);
              setShowB2bTrips(false);
            }}
            className="flex items-center w-full px-4 py-2 justify-between cursor-pointer"
          >
            <div className="flex items-center space-x-2">
              <ImStatsBars className="h-4 w-4 text-[#333]" />
              <h1 className="text-sm font-medium text-[#333]">Statistics</h1>
            </div>
            <FaArrowDown
              className={`h-3
            
            ${
              showStatistics
                ? "animate-toArrowUp rotate-180  "
                : "animate-toArrowDown"
            }
            `}
            />
          </div>
          {showStatistics && (
            <div className="flex flex-col bg-white transition m-2 border rounded">
              <div
                className="flex items-center space-x-1 px-4 border-b py-2 cursor-pointer hover:bg-gray-50 transition duration-300 ease-in-out"
                onClick={() => navigate(`/statistics`)}
              >
                <h1 className="text-sm font-medium text-gray-500">
                  My Statistics
                </h1>
              </div>
            </div>
          )}
        </div>
        <div
          onClick={() => {
            setShowAccount(false);
            setShowFeedback(false);
            setShowTrips(false);
            setShowBookings(false);
            setShowQuotes(false);
            setShowInbox(false);
            setShowPayments(false);
            setShowProfile(false);
            setShowTickets(false);
            setShowChats(!showChats);
            setShowStatictics(false);
          }}
        >
          <div
            className="flex items-center w-full px-4 py-2 justify-between cursor-pointer"
            // className={cn(
            //   window.location.pathname === "/carrier/messages" &&
            //     "bg-freightBlueDark/10 rounded-r"
            // )}
          >
            <div className="flex items-center space-x-2">
              <IoChatbox className="h-4 w-4  " />

              <h1 className="text-sm font-medium text-[#333]">Chat</h1>
            </div>
            <FaArrowDown
              className={`h-3
            
            ${
              showChats
                ? "animate-toArrowUp rotate-180  "
                : "animate-toArrowDown"
            }
            `}
            />
          </div>
          {showChats && (
            <div className="flex flex-col bg-white transition m-2 border rounded">
              <div
                onClick={() => navigate("/carrier/messages")}
                className="flex items-center space-x-1 px-4 border-b py-2 cursor-pointer hover:bg-gray-50 transition duration-300 ease-in-out"
              >
                <h1 className="text-sm font-medium text-gray-500">My Chats</h1>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
