import React from "react";
import Header from "../components/header/Header";
import { Elipses } from "../components/home/Elipses";
//@ts-ignore
import aboutUs from "../assets/images/about-us.jpg";
//@ts-ignore
import aboutUs2 from "../assets/images/AboutUs.png";
import Footer from "../components/layout/Footer";
export const AboutUs = () => {
  return (
    <div className="h-screen w-screen overflow-x-hidden bg-white">
      <div>
        <Header />
      </div>
      <div className="mt-32">
        <Elipses />
      </div>
      <div className="flex justify-center">
        <p className="text-4xl font-bold mb-8"> About Us </p>
      </div>
      <div className="flex justify-center p-2 ">
        <img src={aboutUs} className="w-[500px] rounded-3xl shadow-md" alt="" />
      </div>
      <div className="flex justify-center">
        <div className="max-w-[1000px] p-10">
          <p className="pb-8">
            <b style={{ color: "#0740bc" }}> RUNNME</b> is a web app for
            deliveries that facilitates the immediate delivery of orders between
            users and clients. It's a platform for anyone to become a delivery
            partner affiliated to an automated logistics system. Anyone can send
            a package, or ask for an errand to be run.
          </p>
          <p className="pb-8">
            <b style={{ color: "#0740bc" }}> RUNNME</b> works to be useful,
            fast, efficient, and to do task and deliveries for us, when we don't
            have the time.
          </p>
          <p
            className="pb-8 text-5xl font-bold"
            style={{ fontFamily: "Franklin Gothic" }}
          >
            <span style={{ color: "#0022b6" }}>M</span>ISSION
          </p>{" "}
          <p className="pb-8">
            <b style={{ color: "#0740bc" }}> RUNNME</b> is more than a simple
            marketplace; it's a comprehensive shippingmanagement ecosystem. It
            goes above and beyond to provide a seamless,secure, and efficient
            process for managing deliveries, from obtaining quotesand selecting
            carriers to tracking shipments. With RUNNME, logistics
            becomeseffortlessly manageable for all stakeholders involved also we
            has a mission and responsibility to reduce the environmental impact
            of vehicles traveling empty or not fully utilized, through the
            efficiency of connecting shippers with carriers at both ends of a
            trip. We belive that our project and business sistem can reduce tons
            of CO2 emissions.
          </p>
          <p className="pb-8">
            <b>Key Issue: </b> Ensuring that individuals orentities responsible
            for transportinggoods or people are justly rewarded fortheir
            services.
          </p>
          <p className="pb-8">
            <b>Objective:</b> Our aim is to lower wait timesfor errands, thereby
            fostering growth inlocal businesses and offering individualsflexible
            earning opportunities.
          </p>
          <p className="pb-8">
            <b>Importance:</b>The significance of thedelivery sector within the
            retail industrycannot be overstated, as it plays a pivotalrole in
            ensuring that goods and productsreach consumers in a timely,
            efficient,and satisfactory manner.
          </p>
          <p
            className="pb-8 text-5xl font-bold"
            style={{ fontFamily: "Franklin Gothic" }}
          >
            <span style={{ color: "#0022b6" }}>V</span>ISION
          </p>{" "}
          <p className="pb-8">
            The vision is not to limit the business development throughthe size
            or weight of the load, but to find the ideal personor company for
            the cargo.
          </p>
          <p className="pb-8">
            Provide a fast and accessible delivery service that connects people
            with what they need, when they need it, in a way that is sustainable
            and beneficial for the community.
          </p>
          <p className="pb-8">
            Allow users to manage loads and logistics effectively from any
            device, improving planning and response to unforeseen events, and
            increasing customer satisfaction. Advanced technology provides
            real-time tracking, optimizing communication and decision-making.
          </p>
          <p className="pb-8">
            To encourage universal transporters development by providing a
            global audience, fair competition format, new career pathways and
            diverse development schemes.
          </p>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
