import { Controller, useForm } from "react-hook-form";

//@ts-ignore
import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { Loader2, User } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import {
  BASE_URL,
  CARBON_FOOTPRINT,
  CUSTOMER_GETPROFILE_URL,
  DELETE_ACCOUNT,
  GET_USER_DATA,
  UPDATE_USER_DATA,
  UPDATE_USER_PROFILE_IMAGE,
} from "../api/config";
import Foto1 from "../assets/images/driver.jpg";
import SidebarLayout from "../components/layout/SidebarLayout";
import TruckLoad from "../components/loadCarbon/LoadCarbon";
import ModalDeleteAccount from "../components/modal/ModalDeleteAccount";
import { Button } from "../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form";
import { Input } from "../components/ui/input";
import { Select, SelectTrigger, SelectValue } from "../components/ui/select";
import { useDeleteAccount } from "../hooks/use-delete-account";
import { cn } from "../lib/utils";

import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";


//@ts-ignore
import placeholder from "../assets/images/user-icon-placeholder.jpg";

const updateProfileFormSchema = z.object({
  email: z.string().min(1, "Email address is required").optional(),
  phone: z.string().min(1, "Phone Number is required").optional(),
  firstName: z.string().min(1, "First name is required").optional(),
  lastName: z.string().min(1, "Last name is required").optional(),
  // imageUser: z.any(),
  companyName: z
    .string()
    .min(1, "Company name is required")
    .optional()
    .or(z.literal("")),
  address: z
    .string()
    .min(1, "Address is required")
    .optional()
    .or(z.literal("")),
  addressline2: z
    .string()
    .min(1, "Address Line 2 is required")
    .optional()
    .or(z.literal("")),
  state: z.string().min(1, "State is required").optional().or(z.literal("")),
  suburb: z.string().min(1, "Suburb is required").optional().or(z.literal("")),
  postcode: z
    .string()
    .min(1, "Postcode is required")
    .optional()
    .or(z.literal("")),
});

const updateProfileImageFormSchema = z.object({
  imageUser: z.any(),
});

const UpdateProfile = () => {
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const [selectedState, setSelectedState] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDeletingAccount, setLoadingDeletingAccount] = useState(false);
  const setToken = useTokenStoreStorage((state) => state.addToken);
  const setUserInfo = userInfoUserStore((s) => s.addUserInfo);
  const navigate = useNavigate();
  const modalDeleteAccount = useDeleteAccount();
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [file, setFile] = useState<any>();
  const [showImageProfile, setShowImageProfile] = useState(false);
  const [loadingUploadImage, setLoadingUploadImage] = useState(false);

  const getProfile = async () => {
    const response = await fetch(`${BASE_URL}${CUSTOMER_GETPROFILE_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    }).then((res) => res.json());

    return response;
  };

  const {
    data: profile,
    isLoading,
    refetch,
  } = useQuery<any>({
    queryKey: ["profile", user_id],
    queryFn: getProfile,
  });

  const form = useForm<z.infer<typeof updateProfileFormSchema>>({
    resolver: zodResolver(updateProfileFormSchema),

    defaultValues: {
      email: "",
      phone: "",
      firstName: "",
      // imageUser: "",
      state: "",
      lastName: "",
      companyName: "",
      address: "",
      addressline2: "",
      suburb: "",
      postcode: "",
    },
  });

  const imageForm = useForm<z.infer<typeof updateProfileImageFormSchema>>({
    resolver: zodResolver(updateProfileImageFormSchema),
    defaultValues: {
      imageUser: "",
    },
  });

  const getUserData = async () => {
    const response = await fetch(
      `${BASE_URL}${GET_USER_DATA}?user_id=${user_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const {
    data: userData,
    refetch: refetchUserData,
    isLoading: isLoadingUserData,
  } = useQuery({
    queryKey: ["user_data"],
    queryFn: getUserData,
  });

  useEffect(() => {
    if (!isLoadingUserData) {
      form.reset({
        email: userData?.data[0]?.email,
        phone: userData?.data[0]?.phoneNumber,
        firstName: userData?.data[0]?.firstname,
        // imageUser: profile?.data?.profile_img,
        state: userData?.data[0]?.user_details?.state,
        lastName: userData?.data[0].lastname,
        companyName: userData?.data[0].user_details?.company_name,
        address: userData?.data[0].user_details?.address_line1,
        addressline2: userData?.data[0].user_details?.address_line2,
        suburb: userData?.data[0]?.user_details?.city,
        postcode: userData?.data[0]?.user_details?.postcode,
      });
    }
  }, [userData]);

  useEffect(() => {
    if (!isLoadingUserData) {
      imageForm.reset({
        imageUser: userData?.data?.[0].user_details?.profile_img,
      });
    }
  }, [userData]);

  const onSubmit = async (values: z.infer<typeof updateProfileFormSchema>) => {
    setLoadingUpdate(true);
    setLoading(true);
    const response = await fetch(`${BASE_URL}${UPDATE_USER_DATA}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        phoneNumber: values.phone,
        company_name: values.companyName,
        address_line1: values.address,
        address_line2: values.addressline2,
        city: values.suburb,
        state: values.state,
        postcode: values.postcode,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "User updated successfully") {
          refetchUserData();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setLoadingUpdate(false);
      });

    return response;
  };

  const onSubmitImageForm = async (
    values: z.infer<typeof updateProfileImageFormSchema>
  ) => {
    uploadImage(values.imageUser);
  };

  const formData = new FormData();

  formData.append("user_id", user_id);

  const uploadImage = async (profileImage: string) => {
    setLoadingUploadImage(true);
    formData.append("profile_image", profileImage);
    const response = await fetch(`${BASE_URL}${UPDATE_USER_PROFILE_IMAGE}`, {
      method: "POST",
      headers: {
        AccessToken: token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Image updated successfully.") {
          refetchUserData();
          setFile(null);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingUploadImage(false);
      });

    return response;
  };

  const deleteUserAccount = async () => {
    setLoadingDeletingAccount(true);
    const response = await fetch(`${BASE_URL}${DELETE_ACCOUNT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        user_type: user_type,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "User deleted successfully.") {
          setToken("");
          setUserInfo("", "", "", "", "");
          navigate("/sign-in");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingDeletingAccount(false);
      });

    return response;
  };
  const getCarbonFootPrint = async () => {
    const response = await fetch(
      `${BASE_URL}${CARBON_FOOTPRINT}?user_id=${user_id}`,
      {
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };
  const { data: carbonFootprint } = useQuery({
    queryKey: ["carbon_footprint"],
    queryFn: getCarbonFootPrint,
  });

  return (
    <SidebarLayout>
      <div className="">
        <div className="flex items-center justify-between w-full border-border border-b pb-3 space-x-2 mb-4">
          <h1 className="text-lg font-semibold ">Update Details</h1>
        </div>
        <div className="flex items-start justify-between">
          <div className="flex items-start space-x-2 mb-4">
            <div
              className={cn(
                "bg-[#e2e2e2] px-4 py-2 rounded cursor-pointer",
                !showImageProfile && "bg-[#333] text-white"
              )}
              onClick={() => {
                setShowImageProfile(false);
              }}
            >
              <p className="text-sm font-medium">Account information</p>
            </div>
            <div
              className={cn(
                "bg-[#e2e2e2] px-4 py-2 rounded cursor-pointer",
                showImageProfile && "bg-[#333] text-white"
              )}
              onClick={() => {
                setShowImageProfile(true);
              }}
            >
              <p className="text-sm font-medium">Image profile</p>
            </div>
          </div>
          {carbonFootprint?.message === "No data (km) found for the user." ? (
            <></>
          ) : (
            <div className="flex flex-col items-start">
              {/* <div className="relative w-[300px] h-[150px]">
                <img
                  src={require("../assets/truck-image.png")}
                  alt="Truck"
                  className="w-full h-full object-contain"
                />

                <div
                  className="absolute top-[25%] left-[25%] h-[45%] bg-black/20 transition-all duration-500"
                  style={{ width: `${loadPercentage}%` }}
                />
              </div> */}
              <TruckLoad
                percentage={Math.round(
                  carbonFootprint?.data.CarbonPrint.total_result * 100
                )}
                tier={carbonFootprint?.data.CarbonPrint.tier}
              />
              <div className="flex items-start">
                <div className="flex flex-col items-start  w-20 h-20 bg-black/60 p-2 rounded-l-lg">
                  <p className="text-sm font-semibold text-white">CO2 </p>
                  <p className="text-yellow-400">
                    {" "}
                    {carbonFootprint?.data.CarbonPrint.total_kg}{" "}
                  </p>
                  <p className="text-gray-300">kg</p>
                </div>
                <div className="flex flex-col items-start  w-20 h-20 bg-black/60 p-2  border-l-2">
                  <p className="text-sm font-semibold text-white">Save </p>
                  <p className="text-yellow-400">
                    {" "}
                    {carbonFootprint?.data.CarbonPrint.total_km}{" "}
                  </p>
                  <p className="text-gray-300">km</p>
                </div>
                <div className="flex flex-col items-start  w-20 h-20 bg-black/60 p-2 rounded-r-lg border-l-2">
                  <p className="text-sm font-semibold text-white">Tier </p>
                  <p className="text-yellow-400">
                    {" "}
                    {carbonFootprint?.data.CarbonPrint.tier}{" "}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        {showImageProfile && (
          <div className="flex flex-col mt-10 bg-white shadow p-4 border-border border">
            <Form {...imageForm}>
              <form
                className="flex flex-col w-full"
                onSubmit={imageForm.handleSubmit(onSubmitImageForm)}
              >
                <div className="w-full mb-4 relative">
                  <h1 className="font-medium text-sm mb-2">Images</h1>

                  {isLoading ? (
                    <div className="w-20 h-20 bg-gray-50 flex items-center justify-center rounded mb-2">
                      <Loader2 className="h-4 w-4 animate-spin" />
                    </div>
                  ) : (
                    <>
                      {userData?.data[0]?.user_details?.profile_img === null ? (
                        <>
                          <img
                            alt="profile"
                            src={require("../assets/images/user-icon-placeholder.jpg")}
                            className=" w-32 object-contain mb-2 rounded"

                          />
                        </>
                      ) : (
                        <>
                          <img
                            alt="profile"
                            src={
                              profile?.data?.profile_img
                                ? `${BASE_URL}images?user_id=${user_id}&file_name=${userData?.data[0].user_details?.profile_img}&image_type=profile`
                                : Foto1
                            }
                            className=" w-32 object-contain mb-2 rounded"
                          />
                        </>
                      )}
                    </>
                  )}
                  <Controller
                    name="imageUser"
                    control={imageForm.control}
                    render={({ field, fieldState }) => (
                      <div>
                        <Input
                          accept="image/png, image/jpeg, image/gif*"
                          {...field}
                          value={field.value?.fileName}
                          type="file"
                          onChange={(event: any) => {
                            field.onChange(event.target.files?.[0]);
                            setFile(
                              URL.createObjectURL(event.target.files?.[0])
                            );
                          }}
                        />
                        {fieldState.error && (
                          <p className="text-red-500 text-sm mt-1">
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                  {file && (
                    <img
                      src={file}
                      className=" w-32 object-contain rounded mt-2"
                      alt="profile"
                    />
                  )}
                </div>
                <Button
                  disabled={!file}
                  variant={"freightBlue"}
                  className="w-40 ml-auto"
                >
                  {loadingUploadImage ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                    </>
                  ) : (
                    <p className="text-sm font-medium">Upload Image</p>
                  )}
                </Button>
              </form>
            </Form>
          </div>
        )}
        {!showImageProfile && (
          <div className="flex flex-col mt-10 bg-white p-4 border-border border">
            <Form {...form}>
              <form
                className="w-full flex flex-col"
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <div className="w-full mb-4 relative">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field, fieldState }) => (
                      <div>
                        <FormItem>
                          <FormLabel className="text-sm pb-3 dark:text-black">
                            Email
                          </FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              name="email"
                              type="email"
                              className="w-full dark:bg-transparent"
                            />
                          </FormControl>
                        </FormItem>
                        {fieldState.error && (
                          <p className="text-red-500 text-sm mt-1">
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>

                <div className="w-full mb-4 relative">
                  <FormField
                    control={form.control}
                    name="phone"
                    render={({ field, fieldState }) => (
                      <div>
                        <FormItem>
                          <FormLabel>Phone number</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              name="phone"
                              type="phone"
                              className="w-full dark:bg-transparent"
                            />
                          </FormControl>
                        </FormItem>
                        {fieldState.error && (
                          <p className="text-red-500 text-sm mt-1">
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="w-full mb-4 relative">
                  <FormField
                    control={form.control}
                    name="firstName"
                    render={({ field, fieldState }) => (
                      <div>
                        <FormItem>
                          <FormLabel>First name</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              name="firstname"
                              type="text"
                              className="w-full dark:bg-transparent"
                            />
                          </FormControl>
                        </FormItem>
                        {fieldState.error && (
                          <p className="text-red-500 text-sm mt-1">
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="w-full mb-4 relative">
                  <FormField
                    control={form.control}
                    name="lastName"
                    render={({ field, fieldState }) => (
                      <div>
                        <FormItem>
                          <FormLabel>Last name</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              name="lastname"
                              type="text"
                              className="w-full dark:bg-transparent"
                            />
                          </FormControl>
                        </FormItem>
                        {fieldState.error && (
                          <p className="text-red-500 text-sm mt-1">
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>

                <div className="w-full mb-4 relative">
                  <FormField
                    control={form.control}
                    name="companyName"
                    render={({ field, fieldState }) => (
                      <div>
                        <FormItem>
                          <FormLabel>Company name</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              name="companyName"
                              type="text"
                              className="w-full dark:bg-transparent"
                            />
                          </FormControl>
                        </FormItem>
                        {fieldState.error && (
                          <p className="text-red-500 text-sm mt-1">
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>

                <div className="w-full mb-4 relative">
                  <FormField
                    control={form.control}
                    name="address"
                    render={({ field, fieldState }) => (
                      <div>
                        <FormItem>
                          <FormLabel>Address</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              name="address"
                              type="text"
                              className="w-full dark:bg-transparent"
                            />
                          </FormControl>
                        </FormItem>
                        {fieldState.error && (
                          <p className="text-red-500 text-sm mt-1">
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="w-full mb-4 relative">
                  <FormField
                    control={form.control}
                    name="addressline2"
                    render={({ field, fieldState }) => (
                      <div>
                        <FormItem>
                          <FormLabel>Address Line 2</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              name="addressline2"
                              type="text"
                              className="w-full dark:bg-transparent"
                            />
                          </FormControl>
                        </FormItem>
                        {fieldState.error && (
                          <p className="text-red-500 text-sm mt-1">
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="w-full mb-4 relative">
                  <FormField
                    control={form.control}
                    name="suburb"
                    render={({ field, fieldState }) => (
                      <div>
                        <FormItem>
                          <FormLabel>Suburb</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              name="suburb"
                              type="text"
                              className="w-full dark:bg-transparent"
                            />
                          </FormControl>
                        </FormItem>
                        {fieldState.error && (
                          <p className="text-red-500 text-sm mt-1">
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="w-full mb-4 relative">
                  <h1 className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 mb-2">
                    State
                  </h1>

                  <Controller
                    name="state"
                    control={form.control}
                    render={({ field, fieldState }) => (
                      <div>
                        <Select
                          onValueChange={(value) => {
                            form.setValue("state", value);
                          }}
                          {...field}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select" />
                          </SelectTrigger>
                        </Select>
                        {fieldState.error && (
                          <p className="text-sm font-medium text-red-500">
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className="w-full mb-4 relative">
                  <FormField
                    control={form.control}
                    name="postcode"
                    render={({ field, fieldState }) => (
                      <div>
                        <FormItem>
                          <FormLabel>Postcode</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              name="postcode"
                              type="text"
                              className="w-full dark:bg-transparent"
                            />
                          </FormControl>
                        </FormItem>
                        {fieldState.error && (
                          <p className="text-red-500 text-sm mt-1">
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                </div>
                <Button variant={"freightBlue"} className="max-w-60 items-end ">
                  {loadingUpdate ? (
                    <>
                      <Loader2 className="h-5 w-5 animate-spin" />
                    </>
                  ) : (
                    <p>Update Profile</p>
                  )}
                </Button>
              </form>
            </Form>
          </div>
        )}
      </div>
      <div className=" bg-white shadow p-4 mt-10">
        <div className="flex flex-col ">
          <div className="flex w-full mb-2 border-b pb-3">
            <div className="flex items-center space-x-2">
              <User className="h-5 w-5 text-freightBlueDark" />
              <h1 className="text-sm font-semibold ">Account</h1>
            </div>
          </div>
          <div className="sm:flex items-end justify-between">
            <div className="w-2/3">
              <p className="text-sm font-semibold">Delete account</p>
              <p className="text-sm text-gray-400">
                Take into account that deleting your account, all the account's
                information will be delete without the possibility to restore.
              </p>
            </div>
            <Button
              type="button"
              className="bg-freightBlueDark font-semibold"
              onClick={() => modalDeleteAccount.onOpen("deleteAccount")}
              variant={"freightBlue"}
            >
              {loadingDeletingAccount ? (
                <>
                  <Loader2 className="h-6 w-6 animate-spin" />
                </>
              ) : (
                <p>Delete account</p>
              )}
            </Button>
          </div>
        </div>
        {modalDeleteAccount && <ModalDeleteAccount />}
      </div>
    </SidebarLayout>
  );
};

export default UpdateProfile;
