import { useEffect, useState } from "react";
import "./LoadCarbon.css";

//@ts-ignore
import { CreateSvg } from "./CreateSvg";
const TruckLoad = ({
  percentage,
  tier,
}: {
  percentage: number;
  tier: number;
}) => {
  // const clampedLoad = Math.min(Math.max(percentage, 0), 100);

  // const maxLoadHeight = 70;
  const [svgContent, setSvgContent] = useState(CreateSvg(percentage)); // Initialize SVG content

  useEffect(() => {
    setSvgContent(CreateSvg(percentage));
  }, [percentage]);

  return (
    <div className="relative w-[300px] h-[150px]">
      {/* Truck Image */}
      {tier === 1 && (
        <>
          <div dangerouslySetInnerHTML={{ __html: svgContent }} />
          <div className="absolute bottom-[60px] left-32 font-semibold">
            {percentage}%
          </div>
        </>
      )}

      {tier === 2 && (
        <img
          src={require("../../assets/truck-image.png")}
          alt="Truck"
          className="w-full h-full object-contain"
        />
      )}
    </div>
  );
};

export default TruckLoad;
