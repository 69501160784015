import SidebarLayout from "../components/layout/SidebarLayout";

const Subscriptions = () => {
  return (
    <SidebarLayout>
      <div className="flex items-center justify-between space-x-2 border-b pb-3 mb-4">
        <h1 className="text-lg font-semibold">Subscriptions</h1>
      </div>
      <div className=" bg-white p-4 shadow rounded">
        <div>
          <h1 className="text-lg font-medium">Nothing found</h1>
          <p className="text-sm text-gray-400">
            Right now, you don't have any subscriptions
          </p>
        </div>
      </div>
    </SidebarLayout>
  );
};

export default Subscriptions;
