import { useEffect, useState } from "react";
import { AVAILABLE_PACKAGES, BASE_URL } from "../api/config";

export const useAvailablePackages = () => {
  const [availablePackage, setAvailablePackages] = useState<any>();

  const getPackages = async () => {
    const response = fetch(`${BASE_URL}${AVAILABLE_PACKAGES}`, {
      method: "GET",
      headers: {},
    })
      .then((res) => res.json())
      .then((data) => {
        setAvailablePackages(data.data);
      })

      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  useEffect(() => {
    getPackages();
  }, []);

  return availablePackage;
};
