import {
  BASE_URL,
  FREIGHT_LIST,
  GET_CUSTOMER_STOPS,
  GET_IMAGE,
  GET_INBOX,
} from "src/api/config";
import small from "../../assets/images/books-box.png";
import large from "../../assets/images/moving-box.png";
import medium from "../../assets/images/shoes-box.png";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { FreightItem, MessageType, StopType } from "@/src/types/types";
import { Button } from "../ui/button";
import ModalCancelFreight from "../modal/ModalCancelFreight";
import { useCancelFreightModal } from "src/hooks/use-cancel-freight";
import { cn } from "src/lib/utils";
import { ChevronRight, Eye, Mail } from "lucide-react";
import { useNavigate } from "react-router-dom";
import SidebarLayout from "../layout/SidebarLayout";
import { Skeleton } from "../skeleton/Skeleton";
//@ts-ignore
import SmallBox from "../../assets/images/Smallbox.png";
//@ts-ignore
import MediumBox from "../../assets/images/Mediumbox.png";
//@ts-ignore
import LargeBox from "../../assets/images/Largebox.png";

//@ts-ignore
import { ReactComponent as EmptySvg } from "../../assets/images/data-not-found.svg";
import { hasLoggedIn } from "src/atoms/atoms";
import { useAtom } from "jotai";

const DashboardCustomer = () => {
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const modalCancelFreight = useCancelFreightModal();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useAtom(hasLoggedIn);

  const getFreightList = async () => {
    const response = await fetch(`${BASE_URL}${FREIGHT_LIST}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        status: "active",
        sort: "DESC",
      }),
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return response;
  };

  const {
    data: freightList,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["freight_list"],
    queryFn: getFreightList,
  });

  const getInbox = async () => {
    const response = await fetch(`${BASE_URL}${GET_INBOX}`, {
      method: "POST",
      body: JSON.stringify({
        user_id: user_id,
        status: "unread",
      }),
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
    }).then((res) => res.json());
    return response;
  };

  const { data: messagesInbox } = useQuery({
    queryKey: ["inbox"],
    queryFn: getInbox,
  });

  useEffect(() => {
    if (
      !isLoading &&
      messagesInbox?.message === "Invalid access_token provided." &&
      !isLoggedIn
    ) {
      navigate("/sign-in");
    }
  }, [messagesInbox, isLoading, isLoggedIn]);

  const getRoutesByCustomerId = async () => {
    const response = await fetch(
      `${BASE_URL}${GET_CUSTOMER_STOPS}?user_id=${user_id}&sort=desc`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return response;
  };
  const {
    data: routes,
    isLoading: isLoadingRoutes,
    refetch: refetchStops,
  } = useQuery({
    queryKey: ["routes_customer"],
    queryFn: getRoutesByCustomerId,
  });

  return (
    <SidebarLayout>
      {/* <Header /> */}
      {/* <div className="grid grid-cols-7 mt-20 px-10">
        <div className="col-span-2">
          <CustomerSidebar />
        </div> */}
      {/* <div className="col-span-5"> */}
      <div className="flex items-center justify-between w-full border-b border-border pb-3 mb-4">
        <h1 className="text-lg font-semibold ">Recent Unread Messages</h1>
      </div>
      <div className=" mr-auto bg-white shadow ">
        {!isLoading &&
          messagesInbox?.message === "My inbox." &&
          !messagesInbox?.data && (
            <div className="flex flex-col items-center w-full mx-auto space-y-4 justify-center py-3">
              <EmptySvg />
              <p className=" font-medium">Nothing found</p>
              <p className="text-gray-500 font-medium text-sm">
                No messages in your inbox
              </p>
            </div>
          )}
        {isLoading ? (
          <Table className="border border-gray-200 bg-white shadow-md">
            <TableHeader>
              <TableRow>
                <TableHead className="bg-gray-100 text-gray-600">
                  From
                </TableHead>
                <TableHead className="bg-gray-100 text-gray-600">
                  Subject
                </TableHead>
                <TableHead className="bg-gray-100 text-gray-600">
                  Received
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {/* Add skeleton loaders or placeholder rows if needed */}
            </TableBody>
          </Table>
        ) : (
          <>
            {messagesInbox?.data?.length > 0 && (
              <Table className="border border-gray-200 bg-white shadow-md">
                <TableHeader>
                  <TableRow>
                    <TableHead className="bg-gray-100 text-gray-600">
                      From
                    </TableHead>
                    <TableHead className="bg-gray-100 text-gray-600">
                      Subject
                    </TableHead>
                    <TableHead className="bg-gray-100 text-gray-600">
                      Received
                    </TableHead>
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {messagesInbox?.data
                    ?.slice(0, 4)
                    .map((message: MessageType) => (
                      <TableRow
                        key={message.id}
                        className="hover:bg-gray-50 transition-colors duration-200"
                      >
                        <TableCell>
                          <div className="flex items-center space-x-2">
                            <Mail className="h-5 w-5 text-gray-500" />
                            <p className="font-semibold text-sm text-gray-800">
                              Freight
                            </p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <p
                            onClick={() =>
                              navigate(`/myInbox/showdetail/${message.id}`)
                            }
                            className={cn(
                              message.status === "unread"
                                ? "text-freightBlue font-medium"
                                : "text-gray-600",
                              "text-sm cursor-pointer hover:underline"
                            )}
                          >
                            {message.subject}
                          </p>
                        </TableCell>
                        <TableCell>
                          <p className="font-medium text-gray-600">
                            {message.date}
                          </p>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </>
        )}
      </div>
      <div className="flex  mt-10 items-center justify-between w-full border-b border-border pb-3 mb-4">
        <h1 className="text-lg font-semibold ">Active Freight Listings</h1>
      </div>
      <div className=" mr-auto bg-white shadow ">
        {!isLoading && freightList?.message === "No freight found." && (
          <div className="flex flex-col items-center w-full mx-auto space-y-4 justify-center py-3">
            <EmptySvg />
            <p className=" font-medium">Nothing found</p>
            <p className="text-gray-500 font-medium text-sm">
              No freights available
            </p>
          </div>
        )}

        {isLoading ? (
          <Table className="border border-gray-300 rounded-lg">
            <TableHeader>
              <TableRow>
                <TableHead></TableHead>
                <TableHead>Details</TableHead>
                <TableHead>Freight/Date</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Action</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Skeleton className="h-5 w-20" />
                  <Skeleton className="h-5 w-16 mt-4" />
                  <Skeleton className="h-5 w-24 mt-4" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-5 w-20" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-5 w-20" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-5 w-20" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-5 w-20" />
                </TableCell>
                <TableCell>
                  <Skeleton className="h-5 w-20" />
                  <Skeleton className="h-5 w-20 mt-4" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <>
            {freightList?.data?.freights?.length > 0 && (
              <Table className="border border-gray-300 rounded-lg">
                <TableHeader>
                  <TableRow>
                    <TableHead></TableHead>
                    <TableHead>Details</TableHead>
                    <TableHead>Freight/Date</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Action</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {freightList?.data?.freights
                    .slice(0, 4)
                    ?.map((freight: FreightItem) => (
                      <TableRow key={freight.id}>
                        <TableCell className="p-4">
                          <div className="text-sm bg-gray-100 font-medium text-center py-2 mb-4 rounded-md">
                            Category{" "}
                            <span className="text-gray-500">
                              {freight.category}/{freight.sub_category}
                            </span>
                          </div>
                          {freight.animal_img ? (
                            <div className="flex items-center space-x-2">
                              <img
                                className="h-40 object-cover rounded-lg shadow-sm"
                                alt={freight.title}
                                src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${freight.animal_img}&image_type=freight`}
                              />
                              {/* <p>{`Description: ${freight.description}`}</p> */}
                            </div>
                          ) : (
                            <div className="flex items-center space-x-2">
                              <div className="flex items-center justify-center w-40 h-40 rounded-lg shadow-sm">
                                {freight.freight_type_info?.freight_size.id ===
                                  1 && (
                                  <img
                                    src={small}
                                    className="h-22 w-22 object-contain cursor-pointer"
                                    alt="small package"
                                    onClick={() =>
                                      navigate(
                                        `/customer/freightDetails/${freight.id}`
                                      )
                                    }
                                  />
                                )}
                                {freight.freight_type_info?.freight_size.id ===
                                  2 && (
                                  <img
                                    src={medium}
                                    className="h-22 w-22 object-contain cursor-pointer"
                                    alt="medium package"
                                    onClick={() =>
                                      navigate(
                                        `/customer/freightDetails/${freight.id}`
                                      )
                                    }
                                  />
                                )}
                                {freight.freight_type_info?.freight_size.id ===
                                  3 && (
                                  <img
                                    src={large}
                                    className="h-22 w-22 object-contain cursor-pointer"
                                    alt="large package"
                                    onClick={() =>
                                      navigate(
                                        `/customer/freightDetails/${freight.id}`
                                      )
                                    }
                                  />
                                )}
                              </div>
                              {freight.category !== "Animals" && (
                                <div className="mt-3">
                                  <p className="font-bold flex gap-2 capitalize">
                                    {
                                      freight.freight_type_info?.freight_size
                                        .name
                                    }{" "}
                                    Dimensions
                                  </p>
                                  <p>
                                    {
                                      freight.freight_type_info?.freight_size
                                        .length
                                    }{" "}
                                    x{" "}
                                    {
                                      freight.freight_type_info?.freight_size
                                        .width
                                    }{" "}
                                    x{" "}
                                    {
                                      freight.freight_type_info?.freight_size
                                        .height
                                    }{" "}
                                    cm
                                  </p>
                                  <p>
                                    {
                                      freight.freight_type_info?.freight_size
                                        .weight
                                    }{" "}
                                    kg
                                  </p>
                                </div>
                              )}
                            </div>
                          )}
                        </TableCell>
                        <TableCell className="p-4">
                          <div className="flex flex-col space-y-2">
                            <p
                              onClick={() =>
                                navigate(
                                  `/customer/freightDetails/${freight.id}`
                                )
                              }
                              className="text-freightBlueDark hover:underline cursor-pointer text-base font-semibold"
                            >
                              {freight.title}
                            </p>
                            <div className="flex items-center space-x-2 text-sm text-gray-700">
                              <p>{freight.start_date}</p>
                              <ChevronRight className="h-4 w-4" />
                              <p>{freight.end_date}</p>
                            </div>
                            <p className="text-black">
                              Origin:{" "}
                              <span className="font-medium">
                                {freight.origin_address}
                              </span>
                            </p>
                            <p className="text-black">
                              Destination: {freight.destination_address}
                            </p>
                          </div>
                        </TableCell>

                        <TableCell className="p-4">
                          {freight.expire_date}
                        </TableCell>
                        <TableCell className="p-4">
                          <p
                            className={cn(
                              "flex justify-start text-sm px-4 py-2 text-white font-semibold rounded-md",
                              freight.status === "active" && "text-green-500",
                              freight.status === "cancelled" && "text-gray-500",
                              freight.status === "booked" && "text-freightBlue"
                            )}
                          >
                            {freight.status.toUpperCase()}
                          </p>
                        </TableCell>
                        <TableCell className="p-4">
                          <div className="flex flex-col space-y-2">
                            <Button
                              onClick={() =>
                                navigate(`/editFreight/details/${freight.id}`)
                              }
                              variant="freightBlue"
                              className="w-28"
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() =>
                                modalCancelFreight.onOpen(
                                  "cancelFreight",
                                  freight.id.toString()
                                )
                              }
                              variant="outline"
                              className="w-28 mt-1"
                            >
                              Cancel
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </>
        )}
      </div>
      <div className="flex  mt-10 items-center justify-between w-full border-b border-border pb-3 mb-4">
        <h1 className="text-xl font-semibold text-gray-800">
          Active Stops Delivery
        </h1>
      </div>

      <div className="bg-white shadow-md rounded-lg p-4">
        {!isLoadingRoutes && routes?.data?.stops?.length === 0 ? (
          <div className="flex flex-col items-center space-y-2 py-8">
            <EmptySvg className="h-20 w-24 text-gray-400" />
            <p className="text-lg font-medium text-gray-700">Nothing found</p>
            <p className="text-gray-500 text-sm">No stops available</p>
          </div>
        ) : isLoadingRoutes ? (
          <Table className="min-w-full border border-gray-200 bg-white shadow-sm rounded-lg">
            <TableHeader className="bg-gray-100 text-gray-600">
              <TableRow>
                <TableHead className="p-3">Stop Id/Package</TableHead>
                <TableHead className="p-3">Origin/Destination</TableHead>
                <TableHead className="p-3">Size</TableHead>
                <TableHead className="p-3">Weight</TableHead>
                <TableHead className="p-3">Price</TableHead>
                <TableHead className="p-3">Status</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell className="p-3">
                  <Skeleton className="h-5 w-24" />
                  <Skeleton className="h-5 w-16 mt-4" />
                  <Skeleton className="h-5 w-24 mt-4" />
                </TableCell>
                <TableCell className="p-3">
                  <Skeleton className="h-5 w-24" />
                </TableCell>
                <TableCell className="p-3">
                  <Skeleton className="h-5 w-24" />
                </TableCell>
                <TableCell className="p-3">
                  <Skeleton className="h-5 w-24" />
                </TableCell>
                <TableCell className="p-3">
                  <Skeleton className="h-5 w-24" />
                </TableCell>
                <TableCell className="p-3">
                  <Skeleton className="h-5 w-24" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <Table className="min-w-full border border-gray-200 bg-white shadow-sm rounded-lg">
            <TableHeader className="bg-gray-100 text-gray-600">
              <TableRow>
                <TableHead className="p-3">Stop Id/Package</TableHead>
                <TableHead className="p-3">Origin/Destination</TableHead>
                <TableHead className="p-3">Size</TableHead>
                <TableHead className="p-3">Weight</TableHead>
                <TableHead className="p-3">Price</TableHead>
                <TableHead className="p-3">Status</TableHead>
                <TableHead className="p-3">Action</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {routes?.data?.stops?.map((route: StopType) => (
                <TableRow
                  key={route.id}
                  className="hover:bg-gray-50 transition-colors duration-200"
                >
                  <TableCell className="p-3">
                    <div className="flex items-center justify-center">
                      {route.package_size === "small" && (
                        <img
                          src={SmallBox}
                          className="h-16 w-16 object-contain cursor-pointer"
                          alt="small package"
                          onClick={() =>
                            navigate(`/customer/trips/${route.id}`)
                          }
                        />
                      )}
                      {route.package_size === "medium" && (
                        <img
                          src={MediumBox}
                          className="h-16 w-16 object-contain cursor-pointer"
                          alt="medium package"
                          onClick={() =>
                            navigate(`/customer/trips/${route.id}`)
                          }
                        />
                      )}
                      {route.package_size === "large" && (
                        <img
                          src={LargeBox}
                          className="h-16 w-16 object-contain cursor-pointer"
                          alt="large package"
                          onClick={() =>
                            navigate(`/customer/trips/${route.id}`)
                          }
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="p-3">
                    <div className="flex flex-col space-y-2">
                      <p className="text-sm font-medium text-gray-600">
                        Origin
                      </p>
                      <p className="text-sm text-gray-500">
                        {route.origin_address}
                      </p>
                      <p className="text-sm font-medium text-gray-600">
                        Destination
                      </p>
                      <p className="text-sm text-gray-500">
                        {route.destination_address}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell className="p-3 text-center bg-gray-50 font-medium capitalize">
                    {route.package_size}
                  </TableCell>
                  <TableCell className="p-3 text-center">
                    {route.package_weight} kg
                  </TableCell>
                  <TableCell className="p-3 text-center">
                    {route.price.toFixed(2)} €
                  </TableCell>
                  <TableCell className="p-3">
                    <div
                      className={cn(
                        "flex items-center justify-center py-2 rounded text-white font-medium",
                        route.status === "created" && "bg-yellow-500",
                        route.status === "confirmed" && "bg-blue-500",
                        route.status === "booked" && "bg-green-500",
                        route.status === "in-transit" && "bg-orange-500"
                      )}
                    >
                      {route.status}
                    </div>
                  </TableCell>
                  <TableCell className="p-3">
                    <div className="flex items-center space-x-3">
                      <Eye
                        onClick={() => navigate(`/customer/trips/${route.id}`)}
                        className="h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-800 transition-colors duration-200"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>

      {modalCancelFreight.isOpen && <ModalCancelFreight refetch={refetch} />}
    </SidebarLayout>
  );
};

export default DashboardCustomer;
