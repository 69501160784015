import { zodResolver } from "@hookform/resolvers/zod";
import { useJsApiLoader } from "@react-google-maps/api";
import { useQuery } from "@tanstack/react-query";
import { ChevronDown } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { BiSlider } from "react-icons/bi";
import { LuArrowRightLeft } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { z } from "zod";
import { BASE_URL } from "../api/config";
import { Button } from "../components/ui/button";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
//@ts-ignore
//@ts-ignore
//@ts-ignore

import { ALL_B2B_TRIPS, GET_ALL_INSTANT } from "../api/config";
import Header from "../components/header/Header";
import { Input } from "../components/ui/input";
//@ts-ignore
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";

//@ts-ignore

//@ts-ignore
import { GOOGLE_API_KEY } from "../api/config";

import { GETCATEGORY_SUBCATEGORY, GET_ALL_FREIGHT } from "../api/config";
import { DropdownMenuItem } from "../components/ui/dropdown-menu";

//@ts-ignore
import { useCancelInstantFreightModal } from "../hooks/use-cancel-instant-freight";
import { useInstantFreightPaid } from "../hooks/use-instant-freight-paid";
import { usePayInstantFreight } from "../hooks/use-pay-instant-freight";
import SearchFreightFreights from "./SearchFreight.freight";
import SearchFreightInstants from "./SearchFreight.Instant";
import SearchFreightOrders from "./SearchFreight.orders";

const radiusOptions = [
  {
    id: 1,
    value: "10km",
  },
  {
    id: 2,
    value: "20km",
  },
  {
    id: 3,
    value: "50km",
  },
  {
    id: 4,
    value: "100km",
  },
  {
    id: 5,
    value: "150km",
  },
  {
    id: 6,
    value: "200km",
  },
  {
    id: 7,
    value: "300km",
  },
  {
    id: 8,
    value: "400km",
  },
  {
    id: 9,
    value: "500km",
  },
];

const originDestinationFormSchema = z.object({
  originLocation: z.any().optional(),
  originRadius: z.string().min(1).optional(),
  destinationLocation: z.any().optional(),
  destinationRadius: z.string().min(1).optional(),
  readyOnPickup: z.any(),
  readyonDropoff: z.any(),
});

const SearchFreight = () => {
  const token = useTokenStoreStorage((state) => state.token);

  const { user_id, user_type } = userInfoUserStore((state) => state);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [ordersType, setOrdersType] = useState("Freights");
  const [sortBy, setSortBy] = useState("desc");
  const [sortInstantsBy, setSortInstantsBy] = useState("");
  const [originInputValue, setOriginInputValue] = useState("");
  const [destinationInputValue, setDestinationInputValue] = useState("");
  const [startDateInputValue, setStartDateInputValue] = useState("");
  const [endDateInputValue, setEndDateInputValue] = useState("");
  const [distanceInputValue, setDistanceInputValue] = useState("");
  const [durationInputValue, setDurationInputValue] = useState("");
  const [debouncedInputValue] = useDebounce(inputValue, 500);
  const [debounceOriginInputValue] = useDebounce(originInputValue, 500);
  const [deboundeDestinationInputValue] = useDebounce(
    destinationInputValue,
    500
  );
  const [debouncedStartDateInputValue] = useDebounce(startDateInputValue, 500);
  const [debouncedEndDateInputValue] = useDebounce(endDateInputValue, 500);
  const [debouncedDistanceInputValue] = useDebounce(distanceInputValue, 500);
  const [debouncedDurationInputValue] = useDebounce(durationInputValue, 500);
  const modal = useCancelInstantFreightModal();
  const modalFreightPaid = useInstantFreightPaid();
  const modalPayInstantFreight = usePayInstantFreight();
  const [page, setPage] = useState(1);
  const [ecomPage, setEcomPage] = useState(1);
  const [instantPage, setInstantPage] = useState(1);
  const [instantTotalPages, setInstantTotalPage] = useState(1);
  const [ecomTotalPages, setEcomTotalPages] = useState(1);
  const [allCategories, setAllCategories] = useState<any[]>([]);
  const [allCategoriesChecked, setAllCategoriesChecked] = useState<any[]>([]);

  const [allSubcategories, setAllSubcategories] = useState<any[]>([]);

  const [durationDistanceFilters, setDurationDistanceFilters] = useState({
    distance: "",
    duration: "",
  });

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleOriginInputChange = (event: any) => {
    setOriginInputValue(event.target.value);
  };
  const handleDestinationInputChange = (event: any) => {
    setDestinationInputValue(event.target.value);
  };
  const handleChangeStartDateChange = (event: any) => {
    setStartDateInputValue(event.target.value);
  };
  const handleChangeEndDateChange = (event: any) => {
    setEndDateInputValue(event.target.value);
  };

  const handleDistanceChange = (event: any) => {
    setDistanceInputValue(event.target.value);
  };

  const handleDurationChange = (event: any) => {
    setDurationInputValue(event.target.value);
  };

  const handleChangeDurationDistance = (
    distanceValue: string,
    durationValue: string
  ) => {
    setDurationDistanceFilters({
      distance: distanceValue,
      duration: durationValue,
    });
  };

  const [directionsResponse, setDirectionsRespnose] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });

  const [allSubcategoriesChecked, setAllSubcategoriesChecked] = useState<any[]>(
    []
  );

  const [position, setPostition] = useState<{
    latitude: number | null;
    longitude: number | null;
  }>({
    latitude: null,
    longitude: null,
  });

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPostition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  const [selectedCategories, setSelectedCategories] = useState<
    { catname: string; catid: number }[]
  >([]);

  const originRef = useRef<any>();
  const destinationRef = useRef<any>();

  const sortOptions = [
    {
      value: "asc",
      label: "Ascendent",
    },
    {
      value: "desc",
      label: "Descendent",
    },
  ];

  async function calculateRuote() {
    if (originRef.current.value === "" || destinationRef.current.value === "") {
      return;
    }

    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destinationRef.current.value,
      travelMode: google.maps.TravelMode.DRIVING,
    });
    //@ts-ignore
    setDirectionsRespnose(results);
    //@ts-ignore
    setDistance(results.routes[0].legs[0].distance.text);
    //@ts-ignore
    setDuration(results.routes[0].legs[0].duration?.text);
  }

  function clearRoute() {
    setDirectionsRespnose(null);
    setDistance("");
    setDuration("");
    originRef.current.value = "";
    destinationRef.current.value = "";
  }
  const getAllInstants = async () => {
    const response = await fetch(
      `${BASE_URL}${GET_ALL_INSTANT}?user_id=${user_id}&page=${instantPage}&sorted=${sortInstantsBy}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const {
    data: allInstants,
    isLoading: isLoadingInstants,
    refetch: refetchInstants,
  } = useQuery({
    queryKey: ["instants", instantPage, sortInstantsBy],
    queryFn: getAllInstants,
    enabled: ordersType === "Instant Freights",
  });

  const getOrders = async () => {
    const response = await fetch(
      `${BASE_URL}${ALL_B2B_TRIPS}?user_id=${user_id}&page=${ecomPage}&items_per_page=5&sorted=${sortBy}`,
      {
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response?.data;
  };
  const {
    data: ordersEcom,
    isLoading: isLoadingOrders,
    refetch: refetchOrders,
  } = useQuery({
    queryKey: ["orders_transporter", ecomPage, sortBy],
    queryFn: getOrders,
    enabled: ordersType === "Orders (Ecom)",
  });

  // const getFreightList = async () => {
  //   const response = await fetch(
  //     `${BASE_URL}${GET_ALL_FREIGHT}?sort=DESC&user_id=${user_id}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         AccessToken: token,
  //       },
  //     }
  //   ).then((res) => res.json());

  //   return response;
  // };

  // const { data: freightFlist } = useQuery({
  //   queryKey: ["freight_list"],
  //   queryFn: getFreightList,
  // });

  useEffect(() => {
    setEcomTotalPages(ordersEcom?.total_pages);
    setInstantTotalPage(allInstants?.total_pages);
  }, [ordersEcom?.total_pages, allInstants?.total_pages]);

  const originDestination = useForm<
    z.infer<typeof originDestinationFormSchema>
  >({
    resolver: zodResolver(originDestinationFormSchema),
    defaultValues: {
      destinationLocation: "",
      destinationRadius: "",
      originLocation: "",
      originRadius: "",
      readyonDropoff: "",
      readyOnPickup: "",
    },
  });

  // const onSubmit = async (
  //   values: z.infer<typeof originDestinationFormSchema>
  // ) => {
  //   calculateRuote();
  // };

  const getAllFreight = async () => {
    const response = await fetch(
      `${BASE_URL}${GET_ALL_FREIGHT}?user_id=${user_id}&page=${page}&status=active&items_per_page=10&sort=DESC&origin=${debounceOriginInputValue}&destination=${deboundeDestinationInputValue}&start_date=${debouncedStartDateInputValue}&end_date=${debouncedEndDateInputValue}&distance=${debouncedDistanceInputValue}&duration=${debouncedDurationInputValue}`,
      {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const getCategories = async () => {
    const response = await fetch(`${BASE_URL}${GETCATEGORY_SUBCATEGORY}`, {
      method: "GET",
      headers: {
        AccessToken: token,
      },
    }).then((res) => res.json());
    return response;
  };

  const { data: categories } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategories,
  });
  useEffect(() => {
    setAllCategories(
      categories?.map(
        (category: {
          id: number;
          name: string;
          img: string;
          subcategories: { id: number; name: string }[];
        }) => ({
          catname: category.name,
          id: category.id,
          checked: false,
          sub: category.subcategories?.map((sub) => ({
            catname: sub.name,
            id: sub.id,
            checked: false,
          })),
        })
      )
    );
  }, [categories]);

  const { data: allFreight, isLoading } = useQuery({
    queryKey: [
      "all_freight",
      page,
      debouncedInputValue,
      debounceOriginInputValue,
      deboundeDestinationInputValue,
      debouncedStartDateInputValue,
      debouncedEndDateInputValue,
      debouncedDistanceInputValue,
      debouncedDurationInputValue,
      allCategoriesChecked,
      allSubcategoriesChecked,
    ],
    queryFn: getAllFreight,
  });

  const onFilteredChange = (filteredCategories: any) => {
    setAllCategories(filteredCategories);
    setAllCategoriesChecked(
      filteredCategories.filter((category: any) => category.checked === true)
    );
  };

  const onFilteredSubChange = (filteredCategories: any) => {
    setAllSubcategoriesChecked(filteredCategories);
  };

  return (
    <div className="bg-gray-50 min-h-screen overflow-x-hidden">
      <Header />
      <div className="w-full bg-freightBlueDark">
        <div className="   max-w-7xl  mx-auto p-4 py-10 ">
          <h1 className="text-2xl font-medium text-white">Browse Loads</h1>
          <div className="flex items-center ">
            <div className="sm:flex sm:items-center sm:space-x-10 mt-6">
              <div className="flex items-end space-x-4">
                <div className="text-white">
                  {/* <div className="flex items-start space-x-10 mt-4"> */}
                  <div className="flex flex-col  items-start ">
                    <h1 className="text-sm font-medium mb-2">Origin: </h1>
                    <Input
                      type="text"
                      className="text-sm text-black"
                      value={originInputValue}
                      onChange={handleOriginInputChange}
                      placeholder="Origin"
                    />
                  </div>
                </div>
                <LuArrowRightLeft className="h-4 w-4 text-freightBlue  mb-3" />

                <div className="flex items-center">
                  <div className="flex flex-col">
                    <h1 className="text-sm font-medium mb-2 text-white">
                      Destination
                    </h1>
                    <div className="flex items-center space-x-2">
                      <div className="flex items-center space-x-10">
                        <Input
                          type="text"
                          value={destinationInputValue}
                          onChange={handleDestinationInputChange}
                          placeholder="Destination"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="text-sm font-medium mb-2 text-white">
                  Freight type
                </p>

                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <div className=" h-10  w-40 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 flex items-center justify-between">
                      <p>{ordersType}</p>
                      <ChevronDown className="h-5 w-5" />
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem onClick={() => setOrdersType("Freights")}>
                      Freights
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => setOrdersType("Instant Freights")}
                    >
                      Instant Freights
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => setOrdersType("Orders (Ecom)")}
                    >
                      Orders (Ecom)
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>

              <div>
                <p className="text-sm  font-medium mb-2 text-white">Ready on</p>
                <div className="flex items-center">
                  <Input
                    type="date"
                    className="focus-visible:ring-blue-500 mr-2 focus-visible:ring-2 w-36 transition duration-200 ease-in-out outline-none border-[lightgray]  border h-[38px] rounded px-2 font-paragraph text-sm"
                    value={startDateInputValue}
                    onChange={handleChangeStartDateChange}
                  />

                  <Input
                    type="date"
                    value={endDateInputValue}
                    onChange={handleChangeEndDateChange}
                    className="focus-visible:ring-blue-500 focus-visible:ring-2 w-36 transition duration-200 ease-in-out outline-none border-[lightgray]  border h-[38px] rounded px-2 font-paragraph text-sm"
                  />
                </div>
              </div>
              <div>
                <p className="text-sm font-medium mb-2 text-white">
                  More Filters
                </p>
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <div className=" h-10  w-40 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 flex items-center justify-between">
                      <BiSlider className="h-4 w-4" />

                      <p>More Filters</p>
                      <ChevronDown className="h-5 w-5" />
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <div className="flex flex-col w-60 p-2">
                      <div className="flex flex-col items-start py-4">
                        <h1 className="text-sm font-medium mb-2">Distance</h1>
                        <Input
                          type="text"
                          placeholder="Distance (kms)"
                          value={distanceInputValue}
                          onChange={handleDistanceChange}
                        />
                      </div>
                      <div className="flex flex-col items-start py-4">
                        <h1 className="text-sm font-medium mb-2">Duration</h1>
                        <Input
                          type="text"
                          placeholder="Duration (*h **min)"
                          value={durationInputValue}
                          onChange={handleDurationChange}
                        />
                      </div>
                      <Button
                        onClick={() =>
                          handleChangeDurationDistance(
                            debouncedDistanceInputValue,
                            debouncedDurationInputValue
                          )
                        }
                        variant={"freightBlue"}
                      >
                        Apply Filters
                      </Button>
                    </div>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <div className="text-white  text-sm font-medium ">Reset</div>
              {/* </form>
            </Form> */}
            </div>
          </div>
        </div>
      </div>

      {ordersType === "Freights" && (
        <SearchFreightFreights
          allFreight={allFreight}
          ordersType={ordersType}
          onFilteredSubChange={onFilteredSubChange}
          allCategories={allCategories}
          onFilteredChange={onFilteredChange}
          inputValue={inputValue}
          handleInputChange={handleInputChange}
          page={page}
          setPage={setPage}
          isLoading={isLoading}
        />
      )}

      {ordersType === "Orders (Ecom)" && (
        <SearchFreightOrders
          ecomPage={ecomPage}
          setEcomPage={setEcomPage}
          ecomTotalPages={ecomTotalPages}
          isLoading={isLoading}
          isLoadingOrders={isLoadingOrders}
          ordersEcom={ordersEcom}
          setSortBy={setSortBy}
          refetchOrders={refetchOrders}
        />
      )}

      {ordersType === "Instant Freights" && (
        <SearchFreightInstants
          instantPage={instantPage}
          setInstantPage={setInstantPage}
          setSortInstantsBy={setSortInstantsBy}
          sortInstantsBy={sortInstantsBy}
          isLoadingInstants={isLoadingInstants}
          allInstants={allInstants}
          refetchInstants={refetchInstants}
        />
      )}
    </div>
  );
};

export default SearchFreight;
