import { useQuery } from "@tanstack/react-query";
import {
  BASE_URL,
  GETCARRIERACCOUNT,
  GETCARRIERPROFILE,
  VIEW_CARRIER_PROFILE,
} from "../api/config";

import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { useEffect, useState } from "react";
import { CarrierProfile, ViewCarrierProfileType } from "../types/types";

import { Rating } from "../components/Rating";
import SidebarLayout from "../components/layout/SidebarLayout";

const ViewCarrierProfile = () => {
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);

  const [carrierInfo, setCarrierInfo] = useState<CarrierProfile>({
    abn_certificate: "",
    accreditations: "",
    categories: [],
    company_description: "",
    company_name: "",
    drivers_license: "",
    images: [],
    insurance: "",
    num_drivers: "",
    num_staff: "",
    num_trucks: "",
    optstate: [],
    payment_methods: "",
    payment_terms: "",
    termsConditions: "",
    user_id: 0,
    year_established: "",
  });

  const getCarrierAccount = async () => {
    const response = await fetch(`${BASE_URL}${GETCARRIERACCOUNT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        carrier_id: 1,
      }),
    }).then((res) => res.json());

    return response;
  };

  const { data: carrierAccount } = useQuery({
    queryKey: ["carrier_account"],
    queryFn: getCarrierAccount,
  });

  const getCarrierProfile = async () => {
    const response = await fetch(`${BASE_URL}${GETCARRIERPROFILE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    }).then((res) => res.json());
    return response;
  };

  const { data: carrierProfile } = useQuery({
    queryKey: ["carrier_profile"],
    queryFn: getCarrierProfile,
  });

  const getViewCarrierProfile = async () => {
    const response = await fetch(`${BASE_URL}${VIEW_CARRIER_PROFILE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        carrier_id: user_id,
      }),
    }).then((res) => res.json());
    return response?.data;
  };

  const { data: viewCarrierProfile } = useQuery<ViewCarrierProfileType>({
    queryKey: ["view_carrierprofile"],
    queryFn: getViewCarrierProfile,
  });

  useEffect(() => {
    setCarrierInfo(carrierProfile?.data);
  }, [carrierProfile]);

  return (
    <SidebarLayout>
      <div className="max-w-4xl mr-auto bg-white shadow p-4">
        <h1 className="text-sm font-semibold border-b-2 pb-3 mb-4">
          Transport Provider Profile:
        </h1>
      </div>
      <div className="max-w-4xl mr-auto bg-white shadow p-4">
        <h1 className="text-xl font-semibold my-4">Opiniones</h1>
        <div className="md:grid grid-cols-7 items-center space-x-2  gap-4">
          <div className="bg-gray-50 flex flex-col h-full items-center rounded mb-4 col-span-2 py-4 space-y-3">
            <h1 className="text-4xl font-bold">
              {viewCarrierProfile?.average_rating}
            </h1>

            <h3 className="underlined text-sm ">
              {viewCarrierProfile?.reviewlist?.length} Opiniones
            </h3>
          </div>
          <div className="bg-gray-50 h-full mb-4 flex flex-col items-center rounded col-span-2 py-4 space-y-3">
            <h1 className="text-4xl font-bold">74%</h1>
            <h3 className="font-medium">Recomiendan</h3>
            <h4 className="text-sm">13 Recomendaciones</h4>
          </div>
          <div className="mb-4 col-span-3">
            {viewCarrierProfile?.reviewlist?.map((review) => (
              <div>
                <h1 className="text-lg font-medium w-80 mb-4">
                  {review.customer_name}
                </h1>
                <div className="flex items-center justify-between w-80">
                  <h1 className="text-gray-500 text-sm font-medium">Care</h1>
                  {/* <h3>{review.rating_care}</h3> */}
                  <Rating
                    count={review.rating_care}
                    value={review.rating_care}
                    edit={false}
                  />
                </div>
                <div className="flex items-center justify-between w-80">
                  <h1 className="text-gray-500 text-sm font-medium">
                    Communication
                  </h1>
                  <Rating
                    count={review.rating_communication}
                    value={review.rating_communication}
                    edit={false}
                  />
                </div>
                <div className="flex items-center justify-between w-80">
                  <h1 className="text-gray-500 text-sm font-medium">Service</h1>

                  <Rating
                    count={review.rating_service}
                    value={review.rating_service}
                    edit={false}
                  />
                </div>
                <div className="flex items-center justify-between w-80">
                  <h1 className="text-sm font-medium text-gray-500">
                    Punctuality
                  </h1>
                  <Rating
                    count={review.rating_punctuality}
                    value={review.rating_punctuality}
                  />
                </div>
                <div className="flex items-center justify-between w-80">
                  <h1 className="text-sm font-medium text-gray-500">
                    Customer Service
                  </h1>
                  <Rating
                    count={review.rating_customer_service}
                    value={review.rating_customer_service}
                  />
                </div>
                <h3 className="text-sm font-medium mt-4">
                  {review.review_response}
                </h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    </SidebarLayout>
  );
};

export default ViewCarrierProfile;
