import { useBookTripStop } from "src/hooks/use-book-trip-stop";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { Button } from "../ui/button";
import { useState } from "react";
import { Check, Loader2 } from "lucide-react";
import { cn } from "src/lib/utils";
import { useNavigate } from "react-router-dom";

interface ModalBookTripStopProps {
  refetchRoutes: () => void;
}

const ModalBookTripStop: React.FC<ModalBookTripStopProps> = ({
  refetchRoutes,
}) => {
  const modalBookTripStop = useBookTripStop();
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const [loadingAssignRoute, setLoadingAssignRoute] = useState(false);
  const [assignSuccessfully, setAssignSuccessfully] = useState(false);
  const navigate = useNavigate();

  const assignRoute = () => {
    navigate(`/routes/trips/assign/${modalBookTripStop.stop?.id}`);
  };

  return (
    <Dialog
      open={modalBookTripStop.isOpen}
      onOpenChange={modalBookTripStop.onClose}
    >
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-start w-full">
            <h1 className="text-lg font-medium border-b-2 border-border pb-2 w-full">
              Deliver on this route
            </h1>
            <div className="py-4 w-full">
              <div className="grid grid-cols-2 gap-4 mt-4 py-2 w-full">
                <div className="flex flex-col items-start">
                  <h1 className="text-sm font-medium">Origin</h1>
                  <h3 className="text-gray-500 text-sm font-medium">
                    {modalBookTripStop.stop?.origin_city}
                  </h3>
                </div>
                <div className="flex flex-col items-start">
                  <h1 className="text-sm font-medium">Destination</h1>
                  <h3 className="text-gray-500 text-sm font-medium">
                    {modalBookTripStop.stop?.destination_city}
                  </h3>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4  border-t border-border  py-4  w-full">
                <div className="flex flex-col items-start  ">
                  <h1 className="text-sm font-medium">Distance</h1>
                  <h3 className="text-gray-500 text-sm font-medium">
                    {modalBookTripStop.stop?.delivery_distance}
                  </h3>
                </div>
                <div className="flex flex-col items-start ">
                  <h1 className="text-sm font-medium">Duration</h1>
                  <h3 className="text-gray-500 text-sm font-medium">
                    {modalBookTripStop.stop?.delivery_duration}
                  </h3>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4  border-t border-border  py-4  w-full">
                <div className="flex flex-col items-start">
                  <h1 className="text-sm font-medium">Package Size</h1>
                  <h3 className="text-sm font-medium text-gray-500 capitalize">
                    {modalBookTripStop.stop?.package_size}
                  </h3>
                </div>
                <div className="flex flex-col items-start">
                  <h1 className="text-sm font-medium">Package Weight</h1>
                  <h3 className="text-sm font-medium text-gray-500">
                    {modalBookTripStop.stop?.package_weight}kg
                  </h3>
                </div>
              </div>
              <div className="flex items-start justify-between w-full py-4 border-t border-border">
                <h1 className="text-sm font-medium">Price</h1>
                <h3 className="text-gray-500 text-sm font-medium bg-gray-200 rounded px-4 py-1">
                  {modalBookTripStop.stop?.price}€
                </h3>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end space-x-2 w-full mt-4">
            <Button
              variant={"outline"}
              onClick={() => modalBookTripStop.onClose()}
            >
              Back
            </Button>
            <Button
              variant={"freightBlue"}
              onClick={() => {
                assignRoute();
              }}
              className={cn(
                "w-40",
                assignSuccessfully && "bg-green-500 text-white"
              )}
            >
              {loadingAssignRoute ? (
                <>
                  <Loader2 className="animate-spin h-5 w-5" />
                </>
              ) : (
                <div>
                  {assignSuccessfully ? (
                    <Check className="h-5 w-5" />
                  ) : (
                    <p className="text-sm font-medium">Assign Route</p>
                  )}
                </div>
              )}
            </Button>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalBookTripStop;
