import { useQuery } from "@tanstack/react-query";
import { BASE_URL, GET_CHATS_USER, GET_CONVERSATIONS } from "../api/config";
import SidebarLayout from "../components/layout/SidebarLayout";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { useEffect, useState } from "react";
import { EllipsisVertical, Loader2, Search } from "lucide-react";

//@ts-ignore
import { ReactComponent as EmptySvg } from "../assets/images/data-not-found.svg";
import { ChatData } from "../types/types";
import { cn } from "../lib/utils";
import ChatMessageCard from "../components/chat/ChatMessageCard";
import Conversation from "./Conversation";

const CustomerChat = () => {
  const { user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const [selectedChat, setSelectedChat] = useState<any>();
  const [groupedByQuote, setGroupedByQuote] = useState([]);
  const [chatType, setChatType] = useState("programmed");

  const getChatsByUser = async () => {
    const response = await fetch(`${BASE_URL}${GET_CHATS_USER}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        chat_type: chatType,
        // carrier_id: user_id,
        // freight_id: "1",
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const {
    data: chatsByUser,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["chats_user", chatType],
    queryFn: getChatsByUser,
  });

  const groupBy = (keys: any) => (array: any) =>
    array?.reduce((objectByValue: any, obj: any) => {
      const value = keys.map((key: any) => obj[key]).join("-");
      objectByValue[value] = (objectByValue[value] || []).concat(obj);

      return objectByValue;
    }, {});

  useEffect(() => {
    if (chatsByUser?.data?.chat_data) {
      const groupByQuoteId = groupBy(["quote_id"]);

      setGroupedByQuote(groupByQuoteId(chatsByUser?.data.chat_data));
    }
  }, [chatsByUser]);

  return (
    <SidebarLayout>
      <div className="">
        <div className="flex items-center justify-between w-full border-b border-border pb-3 mb-4">
          <h1 className="text-lg font-semibold">Chat</h1>
        </div>
        <div className="flex items-start space-x-2 mb-4">
          <div
            onClick={() => {
              setChatType("programmed");
            }}
            className={cn(
              "bg-[#e2e2e2] px-4 py-2 rounded cursor-pointer text-sm font-medium",
              chatType === "programmed" && "bg-[#333] text-white"
            )}
          >
            Programmed
          </div>
          <div
            onClick={() => {
              setChatType("instant");
            }}
            className={cn(
              "bg-[#e2e2e2] px-4 py-2 rounded cursor-pointer text-sm font-medium",
              chatType === "instant" && "bg-[#333] text-white"
            )}
          >
            Instant
          </div>
          <div
            onClick={() => {
              setChatType("stop");
            }}
            className={cn(
              "bg-[#e2e2e2] px-4 py-2 rounded cursor-pointer text-sm font-medium",
              chatType === "stop" && "bg-[#333] text-white"
            )}
          >
            Stop
          </div>
        </div>
        <div className="sm:grid grid-cols-4">
          <div className="bg-gray-50  col-span-1 border rounded-tl">
            <div className="flex items-center border-b p-4 h-10 bg-freightBlueDark/70 rounded-tl">
              <h1 className="text-sm font-medium text-white">My Chats</h1>
            </div>

            <div className="h-[60vh] overflow-y-auto">
              {isLoading ? (
                <div className="w-full h-full bg-white flex items-center justify-center">
                  <Loader2 className="h-5 w-5 animate-spin" />
                </div>
              ) : (
                <>
                  {chatsByUser?.data.chat_data.length === 0 ? (
                    <div className="bg-white p-4 h-full w-full">
                      <p className="text-sm font-medium text-gray-500">
                        No chats...
                      </p>
                    </div>
                  ) : (
                    <>
                      {Object.entries(groupedByQuote).map(
                        (groupedQuote: any) => (
                          <>
                            <ChatMessageCard
                              chatMessage={groupedQuote[1][0]}
                              selectedChat={selectedChat}
                              setSelectedChat={setSelectedChat}
                            />
                          </>
                        )
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="bg-white  col-span-3 border rounded-tr">
            <div className="flex items-center justify-between border-b p-4 h-10 bg-gray-50">
              <Search className="h-4 w-4" />
              <EllipsisVertical className="h-4 w-4" />
            </div>
            <div className="relative w-full">
              {chatsByUser?.data.chat_data.length === 0 && (
                <div className="h-[60vh]">
                  <div className="h-full w-full flex flex-col items-center justify-center">
                    <EmptySvg />
                    <p className="text-sm font-semibold">No chats found.</p>
                  </div>
                </div>
              )}

              {selectedChat && (
                <Conversation selectedChat={selectedChat} refetch={refetch} />
              )}
            </div>
          </div>
        </div>
      </div>
    </SidebarLayout>
  );
};

export default CustomerChat;
