import { create } from "zustand";

export type ModalType = "declineQuote";

interface ModalStore {
  type: ModalType | null;
  quoteId: number | null;
  isOpen: boolean;
  onOpen: (type: ModalType, quoteId: number | null) => void;
  onClose: () => void;
}

export const useDeclineQuoteModal = create<ModalStore>((set) => ({
  type: null,
  quoteId: null,
  isOpen: false,
  onOpen: (type, quoteId) => set({ isOpen: true, type, quoteId }),
  onClose: () => set({ type: null, isOpen: false }),
}));
