import { useQuery } from "@tanstack/react-query";
import { BASE_URL, LIST_TICKETS } from "../api/config";
import Header from "../components/header/Header";
import CustomerSidebar from "../components/sidebar/CustomerSidebar";
import Sidebar from "../components/sidebar/Sidebar";
import { Button } from "../components/ui/button";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { useEffect } from "react";
import { useAddTicket } from "../hooks/use-add-ticket";
import ModalAddTicket from "../components/modal/ModalAddTicket";
import Footer from "../components/layout/Footer";
import SidebarLayout from "../components/layout/SidebarLayout";

const RequestTicket = () => {
  const modalAddTicket = useAddTicket();
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id, user_type } = userInfoUserStore((state) => state);

  const getTickets = async () => {
    const response = await fetch(`${BASE_URL}${LIST_TICKETS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    }).then((res) => res.json());
    return response;
  };

  const { data: tickets } = useQuery({
    queryKey: ["tickets"],
    queryFn: getTickets,
  });

  return (
    <SidebarLayout>
      {/* <Header />
      <div className="grid grid-cols-7 mt-20 px-10">
        <div className="col-span-2">
          {user_type === "customer" ? <CustomerSidebar /> : <Sidebar />}
        </div>
        <div className="col-span-5"> */}
      <div className="flex items-center justify-between w-full border-b border-border pb-3 mb-4">
        <h1 className="text-lg  font-semibold ">My Ticket</h1>
        <Button
          onClick={() => modalAddTicket.onOpen("addTicket")}
          variant={"freightBlue"}
          className="my-4"
        >
          Add Ticket
        </Button>
      </div>
      <div className=" bg-white shadow p-4">
        <Table className="border border-border  bg-white shadow">
          <TableHeader>
            <TableRow>
              <TableHead>Freight</TableHead>
              <TableHead>Query</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Raise by</TableHead>
              <TableHead>Date</TableHead>
            </TableRow>
          </TableHeader>
          {tickets?.data[0].length === 0 ? (
            <TableBody>
              <p className="text-sm font-medium text-gray-500 p-4">
                No tickets found
              </p>
            </TableBody>
          ) : (
            <TableBody></TableBody>
          )}
        </Table>
      </div>
      {/* </Sidebarla> */}
      {/* // </div> */}
      {modalAddTicket.isOpen && <ModalAddTicket />}
    </SidebarLayout>
  );
};

export default RequestTicket;
