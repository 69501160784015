import { BASE_URL, GET_COUNT } from "src/api/config";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { FaArrowDown, FaTruck } from "react-icons/fa";
import { BookingMessage, FreightMessage, InboxMessage } from "src/types/types";
import { useNavigate } from "react-router-dom";
import {
  Banknote,
  CarFront,
  History,
  Plus,
  WalletCards,
  Zap,
} from "lucide-react";
import { cn } from "src/lib/utils";
import useLocalStorage from "src/hooks/use-local-storage";
import {
  BiPackage,
  BiSolidHome,
  BiSolidMessage,
  BiSolidTruck,
  BiSolidUser,
} from "react-icons/bi";
import { MdBolt, MdPayments } from "react-icons/md";
import { IoChatbox, IoTicket } from "react-icons/io5";
import { MdFeedback } from "react-icons/md";
import { FiPackage } from "react-icons/fi";

const CustomerSidebar = () => {
  const { email, name, user_id, user_type } = userInfoUserStore(
    (state) => state
  );
  const [inboxMessages, setInboxMessages] = useState<InboxMessage>();
  const [freightMessages, setFreightMessages] = useState<FreightMessage>();
  const [bookingMessages, setBookingMessages] = useState<BookingMessage>();
  const token = useTokenStoreStorage((s) => s.token);

  const [showFreightListings, setShowFreightListings] = useLocalStorage(
    "show_freight_listings"
  );
  const [showInbox, setShowInbox] = useLocalStorage("show_inbox");
  const [showBookings, setShowBookings] = useLocalStorage("show_bookings");
  const [showTickets, setShowTickets] = useLocalStorage("show_tickets");
  const [showAccount, setShowAccount] = useLocalStorage("show_accounts");
  const [showFeedback, setShowFeedback] = useLocalStorage("show_feedback");
  const [showPayments, setShowPayments] = useLocalStorage("show_payments");
  const [showTrips, setShowTrips] = useLocalStorage("show_trips");

  const navigate = useNavigate();
  const getCount = async () => {
    const response = await fetch(`${BASE_URL}${GET_COUNT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },

      body: JSON.stringify({
        user_id: user_id,
        user_type: user_type,
      }),
    }).then((res) => res.json());

    return response;
  };

  const { data: count, refetch } = useQuery<any>({
    queryKey: ["count"],
    queryFn: getCount,
    enabled: user_id !== "",
  });

  useEffect(() => {
    setInboxMessages(count?.data?.my_inbox_cnt);
    setFreightMessages(count?.data?.my_freight_cnt);
    setBookingMessages(count?.data?.my_bookings_cnt);
  }, [count]);

  return (
    <div className="w-[300px] h-full min-h-[95vh] bg-gray-100 flex flex-col justify-between">
      <div>
        <div>
          <div className="flex flex-col space-y-2 mb-2 mt-2 ">
            <div
              className="flex items-center justify-between  border-b py-2 cursor-pointer px-4 bg-[#4a4a4a] text-gray-50 mx-2 rounded"
              onClick={() => navigate("/getFreight")}
            >
              <div className="flex items-center space-x-1">
                <FaTruck className="h-4 w-4 mr-2" />
                <h1 className="text-sm  font-medium">Create Freight</h1>
              </div>
              <Plus className="h-4 w-4" />
            </div>
            <div
              className="flex items-center justify-between  border-b py-2 cursor-pointer px-4 bg-[#4a4a4a] text-gray-50 mx-2 rounded"
              onClick={() => navigate("/send-now")}
            >
              <div className="flex items-center space-x-1">
                <MdBolt className="h-4 w-4 mr-2" />
                <h1 className="text-sm  font-medium">Create Instant</h1>
              </div>

              <Plus className="h-4 w-4" />
            </div>
          </div>
        </div>{" "}
        <div
          onClick={() => navigate("/dashboard-customer")}
          className={cn(
            "flex items-center   px-4 py-2 space-x-2 cursor-pointer   ",

            window.location.pathname === "/dashboard-customer" && "bg-white  "
          )}
        >
          <BiSolidHome className="h-4 w-4 text-[#333]" />
          <h1 className="text-[#333] text-sm font-medium">Customer Account</h1>
        </div>
        <div className="  ">
          <div
            className="flex p-1 items-center w-full px-4 py-2 justify-between cursor-pointer"
            onClick={() => {
              setShowInbox(!showInbox);
              setShowPayments(false);
              setShowAccount(false);
              setShowBookings(false);
              setShowFeedback(false);
              setShowFreightListings(false);
            }}
          >
            <div className="flex items-center space-x-2">
              <BiSolidMessage className="h-4 w-4 text-[#333]" />
              <h3 className="text-[#333] text-sm font-medium">My Messages</h3>
              {Number(inboxMessages?.unreadCnt) !== 0 && (
                <div className="bg-[#333] h-6 w-6 shadow rounded-full flex items-center justify-center">
                  <p className="font-semibold text-xs text-white">
                    {inboxMessages?.unreadCnt}
                  </p>
                </div>
              )}
            </div>
            <div>
              <FaArrowDown
                className={`h-3  transition ${
                  showInbox
                    ? "animate-toArrowUp rotate-180  "
                    : "animate-toArrowDown"
                } `}
              />
            </div>
          </div>
          {showInbox && (
            <div className="flex flex-col px-4 bg-white transition border rounded m-2 ">
              <div
                onClick={() => navigate("/myinbox/unread")}
                className="flex items-center space-x-1 border-b py-2 cursor-pointer"
              >
                <h1 className="text-sm font-medium text-gray-500">
                  Unread Messages
                </h1>
                {Number(inboxMessages?.unreadCnt) > 0 && (
                  <div className="bg-red-500 h-6 w-6 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-xs text-white">
                      {inboxMessages?.unreadCnt}
                    </p>
                  </div>
                )}
              </div>
              <div
                onClick={() => navigate("/myinbox/read")}
                className="flex items-center space-x-1 border-b py-2 cursor-pointer"
              >
                <h1 className="text-sm font-medium text-gray-500">
                  Read Messages
                </h1>
                {Number(inboxMessages?.readCnt) > 0 && (
                  <div className="bg-red-500 h-6 w-6 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-xs text-white">
                      {inboxMessages?.readCnt}
                    </p>
                  </div>
                )}
              </div>
              <div
                onClick={() => navigate("/myinbox/archived")}
                className="flex items-center space-x-1 border-b py-2 cursor-pointer"
              >
                <h1 className="text-sm font-medium text-gray-500">
                  Archived Messages
                </h1>
                {Number(inboxMessages?.archiveCnt) > 0 && (
                  <div className="bg-red-500 h-6 w-6 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white">
                      {inboxMessages?.archiveCnt}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            onClick={() => {
              setShowPayments(!showPayments);
              setShowAccount(false);
              setShowBookings(false);
              setShowFeedback(false);
              setShowFreightListings(false);
              setShowInbox(false);
            }}
            // onClick={() => navigate("/customer/payments")}
            className="flex p-1 items-center w-full px-4 py-2 justify-between cursor-pointer"
          >
            <div className="flex items-center space-x-2">
              <MdPayments className="h-4 w-4 text-[#333]" />
              <h1 className="text-sm font-medium text-[#333]">Billing</h1>
            </div>
            <div>
              <FaArrowDown
                className={`h-3 transition ${
                  showPayments ? "animate-toArrowUp rotate-180" : "animate-tow"
                }`}
              />
            </div>
          </div>
          {showPayments && (
            <div className="flex flex-col px-4 bg-white py-2 transition m-2 border rounded">
              <div
                onClick={() => navigate("/customer/history-payments")}
                className={cn(
                  "flex items-center space-x-1 border-b py-2 px-2 cursor-pointer",
                  window.location.pathname === "/customer/history-payments" &&
                    "bg-freightBlueDark/10 rounded-r"
                )}
              >
                <Banknote
                  className={cn(
                    "h-5 w-5 text-gray-500",
                    window.location.pathname === "/customer/history-payments" &&
                      "text-freightBlueDark"
                  )}
                />
                <h1
                  className={cn(
                    "text-sm font-medium text-gray-500",
                    window.location.pathname === "/customer/history-payments" &&
                      "text-freightBlueDark"
                  )}
                >
                  History Payments
                </h1>
              </div>
              <div
                onClick={() => navigate("/customer/method-payments")}
                className={cn(
                  "flex items-center space-x-1 border-b py-2 px-2 cursor-pointer",
                  window.location.pathname === "/customer/method-payments" &&
                    "bg-freightBlueDark/10 rounded-r"
                )}
              >
                <WalletCards
                  className={cn(
                    "h-5 w-5 text-gray-500",
                    window.location.pathname === "/customer/method-payments" &&
                      "text-freightBlueDark"
                  )}
                />
                <h1
                  className={cn(
                    "text-sm font-medium text-gray-500",
                    window.location.pathname === "/customer/method-payments" &&
                      "text-freightBlueDark"
                  )}
                >
                  Method Payments
                </h1>
              </div>
            </div>
          )}

          <div
            onClick={() => {
              setShowFreightListings(!showFreightListings);
              setShowAccount(false);
              setShowBookings(false);
              setShowFeedback(false);
              setShowInbox(false);
              setShowPayments(false);
              setShowTrips(false);
            }}
            className="flex p-1 items-center w-full px-4 py-2 justify-between cursor-pointer"
          >
            <div className="flex items-center space-x-2">
              <BiSolidTruck className="h-4 w-4 text-[#333]" />
              <h1 className="text-sm font-medium text-[#333]">
                My Freight Listings
              </h1>
            </div>
            <div>
              <FaArrowDown
                className={`h-3  transition ${
                  showFreightListings
                    ? "animate-toArrowUp rotate-180  "
                    : "animate-toArrowDown"
                } `}
              />
            </div>
          </div>
          {/* </div> */}
          {showFreightListings && (
            <div className="flex flex-col px-4 bg-white py-2 transition m-2 border rounded">
              {/* <div className="flex items-center space-x-1 border-b py-2">
              <h1>Incomplete Listings</h1>

              <p className="">(0)</p>
            </div> */}
              <div
                onClick={() => navigate("/my-instant-freights")}
                className={cn(
                  "flex items-center justify-between space-x-1 border-b py-2 px-2 cursor-pointer",
                  window.location.pathname === "/my-instant-freights" &&
                    "bg-freightBlueDark/10 rounded-r"
                )}
              >
                <h1 className="text-sm font-medium text-gray-500">
                  Instant Service
                </h1>
                <Zap className="h-4 w-4 text-freightBlueDark" />
              </div>
              <div
                onClick={() => navigate("/myFreight/active")}
                className={cn(
                  "flex items-center justify-between space-x-1 border-b py-2 px-2 cursor-pointer",
                  window.location.pathname === "/myFreight/active" &&
                    "bg-freightBlueDark/10 rounded-r"
                )}
              >
                <h1 className="text-sm font-medium text-gray-500">Active</h1>
                {Number(freightMessages?.activeCnt) > 0 && (
                  <div className="bg-red-500 h-6 w-6 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white">
                      {freightMessages?.activeCnt}
                    </p>
                  </div>
                )}
              </div>
              <div
                onClick={() => navigate("/myFreight/booked")}
                className={cn(
                  "flex items-center justify-between space-x-1 border-b py-2 px-2 cursor-pointer",
                  window.location.pathname === "/myFreight/booked" &&
                    "bg-freightBlueDark/10 rounded-r"
                )}
              >
                <h1 className="text-sm font-medium text-gray-500">Booked</h1>

                {Number(freightMessages?.bookedCnt) > 0 && (
                  <div className="bg-red-500 h-6 w-6 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white">
                      {freightMessages?.bookedCnt}
                    </p>
                  </div>
                )}
              </div>
              <div
                onClick={() => navigate("/myFreight/expired")}
                className={cn(
                  "flex items-center justify-between space-x-1 border-b py-2 px-2 cursor-pointer",
                  window.location.pathname === "/myFreight/expired" &&
                    "bg-freightBlueDark/10 rounded-r"
                )}
              >
                <h1 className="text-sm font-medium text-gray-500">Expired</h1>

                {Number(freightMessages?.expiredCnt) > 0 && (
                  <div className="bg-red-500 h-6 w-6 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white">
                      {freightMessages?.expiredCnt}
                    </p>
                  </div>
                )}
              </div>
              <div
                onClick={() => navigate("/myFreight/cancelled")}
                className={cn(
                  "flex items-center justify-between space-x-1 border-b py-2 px-2 cursor-pointer",
                  window.location.pathname === "/myFreight/cancelled" &&
                    "bg-freightBlueDark/10 rounded-r"
                )}
              >
                <h1 className="text-sm font-medium text-gray-500">Cancelled</h1>
                {Number(freightMessages?.cancelledCnt) > 0 && (
                  <div className="bg-red-500 h-6 w-6 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white">
                      {freightMessages?.cancelledCnt}
                    </p>
                  </div>
                )}
              </div>
              <div
                onClick={() => navigate("/getFreight")}
                className="flex items-center space-x-1 border-b py-2 cursor-pointer"
              >
                <div className="h-6 w-6 flex items-center justify-center rounded-full border border-border ">
                  <Plus className="h-4 w-4 " />
                </div>
                <h1 className="text-sm font-medium">List Freight</h1>
              </div>
            </div>
          )}
          <div
            className="flex p-1 items-center w-full px-4 py-2 justify-between cursor-pointer"
            onClick={() => {
              setShowBookings(!showBookings);
              setShowTickets(false);
              setShowAccount(false);
              setShowFeedback(false);
              setShowPayments(false);
              setShowFreightListings(false);
              setShowTrips(false);
              setShowInbox(false);
            }}
          >
            <div className="flex items-center space-x-2">
              <BiPackage className="h-4 w-4 text-[#333]" />
              <h1 className="text-sm text-[#333]">My Bookings</h1>
            </div>
            <div>
              <FaArrowDown
                className={`h-3  transition ${
                  showBookings
                    ? "animate-toArrowUp rotate-180  "
                    : "animate-toArrowDown"
                } `}
              />
            </div>
          </div>
          {showBookings && (
            <div className="flex flex-col px-4 bg-white transition m-2 py-2 border rounded">
              <div
                onClick={() => navigate(`/booking/customer/booked`)}
                className={cn(
                  "flex items-center space-x-1 border-b py-2 px-2 cursor-pointer",
                  window.location.pathname === "/booking/customer/booked" &&
                    " bg-freightBlueDark/10 rounded-r"
                )}
              >
                <h1
                  className={cn(
                    "text-sm font-medium text-gray-500",
                    window.location.pathname === "/booking/customer/booked" &&
                      "text-freightBlueDark"
                  )}
                >
                  Booked
                </h1>
                {Number(bookingMessages?.bookedCnt) > 0 && (
                  <div className="bg-red-500 h-6 w-6 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white">
                      {bookingMessages?.bookedCnt}
                    </p>
                  </div>
                )}
                {/* <p>(0)</p> */}
              </div>

              <div
                onClick={() => navigate(`/booking/customer/complete`)}
                className={cn(
                  "flex items-center space-x-1 border-b py-2 px-2 cursor-pointer",
                  window.location.pathname === "/booking/customer/complete" &&
                    "bg-freightBlueDark/10 rounded-r"
                )}
              >
                <h1
                  className={cn(
                    "text-sm font-medium text-gray-500",
                    window.location.pathname === "/booking/customer/complete" &&
                      "text-freightBlueDark"
                  )}
                >
                  Completed
                </h1>
                {Number(bookingMessages?.completedCnt) > 0 && (
                  <div className="bg-red-500 h-6 w-6 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white">
                      {bookingMessages?.completedCnt}
                    </p>
                  </div>
                )}
                {/* <p>(0)</p> */}
              </div>
              <div
                onClick={() => navigate(`/booking/customer/cancelled`)}
                className={cn(
                  "flex items-center space-x-1 border-b py-2 px-2 cursor-pointer",
                  window.location.pathname === "/booking/customer/cancelled" &&
                    "bg-freightBlueDark/10 rounded-r"
                )}
              >
                <h1
                  className={cn(
                    "text-sm font-medium text-gray-500",
                    window.location.pathname ===
                      "/booking/customer/cancelled" && "text-freightBlueDark"
                  )}
                >
                  Cancelled
                </h1>
                {Number(bookingMessages?.cancelledCnt) > 0 && (
                  <div className="bg-red-500 h-6 w-6 shadow rounded-full flex items-center justify-center">
                    <p className="font-semibold text-white">
                      {bookingMessages?.cancelledCnt}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="">
            <div
              onClick={() => {
                setShowTrips(!showTrips);
                setShowTickets(false);
                setShowAccount(false);
                setShowBookings(false);
                setShowFeedback(false);
                setShowFreightListings(false);
                setShowInbox(false);
                setShowPayments(false);
              }}
              className="flex p-1 items-center w-full px-4 py-2 justify-between transition cursor-pointer"
            >
              <div className="flex items-center space-x-2">
                <CarFront className="h-4 w-4 text-[#333]" />
                <h3 className="text-sm font-medium text-[#333]">
                  My Deliveries
                </h3>
              </div>
              <FaArrowDown
                className={`h-3 transition ${
                  showTrips
                    ? "animate-toArrowUp rotate-180"
                    : "animate-toArrowDown"
                }`}
              />
            </div>
            {showTrips && (
              <div className="flex flex-col px-4 bg-white transition py-2 m-2 border rounded">
                <div
                  className="flex items-center space-x-1 px-2 border-b py-2 cursor-pointer hover:bg-gray-50 transition duration-300 ease-in-out"
                  onClick={() => navigate(`/customer/trips`)}
                >
                  <h1 className="text-sm font-medium text-gray-500">
                    View Stops
                  </h1>
                </div>
              </div>
            )}
          </div>
          <div className="">
            <div
              onClick={() => {
                setShowTickets(!showTickets);
                setShowAccount(false);
                setShowBookings(false);
                setShowFeedback(false);
                setShowFreightListings(false);
                setShowInbox(false);
                setShowPayments(false);
              }}
              className="flex p-1 items-center w-full px-4 py-2 justify-between transition cursor-pointer"
            >
              <div className="flex items-center space-x-2">
                <IoTicket className="h-4 w-4 text-[#333]" />
                <h3 className="text-sm font-medium">Raise Ticket</h3>
              </div>
              <FaArrowDown
                className={`h-3 transition ${
                  showTickets
                    ? "animate-toArrowUp rotate-180"
                    : "animate-toArrowDown"
                }`}
              />
            </div>
            {showTickets && (
              <div className="flex flex-col bg-white  transition m-2 py-2 px-4 border rounded">
                <div
                  onClick={() => navigate("/tickets/raiseTicket")}
                  className={cn(
                    "flex items-center space-x-1 px-2 border-b py-2 cursor-pointer hover:bg-gray-50 transition duration-300 ease-in-out",
                    window.location.pathname === "/tickets/raiseTicket" &&
                      " bg-freightBlueDark/10 rounded-r"
                  )}
                >
                  <h1
                    className={cn(
                      "text-sm font-medium text-gray-500",
                      window.location.pathname === "/tickets/raiseTicket" &&
                        "text-freightBlueDark"
                    )}
                  >
                    Request Ticket
                  </h1>
                </div>
              </div>
            )}
          </div>
          <div
            onClick={() => {
              setShowAccount(!showAccount);
              setShowBookings(false);
              setShowFeedback(false);
              setShowFreightListings(false);
              setShowInbox(false);
              setShowPayments(false);
              setShowTickets(false);
            }}
            className="flex p-1 items-center w-full px-4 py-2 justify-between cursor-pointer"
          >
            <div className="flex items-center space-x-2">
              <BiSolidUser className="h-4 w-4 text-[#333]" />
              <h1 className="text-sm font-medium text-[#333]">My Account</h1>
            </div>
            <FaArrowDown
              className={`h-3  transition ${
                showAccount
                  ? "animate-toArrowUp rotate-180  "
                  : "animate-toArrowDown"
              } `}
            />
          </div>
          {showAccount && (
            <div className="flex flex-col px-4 bg-white transition m-2 py-2 border rounded">
              <div
                onClick={() => navigate(`/${user_type}/updateProfile`)}
                className={cn(
                  "flex items-center space-x-1 border-b py-2 px-2 cursor-pointer",
                  window.location.pathname === `${user_type}/updateProfile` &&
                    " bg-freightBlueDark/10 rounded-r"
                )}
              >
                <h1
                  className={cn(
                    "text-sm font-medium text-gray-500",
                    window.location.pathname ===
                      `/${user_type}/updateProfile` && "text-freightBlueDark"
                  )}
                >
                  Update Account Details
                </h1>
              </div>
              <div
                onClick={() => navigate(`/${user_type}/changePassword`)}
                className={cn(
                  "flex items-center space-x-1 border-b py-2 px-2 cursor-pointer",
                  window.location.pathname === `/${user_type}/changePassword` &&
                    " bg-freightBlueDark/10 rounded-r"
                )}
              >
                <h1
                  className={cn(
                    "text-sm font-medium text-gray-500",
                    window.location.pathname ===
                      `/${user_type}/changePassword` && "text-freightBlueDark"
                  )}
                >
                  Change Password
                </h1>
              </div>
            </div>
          )}
          <div
            onClick={() => setShowFeedback(!showFeedback)}
            className="flex items-center p-1 w-full px-4 py-2 justify-between cursor-pointer"
          >
            <div className="flex items-center space-x-2">
              <MdFeedback className="h-4 w-4 text-[#333]" />
              <h1 className="text-sm font-medium text-[#333]">My Feedback</h1>
            </div>
            <FaArrowDown
              className={`h-3
            transition
            ${
              showFeedback
                ? "animate-toArrowUp rotate-180   "
                : "animate-toArrowDown"
            }
            `}
            />
          </div>
          {showFeedback && (
            <div className="flex flex-col px-4 bg-white transition m-2 py-2 border rounded">
              <div
                onClick={() => navigate("/freight/customerFeedback")}
                className={cn(
                  "flex items-center space-x-1 border-b py-2 px-2 cursor-pointer",
                  window.location.pathname === `/freight/customerFeedback` &&
                    " bg-freightBlueDark/10 rounded-r"
                )}
              >
                <h1 className="text-sm font-medium text-gray-500">Feedback</h1>
              </div>
              <div
                onClick={() => navigate("/customer/statistics")}
                className={cn(
                  "flex items-center space-x-1 border-b py-2 px-2 cursor-pointer",
                  window.location.pathname === `/customer/statistics` &&
                    " bg-freightBlueDark/10 rounded-r"
                )}
              >
                <h1 className="text-sm font-medium text-gray-500">
                  My Statistics
                </h1>
              </div>
            </div>
          )}
        </div>
        <div
          onClick={() => navigate(`/customer/messages`)}
          className={cn(
            "flex items-center space-x-2 border-b py-2 cursor-pointer px-4 ",
            window.location.pathname === "/customer/messages" &&
              " bg-freightBlueDark/10 rounded-r"
          )}
        >
          <IoChatbox className="h-4 w-4  " />
          <h1 className="text-sm text-[#333] font-medium">Chat</h1>
        </div>
      </div>
    </div>
  );
};

export default CustomerSidebar;
