import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import {
  BASE_URL,
  BOOK_STOP,
  REGISTER_STOP_PAYMENT,
  UPDATE_STOP_STATUS,
} from "src/api/config";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { RouteType } from "src/types/types";
import * as animationData from "../../assets/SuccessPurchase.json";
import { Button } from "../ui/button";

interface CheckoutCompleteDeliveryPackageProps {
  clientSecret: string;
  customerId: any;
  attachPaymentMethod: (payment_method_token: string) => void;
  createWithCustomer: boolean;
  selectedMethod: any;
  paymentIntentId: string;
  selectedWithPaymentMethod: boolean;
  stop: {
    data: RouteType[];
    message: string;
  };
}

const CheckoutCompleteDeliveryPackage: React.FC<
  CheckoutCompleteDeliveryPackageProps
> = ({
  clientSecret,
  attachPaymentMethod,
  // createPaymentMethod,
  createWithCustomer,
  customerId,
  paymentIntentId,
  selectedWithPaymentMethod,
  selectedMethod,
  stop,
}) => {
  const token = useTokenStoreStorage((state) => state.token);
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");
  const [loadingRetrieve, setLoadingRetrieve] = useState(false);
  const [createdWithCustomer, setCreatedWithCustomer] = useState(false);
  const [successfulWithSelectedMethod, setSuccesfulWithSelectedMethod] =
    useState(false);
  const [showSuccessfulMessage, setShowSuccessfulMessage] = useState(false);
  const { email, name, user_id, user_type } = userInfoUserStore(
    (state) => state
  );
  const [successfulPayment, setSuccessfulPayment] = useState(false);
  const [hideInput, setHideInput] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedWithPaymentMethod && stripe !== null) {
      setTimeout(() => {
        setSuccesfulWithSelectedMethod(true);
      }, 1000);
    } else {
      return;
    }
  }, [selectedWithPaymentMethod, stripe]);
  // useEffect(() => {
  //   if (createWithCustomer && selectedMethod) {
  //     setCreatedWithCustomer(true);
  //   } else {
  //     setCreatedWithCustomer(false);
  //   }
  // }, [createWithCustomer, selectedMethod]);

  // useEffect(() => {
  //   if (createdWithCustomer) {
  //     confirmPayment(selectedMethod.id);
  //   }
  // }, [createdWithCustomer]);

  const retrievePayment = async (e?: any) => {
    e?.preventDefault();

    if (!stripe || !elements) return;

    //@ts-ignore
    const result = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          email: email,
          name: name,
        },
      },
    });

    const response: any = await attachPaymentMethod(result?.paymentMethod?.id!);

    if (response?.message === "Payment method associated successfully") {
      confirmPayment(response?.data.payment_method_id);
      setSuccessfulPayment(true);
      setLoadingRetrieve(false);
    }
  };

  const setTripToBooked = async () => {
    const response = await fetch(`${BASE_URL}${UPDATE_STOP_STATUS}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        stop_id: stop?.data[0].id,
        status: "booked",
      }),
    });

    return response;
  };

  const registerTripStopPayment = async (paymentIntentId: string) => {
    const response = await fetch(
      `${BASE_URL}${REGISTER_STOP_PAYMENT}?user_id=${user_id}&freight_id=${stop.data[0].id}&freight_amount=${stop.data[0].price}&stripe_customer_id=${customerId}&stripe_freight_payment_id=${paymentIntentId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Stop payment successfully registered.") {
          toast.success(data.message);
          setTripToBooked();
          assignRoute();
          setTimeout(() => {
            navigate("/customer/trips");
          }, 2000);
        }
      });
    return response;
  };

  const assignRoute = async () => {
    const response = await fetch(`${BASE_URL}${BOOK_STOP}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        stop_id: stop.data[0].id.toString(),
        customer_id: user_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Customer stop accepted successfully") {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const confirmPayment = async (paymentMethod: string) => {
    if (!stripe) return;

    const { error, paymentIntent } = await stripe.confirmPayment({
      clientSecret: clientSecret,
      confirmParams: {
        payment_method: paymentMethod,

        return_url: "http://localhost:3000/dashboard-customer",
      },
      redirect: "if_required",
    });
    if (paymentIntent?.status === "succeeded") {
      toast.success(`Payment successful`);
      setShowSuccessfulMessage(true);

      setTimeout(() => {
        registerTripStopPayment(paymentIntent?.id);
      }, 2000);
    }

    if (error?.type === "card_error" || error?.type === "validation_error") {
      toast.error(`${error.message}`);
    } else {
      setMessage("An unexpected error occured");
    }
  };
  const retrievePaymentWithRegisteredCard = async () => {
    if (!stripe || !elements) return;

    const response: any = await attachPaymentMethod(selectedMethod?.id!);

    if (
      //   response?.message === "Payment method associated successfully" ||
      response.message ===
      "The payment method is already associated with a user."
    ) {
      setSuccessfulPayment(true);
      confirmPayment(selectedMethod?.id!);
    }
  };
  useEffect(() => {
    retrievePaymentWithRegisteredCard();
  }, [successfulWithSelectedMethod]);
  const [isStopped, setIsStopped] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  if (successfulPayment && !showSuccessfulMessage) {
    return (
      <div className="flex flex-col space-y-2 w-full items-center justify-center ">
        <p className="text-lg font-medium">Fulfilling order</p>

        <Loader2 className="h-10 w-10 animate-spin" />
      </div>
    );
  }
  if (successfulPayment && showSuccessfulMessage) {
    return (
      <div className="flex flex-col space-y-2 w-full items-center justify-center ">
        <p className="text-lg font-medium">Order successful!</p>
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={isStopped}
          isPaused={isPaused}
        />{" "}
      </div>
    );
  }
  return (
    <div>
      <form id="payment-form" onSubmit={retrievePayment}>
        {!selectedWithPaymentMethod && (
          <>
            <h1 className="text-lg border-b pb-2 font-medium mb-4">
              Enter your card details
            </h1>
            <CardElement />
            <div className="mt-4 ml-auto justify-end w-full flex">
              <Button variant={"freightBlue"}>
                {loadingRetrieve ? (
                  <Loader2 className="h-4 w-4 animate-spin" />
                ) : (
                  `Pay ${stop?.data[0].price}€€`
                )}
              </Button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default CheckoutCompleteDeliveryPackage;
