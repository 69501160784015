import { useNavigate, useParams } from "react-router-dom";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { ALL_ROUTES, BASE_URL } from "../api/config";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import Header from "../components/header/Header";
import { Check } from "lucide-react";
import { Button } from "../components/ui/button";

const RoutePackageSuccess = () => {
  const params = useParams();
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id, name } = userInfoUserStore((state) => state);
  const navigate = useNavigate();
  const getRoute = async () => {
    const response = await fetch(
      `${BASE_URL}${ALL_ROUTES}?sort=desc&route_id=${params.routeId}&hasStops=false&user_id=${user_id}`,
      {
        headers: {},
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };
  const { data } = useQuery({
    queryKey: ["route", params.routeId],
    queryFn: getRoute,
  });

  return (
    <div className="bg-gray-50 h-screen">
      <Header />
      <div className="max-w-7xl mx-auto mt-20 gap-10 bg-white shadow rounded p-4">
        <div className="col-span-5">
          <div className="px-16 py-10 relative">
            <div className="absolute left-4 top-10 bg-green-500 h-7 w-7 rounded-full justify-center items-center flex">
              <Check className="h-5 w-5 text-white  " />
            </div>
            <div className="flex flex-col items-start space-y-5">
              <h1 className="font-medium">Congratulations {name}</h1>
              <p>Your stop is active. Check out your route here.</p>
              <Button
                onClick={() => navigate("/carrier/trips")}
                className="mt-4"
                variant={"freightBlue"}
              >
                Go To My Dashboard
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoutePackageSuccess;
