import { zodResolver } from "@hookform/resolvers/zod";
import { useAtom } from "jotai";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  BASE_URL,
  CUSTOMER_LOGIN_URL,
  LOGIN_CHECK,
  PRE_LOGIN,
} from "src/api/config";
import { hasLoggedIn, userId } from "src/atoms/atoms";
import { Button } from "src/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import {
  useGuidTokenStorage,
  useTokenStoreStorage,
} from "src/store/useTokenStore";
import { z } from "zod";
import { userInfoUserStore } from "../store/useUserInfoStore";
//@ts-ignore
import LogoApp from "src/assets/logo/LogoApp.png";

import { ArrowRight, Loader2 } from "lucide-react";
// import toast from "react-hot-toast";
import { Bounce, toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showErrorPassword, setShowErrorPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const setTokens = useTokenStoreStorage((s) => s.addToken);
  const [user_id, setUserId] = useAtom(userId);
  const setUserInfo = userInfoUserStore((s) => s.addUserInfo);
  const setGuidToken = useGuidTokenStorage((state) => state.addGuid);
  const [isLoggedIn, setIsLoggedIn] = useAtom(hasLoggedIn);

  const formSchema = z.object({
    email: z.string().min(1),
    password: z.string().min(1),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const preLogin = async (username: string, password: string) => {
    const response = await fetch(`${BASE_URL}${PRE_LOGIN}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          setLoading(false);
          toast.error(`Error trying to login`);
        }
        return res.json();
      })
      .then((data) => {
        if (data.message === "Not found.") {
          toast.error("Account not found", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          // toast.error(`Account not found`);
          setLoading(false);
        }
        setGuidToken(data.data.GUID);
        loginUser(username, password, data.data.GUID);
        setIsLoggedIn(true);
      })
      .catch((err) => {
        toast.error(`Error trying to login`);
        console.log(err);
      });

    return response;
  };

  const loginUser = async (
    email: string,
    password: string,
    guidToken: string
  ) => {
    const response = await fetch(
      `${BASE_URL}${CUSTOMER_LOGIN_URL}?username=${email}&password=${password}&GUID=${guidToken}`,
      {
        method: "POST",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (
          data.message ===
          "User authentication has failed. Check your credentials and try again."
        ) {
          setShowErrorPassword(true);
          toast.error(data.message);
        }
        if (
          data.message === "User logged in successfully." &&
          (data.data.user_type === "customer" ||
            data.data.user_type === "carrier")
        ) {
          setUserInfo(
            data.data.email,
            data.data.phone_number,
            data.data.user_type,
            data.data.user_id,
            data.data.first_name
          );
          setUserId(data.data.user_id);
          setTokens(data.data.access_token);
          if (data.data.user_type === "customer") {
            navigate("/dashboard-customer");
          }
          if (data.data.user_type === "carrier") {
            navigate("/dashboard");
          }
        }
        if (data.message === "Your account need to be verified first.") {
          navigate("/register/verify");
        }
        if (data.message === "This account is currently logged in.") {
          setUserId(data.data.user_id);
          setLoading(false);

          if (data.data.user_id !== "") {
            loginCheck(data.data.user_id, guidToken);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return response;
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setLoading(true);
    preLogin(values.email, values.password);
  };

  const loginCheck = async (userId: string, guidToken: string) => {
    const response = await fetch(
      `${BASE_URL}${LOGIN_CHECK}?GUID=${guidToken}&user_id=${userId}`,
      {
        method: "POST",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (
          data.message === "User logged in successfully." &&
          (data.data.user_type === "customer" ||
            data.data.user_type === "carrier")
        ) {
          setUserInfo(
            data.data.email,
            data.data.mobile,
            data.data.user_type,
            data.data.user_id,
            data.data.first_name
          );
          // testRecaptcha();
          setUserId(data.data.user_id);
          setTokens(data.data.access_token);
          if (data.data.user_type === "customer") {
            navigate("/dashboard-customer");
          }
          if (data.data.user_type === "carrier") {
            navigate("/dashboard");
          }
        }
      });

    return response;
  };

  return (
    <main className="flex flex-col items-center">
      <div className="max-w-6xl lg:mx-auto mx-2 flex  mt-20 shadow">
        {/* <div className="grid grid-cols-2 min-h-[800px]"> */}
        {/* <div className="p-16 space-y-4 bg-freightBlueDark">
            <h1 className="text-2xl font-medium text-white">
              Welcome to Runnme
            </h1>
            <h3 className=" font-medium  text-white rounded-l">
              Any type of freight can be listed on Runnme, ranging from
              palletised freight, packaged freight, freight containers,
              furniture removals, household goods, cars, boats, horses,
              livestock and even pets.
            </h3>
            <div className="flex flex-col items-center space-y-3 pt-20">
              <button className="  font-medium text-white">
                Don't have an account?
              </button>
              <button
                onClick={() => navigate("/register")}
                className=" px-10 py-2 font-medium border rounded border-black bg-white text-black"
              >
                Sign up
              </button>
            </div>
          </div> */}
        <div className="flex flex-col items-center p-10 lg:p-16 lg:min-w-[500px] bg-white rounded w-full">
          <img src={LogoApp} className="h-20 w-20 mb-4" alt="logo" />
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full flex flex-col"
            >
              <div className="w-full mb-4">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm  dark:text-black mb-1">
                        Email address or username *
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          name="email"
                          className="w-full dark:bg-transparent"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
              <div className="w-full ">
                <div className="w-full mb-4 relative">
                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-sm  dark:text-black mb-1">
                          Password *
                        </FormLabel>
                        <FormControl>
                          <Input
                            type={!showPassword ? "password" : "text"}
                            {...field}
                            name="password"
                            className="w-full dark:bg-transparent "
                          />
                        </FormControl>
                        <div className="absolute top-10 right-2">
                          {showPassword ? (
                            <div
                              onClick={() => setShowPassword(false)}
                              className="cursor-pointer h-4 w-4"
                            >
                              <FaEyeSlash />
                            </div>
                          ) : (
                            <div onClick={() => setShowPassword(true)}>
                              <FaEye className="h-4 w-4 cursor-pointer" />
                            </div>
                          )}
                        </div>
                      </FormItem>
                    )}
                  />
                </div>
                <div className="w-full mb-10">
                  <p className="text-text text-sm font-medium">
                    Forget your password?
                  </p>
                </div>
              </div>
              <div className="w-full">
                <Button
                  disabled={loading}
                  type="submit"
                  variant={"freightBlue"}
                  className="w-full"
                >
                  {loading ? (
                    <Loader2 className="animate-spin h-6 w-6" />
                  ) : (
                    <p>Sign in</p>
                  )}
                </Button>
              </div>
              <div className="w-full mt-4 flex items-center space-x-1 pt-4 border-t border-border">
                <p className="text-sm">New in Runnme?</p>
                <div
                  onClick={() => navigate("/preregister")}
                  className="text-sm font-medium group text-freightBlueDark flex hover:underline items-center cursor-pointer"
                >
                  {/* <p>Start here</p> */}
                  <p>Get early access</p>
                  <ArrowRight className="h-4 w-4 text-freightBlue group-hover:translate-x-2 transition" />
                </div>
              </div>
            </form>
          </Form>
        </div>
        {/* </div> */}
      </div>
    </main>
  );
};

export default Login;
