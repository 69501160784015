import { useQuery } from "@tanstack/react-query";
import { ChevronLeft, Pencil } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { BASE_URL, BASE_URL_IMG_2, INSTANT_FREIGHTS } from "../api/config";
import Header from "../components/header/Header";
import { Button } from "../components/ui/button";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
//@ts-ignore
import MovingBox from "../assets/images/moving-box.png";
//@ts-ignore
import ShoesBox from "../assets/images/shoes-box.png";
//@ts-ignore
import BooksBox from "../assets/images/books-box.png";
import ModalInstantServiceEdit from "../components/modal/ModalInstantServiceEdit";
import { useEditInstantService } from "../hooks/use-edit-instant-freight";

const pickupDropoffSchema = z.object({
  origin: z.any(),
  destination: z.any(),
});
const shipmentDetailsSchema = z.object({
  weightArticle: z.string().min(1),
  dimensionLongitude: z.string().min(1),
  dimensionWidth: z.string().min(1),
  dimensionHeight: z.string().min(1),
  articleQuantity: z.string().min(1),
});
const shipmentOptions = [
  {
    title: "Small",
    dimensions: "23 x 14 x 4cm",
    image: BooksBox,
  },
  {
    title: "Medium",
    dimensions: "35 x 20 x 15cm",
    image: ShoesBox,
  },
  {
    title: "Large",
    dimensions: "75 x 35 x 35cm",
    image: MovingBox,
  },
];
const InstantServiceEdit = () => {
  const { user_id } = userInfoUserStore((state) => state);
  const params = useParams();
  const token = useTokenStoreStorage((state) => state.token);

  const navigate = useNavigate();

  const modalEditInstantService = useEditInstantService();

  const getInstantFreights = async () => {
    const response = await fetch(
      `${BASE_URL}${INSTANT_FREIGHTS}?user_id=${user_id}&freight_id=${params.instantServiceId}&sort=ASC`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    ).then((res) => res.json());
    return response;
  };

  const {
    data: instantFreights,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["instant_freights", params.instantServiceId],
    queryFn: getInstantFreights,
  });

  return (
    <div className="bg-gray-50 h-screen">
      <Header />
      <div className="flex items-start space-x-2 py-10 max-w-7xl mx-auto w-full h-[94vh] no-scrollbar">
        <div className="sm:flex pl-2 pr-2 flex-col w-full">
          <div className="flex items-center justify-between">
            <div
              onClick={() =>
                navigate(`/instant-freights/${params.instantServiceId}`)
              }
              className="flex items-center space-x-2 group cursor-pointer mb-4"
            >
              <ChevronLeft className="text-freightBlueDark h-5 w-5" />
              <h1 className="text-freightBlueDark text-sm group-hover:underline ">
                Back
              </h1>
            </div>
          </div>
          <div className="flex pr-2 items-center justify-between mt-10">
            <h1 className="text-2xl font-medium text-gray-500">
              Edit Instant Service
            </h1>
          </div>
          <div className="bg-white shadow pr-6 mt-10 pt-4 pb-4">
            <>
              {" "}
              <div className="mt-8 mx-4">
                <h2 className="text-lg font-bold mb-4">Shipping Details</h2>
                <div className="flex space-x-6 bg-gray-100 p-4 rounded-lg">
                  <div>
                    <h3 className="text-sm font-medium">Origin Address</h3>
                    <p className="text-gray-500">{`${instantFreights?.data[0][0].origin_address}, ${instantFreights?.data[0][0].origin_city}`}</p>
                  </div>
                  <div>
                    <h3 className="text-sm font-medium">Destination Address</h3>
                    <p className="text-gray-500">{`${instantFreights?.data[0][0].destination_address}, ${instantFreights?.data[0][0].destination_city}`}</p>
                  </div>
                  <div>
                    <h3 className="text-sm font-medium">Distance</h3>
                    <p className="text-gray-500">{`${instantFreights?.data[0][0].delivery_distance} km`}</p>
                  </div>
                  <div>
                    <h3 className="text-sm font-medium">Duration</h3>
                    <p className="text-gray-500">{`${instantFreights?.data[0][0].delivery_duration}`}</p>
                  </div>
                </div>
                <Button
                  onClick={() =>
                    modalEditInstantService.onOpen(
                      "editInstantService",
                      instantFreights?.data[0][0],
                      "direction"
                    )
                  }
                  className="flex items-center space-x-2 mt-2 ml-auto"
                >
                  <Pencil className="h-4 w-4" />
                  <span>Edit</span>
                </Button>
              </div>{" "}
              <div className="mt-6 mx-4">
                <h2 className="text-lg font-bold mb-4">Package</h2>
                <div className="flex space-x-6 bg-gray-100 p-4 rounded-lg">
                  <div>
                    <h3 className="text-sm font-medium">Weight</h3>
                    <p className="text-gray-500">{`${instantFreights?.data[0][0].package_weight} kg`}</p>
                  </div>
                  <div>
                    <h3 className="text-sm font-medium">Dimensions</h3>
                    <p className="text-gray-500">{`${instantFreights?.data[0][0].package_size}`}</p>
                  </div>
                  <div>
                    <h3 className="text-sm font-medium">Price Estimated</h3>
                    <p className="text-gray-500">{`${Number(
                      instantFreights?.data[0][0].estimated_price
                    ).toFixed(2)} €`}</p>
                  </div>
                </div>
                <Button
                  onClick={() =>
                    modalEditInstantService.onOpen(
                      "editInstantService",
                      instantFreights?.data[0][0],
                      "package"
                    )
                  }
                  className="flex items-center space-x-2 mt-2 ml-auto"
                >
                  <Pencil className="h-4 w-4" />
                  <span>Edit</span>
                </Button>
              </div>
              {instantFreights?.data[0][0]?.pet_type && (
                <div className="mt-6 mx-4">
                  <h2 className="text-lg font-bold mb-4">Pet</h2>
                  <div className="flex space-x-6 bg-gray-100 p-4 rounded-lg">
                    <div>
                      <h3 className="text-sm font-medium">Pet Type</h3>
                      <p className="text-gray-500 capitalize">
                        {instantFreights?.data[0][0]?.pet_type}
                      </p>
                    </div>
                    <div>
                      <h3 className="text-sm font-medium">Pet Size</h3>
                      <p className="text-gray-500 capitalize">
                        {instantFreights?.data[0][0]?.pet_size}
                      </p>
                    </div>
                    <div>
                      <img
                        src={`${BASE_URL_IMG_2}${instantFreights?.data[0][0]?.pet_img}`}
                        alt="pet"
                        className="h-20 rounded object-cover"
                      />
                    </div>
                  </div>
                  <Button
                    onClick={() =>
                      modalEditInstantService.onOpen(
                        "editInstantService",
                        instantFreights?.data[0][0],
                        "pet"
                      )
                    }
                    className="flex items-center space-x-2 mt-2 ml-auto"
                  >
                    <Pencil className="h-4 w-4" />
                    <span>Edit</span>
                  </Button>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
      {modalEditInstantService.isOpen && (
        <ModalInstantServiceEdit refetch={refetch} />
      )}
    </div>
  );
};

export default InstantServiceEdit;
