import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useQuery } from "@tanstack/react-query";
import { Check, ChevronLeft, CircleOff, Loader2 } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ALL_ROUTES, BASE_URL, GOOGLE_API_KEY } from "../api/config";
import Header from "../components/header/Header";
import { Skeleton } from "../components/skeleton/Skeleton";
import { useBookTripStop } from "../hooks/use-book-trip-stop";
import { cn } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { RouteType, StopType } from "../types/types";

import { Button } from "../components/ui/button";

//@ts-ignore

//@ts-ignore
//@ts-ignore

//@ts-ignore
import ModalBookTripStop from "../components/modal/ModalBookTripStop";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";
const containerStyle = {
  Maxwidth: "540px",
  height: "400px",
  borderRadius: "4px",
};
const center = {
  lat: -3.745,
  lng: -38.523,
};

const SingleRouteTrip = () => {
  const token = useTokenStoreStorage((state) => state.token);
  const params = useParams();
  const { user_id } = userInfoUserStore((state) => state);
  const navigate = useNavigate();
  const [directionResponse, setDirectionsResponse] = useState<any>(null);
  const [map, setMap] = useState(null);
  const modalBookTripStop = useBookTripStop();
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });

  async function calculateRoute() {
    const directionsService = new google.maps.DirectionsService();

    //@ts-ignore
    const results = await directionsService.route({
      origin: {
        lat: Number(route?.data.routes[0]?.origin_lat),
        lng: Number(route?.data.routes[0]?.origin_lng),
      },
      destination: {
        lat: Number(route?.data.routes[0]?.destination_lat),
        lng: Number(route?.data.routes[0]?.destination_lng),
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionsResponse(results);
  }

  const getRoute = async () => {
    const response = await fetch(
      `${BASE_URL}${ALL_ROUTES}?route_id=${params.routeId}&sort=desc&hasStops=false`,
      {
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };
  const {
    data: route,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["routes_user_route", params.routeId],
    queryFn: getRoute,
  });

  useEffect(() => {
    refetch();
  }, []);

  const onLoad = useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      calculateRoute();
    }
  }, [isLoading, route]);

  return (
    <div className="h-screen bg-gray-50">
      <Header />
      <div className="max-w-7xl mx-auto p-16 h-[94vh] overflow-y-auto no-scrollbar">
        <div className="flex items-center justify-between">
          <div
            onClick={() => navigate("/find-trip")}
            className="flex items-center space-x-2 group cursor-pointer mb-4"
          >
            <ChevronLeft className="text-freightBlueDark h-5 w-5" />
            <h1 className="text-freightBlueDark text-sm group-hover:underline ">
              Back
            </h1>
          </div>
        </div>
        {isLoading ? (
          <div className="bg-white shadow border border-border p-4 grid grid-cols-2 gap-4">
            <div>
              <div className="w-[540px] h-[400px] bg-gray-50 flex items-center justify-center">
                <Loader2 className="h-5 w-5 animate-spin" />
              </div>
              <div className="flex items-center justify-around mt-4">
                <Skeleton className=" h-20 w-20" />
                <Skeleton className=" h-20 w-20" />
                <Skeleton className=" h-20 w-20" />
              </div>
            </div>
            <div className="flex flex-col items-start">
              <div className="flex items-center justify-between w-full">
                <Skeleton className="h-10 w-10" />
                <Skeleton className="h-10 w-10" />
              </div>
            </div>
          </div>
        ) : (
          <div>
            {route.data.routes.map((route: RouteType) => (
              <div className="bg-white shadow border-border border p-4 sm:grid grid-cols-2 gap-4">
                <div>
                  {isLoaded ? (
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={{
                        lat: Number(route?.origin_lat),
                        lng: Number(route?.origin_lng),
                      }}
                      options={{
                        streetViewControl: false,
                      }}
                      zoom={15}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                    >
                      {/* <Marker
                        position={{
                          lat: Number(route?.origin_lat),
                          lng: Number(route?.origin_lng),
                        }}
                      />
                      <Marker
                        position={{
                          lat: Number(route?.destination_lat),
                          lng: Number(route?.destination_lng),
                        }}
                      /> */}
                      {route.stops.map((stop) => (
                        <Marker
                          key={stop.id}
                          icon={{
                            // path: google.maps.SymbolPath.CIRCLE,
                            url: require("./../assets/images/box.png"),
                            fillColor: "#EB00FF",
                            scale: 7,
                          }}
                          // icon={{
                          //   // url: "/package.svg",
                          //   url: "/custom_marker_pin.svg",
                          //   anchor: new google.maps.Point(17, 46),
                          //   scaledSize: new google.maps.Size(37, 37),
                          // }}
                          position={{
                            lat: Number(stop.destination_lat),
                            lng: Number(stop.destination_lng),
                          }}
                        />
                      ))}
                      {directionResponse && (
                        <DirectionsRenderer directions={directionResponse} />
                      )}
                    </GoogleMap>
                  ) : null}
                </div>

                <div className="flex flex-col items-start space-y-10">
                  <div className="flex items-center justify-between w-full">
                    <div className="">
                      {route.is_one_way ? (
                        <div className="flex items-center space-x-2">
                          <div className="h-6 w-6 bg-green-500 flex items-center justify-center rounded-full">
                            <Check className="text-white  h-4 w-4" />
                          </div>
                          <p className="text-sm font-medium">One way trip</p>
                        </div>
                      ) : (
                        <div className="flex items-center space-x-2">
                          <CircleOff className="text-white bg-red-500 rounded-full h-5 w-5 " />
                          <p className="text-sm font-medium">
                            Not One Way Trip
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col space-y-4 w-full">
                    <div className="flex items-start justify-between w-full">
                      <p className="text-sm font-medium">Start Date</p>
                      <p className="text-sm font-medium text-gray-500">
                        {new Date(route.start_date).toDateString()}{" "}
                        {new Date(route.start_date).getHours()}:
                        {new Date(route.start_date).getMinutes()}
                      </p>
                    </div>
                    <div className="flex items-start justify-between w-full">
                      <p className="text-sm font-medium">End Date</p>
                      <p className="text-sm font-medium text-gray-500">
                        {new Date(route.end_date).toDateString()}{" "}
                        {new Date(route.end_date).getHours()}:
                        {new Date(route.end_date).getMinutes()}
                      </p>
                    </div>
                    <div className="flex items-start justify-between w-full">
                      <p className="text-sm font-medium">Origin</p>
                      <p className="text-sm font-medium text-gray-500">
                        {route.origin_address}, {route.origin_zipcode}
                      </p>
                    </div>
                    <div className="flex items-start justify-between w-full">
                      <p className="text-sm font-medium">Destination</p>
                      <p className="text-sm font-medium text-gray-500">
                        {route.destination_address}, {route.destination_zipcode}
                      </p>
                    </div>
                    <div className="flex items-start justify-between w-full">
                      <p className="text-sm font-medium">Nº stops</p>
                      <p className="text-sm font-medium text-gray-500">
                        {route.stops.length}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className=" mt-10">
          <h1 className="text-lg font-medium pb-3">Stops</h1>
          <div className="  sm:grid grid-cols-3 gap-4">
            {route?.data.routes[0].stops.map((route: StopType) => (
              <div className="bg-white border border-border p-4 rounded flex flex-col w-full">
                <div className="flex flex-col space-y-3">
                  <div className="flex items-start justify-between">
                    <p className="text-sm font-medium">Status</p>
                    {route.status === "available" ? (
                      <>
                        <TooltipProvider>
                          <Tooltip delayDuration={0}>
                            <TooltipTrigger>
                              <Button
                                onClick={() => {
                                  if (user_id !== "") {
                                    modalBookTripStop.onOpen(
                                      "bookTripStop",
                                      route,
                                      user_id
                                    );
                                  }
                                }}
                                className={cn(
                                  "w-40 cursor-pointer",
                                  "text-sm font-medium  text-white capitalize bg-statusCreated hover:bg-statusCreated px-4 py-2 rounded w-40 text-center"
                                )}
                              >
                                <p className="text-sm font-medium capitalize">
                                  {route.status}
                                </p>
                              </Button>
                            </TooltipTrigger>
                            {user_id === "" && (
                              <TooltipContent>
                                <p>User must be logged in</p>
                                <></>
                              </TooltipContent>
                            )}
                          </Tooltip>
                        </TooltipProvider>
                      </>
                    ) : (
                      <p
                        className={cn(
                          "text-sm font-medium text-white capitalize bg-statusCreated px-4 py-2 rounded w-40 text-center",
                          route.status === "available" && "bg-statusCreated",
                          route.status === "in-transit" && "bg-statusInTransit",
                          route.status === "booked" && "bg-statusPaid",
                          route.status === "confirmed" && "bg-statusConfirmed"
                        )}
                      >
                        <p className="text-sm font-medium capitalize">
                          {route.status}
                        </p>
                      </p>
                    )}
                  </div>
                  <div className="flex items-start justify-between">
                    <p className="text-sm font-medium">Customer</p>
                    <p className="text-sm font-medium text-gray-500">
                      {route.customer_id}
                    </p>
                  </div>
                  <div className=" flex items-start justify-between">
                    <p className="text-sm font-medium">Package</p>
                    <p className="text-sm font-medium text-gray-500 capitalize">
                      {route.package_size}
                    </p>
                  </div>
                  <div className="flex items-start justify-between">
                    <p className="text-sm font-medium">Price</p>
                    <p className="text-sm font-medium text-gray-500">
                      {route.price.toFixed(2)}€
                    </p>
                  </div>

                  <div className="flex items-start justify-between ">
                    <p className="text-sm font-medium">Origin</p>
                    <p className="text-sm font-medium text-gray-500">
                      {route.origin_address}
                    </p>
                  </div>
                  <div className="flex items-start justify-between ">
                    <p className="text-sm font-medium">Destination</p>
                    <p className="text-sm font-medium text-gray-500">
                      {route.destination_address}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {modalBookTripStop.isOpen && (
        <ModalBookTripStop refetchRoutes={refetch} />
      )}
    </div>
  );
};

export default SingleRouteTrip;
