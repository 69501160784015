import { useEffect, useState } from "react";
import Header from "../components/header/Header";
import { cn } from "../lib/utils";
import { Input } from "../components/ui/input";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form";
import { Button } from "../components/ui/button";

//@ts-ignore
import A4 from "../assets/images/a4.png";
//@ts-ignore
import MovingBox from "../assets/images/moving-box.png";
//@ts-ignore
import ShoesBox from "../assets/images/shoes-box.png";
//@ts-ignore
import BooksBox from "../assets/images/books-box.png";
import { Check } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";

//@ts-ignore
import ESflag from "../assets/flags/spainflag.jpg";
//@ts-ignore
import AUFlag from "../assets/flags/auflag.png";

const shipmentOriginDestinSchema = z.object({
  fromOrigin: z.string().min(1),
  cityOrigin: z.string().min(1),
  zipCodeOrigin: z.string().min(1),
  toDestination: z.string().min(1),
  cityDestination: z.string().min(1),
  zipCodeDestination: z.string().min(1),
});

const shipmentDetailsSchema = z.object({
  weightArticle: z.string().min(1),
  dimensionLongitude: z.string().min(1),
  dimensionWidth: z.string().min(1),
  dimensionHeight: z.string().min(1),
  articleQuantity: z.string().min(1),
});

const shipmentOptions = [
  // {
  //   title: "About A4",
  //   dimensions: "32 x 24 x 1cm",
  //   image: A4,
  // },
  {
    title: "Small",
    dimensions: "23 x 14 x 4cm",
    image: BooksBox,
  },
  {
    title: "Medium",
    dimensions: "35 x 20 x 15cm",
    image: ShoesBox,
  },
  {
    title: "Large",
    dimensions: "75 x 35 x 35cm",
    image: MovingBox,
  },
];

const countryOptions = [
  {
    country: "Spain",
    symbol: "es",
    flag: ESflag,
  },
  {
    country: "Australia",
    symbol: "au",
    flag: AUFlag,
  },
];

const BookNow = () => {
  const [showEntreprise, setShowEntreprise] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<{
    title: string;
    dimensions: string;
    image: string;
  }>({ dimensions: "", image: "", title: "" });
  const [allCities, setAllCities] = useState<
    {
      id: string;
      name: string;
      province: string;
    }[]
  >([]);

  const formShipment = useForm<z.infer<typeof shipmentOriginDestinSchema>>({
    resolver: zodResolver(shipmentOriginDestinSchema),
    defaultValues: {
      fromOrigin: "",
      cityDestination: "",
      cityOrigin: "",
      toDestination: "",
      zipCodeDestination: "",
      zipCodeOrigin: "",
    },
  });

  const shipmentDetails = useForm<z.infer<typeof shipmentDetailsSchema>>({
    resolver: zodResolver(shipmentDetailsSchema),
    defaultValues: {
      articleQuantity: "1",
      dimensionHeight: "",
      dimensionLongitude: "",
      dimensionWidth: "",
      weightArticle: "",
    },
  });

  const handleChangePackage = (item: {
    title: string;
    dimensions: string;
    image: string;
  }) => {
    if (item.dimensions === "32 x 24 x 1cm") {
      shipmentDetails.reset({
        weightArticle: shipmentDetails.getValues("weightArticle"),
        dimensionHeight: "1",
        dimensionLongitude: "32",
        dimensionWidth: "24",
      });
    } else if (item.dimensions === "23 x 14 x 4cm") {
      shipmentDetails.reset({
        weightArticle: shipmentDetails.getValues("weightArticle"),
        dimensionHeight: "4",
        dimensionLongitude: "23",
        dimensionWidth: "14",
      });
    } else if (item.dimensions === "35 x 20 x 15cm") {
      shipmentDetails.reset({
        weightArticle: shipmentDetails.getValues("weightArticle"),
        dimensionHeight: "15",
        dimensionLongitude: "35",
        dimensionWidth: "20",
      });
    } else if (item.dimensions === "75 x 35 x 35cm") {
      shipmentDetails.reset({
        weightArticle: shipmentDetails.getValues("weightArticle"),
        dimensionHeight: "35",
        dimensionLongitude: "75",
        dimensionWidth: "35",
      });
    }
  };

  const tokenData = new FormData();
  tokenData.append("mode", "username");
  tokenData.append("username", "support@flexihours.com");
  const getTokenForValidation = () => {
    fetch(`https://api.flexihours.es/login`, {
      method: "POST",
      body: tokenData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          getCities(data?.result.token);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTokenForValidation();
  }, []);

  const getCities = async (token: string) => {
    const data = await fetch(
      `https://api.flexihours.es/cities?country_code=ES`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setAllCities(data?.result);
      });

    return data;
  };

  // const { data: allCities } = useQuery({
  //   queryKey: ["allcities"],
  //   queryFn: getCities,
  // });

  const onSubmit = async (
    values: z.infer<typeof shipmentOriginDestinSchema>
  ) => {};

  const onSubmitShipment = async (
    values: z.infer<typeof shipmentDetailsSchema>
  ) => {};
  return (
    <div>
      <Header />
      <div className="max-w-7xl mx-auto p-16">
        <div className="flex items-center ">
          <div
            onClick={() => setShowEntreprise(false)}
            className={cn(
              "px-4 border-t-2 py-4 shadow cursor-pointer bg-gray-200",
              !showEntreprise && "border-t-4 border-t-freightBlueDark bg-white"
            )}
          >
            <h1>Entreprise</h1>
          </div>
          <div
            onClick={() => setShowEntreprise(true)}
            className={cn(
              "px-4 border-t-2 py-4 shadow bg-gray-200 cursor-pointer",
              showEntreprise && "border-t-4 border-t-freightBlueDark bg-white "
            )}
          >
            <h1>Particular</h1>
          </div>
        </div>
        <div className="p-4 bg-white shadow">
          <Form {...formShipment}>
            <form onSubmit={formShipment.handleSubmit(onSubmit)}>
              <div className="flex flex-col py-4">
                <div className="flex items-start space-x-4 mb-4">
                  <div className="flex flex-col items-start">
                    {/* <FormField
                      control={formShipment.control}
                      name="fromOrigin"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>From</FormLabel>
                          <FormControl>
                            <Input className="w-80" {...field} />
                          </FormControl>
                        </FormItem>
                      )}
                    /> */}
                    <h1 className="text-lg font-medium mb-2">From</h1>
                    <Controller
                      name="fromOrigin"
                      control={formShipment.control}
                      render={({ field, fieldState }) => (
                        <>
                          <Select
                            onValueChange={(value) => {
                              formShipment.setValue("fromOrigin", value);
                            }}
                            {...field}
                          >
                            <SelectTrigger className="w-80">
                              <SelectValue placeholder="Country or region" />
                            </SelectTrigger>
                            <SelectContent>
                              {countryOptions.map((country) => (
                                <SelectItem value={country.country}>
                                  <div className="flex items-center space-x-4">
                                    <img
                                      alt={country.symbol}
                                      src={country.flag}
                                      className="h-8 w-8 object-contain"
                                    />
                                    <p className="text-sm font-medium">
                                      {country.country}
                                    </p>
                                  </div>
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          {fieldState.error && (
                            <p className="text-sm font-medium">
                              {fieldState.error.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div>
                    <h1 className="text-sm font-medium mb-2 mt-2">City</h1>
                    <Controller
                      name="cityOrigin"
                      control={formShipment.control}
                      render={({ field, fieldState }) => (
                        <Select
                          onValueChange={(value) => {
                            formShipment.setValue("cityOrigin", value);
                          }}
                          {...field}
                        >
                          <SelectTrigger className="w-80">
                            <SelectValue placeholder="City" />
                          </SelectTrigger>
                          <SelectContent className="h-80">
                            {allCities?.map((city) => (
                              <SelectItem value={`${city.name} ${city.id}`}>
                                {city.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </div>

                  {/* <FormField
                    name="cityOrigin"
                    control={formShipment.control}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>City</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                      </FormItem>
                    )}
                  /> */}
                  <FormField
                    name="zipCodeOrigin"
                    control={formShipment.control}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Zipcode</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Ex: 99999"
                            {...field}
                            name="zipCodeOrigin"
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex items-start space-x-4">
                  <div className="flex flex-col ">
                    <h1 className="text-lg font-medium mb-2">To</h1>
                    <Controller
                      control={formShipment.control}
                      name="toDestination"
                      render={({ field, fieldState }) => (
                        <>
                          <Select
                            onValueChange={(value) => {
                              formShipment.setValue("toDestination", value);
                            }}
                            {...field}
                          >
                            <SelectTrigger className="w-80">
                              <SelectValue placeholder="Country or region" />
                            </SelectTrigger>
                            <SelectContent>
                              {countryOptions.map((country) => (
                                <SelectItem value={country.country}>
                                  <div className="flex items-center space-x-4">
                                    <img
                                      alt={country.symbol}
                                      src={country.flag}
                                      className="h-8 w-8 object-contain"
                                    />
                                    <p className="text-sm font-medium">
                                      {country.country}
                                    </p>
                                  </div>
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                          {fieldState.error && (
                            <p className="text-sm font-medium">
                              {fieldState.error.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  </div>
                  <div>
                    <h1 className="text-sm font-medium mb-2 mt-2">City</h1>

                    <FormField
                      name="cityDestination"
                      control={formShipment.control}
                      render={({ field }) => (
                        <Select
                          onValueChange={(value) => {
                            formShipment.setValue("cityDestination", value);
                          }}
                          {...field}
                        >
                          <SelectTrigger className="w-80">
                            <SelectValue placeholder="City" />
                          </SelectTrigger>
                          <SelectContent className="h-80">
                            {allCities?.map((city) => (
                              <SelectItem value={`${city.name} ${city.id}`}>
                                {city.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </div>
                  <FormField
                    // disabled={formShipment.getValues("toDestination") === ""}
                    name="zipCodeDestination"
                    control={formShipment.control}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Zipcode</FormLabel>
                        <FormControl>
                          <Input type="number" {...field} />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <Button variant={"freightBlue"} className="mt-4">
                Describe your shipment
              </Button>
            </form>
          </Form>
        </div>
        <div className="mt-10 ">
          <h1 className="text-lg font-semibold">Shipment</h1>
          <div className="bg-white shadow rounded p-4">
            <h1 className="font-semibold text-lg">#1</h1>
            <Form {...shipmentDetails}>
              <form onSubmit={shipmentDetails.handleSubmit(onSubmitShipment)}>
                <div className="flex items-center space-x-4">
                  <div>
                    <h1 className="text-lg font-semibold">Article's weight</h1>
                    <FormField
                      name="weightArticle"
                      control={shipmentDetails.control}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Article's Weight (kg)</FormLabel>
                          <FormControl>
                            <Input {...field} name="weightArticle" />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <h1 className="text-lg font-semibold">
                      Article's dimensions
                    </h1>
                    <div className="flex items-center space-x-2">
                      <FormField
                        control={shipmentDetails.control}
                        name="dimensionLongitude"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Longitud</FormLabel>
                            <FormControl>
                              <Input {...field} name="dimensionLongitude" />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <p className="text-lg font-medium">X</p>
                      <FormField
                        control={shipmentDetails.control}
                        name="dimensionWidth"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Width</FormLabel>
                            <FormControl>
                              <Input {...field} name="dimensionWidth" />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <p className="text-lg font-medium">X</p>
                      <FormField
                        control={shipmentDetails.control}
                        name="dimensionHeight"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Height</FormLabel>
                            <FormControl>
                              <Input {...field} name="dimensionHeight" />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <div>
                        <FormField
                          control={shipmentDetails.control}
                          name="articleQuantity"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Article's Quantity</FormLabel>
                              <FormControl>
                                <Input
                                  type="number"
                                  {...field}
                                  name="articleQuantity"
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </Form>
            <div className="mt-4 border-t pt-4">
              <h1 className=" text-sm font-semibold mb-4">
                ¿No está seguro de los tamaños?
              </h1>
            </div>
            <div className="flex items-start space-x-2">
              {shipmentOptions.map((shipment) => (
                <div
                  onClick={() => {
                    setSelectedPackage(shipment);
                    handleChangePackage(shipment);
                  }}
                  className={cn(
                    "flex items-center relative space-x-2 hover:shadow-lg cursor-pointer transition border-2 w-60 rounded shadow bg-white",
                    selectedPackage.title === shipment.title &&
                      "border-2 border-freightBlueDark shadow-freightBlueDark"
                  )}
                >
                  <div
                    className={cn(
                      "h-0 w-0 rotate-[58deg] border-t-[68px] rounded-t border-r-freightBlueDark border-r-[50px] border-t-transparent border-b-[30px] border-b-transparent absolute -top-9 left-1",
                      selectedPackage.title !== shipment.title && "hidden"
                    )}
                  ></div>
                  <Check
                    className={cn(
                      "h-6 w-6 text-white absolute top-3 left-0",
                      selectedPackage.title !== shipment.title && "hidden"
                    )}
                  />
                  <div className="ml-10 flex items-center">
                    <img
                      className="h-20 w-20 object-contain ml-10"
                      src={shipment.image}
                      alt={shipment.title}
                    />
                    <div className="">
                      <h1 className="text-sm font-semibold">
                        {shipment.title}
                      </h1>
                      <h3 className="text-[13px] font-medium">
                        {shipment.dimensions}
                      </h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-4">
              <h1 className="font-medium">Peso total del envío: 20kg </h1>
              <div className="flex items-start justify-end">
                <Button className=" bg-freightBlueDark">
                  Obtain your budget
                </Button>
              </div>
            </div>
            {/* <FormField name="weightArticle" control={shipmentDetails.control} render={({field})=>(

              )} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookNow;
