import { zodResolver } from "@hookform/resolvers/zod";
import { Loader } from "lucide-react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import Header from "../components/header/Header";
import { Button } from "../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form";
import { Input } from "../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
//@ts-ignore
import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import ReactSelect from "react-select";
import Package from "src/assets/images/package.png";
import { useDebounce } from "use-debounce";
import { ALL_CITIES, BASE_URL } from "../api/config";
import {
  dimensionsInfo,
  petInfo,
  selectNormalAtom,
  selectPetDeliveryAtom,
  shippingInfo,
} from "../atoms/atoms";
import { Switch } from "../components/ui/switch";
import useLocalStorage from "../hooks/use-local-storage";
//@ts-ignore

const sendNowSchema = z.object({
  origin: z.string().min(1, "Select a country"),
  originCity: z.any(),
  originZipCode: z.string().min(1, "Zipcode"),
  destination: z.string().min(1, "Select a country"),
  destinationCity: z.any(),
  destinationZipCode: z.string().min(1, "Zipcode"),
  wantToDeliverPets: z.boolean(),
});

const packageDimensionsSchema = z.object({
  height: z.string().min(1),
  width: z.string().min(1),
  length: z.string().min(1),
  weight: z.string().min(1),
});

const petSchema = z.object({
  petType: z.string().min(1),
  petImage: z.any().optional(),
  petWeight: z.string().min(1),
  pet_has_box: z.boolean(),
});

const SendNow = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [countriesSelected, setCountriesSelected] =
    useLocalStorage("countries_instant");

  const [showSetDimensions, setShowSetDimensions] = useState(false);
  const [showSelectedDimensions, setShowSelectedDimensions] = useState(false);
  const [dimensionsDetails, setDimensionsDetails] = useState<any>();
  const [showDeliverPets, setShowDeliverPets] = useState(false);
  const [showSelectedPetDetails, setShowSelectedPetDetails] = useState(false);
  const [selectedPetDetails, setSelectedPetDetails] = useState<any>();
  const [petImage, setPetImage] = useState<any>();
  const [shippingData, setShippingData] = useAtom(shippingInfo);
  const [dimensionsData, setDimensionsData] = useAtom(dimensionsInfo);
  const [petData, setPetData] = useAtom(petInfo);
  const [searchCity, setSearchCity] = useState("");
  const [debouncedInputValue] = useDebounce(searchCity, 500);

  const [selectNormal, setSelectNormal] = useAtom(selectNormalAtom);
  const [selectPetDelivery, setSelectPetDelivery] = useAtom(
    selectPetDeliveryAtom
  );

  const getAllCities = async () => {
    const response = await fetch(
      `${BASE_URL}${ALL_CITIES}?search=${debouncedInputValue}`
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const { data: cities } = useQuery({
    queryKey: ["cities", debouncedInputValue],
    queryFn: getAllCities,
  });

  const sendNowDetails = useForm<z.infer<typeof sendNowSchema>>({
    resolver: zodResolver(sendNowSchema),
    defaultValues: {
      origin: "",
      originCity: "",
      destinationZipCode: "",
      originZipCode: "",
      wantToDeliverPets: false,
      destination: "",
      destinationCity: "",
    },
  });

  const petDetails = useForm<z.infer<typeof petSchema>>({
    resolver: zodResolver(petSchema),
    defaultValues: {
      pet_has_box: false,
      petImage: "",
      petType: "",
      petWeight: "",
    },
  });

  const packageDimensionsDetails = useForm<
    z.infer<typeof packageDimensionsSchema>
  >({
    resolver: zodResolver(packageDimensionsSchema),
    defaultValues: {
      height: "",
      length: "",
      width: "",
      weight: "",
    },
  });

  const handleSwitchChange = (checked: any) => {
    setSelectNormal(!checked); // When "checked", set selectNormal to false
    setSelectPetDelivery(checked); // When "checked", set selectPetDelivery to true
  };

  const onSubmitPets = async (values: z.infer<typeof petSchema>) => {
    console.log(values);
    setSelectedPetDetails({
      petWeight: values.petWeight,
      petType: values.petType,
      petHasBox: values.pet_has_box,
      petImage: values.petImage,
    });
    setShowSelectedPetDetails(true);
  };

  const onSubmitDimensions = async (
    values: z.infer<typeof packageDimensionsSchema>
  ) => {
    setDimensionsDetails({
      width: values.width,
      length: values.length,
      height: values.height,
      weight: values.weight,
    });
    setShowSelectedDimensions(true);
  };

  const saveInfo = () => {
    setShippingData(countriesSelected);
    setPetData({ ...selectedPetDetails, petForLocalImage: petImage });
    !sendNowDetails.getValues("wantToDeliverPets") &&
      setDimensionsData(dimensionsDetails);
  };

  const onSubmit = async (values: z.infer<typeof sendNowSchema>) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    setShowSetDimensions(true);
    setShowDeliverPets(true);
    setCountriesSelected({
      origin: values.origin,
      originCity: values.originCity.value,
      originZipcode: values.originZipCode,
      destination: values.destination,
      destinationCity: values.destinationCity.value,
      destinationZipcode: values.destinationZipCode,
    });
  };
  const optionsCities = cities?.data.data.map(
    (city: { name: string; zipcode: string }) => {
      return {
        value: city.name,
        label: `${city.name}, ${city.zipcode} `,
      };
    }
  );
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      display: "flex",
      width: "200px",
      height: "40px", // Match input height
      borderRadius: "0.375rem", // Rounded-md
      borderColor: state.isFocused ? "#3182ce" : "#cbd5e0", // Change on focus
      backgroundColor: "#fff", // bg-background from Input component
      padding: "0 0.75rem", // px-3
      fontSize: "0.875rem", // text-sm
      outline: "none",
      boxShadow: state.isFocused ? "0 0 0 2px #3182ce" : null, // focus-visible:ring-2
      "&:hover": {
        borderColor: "#3182ce", // Focus border color on hover
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#edf2f7" : "white", // Lighter background on hover
      color: "black",
      padding: "0.5rem", // Adjust padding
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: "0.375rem", // Same rounded-md style
      border: "1px solid #cbd5e0", // Similar border as input
      marginTop: "0.25rem", // margin to avoid overlap with the control
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#a0aec0", // Placeholder color similar to Input
      fontSize: "0.875rem", // Match text-sm
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#2d3748", // Text color
      fontSize: "0.875rem", // text-sm
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#3182ce", // Blue background for selected values
      borderRadius: "0.25rem", // rounded-md for multi-values
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: "white", // White text for selected values
      padding: "0.25rem", // small padding inside selected values
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: "white",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#2b6cb0", // Darker on hover
      },
    }),
  };

  useEffect(() => {
    if (sendNowDetails.getValues("originCity").value !== "") {
      sendNowDetails.setValue(
        "originZipCode",
        sendNowDetails.getValues("originCity").label?.split(",")[1]
      );
    }
  }, [sendNowDetails.getValues("originCity").value, sendNowDetails]);

  useEffect(() => {
    if (sendNowDetails.getValues("destinationCity").value !== "") {
      sendNowDetails.setValue(
        "destinationZipCode",
        sendNowDetails.getValues("destinationCity").label?.split(",")[1]
      );
    }
  }, [sendNowDetails.getValues("destinationCity").value, sendNowDetails]);
  return (
    <div className="bg-gray-50 h-full pb-10 overflow-x-hidden">
      <Header />
      <div className="flex items-center justify-between w-full border-b border-border p-3 mb-4 max-w-4xl mx-auto mt-10">
        <h1 className="text-4xl font-medium ">
          Send <span className="text-2xl font-medium">small packages</span>
        </h1>
        <img src={Package} className="h-14 w-14 object-contain" alt="package" />
      </div>
      <div className="relative flex items-start justify-center">
        <div className=" p-4 bg-white shadow mt-10 w-full max-w-4xl  ">
          <h1 className="text-lg font-semibold">Package Destination</h1>
          <button
            onClick={() => {
              console.log(selectPetDeliveryAtom);
              console.log(selectNormalAtom);
            }}
          ></button>
          <div className="flex  pr-2 pl-2 w-full py-10">
            {!showSetDimensions ? (
              <Form {...sendNowDetails}>
                <form
                  className="w-full p-6 bg-white "
                  onSubmit={sendNowDetails.handleSubmit(onSubmit)}
                >
                  <div className="space-y-6">
                    {/* From Section */}
                    <div>
                      <h2 className="text-lg font-semibold mb-4">From</h2>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="relative">
                          <Controller
                            name="origin"
                            control={sendNowDetails.control}
                            render={({ field, fieldState }) => (
                              <FormItem>
                                <FormLabel>Origin</FormLabel>
                                <Select
                                  onValueChange={(value) =>
                                    sendNowDetails.setValue("origin", value)
                                  }
                                  {...field}
                                >
                                  <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Select a Country" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value="Spain">Spain</SelectItem>
                                    <SelectItem value="Australia">
                                      Australia
                                    </SelectItem>
                                  </SelectContent>
                                </Select>
                                {fieldState.error && (
                                  <p className="text-xs text-red-500 absolute -bottom-5">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </FormItem>
                            )}
                          />
                        </div>

                        <div className="flex flex-col">
                          <label className="text-sm font-medium mb-2">
                            City
                          </label>
                          <Controller
                            name="originCity"
                            rules={{ required: "City selection is required" }}
                            control={sendNowDetails.control}
                            render={({ field, fieldState }) => (
                              <>
                                <ReactSelect
                                  {...field}
                                  placeholder="Select a city..."
                                  isSearchable
                                  options={optionsCities}
                                  styles={customStyles}
                                />
                                {fieldState.error && (
                                  <span className="text-red-500 text-xs">
                                    {fieldState.error.message}
                                  </span>
                                )}
                              </>
                            )}
                          />
                        </div>

                        <FormField
                          name="originZipCode"
                          control={sendNowDetails.control}
                          render={({ field, fieldState }) => (
                            <FormItem>
                              <FormLabel>Zip Code</FormLabel>
                              <FormControl>
                                <Input
                                  className="w-full"
                                  {...field}
                                  placeholder="Origin Zipcode"
                                />
                              </FormControl>
                              {fieldState.error && (
                                <p className="text-xs text-red-500 absolute -bottom-5">
                                  {fieldState.error.message}
                                </p>
                              )}
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>

                    {/* To Section */}
                    <div>
                      <h2 className="text-lg font-semibold mb-4">To</h2>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="relative">
                          <Controller
                            name="destination"
                            control={sendNowDetails.control}
                            render={({ field, fieldState }) => (
                              <FormItem>
                                <FormLabel>Destination</FormLabel>
                                <Select
                                  onValueChange={(value) =>
                                    sendNowDetails.setValue(
                                      "destination",
                                      value
                                    )
                                  }
                                  {...field}
                                >
                                  <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Select a Country" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value="Spain">Spain</SelectItem>
                                    <SelectItem value="Australia">
                                      Australia
                                    </SelectItem>
                                  </SelectContent>
                                </Select>
                                {fieldState.error && (
                                  <p className="text-xs text-red-500 absolute -bottom-5">
                                    {fieldState.error.message}
                                  </p>
                                )}
                              </FormItem>
                            )}
                          />
                        </div>

                        <div className="flex flex-col">
                          <label className="text-sm font-medium mb-2">
                            City
                          </label>
                          <Controller
                            name="destinationCity"
                            rules={{ required: "City selection is required" }}
                            control={sendNowDetails.control}
                            render={({ field, fieldState }) => (
                              <>
                                <ReactSelect
                                  {...field}
                                  placeholder="Select a city..."
                                  isSearchable
                                  options={optionsCities}
                                  styles={customStyles}
                                />
                                {fieldState.error && (
                                  <span className="text-red-500 text-xs">
                                    {fieldState.error.message}
                                  </span>
                                )}
                              </>
                            )}
                          />
                        </div>

                        <FormField
                          name="destinationZipCode"
                          control={sendNowDetails.control}
                          render={({ field, fieldState }) => (
                            <FormItem>
                              <FormLabel>Zip Code</FormLabel>
                              <FormControl>
                                <Input
                                  className="w-full"
                                  {...field}
                                  placeholder="Destination Zipcode"
                                />
                              </FormControl>
                              {fieldState.error && (
                                <p className="text-xs text-red-500 absolute -bottom-5">
                                  {fieldState.error.message}
                                </p>
                              )}
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>

                    {/* Pets Delivery Section */}
                    <div className="flex items-center space-x-2 mt-6">
                      <Controller
                        control={sendNowDetails.control}
                        name="wantToDeliverPets"
                        render={({ field }) => (
                          <div className="flex items-center space-x-2">
                            <Switch
                              checked={field.value}
                              onCheckedChange={(value) => {
                                field.onChange(value); // Update form state
                                handleSwitchChange(value); // Toggle the atom states
                              }}
                              color="#0022B6"
                            />
                            <h1 className="text-sm font-medium">Deliver Pet</h1>
                          </div>
                        )}
                      />
                    </div>

                    {/* Submit Button */}
                    <div className="flex justify-end mt-8">
                      <Button
                        type="submit"
                        disabled={loading}
                        className="bg-freightBlueDark text-white disabled:bg-gray-300 w-full md:w-60 py-2 rounded-md "
                        title="Describe your shipment"
                      >
                        <p className="text-center">Describe your shipment</p>
                      </Button>
                    </div>
                  </div>
                </form>
              </Form>
            ) : (
              <div className="p-6 bg-white border rounded-lg w-full">
                <div className="flex flex-col md:flex-row gap-6">
                  <div className="flex-1">
                    <h3 className="text-gray-500 font-medium mb-2 text-sm uppercase tracking-wide">
                      Origin
                    </h3>
                    <p className="text-lg font-semibold">
                      {countriesSelected.origin}, {countriesSelected.originCity}
                      , {countriesSelected.originZipcode}
                    </p>
                  </div>
                  <div className="flex-1">
                    <h3 className="text-gray-500 font-medium mb-2 text-sm uppercase tracking-wide">
                      Destination
                    </h3>
                    <p className="text-lg font-semibold">
                      {countriesSelected.destination},{" "}
                      {countriesSelected.destinationCity},{" "}
                      {countriesSelected.destinationZipcode}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          {showSetDimensions && (
            <div className="flex flex-col border-t pt-2">
              {!sendNowDetails.getValues("wantToDeliverPets") && (
                <div>
                  <h1 className="text-lg font-semibold pb-3">
                    Package Dimensions
                  </h1>

                  {showSelectedDimensions ? (
                    <div className="p-4 bg-white rounded-lg border">
                      <div className="flex items-start justify-between space-x-6">
                        {/* Dimensions Section */}
                        <div className="flex flex-wrap space-y-4 sm:space-y-0 sm:space-x-6">
                          <div className="flex flex-col w-32">
                            <h3 className="text-gray-500 text-sm font-medium mb-1">
                              Width:
                            </h3>
                            <h1 className="text-lg font-semibold">
                              {dimensionsDetails?.width} cm
                            </h1>
                          </div>
                          <div className="flex flex-col w-32">
                            <h3 className="text-gray-500 text-sm font-medium mb-1">
                              Length:
                            </h3>
                            <h1 className="text-lg font-semibold">
                              {dimensionsDetails?.length} cm
                            </h1>
                          </div>
                          <div className="flex flex-col w-32">
                            <h3 className="text-gray-500 text-sm font-medium mb-1">
                              Height:
                            </h3>
                            <h1 className="text-lg font-semibold">
                              {dimensionsDetails?.height} cm
                            </h1>
                          </div>
                          <div className="flex flex-col w-32">
                            <h3 className="text-gray-500 text-sm font-medium mb-1">
                              Weight:
                            </h3>
                            <h1 className="text-lg font-semibold">
                              {dimensionsDetails?.weight} kg
                            </h1>
                          </div>
                        </div>

                        {/* Image Section */}
                        <div className="relative flex items-center justify-center w-40">
                          <img
                            alt="package-dimensions"
                            src={require("src/assets/images/shoes-box.png")}
                            className="object-contain h-28 w-auto"
                          />
                          <div className="absolute top-0 right-0 p-2 bg-white bg-opacity-75 text-xs text-gray-700 rounded">
                            <p className="font-medium">
                              Width:{" "}
                              {Math.round(Number(dimensionsDetails?.width))} cm
                            </p>
                            <p className="font-medium">
                              Height:{" "}
                              {Math.round(Number(dimensionsDetails?.height))} cm
                            </p>
                            <p className="font-medium">
                              Length:{" "}
                              {Math.round(Number(dimensionsDetails?.length))} cm
                            </p>
                            <p className="mt-2 text-xs text-gray-500">
                              Weight: {dimensionsDetails?.weight} kg
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center space-x-4 w-full">
                      <Form {...packageDimensionsDetails}>
                        <form
                          className="w-full space-y-6"
                          onSubmit={packageDimensionsDetails.handleSubmit(
                            onSubmitDimensions
                          )}
                        >
                          <div className="flex flex-col space-y-6 w-full">
                            {/* Dimensions Inputs */}
                            <div className="flex flex-col space-y-4 w-full sm:flex-row sm:space-y-0 sm:space-x-4 items-center">
                              <FormField
                                name="height"
                                control={packageDimensionsDetails.control}
                                render={({ field, fieldState }) => (
                                  <FormItem className="w-full">
                                    <FormLabel>Height (cm)</FormLabel>
                                    <FormControl>
                                      <Input
                                        className="w-full sm:w-52"
                                        type="number"
                                        max={30}
                                        min={0}
                                        {...field}
                                        placeholder="Parcel Height"
                                      />
                                    </FormControl>
                                    {fieldState.error && (
                                      <p className="text-xs text-red-500 mt-1">
                                        {fieldState.error.message}
                                      </p>
                                    )}
                                  </FormItem>
                                )}
                              />
                              <FormField
                                name="width"
                                control={packageDimensionsDetails.control}
                                render={({ field, fieldState }) => (
                                  <FormItem className="w-full">
                                    <FormLabel>Width (cm)</FormLabel>
                                    <FormControl>
                                      <Input
                                        className="w-full sm:w-52"
                                        type="number"
                                        max={30}
                                        min={0}
                                        {...field}
                                        placeholder="Parcel Width"
                                      />
                                    </FormControl>

                                    {fieldState.error && (
                                      <p className="text-xs text-red-500 mt-1">
                                        {fieldState.error.message}
                                      </p>
                                    )}
                                  </FormItem>
                                )}
                              />
                              <FormField
                                name="length"
                                control={packageDimensionsDetails.control}
                                render={({ field, fieldState }) => (
                                  <FormItem className="w-full">
                                    <FormLabel>Length (cm)</FormLabel>
                                    <FormControl>
                                      <Input
                                        className="w-full sm:w-52"
                                        type="number"
                                        max={30}
                                        min={0}
                                        {...field}
                                        placeholder="Parcel Length"
                                      />
                                    </FormControl>
                                    {fieldState.error && (
                                      <p className="text-xs text-red-500 mt-1">
                                        {fieldState.error.message}
                                      </p>
                                    )}
                                  </FormItem>
                                )}
                              />
                            </div>

                            {/* Weight Input */}
                            <div>
                              <FormField
                                name="weight"
                                control={packageDimensionsDetails.control}
                                render={({ field, fieldState }) => (
                                  <FormItem>
                                    <FormLabel>Weight (kg)</FormLabel>
                                    <FormControl>
                                      <Input
                                        className="w-full sm:w-52"
                                        type="number"
                                        max={30}
                                        min={0}
                                        {...field}
                                        placeholder="Parcel Weight"
                                      />
                                    </FormControl>
                                    {fieldState.error && (
                                      <p className="text-xs text-red-500 mt-1">
                                        {fieldState.error.message}
                                      </p>
                                    )}
                                  </FormItem>
                                )}
                              />
                            </div>

                            {/* Submit Button */}
                            <div className="flex items-center justify-end">
                              <Button
                                type="submit"
                                disabled={
                                  !packageDimensionsDetails.getValues(
                                    "height"
                                  ) ||
                                  !packageDimensionsDetails.getValues(
                                    "width"
                                  ) ||
                                  !packageDimensionsDetails.getValues(
                                    "length"
                                  ) ||
                                  !packageDimensionsDetails.getValues("weight")
                                }
                                className="w-full sm:w-60"
                                variant={"freightGreen"}
                                title="Get Quote"
                              >
                                {loading ? (
                                  <p className="text-sm font-medium text-white">
                                    <Loader className="h-5 w-5 animate-spin" />
                                  </p>
                                ) : (
                                  "Get Estimated Quote"
                                )}
                              </Button>
                            </div>
                          </div>
                        </form>
                      </Form>
                    </div>
                  )}
                </div>
              )}

              {sendNowDetails.getValues("wantToDeliverPets") &&
                showDeliverPets && (
                  <div className="mt-5 border-t pt-2">
                    <h1 className="text-lg font-medium  pb-3">
                      Your Pet Details
                    </h1>
                    {showSelectedPetDetails ? (
                      <div className="flex items-center p-4 justify-between  bg-white rounded-lg border">
                        {/* Pet Details Section */}
                        <div className="flex flex-col space-y-6 sm:space-y-0 sm:space-x-8 sm:flex-row">
                          <div className="flex-1">
                            <h3 className="text-gray-500 font-semibold mb-2">
                              Pet Weight
                            </h3>
                            <h1 className="text-lg font-bold">
                              {selectedPetDetails.petWeight} kg
                            </h1>
                          </div>
                          <div className="flex-1">
                            <h3 className="text-gray-500 font-semibold mb-2">
                              Pet Type
                            </h3>
                            <h1 className="text-lg font-bold">
                              {selectedPetDetails.petType}
                            </h1>
                          </div>
                          <div className="flex-1">
                            <h3 className="text-gray-500 font-semibold mb-2">
                              Available Box
                            </h3>
                            <h1 className="text-lg font-bold">
                              {selectedPetDetails.petHasBox ? "Yes" : "No"}
                            </h1>
                          </div>
                        </div>

                        {/* Pet Image Section */}
                        {petImage && (
                          <div className="flex-shrink-0">
                            <img
                              alt="pet"
                              src={petImage}
                              className="h-20 w-20 object-cover rounded-lg shadow-sm"
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <Form {...petDetails}>
                        <form
                          onSubmit={petDetails.handleSubmit(onSubmitPets)}
                          className="py-4 bg-white "
                        >
                          <div className="flex flex-col space-y-6">
                            {/* Pet Details Section */}
                            <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6">
                              <Controller
                                name="petType"
                                control={petDetails.control}
                                render={({ field, fieldState }) => (
                                  <FormItem className="flex-1">
                                    <FormLabel>Pet Type</FormLabel>
                                    <Select
                                      onValueChange={(value) =>
                                        petDetails.setValue("petType", value)
                                      }
                                      {...field}
                                    >
                                      <SelectTrigger className="w-full sm:w-60">
                                        <SelectValue placeholder="Ex: Dog" />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectItem value="Cat">Cat</SelectItem>
                                        <SelectItem value="Dog">Dog</SelectItem>
                                      </SelectContent>
                                    </Select>
                                    {fieldState.error && (
                                      <p className="text-xs text-red-500 mt-1">
                                        {fieldState.error.message}
                                      </p>
                                    )}
                                  </FormItem>
                                )}
                              />

                              <Controller
                                name="petWeight"
                                control={petDetails.control}
                                render={({ field }) => (
                                  <FormItem className="flex-1">
                                    <FormLabel>Weight (kg)</FormLabel>
                                    <FormControl>
                                      <Input
                                        className="w-full"
                                        type="number"
                                        {...field}
                                        placeholder="Pet Weight"
                                      />
                                    </FormControl>
                                  </FormItem>
                                )}
                              />

                              <Controller
                                control={petDetails.control}
                                name="pet_has_box"
                                render={({ field }) => (
                                  <div className="flex items-center space-x-2">
                                    <Switch
                                      checked={field.value}
                                      onCheckedChange={field.onChange}
                                      color="#0022B6"
                                    />
                                    <h1 className="text-sm font-medium">
                                      Box Available
                                    </h1>
                                  </div>
                                )}
                              />
                            </div>

                            {/* Pet Image Section */}
                            <div className="flex flex-col space-y-4 sm:flex-row items-start sm:space-x-6">
                              <div className="flex-1">
                                <p className="text-sm font-medium mb-2">
                                  Pet Image
                                </p>
                                <Controller
                                  control={petDetails.control}
                                  name="petImage"
                                  render={({ field }) => (
                                    <div className="border border-dashed border-gray-300 rounded-lg p-6">
                                      <div className="text-center">
                                        <svg
                                          className="mx-auto h-12 w-12 text-gray-300"
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          aria-hidden="true"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                        <div className="mt-4 flex flex-col items-center text-sm text-gray-600">
                                          <label className="cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600">
                                            <span>Upload a file</span>
                                            <Input
                                              accept="image/png, image/jpeg, image/gif"
                                              {...field}
                                              value={field.value.fileName}
                                              type="file"
                                              onChange={(event: any) => {
                                                field.onChange(
                                                  event.target.files?.[0]
                                                );
                                                setPetImage(
                                                  URL?.createObjectURL(
                                                    event.target.files?.[0]
                                                  )
                                                );
                                              }}
                                              className="sr-only"
                                            />
                                          </label>
                                          <p className="mt-1">
                                            or drag and drop
                                          </p>
                                          <p className="text-xs text-gray-500">
                                            PNG, JPG, GIF up to 10MB
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                />
                              </div>

                              {petImage && (
                                <img
                                  src={petImage}
                                  className="h-full w-[40%] object-cover rounded-lg  "
                                  alt="pet"
                                />
                              )}
                            </div>

                            {/* Submit Button */}
                            <div className="flex justify-end mt-8">
                              <Button
                                type="submit"
                                disabled={
                                  !petDetails.getValues("petType") ||
                                  !petDetails.getValues("petWeight")
                                }
                                className="w-60"
                                variant="freightGreen"
                                title="Get Quote"
                              >
                                {loading ? (
                                  <p className="text-sm font-medium text-white">
                                    <Loader className="h-5 w-5 animate-spin" />
                                  </p>
                                ) : (
                                  <>Get Estimated Quote</>
                                )}
                              </Button>
                            </div>
                          </div>
                        </form>
                      </Form>
                    )}
                  </div>
                )}
              {(showSelectedDimensions || showSelectedPetDetails) && (
                <div className="mt-4 flex items-center space-x-2 w-60 ml-auto">
                  <Button
                    onClick={() => {
                      saveInfo();
                      navigate("/calculate-quote");
                    }}
                    className="w-60"
                    // disabled={!showSelectedDimensions}
                  >
                    Calculate Quote
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        {showSetDimensions && (
          <div className="px-4  ">
            <p className="text-lg font-medium mb-2">Resumen</p>
            <div className="p-4 bg-white rounded-lg border mt-2">
              <div className="">
                <h3 className="text-gray-500 font-medium mb-2 text-sm uppercase tracking-wide">
                  From
                </h3>
                <p className="text-sm font-semibold">
                  {countriesSelected.origin}, {countriesSelected.originCity},{" "}
                  {countriesSelected.originZipcode}
                </p>
              </div>
              <div className="mt-4">
                <h3 className="text-gray-500 font-medium mb-2 text-sm uppercase tracking-wide">
                  To
                </h3>
                <p className="text-sm font-semibold">
                  {countriesSelected.destination},{" "}
                  {countriesSelected.destinationCity},{" "}
                  {countriesSelected.destinationZipcode}
                </p>
              </div>
            </div>
            {dimensionsDetails?.weight !== undefined && (
              <div className="p-4 bg-white rounded-lg border mt-2">
                <div className="flex flex-col items-start ">
                  {/* Image Section */}
                  <p>Packages (1)</p>
                  <div className="relative flex items-center  mt-2">
                    <div className=" text-xs flex items-center space-x-2 text-gray-700 ">
                      <p className=" text-xs text-gray-500">
                        {dimensionsDetails?.weight} kg
                      </p>
                      <p className="font-medium">
                        {Math.round(Number(dimensionsDetails?.width))} x
                      </p>
                      <p className="font-medium">
                        {Math.round(Number(dimensionsDetails?.height))} x
                      </p>
                      <p className="font-medium">
                        {Math.round(Number(dimensionsDetails?.length))} cm
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SendNow;
