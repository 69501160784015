import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { Loader2, Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ATTACH_PAYMENT_METHOD,
  BASE_URL,
  CREATE_CUSTOMER,
  CUSTOMER_GETPROFILE_URL,
  CUSTOMER_PAYMENT_METHODS,
  INSTANT_FREIGHTS,
  PAYMENT_INTENT,
  USER_EXISTS,
} from "../api/config";
import { paymentWithCustomer } from "../atoms/atoms";
import Header from "../components/header/Header";
import PayInstantFreightCheckout from "../components/payment/PayInstantFreightCheckout";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import { cn } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";

const PayInstantFreight = () => {
  const { user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const params = useParams();
  const [customerId, setCustomerId] = useState<string>("");
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [createWithCustomer, setCreateWithCustomer] =
    useAtom(paymentWithCustomer);
  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const [secureQuote, setSecureQuote] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<any>();
  const [showMore, setShowMore] = useState(false);
  const [followUpPayment, setFollowUpPayment] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [selectedWithPaymentMethod, setSelectedWithPaymentMethod] =
    useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState("");

  const [showRegisteredCards, setShowRegisteredCards] = useState<any>();
  const [loadingUserExist, setLoadingUserExist] = useState(false);
  const [loadingPaymentWithCustomer, setLoadingPaymentWithCustomer] =
    useState(false);
  const [paymentMadeWithRegisteredCard, setPaymentMadeWithRegisteredCard] =
    useState(false);
  const { user_type } = userInfoUserStore((state) => state);

  useEffect(() => {
    setSelectedMethod(null);
    setSelectedWithPaymentMethod(false);
  }, []);

  const stripePromise = loadStripe(
    "pk_test_51QBHUHIci7ftBuwklK7hfOot7XAWPHu8UpfdwLBmAo697eX4pPtYeJXCrqpkoZzxerzUUqVvZ1VS1zy1d2IS5vXo00aD6le0UG"
  );

  //PROFILE
  const getProfile = async () => {
    const response = await fetch(`${BASE_URL}${CUSTOMER_GETPROFILE_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    }).then((res) => res.json());

    return response;
  };

  const { data: profile, refetch: refetchProfile } = useQuery<any>({
    queryKey: ["profile", user_id],
    queryFn: getProfile,
  });

  useEffect(() => {
    refetchProfile();
  }, []);

  //CREATE CUSTOMER

  const createCustomer = async () => {
    const response = await fetch(`${BASE_URL}${CREATE_CUSTOMER}`, {
      method: "POST",
      headers: {
        AccessToken: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        description: `${profile?.data.firstname} - ${profile?.data?.lastname} - ${user_type}`,
        email: profile?.data.Email,
        name: `${profile?.data.firstname} ${profile?.data?.lastname}`,
        phone: profile?.data.Mobile,
        user_id: user_id,
        shipping: {
          address: `c/ Daniel Balaciart 2`,
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Customer created successful!") {
          setCustomerId(data.data[0].id);
          setShowRegisteredCards(!showRegisteredCards);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  //USER EXIST

  const doesUserExist = async () => {
    const response = await fetch(`${BASE_URL}${USER_EXISTS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        email: profile?.data.Email,
        user_id: user_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "User is not registered in stripe.") {
          createCustomer();
        }
        if (data.data?.length > 0) {
          setCustomerId(data.data[0][0].id);
          setShowRegisteredCards(!showRegisteredCards);
          // createPaymentMethod(data.data[0][0].id);
        }
      })
      .finally(() => {
        setLoadingUserExist(false);
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  useEffect(() => {
    doesUserExist();
  }, [profile]);

  const getInstantFreights = async () => {
    const response = await fetch(
      `${BASE_URL}${INSTANT_FREIGHTS}?user_id=${user_id}&freight_id=${params.instantFreightId}&sort=ASC`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });
    return response;
  };

  const {
    data: instantFreights,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["instant_freights", params.id],
    queryFn: getInstantFreights,
  });

  //PAYMENT INTNENT WITH CUSTOMER ID
  const createPaymentNoCustomer = async () => {
    setLoadingPayment(true);

    const response = await fetch(`${BASE_URL}${PAYMENT_INTENT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        // amount: "10000",
        user_id: user_id,
        customer_id: customerId,
        description: `FS Instant Freight - ${instantFreights?.data[0][0].id}`,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Session creation went successful!") {
          setClientSecret(data.data[0].clientSecret);
          setCreateWithCustomer(false);
          setPaymentIntentId(data.data[0].paymentIntentId);
        }
      })
      .finally(() => {
        setLoadingPayment(false);
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  //PAYMENT INTENT
  const createPayment = async (customerId: string) => {
    setLoadingPaymentWithCustomer(true);
    const response = await fetch(`${BASE_URL}${PAYMENT_INTENT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        // amount: "999999",
        customer_id: customerId,
        payment_method_token: selectedMethod.id,
        user_id: user_id,
        description: `FS Instant Freight - ${instantFreights?.data[0][0].id}`,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Session creation went successful!") {
          setPaymentIntentId(data.data[0].paymentIntentId);
          setCreateWithCustomer(true);
          setPaymentMadeWithRegisteredCard(false);
          setClientSecret(data.data[0].clientSecret);
          setSelectedWithPaymentMethod(true);
          // stePaymentSuccessfulWithCard(true);
        }
      })
      .finally(() => {
        setLoadingPaymentWithCustomer(false);
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  //CUSTOMER PAYMENT METHODS

  const getCustomerPaymentMethods = async () => {
    const response = await fetch(
      `${BASE_URL}${CUSTOMER_PAYMENT_METHODS}?customer_id=${customerId}&user_id=${user_id}`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // This block will always run after the promise resolves or rejects
        setInitialLoading(false);
      });

    return response;
  };
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = useQuery(
    {
      queryKey: ["payment_methods", customerId],
      queryFn: getCustomerPaymentMethods,
      enabled: customerId !== "",
    }
  );
  const appearance = {
    theme: "stripe",
  };

  const options: any = {
    clientSecret,
    appearance,
  };

  //ATTACH PAYMENT METHODS
  const attachPaymentMethod = async (paymentMethodToken: string) => {
    const response = await fetch(`${BASE_URL}${ATTACH_PAYMENT_METHOD}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        customer_id: customerId,
        payment_method_id: paymentMethodToken,
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  if (clientSecret !== "") {
    return (
      <div className="bg-gray-50 h-screen">
        <Header />
        <div className="flex flex-col py-10 max-w-4xl mx-auto w-full h-[94vh] no-scrollbar">
          <h1 className="text-2xl font-medium text-gray-500 mt-10 mb-4">
            Pay Instant Freight
          </h1>
          <div className="bg-white p-4 border border-border">
            <Elements options={options} stripe={stripePromise}>
              <PayInstantFreightCheckout
                profile={profile?.data}
                instantFreight={instantFreights?.data[0][0]}
                customerId={customerId}
                clientSecret={clientSecret}
                paymentIntentId={paymentIntentId}
                attachPaymentMethod={attachPaymentMethod}
                selectedMethod={selectedMethod}
                selectedWithPaymentMethod={selectedWithPaymentMethod}
              />
            </Elements>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 h-screen">
      <Header />
      <div className="flex flex-col py-10 max-w-4xl mx-auto w-full h-[94vh] no-scrollbar">
        <h1 className="text-2xl font-medium text-gray-500 mt-10 mb-4">
          Pay Instant Freight
        </h1>
        <div className="bg-white shadow p-4">
          <div>
            <Button
              onClick={() => {
                setAddPaymentMethod(true);
                createPaymentNoCustomer();
                setSecureQuote(false);
              }}
              variant={"outline"}
              className="mb-4"
            >
              <Plus />
              Add Payment Method
            </Button>
          </div>

          {initialLoading ? (
            <div className="border py-2 rounded flex items-center cursor-pointer w-full justify-between px-4  bg-white">
              <div>
                <img
                  src={require("../assets/visa.png")}
                  className="h-10 w-10 object-contain rounded"
                  alt="visa"
                />
              </div>
              <div className="flex flex-col items-end">
                <Skeleton className="h-4 w-10" />
                <Skeleton className="h-4 w-10 mt-2" />
              </div>
            </div>
          ) : (
            <>
              {paymentMethods?.data?.length > 0 && (
                <div className="flex flex-col space-y-2">
                  <div
                    onClick={() => setSelectedMethod(paymentMethods?.data[0])}
                    className={cn(
                      "border py-2 rounded flex items-center cursor-pointer w-full justify-between px-4  bg-white",
                      selectedMethod?.id === paymentMethods?.data[0]?.id &&
                        "bg-gray-200 text-[#333]"
                    )}
                  >
                    <div>
                      <img
                        src={require("../assets/visa.png")}
                        className="h-10 w-10 object-contain rounded"
                        alt={`visa-${paymentMethods?.data[0].card.brand}`}
                      />
                    </div>
                    <div className="flex flex-col items-end">
                      <p className="text-sm">
                        *{paymentMethods?.data[0].card.last4}
                      </p>
                      <p className="text-xs font-medium text-gray-400">
                        {paymentMethods?.data[0].card.exp_month}/
                        {paymentMethods?.data[0].card.exp_year}
                      </p>
                    </div>
                  </div>
                  {!showMore && (
                    <h3
                      onClick={() => setShowMore(true)}
                      className="hover:underline text-freightBlueDark cursor-pointer text-sm font-medium text-right"
                    >
                      Show more
                    </h3>
                  )}
                </div>
              )}
              {followUpPayment && (
                <div className="flex flex-col items-start w-full border-t border-border">
                  <div className="pt-4 text-sm">
                    Are you sure you want continue with this card?
                  </div>
                  <div className="flex  items-start justify-between py-2 mt-4 mb-4 w-full bg-white rounded border-border border px-4">
                    <div className="flex  items-center space-x-2">
                      <h1 className="uppercase">
                        <img
                          src={require("../assets/visa.png")}
                          className="h-5 w-5 object-contain rounded"
                          alt={`visa-${selectedMethod.card.brand}`}
                        />
                      </h1>
                      <div className="flex items-center space-x-2">
                        <h1 className="text-sm font-medium">
                          *{selectedMethod.card.last4}
                        </h1>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <p className="text-sm font-medium">
                        {selectedMethod.card.exp_month} /{" "}
                        {selectedMethod.card.exp_year}
                      </p>
                    </div>
                  </div>

                  <Button
                    disabled={loadingPaymentWithCustomer}
                    variant={"freightBlue"}
                    onClick={() => {
                      createPayment(customerId);
                      setPaymentMadeWithRegisteredCard(true);
                    }}
                    className="justify-end ml-auto"
                  >
                    {!loadingPaymentWithCustomer ? (
                      <p className="text-sm font-medium">Continue</p>
                    ) : (
                      <Loader2 className="h-4 w-4 animate-spin" />
                    )}
                  </Button>
                </div>
              )}
              {!followUpPayment && addPaymentMethod ? (
                <></>
              ) : (
                <div className="flex flex-col items-start space-y-4 w-full mt-4">
                  {paymentMethods?.data?.length > 0 &&
                    !followUpPayment &&
                    selectedMethod?.customer !== "" && (
                      <Button
                        onClick={() => {
                          // createPayment(selectedMethod.customer);
                          setFollowUpPayment(true);
                        }}
                        className="justify-end items-end ml-auto"
                        variant={"freightBlue"}
                        disabled={!selectedMethod}
                      >
                        Pay with this card
                      </Button>
                    )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PayInstantFreight;
