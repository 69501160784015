import { create } from "zustand";

export type ModalType = "deleteStop";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  stopId: string;
  onOpen: (type: ModalType, stopId: string) => void;
  onClose: () => void;
}

export const useDeleteStop = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  stopId: "",
  onOpen: (type, stopId) => set({ isOpen: true, type, stopId }),
  onClose: () => set({ type: null, isOpen: false }),
}));
