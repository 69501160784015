import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2, PackageCheck } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { BASE_URL, FINISH_INSTANT_FREIGHT } from "src/api/config";
import { useComplteInstantFreight } from "src/hooks/use-complete-instant-freight";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { z } from "zod";
import { Button } from "../ui/button";
import { Dialog, DialogContent } from "../ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel } from "../ui/form";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "../ui/input-otp";

const completeInstantFreightSchema = z.object({
  otpNumber: z.any(),
});

interface ModalCompleteInstantFreightProps {
  refetch: () => void;
}

const ModalCompleteInstantFreight: React.FC<
  ModalCompleteInstantFreightProps
> = ({ refetch }) => {
  const { user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const modal = useComplteInstantFreight();
  const [loading, setLoading] = useState(false);

  const form = useForm<z.infer<typeof completeInstantFreightSchema>>({
    resolver: zodResolver(completeInstantFreightSchema),
    defaultValues: {
      otpNumber: "",
    },
  });

  const completeFinishPayment = async (otpNumber: string) => {
    setLoading(true);
    const response = await fetch(`${BASE_URL}${FINISH_INSTANT_FREIGHT}`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        otp: otpNumber,
        instant_freight_id: modal.freightId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Instant freight finished successfully") {
          toast.success(data.message);
          modal.onClose();
          refetch();
        } else {
          toast.error(data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

    return response;
  };

  const onSubmit = async (
    values: z.infer<typeof completeInstantFreightSchema>
  ) => {
    completeFinishPayment(values.otpNumber);
  };
  return (
    <Dialog open={modal.isOpen} onOpenChange={modal.onClose}>
      <DialogContent>
        {/* <DialogHeader> */}
        <div className="flex flex-col">
          <div className="flex flex-col items-center">
            <div className="h-20 w-20 border border-border rounded-full flex items-center justify-center">
              <PackageCheck className="h-10 w-10 text-statusConfirmed" />
            </div>
            <div className="mt-4 flex flex-col items-center pb-10">
              <h1 className="text-xl font-medium">Are you sure? </h1>
              <p className="text-lg">You want to complete this booking</p>
            </div>
          </div>
          <Form {...form}>
            <form
              className="w-full flex flex-col justify-center items-center"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <FormField
                control={form.control}
                name="otpNumber"
                render={({ field, fieldState }) => (
                  <>
                    <FormItem>
                      <FormControl className="">
                        <FormItem className="">
                          <FormLabel>Verify OTP Number</FormLabel>
                          <InputOTP {...field} maxLength={6}>
                            <InputOTPGroup>
                              <InputOTPSlot index={0} />
                              <InputOTPSlot index={1} />
                              <InputOTPSlot index={2} />
                            </InputOTPGroup>
                            <InputOTPSeparator />
                            <InputOTPGroup>
                              <InputOTPSlot index={3} />
                              <InputOTPSlot index={4} />
                              <InputOTPSlot index={5} />
                            </InputOTPGroup>
                          </InputOTP>
                          {/* <Input
                          {...field}
                          name="otpNumber"
                          className="w-full dark:bg-transparent"
                        /> */}
                        </FormItem>
                      </FormControl>
                    </FormItem>
                    {fieldState.error && (
                      <p className="text-sm font-medium text-red-500">
                        {fieldState.error.message}
                      </p>
                    )}
                  </>
                )}
              />
              {/* <div className="w-full justify-end flex space-x-2 mt-4"> */}
              {/* <Button onClick={() => modal.onClose()} variant={"outline"}>
                  Cancel
                </Button> */}
              <Button
                variant={"freightBlue"}
                className="mt-4 ml-auto justify-end"
              >
                {loading ? (
                  <>
                    <Loader2 className="h-5 w-5 animate-spin" />
                  </>
                ) : (
                  <> Complete</>
                )}
              </Button>
              {/* </div> */}
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalCompleteInstantFreight;
