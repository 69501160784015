import Lottie from "lottie-react";
import React, { useEffect, useRef, useState } from "react";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { BASE_URL, FREIGHTADD, GET_ALL_SIZES } from "../../api/config";
import loadeanimation from "../../assets/Animations/LoadingBox.json";
import small from "../../assets/images/books-box.png";
import large from "../../assets/images/moving-box.png";
import medium from "../../assets/images/shoes-box.png";
import { useTokenStoreStorage } from "../../store/useTokenStore";

import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ExpireOption, FormDataFreights, Size } from "src/types/types";
import { GOOGLE_API_KEY } from "../../api/config";
import FreightSummary from "./summaries/FreightSummary"; // Asegúrate de importar el nuevo componente

interface FreightGeneralProps {
  onFreightDetailsClick: () => void;
  onFreightSummaryClick: () => void;
  handleBackToGetFreight: () => void;
}

export const FreightGeneral: React.FC<FreightGeneralProps> = ({
  onFreightDetailsClick,
  onFreightSummaryClick,
  handleBackToGetFreight,
}) => {
  const navigate = useNavigate();

  const { user_id } = userInfoUserStore((state) => state);
  const [shipmentDetailsClicked, setShipmentDetailsClicked] = useState(false);
  const [distance, setDistance] = useState<string | null>(null);
  const [duration, setDuration] = useState<string | null>(null);

  const [loadingCreationRoute, setLoadingCreationRoute] = useState(false);
  const [sizes, setSizes] = useState<Size[]>([]);
  const [expireOptions, setExpireOptions] = useState<ExpireOption[]>([]);
  const [selectedSizeId, setSelectedSizeId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<FormDataFreights>({
    description: "",
    origin_address: "",
    origin_lat: null,
    origin_lng: null,
    destination_address: "",
    destination_lat: null,
    destination_lng: null,
    start_date: "",
    end_date: "",
    expire_date: "",
    freight_type: 1,
    origin_location_type: "",
    size_id: null,
    delivery_duration: "",
    delivery_distance: "",
  });

  const token = useTokenStoreStorage((state) => state.token);
  const originAutocompleteRef = useRef<google.maps.places.Autocomplete | null>(
    null
  );
  const destinationAutocompleteRef =
    useRef<google.maps.places.Autocomplete | null>(null);
  const originInputRef = useRef<HTMLInputElement>(null);
  const destinationInputRef = useRef<HTMLInputElement>(null);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });

  async function calculateRoute() {
    setLoadingCreationRoute(true);
    const directionsService = new google.maps.DirectionsService();

    const results = await directionsService.route({
      origin: {
        lat: Number(formData.origin_lat),
        lng: Number(formData.origin_lng),
      },
      destination: {
        lat: Number(formData.destination_lat),
        lng: Number(formData.destination_lng),
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    const leg = results.routes[0].legs[0];

    // Check if distance and duration exist
    if (leg.distance && leg.duration) {
      setDistance(leg.distance.text); // Set distance only if it exists
      setDuration(leg.duration.text); // Set duration only if it exists

      setFormData((prevData: any) => ({
        ...prevData,
        delivery_distance: leg.distance?.text || null, // Update delivery_distance
        delivery_duration: leg.duration?.text || null, // Update delivery_duration!
      }));
    }

    setTimeout(() => {
      setLoadingCreationRoute(false);
    }, 2000);
  }

  const getAllSizes = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}${GET_ALL_SIZES}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch sizes");
      }
      const data = await response.json();
      setSizes(data.data);
    } catch (error) {
      console.error("Error fetching sizes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllSizes();
  }, []);

  const getImageForSize = (name: string) => {
    switch (name) {
      case "small":
        return small;
      case "medium":
        return medium;
      case "large":
        return large;
      default:
        return null;
    }
  };

  const handleCardClick = (id: number) => {
    setSelectedSizeId(id);
    onFreightDetailsClick();
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePlaceSelect = (
    autocompleteRef: React.RefObject<google.maps.places.Autocomplete>,
    type: "origin" | "destination"
  ) => {
    const place = autocompleteRef.current?.getPlace();
    if (place && place.geometry && place.geometry.location) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const address = place.formatted_address || "";

      if (type === "origin") {
        setFormData((prevData) => ({
          ...prevData,
          origin_address: address,
          origin_lat: lat,
          origin_lng: lng,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          destination_address: address,
          destination_lat: lat,
          destination_lng: lng,
        }));
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Aquí no haremos el POST, simplemente cambiamos el estado para mostrar el resumen
    setShipmentDetailsClicked(true); // Cambia el estado para renderizar el FreightSummary
    onFreightSummaryClick();
    calculateRoute();
  };

  const handleCreateFreight = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        AccessToken: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id,
        data: {
          detail: {
            description: formData.description,
            start_date: formData.start_date,
            end_date: formData.end_date,
            expire_date: formData.expire_date,
            origin_address: formData.origin_address,
            origin_lat: formData.origin_lat,
            origin_lng: formData.origin_lng,
            destination_address: formData.destination_address,
            destination_lat: formData.destination_lat,
            destination_lng: formData.destination_lng,
            origin_location_type: formData.origin_location_type,
            freight_type: formData.freight_type,
            delivery_distance: formData.delivery_distance,
            delivery_duration: formData.delivery_duration,
            size_id: selectedSizeId, // Use the selected size ID
          },
        },
      }),
    };

    try {
      const response = await fetch(`${BASE_URL}${FREIGHTADD}`, requestOptions);
      if (!response.ok) {
        throw new Error("Failed to create freight");
      }
      const data = await response.json();
      toast.success("Freight created successfully!");
      navigate("/myFreight/active");
    } catch (error) {
      console.error("Error creating freight:", error);
      toast.error("Failed to create freight.");
    }
  };

  return (
    <div className="flex flex-col items-center p-8">
      {loading ? (
        <div className="flex justify-center items-center">
          <Lottie
            animationData={loadeanimation}
            className="h-[300px] w-[300px]"
          />
        </div>
      ) : (
        <>
          <button
            className="bg-gray-500 px-2 py-1 mb-3 rounded-md text-white hover:bg-gray-800"
            onClick={handleBackToGetFreight}
          >
            <div className="flex gap-2 items-center">
              <IoArrowBack />
              <p>Back to categories</p>
            </div>
          </button>
          <div className="flex flex-col gap-4">
            {shipmentDetailsClicked ? (
              <FreightSummary
                formData={{
                  ...formData,
                  size_id: selectedSizeId,
                  origin_lat: formData.origin_lat!,
                  origin_lng: formData.origin_lng!,
                  destination_lat: formData.destination_lat!,
                  destination_lng: formData.destination_lng!,
                }}
                onCreateFreight={handleCreateFreight}
                sizes={sizes}
                onEdit={() => setShipmentDetailsClicked(false)}
              />
            ) : (
              <div className="flex flex-col gap-4">
                <div className="flex gap-4">
                  {sizes.map((size) => (
                    <div
                      key={size.id}
                      onClick={() => handleCardClick(size.id)}
                      className={`flex items-center gap-4 border px-4 rounded-md bg-white cursor-pointer ${
                        selectedSizeId === size.id
                          ? "shadow-blue-500 shadow-lg"
                          : "shadow-md"
                      } hover:shadow-blue-300 hover:shadow-lg transition-shadow duration-300`}
                    >
                      <img
                        src={getImageForSize(size.name)}
                        alt={size.name}
                        className="h-28 w-28 object-contain"
                      />
                      <div>
                        <p className="font-bold flex gap-2 capitalize">
                          {size.name}
                        </p>
                        <p>
                          {size.length} cm x {size.width} cm x {size.height} cm
                        </p>
                        <p>{size.weight} kg</p>
                      </div>
                    </div>
                  ))}
                </div>

                {selectedSizeId && (
                  <div className="w-full">
                    <form
                      onSubmit={handleSubmit}
                      className="flex flex-col gap-4 mt-4 p-4 rounded-md w-full"
                    >
                      <h3 className="text-xl font-semibold">
                        Complete Freight Details
                      </h3>
                      <p className="font-semibold">Dates</p>
                      <div className="flex gap-4">
                        <div className="flex flex-column w-full">
                          <small>Available from</small>
                          <input
                            type="date"
                            name="start_date"
                            placeholder="Start Date"
                            value={formData.start_date}
                            onChange={handleInputChange}
                            onClick={(e) => e.currentTarget.showPicker()} // Opens the date picker
                            className="p-2 border rounded-md w-full"
                            required
                            min={new Date().toISOString().split("T")[0]} // Disable past dates
                            max={formData.end_date}
                          />
                        </div>
                        <div className="flex w-full flex-column">
                          <small>Available Until</small>
                          <input
                            type="date"
                            name="end_date"
                            placeholder="End Date"
                            value={formData.end_date}
                            onChange={handleInputChange}
                            onClick={(e) => e.currentTarget.showPicker()} // Opens the date picker
                            className="p-2 border rounded-md w-full"
                            required
                            disabled={formData.start_date === ""}
                            min={
                              formData.start_date ||
                              new Date().toISOString().split("T")[0]
                            } // Start from start_date or today's date
                          />
                        </div>
                      </div>
                      <p className="font-semibold">Address Information</p>
                      {isLoaded && (
                        <div className="flex flex-col gap-4">
                          <Autocomplete
                            onLoad={(autocomplete) =>
                              (originAutocompleteRef.current = autocomplete)
                            }
                            onPlaceChanged={() =>
                              handlePlaceSelect(originAutocompleteRef, "origin")
                            }
                          >
                            <input
                              ref={originInputRef}
                              type="text"
                              value={formData.origin_address}
                              onChange={(e) =>
                                setFormData((prevData) => ({
                                  ...prevData,
                                  origin_address: e.target.value,
                                  origin_lat: null,
                                  origin_lng: null,
                                }))
                              }
                              placeholder="Origin Address"
                              className="p-2 border rounded-md w-full"
                            />
                          </Autocomplete>
                          <Autocomplete
                            onLoad={(autocomplete) =>
                              (destinationAutocompleteRef.current =
                                autocomplete)
                            }
                            onPlaceChanged={() =>
                              handlePlaceSelect(
                                destinationAutocompleteRef,
                                "destination"
                              )
                            }
                          >
                            <input
                              ref={destinationInputRef}
                              type="text"
                              value={formData.destination_address}
                              onChange={(e) =>
                                setFormData((prevData) => ({
                                  ...prevData,
                                  destination_address: e.target.value,
                                  destination_lat: null,
                                  destination_lng: null,
                                }))
                              }
                              placeholder="Destination Address"
                              className="p-2 border rounded-md w-full"
                            />
                          </Autocomplete>

                          <input
                            type="text"
                            name="origin_location_type"
                            placeholder="Location Type (Building, Office, House...)"
                            value={formData.origin_location_type}
                            onChange={handleInputChange}
                            className="p-2 border rounded-md w-full"
                            required
                          />
                        </div>
                      )}
                      <p className="font-semibold">Additional Information</p>

                      <textarea
                        name="description"
                        placeholder="Description"
                        value={formData.description}
                        onChange={handleInputChange}
                        className="p-2 border rounded-md h-44 w-full resize-none"
                        maxLength={150}
                        required
                      />
                      <div className="w-full flex justify-end">
                        <button
                          type="submit"
                          className={`p-2 rounded-md w-[150px] ${
                            formData.origin_lat === null ||
                            formData.destination_lat === null
                              ? "bg-gray-400 text-gray-200 cursor-not-allowed"
                              : "bg-[#1889ca] text-white"
                          }`}
                          disabled={
                            formData.origin_lat === null ||
                            formData.destination_lat === null
                          }
                        >
                          Next
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
