import { useEffect, useRef, useState } from "react";
import SubcategoryFilter from "./SubcategoryFilter";

interface CategoriesFilterSubProps {
  category: {
    id: string;
    checked: boolean;
    catname: string;
    sub: {
      checked: boolean;
      id: string;
      catname: string;
    }[];
  };

  handleCategoryChange: (id: string) => void;
  onFilterSubChange: any;
  checkedSubcategoriesSub: { catname: string; id: string; checked: boolean }[];
}

const CategoriesFilterSub: React.FC<CategoriesFilterSubProps> = ({
  category,
  handleCategoryChange,
  onFilterSubChange,
  checkedSubcategoriesSub,
}) => {
  const [showSubcategories, setShowSubCategories] = useState(false);
  const parent = useRef(null);
  const [checkedSubcategories, setCheckedSubcategories] = useState<
    {
      id: string;
      checked: boolean;
      catname: string;
    }[]
  >([]);
  useEffect(() => {
    setCheckedSubcategories(category.sub?.map((category) => category));
  }, []);

  const handleSubcategoryChange = (id: any) => {
    setCheckedSubcategories((prevCheckSubcategories: any) =>
      prevCheckSubcategories.map((subcategory: any) =>
        subcategory.id === id
          ? { ...subcategory, checked: !subcategory.checked }
          : subcategory
      )
    );
  };

  useEffect(() => {
    onFilterSubChange(checkedSubcategories);
  }, [checkedSubcategories]);

  return (
    <li ref={parent} className="flex flex-col space-y-2 py-1" key={category.id}>
      <div className="flex items-start space-x-2 py-1">
        <input
          type="checkbox"
          id={category.id}
          checked={category.checked}
          onChange={() => {
            handleCategoryChange(category.id);

            if (category.checked) {
              setShowSubCategories(false);
            } else {
              setShowSubCategories(true);
            }
          }}
        />
        &nbsp;
        <p className="text-sm font-medium">{category.catname}</p>
      </div>
      {showSubcategories &&
        category.sub?.map((subcat: any) => (
          <SubcategoryFilter
            subcat={subcat}
            handleSubcategoryChange={handleSubcategoryChange}
            checkedSubcategories={checkedSubcategoriesSub}
          />
        ))}
    </li>
  );
};

export default CategoriesFilterSub;
