import { useQuery } from "@tanstack/react-query";
import { ChatData } from "../types/types";
import {
  BASE_URL,
  GET_CHATS_QUOTE,
  GET_CHATS_USER,
  SAVE_CHATS,
} from "../api/config";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { cn } from "../lib/utils";
import { Form, FormControl, FormField, FormItem } from "../components/ui/form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Loader2, Send, User } from "lucide-react";
import { useState } from "react";
import toast from "react-hot-toast";

interface ConversationProps {
  selectedChat: ChatData;
  refetch: () => void;
}
const formSendMessageSchema = z.object({
  message: z.string().min(1),
});

const Conversation: React.FC<ConversationProps> = ({
  selectedChat,
  refetch,
}) => {
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const [loading, setLoading] = useState(false);
  const getChatsByUser = async () => {
    const response = await fetch(
      `${BASE_URL}${GET_CHATS_QUOTE}?user_id=${user_id}&quote_id=${selectedChat.quote_id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const { data: chatsUser, refetch: refetchChatQuote } = useQuery({
    queryKey: ["chats_quote", selectedChat.id],
    queryFn: getChatsByUser,
  });

  const formSendMessage = useForm<z.infer<typeof formSendMessageSchema>>({
    resolver: zodResolver(formSendMessageSchema),
    defaultValues: {
      message: "",
    },
  });
  const onSubmit = async (values: z.infer<typeof formSendMessageSchema>) => {
    setLoading(true);
    const response = await fetch(
      `${BASE_URL}${SAVE_CHATS}?user_id=${user_id}&quote_id=${
        selectedChat.quote_id
      }&sender_id=${user_id}&reciever_id=${
        Number(user_id) === selectedChat.sender_id
          ? selectedChat.reciever_id
          : selectedChat.sender_id
      }&freight_id=${selectedChat.freight_id}&message=${values.message}`,
      {
        method: "POST",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Message saved successfully.") {
          toast.success("Message sent!");
          refetchChatQuote();
          refetch();
          formSendMessage.reset({
            message: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

    return response;
  };

  return (
    <>
      <div className="h-[60vh] overflow-y-auto ">
        <div className="flex flex-col h-full">
          <div className="flex-1 h-full overflow-auto flex flex-col-reverse pb-10">
            {chatsUser?.data.chat_data.map((chatMessage: ChatData) => (
              <div className="flex flex-col w-full">
                {chatMessage.reciever_id === Number(user_id) && (
                  <div className="ml-2 flex items-start mt-10 mr-auto">
                    <div className="bg-gray-100 h-6 w-6 rounded-full flex items-center justify-center ml-2">
                      <User className="h-3 w-3 text-gray-400" />
                    </div>
                    <div className="flex items-center  mr-auto ml-2">
                      <div
                        className={cn(
                          "text-sm text-white px-2 py-2 rounded-tr rounded-br rounded-bl bg-gray-50"
                        )}
                      >
                        <div className="flex items-center space-x-2">
                          <h1 className="text-sm text-[#333] font-semibold">
                            {chatMessage.sender_id}
                          </h1>
                          <p className="text-xs">{chatMessage.time}</p>
                        </div>
                        <h3 className="text-[#333]">{chatMessage.message}</h3>
                      </div>
                    </div>
                  </div>
                )}
                {chatMessage.sender_id === Number(user_id) && (
                  <div className="mr-2 flex items-start mt-10 ml-auto justify-end  ">
                    <div className="flex items-center mr-2">
                      <div
                        className={cn(
                          "text-sm text-white px-2 py-2 rounded-tl rounded-bl rounded-br bg-freightBlue/10"
                        )}
                      >
                        <div className="flex items-center space-x-2">
                          <h1 className="text-sm text-[#333] font-semibold">
                            {user_id}
                          </h1>
                          <p className="text-xs text-gray-500">
                            {chatMessage.time}
                          </p>
                        </div>
                        <h3 className="text-[#333]">{chatMessage.message}</h3>
                      </div>
                    </div>
                    <div className="bg-gray-100 h-6 w-6 rounded-full flex items-center justify-center mr-2">
                      <User className="h-3 w-3 text-gray-400" />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Form {...formSendMessage}>
        <form
          onSubmit={formSendMessage.handleSubmit(onSubmit)}
          className="w-full  px-4 py-2 bg-gray-50"
        >
          <div className="flex items-center w-full  justify-between ">
            <FormField
              control={formSendMessage.control}
              name="message"
              render={({ field, fieldState }) => (
                <>
                  <FormItem className="w-full">
                    <FormControl>
                      <Input
                        placeholder="Type message..."
                        className="w-full flex-grow"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                </>
              )}
            />
            <Button
              disabled={loading}
              variant={"freightBlue"}
              className="w-28 ml-4"
            >
              {loading ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin" />
                </>
              ) : (
                <>
                  <Send className="h-4 w-4 mr-2" />
                  <p>Send</p>
                </>
              )}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

export default Conversation;
