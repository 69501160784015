import { userInfoUserStore } from "src/store/useUserInfoStore";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useCompleteBooking } from "src/hooks/use-complete-booking";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { Button } from "../ui/button";
import {
  BASE_URL,
  EMAIL_OTP,
  JOB_COMPLETE,
  REGISTER_FREIGHT_PAYMENT,
} from "src/api/config";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { useCompletePayment } from "src/hooks/use-complete-payment";
import { Check, Loader2 } from "lucide-react";

interface CheckoutCompletePaymentProps {
  customerId: any;
  createPaymentMethod: (payment_method_token: string) => void;
  clientSecret: string;
  createWithCustomer: boolean;
  selectedMethod: any;
  paymentIntentId: string;
  refetch: () => void;
}

const CheckoutCompletePayment: React.FC<CheckoutCompletePaymentProps> = ({
  clientSecret,
  createPaymentMethod,
  createWithCustomer,
  customerId,
  selectedMethod,
  paymentIntentId,
  refetch,
}) => {
  const { email, name, mobile, user_type, user_id } = userInfoUserStore(
    (state) => state
  );
  const modalCompletePayment = useCompletePayment();
  const token = useTokenStoreStorage((state) => state.token);
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");
  const [createdWithCustomer, setCreatedWithCustomer] = useState(false);
  const [showSuccessfulMessage, setShowSuccessfulMessage] = useState(false);
  const [hideInput, setHideInput] = useState(false);
  const [successfulPayment, setSuccessfulPayment] = useState(false);
  console.log("paymentIntentId", paymentIntentId);

  useEffect(() => {
    if (createWithCustomer && selectedMethod) {
      setCreatedWithCustomer(true);
    } else {
      setCreatedWithCustomer(false);
    }
  }, [createWithCustomer, selectedMethod]);

  const emailSendOtp = async () => {
    const response = await fetch(
      `${BASE_URL}${EMAIL_OTP}?user_id=${user_id}&freight_id=${modalCompletePayment.data?.freight_id}&email=${email}&firstname=${name}`,
      {
        method: "POST",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "OTP sended successfully.") {
          toast.success(data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const completeJob = async () => {
    const response = await fetch(`${BASE_URL}${JOB_COMPLETE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        booking_status: "complete",
        booking_id: modalCompletePayment.data?.booking_id,
        completed_by: "customer",
        job_completed: true,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Job completed successfully.") {
          modalCompletePayment.onClose();
          refetch();
        }
      });

    return response;
  };

  const registerFreightPayment = async () => {
    const response = await fetch(
      `${BASE_URL}${REGISTER_FREIGHT_PAYMENT}?user_id=${user_id}&freight_id=${modalCompletePayment.data?.freight_id}&freight_amount=${modalCompletePayment.data?.quote_amount}&booking_id=${modalCompletePayment.data?.booking_id}&stripe_freight_payment_id=${paymentIntentId}&stripe_customer_id=${customerId}&is_freight_amount_paid=1`,
      {
        method: "POST",
        headers: {
          "Content-Type": "applsication/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Freight payment registered successfully") {
          toast.success(data.message);
          completeJob();
        }
      });
    return response;
  };

  const retrievePayment = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    //@ts-ignore
    const result = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          email: email,
          name: name,
        },
      },
    });

    const response: any = await createPaymentMethod(result?.paymentMethod?.id!);

    if (response?.message === "Payment method associated successfully") {
      confirmPayment(response?.data.payment_method_id);
      setSuccessfulPayment(true);
    }
  };

  useEffect(() => {
    if (createdWithCustomer) {
      confirmPayment(selectedMethod.id);
    }
  }, [createdWithCustomer]);

  const confirmPayment = async (paymentMethod: string) => {
    if (!stripe) return;

    const { error, paymentIntent } = await stripe.confirmPayment({
      clientSecret: clientSecret,
      confirmParams: {
        payment_method: paymentMethod,

        return_url: "http://localhost:3000/dashboard-customer",
      },
      redirect: "if_required",
    });
    if (paymentIntent?.status === "succeeded") {
      toast.success(`Payment successful`);
      modalCompletePayment.onClose();
      registerFreightPayment();
      setShowSuccessfulMessage(true);
      //   bookingAccept();
      // confirmPaymentIntent(paymentIntent.id);
    }

    if (error?.type === "card_error" || error?.type === "validation_error") {
      toast.error(`${error.message}`);
    } else {
      setMessage("An unexpected error occured");
    }
  };
  if (successfulPayment && !showSuccessfulMessage) {
    return (
      <div className="flex flex-col space-y-2 ">
        <p className="text-lg font-medium">Fulfilling order</p>

        <Loader2 className="h-10 w-10 animate-spin" />
      </div>
    );
  }
  if (successfulPayment && showSuccessfulMessage) {
    return (
      <div className="flex flex-col space-y-2 ">
        <p className="text-lg font-medium">Order successful!</p>

        <Check className="bg-green-500 p-2 text-white h-10 w-10 rounded-full" />
      </div>
    );
  }
  return (
    <form id="payment-form" onSubmit={retrievePayment}>
      {hideInput ? (
        <div>
          <p className="text-sm font-medium">Confirming payment</p>
          <Loader2 className="h-5 w-5 animate-spin" />
        </div>
      ) : (
        <>
          <h1 className="text-sm font-medium my-2">Enter your card details</h1>

          <div className="border-border border p-2 rounded">
            <CardElement />
          </div>
          <div className="mt-4 ml-auto justify-end w-full flex">
            <Button variant={"freightBlue"}>
              Pay {modalCompletePayment.data?.quote_amount}€
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export default CheckoutCompletePayment;
