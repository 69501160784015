import { useNavigate, useParams } from "react-router-dom";
import SidebarLayout from "../components/layout/SidebarLayout";
import { BASE_URL, BOOKING_QUOTE_CUSTOMER, GET_IMAGE } from "../api/config";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { useQuery } from "@tanstack/react-query";
import { ArrowLeft, Mail, MapPin, Phone, Settings } from "lucide-react";
import ModalSendMessage from "../components/modal/ModalSendMessage";
import { Skeleton } from "../components/skeleton/Skeleton";
import { useSendMessage } from "../hooks/use-send-message";
import { Button } from "../components/ui/button";
import medium from "../assets/images/shoes-box.png";

import { CustomerQuoteType } from "../types/types";

//@ts-ignore
import { ReactComponent as EmptySvg } from "../assets/images/data-not-found.svg";
import { useCompletePayment } from "../hooks/use-complete-payment";
import ModalCompletePayment from "../components/modal/ModalCompletePayment";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheets";

const ViewCustomerQuote = () => {
  const params = useParams();
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const navigate = useNavigate();
  const modalCompletePayment = useCompletePayment();
  const modalSendMessage = useSendMessage();

  const getQuotes = async () => {
    const response = await fetch(`${BASE_URL}${BOOKING_QUOTE_CUSTOMER}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id.toString(),
        quote_id: params.quoteId,
      }),
    }).then((res) => res.json());

    return response;
  };

  const {
    data: quotes,
    isLoading,
    refetch,
  } = useQuery<any>({
    queryKey: ["quotes", params.quoteId],
    queryFn: getQuotes,
  });

  return (
    <SidebarLayout>
      <div className="flex items-center justify-between">
        <div
          onClick={() => {
            navigate(`/customer/freightDetails/${params.freightId}`);
          }}
          className="flex items-center space-x-4  py-4"
        >
          <ArrowLeft className="h-4 w-4 text-freightBlueDark cursor-pointer" />
          <h1 className="text-sm hover:underline text-freightBlueDark  cursor-pointer">
            Back
          </h1>
        </div>
        <p className="text-sm font-medium text-gray-500 capitalize bg-white rounded px-4 py-2">
          {quotes?.data?.[0].status}
        </p>
      </div>

      {isLoading ? (
        <div className="flex items-start space-x-4 bg-white m-4 p-4 border-border border justify-between">
          <div className="flex flex-col items-start bg-gray-50 rounded border p-4">
            <Skeleton className="h-5 w-20" />
            <Skeleton className="h-5 w-28 mt-4" />
            <Skeleton className="h-5 w-28 mt-4" />
          </div>
          <div className="flex flex-col items-start">
            <Skeleton className="h-5 w-20" />
            <Skeleton className="h-5 w-40 mt-4" />
            <Skeleton className="h-5 w-40 mt-4" />
          </div>
          <Skeleton className="h-5 w-40" />
        </div>
      ) : (
        <div className="flex flex-wrap">
          {quotes?.message === "No records were found." ? (
            <div className="flex flex-col items-center w-full mx-auto space-y-4 justify-center py-3 bg-white shadow">
              <EmptySvg />
              <p className="text-sm font-medium">No quotes found</p>
            </div>
          ) : (
            <div className="flex items-start space-x-4 bg-white m-4 p-4 border-border border justify-between">
              {quotes?.data.map((quote: CustomerQuoteType) => (
                <div className="sm:flex flex-col space-y-2 items-start  w-full relative">
                  <Sheet>
                    <SheetTrigger asChild>
                      <Button
                        variant="outline"
                        className="ml-auto"
                        // className="ml-auto absolute top-2 right-2"
                      >
                        <Settings className="h-4 w-4 m-2" />
                        Settings
                      </Button>
                    </SheetTrigger>
                    <SheetContent className="flex flex-col justify-between">
                      <SheetHeader>
                        <SheetTitle>Booking Settings</SheetTitle>
                        <SheetDescription>
                          Make changes to your booking here.
                        </SheetDescription>
                      </SheetHeader>

                      <SheetFooter>
                        <div className="grid grid-cols-4 items-center gap-4">
                          <Button className="col-span-1" variant={"outline"}>
                            <Phone className="h-4 w-4 " />
                          </Button>

                          <Button
                            onClick={() =>
                              modalSendMessage.onOpen("sendMessage", {
                                quoteId: quote.quote_id,
                                customerId: quote.customerId,
                                carrierId: quote.accepted_carr_id,
                                receiverId: Number(user_id),
                                senderId: quote.accepted_carr_id,
                                freightId: Number(quote.freight_id),
                                transporterImage: quote.transporter_img,
                                transporterName: quote.transporter_name,
                              })
                            }
                            variant={"outline"}
                            className="col-span-3"
                          >
                            <Mail className="h-4 w-4 mr-2" />
                            <p>Send Message</p>
                          </Button>
                        </div>
                      </SheetFooter>
                    </SheetContent>
                  </Sheet>
                  <div className="flex-col items-start sm:space-y-2 w-full flex-grow">
                    <div className="bg-gray-50  rounded border p-4  md:flex flex-row md:w-[1000px] items-start space-y-4">
                      <div className="items-center w-full space-x-10">
                        <div className="flex flex-col items-start">
                          <h1 className="text-sm font-medium">Freight</h1>
                          <p
                            onClick={() =>
                              navigate(
                                `/freight/freightDetails/${quote.freight_id}`
                              )
                            }
                            className="hover:underline text-freightBlueDark cursor-pointer"
                          >
                            {quote.title}
                          </p>
                          <div className="flex flex-col items-start space-y-1 mt-4">
                            <p className="font-medium text-sm">Pickup date</p>{" "}
                            <p className="text-sm font-medium text-gray-500">
                              {quote.pickup_date1}
                            </p>
                          </div>
                          <div className="flex flex-col items-start space-y-1">
                            <p className="font-medium text-sm">Dropoff date</p>
                            <p className="text-sm font-medium text-gray-500">
                              {quote.dropoff_date1}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="items-center w-full space-x-10">
                        <div className="flex flex-col items-start">
                          <div className="flex items-center space-x-2">
                            <div className="w-6">
                              <MapPin className="text-green-500 h-5 w-5" />
                            </div>
                            <div className="">
                              <p className="text-sm font-medium">Origin</p>
                              <p className="text-sm font-medium text-gray-500">
                                {quote.origin_address}
                              </p>
                            </div>{" "}
                          </div>
                          <div className="flex items-center space-x-2">
                            <div className="w-6">
                              <MapPin className="text-freightBlueDark h-5 w-5" />
                            </div>

                            <div className="">
                              <p className="text-sm font-medium">Destination</p>{" "}
                              <p className="text-sm font-medium text-gray-500">
                                {quote.destination_address}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        {quote.freightImages.length > 0 ? (
                          <>
                            {quote.freightImages.map(
                              (freightImage: { freight_img: string }) => (
                                <img
                                  src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${freightImage.freight_img}&image_type=freight`}
                                  className="w-full object-contain rounded"
                                  alt="freightImage"
                                />
                              )
                            )}
                          </>
                        ) : (
                          <div className="flex items-center justify-center w-40 h-40 rounded-lg shadow-sm">
                            {quote.title === "Normal package freight" && (
                              <img
                                src={medium}
                                className="h-22 w-22 object-contain cursor-pointer"
                                alt="medium package"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col items-start sm:min-w-80 bg-gray-50 border-border border rounded ">
                      <div className="  p-4 flex items-center justify-between w-full">
                        <h1 className="text-sm font-medium">Amount</h1>
                        <p className="text-sm font-medium text-gray-500 bg-white p-2 rounded">
                          {quote.amount}€
                        </p>
                      </div>

                      <div className="  p-4 flex items-center justify-between w-full">
                        <h1 className="text-sm font-medium ">Expires in</h1>
                        <p className="text-sm font-medium text-gray-500 bg-white p-2 rounded">
                          {quote.expire_date}
                        </p>
                      </div>
                    </div>
                    {/* <div className=" absolute left-[91%] -top-20"> */}
                  </div>
                  {/* </div> */}
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {/* {modalCancelQuote.isOpen && <ModalCancelQuote refetch={refetch} />} */}
      {modalSendMessage.isOpen && <ModalSendMessage />}
      {modalCompletePayment.isOpen && (
        <ModalCompletePayment refetch={refetch} />
      )}
    </SidebarLayout>
  );
};

export default ViewCustomerQuote;
