//@ts-ignore
//@ts-ignore
import movil from "../../assets/images/iPhone.png";
//@ts-ignore
import androidstore from "../../assets/images/androidstore.png";
//@ts-ignore
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import applestore from "../../assets/images/applestore.png";

export const TransportersCard = () => {
  const navigate = useNavigate();
  const isScreenWideEnough = useMediaQuery("(min-width: 700px)");

  return (
    <div className=" flex bg-opacity-100 rounded-xl h-[650px] pt-10 w-[70%] overflow-none">
      {/* Renderizar solo si el ancho de la pantalla es mayor que 700px */}
      {isScreenWideEnough && (
        <div
          className="w-2/3 h-[100%]"
          style={{
            backgroundImage: `url(${movil})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      )}
      <div>
        <h2 className="text-4xl font-bold text-white pt-20">GET THE APP</h2>
        <p className=" text-slate-300 font-bold text-4xl">AVAILABLE SOON</p>
        <div className="sm:flex gap-4">
          <img src={androidstore} className="w-60 mt-10 mb-10" alt="" />
          <img src={applestore} className="w-60 mt-10 mb-10" alt="" />
        </div>
        <div>
          <h2 className="text-3xl font-bold text-white">
            Or become a transporter
          </h2>
          <button
            // onClick={() => navigate("/register/carrier")}
            onClick={() => navigate("/preregister")}
            className="h-[40px] w-[200px] mt-4 bg-[#ff380f] shadow-lg hover:bg-[#f4775efb] hover:shadow-4xl text-white font-bold rounded-2xl transition duration-300 ease-in-out"
          >
            Distribute with us
          </button>
        </div>
      </div>
    </div>
  );
};
