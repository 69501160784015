import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, CUSTOMERBOOKINGSLIST } from "../api/config";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";

import { ArrowLeft, ArrowRight, Loader2 } from "lucide-react";
import { useState } from "react";
import CustomerBookingCard from "../components/card/CustomerBookingCard";
import SidebarLayout from "../components/layout/SidebarLayout";
import ModalCancelBookingQuote from "../components/modal/ModalCancelBookingQuote";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { useCancelBookingQuote } from "../hooks/use-cancel-booking-quote";
import { cn } from "../lib/utils";
import { BookingCustomerType } from "../types/types";

const CustomerBookings = () => {
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const modalCancelBookingQuote = useCancelBookingQuote();
  const params = useParams();
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<"ASC" | "DESC">("DESC");
  const [page, setPage] = useState(1);

  const getBookingsCustomer = async () => {
    const response = await fetch(
      `${BASE_URL}${CUSTOMERBOOKINGSLIST}?sort=${sortBy}&page=${page}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
        body: JSON.stringify({
          user_id: user_id,
          status: params.type,
          page: page,
          items_per_page: 5,
          sort: sortBy,
        }),
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });
    return response;
  };

  const { data: bookingCustomer, isLoading } = useQuery({
    queryKey: ["booking_customer", params.type, page, sortBy],
    queryFn: getBookingsCustomer,
  });

  const sortOptions = [
    {
      value: "ASC",
      label: "Ascendent",
    },
    {
      value: "DESC",
      label: "Descendent",
    },
  ];

  return (
    <SidebarLayout>
      <div className="">
        <div className="flex items-center justify-between w-full border-border border-b pb-3 space-x-2 mb-4">
          <h1 className="text-lg font-semibold">My Bookings</h1>
          <p className="bg-gray-100 px-4 py-2 capitalize text-sm font-medium">
            {params.type}
          </p>
        </div>
        {bookingCustomer?.message === "Freight not found" ? (
          <></>
        ) : (
          <>
            {bookingCustomer?.message !== "No records were found." && (
              <div className="mt-4 ml-auto w-full justify-end flex space-x-2 mb-4">
                <>
                  <div className="text-sm font-medium flex items-center space-x-1">
                    <h1>Total</h1>
                    {!isLoading ? (
                      <span>{bookingCustomer?.data?.total_items}</span>
                    ) : (
                      <span>
                        <Loader2 className="h-4 w-4 animate-spin" />
                      </span>
                    )}
                    <h1>Bookings</h1>
                  </div>
                </>
                <Select
                  onValueChange={(val: any) => {
                    setSortBy(val);
                  }}
                  value={sortBy}
                >
                  <SelectTrigger className="w-40 h-8">
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent>
                    {sortOptions.map((sort) => (
                      <SelectItem value={sort.value}>{sort.label}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Button
                  disabled={page === 1}
                  onClick={() => setPage(1)}
                  className={cn(
                    "h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                    page === 1 && "bg-[#333] text-white"
                  )}
                >
                  <p className="text-sm font-medium">1</p>
                </Button>
                <div
                  onClick={() => {
                    if (
                      page > 1
                      // page !== bookingCustomer?.data?.total_pages
                    ) {
                      setPage((page) => page - 1);
                    }
                  }}
                  className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
                >
                  <ArrowLeft className="h-4 w-4 text-[#333]" />
                </div>
                <div
                  onClick={() => {
                    if (page !== bookingCustomer?.data.total_pages) {
                      setPage((page) => page + 1);
                    }
                  }}
                  className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
                >
                  <ArrowRight className="h-4 w-4 text-[#333]" />
                </div>
                <Button
                  disabled={page === bookingCustomer?.data?.total_pages}
                  onClick={() => setPage(bookingCustomer?.data?.total_pages)}
                  className={cn(
                    "h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                    page === bookingCustomer?.data?.total_pages &&
                      "bg-[#333] text-white"
                  )}
                >
                  {isLoading ? (
                    <p>
                      <Loader2 className="h-5 w-5 animate-spin" />
                    </p>
                  ) : (
                    <p className="text-sm font-medium">
                      {bookingCustomer?.data?.total_pages}
                    </p>
                  )}
                </Button>
              </div>
            )}

            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Skeleton className="h-20 w-20 rounded" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-28 rounded" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-28 rounded" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-36 rounded" />
                  </TableCell>

                  <TableCell>
                    <Skeleton className="h-5 w-40 rounded" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-10 rounded" />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {bookingCustomer?.message === "No records were found." ? (
                  <p className="text-sm font-medium text-gray-500 p-4 bg-white border border-border rounded">
                    {bookingCustomer?.message}
                  </p>
                ) : (
                  <>
                    <Table className="border border-border bg-white shadow">
                      <TableHeader>
                        <TableRow>
                          <TableHead>Image</TableHead>
                          <TableHead>Freight</TableHead>
                          <TableHead>Quote Amount</TableHead>
                          <TableHead>Transporter</TableHead>
                          {/* <TableHead>Date Booked</TableHead> */}
                          <TableHead>Status</TableHead>
                          <TableHead>Actions</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {bookingCustomer?.data[0]?.map(
                          (booking: BookingCustomerType) => (
                            <CustomerBookingCard booking={booking} />
                          )
                        )}
                      </TableBody>
                    </Table>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>

      {modalCancelBookingQuote.isOpen && <ModalCancelBookingQuote />}
    </SidebarLayout>
  );
};

export default CustomerBookings;
