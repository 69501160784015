import { create } from "zustand";

export type ModalType = "cancelBookingQuote";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;

  bookingId: string;
  onOpen: (type: ModalType, bookingId: string) => void;
  onClose: () => void;
}

export const useCancelBookingQuote = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  bookingId: "",
  onOpen: (type, bookingId) => set({ isOpen: true, type, bookingId }),
  onClose: () => set({ type: null, isOpen: false }),
}));
