import { Loader2 } from "lucide-react";
import Header from "../components/header/Header";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PackageDeliverySuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/customer/trips");
    }, 3000);
  }, []);
  return (
    <div className="bg-gray-50 h-screen">
      <Header />
      <div className="flex flex-col py-10 max-w-4xl mx-auto w-full h-[94vh] no-scrollbar">
        <h1 className="text-2xl font-medium text-gray-500 mt-10">
          Trip Stop created
        </h1>
        <div className="bg-white shadow mt-10 p-4">
          <div className="flex items-center justify-center">
            <Loader2 className="h-10 w-10 animate-spin" />
            <h1 className="text-lg font-medium">Searching for drivers...</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageDeliverySuccess;
