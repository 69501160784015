import { Check, Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { BASE_URL, CREATE_INSTANT_FREIGHT } from "../api/config";
import Header from "../components/header/Header";
import { Button } from "../components/ui/button";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
//@ts-ignore
import SmallBox from "../assets/images/books-box.png";
//@ts-ignore
import MediumBox from "../assets/images/shoes-box.png";
//@ts-ignore
import { useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../api/config";
import LargeBox from "../assets/images/moving-box.png";
import { cn } from "../lib/utils";

const SelectionInstantFreights = () => {
  const valuesItem = localStorage.getItem("instant_item");
  const parsedValues = JSON.parse(valuesItem!);
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [distance, setDistance] = useState<any>();
  const [duration, setDuration] = useState<any>();
  const [successFullCreation, setSuccessfulCreation] = useState(false);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });

  const calculateRoute = async () => {
    const directionsService = new google.maps.DirectionsService();

    //@ts-ignore
    const results = await directionsService.route({
      origin: {
        lat: Number(parsedValues?.origin_lat),
        lng: Number(parsedValues?.origin_lng),
      },
      destination: {
        lat: Number(parsedValues?.destination_lat),
        lng: Number(parsedValues?.destination_lng),
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDistance(results.routes[0].legs[0].distance?.text);
    setDuration(results.routes[0].legs[0].duration?.text);
  };

  useEffect(() => {
    calculateRoute();
  }, []);
  const createInstantFreight = async () => {
    setLoading(true);
    const response = await fetch(`${BASE_URL}${CREATE_INSTANT_FREIGHT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        customer_id: user_id,
        origin_city: parsedValues?.origin_city,
        origin_address: parsedValues?.origin_address,
        origin_zipcode: parsedValues?.origin_zipcode,
        origin_lat: parsedValues?.origin_lat,
        origin_lng: parsedValues?.origin_lng,
        delivery_distance: distance,
        delivery_duration: duration,
        destination_lat: parsedValues?.destination_lat,
        destination_lng: parsedValues?.destination_lng,
        is_valuable: false,
        destination_city: parsedValues?.destination_city,
        destination_address: parsedValues?.destination_address,
        destination_zipcode: parsedValues?.destination_zipcode,
        package_size: parsedValues?.package_size,
        package_weight: parsedValues?.package_weight,
        estimated_price: parsedValues?.price_estimated,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Instant freight generated successfully") {
          toast.success("Freight created!");
          setSuccessfulCreation(true);
          setTimeout(() => {
            navigate("/my-instant-freights");
          }, 2000);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return response;
  };

  return (
    <div className="bg-gray-50 ">
      <Header />
      <div className="flex flex-col py-10 max-w-4xl mx-auto w-full h-[94vh] overflow-y-auto no-scrollbar">
        <div className=" w-full relative flex items-center justify-between ">
          <div className="w-full border-b h-1 absolute top-4 z-10" />
          {window.location.pathname === `/instant-freight` && (
            <div className="w-1/3 border-b h-1 absolute top-4 z-10 border-[#333]" />
          )}
          {window.location.pathname === `/instant-freight` && (
            <div className="w-2/3 border-b h-1 absolute top-4 z-10 border-[#333]" />
          )}
          {window.location.pathname === `/instant-freight` && (
            <div className="w-full border-b h-1 absolute top-4 z-10 border-[#333]" />
          )}
          <div className="flex flex-col z-20">
            <div
              className={cn(
                "bg-gray-100 z-20 h-10 w-10 rounded-full text-center items-center justify-center flex",

                window.location.pathname === `/instant-freight` &&
                  "bg-[#333] text-white"
              )}
            >
              <p className="text-sm font-medium">1</p>
            </div>
            <p
              className={cn(
                "text-xs w-20 mt-2 font-medium text-gray-500",
                window.location.pathname === `/instant-freight` && "text-[#333]"
              )}
            >
              Freight Details
            </p>
          </div>
          <div className="flex flex-col z-20 items-center">
            <div
              className={cn(
                "bg-gray-100 z-20 h-10 w-10 rounded-full text-center items-center justify-center flex",
                window.location.pathname === `/instant-freight` && "bg-[#333]"
              )}
            >
              <p
                className={cn(
                  "text-sm font-medium",

                  window.location.pathname === `/instant-freight` &&
                    "text-white"
                )}
              >
                2
              </p>
            </div>
            <p
              className={cn(
                "text-xs text-center w-20 mt-2 font-medium text-gray-500",
                window.location.pathname === `/instant-freight` && "text-[#333]"
              )}
            >
              Shipment Details
            </p>
          </div>
          <div className="flex flex-col z-20 items-end  ">
            <div
              className={cn(
                "bg-gray-100 z-20 h-10 w-10 rounded-full text-center items-center justify-center flex",
                window.location.pathname === `/instant-freight` && "bg-[#333]"
              )}
            >
              <p
                className={cn(
                  "text-sm font-medium",
                  window.location.pathname === `/instant-freight` &&
                    "text-white"
                )}
              >
                3
              </p>
            </div>
            <p
              className={cn(
                "text-xs w-20 mt-2  justify-end items-end text-right font-medium text-gray-500",
                window.location.pathname === `/instant-freight` && "text-[#333]"
              )}
            >
              Submit Freight
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between mt-10">
          <h1 className="text-2xl font-medium text-gray-500">
            Price estimated:{" "}
            {Number(parsedValues?.price_estimated ?? 0.0).toFixed(2)}€
          </h1>
        </div>
        <div className="max-w-4xl mx-auto  p-4 bg-white shadow mt-10 w-full flex flex-col">
          {/* <h1 className="text-2xl font-medium text-gray-600">
            Price estimated: {Math.round(parsedValues.price_estimated)}€
          </h1> */}
          <div className=" flex-col space-y-2 w-full">
            <h1 className="text-lg border-b mb-4 pb-2 text-gray-500 font-semibold">
              Details
            </h1>
            <div className="flex items-start w-full justify-between ">
              <div className="flex flex-col space-y-2 border border-border">
                <div className=" flex flex-col items-start justify-between w-40 p-2 rounded">
                  <p className="text-sm font-medium">Distance</p>
                  <span className="text-sm font-medium capitalize">
                    {parsedValues?.distance}
                  </span>
                </div>
                <div className="flex flex-col items-start justify-between w-40 p-2 rounded ">
                  <p className="text-sm font-medium">Weight </p>
                  <span className="text-sm font-medium capitalize">
                    {parsedValues?.package_weight}kg
                  </span>
                </div>
                <div className="flex flex-col items-start justify-between w-40 p-2 rounded">
                  <p className="text-sm font-medium">Dimensions </p>
                  <span className="capitalize text-sm font-medium">
                    {parsedValues?.package_size}
                  </span>
                </div>
              </div>
              <div>
                {parsedValues?.package_size === "small" && (
                  <div className="h-28 w-28 border border-border bg-white rounded ">
                    <img
                      src={SmallBox}
                      className="h-full w-full object-contain "
                      alt="large-box"
                    />
                  </div>
                )}
                {parsedValues?.package_size === "medium" && (
                  <div className="h-28 w-28 border border-border bg-white rounded">
                    <img
                      src={MediumBox}
                      className="h-full w-full object-contain "
                      alt="large-box"
                    />
                  </div>
                )}
                {parsedValues?.package_size === "large" && (
                  <div className="h-28 w-28 border border-border bg-white rounded">
                    <img
                      src={LargeBox}
                      className="h-full w-full object-contain "
                      alt="large-box"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <Button
              onClick={() => createInstantFreight()}
              variant={"freightBlue"}
              className={cn(
                " sm:ml-auto mt-4  w-60 transition flex items-center justify-center",
                successFullCreation && "bg-green-500"
              )}
            >
              {loading ? (
                <>
                  <Loader2 className="h-5 w-5 animate-spin" />
                </>
              ) : (
                <>
                  {successFullCreation ? (
                    <>
                      <Check className="bg-green-500 text-white rounded-full h-5 w-5" />
                    </>
                  ) : (
                    <p className="text-sm font-medium text-center">
                      Search drivers
                    </p>
                  )}
                </>
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectionInstantFreights;
