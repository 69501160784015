import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const userInfoUserStore = create(
  persist<{
    email: string;
    mobile: string;
    user_type: string;
    user_id: string;
    name: string;
    addUserInfo: (
      email: string,
      mobile: string,
      user_type: string,
      user_id: string,
      name: string
    ) => void;
  }>(
    (set) => ({
      email: "",
      mobile: "",
      user_type: "",
      user_id: "",
      name: "",
      addUserInfo: (
        email: string,
        mobile: string,
        user_type: string,
        user_id: string,
        name: string
      ) => set({ email, mobile, user_id, user_type, name }),
    }),
    {
      name: "info_user",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export { userInfoUserStore };
