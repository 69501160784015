import { OrdersEcom } from "@/src/types/types";
import { ChevronDown, ChevronRight } from "lucide-react";
import { useState } from "react";
import { FaLocationPin } from "react-icons/fa6";
import { useAssignOrder } from "src/hooks/use-assign-order";
import { cn } from "src/lib/utils";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { Button } from "../ui/button";

interface OrdersAllCardProps {
  order: OrdersEcom;
}

const OrdersAllCard: React.FC<OrdersAllCardProps> = ({ order }) => {
  const [showMoreItems, setShowMoreItems] = useState(false);
  const modalAssignOrder = useAssignOrder();
  const userInfo = userInfoUserStore((s) => s);

  return (
    <div className="bg-white p-4 rounded shadow mb-2">
      <div className=" ">
        {/* <p className="text-sm capitalize">{order.company}</p> */}
        <div className="flex flex-col items-start">
          <div className="flex items-center space-x-4">
            <div className="bg-green-500 text-white px-4 py-1 rounded-lg">
              {order.price}€
            </div>
            <div className=" flex items-center space-x-4">
              <div className="flex items-center space-x-2 text-gray-500">
                <FaLocationPin className="h-4 w-4 text-green-500" />
                <p className="text-sm text-[#333] font-medium">
                  {order.origin}
                </p>{" "}
                <ChevronRight className="h-4 w-4" />{" "}
                <FaLocationPin className="h-4 w-4 text-blue-500" />
                <p className="text-sm text-[#333] font-medium">
                  {order.destination}
                </p>
              </div>
            </div>
          </div>
          {order.state === 1 && order.carrier && (
            <div className="mt-4">
              <p className="text-sm font-medium">
                <span className="text-gray-500">Assigned to </span>
                <span className="capitalize">{order.carrier.username}</span>
              </p>
            </div>
          )}

          <div></div>
        </div>
        {userInfo.user_type !== "customer" && (
          <div className="flex items-end justify-end ml-auto absolute top-0 right-3">
            {order.state === 1 && (
              <Button className="bg-freightBlue text-white text-center px-4 py-1 rounded text-sm">
                In Transit
              </Button>
            )}
            {order.state === 0 && (
              <Button
                onClick={() =>
                  modalAssignOrder.onOpen(
                    "assignOrder",
                    order.id.toString(),
                    "0"
                  )
                }
                className="bg-freightOrange text-white text-center px-4 py-1 rounded text-sm"
              >
                Assign Delivery
              </Button>
            )}
            {order.state === 2 && (
              <div>
                <Button variant={"ghost"} className="bg-gray-200 rouunded">
                  <p className="text-sm text-[#333] font-medium">
                    <span>Completed</span>
                  </p>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="mt-4">
        {order.products.slice(0, 2).map((product) => (
          <div className="flex items-center justify-between mb-2 border-b py-2 px-4  bg-gray-50 rounded mt-2">
            <div className="flex flex-col items-start">
              <p className="text-sm font-medium capitalize">{product.name}</p>
              <img
                alt={product.name}
                src={product.image}
                className="h-10 w-20"
              />
              <p className="text-xs text-gray-500 mt-2">
                Quantity: {product.quantity}
              </p>
            </div>
            <div>
              <div className="relative h-14 w-24 ">
                <img
                  alt="package-dimensions"
                  src={require("../../assets/images/moving-box.png")}
                  className="h-full w-full"
                />
                <p className="text-[10px] absolute right-14 top-10 font-medium">
                  {Math.round(Number(product.width))}cm(w)
                </p>
                <p className="text-[10px] absolute right-20 top-4 font-medium">
                  {Math.round(Number(product.height))}cm(h)
                </p>
                <p className="text-[10px] absolute right-0 top-10 font-medium">
                  {Math.round(Number(product.length))}cm(l)
                </p>
              </div>
            </div>
          </div>
        ))}
        {order.products.length > 2 && (
          <button
            onClick={() => setShowMoreItems(!showMoreItems)}
            className="flex items-center space-x-3 border outline-none ring-0 rounded w-fit px-4 py-1 cursor-pointer hover:bg-gray-100"
          >
            <h3 className="text-sm font-semibold">View More</h3>
            <ChevronDown
              className={cn(
                "h-5 w-5",
                showMoreItems &&
                  "rotate-180 transition duration-200 ease-in-out"
              )}
            />
          </button>
        )}
        {showMoreItems && (
          <>
            {order.products.slice(2).map((product) => (
              <div className="flex items-center justify-between mb-2 border-b py-2 px-4  bg-gray-50 rounded mt-2">
                <div className="flex flex-col items-start">
                  <p className="text-sm font-medium capitalize">
                    {product.name}
                  </p>
                  <img
                    alt={product.name}
                    src={product.image}
                    className="h-10 w-20"
                  />
                  <p className="text-xs text-gray-500 mt-2">
                    Quantity: {product.quantity}
                  </p>
                </div>
                <div>
                  <div className="relative h-14 w-24 ">
                    <img
                      alt="package-dimensions"
                      src={require("../../assets/images/moving-box.png")}
                      className="h-full w-full"
                    />
                    <p className="text-[10px] absolute right-14 top-10 font-medium">
                      {Math.round(Number(product.width))}cm(w)
                    </p>
                    <p className="text-[10px] absolute right-20 top-4 font-medium">
                      {Math.round(Number(product.height))}cm(h)
                    </p>
                    <p className="text-[10px] absolute right-0 top-10 font-medium">
                      {Math.round(Number(product.length))}cm(l)
                    </p>
                  </div>
                  {/* <p className="text-sm text-gray-400">Dimensions</p>
                  <div className="flex space-x-2">
                    <p className="text-sm">{product.height}cm</p>
                    <span className="text-sm">x</span>
                    <p className="text-sm">{product.width}cm</p>
                    <span className="text-sm">x</span>

                    <p className="text-sm">{product.length}cm</p>
                  </div> */}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default OrdersAllCard;
