import { zodResolver } from "@hookform/resolvers/zod";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useQuery } from "@tanstack/react-query";
import { AlertCircle, Loader2, Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  ATTACH_PAYMENT_METHOD,
  BASE_URL,
  CREATE_CUSTOMER,
  CUSTOMER_PAYMENT_METHODS,
  PAYMENT_INTENT,
  PAYMENT_INTENT_INSTANT,
  PAYMENT_METHOD,
  USER_EXISTS,
} from "src/api/config";
import { usePayInstantFreight } from "src/hooks/use-pay-instant-freight";
import { cn } from "src/lib/utils";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { PaymentMethodType } from "src/types/types";
import { z } from "zod";
import CheckoutCompleteInstantPayment from "../payment/CheckoutCompleteInstantPayment";
import { Skeleton } from "../skeleton/Skeleton";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { Form } from "../ui/form";

interface ModalPayInstantFreightProps {
  refetch: () => void;
}

const formSchema = z.object({
  phoneNumber: z.string().min(1),
  termsConditions: z.boolean(),
});

const ModalPayInstantFreight: React.FC<ModalPayInstantFreightProps> = ({
  refetch,
}) => {
  const { user_id, email, mobile, name, user_type } = userInfoUserStore(
    (state) => state
  );
  const token = useTokenStoreStorage((state) => state.token);
  const modal = usePayInstantFreight();
  const [addPaymentMethod, setAddPaymentMethod] = useState(false);

  const [loadingPaymentWithCustomer, setLoadingPaymentWithCustomer] =
    useState(false);
  const [createWithCustomer, setCreateWithCustomer] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [showRegisteredCards, setShowRegisteredCards] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<any>();
  const [paymentMadeWithRegisteredCard, setPaymentMadeWithRegisteredCard] =
    useState(false);
  const [followUpPayment, setFollowUpPayment] = useState(false);
  const [noAddedPaymentMethods, setNoAddedPaymentMethods] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [paymentSuccessulWithCard, stePaymentSuccessfulWithCard] =
    useState(false);
  const [secureQuote, setSecureQuote] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [loadingUserExist, setLoadingUserExist] = useState(false);

  const stripePromise = loadStripe(
    "pk_test_51QBHUHIci7ftBuwklK7hfOot7XAWPHu8UpfdwLBmAo697eX4pPtYeJXCrqpkoZzxerzUUqVvZ1VS1zy1d2IS5vXo00aD6le0UG"
  );

  useEffect(() => {
    formAcceptQuote.reset({
      phoneNumber: mobile,
    });
  }, [mobile]);

  useEffect(() => {
    doesUserExist();
  }, []);

  useEffect(() => {
    stePaymentSuccessfulWithCard(false);
    setShowRegisteredCards(false);
  }, []);

  const formAcceptQuote = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      phoneNumber: "",
      termsConditions: false,
    },
  });

  const doesUserExist = async () => {
    const response = await fetch(`${BASE_URL}${USER_EXISTS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        email: email,
        user_id: user_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "User is not registered in stripe.") {
          createCustomer();
        }
        if (data.data?.length > 0) {
          setCustomerId(data.data[0][0].id);
          createPaymentMethod(data.data[0][0].id);
        } else {
        }
      })
      .finally(() => {
        setLoadingUserExist(false);
      });

    return response;
  };

  const createPaymentNoCustomer = async () => {
    setLoadingPayment(true);

    const response = await fetch(`${BASE_URL}${PAYMENT_INTENT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        // amount: "10000",
        user_id: user_id,
        customer_id: customerId,
        description: `FS Booking - ${modal.freightId}`,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Session creation went successful!") {
          setClientSecret(data.data[0].clientSecret);
          setCreateWithCustomer(false);
          // stePaymentSuccessfulWithCard(true);
        }
      })
      .finally(() => {
        setLoadingPayment(false);
      });

    return response;
  };

  const createPaymentMethod = async (customerId: string) => {
    const response = await fetch(`${BASE_URL}${PAYMENT_METHOD}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        customer_id: customerId,
        // payment_method_token: paymentMethodToken,
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const createCustomer = async () => {
    const response = await fetch(`${BASE_URL}${CREATE_CUSTOMER}`, {
      method: "POST",
      headers: {
        AccessToken: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        description: `${name} - ${user_type}`,
        email: email,
        name: name,
        phone: mobile,
        user_id: user_id,
        shipping: {
          address: `c/ Daniel Balaciart 2`,
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Customer created successful!") {
          setCustomerId(data.data[0].id);
          // setShowRegisteredCards(!showRegisteredCards);
        }
      });

    return response;
  };

  const getCustomerPaymentMethods = async () => {
    const data = await fetch(
      `${BASE_URL}${CUSTOMER_PAYMENT_METHODS}?customer_id=${customerId}&user_id=${user_id}`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    ).then((res) => res.json());

    return data;
  };
  const { data: paymentMethods, isLoading } = useQuery({
    queryKey: ["payment_methods", customerId],
    queryFn: getCustomerPaymentMethods,
    enabled: customerId !== "",
  });

  const createPayment = async (customerId: string) => {
    setLoadingPaymentWithCustomer(true);
    const response = await fetch(`${BASE_URL}${PAYMENT_INTENT_INSTANT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        // amount: `${modal.amount}00`,
        amount: `${Math.round(Number(modal.amount))}00`,

        customer_id: customerId,
        payment_method_token: selectedMethod.id,
        user_id: user_id,
        description: `FS Instant - ${modal.freightId}`,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Session creation went successful!") {
          setPaymentIntentId(data.data[0].paymentIntentId);
          setCreateWithCustomer(true);
          setPaymentMadeWithRegisteredCard(false);
          setClientSecret(data.data[0].clientSecret);
          stePaymentSuccessfulWithCard(true);
        }
      })
      .finally(() => {
        setLoadingPaymentWithCustomer(false);
      });

    return response;
  };

  const attachPaymentMethod = async (paymentMethodToken: string) => {
    const response = await fetch(`${BASE_URL}${ATTACH_PAYMENT_METHOD}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        customer_id: customerId,
        payment_method_id: paymentMethodToken,
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const appearance = {
    theme: "stripe",
  };

  const options: any = {
    clientSecret,
    appearance,
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setLoadingUserExist(true);
    // if (values.termsConditions && values.phoneNumber.length > 0) {
    // createPayment();
    if (values.termsConditions) {
      doesUserExist();
      setShowRegisteredCards(true);
    }
    // }
  };

  return (
    <Dialog open={modal.isOpen} onOpenChange={modal.onClose}>
      {!paymentMadeWithRegisteredCard && clientSecret ? (
        <DialogContent>
          <Elements options={options} stripe={stripePromise}>
            <CheckoutCompleteInstantPayment
              clientSecret={clientSecret}
              createWithCustomer={createWithCustomer}
              createPaymentMethod={attachPaymentMethod}
              customerId={customerId}
              paymentIntentId={paymentIntentId}
              selectedMethod={selectedMethod}
              refetch={refetch}
              paymentSuccessful={paymentSuccessulWithCard}
            />
          </Elements>
        </DialogContent>
      ) : (
        <>
          {secureQuote ? (
            <DialogContent className="bg-gray-50 p-6">
              <DialogHeader>
                {showRegisteredCards ? (
                  <div>
                    <Button
                      onClick={() => {
                        setAddPaymentMethod(true);
                        createPaymentNoCustomer();
                        setSecureQuote(false);
                      }}
                      variant={"outline"}
                      className="mb-4"
                    >
                      <Plus />
                      Add Payment Method
                    </Button>
                    {paymentMethods?.message !==
                      "This customer has no payment methods associated, please create a new one." && (
                      <div className="flex flex-col space-y-1 py-2">
                        {!followUpPayment && (
                          <h1 className="text-sm font-medium mb-2">
                            Previously used
                          </h1>
                        )}

                        {isLoading ? (
                          <div className="border py-2 rounded flex items-center cursor-pointer w-full justify-between px-4  bg-white">
                            <div>
                              <img
                                src={require("../../assets/visa.png")}
                                className="h-10 w-10 object-contain rounded"
                                alt="visa"
                              />
                            </div>
                            <div className="flex flex-col items-end">
                              <Skeleton className="h-4 w-10" />
                              <Skeleton className="h-4 w-10 mt-2" />
                            </div>
                          </div>
                        ) : (
                          <>
                            {paymentMethods?.data?.length > 0 && (
                              <div
                                className={cn(
                                  "flex flex-col space-y-2",
                                  followUpPayment && "opacity-20"
                                )}
                              >
                                <div
                                  onClick={() =>
                                    setSelectedMethod(paymentMethods?.data[0])
                                  }
                                  className={cn(
                                    "border py-2 rounded flex items-center cursor-pointer w-full justify-between px-4  bg-white",
                                    selectedMethod?.id ===
                                      paymentMethods?.data[0]?.id &&
                                      "bg-gray-200 text-[#333]"
                                  )}
                                >
                                  <div>
                                    <img
                                      src={require("../../assets/visa.png")}
                                      className="h-10 w-10 object-contain rounded"
                                      alt={`visa-${paymentMethods?.data[0].card.brand}`}
                                    />
                                  </div>
                                  <div className="flex flex-col items-end">
                                    <p className="text-sm">
                                      *{paymentMethods?.data[0].card.last4}
                                    </p>
                                    <p className="text-xs font-medium text-gray-400">
                                      {paymentMethods?.data[0].card.exp_month}/
                                      {paymentMethods?.data[0].card.exp_year}
                                    </p>
                                  </div>
                                </div>
                                {!showMore && (
                                  <h3
                                    onClick={() => setShowMore(true)}
                                    className="hover:underline text-freightBlueDark cursor-pointer text-sm font-medium text-right"
                                  >
                                    Show more
                                  </h3>
                                )}
                              </div>
                            )}
                          </>
                        )}

                        {/* {showMore && */}
                        {showMore && (
                          <div className="flex flex-col space-y-1 py-2">
                            {paymentMethods?.data?.length > 0 &&
                              paymentMethods?.data
                                .slice(1, 5)
                                .map((method: PaymentMethodType) => (
                                  <div
                                    onClick={() => setSelectedMethod(method)}
                                    className={cn(
                                      "border py-2 rounded flex items-center cursor-pointer w-full justify-between px-4  bg-white",
                                      selectedMethod?.id === method?.id &&
                                        "bg-gray-200 text-[#333]"
                                    )}
                                  >
                                    <div>
                                      <img
                                        src={require("../../assets/visa.png")}
                                        className="h-10 w-10 object-contain rounded"
                                        alt={`visa-${method.card.brand}`}
                                      />
                                    </div>
                                    <div className="flex flex-col items-end">
                                      <p className="text-sm">
                                        *{method.card.last4}
                                      </p>
                                      <p className="text-xs font-medium text-gray-400">
                                        {method.card.exp_month}/
                                        {method.card.exp_year}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                            <h3
                              onClick={() => setShowMore(false)}
                              className="hover:underline text-freightBlueDark cursor-pointer text-sm font-medium text-right"
                            >
                              Show less
                            </h3>
                          </div>
                        )}
                      </div>
                    )}

                    {followUpPayment && (
                      <div className="flex flex-col items-start w-full border-t border-border">
                        <div className="pt-4 text-sm">
                          Are you sure you want continue with this card?
                        </div>
                        <div className="flex  items-start justify-between py-2 mt-4 mb-4 w-full bg-white rounded border-border border px-4">
                          <div className="flex  items-center space-x-2">
                            <h1 className="uppercase">
                              <img
                                src={require("../../assets/visa.png")}
                                className="h-5 w-5 object-contain rounded"
                                alt={`visa-${selectedMethod.card.brand}`}
                              />
                            </h1>
                            <div className="flex items-center space-x-2">
                              <h1 className="text-sm font-medium">
                                *{selectedMethod.card.last4}
                              </h1>
                            </div>
                          </div>
                          <div className="flex items-center space-x-2">
                            <p className="text-sm font-medium">
                              {selectedMethod.card.exp_month} /{" "}
                              {selectedMethod.card.exp_year}
                            </p>
                          </div>
                        </div>

                        <Button
                          disabled={loadingPaymentWithCustomer}
                          variant={"freightBlue"}
                          onClick={() => {
                            createPayment(customerId);
                            setPaymentMadeWithRegisteredCard(true);
                          }}
                          className="justify-end ml-auto"
                        >
                          {!loadingPaymentWithCustomer ? (
                            <p className="text-sm font-medium">Continue</p>
                          ) : (
                            <Loader2 className="h-4 w-4 animate-spin" />
                          )}
                        </Button>
                      </div>
                    )}
                    {paymentMethods?.message !==
                      "This customer has no payment methods associated, please create a new one." && (
                      <>
                        {!followUpPayment && addPaymentMethod ? (
                          <></>
                        ) : (
                          <div className="flex flex-col items-start space-y-4 w-full mt-4">
                            {!noAddedPaymentMethods &&
                              !followUpPayment &&
                              selectedMethod?.customer !== "" && (
                                <Button
                                  onClick={() => {
                                    // createPayment(selectedMethod.customer);
                                    setFollowUpPayment(true);
                                  }}
                                  className="justify-end items-end ml-auto"
                                  variant={"freightBlue"}
                                  disabled={!selectedMethod}
                                >
                                  Pay with this card
                                </Button>
                              )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <div className="flex flex-col items-start  ">
                    <h1 className="text-lg font-medium">Secure this quote</h1>
                    <p className="text-left text-xs text-gray-400">
                      To secure this quote a depost of 10% of the total quote is
                      reuqired. This is deducted from the remaining amount
                      owing.
                    </p>
                    <div className=" mt-4 flex flex-col items-start w-full bg-white p-4 border rounded">
                      <div className="flex items-center justify-between border-b-2 w-full">
                        <h1 className="text-sm font-semibold  text-left ">
                          Title
                        </h1>
                        <h1 className="text-sm font-semibold">Amount</h1>
                      </div>
                      <div className="flex items-center justify-between w-full border-b border-border py-2">
                        <h3 className="text-sm">
                          Deposit Fee: {modal?.freightId}
                        </h3>
                        <p className="text-sm font-medium">{modal?.amount}€</p>
                      </div>

                      <div className="flex items-center justify-end w-full border-b border-border py-2">
                        <p className="text-sm font-medium">
                          Total {modal?.amount}€
                        </p>
                      </div>
                      <Form {...formAcceptQuote}>
                        <form
                          className="w-full flex flex-col"
                          onSubmit={formAcceptQuote.handleSubmit(onSubmit)}
                        >
                          {/* <FormField
                            control={formAcceptQuote.control}
                            name="phoneNumber"
                            render={({ field }) => (
                              <div className="py-2 pt-4">
                                <FormItem className="flex flex-col items-start">
                                  <FormLabel>Your Phone Number</FormLabel>
                                  <FormControl>
                                    <Input
                                      // name="phoneNumber"
                                      {...field}
                                      className="w-full dark:bg-transparent"
                                    />
                                  </FormControl>
                                </FormItem>
                              </div>
                            )}
                          /> */}
                          <div className="w-full mb-4">
                            <p className="font-medium mb-4 text-sm text-left mt-4">
                              The transporter you are choosing is an independent
                              company in their own right therefore your are
                              solely responsible for all the activities that
                              happen between the service provider and yourself.
                            </p>

                            <Controller
                              control={formAcceptQuote.control}
                              name="termsConditions"
                              render={({ field }) => (
                                <div className="flex items-center space-x-2 mt-4">
                                  <Checkbox
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                  />
                                  <p className="text-sm">
                                    I accept the terms & conditions set out by
                                  </p>
                                </div>
                              )}
                            />
                          </div>
                          <Button
                            disabled={
                              loadingUserExist ||
                              formAcceptQuote.getValues("termsConditions") ===
                                false
                            }
                            variant={"freightBlue"}
                          >
                            {loadingUserExist ? (
                              <>
                                <Loader2 className="h-5 w-5 animate-spin" />
                              </>
                            ) : (
                              <p>Accept Quote</p>
                            )}
                          </Button>
                        </form>
                      </Form>
                    </div>
                  </div>
                )}
              </DialogHeader>
            </DialogContent>
          ) : (
            <DialogContent>
              <DialogHeader>
                <div className="flex flex-col ">
                  <div className="flex items-center space-x-3 mb-3">
                    <div className="rounded-full flex items-center justify-center">
                      <AlertCircle className="h-10 w-10" />
                    </div>
                    <h1 className="font-semibold text-xl">
                      You are about to accept the quote
                    </h1>
                  </div>
                  <div className="text-left">
                    After a quote has been accepted and your deposit paid, your
                    details will be sent to the transporter. The transporter
                    arrange pickup and delivery.
                  </div>
                </div>
              </DialogHeader>
              {loadingPayment ? (
                <div>
                  <Button variant={"freightBlue"}>
                    <Loader2 className="h-5 w-5 animate-spin" />
                  </Button>
                </div>
              ) : (
                <div className="w-full justify-end items-end flex space-x-2">
                  <Button onClick={() => modal.onClose()} variant={"outline"}>
                    Cancel
                  </Button>

                  <Button
                    onClick={() => setSecureQuote(true)}
                    variant={"freightBlue"}
                  >
                    Continue
                  </Button>
                </div>
              )}
            </DialogContent>
          )}
        </>
      )}
    </Dialog>
    // <Dialog open={modal.isOpen} onOpenChange={modal.onClose}>
    //   {!paymentMadeWithRegisteredCard && clientSecret ? (
    //     <DialogContent>
    //       <Elements options={options} stripe={stripePromise}>
    //         <CheckoutCompleteInstantPayment
    //           clientSecret={clientSecret}
    //           createWithCustomer={createWithCustomer}
    //           createPaymentMethod={attachPaymentMethod}
    //           customerId={customerId}
    //           paymentIntentId={paymentIntentId}
    //           selectedMethod={selectedMethod}
    //           refetch={refetch}
    //           paymentSuccessful={paymentSuccessulWithCard}
    //         />
    //       </Elements>
    //     </DialogContent>
    //   ) : (
    //     <>
    //       {secureQuote ? (
    //         <DialogContent>
    //           <DialogHeader>
    //             {showRegisteredCards ? (
    //               <div>
    //                 <Button
    //                   onClick={() => {
    //                     setAddPaymentMethod(true);
    //                     createPaymentNoCustomer();
    //                     setSecureQuote(false);
    //                   }}
    //                   variant={"outline"}
    //                   className="mb-4"
    //                 >
    //                   <Plus />
    //                   Add Payment Method
    //                 </Button>
    //                 <div className="flex flex-col space-y-1 py-2">
    //                   <h1 className="text-sm font-medium mb-2">
    //                     Previously used
    //                   </h1>

    //                   {isLoading ? (
    //                     <div className="border py-2 rounded flex items-center cursor-pointer w-full justify-between px-4  bg-white">
    //                       <div>
    //                         <img
    //                           src={require("../../assets/visa.png")}
    //                           className="h-10 w-10 object-contain rounded"
    //                           alt="visa"
    //                         />
    //                       </div>
    //                       <div className="flex flex-col items-end">
    //                         <Skeleton className="h-4 w-10" />
    //                         <Skeleton className="h-4 w-10 mt-2" />
    //                       </div>
    //                     </div>
    //                   ) : (
    //                     <>
    //                       {paymentMethods?.data?.length > 0 && (
    //                         <div className="flex flex-col space-y-2">
    //                           <div
    //                             onClick={() =>
    //                               setSelectedMethod(paymentMethods?.data[0])
    //                             }
    //                             className={cn(
    //                               "border py-2 rounded flex items-center cursor-pointer w-full justify-between px-4  bg-white",
    //                               selectedMethod?.id ===
    //                                 paymentMethods?.data[0]?.id &&
    //                                 "bg-gray-200 text-[#333]"
    //                             )}
    //                           >
    //                             <div>
    //                               <img
    //                                 src={require("../../assets/visa.png")}
    //                                 className="h-10 w-10 object-contain rounded"
    //                                 alt={`visa-${paymentMethods?.data[0].card.brand}`}
    //                               />
    //                             </div>
    //                             <div className="flex flex-col items-end">
    //                               <p className="text-sm">
    //                                 *{paymentMethods?.data[0].card.last4}
    //                               </p>
    //                               <p className="text-xs font-medium text-gray-400">
    //                                 {paymentMethods?.data[0].card.exp_month}/
    //                                 {paymentMethods?.data[0].card.exp_year}
    //                               </p>
    //                             </div>
    //                           </div>
    //                           {!showMore && (
    //                             <h3
    //                               onClick={() => setShowMore(true)}
    //                               className="hover:underline text-freightBlueDark cursor-pointer text-sm font-medium text-right"
    //                             >
    //                               Show more
    //                             </h3>
    //                           )}
    //                         </div>
    //                       )}
    //                     </>
    //                   )}

    //                   {/* {showMore && */}
    //                   {showMore && (
    //                     <div className="flex flex-col space-y-1 py-2">
    //                       {paymentMethods?.data?.length > 0 &&
    //                         paymentMethods?.data
    //                           .slice(1, 5)
    //                           .map((method: PaymentMethodType) => (
    //                             <div
    //                               onClick={() => setSelectedMethod(method)}
    //                               className={cn(
    //                                 "border py-2 rounded flex items-center cursor-pointer w-full justify-between px-4  bg-white",
    //                                 selectedMethod?.id === method?.id &&
    //                                   "bg-gray-200 text-[#333]"
    //                               )}
    //                             >
    //                               <div>
    //                                 <img
    //                                   src={require("../../assets/visa.png")}
    //                                   className="h-10 w-10 object-contain rounded"
    //                                   alt={`visa-${method.card.brand}`}
    //                                 />
    //                               </div>
    //                               <div className="flex flex-col items-end">
    //                                 <p className="text-sm">
    //                                   *{method.card.last4}
    //                                 </p>
    //                                 <p className="text-xs font-medium text-gray-400">
    //                                   {method.card.exp_month}/
    //                                   {method.card.exp_year}
    //                                 </p>
    //                               </div>
    //                             </div>
    //                           ))}
    //                       <h3
    //                         onClick={() => setShowMore(false)}
    //                         className="hover:underline text-freightBlueDark cursor-pointer text-sm font-medium text-right"
    //                       >
    //                         Show less
    //                       </h3>
    //                     </div>
    //                   )}
    //                 </div>

    //                 {followUpPayment && (
    //                   <div className="flex flex-col items-start w-full border-t border-border">
    //                     <div className="pt-4 text-sm">
    //                       Are you sure you want continue with this card?
    //                     </div>
    //                     <div className="flex  items-start justify-between py-2 mt-4 mb-4 w-full bg-white rounded border-border border px-4">
    //                       <div className="flex  items-center space-x-2">
    //                         <h1 className="uppercase">
    //                           <img
    //                             src={require("../../assets/visa.png")}
    //                             className="h-5 w-5 object-contain rounded"
    //                             alt={`visa-${selectedMethod.card.brand}`}
    //                           />
    //                         </h1>
    //                         <div className="flex items-center space-x-2">
    //                           <h1 className="text-sm font-medium">
    //                             *{selectedMethod.card.last4}
    //                           </h1>
    //                         </div>
    //                       </div>
    //                       <div className="flex items-center space-x-2">
    //                         <p className="text-sm font-medium">
    //                           {selectedMethod.card.exp_month} /{" "}
    //                           {selectedMethod.card.exp_year}
    //                         </p>
    //                       </div>
    //                     </div>

    //                     <Button
    //                       disabled={loadingPaymentWithCustomer}
    //                       variant={"freightBlue"}
    //                       onClick={() => {
    //                         createPayment(customerId);
    //                         setPaymentMadeWithRegisteredCard(true);
    //                       }}
    //                       className="justify-end ml-auto"
    //                     >
    //                       {!loadingPaymentWithCustomer ? (
    //                         <p className="text-sm font-medium">Continue</p>
    //                       ) : (
    //                         <Loader2 className="h-4 w-4 animate-spin" />
    //                       )}
    //                     </Button>
    //                   </div>
    //                 )}
    //                 {!followUpPayment && addPaymentMethod ? (
    //                   <></>
    //                 ) : (
    //                   <div className="flex flex-col items-start  ">
    //                     <h1 className="text-lg font-medium">
    //                       Secure this quote
    //                     </h1>
    //                     <p className="text-left text-xs text-gray-400">
    //                       To secure this quote a depost of 10% of the total
    //                       quote is reuqired. This is deducted from the remaining
    //                       amount owing.
    //                     </p>
    //                     <div className=" mt-4 flex flex-col items-start w-full bg-white p-4 border rounded">
    //                       <div className="flex items-center justify-between border-b-2 w-full">
    //                         <h1 className="text-sm font-semibold  text-left ">
    //                           Title
    //                         </h1>
    //                         <h1 className="text-sm font-semibold">Amount</h1>
    //                       </div>
    //                       <div className="flex items-center justify-between w-full border-b border-border py-2">
    //                         <h3 className="text-sm">
    //                           Deposit Fee: {modal?.freightId}
    //                         </h3>
    //                         <p className="text-sm font-medium">
    //                           {modal?.amount}€
    //                         </p>
    //                       </div>
    //                       <div className="flex items-center  w-full border-b border-border py-2 justify-end">
    //                         <p className="text-sm font-medium">
    //                           {modal?.amount}€
    //                         </p>
    //                       </div>
    //                       <div className="flex items-center justify-end w-full border-b border-border py-2">
    //                         <p className="text-sm font-medium">
    //                           Total {modal?.amount}€
    //                         </p>
    //                       </div>
    //                       <Form {...formAcceptQuote}>
    //                         <form
    //                           className="w-full flex flex-col"
    //                           onSubmit={formAcceptQuote.handleSubmit(onSubmit)}
    //                         >
    //                           <FormField
    //                             control={formAcceptQuote.control}
    //                             name="phoneNumber"
    //                             render={({ field }) => (
    //                               <div className="py-2 pt-4">
    //                                 <FormItem className="flex flex-col items-start">
    //                                   <FormLabel>Your Phone Number</FormLabel>
    //                                   <FormControl>
    //                                     <Input
    //                                       // name="phoneNumber"
    //                                       {...field}
    //                                       className="w-full dark:bg-transparent"
    //                                     />
    //                                   </FormControl>
    //                                 </FormItem>
    //                               </div>
    //                             )}
    //                           />
    //                           <div className="w-full mb-4">
    //                             <p className="font-medium mb-4 text-xs text-left">
    //                               The transporter you are choosing is an
    //                               independent company in their own right
    //                               therefore your are solely responsible for all
    //                               the activities that happen between the service
    //                               provider and yourself.
    //                             </p>

    //                             <Controller
    //                               control={formAcceptQuote.control}
    //                               name="termsConditions"
    //                               render={({ field }) => (
    //                                 <div className="flex items-center space-x-2 mt-4">
    //                                   <Checkbox
    //                                     checked={field.value}
    //                                     onCheckedChange={field.onChange}
    //                                   />
    //                                   <p className="text-sm">
    //                                     I accept the terms & conditions set out
    //                                     by
    //                                   </p>
    //                                 </div>
    //                               )}
    //                             />
    //                           </div>
    //                           <Button
    //                             disabled={loadingUserExist}
    //                             variant={"freightBlue"}
    //                           >
    //                             {loadingUserExist ? (
    //                               <>
    //                                 <Loader2 className="h-5 w-5 animate-spin" />
    //                               </>
    //                             ) : (
    //                               <p>Accept Quote</p>
    //                             )}
    //                           </Button>
    //                         </form>
    //                       </Form>
    //                     </div>
    //                   </div>
    //                 )}
    //               </div>
    //             ) : (
    //               <div className="flex flex-col items-start  ">
    //                 <h1 className="text-lg font-medium">Secure this quote</h1>
    //                 <p className="text-left text-xs text-gray-400">
    //                   To secure this quote a depost of 10% of the total quote is
    //                   reuqired. This is deducted from the remaining amount
    //                   owing.
    //                 </p>
    //                 <div className=" mt-4 flex flex-col items-start w-full bg-white p-4 border rounded">
    //                   <div className="flex items-center justify-between border-b-2 w-full">
    //                     <h1 className="text-sm font-semibold  text-left ">
    //                       Title
    //                     </h1>
    //                     <h1 className="text-sm font-semibold">Amount</h1>
    //                   </div>
    //                   <div className="flex items-center justify-between w-full border-b border-border py-2">
    //                     <h3 className="text-sm">Freight: {modal.freightId}</h3>
    //                     <p>{modal.amount}</p>
    //                   </div>
    //                   <div className="flex items-center  w-full border-b border-border py-2 justify-end">
    //                     <p className="">{modal.amount}</p>
    //                   </div>
    //                   <div className="flex items-center justify-end w-full border-b border-border py-2">
    //                     <p>Total {modal.amount}</p>
    //                   </div>
    //                   <Form {...formAcceptQuote}>
    //                     <form
    //                       className="w-full flex flex-col"
    //                       onSubmit={formAcceptQuote.handleSubmit(onSubmit)}
    //                     >
    //                       <FormField
    //                         control={formAcceptQuote.control}
    //                         name="phoneNumber"
    //                         render={({ field }) => (
    //                           <div className="py-2 pt-4">
    //                             <FormItem className="flex flex-col items-start">
    //                               <FormLabel>Your Phone Number</FormLabel>
    //                               <FormControl>
    //                                 <Input
    //                                   // name="phoneNumber"
    //                                   {...field}
    //                                   className="w-full dark:bg-transparent"
    //                                 />
    //                               </FormControl>
    //                             </FormItem>
    //                           </div>
    //                         )}
    //                       />
    //                       <div className="w-full mb-4">
    //                         <p className="font-medium mb-4 text-xs text-left">
    //                           The transporter you are choosing is an independent
    //                           company in their own right therefore your are
    //                           solely responsible for all the activities that
    //                           happen between the service provider and yourself.
    //                         </p>

    //                         <Controller
    //                           control={formAcceptQuote.control}
    //                           name="termsConditions"
    //                           render={({ field }) => (
    //                             <div className="flex items-center space-x-2 mt-4">
    //                               <Checkbox
    //                                 checked={field.value}
    //                                 onCheckedChange={field.onChange}
    //                               />
    //                               <p className="text-sm">
    //                                 I accept the terms & conditions set out by
    //                               </p>
    //                             </div>
    //                           )}
    //                         />
    //                       </div>
    //                       <Button variant={"freightBlue"}>Accept Quote</Button>
    //                     </form>
    //                   </Form>
    //                 </div>
    //               </div>
    //             )}
    //           </DialogHeader>
    //         </DialogContent>
    //       ) : (
    //         <DialogContent>
    //           <DialogHeader>
    //             <div className="flex flex-col ">
    //               <div className="flex items-center space-x-3 mb-3">
    //                 <div className="rounded-full flex items-center justify-center">
    //                   <AlertCircle className="h-10 w-10" />
    //                 </div>
    //                 <h1 className="font-semibold text-xl">
    //                   You are about to accept the quote
    //                 </h1>
    //               </div>
    //               <div className="text-left">
    //                 After a quote has been accepted and your deposit paid, your
    //                 details will be sent to the transporter. The transporter
    //                 arrange pickup and delivery.
    //               </div>
    //             </div>
    //           </DialogHeader>
    //           {loadingPayment ? (
    //             <div>
    //               <Button variant={"freightBlue"}>
    //                 <Loader2 className="h-5 w-5 animate-spin" />
    //               </Button>
    //             </div>
    //           ) : (
    //             <div className="w-full justify-end items-end flex space-x-2">
    //               <Button onClick={() => modal.onClose()} variant={"outline"}>
    //                 Cancel
    //               </Button>

    //               <Button
    //                 onClick={() => setSecureQuote(true)}
    //                 variant={"freightBlue"}
    //               >
    //                 Continue
    //               </Button>
    //             </div>
    //           )}
    //         </DialogContent>
    //       )}
    //     </>
    //   )}
    // </Dialog>
  );
};

export default ModalPayInstantFreight;
