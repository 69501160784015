import { create } from "zustand";

export type ModalType = "sendReviewStop";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  stopId: string;
  transporterId: string;
  onOpen: (type: ModalType, stopId: string, transporterId: string) => void;
  onClose: () => void;
}

export const useSendReviewStop = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  stopId: "",
  transporterId: "",
  onOpen: (type, stopId, transporterId) =>
    set({ isOpen: true, type, stopId, transporterId }),
  onClose: () => set({ type: null, isOpen: false }),
}));
