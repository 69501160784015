import { userInfoUserStore } from "src/store/useUserInfoStore";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { useDeclineQuoteModal } from "src/hooks/use-decline-quote";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "../ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { BASE_URL, DECLINE_QUOTE } from "src/api/config";
import { Button } from "../ui/button";
import toast from "react-hot-toast";

const declineSchema = z.object({
  reasonCancellation: z.string().min(1),
});

const declineReasons = [
  {
    id: 0,
    value: "Quote too high",
  },
  {
    id: 1,
    value: "Transporter Provider not suitable for my purposes",
  },
  {
    id: 2,
    value: "Transporter Provider dates not suitable",
  },
  {
    id: 3,
    value: "I do not wish to use this Transporter Provider",
  },
  {
    id: 4,
    value: "Other reason",
  },
];

interface ModalDeclineQuoteProps {
  refetch: () => void;
}

const ModalDeclineQuote: React.FC<ModalDeclineQuoteProps> = ({ refetch }) => {
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const modalDeclineQuote = useDeclineQuoteModal();

  const declineReason = useForm<z.infer<typeof declineSchema>>({
    resolver: zodResolver(declineSchema),
    defaultValues: {
      reasonCancellation: "",
    },
  });

  const declineQuote = async (reason: string) => {
    const response = await fetch(`${BASE_URL}${DECLINE_QUOTE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        quote_id: modalDeclineQuote.quoteId,
        reason: reason,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Quote declined successfully.") {
          modalDeclineQuote.onClose();
          toast.success(`Quote Declined ${modalDeclineQuote.quoteId}`);
          refetch();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const onSubmit = (values: z.infer<typeof declineSchema>) => {
    if (values.reasonCancellation !== "") {
      declineQuote(values.reasonCancellation);
    }
  };
  return (
    <Dialog
      open={modalDeclineQuote.isOpen}
      onOpenChange={modalDeclineQuote.onClose}
    >
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-start">
            <div className="flex items-center border-b w-full mb-4 pb-4">
              <h1>Decline Quote</h1>
            </div>
            <Form {...declineReason}>
              <form
                className="w-full flex flex-col"
                onSubmit={declineReason.handleSubmit(onSubmit)}
              >
                <div className="flex flex-col ">
                  <h1 className="text-sx font-medium mb-4">
                    Select reason why this quote is being cancelled
                  </h1>
                </div>
                <Controller
                  name="reasonCancellation"
                  control={declineReason.control}
                  render={({ field }) => (
                    <Select
                      onValueChange={(value) => {
                        declineReason.setValue("reasonCancellation", value);
                      }}
                      {...field}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select" />
                      </SelectTrigger>
                      <SelectContent>
                        {declineReasons.map((reason) => (
                          <SelectItem value={reason.value}>
                            {reason.value}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                />
                <div className=" border-t-2 mt-4">
                  <div className="py-3  w-full justify-end flex items-end space-x-2">
                    <Button
                      type="button"
                      onClick={() => modalDeclineQuote.onClose()}
                      variant={"outline"}
                    >
                      Close
                    </Button>
                    <Button variant={"freightBlue"} type="submit">
                      Decline Quote
                    </Button>
                  </div>
                </div>
              </form>
            </Form>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalDeclineQuote;
