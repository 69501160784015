import { userInfoUserStore } from "src/store/useUserInfoStore";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { useSendMessage } from "src/hooks/use-send-message";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import { BASE_URL, BASE_URL_IMG, SAVE_CHATS } from "src/api/config";
import { Button } from "../ui/button";
import { Loader2, Send } from "lucide-react";
import { useState } from "react";
import toast from "react-hot-toast";

const formSendMessageSchema = z.object({
  message: z.string().min(1, "Message is empty!"),
});

const ModalSendMessage = () => {
  const { user_id, user_type, email, name, mobile } = userInfoUserStore(
    (state) => state
  );
  const token = useTokenStoreStorage((state) => state.token);

  const modal = useSendMessage();
  const [loading, setLoading] = useState(false);

  const formSendMessage = useForm<z.infer<typeof formSendMessageSchema>>({
    resolver: zodResolver(formSendMessageSchema),
    defaultValues: {
      message: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSendMessageSchema>) => {
    setLoading(true);
    const response = await fetch(
      `${BASE_URL}${SAVE_CHATS}?user_id=${user_id}&quote_id=${
        modal.data.quoteId
      }&sender_id=${user_id}&reciever_id=${
        Number(user_id) === modal.data.senderId
          ? modal.data.customerId
          : modal.data.receiverId
      }&freight_id=${modal.data.freightId}&message=${values.message}`,
      {
        method: "POST",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Message saved successfully.") {
          toast.success("Message sent!");
          modal.onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

    return response;
  };

  return (
    <Dialog open={modal.isOpen} onOpenChange={modal.onClose}>
      <DialogContent className="bg-gray-50 p-6">
        <DialogHeader>
          <div className="flex flex-col items-start">
            <h1 className="text-sm font-medium">
              Send Message to{" "}
              {user_type === "carrier" ? "Customer" : "Transporter"}{" "}
            </h1>
            <div className="flex items-start">
              {user_type === "customer" ? (
                <div className="flex  items-center space-x-2 py-4">
                  {modal.data.transporterImage === null ? (
                    <img
                      src={require("../../assets/images/user-icon-placeholder.jpg")}
                      className="h-10 w-10 rounded-full object-contain z-10"
                      alt="user-icon-placeholder"
                    />
                  ) : (
                    <img
                      className="w-16 h-16 object-cover rounded-full object-top"
                      src={`${BASE_URL}images?user_id=${user_id}&file_name=${modal.data.transporterImage}&image_type=profile`}
                      alt="profile"
                    />
                  )}
                  <h1 className="font-medium text-sm text-gray-500">
                    {modal.data.transporterName}
                  </h1>
                </div>
              ) : (
                <div>
                  <h1 className="text-lg font-semibold">
                    {modal.data.customerName}
                  </h1>
                </div>
              )}
            </div>
          </div>

          <Form {...formSendMessage}>
            <form
              className="w-full flex flex-col "
              onSubmit={formSendMessage.handleSubmit(onSubmit)}
            >
              <FormField
                control={formSendMessage.control}
                name="message"
                render={({ field, fieldState }) => (
                  <>
                    <FormItem className="flex flex-col items-start">
                      {/* <FormLabel>Message</FormLabel> */}
                      <FormControl>
                        <Input placeholder="Hi, there!" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                    {/* {fieldState.error && (
                      <p className="text-sm font-medium text-red-500 mt-2">
                        {fieldState.error.message}
                      </p>
                    )} */}
                  </>
                )}
              />
              <Button
                disabled={loading}
                className="w-40 mt-4 ml-auto"
                variant={"freightBlue"}
              >
                {loading ? (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin" />
                  </>
                ) : (
                  <>
                    <Send className="h-4 w-4 mr-2" />
                    <>Send</>
                  </>
                )}
              </Button>
            </form>
          </Form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalSendMessage;
