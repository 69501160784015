import {
  Calendar,
  Check,
  CheckCircle,
  ChevronRight,
  MapPin,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { RouteType } from "src/types/types";

import { Button } from "../ui/button";

//@ts-ignore
import MediumBox from "../../assets/images/Mediumbox.png";

//@ts-ignore
import LargeBox from "../../assets/images/Largebox.png";
//@ts-ignore
import SmallBox from "../../assets/images/Smallbox.png";

//@ts-ignore
import { BASE_URL, GET_IMAGE } from "src/api/config";
import { useBookTripStop } from "src/hooks/use-book-trip-stop";
import { cn } from "src/lib/utils";
import MapMarker from "../../assets/images/mapmarker.png";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

interface RouteAllCardProps {
  route: RouteType;
}

const RouteAllCard: React.FC<RouteAllCardProps> = ({ route }) => {
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const navigate = useNavigate();
  const modalBookTripStop = useBookTripStop();

  return (
    <div className="bg-white p-4 rounded shadow">
      <div className="sm:flex sm:items-start sm:justify-between">
        <div className="flex justify-center items-start space-x-3">
          <div className="flex items-start space-x-10">
            <div className="flex flex-col space-y-2  ">
              <div className="flex flex-col items-start">
                <h1 className="text-lg font-medium text-gray-500 ">Route</h1>
              </div>
              <h1 className="text-[15px] font-semibold">
                {new Date(route.start_date).getHours()}:
                {new Date(route.start_date).getMinutes().toString().split("")
                  .length > 1
                  ? new Date(route.start_date).getMinutes()
                  : `${new Date(route.start_date).getMinutes()}0`}
              </h1>
              <h3 className="text-[15px] font-medium text-gray-500">
                {route.delivery_duration?.replace("hours", "h")}
              </h3>
              <h1 className="text-[15px] font-semibold">
                {new Date(route.end_date).getHours()}:
                {new Date(route.end_date).getMinutes().toString().split("")
                  .length > 1
                  ? new Date(route.end_date).getMinutes()
                  : `${new Date(route.end_date).getMinutes()}0`}
              </h1>
              <div className="flex items-center relative">
                {route.profile_img === null ? (
                  <img
                    src={require("../../assets/images/user-icon-placeholder.jpg")}
                    className="h-10 w-10 rounded-full object-cover mb-2 -mr-5 z-10"
                    alt="user-icon-placeholder"
                  />
                ) : (
                  <img
                    src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${route.profile_img}&image_type=profile`}
                    className="h-10 w-10 rounded-full object-cover mb-2 -mr-5 z-10"
                    alt="user-icon-placeholder"
                  />
                )}
                {route.package_count === "car" && (
                  <img
                    src={require("../../assets/images/car-nobg.png")}
                    alt="car"
                    className=" w-20 object-contain -scale-x-100 "
                  />
                )}
                {route.package_count === "bike" && (
                  <img
                    src={require("../../assets/images/bike.jpg")}
                    alt="car"
                    className=" w-20 object-contain  "
                  />
                )}
                {route.package_count === "motorbike" && (
                  <img
                    src={require("../../assets/images/motorbike-nobg.png")}
                    alt="car"
                    className=" w-20 object-contain  "
                  />
                )}
              </div>
            </div>

            <div className="flex flex-col  items-start justify-center pr-3 px-10 ">
              <div className=" mb-4">
                <div className="flex items-center space-x-2 text-gray-500">
                  <p className="text-sm text-[#333] font-medium">
                    {route.origin_city}
                  </p>{" "}
                  <ChevronRight className="h-4 w-4" />{" "}
                  <p className="text-sm text-[#333] font-medium">
                    {route.destination_city}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-start bg-gray-50 rounded p-2 border-border border  w-40">
                <p className="text-sm font-medium text-gray-500 mb-2 border-b border-border w-full pb-2">
                  Stop Packages
                </p>
                <div className="flex flex-wrap items-center gap-4 justify-center">
                  {route.stops.length > 0 ? (
                    route.stops.map((item) => (
                      <div>
                        {item.package_size === "small" && (
                          <div className="flex flex-col items-center bg-white rounded p-1">
                            <img
                              src={SmallBox}
                              className="h-7 w-7"
                              alt="smallbox"
                            />
                            <p className="text-xs text-[#333] font-medium">
                              {item.price.toFixed(2)}€
                            </p>
                          </div>
                        )}
                        {item.package_size === "medium" && (
                          <div className="flex flex-col items-center bg-white rounded p-1">
                            <img
                              src={MediumBox}
                              className="h-7 w-7"
                              alt="mediumbox"
                            />
                            <p className="text-xs text-[#333] font-medium">
                              {item.price.toFixed(2)}€
                            </p>
                          </div>
                        )}
                        {item.package_size === "large" && (
                          <div className="flex flex-col items-center bg-white rounded p-1">
                            <img
                              src={LargeBox}
                              className="h-7 w-7"
                              alt="largebox"
                            />
                            <p className="text-xs text-[#333] font-medium">
                              {item.price.toFixed(2)}€
                            </p>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="h-10">
                      <p className="text-xs font-medium text-gray-400">
                        No stops available
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start md:w-[60%]">
          <h1 className="text-lg font-medium text-gray-500 mb-4">
            Delivery Stops
          </h1>
          <div className="flex flex-col space-y-2 w-full">
            {route.stops.slice(0, 5).map((stop) => (
              <div
                className=" bg-gray-50 border-border border items-center py-3 justify-between flex rounded"
                key={route.id}
              >
                <div className="flex flex-wrap items-center justify-between w-full px-4">
                  <div className="flex items-center space-x-2">
                    <img
                      src={MapMarker}
                      className="h-7 w-7 object-contain"
                      alt={`map-marker-${stop.id}`}
                    />
                    <div className="flex justify-center items-center space-x-2">
                      <Calendar className="h-5 w-5 text-gray-500" />
                      <p className="text-sm font-medium">
                        {new Date(route.updated_at).toDateString().slice(0, 10)}
                      </p>
                    </div>
                  </div>
                  <h1 className="text-sm font-semibold">
                    {new Date(route.updated_at).getHours()}:
                    {new Date(route.updated_at)
                      .getMinutes()
                      .toString()
                      .split("").length > 1
                      ? new Date(route.updated_at).getMinutes()
                      : `${new Date(route.updated_at).getMinutes()}0`}
                  </h1>
                  <div className="">
                    {stop.package_size === "small" && (
                      <div className="flex items-center space-x-2">
                        <img
                          src={SmallBox}
                          alt="small-box"
                          className=" w-6 object-contain"
                        />
                        <p className="text-sm font-medium">
                          {stop.price.toFixed(2)}€
                        </p>
                      </div>
                    )}
                    {stop.package_size === "medium" && (
                      <div className="flex items-center space-x-2">
                        <img
                          src={MediumBox}
                          alt="medium-box"
                          className=" w-6 object-contain"
                        />
                        <p className="text-sm font-medium">
                          {stop.price.toFixed(2)}€
                        </p>
                      </div>
                    )}
                    {stop.package_size === "large" && (
                      <div className="flex items-center space-x-2">
                        <img
                          src={LargeBox}
                          alt="large-box"
                          className=" w-6 object-contain"
                        />
                        <p className="text-sm font-medium">
                          {stop.price.toFixed(2)}€
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="flex items-center space-x-2">
                    <MapPin className="h-5 w-5 text-gray-500" />
                    <p className="text-xs font-medium">
                      {stop.destination_city}
                    </p>
                  </div>
                </div>
                <div className="ml-auto mr-3">
                  {user_type !== "carrier" && (
                    <>
                      {stop.status === "available" ? (
                        <>
                          <TooltipProvider>
                            <Tooltip delayDuration={0}>
                              <TooltipTrigger>
                                <Button
                                  onClick={() => {
                                    if (user_id !== "") {
                                      modalBookTripStop.onOpen(
                                        "bookTripStop",
                                        stop,
                                        user_id
                                      );
                                    }
                                  }}
                                  className={cn(
                                    "w-40 cursor-pointer text-sm font-medium text-white capitalize px-4 py-2 rounded w-40 text-center",
                                    "bg-green-500 hover:bg-green-600" // Updated to make 'available' status distinct (green color)
                                  )}
                                >
                                  <p className="text-sm font-medium capitalize">
                                    <span className="flex items-center">
                                      <CheckCircle className="w-4 h-4 mr-1" />{" "}
                                      {/* CheckCircle icon */}
                                      Available
                                    </span>
                                  </p>
                                </Button>
                              </TooltipTrigger>
                              {user_id === "" && (
                                <TooltipContent>
                                  <p>User must be logged in</p>
                                  <></>
                                </TooltipContent>
                              )}
                            </Tooltip>
                          </TooltipProvider>
                        </>
                      ) : (
                        <p
                          className={cn(
                            "text-sm font-medium text-white capitalize px-4 py-2 rounded w-40 text-center",
                            stop.status === "available" && "bg-green-500", // Green for available
                            stop.status === "in-transit" && "bg-yellow-500", // Yellow for in-transit
                            stop.status === "booked" && "bg-blue-500", // Blue for booked
                            stop.status === "confirmed" && "bg-gray-600" // Purple for confirmed to distinguish it better
                          )}
                        >
                          <p className="text-sm font-medium capitalize">
                            {stop.status === "confirmed" ? (
                              <span className="flex items-center">
                                <Check className="w-4 h-4 mr-1" />{" "}
                                {/* Check icon */}
                                Confirmed
                              </span>
                            ) : (
                              stop.status
                            )}
                          </p>
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}
            {route.stops.length > 0 && (
              <div className="flex justify-center sm:ml-auto">
                <p
                  onClick={() => navigate(`/routes/trips/${route.id}`)}
                  className="text-sm font-medium hover:underline text-freightBlueDark cursor-pointer"
                >
                  View more
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RouteAllCard;
