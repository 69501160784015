import { PackageCheck } from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAssignInstant } from "src/hooks/use-assign-instant";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { z } from "zod";
import { Button } from "../ui/button";
import { Dialog, DialogContent } from "../ui/dialog";
const completeInstantFreightSchema = z.object({
  otpNumber: z.any(),
});

interface ModalAssignInstantFreightProps {
  refetch: () => void;
}

const ModalAssignInstantFreight: React.FC<ModalAssignInstantFreightProps> = ({
  refetch,
}) => {
  const { user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const modal = useAssignInstant();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  const handleAssign = () => {
    modal.onClose();
    navigate(`/carrier/assign-freight/${params.id}`);
  };
  return (
    <Dialog open={modal.isOpen} onOpenChange={modal.onClose}>
      <DialogContent>
        {/* <DialogHeader> */}
        <div className="flex flex-col">
          <div className="flex flex-col items-center">
            <div className="h-20 w-20 border border-border rounded-full flex items-center justify-center">
              <PackageCheck className="h-10 w-10 text-statusConfirmed" />
            </div>
            <div className="mt-4 flex flex-col items-center pb-10">
              <h1 className="text-xl font-medium">Are you sure? </h1>
              <p className="text-lg">You want to assign this Instant Freight</p>
            </div>
            <div className="flex d-flex gap-20">
              <Button onClick={() => modal.onClose()}>Cancel</Button>
              <Button onClick={() => handleAssign()} variant={"freightGreen"}>
                Assign{" "}
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAssignInstantFreight;
