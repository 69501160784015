import { useQuery } from "@tanstack/react-query";
import { BASE_URL, TRANSPORTERBOOKINGSLIST } from "../api/config";
import Header from "../components/header/Header";
import CustomerSidebar from "../components/sidebar/CustomerSidebar";
import Sidebar from "../components/sidebar/Sidebar";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";

const BookingsCompleted = () => {
  const { user_type, user_id } = userInfoUserStore((state) => state);

  const token = useTokenStoreStorage((state) => state.token);

  const getBookingTransporter = async () => {
    const response = await fetch(`${BASE_URL}${TRANSPORTERBOOKINGSLIST}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return response;
  };

  const { data: bookingTransporters } = useQuery({
    queryKey: ["booking_transporters"],
    queryFn: getBookingTransporter,
  });

  return (
    <div>
      <Header />
      <div className="grid grid-cols-7 mt-20 px-10">
        <div className="col-span-2">
          {user_type === "customer" ? <CustomerSidebar /> : <Sidebar />}
        </div>
        <div className="col-span-5">
          <div className="max-w-4xl mx-auto">
            <div className="flex items-center space-x-2 border-b pb-3 mb-4">
              <h1 className="text-sm font-semibold">My Bookings</h1>
            </div>
            <div>
              <h1>Opiniones</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingsCompleted;
