import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/layout/Footer";
//@ts-ignore
import cookies1 from "../assets/images/cookies1.png";
//@ts-ignore
import cookies2 from "../assets/images/cookies2.png";
//@ts-ignore
import cookies3 from "../assets/images/cookies3.png";
//@ts-ignore
import cookies4 from "../assets/images/cookies4.png";
export const Policy = () => {
  return (
    <div>
      {" "}
      <div>
        <Header />
        <div
          className="pb-8 mt-2 text-5xl font-bold flex justify-center gap-2"
          style={{ fontFamily: "Franklin Gothic" }}
        >
          <h2>
            <span style={{ color: "#0022b6" }}>C</span>OOKIE {"  "}
          </h2>
          <h2>
            <span style={{ color: "#0022b6" }}>P</span>OLICY{" "}
          </h2>
        </div>
        <div className="flex justify-center flex-wrap p-2">
          <img src={cookies1} alt="" />
          <img src={cookies2} alt="" />
          <img src={cookies3} alt="" />
          <img src={cookies4} alt="" />
        </div>
      </div>
      <Footer />
    </div>
  );
};
