import { create } from "zustand";

export type ModalType = "updateStopStatus";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  stopId: string;
  status: string;
  statusChange: string;
  statusChangeDisplay: string;
  onOpen: (
    type: ModalType,
    stopId: string,
    status: string,
    statusChange: string,
    statusChangeDisplay: string
  ) => void;
  onClose: () => void;
}

export const useUpdateStatusStop = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  stopId: "",
  status: "",
  statusChange: "",
  statusChangeDisplay: "",
  onOpen: (type, stopId, status, statusChange, statusChangeDisplay) =>
    set({
      isOpen: true,
      type,
      stopId,
      status,
      statusChange,
      statusChangeDisplay,
    }),
  onClose: () => set({ type: null, isOpen: false }),
}));
