import { userInfoUserStore } from "src/store/useUserInfoStore";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button } from "../ui/button";
import {
  BASE_URL,
  MARK_INSTANT_AS_PAID,
  REGISTER_FREIGHT_PAYMENT,
  REGISTER_INSTANT_FREIGHT_PAYMENT,
} from "src/api/config";
import { useCompletePayment } from "src/hooks/use-complete-payment";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { usePayInstantFreight } from "src/hooks/use-pay-instant-freight";
import { Check, Loader2 } from "lucide-react";

interface CheckoutCompletePaymentProps {
  customerId: any;
  createPaymentMethod: (payment_method_token: string) => void;
  clientSecret: string;
  createWithCustomer: boolean;
  selectedMethod: any;
  paymentIntentId: string;
  refetch: () => void;
  paymentSuccessful: boolean;
}

const CheckoutCompleteInstantPayment: React.FC<
  CheckoutCompletePaymentProps
> = ({
  clientSecret,
  refetch,
  createPaymentMethod,
  createWithCustomer,
  customerId,
  paymentIntentId,
  selectedMethod,
  paymentSuccessful,
}) => {
  const { email, name, mobile, user_type, user_id } = userInfoUserStore(
    (state) => state
  );
  const [successfulPayment, setSuccessfulPayment] = useState(false);

  const modalPayInstantFreight = usePayInstantFreight();
  const token = useTokenStoreStorage((state) => state.token);
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");
  const [createdWithCustomer, setCreatedWithCustomer] = useState(false);
  const [showSuccessfulMessage, setShowSuccessfulMessage] = useState(false);

  useEffect(() => {
    if (paymentSuccessful) {
      setSuccessfulPayment(true);
    }
  }, [paymentSuccessful]);

  useEffect(() => {
    if (createWithCustomer && selectedMethod) {
      setCreatedWithCustomer(true);
    } else {
      setCreatedWithCustomer(false);
    }
  }, [createWithCustomer, selectedMethod]);

  useEffect(() => {
    if (createdWithCustomer) {
      confirmPayment(selectedMethod.id);
    }
  }, [createdWithCustomer]);

  const setFreightToPaid = async () => {
    const response = await fetch(`${BASE_URL}${MARK_INSTANT_AS_PAID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        instant_freight_id: modalPayInstantFreight.freightId,
        user_id: user_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Instant freight updated successfully") {
          toast.success(data.message);
          refetch();
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  };
  // TODO
  const registerFreightPayment = async (paymentIntentId: string) => {
    const response = await fetch(
      `${BASE_URL}${REGISTER_INSTANT_FREIGHT_PAYMENT}?user_id=${user_id}&freight_id=${modalPayInstantFreight.freightId}&freight_amount=${modalPayInstantFreight.amount}&stripe_customer_id=${customerId}&stripe_freight_payment_id=${paymentIntentId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (
          data.message === "Instant freight payment successfully registered."
        ) {
          toast.success(data.message);
          setFreightToPaid();
          // emailSendOtp();
        }
      });
    return response;
  };

  const retrievePayment = async (e?: any) => {
    e?.preventDefault();

    if (!stripe || !elements) return;

    //@ts-ignore
    const result = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          email: email,
          name: name,
        },
      },
    });

    const response: any = await createPaymentMethod(result?.paymentMethod?.id!);

    if (response?.message === "Payment method associated successfully") {
      confirmPayment(response?.data.payment_method_id);
      setSuccessfulPayment(true);
    }
  };

  useEffect(() => {
    if (successfulPayment) {
      retrievePayment();
    }
  }, [successfulPayment]);

  const confirmPayment = async (paymentMethod: string) => {
    if (!stripe) return;

    const { error, paymentIntent } = await stripe.confirmPayment({
      clientSecret: clientSecret,
      confirmParams: {
        payment_method: paymentMethod,

        return_url: "http://localhost:3000/dashboard-customer",
      },
      redirect: "if_required",
    });
    if (paymentIntent?.status === "succeeded") {
      toast.success(`Payment successful`);
      setShowSuccessfulMessage(true);
      registerFreightPayment(paymentIntent?.id);

      setTimeout(() => {
        modalPayInstantFreight.onClose();
      }, 2000);
      refetch();
    }

    if (error?.type === "card_error" || error?.type === "validation_error") {
      toast.error(`${error.message}`);
    } else {
      setMessage("An unexpected error occured");
    }
  };
  if (successfulPayment && !showSuccessfulMessage) {
    return (
      <div className="flex flex-col space-y-2  w-full justify-center items-center">
        <p className="text-lg font-medium">Fulfilling order</p>

        <Loader2 className="h-10 w-10 animate-spin" />
      </div>
    );
  }
  if (successfulPayment && showSuccessfulMessage) {
    return (
      <div className="flex flex-col space-y-2  w-full justify-center items-center">
        <p className="text-lg font-medium">Order successful!</p>

        <Check className="bg-green-500 p-2 text-white h-10 w-10 rounded-full" />
      </div>
    );
  }

  return (
    <div>
      <form id="payment-form" onSubmit={retrievePayment}>
        <h1 className="text-sm font-medium my-2 mb-6">
          Enter your card details
        </h1>

        <CardElement />
        <div className="mt-4 ml-auto justify-end w-full flex">
          <Button variant={"freightBlue"}>
            Pay {modalPayInstantFreight?.amount}€
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CheckoutCompleteInstantPayment;
