import { ArrowLeft, ArrowRight, Loader2 } from "lucide-react";
import ModalAssignOrder from "../components/modal/ModalAssignEcomOrder";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { useAssignOrder } from "../hooks/use-assign-order";
import { cn } from "../lib/utils";
import { SearchFreightOrdersProps } from "../types/types";

//@ts-ignore
import { ReactComponent as EmptySvg } from "../assets/images/data-not-found.svg";
//@ts-ignore
import OrdersAllCard from "../components/card/OrdersAllCard";

//@ts-ignore

const sortOptions = [
  {
    value: "desc",
    label: "Date Listed (Newest to Oldest)",
  },
  {
    value: "asc",
    label: "Date Listed (Oldest to Newest)",
  },
  // {
  //   id: 3,
  //   label: "Distance (Longest to Shortest)",
  // },
  // {
  //   id: 4,
  //   label: "Distance (Shortest to Longest)",
  // },
  // {
  //   id: 5,
  //   label: "Origin (A to Z)",
  // },
  // {
  //   id: 6,
  //   label: "Origin (Z to A)",
  // },
  // {
  //   id: 7,
  //   label: "Destination (A to Z)",
  // },
  // {
  //   id: 8,
  //   label: "Destination (Z to A)",
  // },
];

const SearchFreightOrders: React.FC<SearchFreightOrdersProps> = ({
  ecomPage,
  setEcomPage,
  ecomTotalPages,
  isLoading,
  isLoadingOrders,
  ordersEcom,
  setSortBy,
  refetchOrders,
}) => {
  const modalAssignOrder = useAssignOrder();

  return (
    <>
      <div className="  mt-20  max-w-7xl mx-auto">
        <div className="pb-4">
          <div className="mt-4 ml-auto w-full justify-end flex space-x-2">
            <div className="flex items-center space-x-2 ">
              <Select
                onValueChange={(val: any) => {
                  setSortBy(val.toLowerCase());
                }}
              >
                <SelectTrigger className="w-72">
                  <SelectValue placeholder="Sort by: Latest" />
                </SelectTrigger>
                <SelectContent>
                  {sortOptions.map((sort) => (
                    <SelectItem value={sort.value}>{sort.label}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <Button
              disabled={ecomPage === 1}
              onClick={() => setEcomPage(1)}
              className={cn(
                "h-6 w-6 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                ecomPage === 1 && "bg-[#333] text-white"
              )}
            >
              <p className="text-sm font-medium">1</p>
            </Button>
            <div
              onClick={() => {
                if (ecomPage > 1) {
                  setEcomPage((page) => page - 1);
                }
              }}
              className="h-6 w-6 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
            >
              <ArrowLeft className="h-4 w-4 text-[#333]" />
            </div>
            <div className="h-6 w-6 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition">
              {" "}
              <p className="text-sm font-medium">{ecomPage}</p>
            </div>
            <div
              onClick={() => {
                if (ecomPage !== ecomTotalPages) {
                  setEcomPage((page) => page + 1);
                }
              }}
              className="h-6 w-6 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
            >
              <ArrowRight className="h-4 w-4 text-[#333]" />
            </div>
            <Button
              onClick={() => setEcomPage(ecomTotalPages)}
              className={cn(
                "h-6 w-6 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                ecomPage === ecomTotalPages && "bg-[#333] text-white"
              )}
            >
              {isLoading ? (
                <p>
                  <Loader2 className="h-5 w-5 animate-spin" />
                </p>
              ) : (
                <p className="text-sm font-medium">{ecomTotalPages}</p>
              )}
            </Button>
          </div>
          <div className="mt-4 ml-auto w-full justify-end flex space-x-2"></div>
          {isLoadingOrders ? (
            <div className="flex flex-col space-y-4 mt-4">
              {new Array(4).fill("").map((_, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between bg-white border border-gray-200 p-4 rounded-lg shadow-sm animate-pulse"
                >
                  <div className="flex flex-col space-y-3">
                    <Skeleton className="h-5 w-20 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-md" />
                    <Skeleton className="h-5 w-20 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-md" />
                  </div>
                  <div className="flex flex-col space-y-4">
                    <Skeleton className="h-5 w-32 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-md" />
                    <Skeleton className="h-5 w-32 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 rounded-md" />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>
              {ordersEcom?.orders && ordersEcom.orders.length > 0 ? (
                <>
                  {ordersEcom.orders.map((order) => (
                    <OrdersAllCard key={order.id} order={order} />
                  ))}
                </>
              ) : (
                <div className="p-4 flex flex-col items-center justify-center bg-white border border-gray-200 mt-4 rounded-lg shadow-sm">
                  <EmptySvg className="h-16 w-16 text-gray-400" />
                  <p className="text-sm font-medium text-gray-600 mt-2">
                    No orders found
                  </p>
                </div>
              )}
            </>
          )}
        </div>
        {modalAssignOrder.isOpen && (
          <ModalAssignOrder refetchOrders={refetchOrders} />
        )}
      </div>
    </>
  );
};

export default SearchFreightOrders;
