import {
  BASE_URL,
  MARK_INSTANT_AS_PAID,
  REGISTER_INSTANT_FREIGHT_PAYMENT,
} from "src/api/config";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import toast from "react-hot-toast";
import { InstantFreight } from "src/types/types";
import { useNavigate } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { Button } from "../ui/button";
import { Check, Loader2 } from "lucide-react";
import * as animationData from "../../assets/SuccessPurchase.json";
import Lottie from "react-lottie";

interface PayInstantFreightCheckoutProps {
  profile: {
    Email: string;
    firstname: string;
    lastname: string;
  };
  instantFreight: InstantFreight;
  customerId: string;
  clientSecret: string;
  paymentIntentId: string;
  attachPaymentMethod: (paymentMethodToken: string) => void;
  selectedMethod: any;
  selectedWithPaymentMethod: boolean;
}

const PayInstantFreightCheckout: React.FC<PayInstantFreightCheckoutProps> = ({
  attachPaymentMethod,
  clientSecret,
  customerId,
  instantFreight,
  paymentIntentId,
  profile,
  selectedMethod,
  selectedWithPaymentMethod,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const [successfulWithSelectedMethod, setSuccesfulWithSelectedMethod] =
    useState(false);
  const [successfulPayment, setSuccessfulPayment] = useState(false);
  const [loadingRetrieve, setLoadingRetrieve] = useState(false);
  const [message, setMessage] = useState("");
  const [showSuccessfulMessage, setShowSuccessfulMessage] = useState(false);

  useEffect(() => {
    if (selectedWithPaymentMethod && stripe !== null) {
      setTimeout(() => {
        setSuccesfulWithSelectedMethod(true);
      }, 1000);
    } else {
      return;
    }
  }, [selectedWithPaymentMethod, stripe]);

  const setFreightToPaid = async () => {
    const response = await fetch(`${BASE_URL}${MARK_INSTANT_AS_PAID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        instant_freight_id: instantFreight.id,
        user_id: user_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Instant freight updated successfully") {
          toast.success(data.message);
          // refetch();
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  };

  const registerFreightPayment = async (paymentIntentId: string) => {
    const response = await fetch(
      `${BASE_URL}${REGISTER_INSTANT_FREIGHT_PAYMENT}?user_id=${user_id}&freight_id=${instantFreight.id}&freight_amount=${instantFreight.estimated_price}&stripe_customer_id=${customerId}&stripe_freight_payment_id=${paymentIntentId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (
          data.message === "Instant freight payment successfully registered."
        ) {
          toast.success(data.message);
          setFreightToPaid();
          // emailSendOtp();
        }
      });
    return response;
  };

  const retrievePayment = async (e?: any) => {
    e?.preventDefault();

    if (!stripe || !elements) return;

    //@ts-ignore
    const result = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          email: profile?.Email,
          name: `${profile?.firstname} ${profile?.lastname}`,
        },
      },
    });

    const response: any = await attachPaymentMethod(result?.paymentMethod?.id!);

    if (response?.message === "Payment method associated successfully") {
      confirmPayment(response?.data.payment_method_id);
      setSuccessfulPayment(true);
      setLoadingRetrieve(false);
    }
  };

  const confirmPayment = async (paymentMethod: string) => {
    if (!stripe) return;

    const { error, paymentIntent } = await stripe.confirmPayment({
      clientSecret: clientSecret,
      confirmParams: {
        payment_method: paymentMethod,

        return_url: "http://localhost:3000/dashboard-customer",
      },
      redirect: "if_required",
    });
    if (paymentIntent?.status === "succeeded") {
      toast.success(`Payment successful`);
      setShowSuccessfulMessage(true);
      registerFreightPayment(paymentIntent?.id);

      setTimeout(() => {
        navigate("/my-instant-freights");
      }, 2000);
    }

    if (error?.type === "card_error" || error?.type === "validation_error") {
      toast.error(`${error.message}`);
    } else {
      setMessage("An unexpected error occured");
    }
  };
  useEffect(() => {
    retrievePaymentWithRegisteredCard();
  }, [successfulWithSelectedMethod]);

  const retrievePaymentWithRegisteredCard = async () => {
    if (!stripe || !elements) return;

    const response: any = await attachPaymentMethod(selectedMethod?.id!);

    if (
      //   response?.message === "Payment method associated successfully" ||
      response.message ===
      "The payment method is already associated with a user."
    ) {
      setSuccessfulPayment(true);
      confirmPayment(selectedMethod?.id!);
    }
  };

  const [isStopped, setIsStopped] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  if (successfulPayment && !showSuccessfulMessage) {
    return (
      <div className="flex flex-col space-y-2 w-full justify-center items-center">
        <p className="text-lg font-medium">Fulfilling payment</p>

        <Loader2 className="h-10 w-10 animate-spin" />
      </div>
    );
  }
  if (successfulPayment && showSuccessfulMessage) {
    return (
      <div className="flex flex-col space-y-2  w-full justify-center items-center">
        <p className="text-lg font-medium">Payment successful!</p>
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={isStopped}
          isPaused={isPaused}
        />
        {/* <Check className="bg-green-500 p-2 text-white h-10 w-10 rounded-full" /> */}
      </div>
    );
  }
  return (
    <form id="payment-form" onSubmit={retrievePayment}>
      {!selectedWithPaymentMethod && (
        <>
          <h1 className="text-lg border-b pb-2 font-medium mb-4">
            Enter your card details
          </h1>
          <CardElement />
          <div className="mt-4 ml-auto justify-end w-full flex">
            <Button variant={"freightBlue"}>
              {loadingRetrieve ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                `Pay ${instantFreight?.estimated_price}€`
              )}
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export default PayInstantFreightCheckout;
