interface SubcategoryFilterProps {
  subcat: {
    id: string;
    checked: boolean;
    catname: string;
  };
  handleSubcategoryChange: (id: any) => void;
  checkedSubcategories: { catname: string; id: string; checked: boolean }[];
}

const SubcategoryFilter: React.FC<SubcategoryFilterProps> = ({
  handleSubcategoryChange,
  subcat,
  checkedSubcategories,
}) => {
  return (
    <div className="flex items-start space-x-2 pl-8">
      <input
        type="checkbox"
        id={subcat.id}
        onChange={() => {
          handleSubcategoryChange(subcat.id);
        }}
      />{" "}
      &nbsp; <p className="text-sm font-medium">{subcat.catname}</p>
    </div>
  );
};

export default SubcategoryFilter;
