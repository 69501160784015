import { useQuery } from "@tanstack/react-query";
import { ChevronLeft, MapPin } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import {
  ADD_QUOTE_QUESTION,
  BASE_IMG_URL,
  BASE_URL,
  FREIGHT_DETAIL,
  FREIGHT_QUESTIONS,
  FREIGHT_QUOTELIST,
  GET_IMAGE,
} from "../api/config";
import small from "../assets/images/books-box.png";
import large from "../assets/images/moving-box.png";
import medium from "../assets/images/shoes-box.png";
import Header from "../components/header/Header";
import { Button } from "../components/ui/button";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";

import { zodResolver } from "@hookform/resolvers/zod";
import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useForm } from "react-hook-form";
import { GOOGLE_API_KEY } from "../api/config";
import ModalViewQuoteDetails from "../components/modal/ModalViewQuoteDetails";
import { Skeleton } from "../components/skeleton/Skeleton";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form";
import { Input } from "../components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { useViewQuoteDetails } from "../hooks/use-view-quote";
import { cn } from "../lib/utils";
import { QuoteDetailType } from "../types/types";

const containerStyle = {
  width: "100%",
  height: "100%",
  borderRadius: "4px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const formQuestionSchema = z.object({
  question: z.string().min(1),
});

const ViewFreight = () => {
  const params = useParams();
  const modalQuoteDetails = useViewQuoteDetails();
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const [map, setMap] = useState(null);
  const [showQuotes, setShowQuotes] = useState(false);
  const navigate = useNavigate();
  const [directionResponse, setDirectionsResponse] = useState<any>(null);
  const [distance, setDistance] = useState<any>();
  const [duration, setDuration] = useState<any>();

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });

  const form = useForm<z.infer<typeof formQuestionSchema>>({
    resolver: zodResolver(formQuestionSchema),
    defaultValues: {
      question: "",
    },
  });

  const onLoad = useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const getFreightQuestionsandAnswers = async () => {
    const response = await fetch(
      `${BASE_URL}${FREIGHT_QUESTIONS}/${params.id}`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const { data: questionAndAnswers } = useQuery({
    queryKey: ["question_answers"],
    queryFn: getFreightQuestionsandAnswers,
  });

  const addQuestion = async (message: string) => {
    const response = await fetch(`${BASE_URL}${ADD_QUOTE_QUESTION}`, {
      body: JSON.stringify({
        user_id: user_id,
        freightid: freightDetails.id,
        message: message,
      }),
      headers: {
        AccessToken: token,
      },
    }).then((res) => res.json());

    return response;
  };

  const getFreightDetails = async () => {
    const response = await fetch(
      `${BASE_URL}${FREIGHT_DETAIL}/${params.id}${
        user_id === "" ? "" : `/${user_id}`
      }`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    ).then((res) => res.json());

    return response?.data[0];
  };

  const { data: freightDetails, isLoading } = useQuery({
    queryKey: ["freight_details", params.id],
    queryFn: getFreightDetails,
  });

  const onSubmit = (values: z.infer<typeof formQuestionSchema>) => {
    addQuestion(values.question);
  };

  const getQuoteList = async () => {
    const response = await fetch(`${BASE_URL}${FREIGHT_QUOTELIST}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        freight_id: params.id,
        user_id: user_id,
      }),
    }).then((res) => res.json());

    return response;
  };

  useEffect(() => {
    if (!isLoading) {
      calculateRoute();
    }
  }, [isLoading, freightDetails]);

  const { data: quoteList } = useQuery({
    queryKey: ["quote_list"],
    queryFn: getQuoteList,
  });
  async function calculateRoute() {
    const directionsService = new google.maps.DirectionsService();

    //@ts-ignore
    const results = await directionsService.route({
      origin: {
        lat: Number(freightDetails?.origin_lat),
        lng: Number(freightDetails?.origin_lng),
      },
      destination: {
        lat: Number(freightDetails?.destination_lat),
        lng: Number(freightDetails?.destination_lng),
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance?.text);
    setDuration(results.routes[0].legs[0].duration?.text);
  }

  useEffect(() => {
    console.log(
      "quoteList",
      quoteList?.data?.filter(
        (quote: QuoteDetailType) => quote.status === "accepted"
      )
    );
  }, [quoteList]);

  if (isLoading) {
    return (
      <div className="">
        <Header />
        <div className="max-w-7xl mx-auto p-16">
          <div
            onClick={() => navigate("/searchFreight")}
            className="flex items-center space-x-2 group cursor-pointer mb-4"
          >
            <ChevronLeft className="text-freightBlueDark h-5 w-5" />
            <h1 className="text-freightBlueDark text-sm group-hover:underline ">
              Back
            </h1>
          </div>
          <div className="flex flex-col space-y-2 my-4">
            <Skeleton className=" h-5 w-40" />
            <Skeleton className=" h-5 w-20" />
          </div>
          <div className="bg-gray-50 border-border border">
            <div className="flex items-start space-x-4 bg-white m-4 p-4 border-border border justify-between">
              <div>
                <Skeleton className="h-5 w-24" />
                <Skeleton className="h-5 w-20 mt-4" />
                <Skeleton className="h-5 w-16 mt-4" />
              </div>
              <div>
                <Skeleton className="h-5 w-40 mt-4" />
                <Skeleton className="h-5 w-32 mt-4" />
                <Skeleton className="h-5 w-60 mt-4" />
              </div>
              {/* {

                */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className=" max-w-7xl mx-auto  sm:p-16">
        <div
          onClick={() => navigate("/searchFreight")}
          className="flex items-center space-x-2 group cursor-pointer mb-4"
        >
          <ChevronLeft className="text-freightBlueDark h-5 w-5" />
          <h1 className="text-freightBlueDark text-sm group-hover:underline ">
            Back
          </h1>
        </div>

        <h1 className="text-lg mb-2">
          <span className="font-bold text-xl">{freightDetails?.title}</span>{" "}
          from{" "}
          <span className="font-semibold text-lg">
            {freightDetails.origin_address.split(" ").pop()}
          </span>{" "}
          to{" "}
          <span className="font-semibold text-lg">
            {freightDetails.destination_address.split(" ").pop()}
          </span>
        </h1>
        <div className="bg-gray-50 border-border border">
          <div className="sm:flex items-start  bg-white m-4 p-4 border-border border sm:justify-between">
            <div className="flex flex-col space-y-2">
              <div className="bg-gray-50  rounded border p-4 flex items-center justify-between">
                <p className="text-sm font-medium">Number of Quotes</p>{" "}
                <p className="text-sm font-medium text-gray-500 pl-2">
                  {freightDetails?.quotes}
                </p>
              </div>
              <div className="bg-gray-50  rounded border p-4 flex items-center justify-between">
                <p className="text-sm font-medium">Questions</p>{" "}
                <p className="text-sm font-medium text-gray-500 pl-2">
                  {freightDetails?.questions}
                </p>
              </div>
              <div className="bg-gray-50  rounded border p-4 flex items-center justify-between">
                <p className="font-medium text-sm">Views</p>{" "}
                <p className="text-sm font-medium text-gray-500 pl-2">
                  {freightDetails?.views}
                </p>
              </div>
            </div>
            <div className="sm:flex items-start mt-2 sm:mt-0 sm:space-x-4">
              <div className="flex flex-col space-y-2">
                <div className="bg-gray-50  rounded border p-4 flex items-center ">
                  <p className="text-sm font-medium">Status:</p>
                  <p
                    className={cn(
                      "bg-gray-400 text-white px-2 rounded uppercase ml-4 text-sm font-medium",
                      freightDetails?.status === "active" && "bg-green-500",
                      freightDetails?.status === "booked" &&
                        "bg-freightBlueDark"
                    )}
                  >
                    {freightDetails?.status}
                  </p>
                </div>
                <div className="bg-gray-50  rounded border p-4 flex items-center space-x-4">
                  <p className="text-sm font-medium">Date Posted:</p>
                  <p className="text-sm font-medium text-gray-500">
                    {" "}
                    {freightDetails?.posted_date}
                  </p>
                </div>
              </div>
              <div className="flex flex-col space-y-2 lg:mt-2 mt-0">
                <div className="bg-gray-50  rounded border p-4 flex items-center space-x-4">
                  <p className="text-sm font-medium">Expires:</p>
                  <p className="text-sm font-medium text-gray-500">
                    {freightDetails?.expire_date}
                  </p>
                </div>
                {freightDetails?.customer_name !== null && (
                  <div className="bg-gray-50  rounded border p-4 flex items-center space-x-4">
                    <p className="text-sm font-medium">Customer:</p>{" "}
                    <p className="text-sm font-medium text-gray-500">
                      {freightDetails?.customer_name}
                    </p>
                  </div>
                )}
              </div>
            </div>

            {user_type === "carrier" &&
              (quoteList?.data?.filter(
                (quote: QuoteDetailType) => quote.status === "accepted"
              ).length === 0 ||
                quoteList?.message === "Quote detail.") && (
                <div className="sm:mt-0 mt-4">
                  <Button
                    onClick={() =>
                      navigate(`/freight/quoteFreight/${freightDetails?.id}`)
                    }
                    variant={"freightBlue"}
                  >
                    Provide Quote
                  </Button>
                </div>
              )}
          </div>
          <div className="sm:grid grid-cols-2 w-full p-4 gap-2 ">
            <div className="p-4 col-span-1 bg-white border border-border">
              <div className="bg-gray-50  rounded  p-4 flex items-center space-x-4">
                <h1 className="font-medium">Freight Description:</h1>

                <p className="text-sm font-medium text-gray-500">
                  {freightDetails?.description}
                </p>
              </div>
              <div className="bg-gray-50  rounded  p-4 flex items-center space-x-4">
                <h1 className="font-medium">Pickup Date Range</h1>
                <div className="flex items-center space-x-2">
                  <p className="text-sm font-medium text-gray-500">
                    {freightDetails?.start_date}
                  </p>
                  <p className="text-sm font-medium text-gray-500">
                    {freightDetails?.end_date}
                  </p>
                </div>
              </div>
              <div className="bg-gray-50  rounded  p-4 flex items-center space-x-4">
                <h1 className="font-medium">Freight Category</h1>
                {freightDetails.title === "Normal package freight" ? (
                  <>
                    <p className="text-sm font-medium text-gray-500">
                      Normal Package
                    </p>{" "}
                  </>
                ) : (
                  <>
                    <p>
                      {freightDetails?.freight_type_info?.freight_category !==
                      ""
                        ? freightDetails?.freight_type_info?.freight_category
                        : "Package"}{" "}
                      / {freightDetails?.freight_type_info?.freight_subcategory}
                    </p>
                    <img
                      src={`${BASE_IMG_URL}${freightDetails?.freight_type_info.freight_subcategory_image}`}
                      className="h-16 w-16 object-contain"
                      alt="category"
                    />
                  </>
                )}
              </div>

              <div className="flex flex-col items-start mt-2 w-full">
                <div className="flex items-center space-x-2    py-2 ">
                  <h3 className="font-medium  text-lg text-gray-500">Items</h3>
                </div>
                <div className="w-full"></div>
              </div>
              <div className="mt-4">
                {freightDetails.animal_img ? (
                  <div>
                    <img
                      className="h-40 object-cover rounded-lg shadow-sm"
                      alt={freightDetails.title}
                      src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${freightDetails.animal_img}&image_type=freight`}
                    />
                  </div>
                ) : (
                  <>
                    <div className="flex items-center justify-center w-40 h-40 rounded-lg shadow-sm">
                      {freightDetails.freight_type_info.freight_size.id ===
                        1 && (
                        <img
                          src={small}
                          className="h-22 w-22 object-contain cursor-pointer"
                          alt="small package"
                          onClick={() =>
                            navigate(
                              `/customer/freightDetails/${freightDetails.id}`
                            )
                          }
                        />
                      )}
                      {freightDetails.freight_type_info.freight_size.id ===
                        2 && (
                        <img
                          src={medium}
                          className="h-22 w-22 object-contain cursor-pointer"
                          alt="medium package"
                          onClick={() =>
                            navigate(
                              `/customer/freightDetails/${freightDetails.id}`
                            )
                          }
                        />
                      )}
                      {freightDetails.freight_type_info.freight_size.id ===
                        3 && (
                        <img
                          src={large}
                          className="h-22 w-22 object-contain cursor-pointer"
                          alt="large package"
                          onClick={() =>
                            navigate(
                              `/customer/freightDetails/${freightDetails.id}`
                            )
                          }
                        />
                      )}
                    </div>
                    <div className="mt-3">
                      <p className="font-bold flex gap-2 capitalize">
                        {freightDetails.freight_type_info.freight_size.name}{" "}
                        Dimensions
                      </p>
                      <p>
                        {freightDetails.freight_type_info.freight_size.length} x{" "}
                        {freightDetails.freight_type_info.freight_size.width} x{" "}
                        {freightDetails.freight_type_info.freight_size.height}{" "}
                        cm
                      </p>
                      <p>
                        {freightDetails.freight_type_info.freight_size.weight}{" "}
                        kg
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-span-1 bg-white rounded">
              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={{
                    lat: Number(freightDetails?.origin_lat),
                    lng: Number(freightDetails?.origin_lng),
                  }}
                  options={{
                    streetViewControl: false,
                  }}
                  zoom={15}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                >
                  <Marker
                    position={{
                      lat: Number(freightDetails?.origin_lat),
                      lng: Number(freightDetails?.origin_lng),
                    }}
                  />
                  <Marker
                    position={{
                      lat: Number(freightDetails?.destination_lat),
                      lng: Number(freightDetails?.destination_lng),
                    }}
                  />
                  {directionResponse && (
                    <DirectionsRenderer directions={directionResponse} />
                  )}
                </GoogleMap>
              ) : null}
            </div>
            <div className="flex flex-col items-start bg-white  py-4 px-4 w-full space-y-2">
              <div className="flex items-center justify-between  w-full ">
                <h1 className="text-sm font-medium">Driving Distance: </h1>
                <div className="bg-green-500 text-white px-4 py-1 rounded">
                  <p className="text-sm font-medium">{distance}</p>
                </div>
              </div>
              <div className="flex items-center justify-between  w-full ">
                <h1 className="text-sm font-medium">
                  Approximate driving time:{" "}
                </h1>
                <div className="bg-freightBlueDark text-white px-4 rounded py-1">
                  <p className="text-sm font-medium">{duration}</p>
                </div>
              </div>
            </div>

            <div className="sm:flex flex-col items-start bg-white  rounded-t   py-4 px-4 w-full space-y-2">
              <div className="flex items-start justify-between py-2 px-2">
                <div className="flex items-center space-x-2 w-60">
                  <MapPin className="h-6 w-6 text-green-500" />
                  <h1 className="font-medium text-sm">Pickup Location</h1>
                </div>
                <div className="flex flex-col w-60">
                  <h1 className="text-sm font-semibold">
                    {freightDetails?.origin_address}
                  </h1>
                  <p className="text-gray-500 text-sm font-medium mt-2">
                    {freightDetails?.origin_location_type}
                  </p>
                </div>
              </div>
              <div className="flex items-start justify-between py-2 px-2">
                <div className="flex items-center space-x-2 w-60">
                  <MapPin className="h-6 w-6 text-red-500" />
                  <h1 className="font-medium text-sm">Dropoff Location</h1>
                </div>
                <div className="flex flex-col w-60">
                  <h1 className="text-sm font-semibold">
                    {freightDetails?.destination_address}
                  </h1>
                  <p className="text-gray-500 text-sm font-medium mt-2">
                    {freightDetails?.destination_location_type}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10 max-w-7xl mx-auto">
          <div>
            <div>
              <Button
                variant={!showQuotes ? "ghost" : "freightBlue"}
                onClick={() => setShowQuotes(true)}
                className="px-4 py-2"
              >
                Quotes
              </Button>
              <Button
                variant={showQuotes ? "ghost" : "freightBlue"}
                onClick={() => setShowQuotes(false)}
                className="font-medium px-4 py-2"
              >
                Questions
              </Button>
            </div>
            {showQuotes && (
              <div className="mt-4">
                {user_type === "carrier" &&
                  (quoteList?.data?.filter(
                    (quote: QuoteDetailType) => quote.status === "accepted"
                  ).length === 0 ||
                    quoteList?.message === "Quote detail.") && (
                    <Button
                      onClick={() =>
                        navigate(`/freight/quoteFreight/${freightDetails.id}`)
                      }
                      variant={"freightBlue"}
                    >
                      Provide Quote to Move this Freight
                    </Button>
                  )}
              </div>
            )}
            {quoteList?.data?.length > 0 && showQuotes && (
              <>
                <Table className="border border-border  mt-4">
                  <TableHeader>
                    <TableRow>
                      <TableHead>Quote Amount</TableHead>
                      <TableHead>Transport Provider Listed</TableHead>
                      <TableHead>Expiry Date</TableHead>
                      <TableHead>Messages</TableHead>
                      <TableHead>Quote Details</TableHead>
                      <TableHead>Quote Status</TableHead>
                      <TableHead>Schedule Pickup</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {quoteList?.data?.map((quote: QuoteDetailType) => (
                      <TableRow>
                        <TableCell>
                          {Number(quote.amount).toFixed(2)}€
                        </TableCell>
                        <TableCell>
                          {quote.vehicle_type}
                          <p className="text-gray-400">{quote.transporter}</p>
                        </TableCell>
                        <TableCell>
                          {quote.expire_date}
                          <p className="text-gray-500">
                            {quote.expire_days} days
                          </p>
                        </TableCell>
                        <TableCell>
                          <p className="">{quote.details}</p>
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            modalQuoteDetails.onOpen("quoteDetails", quote)
                          }
                        >
                          <p className="hover:underline text-freightBlueDark cursor-pointer ">
                            View Quote Details
                          </p>
                        </TableCell>
                        <TableCell>
                          <div
                            className={cn(
                              quote.status === "accepted" &&
                                "bg-yellow-500  text-white",
                              quote.status === "active" &&
                                "bg-green-500 text-white",
                              quote.status === "cancelled" &&
                                "bg-gray-500 text-white",
                              "px-2 text-center py-2 rounded shadow text-sm"
                            )}
                          >
                            {quote.status.toUpperCase()}{" "}
                          </div>
                          <p className="text-gray-500 text-xs mt-2">
                            {quote.status_reason}
                          </p>
                        </TableCell>
                        <TableCell>
                          <p className="capitalize text-sm font-medium">
                            {quote.pickup_at}
                          </p>
                          <div className="flex flex-col items-start">
                            <p className="text-gray-400">
                              {quote.pickup_date1}
                            </p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
            {showQuotes &&
              quoteList?.message === "Quote detail." &&
              !quoteList?.data && (
                <div className=" mt-10 border border-border rounded p-4">
                  <p className="text-sm font-medium">No quotes available.</p>
                </div>
              )}
            {!showQuotes && (
              <div className="border-border border p-10 mt-4">
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="w-full mb-4 relative">
                      <FormField
                        name="question"
                        control={form.control}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Ask a question</FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                name="question"
                                type="text"
                                className="w-full dark:bg-transparent"
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>
                    <Button disabled variant={"freightBlue"}>
                      Submit
                    </Button>
                  </form>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>
      {modalQuoteDetails.isOpen && <ModalViewQuoteDetails />}
    </div>
  );
};

export default ViewFreight;
