import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { parse } from "date-fns";
import { CalendarIcon, ChevronLeft, Euro, Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { z } from "zod";
import {
  ADD_FREIGHT,
  BASE_IMG_URL,
  BASE_URL,
  FREIGHT_DETAIL,
  GET_IMAGE,
} from "../api/config";
import small from "../assets/images/books-box.png";
import large from "../assets/images/moving-box.png";
import medium from "../assets/images/shoes-box.png";
import Header from "../components/header/Header";
import ModalAddQuote from "../components/modal/ModalAddQuote";
import { Button } from "../components/ui/button";
import { Calendar } from "../components/ui/calendar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form";
import { Input } from "../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { Textarea } from "../components/ui/textarea";
import { useAddQuote } from "../hooks/use-add-quote";
import { cn, formatDate, pickupFormatFreightDate } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
// import toast from "react-hot-toast";
import { format } from "date-fns";
import { Bounce, toast } from "react-toastify";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover";

const formSchema = z.object({
  quote: z.string().min(1, "Quote is required"),
  // vehicleType: z.string().min(1, "Vehicle type is required"),
  pickupTime: z.string().min(1, "Pickup time is required"),
  pickupDate: z.date().nullable(),
  dropoffTime: z.string().min(1, "Dropoff is required"),
  dropoffDate: z.date().nullable(),
  otherComments: z.string().optional(),
  termsConditions: z.boolean(),
});

const ProvideQuote = () => {
  const params = useParams();
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const [pickupDate, setPickupDate] = useState(new Date());
  const [showPickupDate, setShowPickupDate] = useState(false);
  const modalQuote = useAddQuote();
  const [dropoffDate, setDropoffDate] = useState(new Date());
  const [showDropoffDate, setShowDropoffDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    modalQuote.onClose();
  }, []);
  const getFreightDetails = async () => {
    const response = await fetch(
      `${BASE_URL}${FREIGHT_DETAIL}/${params.id}/${user_id}`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    ).then((res) => res.json());

    return response?.data[0];
  };

  const formProvideQuoteDetails = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      dropoffDate: null,
      dropoffTime: "",

      otherComments: "",
      pickupDate: null,
      pickupTime: "",
      quote: "",
      termsConditions: false,
      // vehicleType: "",
    },
  });

  const { data: freightDetails, isLoading } = useQuery({
    queryKey: ["freight_details", params.id],
    queryFn: getFreightDetails,
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    setLoading(true);
    addQuote(
      values.quote,
      values.otherComments ?? "",
      values.pickupTime,
      values.dropoffTime,
      values.pickupDate ? formatDate(values.pickupDate) : "", // Provide a fallback
      "",
      values.dropoffDate ? formatDate(values.dropoffDate) : "", // Provide a fallback
      ""
    );
  };

  const addQuote = async (
    quoteAmount: string,
    // vehicleType: string,
    otherComments: string,
    pickupAt: string,
    dropoffAt: string,
    pickup_date1: string,
    pickup_date2: string,
    dropoff_date1: string,
    dropoff_date2: string
  ) => {
    const response = await fetch(`${BASE_URL}${ADD_FREIGHT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        freightid: params.id,
        customerId: freightDetails.customer_id,
        amount: quoteAmount,
        details: otherComments,
        pickup_at: pickupAt,
        dropoff_at: dropoffAt,
        expire_date: freightDetails?.end_date,
        pickup_date1: `${pickupFormatFreightDate(pickup_date1)}`,
        pickup_date2: `${pickupFormatFreightDate(pickup_date1)}`,
        dropoff_date1: `${pickupFormatFreightDate(dropoff_date1)}`,
        dropoff_date2: `${pickupFormatFreightDate(dropoff_date1)}`,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Quote added successfully.") {
          modalQuote.onOpen("addQuote", data.data[0]);
          formProvideQuoteDetails.reset({
            dropoffDate: new Date(),
            dropoffTime: "",
            otherComments: "",
            pickupDate: new Date(),
            pickupTime: "",
            quote: "",
            termsConditions: false,
            // vehicleType: "",
          });
        } else if (
          data.message === "Duplicate quote for the same carrier and freight."
        ) {
          toast.error(
            "Quote already sent previously. Limit 1 quote per freight.",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            }
          );
          // toast.error(`Quote limit exceeded`);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });

    return response;
  };

  return (
    <div className="h-screen">
      <Header />
      <div className="max-w-7xl mx-auto p-16 h-[94vh] overflow-y-auto no-scrollbar">
        <div
          onClick={() => navigate(`/freight/freightDetails/${params.id}`)}
          className="flex items-center space-x-2 group cursor-pointer mb-4"
        >
          <ChevronLeft className="text-freightBlueDark h-5 w-5" />
          <h1 className="text-freightBlueDark text-sm group-hover:underline ">
            Back
          </h1>
        </div>
        <div className="sm:grid grid-cols-2 w-full bg-gray-50  border-border border shadow  my-4">
          <div className="p-4 col-span-1">
            <div>
              <div className="sm:flex items-center justify-between">
                <h1 className="font-bold text-xl">{freightDetails?.title}</h1>
                <div className="flex items-center space-x-2">
                  <h1 className="text-sm font-semibold text-">
                    {freightDetails?.customer_name}
                  </h1>
                  {freightDetails?.cust_img !== null ? (
                    <img
                      src={require("../assets/images/user-icon-placeholder.jpg")}
                      className="h-16 w-16 object-cpver rounded-full"
                      alt="customer-profile"
                    />
                  ) : (
                    <img
                      src={`${BASE_URL}images?user_id=${user_id}&file_name=${freightDetails?.cust_img}&image_type=profile`}
                      alt="customer-profile"
                      className="h-16 w-16 object-cover rounded-full "
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col space-y-2 mt-4">
                <div className="bg-white  rounded border p-4 flex items-center justify-between">
                  <div>
                    <p className="text-sm font-medium">Origin</p>
                    <p className="text-sm font-medium text-gray-500">
                      {freightDetails?.origin_address}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-medium">Start</p>
                    <p className="text-sm font-medium text-gray-500">
                      {freightDetails?.start_date}
                    </p>
                  </div>
                </div>
                <div className="bg-white  rounded border p-4  flex items-center justify-between">
                  <div>
                    <p className="text-sm font-medium">Destination</p>
                    <p className="text-sm font-medium text-gray-500">
                      {freightDetails?.destination_address}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm font-medium">End</p>
                    <p className="text-sm font-medium text-gray-500">
                      {freightDetails?.end_date}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-4 border p-4 justify-between bg-white rounded">
                  <h1 className="font-medium">Freight Category</h1>
                  {freightDetails?.title === "Normal package freight" ? (
                    <>
                      <p className="text-sm font-medium text-gray-500">
                        Normal Package
                      </p>{" "}
                    </>
                  ) : (
                    <>
                      <p>
                        {freightDetails?.freight_type_info?.freight_category} /{" "}
                        {freightDetails?.freight_type_info?.freight_subcategory}
                      </p>
                      <img
                        src={`${BASE_IMG_URL}${freightDetails?.freight_type_info.freight_subcategory_image}`}
                        className="h-16 w-16 object-contain"
                        alt="category"
                      />
                    </>
                  )}
                </div>
                <div className="flex items-center space-x-4 border p-4 justify-between bg-white border-border">
                  <h1 className="font-medium text-sm">Distance</h1>
                  <p className="text-sm font-medium text-gray-500">
                    {freightDetails?.delivery_distance}
                  </p>
                </div>
                <div className="flex items-center space-x-4 border p-4 justify-between bg-white border-border">
                  <h1 className="font-medium text-sm">Duration</h1>
                  <p className="text-sm font-medium text-gray-500">
                    {freightDetails?.delivery_duration}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-10">
              <h1 className="font-semibold text-sm mb-4">Items</h1>
              <div className="mt-4">
                {freightDetails?.animal_img ? (
                  <img
                    className="h-40 object-cover rounded-lg shadow-sm"
                    alt={freightDetails?.title}
                    src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${freightDetails.animal_img}&image_type=freight`}
                  />
                ) : (
                  <>
                    <div className="flex items-center justify-center w-40 h-40 rounded-lg shadow-sm">
                      {freightDetails?.freight_type_info.freight_size.id ===
                        1 && (
                        <img
                          src={small}
                          className="h-22 w-22 object-contain cursor-pointer"
                          alt="small package"
                          onClick={() =>
                            navigate(
                              `/customer/freightDetails/${freightDetails.id}`
                            )
                          }
                        />
                      )}
                      {freightDetails?.freight_type_info.freight_size.id ===
                        2 && (
                        <img
                          src={medium}
                          className="h-22 w-22 object-contain cursor-pointer"
                          alt="medium package"
                          onClick={() =>
                            navigate(
                              `/customer/freightDetails/${freightDetails.id}`
                            )
                          }
                        />
                      )}
                      {freightDetails?.freight_type_info.freight_size.id ===
                        3 && (
                        <img
                          src={large}
                          className="h-22 w-22 object-contain cursor-pointer"
                          alt="large package"
                          onClick={() =>
                            navigate(
                              `/customer/freightDetails/${freightDetails.id}`
                            )
                          }
                        />
                      )}
                    </div>
                    {freightDetails && (
                      <div>
                        <p className="font-bold flex gap-2 capitalize">
                          {freightDetails?.freight_type_info.freight_size.name}
                        </p>
                        <p>
                          {
                            freightDetails?.freight_type_info.freight_size
                              .length
                          }{" "}
                          cm x{" "}
                          {freightDetails?.freight_type_info.freight_size.width}{" "}
                          cm x{" "}
                          {
                            freightDetails?.freight_type_info.freight_size
                              .height
                          }{" "}
                          cm
                        </p>
                        <p>
                          {
                            freightDetails?.freight_type_info.freight_size
                              .weight
                          }{" "}
                          kg
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="p-4 mr-4 col-span-1 bg-white border border-border my-2">
            <h1 className="text-xl font-medium mb-10">Provide a Quote</h1>
            <Form {...formProvideQuoteDetails}>
              <form
                onSubmit={formProvideQuoteDetails.handleSubmit(onSubmit)}
                className="w-full flex flex-col"
              >
                <div className="w-full mb-4">
                  <FormField
                    control={formProvideQuoteDetails.control}
                    name="quote"
                    render={({ field, fieldState }) => (
                      <div>
                        <FormItem className="relative">
                          <FormLabel>Your Quote</FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              name="quote"
                              className="w-full dark:bg-transparent"
                            />
                          </FormControl>{" "}
                          <div className="absolute top-1 right-2">
                            <Euro className="h-4 w-4 text-gray-500" />
                          </div>
                          <FormMessage />
                        </FormItem>
                      </div>
                    )}
                  />
                </div>
                <div className="w-full mb-4 ">
                  <FormField
                    control={formProvideQuoteDetails.control}
                    name="pickupTime"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Pickup Time</FormLabel>
                        <Select {...field} onValueChange={field.onChange}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select " />
                          </SelectTrigger>

                          <SelectContent>
                            <SelectItem value="morning">Morning</SelectItem>
                            <SelectItem value="afternoon">Afternoon</SelectItem>
                            <SelectItem value="anytime">Anytime</SelectItem>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="w-full mb-4 ">
                  {/* <h1 className="font-medium mb-4 text-sm">Your Pickup Date</h1> */}
                  <div className=" sm:flex items-end justify-between">
                    <div className="relative ">
                      <FormField
                        control={formProvideQuoteDetails.control}
                        name="pickupDate"
                        render={({ field }) => (
                          <FormItem className="flex flex-col">
                            {/* <FormLabel>Pick up date</FormLabel> */}
                            <Popover>
                              <PopoverTrigger asChild>
                                <FormControl>
                                  <Button
                                    type="button"
                                    variant={"outline"}
                                    className={cn(
                                      "w-[240px] pl-3 text-left font-normal",
                                      !field.value && "text-muted-foreground"
                                    )}
                                  >
                                    {field.value ? (
                                      format(field.value, "PPP")
                                    ) : (
                                      <span>Pick a date</span>
                                    )}
                                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                  </Button>
                                </FormControl>
                              </PopoverTrigger>
                              <PopoverContent className="w-auto p-0">
                                <Calendar
                                  mode="single"
                                  selected={field.value || undefined}
                                  onSelect={field.onChange}
                                  disabled={(date) => {
                                    const dropOffDate =
                                      formProvideQuoteDetails.watch(
                                        "dropoffDate"
                                      );
                                    const startDate = freightDetails?.start_date
                                      ? parse(
                                          freightDetails.start_date,
                                          "dd-MM-yyyy",
                                          new Date()
                                        )
                                      : null;
                                    const endDate = freightDetails?.end_date
                                      ? parse(
                                          freightDetails.end_date,
                                          "dd-MM-yyyy",
                                          new Date()
                                        )
                                      : null;

                                    // Ensure we always return a boolean
                                    if (!dropoffDate) return false; // If no dropoff date, don't disable any dates
                                    if (startDate && date < startDate)
                                      return true;
                                    if (dropOffDate && date > dropOffDate)
                                      return true; // Only check if dropOffDate exists
                                    if (endDate && date > endDate) return true; // Disable dates after end date
                                    return false; // Enable all other dates
                                  }}
                                  initialFocus
                                />
                              </PopoverContent>
                            </Popover>

                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full mb-4">
                  <div>
                    <FormField
                      control={formProvideQuoteDetails.control}
                      name="dropoffTime"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Dropoff Time</FormLabel>
                          <Select {...field} onValueChange={field.onChange}>
                            <SelectTrigger>
                              <SelectValue placeholder="Select " />
                            </SelectTrigger>

                            <SelectContent>
                              <SelectItem value="morning">Morning</SelectItem>
                              <SelectItem value="afternoon">
                                Afternoon
                              </SelectItem>
                              <SelectItem value="anytime">Anytime</SelectItem>
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <div className="w-full mb-4">
                  <></>
                  <div className="sm:flex items-end justify-between">
                    <div className="relative ">
                      <FormField
                        control={formProvideQuoteDetails.control}
                        name="dropoffDate"
                        render={({ field }) => (
                          <FormItem className="flex flex-col">
                            <Popover>
                              <PopoverTrigger asChild>
                                <FormControl>
                                  <Button
                                    variant={"outline"}
                                    className={cn(
                                      "w-[240px] pl-3 text-left font-normal",
                                      !field.value && "text-muted-foreground"
                                    )}
                                  >
                                    {field.value ? (
                                      format(field.value, "PPP")
                                    ) : (
                                      <span>Pick a date</span>
                                    )}
                                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                  </Button>
                                </FormControl>
                              </PopoverTrigger>
                              <PopoverContent className="w-auto p-0">
                                <Calendar
                                  mode="single"
                                  selected={field.value || undefined}
                                  onSelect={field.onChange}
                                  disabled={(date) => {
                                    const pickupDate =
                                      formProvideQuoteDetails.watch(
                                        "pickupDate"
                                      );
                                    const endDate = freightDetails?.end_date
                                      ? parse(
                                          freightDetails.end_date,
                                          "dd-MM-yyyy",
                                          new Date()
                                        )
                                      : null;

                                    // Ensure we always return a boolean
                                    if (!pickupDate) return false; // If no pickup date, don't disable any dates
                                    if (date < pickupDate) return true; // Disable dates before pickup
                                    if (endDate && date > endDate) return true; // Disable dates after end date
                                    return false; // Enable all other dates
                                  }}
                                  initialFocus
                                />
                              </PopoverContent>
                            </Popover>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full mb-4">
                  <FormField
                    name="otherComments"
                    control={formProvideQuoteDetails.control}
                    render={({ field, fieldState }) => (
                      <FormItem>
                        <FormLabel>
                          Any other comments to customer or details about your
                          quote:
                        </FormLabel>
                        <FormControl>
                          <Textarea
                            {...field}
                            name="otherComments"
                            className="w-full dark:bg-transparent"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="w-full mb-4">
                  <FormField
                    control={formProvideQuoteDetails.control}
                    name="termsConditions"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Terms & Conditions</FormLabel>
                        {/* <FormLabel> */}
                        <div className="flex items-start space-x-2">
                          <Input
                            className="h-4 w-4 rounded"
                            type="checkbox"
                            checked={field.value}
                            onChange={field.onChange}
                          />{" "}
                          <p className="text-sm font-medium">
                            I verify that I have read and accept the{" "}
                            <span className="text-blue-500 hover:underline">
                              terms & conditions
                            </span>{" "}
                            set out by Runnme
                          </p>
                        </div>
                        {/* </FormLabel> */}
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <Button
                  disabled={
                    loading ||
                    formProvideQuoteDetails.getValues("quote") === "" ||
                    formProvideQuoteDetails.getValues("termsConditions") ===
                      false
                  }
                  variant={"freightBlue"}
                  className="max-w-60 ml-auto"
                >
                  {loading ? (
                    <Loader2 className="animate-spin h-5 w-5" />
                  ) : (
                    <>Submit Quote</>
                  )}
                </Button>
              </form>
            </Form>
          </div>
        </div>
        {modalQuote.isOpen && <ModalAddQuote />}
      </div>
    </div>
  );
};

export default ProvideQuote;
