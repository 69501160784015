import { create } from "zustand";

export type ModalType = "deleteDrivers";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  driverId: string;
  onOpen: (type: ModalType, driverId: string) => void;
  onClose: () => void;
}

export const useDeleteDrivers = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  driverId: "",
  onOpen: (type, driverId) => set({ isOpen: true, type, driverId }),
  onClose: () => set({ type: null, isOpen: false }),
}));
