import Login from "../components/Login";
import Header from "../components/header/Header";

const SignIn = () => {
  return (
    <div className="bg-gray-50 h-screen">
      <Header />
      <div>
        <Login />
      </div>
    </div>
  );
};

export default SignIn;
