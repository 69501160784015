import { useAddTicket } from "src/hooks/use-add-ticket";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { z } from "zod";
import { Form } from "../ui/form";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Button } from "../ui/button";
import { BASE_URL, TICKET_CATEGORY } from "src/api/config";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

const categoryOptions = [
  {
    id: 1,
    value: "Billing",
  },
  {
    id: 2,
    value: "Delivery",
  },
  {
    id: 3,
    value: "Others",
  },
];

const ticketSchema = z.object({
  category: z.string().min(1),
});

const ModalAddTicket = () => {
  const modal = useAddTicket();
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);

  const ticketForm = useForm<z.infer<typeof ticketSchema>>({
    resolver: zodResolver(ticketSchema),
    defaultValues: {
      category: "",
    },
  });

  const getTicketCategory = async () => {
    const response = await fetch(`${BASE_URL}${TICKET_CATEGORY}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    }).then((res) => res.json());

    return response;
  };

  const { data: ticketCategory } = useQuery({
    queryKey: ["ticket_category"],
    queryFn: getTicketCategory,
  });

  const onSubmit = async (values: z.infer<typeof ticketSchema>) => {};
  return (
    <Dialog open={modal.isOpen} onOpenChange={modal.onClose}>
      <DialogContent>
        <DialogHeader>
          <div className="flex items-center justify-center">
            <h1 className="text-xl">Add Ticket</h1>
          </div>
          <div className="mt-4 flex flex-col w-full">
            <Form {...ticketForm}>
              <form onSubmit={ticketForm.handleSubmit(onSubmit)}>
                <p className="text-lg">Select Category</p>
                <Controller
                  control={ticketForm.control}
                  name="category"
                  render={({ field }) => (
                    <Select
                      {...field}
                      onValueChange={(val) => {
                        ticketForm.setValue("category", val);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select" />
                      </SelectTrigger>
                      <SelectContent>
                        {ticketCategory?.data[0]?.map(
                          (cat: { category: string; id: number }) => (
                            <SelectItem value={cat.category}>
                              {cat.category}
                            </SelectItem>
                          )
                        )}
                      </SelectContent>
                    </Select>
                  )}
                />
                <Button
                  variant={"freightBlue"}
                  className="mt-4 justify-end flex items-end"
                >
                  Submit
                </Button>
              </form>
            </Form>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAddTicket;
