import { zodResolver } from "@hookform/resolvers/zod";
import { useAtom } from "jotai";
import Lottie from "lottie-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { MdKeyboardArrowRight } from "react-icons/md";
import { z } from "zod";
import { BASE_IMG_URL, BASE_URL, GET_ALL_CATEGORIES } from "../api/config";
import loadeanimation from "../assets/Animations/LoadingBox.json";
import packageimg from "../assets/images/package2.png";
import { selectedCategory, selectedSubcategoryNew } from "../atoms/atoms";
import { FreightGeneral } from "../components/freights/FreightGeneral";
import { FreightPets } from "../components/freights/FreightPets";
import Header from "../components/header/Header";
import { Form } from "../components/ui/form";
import { cn } from "../lib/utils";
import { Category, SubCategory } from "../types/types";
const shipmentDetailsCatSchema = z.object({
  categoryId: z.string().min(1),
  subCategoryId: z.string().min(1),
});

const SelectFreight = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [categories, setCategories] = useState<Category[]>([]);
  const [categoryId, setCategoryId] = useAtom(selectedCategory);
  const [subCategoryId, setSubcategoryId] = useAtom(selectedSubcategoryNew);
  const [showSubcategories, setShowSubcategories] = useState(false);
  const [showFreightGeneral, setShowFreightGeneral] = useState(false);
  const [submitFreightActive, setSubmitFreightActive] = useState(false);
  const [freightDetailsClicked, setFreightDetailsClicked] = useState(false);
  const [showFreightSummary, setShowFreightSummary] = useState(false);

  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [selectedFreightComponent, setSelectedFreightComponent] =
    useState<JSX.Element | null>(null);
  const [loading, setLoading] = useState(true);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleNextClick = () => {
    setSubmitFreightActive(true); // Cambia el estado a true cuando se hace clic en "Next"
  };

  const handlePackageClick = () => {
    setShowFreightGeneral(true);
    setIsModalOpen(false); // Cerrar el modal
  };
  const handleFreightSummaryClick = () => {
    setShowFreightSummary(true); // Cambia el estado al mostrar FreightSummary
    handleNextClick();
  };
  const shipmentDetailsCat = useForm<z.infer<typeof shipmentDetailsCatSchema>>({
    resolver: zodResolver(shipmentDetailsCatSchema),
    defaultValues: {
      categoryId: "",
      subCategoryId: "",
    },
  });

  const handleFreightDetailsClick = () => {
    setFreightDetailsClicked(true); // Actualiza el estado al hacer clic
  };

  const getAllCategories = async () => {
    setLoading(true); // Inicia la carga
    try {
      const response = await fetch(`${BASE_URL}${GET_ALL_CATEGORIES}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch categories");
      }
      const data = await response.json();
      setCategories(data); // Save fetched categories
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false); // Finaliza la carga cuando los datos se han recibido
    }
  };

  useEffect(() => {
    getAllCategories();
    setCategoryId({ catid: 0, catname: "", sub: [] });
  }, []);

  const onCategoryClick = (category: Category) => {
    setCategoryId({
      catid: category.id,
      catname: category.name,
    });
    if (category.subcategories.length > 0) {
      setSubCategories(category.subcategories);
      setShowSubcategories(true);
    }
  };

  const onSubCategoryClick = (subcategory: SubCategory) => {
    setSubcategoryId({
      id: subcategory.id,
      name: subcategory.name,
    });

    // Verificar si la subcategoría seleccionada es 'Dogs'
    if (subcategory.name === "Dogs") {
      setSelectedFreightComponent(
        <FreightPets
          onFreightDetailsClick={handleFreightDetailsClick}
          handleBackToGetFreight={handleBackToGetFreight}
        />
      );
    } else if (categoryId.catname === "Package") {
      setSelectedFreightComponent(
        <FreightGeneral
          onFreightDetailsClick={handleFreightDetailsClick}
          onFreightSummaryClick={handleFreightSummaryClick}
          handleBackToGetFreight={handleBackToGetFreight}
        />
      );
    } else {
      setShowFreightGeneral(true);
    }

    setIsModalOpen(false); // Cerrar el modal después de seleccionar la subcategoría
  };

  const handleBackToCategories = () => {
    setShowSubcategories(false);
    setSubCategories([]); // Clear subcategories
    setCategoryId({ catid: 0, catname: "", sub: [] });
  };

  const handleBackToGetFreight = () => {
    setShowFreightGeneral(false);
    setIsModalOpen(true);
    setSelectedFreightComponent(null);
  };
  const onSubmit = (values: z.infer<typeof shipmentDetailsCatSchema>) => {
    // Handle form submission
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <div className="h-screen overflow-x-hidden bg-gray-50 ">
      <Header />

      <div className="flex flex-col py-10 h-[94vh] bg-gray-50 no-scrollbar">
        {/* {isModalOpen && (
          <div className="absolute h-full overflow-hidden inset-0 z-30 bg-black opacity-60"></div>
        )} */}
        <div className="max-w-4xl mx-auto w-full relative flex items-center justify-between">
          <div className="w-full border-b h-1 absolute top-4 z-10" />
          <div className="flex flex-col z-20">
            <div className="bg-[#1889ca] text-white z-20 h-10 w-10 rounded-full text-center items-center justify-center flex">
              <p className="text-sm font-medium">1</p>
            </div>
            <p className="text-xs w-20 mt-2 font-medium text-gray-500">
              Freight Details
            </p>
          </div>
          <div className="flex flex-col z-20 items-center">
            <div
              className={cn(
                "bg-gray-100 z-20 h-10 w-10 rounded-full text-center items-center justify-center flex",
                freightDetailsClicked ? "bg-[#1889ca] text-white" : "" // Cambia el color si está activo
              )}
            >
              <p className="text-sm font-medium">2</p>
            </div>
            <p className="text-xs w-20 mt-2 text-center font-medium text-gray-500">
              Shipment Details
            </p>
          </div>
          <div className="flex flex-col z-20 items-end">
            <div
              className={cn(
                "bg-gray-100 z-20 h-10 w-10 rounded-full text-center items-center justify-center flex",
                submitFreightActive ? "bg-[#1889ca] text-white" : "" // Cambia a azul si el estado es true
              )}
            >
              <p className="text-sm font-medium">3</p>
            </div>
            <p className="text-xs w-20 mt-2 justify-end items-end text-right font-medium text-gray-500">
              Submit Freight
            </p>
          </div>
        </div>

        {showFreightGeneral && (
          <div className="mt-4">
            <FreightGeneral
              onFreightDetailsClick={handleFreightDetailsClick}
              onFreightSummaryClick={handleFreightSummaryClick}
              handleBackToGetFreight={handleBackToGetFreight}
            />{" "}
          </div>
        )}
        {selectedFreightComponent && (
          <div className="mt-4">{selectedFreightComponent}</div>
        )}

        {isModalOpen && (
          <div
            ref={modalRef}
            className={`mt-10 ${
              isModalOpen
                ? "scale-100 opacity-100 animate-fadeIn"
                : "scale-95 opacity-0"
            }`}
          >
            <div className="mt-4 flex items-start justify-center ">
              <div className="max-w-4xl px-4 pt-2 h-[550px] pb-10 w-full z-50 sm:rounded-md bg-white shadow p-4">
                {categoryId.catid !== 0 && (
                  <button
                    className="bg-gray-500 px-2 py-1 rounded-md text-white hover:bg-gray-800"
                    onClick={handleBackToCategories}
                  >
                    <div className="flex gap-2 items-center">
                      <IoArrowBack />
                      <p>Back</p>
                    </div>
                  </button>
                )}
                {/* Lottie se muestra si loading es true */}
                {loading ? (
                  <Lottie
                    animationData={loadeanimation}
                    className="h-[350px]"
                  />
                ) : (
                  <>
                    <div className="flex items-center max-w-4xl mx-auto justify-between w-full border-border border-b pb-3 mt-4 space-x-2 mb-4">
                      <h1 className="text-lg font-semibold">Select Category</h1>
                    </div>
                    <Form {...shipmentDetailsCat}>
                      <form
                        onSubmit={shipmentDetailsCat.handleSubmit(onSubmit)}
                      >
                        <div className="sm:flex justify-evenly flex-wrap gap-4">
                          {!showSubcategories && (
                            <div className="p-1 absolute -top-2 left-[325px] z-50 rounded-full bg-[#ff380f]">
                              <p className="text-sm text-white">Popular</p>
                            </div>
                          )}
                          {!showSubcategories && (
                            <div
                              className="border w-[300px] mb-2 rounded-md hover:bg-slate-100 cursor-pointer"
                              onClick={() => handlePackageClick()}
                            >
                              <div className="flex items-center gap-2">
                                <div className=" rounded-l-md flex items-center">
                                  <img
                                    src={packageimg}
                                    className="h-16 w-20 rounded-l-md object-contain"
                                    alt="package"
                                  />
                                </div>
                                <div>
                                  <p>Package</p>
                                </div>
                              </div>
                            </div>
                          )}
                          {!showSubcategories ? (
                            categories.map((category) => (
                              <div
                                key={category.id}
                                className="border w-[300px] mb-2 rounded-md hover:bg-slate-100 cursor-pointer"
                                onClick={() => onCategoryClick(category)}
                              >
                                <div className="flex items-center gap-2">
                                  <div className=" rounded-l-md flex justify-center items-center">
                                    <img
                                      className="h-16 w-28 rounded-l-md object-contain"
                                      src={BASE_IMG_URL + category.img}
                                      alt={category.name}
                                    />
                                  </div>
                                  <div className="flex justify-between w-full pr-4">
                                    <p>{category.name}</p>
                                    {category.subcategories.length > 0 && (
                                      <MdKeyboardArrowRight
                                        size={25}
                                        className="text-gray-500"
                                      /> // Muestra el ícono solo si hay subcategorías
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="flex flex-wrap gap-4 ">
                              {subCategories.map((subcategory) => (
                                <div
                                  key={subcategory.id}
                                  className="border w-[300px] mb-2 rounded-md hover:bg-slate-100"
                                  onClick={() =>
                                    onSubCategoryClick(subcategory)
                                  }
                                >
                                  <div className="flex items-center gap-2">
                                    <div>
                                      <img
                                        className="h-16 w-28 rounded-l-md object-contain cursor-pointer"
                                        src={BASE_IMG_URL + subcategory.img}
                                        alt={subcategory.name}
                                      />
                                    </div>
                                    <div>
                                      <p>{subcategory.name}</p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </form>
                    </Form>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectFreight;
