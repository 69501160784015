import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/layout/Footer";

export const Claim = () => {
  return (
    <div>
      {" "}
      <div>
        <Header />
      </div>
      <div className="sm:p-20 p-4">
        <div
          className="pb-8 text-5xl font-bold flex justify-center gap-2"
          style={{ fontFamily: "Franklin Gothic" }}
        >
          <h2>
            <span style={{ color: "#0022b6" }}>P</span>RIVACY{" "}
          </h2>
          <h2>
            <span style={{ color: "#0022b6" }}>P</span>OLICY{" "}
          </h2>
        </div>
        <div className="flex justify-center flex-wrap">
          <div className=" max-w-[1000px] mb-4">
            <p>
              We inform you that the data you provide us with are processed for
              the following purposes: to attend and respond to requests for
              information and/or queries received, to send periodic commercial
              and informative communications about our services, to manage the
              CV data received to participate in the selection processes to fill
              a job position in the company, as well as to facilitate, expedite
              and fulfil the commitments established between the Holder and the
              User and to maintain the relationship established
            </p>
            <br />
            <p>
              Likewise, in accordance with the provisions of the GDPR, a
              register of processing activities is kept which specifies,
              according to its purposes, the processing activities carried out
              and the other circumstances established in the GDPR.
            </p>
            <br />
            <p>
              Personal information may not be used for purposes other than those
              related to the services contracted or products purchased. No
              automated decisions will be made on the basis of this profile
            </p>
          </div>
          <div className=" max-w-[1000px] mb-4">
            <p className="font-bold text-2xl">LEGITIMACY FOR DATA PROCESSING</p>
            <br />
            <br />

            <p>
              - The consent given by the User by accepting this Policy and
              ticking the relevant box.
              <br />
              <br />
              - The User has provided his/her personal data in the context of a
              contractual or pre-contractual relationship for the purpose of
              responding to his/her request and/or query and therefore its
              processing is necessary for the maintenance of such relationship.
              <br />
              <br />
              - The legal obligations applicable to the Holder that require the
              processing of personal data in accordance with the services
              provided, such as those related to tax matters.
              <br />
              <br />
              The User may, at any time, revoke his/her consent to the
              processing of his/her personal data. In no case shall the
              withdrawal of such consent condition the provision of the service,
              and/or the execution of contracts with the Data Controller.
            </p>
          </div>
          <div className=" max-w-[1000px]  mb-4">
            <p className="font-bold text-2xl">
              PERSONAL DATA THAT IS PROCESSED AND PROCEDURE
            </p>
            <br />
            <p>
              he personal data that we process are of an identifying nature and
              have been provided by the User himself/herself by filling in the
              contact form, sending e-mails or using the functionalities offered
              on the Portal
            </p>
            <br />
            <p>
              The use of the contact sections, completion of forms and/or
              functionalities offered on the Portal is voluntary. However, the
              completion of certain fields in the form or their provision
              through the use of other functionalities are necessary to
              correctly deal with and manage your request, so that the User's
              refusal to provide the required information will prevent the Owner
              from dealing with it and managing it correctly
            </p>
            <br />
            <p>
              The User guarantees that the data provided are true, accurate and
              complete. The data will be cancelled, erased or blocked when they
              are inaccurate, incomplete or no longer necessary or relevant for
              their purpose in accordance with current legislation. If the
              personal data provided belong to a third party, the User
              guarantees that he/she has informed them of the Privacy Policy and
              has obtained their authorisation to provide their data for the
              aforementioned purposes. The User also guarantees that the data
              provided are accurate and up to date, and shall be liable for any
              direct or indirect damage or harm that may be caused as a result
              of non-compliance with this obligation. The User undertakes and
              accepts responsibility for the veracity and accuracy of the data
              provided, undertaking to keep them duly updated
            </p>
          </div>
          <div className=" max-w-[1000px]  mb-4">
            <p className="font-bold text-2xl">LINKS POLICY</p>
            <br />
            <p>
              The website may include links to third party sites. The
              aforementioned websites have not been reviewed and are not checked
              by the portal. The Owner is present on Facebook, Instagram,
              Twitter, Linkedin, Youtube in order to inform about the services
              it offers, as well as any other activity and information that it
              wishes to publicise. At no time will it obtain the same personal
              data of the users who interact in them, unless there is express
              authorisation. Under no circumstances may the Owner be held
              responsible for the contents of these websites or for the measures
              adopted in relation to their privacy or the processing of their
              personal data. It is recommended that you carefully read the terms
              of use and privacy policy of these sites
            </p>
            <br />
            <p>
              If you are interested in activating a link to this page, you must
              inform the Owner by obtaining express consent to create the link.
              The Owner reserves the right to object to the activation of links
              to its website
            </p>
          </div>
          <div className=" max-w-[1000px]  mb-4">
            <p className="font-bold text-2xl">
              PERSONAL DATA CONSERVATION TERMS
            </p>
            <br />
            <p>
              The personal data provided by the User will be kept for as long as
              he/she remains registered in the service, for as long as the
              business relationship is maintained, for as long as the User does
              not request its deletion, or for the established legal period.
              They may also be kept when they are necessary for the fulfilment
              of a legal obligation or for the formulation, exercise and defence
              of claims
            </p>
            <br />
            <p>
              If the User revokes his/her consent or exercises the rights of
              objection or deletion, his/her data will be kept blocked at the
              disposal of the Administration of Justice for the legally
              established periods in order to attend to possible liabilities
              arising from the processing of personal data. In no case will it
              condition the provision of the service, and/or the execution of
              contracts with the Holde
            </p>
          </div>
          <div className=" max-w-[1000px] mb-4">
            <p className="font-bold text-2xl">
              SECRET AND SECURITY OF PERSONAL DATA
            </p>
            <br />
            <p>
              The Holder undertakes to adopt the necessary technical and
              organisational measures, according to the level of security
              appropriate to the risk of the data collected, so as to ensure the
              security of personal data and to prevent the accidental or
              unlawful destruction, loss or alteration of personal data
              transmitted, stored or otherwise processed, or the unauthorised
              communication or access to such data.
            </p>
            <br />
            <p>
              Personal data shall be treated as confidential by the holder, who
              undertakes to inform and ensure by means of a legal or contractual
              obligation that such confidentiality is respected by its
              employees, partners, and any person to whom it makes the
              information accessible.
            </p>
          </div>
          <div className=" max-w-[1000px]  mb-4">
            <p className="font-bold text-2xl">
              TRANSFERS AND RECIPIENTS OF PERSONAL DATA{" "}
            </p>
            <br />
            <p>
              The data will not be communicated to third parties, except in the
              cases provided for by law or where it is necessary to fulfil the
              purpose of the processing
            </p>
          </div>
          <div className=" max-w-[1000px]  mb-4">
            <p className="font-bold text-2xl">USER RIGHTS</p>
            <br />
            <p>What are your rights when you provide us with your data? </p>
            <br />
            <p>
              The User has the right to obtain confirmation as to whether or not
              we are processing personal data that concerns them. The User has
              the right to <b>access</b> their personal data, as well as to
              request it <b> rectification</b> of inaccurate data or, where
              appropriate, request its <b>deletion</b> when, among other
              reasons, the data is no longer necessary for the purposes for
              which it was collected.In certain circumstances, the User may
              request the <b>limitation</b> of the processing of their data, in
              which case we will only keep them for the exercise or defense of
              claims.In certain circumstances and for reasons related to their
              particular situation, the User may <b> oppose</b> the processing
              of your data, so that the data holder will stop processing the
              data, except for compelling legitimate reasons, or the exercise or
              defense of possible claims.In those cases in which it is legally
              appropriate, you will have the right to <b>portability</b> of the
              data, which implies that you have the right to receive the
              personal data relating to your person that we are processing and
              store them on your own device.
            </p>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
