import { useQuery } from "@tanstack/react-query";
import { MoreHorizontal } from "lucide-react";
import { useEffect, useState } from "react";
import { BASE_URL, LIST_CUSTOMER_PAYMENTS, USER_EXISTS } from "../api/config";
import SidebarLayout from "../components/layout/SidebarLayout";
import { Skeleton } from "../components/skeleton/Skeleton";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { useViewPayment } from "../hooks/use-view-payment";
import { cn } from "../lib/utils";
import { userStripeCustomerStore } from "../store/useStripeStore";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { CustomerPayment } from "../types/types";

const HistoryPayments = () => {
  const { user_id, user_type, email } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const [customerId, setCustomerId] = useState("");
  const addCustomerId = userStripeCustomerStore((state) => state.addCustomerId);
  const [isLoading, setIsLoading] = useState(false);
  const [userNotRegistered, setUserNotRegistered] = useState(true);
  const [predCard, setPredCard] = useState();
  const modalViewPayment = useViewPayment();

  useEffect(() => {
    doesUserExist();
  }, []);

  const doesUserExist = async () => {
    setIsLoading(true);
    const response = await fetch(`${BASE_URL}${USER_EXISTS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        email: email,
        user_id: user_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "User is not registered in stripe.") {
          setUserNotRegistered(true);
        } else {
          setCustomerId(data.data[0][0].id);
          // getListPayments(data.data[0][0].id);
          setPredCard(data.data[0][0].invoice_settings.default_payment_method);
          setUserNotRegistered(false);
          addCustomerId(data.data[0][0].id);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return response;
  };
  const getListPayments = async () => {
    // setLoadingPayments(true);
    const response = await fetch(
      `${BASE_URL}${LIST_CUSTOMER_PAYMENTS}?user_id=${user_id}&customer_id=${customerId}`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    ).then((res) => res.json());
    // .then((data) => {
    //   if (data?.data?.length > 0) {
    //     setListPayments(data?.data);
    //   }
    // })
    // .finally(() => {
    //   setLoadingPayments(false);
    // });

    return response?.data;
  };

  const { data: listPayments, isLoading: isLoadingPayments } = useQuery({
    queryKey: ["list_payments", customerId],
    queryFn: getListPayments,
    enabled: customerId !== "",
  });

  return (
    <SidebarLayout>
      <div className="flex items-center justify-between space-x-2 border-b pb-3 mb-4">
        <h1 className="text-lg font-semibold"> Payments Activity</h1>
      </div>

      <div className=" bg-white p-4 shadow rounded">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Card</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Default</TableHead>
              <TableHead>Amount</TableHead>
              <TableHead>Details</TableHead>
              <TableHead>Date Created</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          {userNotRegistered ? (
            <>
              {isLoading ? (
                <Skeleton className="h-6 w-40 mt-4" />
              ) : (
                <span className="text-sm font-medium text-gray-500 mt-4">
                  <>
                    {user_type === "customer" ? (
                      <span>You have not made any payments.</span>
                    ) : (
                      <span>You have not received any payments.</span>
                    )}
                  </>
                </span>
              )}
            </>
          ) : (
            <>
              <TableBody>
                {isLoadingPayments ? (
                  <TableRow>
                    <TableCell>
                      <Skeleton className="h-5 w-24 rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="h-5 w-24 rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="h-5 w-24 rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="h-5 w-24 rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="h-5 w-20 rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="h-5 w-20 rounded" />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    <>
                      {!isLoadingPayments && listPayments?.length === 0 ? (
                        <h1 className="p-4 text-sm font-medium text-gray-400">
                          No receipts found
                        </h1>
                      ) : (
                        <>
                          {listPayments?.map((payment: CustomerPayment) => (
                            <TableRow key={payment.id}>
                              <TableCell>
                                <p
                                  className={cn(
                                    "text-sm font-medium capitalize px-4 py-2 text-white text-center rounded",

                                    payment.status === "succeeded" &&
                                      "bg-green-500",
                                    payment.status ===
                                      "requires_payment_method" && "bg-red-500"
                                  )}
                                >
                                  {payment.status === "succeeded" && "Success"}
                                  {payment.status ===
                                    "requires_payment_method" && "Failed"}
                                </p>
                              </TableCell>
                              <TableCell>
                                <div className="flex items-center space-x-2">
                                  <img
                                    src={require("../assets/visa.png")}
                                    className="h-10 w-10 object-contain rounded-lg"
                                    alt={`visa-${payment.client_secret}`}
                                  />
                                  {payment.charges?.data?.map((charge) => (
                                    <p className="font-semibold text-sm">
                                      {charge.payment_method_details.card.brand
                                        .slice(0, 1)
                                        .toUpperCase() +
                                        charge.payment_method_details.card.brand.slice(
                                          1
                                        )}
                                    </p>
                                  ))}
                                  <p className="font-semibold text-sm">****</p>
                                  {payment.charges?.data?.map((charge) => (
                                    <p className="text-sm font-semibold">
                                      {charge.payment_method_details.card.last4}
                                    </p>
                                  ))}
                                </div>
                              </TableCell>
                              <TableCell>
                                {predCard === payment.payment_method && (
                                  <div className="bg-gray-100 text-gray-400 font-medium px-4 py-2">
                                    <p className="text-center">Default</p>
                                  </div>
                                )}
                              </TableCell>
                              <TableCell>{payment.amount / 100}€</TableCell>
                              <TableCell>{payment.description}</TableCell>

                              <TableCell>
                                {new Date(
                                  payment?.created * 1000
                                ).toDateString()}
                              </TableCell>
                              <TableCell>
                                <div className="hover:bg-gray-100 h-7 w-7 rounded-full  cursor-pointer flex items-center justify-center transition ">
                                  <DropdownMenu>
                                    <DropdownMenuTrigger>
                                      <MoreHorizontal className="h-5 w-5 text-gray-600" />
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                      <DropdownMenuLabel
                                        onClick={() =>
                                          modalViewPayment.onOpen(
                                            "viewPayment",
                                            payment
                                          )
                                        }
                                      >
                                        View Details
                                      </DropdownMenuLabel>
                                      <DropdownMenuSeparator />
                                      <DropdownMenuLabel>
                                        Cancel
                                      </DropdownMenuLabel>
                                    </DropdownMenuContent>
                                  </DropdownMenu>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </>
                  </>
                )}
              </TableBody>
            </>
          )}
        </Table>
      </div>
    </SidebarLayout>
  );
};

export default HistoryPayments;
