import { Controller, useForm } from "react-hook-form";
import Header from "../components/header/Header";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form";
import { BASE_URL, REGISTER_DEVICE, REGISTER_USER } from "../api/config";
import { Input } from "../components/ui/input";
import { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Checkbox } from "../components/ui/checkbox";
import { Button } from "../components/ui/button";
import {
  useGuidTokenStorage,
  useTokenStoreStorage,
} from "../store/useTokenStore";
import { userId } from "../atoms/atoms";
import { useAtom } from "jotai";
import { userInfoUserStore } from "../store/useUserInfoStore";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CircleX, Loader2 } from "lucide-react";

const formSchema = z.object({
  firstname: z.string().min(1),

  lastname: z.string().min(1),
  username: z.string().min(1),
  password: z.string().min(1),
  email: z.string().min(1),
  phoneNumber: z.string().min(1),
  termsConditions: z.boolean(),
});

const RegisterCustomer = () => {
  const [showPassword, setShowPassword] = useState(false);
  const setTokens = useTokenStoreStorage((s) => s.addToken);
  const setUserInfo = userInfoUserStore((s) => s.addUserInfo);
  const navigate = useNavigate();
  const setGuidToken = useGuidTokenStorage((state) => state.addGuid);
  // const guidToken = useGuidTokenStorage((state) => state.guidToken);
  const [loading, setLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState<any>("");

  const [user_id, setUserId] = useAtom(userId);
  const registerDetails = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      firstname: "",
      lastname: "",
      password: "",
      phoneNumber: "",
      username: "",
      termsConditions: false,
    },
  });

  const registerDevice = async (
    firstname: string,
    lastname: string,
    username: string,
    email: string,
    phoneNumber: string,
    password: string
  ) => {
    const response = await fetch(`${BASE_URL}${REGISTER_DEVICE}?GUID=NEW`, {
      method: "POST",
      headers: {},
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "GUID created successfully.") {
          setGuidToken(data.data.GUID);
          register(
            firstname,
            lastname,
            username,
            email,
            phoneNumber,
            password,
            data.data.GUID
          );
        } else {
          toast.error(`${data.message}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const register = async (
    firstname: string,
    lastname: string,
    username: string,
    email: string,
    phoneNumber: string,
    password: string,
    guidToken: string
  ) => {
    const response = await fetch(
      `${BASE_URL}${REGISTER_USER}?username=${username}&firstname=${firstname}&lastname=${lastname}&email=${email}&phoneNumber=${phoneNumber}&password=${password}&GUID=${guidToken}&user_type=customer&social_login=0`,
      {
        method: "POST",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Customer registered successfully.") {
          setUserInfo(
            data.data.email,
            data.data.phone_number,
            data.data.user_type,
            data.data.user_id,
            data.data.first_name
          );
          setUserId(data.data.user_id);
          toast.success(`Customer registered`);
          navigate("/register/verify");
        } else {
          toast.error(`${data.message}`);
          setErrorResponse(data.data);
          // toast.error(`${data.data}`);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return response;
  };

  useEffect(() => {
    console.log("errorResponse", errorResponse);
  }, [errorResponse]);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    setLoading(true);
    registerDevice(
      values.firstname,
      values.lastname,
      values.username,
      values.email,
      values.phoneNumber,
      values.password
    );
  };
  return (
    <div className="bg-gray-50 min-h-screen">
      <Header />
      <div className="max-w-4xl mx-auto mt-10 ">
        <h1 className="text-xl font-medium mb-4">Register Customer</h1>
        <div className="bg-white p-10  my-4 shadow border border-border rounded">
          <Form {...registerDetails}>
            <form
              onSubmit={registerDetails.handleSubmit(onSubmit)}
              className=" flex-col flex w-full dark:bg-transparent"
            >
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="firstname"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>First name</FormLabel>

                      <FormControl>
                        <Input
                          {...field}
                          name="firstname"
                          className="w-full dark:bg-transparent"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="lastname"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Last name</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          name="lastname"
                          className="w-full dark:bg-transparent"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="username"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Username</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          name="username"
                          className="w-full dark:bg-transparent"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                {errorResponse?.username && (
                  <div className="mt-2 flex items-center space-x-2">
                    <CircleX className="h-4 w-4 text-red-600" />
                    <p className="text-sm font-medium text-red-600">
                      {errorResponse.username}
                    </p>
                  </div>
                )}
              </div>
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email address</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          name="email"
                          className="w-full dark:bg-transparent"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                {errorResponse.email && (
                  <div className="mt-2 flex items-center space-x-2">
                    <CircleX className="h-4 w-4 text-red-600" />
                    <p className="text-sm font-medium text-red-600">
                      {errorResponse.email}
                    </p>
                  </div>
                )}
              </div>
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem className="relative">
                      <FormLabel>Password</FormLabel>
                      <FormControl>
                        <Input
                          type={!showPassword ? "password" : "text"}
                          {...field}
                          name="password"
                          className="w-full dark:bg-transparent"
                        />
                      </FormControl>
                      <div className="absolute top-9 right-2">
                        {showPassword ? (
                          <div
                            className="cursor-pointer"
                            onClick={() => setShowPassword(false)}
                          >
                            <FaEyeSlash className="h-4 w-4" />
                          </div>
                        ) : (
                          <div
                            className="cursor-pointer"
                            onClick={() => setShowPassword(true)}
                          >
                            <FaEye className="h-4 w-4" />
                          </div>
                        )}
                      </div>
                    </FormItem>
                  )}
                />
              </div>
              <div className="w-full mb-4">
                <FormField
                  control={registerDetails.control}
                  name="phoneNumber"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Phone Number</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          name="phoneNumber"
                          className="w-full dark:bg-transparent"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                {errorResponse?.phoneNumber && (
                  <div className="mt-2 flex items-center space-x-2">
                    <CircleX className="h-4 w-4 text-red-600" />
                    <p className="text-sm font-medium text-red-600">
                      {errorResponse.phoneNumber}
                    </p>
                  </div>
                )}
              </div>
              <div className="w-full mb-4">
                <Controller
                  control={registerDetails.control}
                  name="termsConditions"
                  render={({ field }) => (
                    <div className="flex items-center space-x-2 mt-4">
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      <p className="text-sm">
                        I verify that I have read and accept the terms &
                        conditions set out by Runnme
                      </p>
                    </div>
                  )}
                />
              </div>
              <Button
                disabled={
                  loading ||
                  registerDetails.getValues("termsConditions") === false ||
                  registerDetails.getValues("email") === "" ||
                  registerDetails.getValues("firstname") === "" ||
                  registerDetails.getValues("lastname") === "" ||
                  registerDetails.getValues("password") === "" ||
                  registerDetails.getValues("phoneNumber") === ""
                }
                variant={"freightBlue"}
                className="w-60 ml-auto mt-10"
              >
                {loading ? (
                  <>
                    <Loader2 className="animate-spin h-5 w-5" />
                  </>
                ) : (
                  <p className="text-sm font-medium">Register Customer</p>
                )}
              </Button>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default RegisterCustomer;
