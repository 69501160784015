import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import Header from "../components/header/Header";
//@ts-ignore
import Smallbox from "../assets/images/Smallbox.png";
//@ts-ignore
import Mediumbox from "../assets/images/Mediumbox.png";
//@ts-ignore
import Largebox from "../assets/images/Largebox.png";
import { cn } from "../lib/utils";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { useEffect, useState } from "react";
import { Check, Euro, Loader2 } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { ALL_ROUTES, BASE_URL, ROUTE_UPDATE } from "../api/config";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import useLocalStorage from "../hooks/use-local-storage";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";

const pricesPackageSchema = z.object({
  priceSmall: z.string().min(1),
  priceMedium: z.string().min(1),
  priceLarge: z.string().min(1),
});

const packageSchema = z.object({
  packageWeight: z.string().min(1, "Weight is required"),
  packagePrice: z.string().min(1, "Price is required"),
  packageSize: z.string().min(1, "Size is required"),
});

const shipmentOptions = [
  {
    title: "Small",
    dimensions: "23 x 14 x 4cm",
    image: Smallbox,
  },
  {
    title: "Medium",
    dimensions: "35 x 20 x 15cm",
    image: Mediumbox,
  },
  {
    title: "Large",
    dimensions: "75 x 35 x 35cm",
    image: Largebox,
  },
];

const DeliveryRouteEditPrices = () => {
  const [submittedPrices, setSubmittedPrices] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successfullyCreated, setSuccessfullyCreated] = useState(false);
  const [pricesPerSize, setPricesPerSize] = useState([
    { type: "small", price: "10" },
    { type: "medium", price: "20" },
    { type: "large", price: "30" },
  ]);
  const [tripPackageDetails, setTripPackageDetails] = useLocalStorage(
    "trip_package_details"
  );
  const params = useParams();
  const [originZipcode, setOriginZipcode] = useState("");
  const [destinationZipcode, setDestinationZipcode] = useState("");
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const { user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const formPricePerPackage = useForm<z.infer<typeof pricesPackageSchema>>({
    resolver: zodResolver(pricesPackageSchema),
    defaultValues: {
      priceLarge: "",
      priceMedium: "",
      priceSmall: "",
    },
  });
  const navigate = useNavigate();

  const formPackage = useForm<z.infer<typeof packageSchema>>({
    resolver: zodResolver(packageSchema),
    defaultValues: {
      packagePrice: "",
      packageSize: "",
      packageWeight: "",
    },
  });

  const getRoute = async () => {
    const response = await fetch(
      `${BASE_URL}${ALL_ROUTES}?user_id=${user_id}&route_id=${params.routeId}&sort=desc`,
      {
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };
  const {
    data: routes,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["routes_user", params.routeId],
    queryFn: getRoute,
  });

  useEffect(() => {
    formPricePerPackage.reset({
      priceSmall: pricesPerSize[0].price,
      priceMedium: pricesPerSize[1].price,
      priceLarge: pricesPerSize[2].price,
    });
  }, []);

  useEffect(() => {
    formPackage.reset({
      packagePrice: routes?.data.routes[0].price.toString(),
      packageSize: routes?.data.routes[0].package_size.toString(),
      packageWeight: routes?.data.routes[0].package_weight.toString(),
    });
  }, [routes]);

  const onSubmitPrices = async (
    values: z.infer<typeof pricesPackageSchema>
  ) => {
    setPricesPerSize([
      {
        type: "small",
        price: values.priceSmall,
      },
      {
        type: "medium",
        price: values.priceMedium,
      },
      {
        type: "large",
        price: values.priceLarge,
      },
    ]);
    setSubmittedPrices(true);
  };

  async function calculateRoute() {
    const directionsService = new google.maps.DirectionsService();

    const results = await directionsService.route({
      origin: {
        lat: tripPackageDetails.originLat,
        lng: tripPackageDetails.originLng,
      },
      destination: {
        lat: tripPackageDetails.destinationLat,
        lng: tripPackageDetails.destinationLng,
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    //@ts-ignore
    setDistance(results.routes[0].legs[0].distance.text);
    //@ts-ignore
    setDuration(results.routes[0].legs[0].duration.text);
    setOriginZipcode(
      //@ts-ignore
      `${results.routes[0].legs[0].start_address}`.match(/\b\d{5}\b/g)[0]
    );

    setDestinationZipcode(
      //@ts-ignore
      `${results.routes[0].legs[0].end_address}`.match(/\b\d{5}\b/g)[0]
    );
  }

  useEffect(() => {
    calculateRoute();
  }, []);

  const updateTrip = async (
    packagePrice: string,
    packageSize: string,
    packageWeight: string
  ) => {
    const response = await fetch(`${BASE_URL}${ROUTE_UPDATE}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        route_id: params.routeId,
        user_id: user_id,
        price_per_size: `["small" => ${pricesPerSize[0].price},"medium" => ${pricesPerSize[1].price}, "large" => ${pricesPerSize[2].price}]`,
        origin_lat: tripPackageDetails.originLat,
        origin_lng: tripPackageDetails.originLng,
        destination_lat: tripPackageDetails.destinationLat,
        destination_lng: tripPackageDetails.destinationLng,
        start_date: tripPackageDetails.timeDeparture,
        end_date: tripPackageDetails.timeArrival,
        is_one_way: tripPackageDetails.oneWay ? 1 : 0,
        max_stops: tripPackageDetails.maxStops,
        origin_address: tripPackageDetails.originAddress,
        origin_city: tripPackageDetails.originCity,
        origin_zipcode: originZipcode,
        destination_address: tripPackageDetails.destinationAddress,
        destination_city: tripPackageDetails.destinationCity,
        destination_zipcode: destinationZipcode,
        package_size: packageSize,
        package_weight: packageWeight,
        price: packagePrice,
        delivery_distance: distance,
        delivery_duration: duration,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Route updated successfully") {
          navigate(`/carrier/trips`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  };

  const onSubmit = async (values: z.infer<typeof packageSchema>) => {
    if (
      values.packagePrice !== "" &&
      values.packageSize !== "" &&
      values.packageWeight !== ""
    ) {
      updateTrip(values.packagePrice, values.packageSize, values.packageWeight);
    }
  };

  return (
    <div className="bg-gray-50 h-screen">
      <Header />
      <div className="flex flex-col py-10 max-w-4xl mx-auto w-full h-[94vh] no-scrollbar">
        <h1 className="text-2xl font-medium text-gray-500 mt-10">
          Edit Package Prices
        </h1>
        <div className="bg-white shadow mt-10 p-4">
          <div className="flex justify-center w-full pt-10">
            <div className="sm:flex sm:items-start justify-between w-full">
              {shipmentOptions.map((shipment) => (
                <div
                  key={shipment.title}
                  className={cn(
                    "mb-4 flex items-center relative space-x-2 border border-border rounded cursor-pointer transition  w-60   bg-white"
                  )}
                >
                  <div className=" flex items-center p-4">
                    <img
                      className="h-16 w-16 object-contain mr-4"
                      src={shipment.image}
                      alt={shipment.title}
                    />
                    <div className="">
                      <h1 className="text-sm font-semibold">
                        {shipment.title}
                      </h1>
                      <h3 className="text-[13px] font-medium">
                        {shipment.dimensions}
                      </h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {submittedPrices ? (
            <div>
              <h1 className="text-lg font-semibold">Prices per package</h1>
              <div className="flex items-center justify-between">
                {pricesPerSize.map((size) => (
                  <div>
                    <h1 className="text-lg font-medium text-gray-500">
                      {size.type}
                    </h1>
                    <p className="text-sm font-medium">{size.price}€</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <Form {...formPricePerPackage}>
              <form
                onSubmit={formPricePerPackage.handleSubmit(onSubmitPrices)}
                className="flex flex-col w-full space-y-10"
              >
                <div className="flex items-center justify-between">
                  <FormField
                    control={formPricePerPackage.control}
                    name="priceSmall"
                    render={({ field, fieldState }) => (
                      <div>
                        <FormItem {...field}>
                          <FormLabel>Small</FormLabel>
                          <FormControl>
                            <div className="flex items-center">
                              <Input
                                type="number"
                                {...field}
                                name="priceSmall"
                                className="w-full"
                              />
                              <p className="text-sm font-medium ml-2">€</p>
                            </div>
                          </FormControl>
                        </FormItem>
                        {fieldState.error && (
                          <p className="text-sm font-medium text-red-500">
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                  <FormField
                    control={formPricePerPackage.control}
                    name="priceMedium"
                    render={({ field, fieldState }) => (
                      <div>
                        <FormItem {...field}>
                          <FormLabel>Medium</FormLabel>
                          <FormControl>
                            <div className="flex items-center">
                              <Input
                                type="number"
                                {...field}
                                name="priceMedium"
                                className="w-full"
                              />
                              <p className="text-sm font-medium ml-2">€</p>
                            </div>
                          </FormControl>
                        </FormItem>
                        {fieldState.error && (
                          <p className="text-sm font-medium text-red-500">
                            {fieldState.error.message}
                          </p>
                        )}
                      </div>
                    )}
                  />
                  <FormField
                    control={formPricePerPackage.control}
                    name="priceLarge"
                    render={({ field, fieldState }) => (
                      <>
                        <FormItem {...field}>
                          <FormLabel>Large</FormLabel>
                          <FormControl>
                            <div className="flex items-center">
                              <Input
                                type="number"
                                {...field}
                                name="priceLarge"
                                className="w-full"
                              />
                              <p className="text-sm font-medium ml-2">€</p>
                            </div>
                          </FormControl>
                        </FormItem>
                        {fieldState.error && (
                          <p className="text-sm font-medium text-red-500">
                            {fieldState.error.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="flex justify-center">
                  <Button className="w-40 sm:ml-auto">Submit prices</Button>
                </div>
              </form>
            </Form>
          )}
          <Form {...formPackage}>
            <form
              className="flex flex-col w-full space-y-10 border-t-2 border-border mt-10 pt-2"
              onSubmit={formPackage.handleSubmit(onSubmit)}
            >
              <h1 className="text-lg font-medium">Add package</h1>
              <div className="flex flex-col w-full space-y-4">
                <Controller
                  control={formPackage.control}
                  name="packageSize"
                  render={({ field, fieldState }) => (
                    <div>
                      <Select
                        {...field}
                        onValueChange={(val) => {
                          formPackage.setValue("packageSize", val);
                        }}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select Size" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="small">Small</SelectItem>
                          <SelectItem value="medium">Medium</SelectItem>
                          <SelectItem value="large">Large</SelectItem>
                        </SelectContent>
                      </Select>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
                <FormField
                  control={formPackage.control}
                  name="packageWeight"
                  render={({ field, fieldState }) => (
                    <div>
                      <FormItem {...field}>
                        <FormLabel>Package Weight</FormLabel>
                        <FormControl>
                          <div className="flex items-center">
                            <Input
                              type="number"
                              {...field}
                              name="packageWeight"
                              className="w-full"
                            />
                            <p className="text-sm font-medium ml-2">kg</p>
                          </div>
                        </FormControl>
                      </FormItem>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
                <FormField
                  control={formPackage.control}
                  name="packagePrice"
                  render={({ field, fieldState }) => (
                    <div>
                      <FormItem {...field}>
                        <FormLabel>Package Price</FormLabel>
                        <FormControl>
                          <div className="flex items-center">
                            <Input
                              type="number"
                              {...field}
                              name="packagePrice"
                              className="w-full"
                            />
                            <Euro className="h-4 w-4 ml-2" />
                          </div>
                        </FormControl>
                      </FormItem>
                      {fieldState.error && (
                        <p className="text-sm font-medium text-red-500">
                          {fieldState.error.message}
                        </p>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className="flex justify-center">
                <Button
                  disabled={loading}
                  variant={"freightBlue"}
                  className={cn(
                    "w-60 sm:ml-auto  mt-4",
                    successfullyCreated && "bg-green-500"
                  )}
                >
                  {loading ? (
                    <>
                      <Loader2 className="h-5 w-5 animate-spin" />
                    </>
                  ) : (
                    <>
                      {successfullyCreated ? (
                        <>
                          <Check className="text-white" />
                        </>
                      ) : (
                        <p className="text-sm font-medium"> Add package</p>
                      )}
                    </>
                  )}
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default DeliveryRouteEditPrices;
