import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  BASE_URL,
  BOOKINGBYID,
  GET_IMAGE,
  LIST_ALL_PAYMENTS,
  USER_EXISTS,
} from "../api/config";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";

import {
  ArrowLeft,
  Ban,
  CheckCircle,
  Mail,
  MapPin,
  Phone,
  Settings,
} from "lucide-react";
import { useEffect, useState } from "react";
import small from "../assets/images/books-box.png";
import large from "../assets/images/moving-box.png";
import medium from "../assets/images/shoes-box.png";
import ModalCancelBookingQuote from "../components/modal/ModalCancelBookingQuote";
import ModalCompleteBooking from "../components/modal/ModalCompleteBooking";
import ModalConfirmBooking from "../components/modal/ModalConfirmBooking";
import { Button } from "../components/ui/button";
import { useCancelBookingQuote } from "../hooks/use-cancel-booking-quote";
import { useCompleteBooking } from "../hooks/use-complete-booking";
import { useConfirmBooking } from "../hooks/use-confirm-booking";

import SidebarLayout from "../components/layout/SidebarLayout";
import ModalSendMessage from "../components/modal/ModalSendMessage";
import { Skeleton } from "../components/skeleton/Skeleton";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheets";
import { useSendMessage } from "../hooks/use-send-message";
import { cn } from "../lib/utils";

const ViewCarrierBooking = () => {
  const params = useParams();
  const { user_id, user_type, email } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const navigate = useNavigate();
  const modalCancelBookingQuote = useCancelBookingQuote();
  const modalCompleteJob = useCompleteBooking();
  const modalConfirmBooking = useConfirmBooking();
  const [customerId, setCustomerId] = useState("");

  const modalSendMessage = useSendMessage();

  const doesUserExist = async () => {
    const response = await fetch(`${BASE_URL}${USER_EXISTS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        email: email,
        user_id: user_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setCustomerId(data.data[0][0].id);
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  useEffect(() => {
    doesUserExist();
  }, []);

  const getAllListPayments = async () => {
    const response = await fetch(
      `${BASE_URL}${LIST_ALL_PAYMENTS}?user_id=${user_id}&freight_id=${bookingTransporter?.data?.freightid}&booking_id=${params.bookingId}&stripe_customer_id=${customerId}`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const { data: listPayments, isLoading: isLoadingPayment } = useQuery({
    queryKey: ["list_payments", customerId],
    queryFn: getAllListPayments,
  });

  const getBookingsCustomer = async () => {
    const response = await fetch(`${BASE_URL}${BOOKINGBYID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        booking_id: params.bookingId,
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });
    return response;
  };

  const {
    data: bookingTransporter,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["booking_transporter", params.booking_id],
    queryFn: getBookingsCustomer,
  });

  useEffect(() => {
    refetch();
  }, [params.bookingId]);

  return (
    <SidebarLayout>
      <div className="sm:flex items-center justify-between">
        <div
          onClick={() => {
            navigate("/carrier/booking/transporters/booked");
          }}
          className="flex items-center space-x-4 pl-4 py-4"
        >
          <ArrowLeft className="h-4 w-4 text-freightBlueDark cursor-pointer" />
          <h1 className="text-sm hover:underline text-freightBlueDark cursor-pointer">
            Back to Bookings
          </h1>
        </div>
        <p
          className={cn(
            "text-sm font-medium rounded px-4 py-2 capitalize text-white",
            bookingTransporter?.data?.booking_status === "booked" &&
              "bg-freightBlueDark",
            bookingTransporter?.data?.booking_status === "assigned" &&
              "bg-statusAssigned",
            bookingTransporter?.data?.booking_status === "paid" &&
              "bg-statusPaid",
            bookingTransporter?.data?.booking_status === "picking-up" &&
              "bg-statusPickingUp",
            bookingTransporter?.data?.booking_status === "in-transit" &&
              "bg-statusInTransit",
            bookingTransporter?.data?.booking_status === "finished" &&
              "bg-statusFinished",
            bookingTransporter?.data?.booking_status === "complete" &&
              "bg-statusConfirmed",
            bookingTransporter?.data?.booking_status === "cancelled" &&
              "bg-gray-500 text-[#333]"
          )}
        >
          {bookingTransporter?.data?.booking_status}
        </p>
      </div>

      <div className="">
        {isLoading || isRefetching ? (
          <div className="flex items-start space-x-4 bg-white p-4 border-border border justify-between">
            <div className="flex flex-col items-start bg-gray-50 rounded border p-4">
              <Skeleton className="h-5 w-20" />
              <Skeleton className="h-5 w-28 mt-4" />
              <Skeleton className="h-5 w-28 mt-4" />
            </div>
            <div className="flex flex-col items-start">
              <Skeleton className="h-5 w-20" />
              <Skeleton className="h-5 w-40 mt-4" />
              <Skeleton className="h-5 w-40 mt-4" />
            </div>
            <Skeleton className="h-5 w-40" />
          </div>
        ) : (
          <div className="flex items-start space-x-4 bg-white p-4 border-border border justify-between">
            {user_type === "carrier" && bookingTransporter?.data && (
              <div className="flex flex-col items-start w-full relative space-y-2">
                <Sheet>
                  <SheetTrigger asChild>
                    <Button variant="outline" className="ml-auto">
                      <Settings className="h-4 w-4 mr-2" />
                      Settings
                    </Button>
                  </SheetTrigger>
                  <SheetContent className="flex flex-col justify-between">
                    <SheetHeader>
                      <SheetTitle>Booking Settings</SheetTitle>
                      <SheetDescription>
                        Make changes to your booking here.
                      </SheetDescription>
                      <div className="grid gap-4 py-4">
                        <div className="grid grid-cols-4 items-center gap-4">
                          {bookingTransporter.data.status === "booked" && (
                            <Button
                              className="col-span-4"
                              onClick={() =>
                                modalCancelBookingQuote.onOpen(
                                  "cancelBookingQuote",
                                  bookingTransporter.data.id.toString()
                                )
                              }
                              variant={"destructive"}
                            >
                              <Ban className="h-4 w-4 mr-2" />
                              Cancel Booking Quote
                            </Button>
                          )}
                        </div>

                        {user_type === "carrier" &&
                          bookingTransporter.data.status === "booked" && (
                            <div className="grid grid-cols-4 items-center gap-4">
                              <Button
                                className="col-span-4"
                                onClick={() =>
                                  modalConfirmBooking.onOpen(
                                    "confirmBooking",
                                    bookingTransporter.data.freightid.toString()
                                  )
                                }
                                variant={"freightBlue"}
                              >
                                <CheckCircle className="h-4 w-4 mr-2" />
                                Confirm Booking
                              </Button>
                            </div>
                          )}
                      </div>
                    </SheetHeader>

                    <SheetFooter>
                      {user_type === "carrier" && (
                        <div className="grid grid-cols-4 items-center gap-4">
                          <Button className="col-span-1" variant={"outline"}>
                            <Phone className="h-4 w-4" />
                          </Button>
                          <Button
                            className="col-span-3"
                            onClick={() =>
                              modalSendMessage.onOpen("sendMessage", {
                                quoteId: Number(
                                  bookingTransporter.data.quoteid
                                ),
                                receiverId: Number(user_id),
                                freightId: bookingTransporter.data.freightid,
                                transporterImage: "",
                                transporterName: "",
                                carrierId: Number(
                                  bookingTransporter.data.carrier_id
                                ),
                                customerId: Number(
                                  bookingTransporter.data.userId
                                ),
                                senderId: Number(user_id),
                              })
                            }
                            variant={"outline"}
                          >
                            <Mail className="h-4 w-4 mr-2" />
                            <p>Send Message</p>
                          </Button>
                        </div>
                      )}
                    </SheetFooter>
                  </SheetContent>
                </Sheet>

                <div className="flex items-start space-x-2 flex-grow">
                  <div className="bg-gray-50 rounded border p-4 flex flex-col items-start space-y-4">
                    <div className="flex w-full items-start space-x-10">
                      <div className="flex flex-col items-start">
                        <h1 className="font-medium text-sm">Freight</h1>
                        <p className="hover:underline text-freightBlueDark cursor-pointer">
                          {bookingTransporter.data.title}
                        </p>
                      </div>
                      <div className="flex flex-col items-start space-y-2 w-40">
                        <div className="flex flex-col items-start space-y-1">
                          <p className="font-medium text-sm">Pickup date</p>
                          <p className="text-sm font-medium text-gray-500">
                            {bookingTransporter.data.start_date}
                          </p>
                        </div>
                        <div className="flex flex-col items-start space-y-1">
                          <p className="font-medium text-sm">Dropoff date</p>
                          <p className="text-sm font-medium text-gray-500">
                            {bookingTransporter.data.end_date}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col space-y-2">
                        <div className="flex items-center space-x-2">
                          <div className="w-6">
                            <MapPin className="text-green-500 h-5 w-5" />
                          </div>
                          <div className="">
                            <p className="text-sm font-medium">Origin</p>
                            <p className="text-sm font-medium text-gray-500">
                              {bookingTransporter.data.origin_address}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center space-x-2">
                          <MapPin className="text-freightBlueDark h-5 w-5" />
                          <div className="">
                            <p className="text-sm font-medium">Destination</p>
                            <p className="text-sm font-medium text-gray-500">
                              {bookingTransporter.data.destination_address}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-border w-full pt-4">
                      {bookingTransporter.data.animal_img ? (
                        <img
                          src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${bookingTransporter.data.animal_img}&image_type=freight`}
                          className="w-40 object-contain rounded"
                          alt="freightImage"
                        />
                      ) : (
                        <>
                          {bookingTransporter.data?.freight_type_info
                            ?.freight_size?.id === 1 && (
                            <img
                              src={small}
                              className="w-24 object-cover rounded-lg cursor-pointer"
                              alt="small package"
                            />
                          )}
                          {bookingTransporter.data?.freight_type_info
                            ?.freight_size?.id === 2 && (
                            <img
                              src={medium}
                              className="w-24 object-cover rounded-lg cursor-pointer"
                              alt="medium package"
                            />
                          )}
                          {bookingTransporter.data?.freight_type_info
                            ?.freight_size?.id === 3 && (
                            <img
                              src={large}
                              className="w-24 object-cover rounded-lg cursor-pointer"
                              alt="large package"
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col items-start w-80 bg-gray-50 border-border border rounded">
                    <div className="p-4 flex items-center justify-between w-full">
                      <h1 className="font-medium text-sm">Quote Amount</h1>
                      <p className="text-sm font-medium text-gray-500">
                        {Number(bookingTransporter.data.quote_amount).toFixed(
                          2
                        )}
                        €
                      </p>
                    </div>
                    <div className="flex items-center justify-between w-full p-4">
                      <h1 className="font-medium text-sm">Deliver to</h1>
                      <p className="text-sm font-medium text-gray-500">
                        {bookingTransporter.data.deliver_to || "N/A"}
                      </p>
                    </div>
                    <div className="p-4 flex items-center justify-between w-full">
                      <h1 className="font-medium text-sm">Booked date</h1>
                      <p className="text-sm font-medium text-gray-500">
                        {bookingTransporter.data.date_booked}
                      </p>
                    </div>
                    <div className="p-4 flex items-center justify-between w-full">
                      <h1 className="text-sm font-medium">Booking Status</h1>
                      <p className="text-sm font-medium text-gray-500 capitalize">
                        {bookingTransporter.data.booking_status}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {modalCancelBookingQuote.isOpen && <ModalCancelBookingQuote />}
      {modalCompleteJob.isOpen && <ModalCompleteBooking refetch={refetch} />}
      {modalSendMessage.isOpen && <ModalSendMessage />}
      {modalConfirmBooking.isOpen && <ModalConfirmBooking refetch={refetch} />}
    </SidebarLayout>
  );
};

export default ViewCarrierBooking;
