import React from "react";

export const Elipses2 = () => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div
        style={{
          background: "white",
          height: "calc(376px + calc((100vw / 1440) * 72))",
          width: "100%",
          position: "absolute",
          bottom: 0,
          left: 0,
          zIndex: -1, // Coloca el fondo detrás del arco SVG
        }}
      />
      <svg
        className="arc"
        height="92"
        viewBox="150 0 1675 52"
        fill="none"
        aria-hidden="true"
        focusable="false"
        style={{ position: "relative", zIndex: 1 }} // Coloca el arco SVG encima del fondo
      >
        <path
          d="M-400 176C139.222 -24.794 1028.42 -10.941 1440 13.8751V176L-400 176Z"
          fill="#1889ca"
        />
      </svg>
    </div>
  );
};
