import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useQuery } from "@tanstack/react-query";
import { Check, MapPin } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BASE_URL,
  FREIGHT_DETAIL,
  GET_IMAGE,
  GOOGLE_API_KEY,
} from "../api/config";
import Header from "../components/header/Header";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { FreightItem } from "../types/types";

const containerStyle = {
  width: "500px",
  height: "300px",
  borderRadius: "4px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};
const SkeletonLoader = () => (
  <div className="flex flex-col items-start mt-10 space-y-4 p-4">
    <div className="flex items-start space-x-4">
      <Skeleton className="h-5 w-40" />
      <Skeleton className="h-20 w-24" />
      <Skeleton className="h-20 w-24" />
    </div>
    <div className="flex flex-col space-y-2">
      <Skeleton className="h-10 w-56" />
      <Skeleton className="h-10 w-56" />
    </div>
    <div>
      <Skeleton className="h-28 w-28 rounded" />
    </div>
    <div className="flex items-start space-x-4">
      <Skeleton className="h-10 w-20" />
      <Skeleton className="h-10 w-24" />
      <Skeleton className="h-10 w-24" />
      <Skeleton className="h-10 w-20" />
    </div>
  </div>
);
const SuccessFreight = () => {
  const params = useParams();
  const token = useTokenStoreStorage((state) => state.token);
  const { name, user_id } = userInfoUserStore((state) => state);
  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [directionResponse, setDirectionsResponse] = useState<any>(null);
  const [distance, setDistance] = useState<any>(null);
  const [duration, setDuration] = useState<any>(null);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });

  const getFreightDetails = async () => {
    const response = await fetch(
      `${BASE_URL}${FREIGHT_DETAIL}/${params.id}/${user_id}`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const { data, isLoading } = useQuery({
    queryKey: ["freight_details", params.id],
    queryFn: getFreightDetails,
  });

  async function calculateRoute() {
    const directionsService = new google.maps.DirectionsService();

    //@ts-ignore
    const results = await directionsService.route({
      origin: {
        lat: Number(data?.data[0]?.origin_lat),
        lng: Number(data?.data[0]?.origin_lng),
      },
      destination: {
        lat: Number(data?.data[0]?.destination_lat),
        lng: Number(data?.data[0]?.destination_lng),
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance?.text);
    setDuration(results.routes[0].legs[0].duration?.text);
  }

  useEffect(() => {
    if (!isLoading) {
      calculateRoute();
    }
  }, [data, isLoading]);
  const durationSplit = data?.data[0].delivery_duration.split(" ");

  const onLoad = useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);
  return (
    <div className="bg-gray-50 h-screen">
      <Header />
      <div className="max-w-7xl mx-auto mt-20 pb-20 gap-10 bg-white shadow rounded p-4">
        <div className="col-span-5">
          <div className=" px-16 py-10 relative">
            <div className="absolute left-4 top-10 bg-green-500 h-7 w-7 rounded-full justify-center items-center flex">
              <Check className="h-5 w-5 text-white  " />
            </div>
            <div className="flex flex-col items-start space-y-5">
              <h1 className="font-medium">Congratulations {name}</h1>
              <p>
                Your freight shipment is now listed in the marketplace and
                awaiting quotes from transport providers
              </p>
              <Button
                onClick={() => navigate("/dashboard-customer")}
                className="mt-4"
                variant={"freightBlue"}
              >
                Go To My Dashboard
              </Button>
            </div>
            {isLoading ? (
              <SkeletonLoader />
            ) : (
              <>
                {data?.data?.map((freight: FreightItem) => (
                  <div className="md:flex items-start justify-between mt-10 bg-white   border-t border-border pt-4">
                    <div className="flex flex-col items-start space-y-4">
                      <h2
                        onClick={() => navigate(`/dashboard-customer`)}
                        className="text-lg font-semibold text-freightBlueDark hover:underline cursor-pointer"
                      >
                        {freight.title}
                      </h2>
                      <div className="flex items-start space-x-2">
                        {freight.cust_img !== null ? (
                          <img
                            alt="profile"
                            className="h-16 w-16 rounded-full object-cover"
                            src={`${BASE_URL}images?user_id=${user_id}&file_name=${freight.cust_img}&image_type=profile`}
                          />
                        ) : (
                          <img
                            alt="profile"
                            className="h-16 w-16 rounded-full object-cover"
                            src={require("../assets/images/user-icon-placeholder.jpg")}
                          />
                        )}

                        <h1 className="text-xl font-medium text-gray-700">
                          {freight.customer_name}
                        </h1>
                      </div>
                      <div className="flex flex-col items-start p-4 bg-gray-50 border border-gray-200 rounded-lg">
                        <p className="text-sm font-medium text-gray-600">
                          Category
                        </p>
                        <div className="flex items-center space-x-2 mt-1">
                          <span className="text-base font-medium text-gray-700">
                            {freight.category} / {freight.sub_category}
                          </span>
                        </div>
                      </div>
                      <div className="md:flex items-start w-full justify-between bg-gray-50 border border-gray-200 rounded-lg p-4">
                        {/* Dates Info */}
                        <div className="flex flex-col space-y-3">
                          <div className="flex items-center space-x-2">
                            <MapPin className="text-green-500 h-4 w-4" />
                            <p className="text-sm font-medium text-gray-600">
                              {freight.start_date}
                            </p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <MapPin className="text-red-500 h-4 w-4" />
                            <p className="text-sm font-medium text-gray-600">
                              {freight.end_date}
                            </p>
                          </div>
                        </div>

                        {/* Expiration Info */}
                        <div className="flex flex-col items-start space-y-2">
                          <h3 className="text-sm font-medium text-gray-600">
                            Expires:
                          </h3>
                          <span className="text-sm text-gray-500">
                            {freight.expire_date}
                          </span>
                        </div>

                        {/* Status Info */}
                        <div className="flex flex-col items-start space-y-2">
                          <h3 className="text-sm font-medium text-gray-600">
                            Status:
                          </h3>
                          {freight.status === "active" && (
                            <div className="bg-green-500 text-white px-4 py-1 rounded-lg">
                              <span className="text-xs font-medium">
                                {freight.status.toUpperCase()}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="bg-gray-50 rounded-lg border border-gray-200 p-4 w-full space-y-4">
                        <div className="flex flex-col items-start">
                          <h4 className="text-sm font-medium text-gray-600">
                            Origin
                          </h4>
                          <p className="text-sm text-gray-500">
                            {freight.origin_city}
                          </p>
                        </div>
                        <div className="flex flex-col items-start border-t pt-4 w-full">
                          <h4 className="text-sm font-medium text-gray-600">
                            Destination
                          </h4>
                          <p className="text-sm text-gray-500">
                            {freight.destination_city}
                          </p>
                        </div>
                      </div>
                      <div>
                        {freight.items.map(
                          (freightItem: {
                            catid: string | null;
                            created_at: string;
                            freightid: number;
                            id: number;
                            item: {
                              Make: string;
                              Model: string;
                              Year: string;
                              drivable: string;
                              modifications: string;
                              undrivable_details: string;
                            }[];
                          }) => (
                            <div>
                              {freightItem.item.map((item) => (
                                <div className="sm:flex items-start  bg-gray-50 border border-gray-200 rounded-lg p-4 space-x-10 mb-4">
                                  {/* Make */}
                                  <div className="flex flex-col items-start space-y-1">
                                    <h2 className="text-xs font-medium text-gray-500">
                                      Make
                                    </h2>
                                    <p className="text-base font-medium text-gray-800 capitalize">
                                      {item.Make}
                                    </p>
                                  </div>

                                  {/* Model */}
                                  <div className="flex flex-col items-start space-y-1">
                                    <h2 className="text-xs font-medium text-gray-500">
                                      Model
                                    </h2>
                                    <p className="text-base font-medium text-gray-800 capitalize">
                                      {item.Model}
                                    </p>
                                  </div>

                                  {/* Year */}
                                  <div className="flex flex-col items-start space-y-1">
                                    <h2 className="text-xs font-medium text-gray-500">
                                      Year
                                    </h2>
                                    <p className="text-base font-medium text-gray-800 capitalize">
                                      {item.Year}
                                    </p>
                                  </div>

                                  {/* Details */}
                                  <div className="flex flex-col items-start space-y-1">
                                    <h2 className="text-xs font-medium text-gray-500">
                                      Details
                                    </h2>
                                    <div className="flex flex-col space-y-1">
                                      <p className="text-base font-medium text-gray-800 capitalize">
                                        {item.drivable}
                                      </p>
                                      {item.modifications && (
                                        <p className="text-base font-medium text-gray-800 capitalize">
                                          {item.modifications}
                                        </p>
                                      )}
                                      {item.undrivable_details && (
                                        <p className="text-base font-medium text-gray-800 capitalize">
                                          {item.undrivable_details}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )
                        )}
                        {freight.image.map(
                          (
                            freightImage: { freight_img: string },
                            i: number
                          ) => (
                            <img
                              alt={`freight-${i}`}
                              className="w-96 h-32 object-contain rounded"
                              src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${freightImage.freight_img}&image_type=freight`}
                            />
                          )
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col items-start">
                      <div className="justify-end ml-auto p-4 mb-4 text-sm font-medium bg-gray-50 rounded border border-border">
                        Expires in {freight.expire_date}
                      </div>
                      {isLoaded ? (
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={{
                            lat: Number(freight?.origin_lat),
                            lng: Number(freight?.origin_lng),
                          }}
                          options={{
                            streetViewControl: false,
                          }}
                          zoom={15}
                          onLoad={onLoad}
                          onUnmount={onUnmount}
                        >
                          <Marker
                            position={{
                              lat: Number(freight?.origin_lat),
                              lng: Number(freight?.origin_lng),
                            }}
                          />
                          <Marker
                            position={{
                              lat: Number(freight?.destination_lat),
                              lng: Number(freight?.destination_lng),
                            }}
                          />
                          {directionResponse && (
                            <DirectionsRenderer
                              directions={directionResponse}
                            />
                          )}
                        </GoogleMap>
                      ) : null}
                      <div className="mt-4 w-full">
                        <div className="flex items-center justify-between  w-full ">
                          <h1 className="text-sm font-medium">
                            Driving Distance:{" "}
                          </h1>
                          <div className="bg-green-500 text-white px-4 py-1 rounded">
                            <p className="text-sm font-medium">
                              {data.data[0]?.delivery_distance}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center justify-between w-full mt-2">
                          <h1 className="text-sm font-medium">
                            Driving Duration:
                          </h1>
                          <div className="bg-freightBlueDark text-white px-4 rounded py-1">
                            <p className="text-sm font-medium">
                              {data.data[0]?.delivery_duration}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessFreight;
