import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function formatDate(date: Date) {
    return `${new Date(date).getDate()}/${new Date(date).getMonth() + 1
        }/${new Date(date).getFullYear()}`
}

export const pickupFormatDate = (date: string) => {
    return `${date.split("/")[2]}-${date.split("/")[0]}-${date.split("/")[1].length === 1 ? `0${date.split("/")[1]}` : date.split("/")[1]} 00:00:00`
}

export const pickupFormatFreightDate = (date: string) => {
    return `${date.split("/")[2]}-${date.split("/")[1].length === 1 ? `0${date.split("/")[1]}` : date.split("/")[1]}-${date.split("/")[0]} 00:00:00`
}