import { zodResolver } from "@hookform/resolvers/zod";
import { Check, Loader2 } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { BASE_URL, CALCULATE_QUOTE } from "../api/config";
import { Button } from "../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form";
import { Input } from "../components/ui/input";
import { cn } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
//@ts-ignore
import MovingBox from "../assets/images/moving-box.png";
//@ts-ignore
import ShoesBox from "../assets/images/shoes-box.png";
//@ts-ignore
import BooksBox from "../assets/images/books-box.png";
import Header from "../components/header/Header";
import ModalLogin from "../components/modal/ModalLogin";
import useLocalStorage from "../hooks/use-local-storage";
import { useLogin } from "../hooks/use-login";

const shipmentDetailsSchema = z.object({
  weightArticle: z.string().min(1),
  dimensionLongitude: z.string().min(1),
  dimensionWidth: z.string().min(1),
  dimensionHeight: z.string().min(1),
  articleQuantity: z.string().min(1),
});

const InstantFreightPackage = () => {
  const [loadingQuote, setLoadingQuote] = useState(false);
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const params = useParams();
  const [quote, setQuote] = useState(0);
  const [showDimensionsPackages, setShowDimensionsPackages] = useState<any>();
  const [instantItem, setInstantItem] = useLocalStorage("instant_item");
  const navigate = useNavigate();
  const modalLogin = useLogin();
  const [calculateSuccessfully, setCalculatedSuccessfully] = useState(false);
  const [dimensionsPackage, setDimensionsPackage] =
    useLocalStorage("dimensions_package");

  const [selectedPackage, setSelectedPackage] = useState<{
    title: string;
    dimensions: string;
    image: string;
  }>({ dimensions: "", image: "", title: "" });
  const [limitWeight, setLimitWeight] = useState<{ min: number; max: number }>({
    min: 0,
    max: 30,
  });
  const [packageDetails, setPackageDetails] =
    useLocalStorage("package_details");

  const onSubmitShipment = async (
    values: z.infer<typeof shipmentDetailsSchema>
  ) => {
    if (values.dimensionHeight === "15") {
      calculateQuote(instantItem.distance, "medium", values.weightArticle);
      setPackageDetails({
        distance: instantItem.distance,
        dimensions: "medium",
        weight: values.weightArticle,
      });
    } else if (values.dimensionHeight === "35") {
      calculateQuote(instantItem.distance, "large", values.weightArticle);
      setPackageDetails({
        distance: instantItem.distance,
        dimensions: "large",
        weight: values.weightArticle,
      });
    } else if (values.dimensionHeight === "4") {
      calculateQuote(instantItem.distance, "small", values.weightArticle);
      setPackageDetails({
        distance: instantItem.distance,
        dimensions: "small",
        weight: values.weightArticle,
      });
    }
  };

  const shipmentOptions = [
    {
      title: "Small",
      dimensions: "23 x 14 x 4cm",
      image: BooksBox,
    },
    {
      title: "Medium",
      dimensions: "35 x 20 x 15cm",
      image: ShoesBox,
    },
    {
      title: "Large",
      dimensions: "75 x 35 x 35cm",
      image: MovingBox,
    },
  ];
  const handleChangePackage = (item: {
    title: string;
    dimensions: string;
    image: string;
  }) => {
    if (item.dimensions === "32 x 24 x 1cm") {
      shipmentDetails.reset({
        weightArticle: shipmentDetails.getValues("weightArticle"),
        dimensionHeight: "1",
        dimensionLongitude: "32",
        dimensionWidth: "24",
      });
    } else if (item.dimensions === "23 x 14 x 4cm") {
      shipmentDetails.reset({
        weightArticle: shipmentDetails.getValues("weightArticle"),
        dimensionHeight: "4",
        dimensionLongitude: "23",
        dimensionWidth: "14",
      });
      setLimitWeight({
        min: 0,
        max: 5,
      });
    } else if (item.dimensions === "35 x 20 x 15cm") {
      shipmentDetails.reset({
        weightArticle: shipmentDetails.getValues("weightArticle"),
        dimensionHeight: "15",
        dimensionLongitude: "35",
        dimensionWidth: "20",
      });
      setLimitWeight({
        min: 5,
        max: 20,
      });
    } else if (item.dimensions === "75 x 35 x 35cm") {
      shipmentDetails.reset({
        weightArticle: shipmentDetails.getValues("weightArticle"),
        dimensionHeight: "35",
        dimensionLongitude: "75",
        dimensionWidth: "35",
      });
      setLimitWeight({
        min: 20,
        max: 30,
      });
    }
  };

  const shipmentDetails = useForm<z.infer<typeof shipmentDetailsSchema>>({
    resolver: zodResolver(shipmentDetailsSchema),
    defaultValues: {
      articleQuantity: "1",
      dimensionHeight: dimensionsPackage.height,
      dimensionLongitude: dimensionsPackage.length,
      dimensionWidth: dimensionsPackage.width,
      weightArticle: params.weight,
    },
  });

  const calculateQuote = async (
    distance: string,
    dimensions?: string,
    weight?: string,
    userId?: string,
    tokenUser?: string
  ) => {
    setLoadingQuote(true);
    const response = await fetch(`${BASE_URL}${CALCULATE_QUOTE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: tokenUser ? tokenUser : token,
      },
      body: JSON.stringify({
        user_id: userId ? userId : user_id,
        weight: weight,
        dimensions: dimensions,
        kms: distance.split(" ")?.[0].replaceAll(",", ""),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Quote generated successfully") {
          setQuote(data.data.estimated_quote);
          setShowDimensionsPackages(true);
          localStorage.setItem(
            "instant_item",
            JSON.stringify({
              package_size: dimensions,
              distance: distance,
              package_weight: weight,
              price_estimated: data.data.estimated_quote,
              origin_address: instantItem.origin_address,
              origin_zipcode: instantItem.origin_zipcode,
              origin_city: instantItem.origin_city,
              origin_lat: instantItem.origin_lat,
              origin_lng: instantItem?.origin_lng,

              destination_address: instantItem?.destination_address,
              destination_zipcode: instantItem?.destination_zipcode,
              destination_city: instantItem?.destination_city,
              destination_lat: instantItem?.destination_lat,
              destination_lng: instantItem?.destination_lng,
            })
          );
          setCalculatedSuccessfully(true);
          setTimeout(() => {
            navigate("/instant-freight");
          }, 2000);
        }
      })
      .finally(() => {
        setLoadingQuote(false);
        if (!user_id && !userId) {
          modalLogin.onOpen("login");
        }
      });

    return response;
  };
  return (
    <div className="bg-gray-50">
      <Header />
      <div className="flex flex-col py-10 max-w-4xl mx-auto w-full h-[95vh] overflow-y-auto no-scrollbar">
        <div className=" w-full relative flex items-center justify-between ">
          <div className="w-full border-b h-1 absolute top-4 z-10" />
          {window.location.pathname ===
            `/shipping-package/${params.weight}` && (
            <div className="w-1/3 border-b h-1 absolute top-4 z-10 border-[#333]" />
          )}
          {window.location.pathname ===
            `/shipping-package/${params.weight}` && (
            <div className="w-2/3 border-b h-1 absolute top-4 z-10 border-[#333]" />
          )}
          <div className="flex flex-col z-20">
            <div
              className={cn(
                "bg-gray-100 z-20 h-10 w-10 rounded-full text-center items-center justify-center flex",

                window.location.pathname ===
                  `/shipping-package/${params.weight}` && "bg-[#333] text-white"
              )}
            >
              <p className="text-sm font-medium">1</p>
            </div>
            <p
              className={cn(
                "text-xs w-20 mt-2 font-medium text-gray-500",
                window.location.pathname ===
                  `/shipping-package/${params.weight}` && "text-[#333]"
              )}
            >
              Freight Details
            </p>
          </div>
          <div className="flex flex-col z-20 items-center">
            <div
              className={cn(
                "bg-gray-100 z-20 h-10 w-10 rounded-full text-center items-center justify-center flex",
                window.location.pathname ===
                  `/shipping-package/${params.weight}` && "bg-[#333]"
              )}
            >
              <p
                className={cn(
                  "text-sm font-medium",

                  window.location.pathname ===
                    `/shipping-package/${params.weight}` && "text-white"
                )}
              >
                2
              </p>
            </div>
            <p
              className={cn(
                "text-xs text-center w-20 mt-2 font-medium text-gray-500",
                window.location.pathname ===
                  `/shipping-package/${params.weight}` && "text-[#333]"
              )}
            >
              Shipment Details
            </p>
          </div>
          <div className="flex flex-col z-20 items-end  ">
            <div className="bg-gray-100 z-20 h-10 w-10 rounded-full text-center items-center justify-center flex">
              <p className="text-sm font-medium">3</p>
            </div>
            <p className="text-xs w-20 mt-2  justify-end items-end text-right font-medium text-gray-500">
              Submit Freight
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between mt-10">
          <h1 className="text-2xl font-medium text-gray-500">Shipment</h1>
        </div>
        <div className="w-full  my-10 bg-white shadow p-4">
          <div className=" ">
            <div className="  w-full">
              <Form {...shipmentDetails}>
                <form onSubmit={shipmentDetails.handleSubmit(onSubmitShipment)}>
                  <div className="hidden lg:block flex-col items-start space-y-4">
                    <div>
                      <h1 className="text-[15px] font-semibold">
                        Article's weight
                      </h1>
                      <FormField
                        name="weightArticle"
                        control={shipmentDetails.control}
                        render={({ field, fieldState }) => (
                          <>
                            <FormItem>
                              <FormLabel>Weight (kg)</FormLabel>
                              <FormControl>
                                <Input
                                  className="w-60"
                                  {...field}
                                  name="weightArticle"
                                  type="number"
                                  min={limitWeight.min}
                                  max={limitWeight.max}
                                />
                              </FormControl>
                            </FormItem>
                            {fieldState.error && (
                              <div>
                                <p className="text-sm font-medium text-red-500">
                                  {fieldState.error.message}
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      />
                    </div>
                    <div className="flex flex-col items-start border-t border-border pt-4">
                      <h1 className="text-sm font-semibold">
                        Article's dimensions
                      </h1>
                      <div className="sm:flex items-center space-x-2">
                        <FormField
                          control={shipmentDetails.control}
                          name="dimensionLongitude"
                          render={({ field, fieldState }) => (
                            <>
                              <FormItem>
                                <FormLabel>Longitud</FormLabel>
                                <FormControl>
                                  <Input
                                    disabled
                                    {...field}
                                    name="dimensionLongitude"
                                  />
                                </FormControl>
                              </FormItem>
                              {fieldState.error && (
                                <div>
                                  <p className="text-sm font-medium text-red-500">
                                    {fieldState.error.message}
                                  </p>
                                </div>
                              )}
                            </>
                          )}
                        />
                        <p className="text-lg font-medium ">X</p>
                        <FormField
                          control={shipmentDetails.control}
                          name="dimensionWidth"
                          render={({ field, fieldState }) => (
                            <>
                              <FormItem>
                                <FormLabel>Width</FormLabel>
                                <FormControl>
                                  <Input
                                    disabled
                                    {...field}
                                    name="dimensionWidth"
                                  />
                                </FormControl>
                              </FormItem>
                              {fieldState.error && (
                                <div>
                                  <p className="text-sm font-medium text-red-500">
                                    {fieldState.error.message}
                                  </p>
                                </div>
                              )}
                            </>
                          )}
                        />
                        <p className="text-lg font-medium">X</p>
                        <FormField
                          control={shipmentDetails.control}
                          name="dimensionHeight"
                          render={({ field, fieldState }) => (
                            <>
                              <FormItem>
                                <FormLabel>Height</FormLabel>
                                <FormControl>
                                  <Input
                                    disabled
                                    {...field}
                                    name="dimensionHeight"
                                  />
                                </FormControl>
                              </FormItem>
                              {fieldState.error && (
                                <div>
                                  <p className="text-sm font-medium text-red-500">
                                    {fieldState.error.message}
                                  </p>
                                </div>
                              )}
                            </>
                          )}
                        />
                        <div>
                          <FormField
                            control={shipmentDetails.control}
                            name="articleQuantity"
                            render={({ field, fieldState }) => (
                              <>
                                <FormItem>
                                  <FormLabel>Article's Quantity</FormLabel>
                                  <FormControl>
                                    <Input
                                      disabled
                                      type="number"
                                      {...field}
                                      name="articleQuantity"
                                    />
                                  </FormControl>
                                </FormItem>
                                {fieldState.error && (
                                  <div>
                                    <p className="text-sm font-medium text-red-500">
                                      {fieldState.error.message}
                                    </p>
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center mt-4 border-t pt-4">
                    <h1 className=" text-sm font-semibold mb-4">
                      ¿No está seguro de los tamaños?
                    </h1>
                  </div>
                  <div className="flex justify-center">
                    <div className="sm:flex items-start sm:justify-between">
                      {shipmentOptions.map((shipment) => (
                        <div
                          onClick={() => {
                            setSelectedPackage(shipment);
                            handleChangePackage(shipment);
                          }}
                          className={cn(
                            "flex mt-4 mr-2 ml-2 items-center relative space-x-2 hover:shadow-lg cursor-pointer transition border border-border w-60 rounded shadow bg-white",
                            selectedPackage.title === shipment.title &&
                              " shadow-freightBlueDark shadow"
                          )}
                        >
                          <div className=" flex items-center">
                            <img
                              className="h-20 w-20 object-contain mr-4"
                              src={shipment.image}
                              alt={shipment.title}
                            />
                            <div className="">
                              <h1 className="text-sm font-semibold">
                                {shipment.title}
                              </h1>
                              <h3 className="text-[13px] font-medium">
                                {shipment.dimensions}
                              </h3>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="my-10 ">
                    <div className="flex justify-center">
                      {shipmentDetails.getValues("weightArticle") !== "" && (
                        <h1 className="font-medium">
                          Peso total del envío:{" "}
                          {shipmentDetails.getValues("weightArticle")}kg
                        </h1>
                      )}
                    </div>
                    <div className="flex justify-center sm:items-start sm:justify-end mt-4">
                      <Button
                        variant={"freightBlue"}
                        className={cn(
                          ` sm:ml-auto  w-60 transition flex items-center justify-center`,
                          calculateSuccessfully && "bg-green-500"
                        )}
                      >
                        {loadingQuote ? (
                          <>
                            <Loader2 className="h-5 w-5 animate-spin" />
                          </>
                        ) : (
                          <>
                            {calculateSuccessfully ? (
                              <>
                                <Check className="bg-green-500 text-white rounded-full h-5 w-5" />
                              </>
                            ) : (
                              <p className="text-sm font-medium text-center">
                                Obtain your budget
                              </p>
                            )}
                          </>
                        )}
                      </Button>
                    </div>
                  </div>
                </form>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {modalLogin && (
        <ModalLogin
          calculateQuote={calculateQuote}
          packageDetails={packageDetails}
        />
      )}
    </div>
  );
};

export default InstantFreightPackage;
