import { useQuery } from "@tanstack/react-query";
import { LayoutDashboard, LogOut, Truck, User } from "lucide-react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL, GETCATEGORY_SUBCATEGORY, LOGOUTURL } from "src/api/config";
import useLocalStorage from "src/hooks/use-local-storage";
import { cn } from "src/lib/utils";
import {
  useGuidTokenStorage,
  useTokenStoreStorage,
} from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [categoryhome, setCategoryHome] = useLocalStorage("category_home");

  const [menuOpen, setMenuOpen] = useState(false);

  const { user_id, name, user_type, email } = userInfoUserStore(
    (state) => state
  );
  const token = useTokenStoreStorage((state) => state.token);
  const setToken = useTokenStoreStorage((state) => state.addToken);
  const setUserInfo = userInfoUserStore((s) => s.addUserInfo);
  const setGuidToken = useGuidTokenStorage((s) => s.addGuid);

  const logout = async () => {
    const response = await fetch(`${BASE_URL}${LOGOUTURL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Logout done successfully.") {
          setToken("");
          setGuidToken("");
          setUserInfo("", "", "", "", "");
          navigate("/sign-in");
        } else if (data.message === "Error retreiving token.") {
          setToken("");
          setGuidToken("");
          setUserInfo("", "", "", "", "");
          navigate("/sign-in");
        }
      });
    return response;
  };
  const isHomePage =
    location.pathname === "/" ||
    location.pathname === "/pricing" ||
    location.pathname === "/contact-us" ||
    location.pathname === "/how-it-works" ||
    location.pathname === "/terms" ||
    location.pathname === "/policy" ||
    location.pathname === "/claim" ||
    location.pathname === "/about-us";

  const getCategories = async () => {
    const response = await fetch(`${BASE_URL}${GETCATEGORY_SUBCATEGORY}`, {
      method: "GET",
      headers: {
        AccessToken: token,
      },
    }).then((res) => res.json());
    return response;
  };

  const { data: categories } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategories,
  });
  return (
    <div
      className={`px-10 shadow flex h-24 z-40 items-center justify-between ${
        isHomePage
          ? "bg-gradient-to-r from-freightBlueDark to-freightBlue  w-full "
          : "bg-[#4a4a4a] h-[6vh]"
      }`}
    >
      <div
        className={cn(
          " flex items-center justify-between w-full",
          isHomePage && "max-w-7xl mx-auto"
        )}
      >
        <div className="flex items-center space-x-10">
          <img
            onClick={() => navigate("/")}
            src={require("../../assets/logo/logo.png")}
            className="w-28 object-contain cursor-pointer"
            alt="logo"
          />
          {/* {(window.location.pathname === "/" ||
            window.location.pathname === "/pricing") && (
            <div className="hidden lg:block">
              <DropdownMenu>
                <DropdownMenuTrigger className=" font-medium hover:underline text-white font-paragraph">
                  Browse categories
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-full mt-10">
                  <div className="grid grid-cols-3">
                    {categories?.map(
                      (category: {
                        id: number;
                        name: string;
                        img: string;
                        subcategories: { id: number; name: string }[];
                      }) => (
                        <div key={category.id}>
                          <div
                            onClick={() => {
                              setCategoryHome({
                                name: category.name,
                                catid: category.id,
                              });
                              navigate("/getFreight");
                            }}
                            className="px-2 hover:bg-gray-100 group rounded h-10 flex items-center justify-start cursor-pointer"
                          >
                            <p className="text-sm font-medium group-hover:underline">
                              {category.name}
                            </p>
                          </div>

                          {category.subcategories?.map((sub) => (
                            <div
                              key={sub.id}
                              onClick={() => {
                                setCategoryHome({
                                  name: sub.name,
                                  catid: sub.id,
                                });
                                navigate("/getFreight");
                              }}
                              className="px-4 hover:bg-gray-200 group rounded h-8 flex items-center justify-start cursor-pointer"
                            >
                              <p className="text-sm text-gray-600 group-hover:underline">
                                {sub.name}
                              </p>
                            </div>
                          ))}
                        </div>
                      )
                    )}
                  </div>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          )} */}
        </div>

        <div className="lg:hidden ">
          <button className="text-white" onClick={() => setMenuOpen(!menuOpen)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
          {menuOpen && (
            <div className="absolute bg-white -ml-[200px] mt-2 py-2 rounded-lg shadow-xl">
              <ul className="px-4 py-2">
                <li>
                  <button
                    onClick={() => navigate("/searchFreight")}
                    className="block text-gray-800 hover:bg-gray-200 py-2 pr-4"
                  >
                    <p className="text-sm font-medium">Find Freight</p>
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => navigate("/find-trip")}
                    className="block text-gray-800 hover:bg-gray-200 py-2 pr-4"
                  >
                    <p className="text-sm font-medium">Find Trip</p>
                  </button>
                </li>

                <li>
                  <button
                    onClick={() => navigate("/pricing")}
                    className="block text-gray-800 hover:bg-gray-200 py-2 pr-4"
                  >
                    <p className="text-sm font-medium">Pricing </p>
                  </button>
                </li>
                <li>
                  {user_type === "customer" && (
                    <button
                      onClick={() => navigate("/send-now")}
                      className="block text-gray-800 hover:bg-gray-200 py-2 pr-4"
                    >
                      <p className="text-sm font-medium">Send now</p>
                    </button>
                  )}
                </li>
                <li>
                  <button
                    onClick={() => navigate("/how-it-works")}
                    className="block text-gray-800 hover:bg-gray-200 py-2 pr-4"
                  >
                    <p className="text-sm font-medium">How it works</p>
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => navigate("/about-us")}
                    className="block text-gray-800 hover:bg-gray-200 py-2 pr-4"
                  >
                    <p className="text-sm font-medium">About Us</p>
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => navigate("/contact-us")}
                    className="block text-gray-800 hover:bg-gray-200 py-2 pr-4"
                  >
                    <p className="text-sm font-medium">Contact Us</p>
                  </button>
                </li>
                {token === "" ? (
                  <>
                    <button
                      // onClick={() => navigate("/register")}
                      onClick={() => navigate("/preregister")}
                      className="block text-gray-800 hover:bg-gray-200 py-2 pr-4"
                    >
                      Sign up
                    </button>
                    <button
                      // onClick={() => navigate("/sign-in")}
                      onClick={() => navigate("/preregister")}
                      className="block text-gray-800 hover:bg-gray-200 py-2 pr-4"
                    >
                      Sign in
                    </button>
                  </>
                ) : (
                  <div>
                    <li>
                      <button
                        onClick={() =>
                          navigate(
                            `/dashboard${
                              user_type === "customer" ? "-customer" : ""
                            }`
                          )
                        }
                        className="block text-gray-800 hover:bg-gray-200 py-2 pr-4"
                      >
                        <p className="text-sm font-medium">Dashboard</p>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => navigate(`/customer/updateProfile`)}
                        className="block text-gray-800 hover:bg-gray-200 py-2 pr-4"
                      >
                        <p className="text-sm font-medium">
                          Account Information
                        </p>
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => logout()}
                        className="block text-gray-800 hover:bg-gray-200 py-2 pr-4"
                      >
                        <p className="text-sm font-medium">Log out</p>
                      </button>
                    </li>
                  </div>
                )}
              </ul>
            </div>
          )}
        </div>
        <div className="hidden lg:flex items-center space-x-4 mr-60">
          <button
            onClick={() => navigate("/searchFreight")}
            className={cn(
              "text-white ",
              window.location.pathname !== "/" && "text-sm  font-medium"
            )}
          >
            Find Freight
          </button>
          <button
            onClick={() => navigate("/find-trip")}
            className={cn(
              "text-white",
              window.location.pathname !== "/" && "text-sm font-medium"
            )}
          >
            Browse Routes
          </button>
          <button
            onClick={() => navigate("/pricing")}
            className={cn(
              "text-white",
              window.location.pathname !== "/" && "text-sm font-medium"
            )}
          >
            Pricing
          </button>
          {user_type === "customer" && (
            <button
              onClick={() => navigate("/send-now")}
              className={cn(
                "text-white",
                window.location.pathname !== "/" && "text-sm font-medium"
              )}
            >
              Send now
            </button>
          )}
          <button
            onClick={() => navigate("/how-it-works")}
            className={cn(
              "text-white",
              window.location.pathname !== "/" && "text-sm font-medium"
            )}
          >
            How it works
          </button>
          <button
            onClick={() => navigate("/about-us")}
            className={cn(
              "text-white",
              window.location.pathname !== "/" && "text-sm font-medium"
            )}
          >
            About Us
          </button>
          <button
            onClick={() => navigate("/contact-us")}
            className={cn(
              "text-white",
              window.location.pathname !== "/" && "text-sm font-medium"
            )}
          >
            Contact Us
          </button>
          {/* {token === "" ? (
            <>
              <button
                onClick={() => navigate("/register")}
                className="border rounded px-4 py-2 text-white font-medium"
              >
                Sign up
              </button>
              <button
                onClick={() => navigate("/sign-in")}
                className="border rounded px-4 py-2 text-white font-medium"
              >
                Sign in
              </button>
            </>
          ) : (
            <div className="flex items-center">
              <button
                onClick={() =>
                  navigate(
                    `/dashboard${user_type === "customer" ? "-customer" : ""}`
                  )
                }
                className="text-white"
              >
                {name !== null ? name : email}
              </button>
              <button
                onClick={() => navigate(`/customer/updateProfile`)}
                className="border rounded px-4 py-2 text-white font-medium"
              >
                Account
              </button>
              <button
                onClick={() => logout()}
                className="border rounded px-4 py-2 text-white font-medium"
              >
                Log out
              </button>
            </div>
          )} */}
          <div className="flex items-center space-x-2">
            {/* {window.location.pathname !== "/sign-in" && token === "" && (
              <button
                onClick={() => navigate("/sign-in")}
                className=" rounded px-4 py-2 text-white font-medium "
              >
                Sign in
              </button>
            )}
            {window.location.pathname !== "/register" && token === "" && (
              <button
                onClick={() => navigate("/register")}
                className={cn(
                  "border rounded px-4 py-2 bg-white text-[#4a4a4a]",
                  window.location.pathname === "/" &&
                    "bg-white font-medium text-[#1889CA]"
                )}
              >
                Sign up
              </button>
            )} */}
          </div>
          <div>
            {token !== "" && (
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <div className="flex items-center space-x-2 hover:bg-gray-50/20  py-6 px-4 cursor-pointer">
                    {user_type === "customer" ? (
                      <User className="h-5 w-5 fill-white" />
                    ) : (
                      <Truck className="h-5 w-5 fill-white" />
                    )}
                    {name !== null ? (
                      <h1 className="text-sm font-medium text-white capitalize">
                        {name}
                      </h1>
                    ) : (
                      <h1 className="text-xs font-medium text-white capitalize">
                        {email}
                      </h1>
                    )}
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-72">
                  <div className="p-2">
                    <h1 className="text-lg font-semibold">{name}</h1>
                    <p className="text-sm ">{email}</p>
                  </div>
                  <DropdownMenuSeparator />
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      onClick={() => {
                        user_type === "customer"
                          ? navigate(`/dashboard-customer`)
                          : navigate(`/dashboard`);
                      }}
                    >
                      <LayoutDashboard className="w-4 h-4 mr-3" />
                      <span>Dashboard</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => {
                        user_type === "customer"
                          ? navigate(`/customer/updateProfile`)
                          : navigate(`/carrier/profileview/${user_id}`);
                      }}
                    >
                      <User className="w-4 h-4 mr-3" />
                      <span>Account Information</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <Button
                        onClick={() => logout()}
                        variant={"outline"}
                        className="w-full"
                      >
                        <LogOut className="mr-2 h-4 w-4" />
                        <span>Log out</span>
                      </Button>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
