import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { useAddCarrierDrivers } from "src/hooks/use-add-carrier-drivers";
import { z } from "zod";
import { Form, FormControl, FormField, FormItem, FormLabel } from "../ui/form";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Button } from "../ui/button";
import { BASE_URL, DRIVERS_ADD, DRIVERS_UPDATE } from "src/api/config";
import toast from "react-hot-toast";
import { useEditDriver } from "src/hooks/use-edit-driver";

const addDriverSchema = z.object({
  fistName: z.string().min(1),
  lastName: z.string().min(1),
  emailAddress: z.string().min(1),
  phoneNumber: z.string().min(1),
  status: z.string().min(1),
});

interface ModalEditDriverProps {
  refetchDrivers: () => void;
}

const ModalEditDriver: React.FC<ModalEditDriverProps> = ({
  refetchDrivers,
}) => {
  const token = useTokenStoreStorage((state) => state.token);
  const { email, user_id } = userInfoUserStore((state) => state);
  const modalEditDriver = useEditDriver();
  const navigate = useNavigate();

  const editDriverData = new FormData();

  editDriverData.append("user_id", user_id);
  editDriverData.append("status", "1");
  editDriverData.append("driver_id", modalEditDriver.driver!.id.toString());

  const addDriver = async () => {
    const response = await fetch(`${BASE_URL}${DRIVERS_UPDATE}`, {
      method: "POST",
      headers: {
        AccessToken: token,
      },
      body: editDriverData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Driver updated successfully.") {
          toast.success(data.message);
          modalEditDriver.onClose();
          refetchDrivers();
        }
      });
    return response;
  };

  const editDriverForm = useForm<z.infer<typeof addDriverSchema>>({
    resolver: zodResolver(addDriverSchema),
    defaultValues: {
      fistName: modalEditDriver.driver?.firstname,
      emailAddress: modalEditDriver.driver?.email,
      lastName: modalEditDriver.driver?.lastname,
      phoneNumber: modalEditDriver.driver?.phoneNumber,
      status: modalEditDriver.driver?.status,
    },
  });

  const onSubmit = (values: z.infer<typeof addDriverSchema>) => {
    editDriverData.append("firstname", values.fistName);
    editDriverData.append("lastname", values.lastName);
    editDriverData.append("email", values.emailAddress);
    editDriverData.append("phoneNumber", values.phoneNumber);

    addDriver();
  };

  return (
    <Dialog
      open={modalEditDriver.isOpen}
      onOpenChange={modalEditDriver.onClose}
    >
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-start space-y-2 mb-10 w-full">
              <div className="flex items-center">
                <h1>Add Driver</h1>
              </div>
              <div className="mt-4 flex flex-col w-full">
                <Form {...editDriverForm}>
                  <form onSubmit={editDriverForm.handleSubmit(onSubmit)}>
                    <FormField
                      name="fistName"
                      control={editDriverForm.control}
                      render={({ field }) => (
                        <FormControl {...field}>
                          <FormItem>
                            <FormLabel>First Name</FormLabel>
                            <Input
                              {...field}
                              name="fistName"
                              className="w-full dark:bg-transparent"
                            />
                          </FormItem>
                        </FormControl>
                      )}
                    />
                    <FormField
                      name="lastName"
                      control={editDriverForm.control}
                      render={({ field }) => (
                        <FormControl {...field}>
                          <FormItem>
                            <FormLabel>Last Name</FormLabel>
                            <Input
                              {...field}
                              name="lastName"
                              className="w-full dark:bg-transparent"
                            />
                          </FormItem>
                        </FormControl>
                      )}
                    />
                    <FormField
                      name="emailAddress"
                      control={editDriverForm.control}
                      render={({ field }) => (
                        <FormControl {...field}>
                          <FormItem>
                            <FormLabel>Email</FormLabel>
                            <Input
                              {...field}
                              name="emailAddress"
                              className="w-full dark:bg-transparent"
                            />
                          </FormItem>
                        </FormControl>
                      )}
                    />
                    <FormField
                      name="phoneNumber"
                      control={editDriverForm.control}
                      render={({ field }) => (
                        <FormControl {...field}>
                          <FormItem>
                            <FormLabel>Phone Number</FormLabel>
                            <Input
                              {...field}
                              name="phoneNumber"
                              className="w-full dark:bg-transparent"
                            />
                          </FormItem>
                        </FormControl>
                      )}
                    />
                    <h1 className="text-sm font-medium mt-4 pb-2">Status</h1>
                    <Controller
                      name="status"
                      control={editDriverForm.control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          onValueChange={(value) => {
                            editDriverForm.setValue("status", value);
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="active">Active</SelectItem>
                            <SelectItem value="inactive">Inactive</SelectItem>
                          </SelectContent>
                        </Select>
                      )}
                    />
                    <Button className="mt-4" variant={"freightBlue"}>
                      Edit Driver
                    </Button>
                  </form>
                </Form>
              </div>
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalEditDriver;
