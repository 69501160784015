import { useLogin } from "src/hooks/use-login";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel } from "../ui/form";
import { Input } from "../ui/input";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Button } from "../ui/button";
import { useEffect, useState } from "react";
import { Loader2 } from "lucide-react";
import { BASE_URL, CUSTOMER_LOGIN_URL, PRE_LOGIN } from "src/api/config";
import {
  useGuidTokenStorage,
  useTokenStoreStorage,
} from "src/store/useTokenStore";
import toast from "react-hot-toast";
import { useAtom } from "jotai";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { userId } from "src/atoms/atoms";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "src/hooks/use-local-storage";

interface ModalLoginProps {
  calculateQuote: (
    distance: string,
    dimensions: string,
    weight: string,
    userId?: string,
    tokenUser?: string
  ) => void;
  packageDetails: {
    distance: string;
    dimensions: string;
    weight: string;
  };
}

const ModalLogin: React.FC<ModalLoginProps> = ({
  calculateQuote,
  packageDetails,
}) => {
  const modalLogin = useLogin();
  const formSchema = z.object({
    email: z.string().min(1),
    password: z.string().min(1),
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const setGuidToken = useGuidTokenStorage((state) => state.addGuid);
  const setTokens = useTokenStoreStorage((s) => s.addToken);
  const [user_id, setUserId] = useAtom(userId);
  const setUserInfo = userInfoUserStore((s) => s.addUserInfo);
  const [showErrorPassword, setShowErrorPassword] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const navigate = useNavigate();
  //   const [packageDetails, setPackageDetails] =
  //     useLocalStorage("package_details");

  const prelogin = async (username: string, password: string) => {
    const response = await fetch(`${BASE_URL}${PRE_LOGIN}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setGuidToken(data.data.GUID);
        loginUser(username, password, data.data.GUID);
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const loginUser = async (
    email: string,
    password: string,
    guidToken: string
  ) => {
    const response = await fetch(
      `${BASE_URL}${CUSTOMER_LOGIN_URL}?username=${email}&password=${password}&GUID=${guidToken}`,
      {
        method: "POST",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (
          data.message ===
          "User authentication has failed. Check your credentials and try again."
        ) {
          setShowErrorPassword(true);
          toast.error(data.message);
        }
        if (data.message === "User logged in successfully.") {
          setUserInfo(
            data.data.email,
            data.data.phone_number,
            data.data.user_type,
            data.data.user_id,
            data.data.first_name
          );
          setUserId(data.data.user_id);
          setTokens(data.data.access_token);
          //   calculateQuote();
          if (data.data.user_type === "customer") {
            //   navigate("/dashboard-customer");
            modalLogin.onClose();
            calculateQuote(
              packageDetails.distance,
              packageDetails.dimensions,
              packageDetails.weight,
              data.data.user_id,
              data.data.access_token
            );
          }
          if (data.data.user_type === "carrier") {
            //   navigate("/dashboard");
            toast.error(`You are logged in as a driver. Login as a customer`);
          }
        }
        if (data.message === "Your account need to be verified first.") {
          navigate("/register/verify");
        }
        // if (data.message === "This account is currently logged in.") {
        //   setUserId(data.data.user_id);
        //   setLoading(false);

        //   if (data.data.user_id !== "") {
        //     loginCheck(data.data.user_id, guidToken);
        //   }
        // }
      })
      .finally(() => {
        setLoading(false);
      });

    return response;
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    prelogin(values.email, values.password);
  };
  return (
    <Dialog open={modalLogin.isOpen} onOpenChange={modalLogin.onClose}>
      <DialogContent>
        <DialogHeader>
          <h1 className="text-lg font-semibold">Login User</h1>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full flex flex-col"
          >
            <div className="w-full mb-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-sm pb-3 dark:text-black">
                      Correo electrónico o nombre de usuario *
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        name="email"
                        className="w-full dark:bg-transparent"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            <div className="w-full ">
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <div className="w-full mb-4 relative">
                      <FormLabel className="text-sm pb-3 dark:text-black">
                        Contraseña *
                      </FormLabel>
                      <FormControl>
                        <Input
                          type={showPassword ? "password" : "text"}
                          {...field}
                          name="password"
                          className="w-full dark:bg-transparent "
                        />
                      </FormControl>
                      <div className="absolute top-9 right-2">
                        {!showPassword ? (
                          <div
                            onClick={() => setShowPassword(true)}
                            className="cursor-pointer"
                          >
                            <FaEyeSlash />
                          </div>
                        ) : (
                          <div
                            className="cursor-pointer"
                            onClick={() => setShowPassword(false)}
                          >
                            <FaEye />
                          </div>
                        )}
                      </div>
                    </div>
                  </FormItem>
                )}
              />
              <div className="w-full mb-10">
                <p className="text-text text-sm font-medium">
                  ¿Olvidaste tu contraseña?
                </p>
              </div>
            </div>
            <div className="w-full">
              <Button
                disabled={loading}
                type="submit"
                variant={"freightBlue"}
                className="w-full"
              >
                {loading ? (
                  <Loader2 className="animate-spin h-6 w-6" />
                ) : (
                  <p>Inicia sesión</p>
                )}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ModalLogin;
