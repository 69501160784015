import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useQuery } from "@tanstack/react-query";
import { Loader2, Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ATTACH_PAYMENT_METHOD,
  BASE_URL,
  CREATE_CUSTOMER,
  CUSTOMER_PAYMENT_METHODS,
  GET_SINGLE_STOP,
  PAYMENT_INTENT,
  PAYMENT_METHOD,
  USER_EXISTS,
} from "../api/config";
import Header from "../components/header/Header";
import CheckoutCompleteDeliveryPackage from "../components/payment/CheckoutCompleteDeliveryPackage";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import { cn } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { PaymentMethodType } from "../types/types";

const RoutePackagePayStop = () => {
  const [loadingUserExist, setLoadingUserExist] = useState(false);
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id, name, email, mobile, user_type } = userInfoUserStore(
    (state) => state
  );
  const [loadingPaymentWithCustomer, setLoadingPaymentWithCustomer] =
    useState(false);
  const [customerId, setCustomerId] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [createWithCustomer, setCreateWithCustomer] = useState(false);
  const [paymentMadeWithRegisteredCard, setPaymentMadeWithRegisteredCard] =
    useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentSuccessulWithCard, stePaymentSuccessfulWithCard] =
    useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [selectedMethod, setSelectedMethod] = useState<any>();
  const [showMore, setShowMore] = useState(false);
  const [followUpPayment, setFollowUpPayment] = useState(false);
  const [noAddedPaymentMethods, setNoAddedPaymentMethods] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const [secureQuote, setSecureQuote] = useState(false);
  const params = useParams();
  const [selectedWithPaymentMethod, setSelectedWithPaymentMethod] =
    useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  useEffect(() => {
    setSelectedMethod(null);
    setSelectedWithPaymentMethod(false);
  }, []);

  const stripePromise = loadStripe(
    "pk_test_51QBHUHIci7ftBuwklK7hfOot7XAWPHu8UpfdwLBmAo697eX4pPtYeJXCrqpkoZzxerzUUqVvZ1VS1zy1d2IS5vXo00aD6le0UG"
  );
  const appearance = {
    theme: "stripe",
  };

  const options: any = {
    clientSecret,
    appearance,
  };
  const getStop = async () => {
    const response = await fetch(
      `${BASE_URL}${GET_SINGLE_STOP}?user_id=${user_id}&stop_id=${params.stopId}`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const { data: stop } = useQuery({
    queryKey: ["stop"],
    queryFn: getStop,
  });

  //CREATE CUSTOMER
  const createCustomer = async () => {
    const response = await fetch(`${BASE_URL}${CREATE_CUSTOMER}`, {
      method: "POST",
      headers: {
        AccessToken: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        description: `${name} - ${user_type}`,
        email: email,
        name: name,
        phone: mobile,
        user_id: user_id,
        shipping: {
          address: `c/ Daniel Balaciart 2`,
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Customer created successful!") {
          setCustomerId(data.data[0].id);
          // setShowRegisteredCards(!showRegisteredCards);
        }
      });

    return response;
  };

  // USER EXIST
  const doesUserExist = async () => {
    const response = await fetch(`${BASE_URL}${USER_EXISTS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        email: email,
        user_id: user_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "User is not registered in stripe.") {
          createCustomer();
        }
        if (data.data?.length > 0) {
          setCustomerId(data.data[0][0].id);
          createPaymentMethod(data.data[0][0].id);
        } else {
        }
      })
      .finally(() => {
        setLoadingUserExist(false);
      });

    return response;
  };

  useEffect(() => {
    doesUserExist();
  }, []);

  //PAYMENT METHOD

  //ATTACH

  const attachPaymentMethod = async (paymentMethodToken: string) => {
    const response = await fetch(`${BASE_URL}${ATTACH_PAYMENT_METHOD}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        customer_id: customerId,
        payment_method_id: paymentMethodToken,
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const getCustomerPaymentMethods = async () => {
    const response = await fetch(
      `${BASE_URL}${CUSTOMER_PAYMENT_METHODS}?customer_id=${customerId}&user_id=${user_id}`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // This block will always run after the promise resolves or rejects
        setInitialLoading(false);
      });

    return response;
  };
  const { data: paymentMethods, isLoading } = useQuery({
    queryKey: ["payment_methods", customerId],
    queryFn: getCustomerPaymentMethods,
    enabled: customerId !== "",
  });

  const createPaymentMethod = async (customerId: string) => {
    const response = await fetch(`${BASE_URL}${PAYMENT_METHOD}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        customer_id: customerId,
        // payment_method_token: paymentMethodToken,
      }),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  //PAYMENT INTENT

  //NO CUSTOMER
  const createPaymentNoCustomer = async () => {
    setLoadingPayment(true);

    const response = await fetch(`${BASE_URL}${PAYMENT_INTENT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        amount: `${Math.round(Number(stop?.data[0].price))}00`,
        user_id: user_id,
        customer_id: customerId,
        description: `FS Trip - ${params.stopId}`,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Session creation went successful!") {
          setClientSecret(data.data[0].clientSecret);
          setCreateWithCustomer(false);
          // stePaymentSuccessfulWithCard(true);
        }
      })
      .finally(() => {
        setLoadingPayment(false);
      });

    return response;
  };

  //CUSTOMER
  const createPayment = async (customerId: string) => {
    setLoadingPaymentWithCustomer(true);
    const response = await fetch(`${BASE_URL}${PAYMENT_INTENT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        // amount: `${modal.amount}00`,
        amount: `${Math.round(Number(stop?.data[0].price))}00`,

        customer_id: customerId,
        payment_method_token: selectedMethod.id,
        user_id: user_id,
        description: `FS Trip - ${params.stopId}`,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Session creation went successful!") {
          setPaymentIntentId(data.data[0].paymentIntentId);
          setCreateWithCustomer(true);
          setPaymentMadeWithRegisteredCard(false);
          setClientSecret(data.data[0].clientSecret);
          setSelectedWithPaymentMethod(true);
          setShowCheckout(true);
        }
      })
      .finally(() => {
        setLoadingPaymentWithCustomer(false);
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  if (clientSecret !== "") {
    return (
      <div className="bg-gray-50 h-screen">
        <Header />
        <div className="flex flex-col py-10 max-w-4xl mx-auto w-full h-[94vh] no-scrollbar">
          <h1 className="text-2xl font-medium text-gray-500 mt-10 mb-4">
            Pay for Stop Delivery Package
          </h1>
          <div className="bg-white p-4 border border-border">
            <Elements options={options} stripe={stripePromise}>
              <CheckoutCompleteDeliveryPackage
                clientSecret={clientSecret}
                createWithCustomer={createWithCustomer}
                attachPaymentMethod={attachPaymentMethod}
                customerId={customerId}
                paymentIntentId={paymentIntentId}
                selectedMethod={selectedMethod}
                stop={stop}
                // paymentSuccessful={paymentSuccessulWithCard}
                selectedWithPaymentMethod={selectedWithPaymentMethod}
              />
            </Elements>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 h-screen">
      <Header />
      <div className="flex flex-col py-10 max-w-4xl mx-auto  w-full h-[94vh] no-scrollbar">
        {/* payment methods */}
        <h1 className="text-2xl font-medium text-gray-500 mt-10 mb-4">
          Pay for Stop Delivery Package
        </h1>
        <div className="bg-white shadow p-4">
          <div>
            <Button
              onClick={() => {
                setAddPaymentMethod(true);
                createPaymentNoCustomer();
                setSecureQuote(false);
              }}
              variant={"outline"}
              className="mb-4"
            >
              <Plus />
              Add Payment Method
            </Button>
            {paymentMethods?.message !==
              "This customer has no payment methods associated, please create a new one." && (
              <div className="flex flex-col space-y-1 py-2">
                {!followUpPayment && (
                  <h1 className="text-sm font-medium mb-2">Previously used</h1>
                )}

                {initialLoading ? (
                  <div className="border py-2 rounded flex items-center cursor-pointer w-full justify-between px-4  bg-white">
                    <div>
                      <img
                        src={require("../assets/visa.png")}
                        className="h-10 w-10 object-contain rounded"
                        alt="visa"
                      />
                    </div>
                    <div className="flex flex-col items-end">
                      <Skeleton className="h-4 w-10" />
                      <Skeleton className="h-4 w-10 mt-2" />
                    </div>
                  </div>
                ) : (
                  <>
                    {paymentMethods?.data?.length > 0 && (
                      <div
                        className={cn(
                          "flex flex-col space-y-2",
                          followUpPayment && "opacity-20"
                        )}
                      >
                        <div
                          onClick={() =>
                            setSelectedMethod(paymentMethods?.data[0])
                          }
                          className={cn(
                            "border py-2 rounded flex items-center cursor-pointer w-full justify-between px-4  bg-white",
                            selectedMethod?.id ===
                              paymentMethods?.data[0]?.id &&
                              "bg-gray-200 text-[#333]"
                          )}
                        >
                          <div>
                            <img
                              src={require("../assets/visa.png")}
                              className="h-10 w-10 object-contain rounded"
                              alt={`visa-${paymentMethods?.data[0].card.brand}`}
                            />
                          </div>
                          <div className="flex flex-col items-end">
                            <p className="text-sm">
                              *{paymentMethods?.data[0].card.last4}
                            </p>
                            <p className="text-xs font-medium text-gray-400">
                              {paymentMethods?.data[0].card.exp_month}/
                              {paymentMethods?.data[0].card.exp_year}
                            </p>
                          </div>
                        </div>
                        {!showMore && (
                          <h3
                            onClick={() => setShowMore(true)}
                            className="hover:underline text-freightBlueDark cursor-pointer text-sm font-medium text-right"
                          >
                            Show more
                          </h3>
                        )}
                      </div>
                    )}
                  </>
                )}

                {/* {showMore && */}
                {showMore && (
                  <div className="flex flex-col space-y-1 py-2">
                    {paymentMethods?.data?.length > 0 &&
                      paymentMethods?.data
                        .slice(1, 5)
                        .map((method: PaymentMethodType) => (
                          <div
                            onClick={() => setSelectedMethod(method)}
                            className={cn(
                              "border py-2 rounded flex items-center cursor-pointer w-full justify-between px-4  bg-white",
                              selectedMethod?.id === method?.id &&
                                "bg-gray-200 text-[#333]"
                            )}
                          >
                            <div>
                              <img
                                src={require("../assets/visa.png")}
                                className="h-10 w-10 object-contain rounded"
                                alt={`visa-${method.card.brand}`}
                              />
                            </div>
                            <div className="flex flex-col items-end">
                              <p className="text-sm">*{method.card.last4}</p>
                              <p className="text-xs font-medium text-gray-400">
                                {method.card.exp_month}/{method.card.exp_year}
                              </p>
                            </div>
                          </div>
                        ))}
                    <h3
                      onClick={() => setShowMore(false)}
                      className="hover:underline text-freightBlueDark cursor-pointer text-sm font-medium text-right"
                    >
                      Show less
                    </h3>
                  </div>
                )}
              </div>
            )}

            {followUpPayment && (
              <div className="flex flex-col items-start w-full border-t border-border">
                <div className="pt-4 text-sm">
                  Are you sure you want continue with this card?
                </div>
                <div className="flex  items-start justify-between py-2 mt-4 mb-4 w-full bg-white rounded border-border border px-4">
                  <div className="flex  items-center space-x-2">
                    <h1 className="uppercase">
                      <img
                        src={require("../assets/visa.png")}
                        className="h-5 w-5 object-contain rounded"
                        alt={`visa-${selectedMethod.card.brand}`}
                      />
                    </h1>
                    <div className="flex items-center space-x-2">
                      <h1 className="text-sm font-medium">
                        *{selectedMethod.card.last4}
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <p className="text-sm font-medium">
                      {selectedMethod.card.exp_month} /{" "}
                      {selectedMethod.card.exp_year}
                    </p>
                  </div>
                </div>

                <Button
                  disabled={loadingPaymentWithCustomer}
                  variant={"freightBlue"}
                  onClick={() => {
                    createPayment(customerId);
                    setPaymentMadeWithRegisteredCard(true);
                  }}
                  className="justify-end ml-auto"
                >
                  {!loadingPaymentWithCustomer ? (
                    <p className="text-sm font-medium">Continue</p>
                  ) : (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  )}
                </Button>
              </div>
            )}
            {paymentMethods?.message !==
              "This customer has no payment methods associated, please create a new one." && (
              <>
                {!followUpPayment && addPaymentMethod ? (
                  <></>
                ) : (
                  <div className="flex flex-col items-start space-y-4 w-full mt-4">
                    {!noAddedPaymentMethods &&
                      !followUpPayment &&
                      selectedMethod?.customer !== "" && (
                        <Button
                          onClick={() => {
                            // createPayment(selectedMethod.customer);
                            setFollowUpPayment(true);
                          }}
                          className="justify-end items-end ml-auto"
                          variant={"freightBlue"}
                          disabled={!selectedMethod}
                        >
                          Pay with this card
                        </Button>
                      )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoutePackagePayStop;
