import { create } from "zustand";
import { QuoteType } from "../types/types";

export type ModalType = "addQuote";

interface ModalStore {
  type: ModalType | null;
  data: QuoteType | null;
  isOpen: boolean;
  onOpen: (type: ModalType, data: QuoteType) => void;
  onClose: () => void;
}

export const useAddQuote = create<ModalStore>((set) => ({
  type: null,
  data: null,
  isOpen: false,
  onOpen: (type, data) => set({ isOpen: true, type, data }),
  onClose: () => set({ type: null, isOpen: false }),
}));
