import { useQuery } from "@tanstack/react-query";
import { ArrowLeft, ArrowRight, Loader2, Plus } from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, FREIGHT_LIST } from "../api/config";
import CustomerFreightCard from "../components/card/CustomerFreightCard";
import SidebarLayout from "../components/layout/SidebarLayout";
import ModalCancelFreight from "../components/modal/ModalCancelFreight";
import ModalExtendFreight from "../components/modal/ModalExtendFreight";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { useCancelFreightModal } from "../hooks/use-cancel-freight";
import { useExtendFreight } from "../hooks/use-extend-freight";
import useLocalStorage from "../hooks/use-local-storage";
import { cn } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { FreightItem } from "../types/types";

const CustomerFreight = () => {
  const modalCancelFreight = useCancelFreightModal();
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const params = useParams();
  const navigate = useNavigate();
  const modalExtendFreight = useExtendFreight();
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<"ASC" | "DESC">("DESC");
  const [categoryhome, setCategoryHome] = useLocalStorage("category_home");

  const getFreightList = async () => {
    const response = await fetch(
      `${BASE_URL}${FREIGHT_LIST}?sort=${sortBy}&page=${page}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
        body: JSON.stringify({
          user_id: user_id,
          status: params.type,
          page: page,
          items_per_page: 5,
          sort: sortBy,
        }),
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const {
    data: freightList,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["freight_list", params.type, page, sortBy],
    queryFn: getFreightList,
  });

  const sortOptions = [
    {
      value: "ASC",
      label: "Ascendent",
    },
    {
      value: "DESC",
      label: "Descendent",
    },
  ];

  return (
    <SidebarLayout>
      <div className="">
        <div className="flex items-center w-full justify-between border-b border-border pb-3 mb-4">
          <h1 className="text-lg font-semibold ">My Freights</h1>
          <div className="flex items-center space-x-2">
            <div
              onClick={() => navigate("/getFreight")}
              className="flex bg-freightBlueDark rounded text-white items-center space-x-1 border-b  px-4 py-2 cursor-pointer"
            >
              <div className=" flex items-center justify-center rounded-full ">
                <Plus className="h-4 w-4 " />
              </div>
              <h1 className="text-sm font-medium">List Freight</h1>
            </div>
            <div className="bg-gray-100 px-4 py-2 capitalize text-sm font-medium">
              {params.type!.slice(0, 1).toUpperCase() +
                params.type!.slice(1).toLowerCase()}
            </div>
          </div>
        </div>

        {/* {freightList?.message === "No freight found." ? (
          <div className="text-center py-4 text-sm font-medium text-gray-500">
            No freights found. Please check back later.
          </div>
        ) : ( */}
        <>
          {freightList?.message === "No freight found." ? (
            <></>
          ) : (
            <div className="mt-4 ml-auto w-full justify-end flex space-x-2 mb-4">
              <div className="text-sm font-medium flex items-center space-x-1">
                <h1>Total</h1>
                {!isLoading ? (
                  <span>{freightList?.data?.total_items}</span>
                ) : (
                  <span>
                    <Loader2 className="h-4 w-4 animate-spin" />
                  </span>
                )}{" "}
                <h1>freights</h1>
              </div>

              <Select
                onValueChange={(val: any) => {
                  setSortBy(val);
                }}
                value={sortBy}
              >
                <SelectTrigger className="w-40 h-8">
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent>
                  {sortOptions.map((sort) => (
                    <SelectItem value={sort.value} key={sort.value}>
                      {sort.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Button
                disabled={page === 1}
                onClick={() => setPage(1)}
                className={cn(
                  "h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                  page === 1 && "bg-[#333] text-white"
                )}
              >
                <p className="text-sm font-medium">1</p>
              </Button>
              <div
                onClick={() => {
                  if (page > 1) {
                    setPage((page) => page - 1);
                  }
                }}
                className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
              >
                <ArrowLeft className="h-4 w-4 text-[#333]" />
              </div>
              <div
                onClick={() => {
                  if (page !== freightList?.data?.total_pages) {
                    setPage((page) => page + 1);
                  }
                }}
                className="h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
              >
                <ArrowRight className="h-4 w-4 text-[#333]" />
              </div>
              <Button
                disabled={page === freightList?.data?.total_pages}
                onClick={() => setPage(freightList?.data?.total_pages)}
                className={cn(
                  "h-8 w-8 bg-white shadow rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                  page === freightList?.data?.total_pages &&
                    "bg-[#333] text-white"
                )}
              >
                {isLoading ? (
                  <p>
                    <Loader2 className="h-5 w-5 animate-spin" />
                  </p>
                ) : (
                  <p className="text-sm font-medium">
                    {freightList?.data?.total_pages}
                  </p>
                )}
              </Button>
            </div>
          )}
        </>
        {/* )} */}

        {isLoading ? (
          <div className="bg-white border border-border rounded-lg shadow-md p-4">
            <Table className="w-full">
              <TableHeader>
                <TableRow>
                  <TableHead></TableHead>
                  <TableHead>Freight/Date</TableHead>
                  <TableHead>Date Expires</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Skeleton className="h-20 w-20 rounded-lg bg-gray-200" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-28 rounded-lg bg-gray-200" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-28 rounded-lg bg-gray-200" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-36 rounded-lg bg-gray-200" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-40 rounded-lg bg-gray-200" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-5 w-10 rounded-lg bg-gray-200" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className="bg-white border border-border rounded-lg shadow-md p-4">
            <Table className="w-full">
              <TableHeader>
                <TableRow>
                  <TableHead>Details</TableHead>
                  <TableHead>Freight/Date</TableHead>
                  <TableHead>Date Expires</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Action</TableHead>
                </TableRow>
              </TableHeader>
              {freightList?.message === "No freight found." ? (
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      className="text-center py-4 text-sm font-medium text-gray-500"
                    >
                      {freightList?.message}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {freightList?.data?.freights.map((freight: FreightItem) => (
                    <CustomerFreightCard key={freight.id} freight={freight} />
                  ))}
                </TableBody>
              )}
            </Table>
          </div>
        )}
      </div>
      {modalCancelFreight.isOpen && <ModalCancelFreight refetch={refetch} />}
      {modalExtendFreight.isOpen && <ModalExtendFreight refetch={refetch} />}
    </SidebarLayout>
  );
};

export default CustomerFreight;
