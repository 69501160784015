import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { BASE_URL, GET_IMAGE } from "src/api/config";
import { cn } from "src/lib/utils";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { FreightItem } from "src/types/types";
import small from "../../assets/images/books-box.png";
import large from "../../assets/images/moving-box.png";
import medium from "../../assets/images/shoes-box.png";
import { TableCell, TableRow } from "../ui/table";
interface FreightAllCardProps {
  freight: FreightItem;
}

const FreigthAllCard: React.FC<FreightAllCardProps> = ({ freight }) => {
  const { user_id } = userInfoUserStore((state) => state);
  const navigate = useNavigate();

  const handleOnClickImage = () => {
    if (user_id) {
      navigate(`/freight/freightDetails/${freight.id}`);
    } else {
      navigate(`/freight/searchFreight/${freight.id}`);
    }
  };
  return (
    <TableRow className="border-b border-gray-200">
      <TableCell className="py-4 px-2">
        <div className="flex items-center space-x-2">
          {freight.animal_img ? (
            <img
              className="h-40 object-cover rounded-lg shadow-sm cursor-pointer"
              alt={freight.title}
              src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${freight.animal_img}&image_type=freight`}
              onClick={handleOnClickImage}
            />
          ) : (
            <div className="flex items-center justify-center w-40 h-40 rounded-lg shadow-sm">
              {freight.freight_type_info?.freight_size.id === 1 && (
                <img
                  src={small}
                  className="h-22 w-22 object-contain cursor-pointer"
                  alt="small package"
                  onClick={handleOnClickImage}
                />
              )}
              {freight.freight_type_info?.freight_size.id === 2 && (
                <img
                  src={medium}
                  className="h-22 w-22 object-contain cursor-pointer"
                  alt="medium package"
                  onClick={handleOnClickImage}
                />
              )}
              {freight.freight_type_info?.freight_size.id === 3 && (
                <img
                  src={large}
                  className="h-22 w-22 object-contain cursor-pointer"
                  alt="large package"
                  onClick={handleOnClickImage}
                />
              )}
            </div>
          )}
        </div>
      </TableCell>
      <TableCell className="py-4 px-2">
        <div>
          <h1
            onClick={() => {
              if (user_id) {
                navigate(`/freight/freightDetails/${freight.id}`);
              } else {
                navigate(`/freight/searchFreight/${freight.id}`);
              }
            }}
            className={cn(
              "text-sm font-medium text-freightBlue hover:underline cursor-pointer",
              user_id && "cursor-pointer"
            )}
          >
            {freight.title}
          </h1>
          <div className="flex items-center space-x-2 mt-1 text-sm text-gray-600">
            <p>{freight.start_date.slice(0, 10)}</p>
            <ChevronRight className="h-4 w-4 text-gray-400" />
            <p>{freight.end_date.slice(0, 10)}</p>
          </div>
        </div>
      </TableCell>
      <TableCell className="py-4 px-2">
        <div className="flex flex-col space-y-1">
          <div>
            <p className="text-xs font-medium text-gray-600">Origin:</p>
            <p className="text-sm font-medium text-gray-800">
              {freight.origin_address}
            </p>
          </div>
          <div>
            <p className="text-xs font-medium text-gray-600">Destination:</p>
            <p className="text-sm font-medium text-gray-800">
              {freight.destination_address}
            </p>
          </div>
        </div>
      </TableCell>
      <TableCell className="py-4 px-2 text-center">
        <p className="text-sm font-medium text-gray-700">
          {freight.delivery_duration}
        </p>
      </TableCell>
      <TableCell className="py-4 px-2 text-center">
        <p className="text-sm font-medium text-gray-700">
          {freight.delivery_distance}
        </p>
      </TableCell>
      <TableCell className="py-4 px-2 text-center">
        <p className="text-sm font-medium text-gray-700">
          {freight.created_at.slice(0, 10)}
        </p>
      </TableCell>
    </TableRow>
  );
};

export default FreigthAllCard;
