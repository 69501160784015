import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
//@ts-ignore

import step1 from "../../assets/images/step1.png";
//@ts-ignore
import shipmentDetails from "../../assets/images/shipmentDetails.png";
//@ts-ignore
import review from "../../assets/images/review.png";

const steps = [
  {
    label: "Select a date and location",
    description: (
      <>
        <img src={step1} alt="Image 1" />
        <br />
        Fill the form with your order details such as Origin location,
        destination and the date of pickup/offf. Dont forget to login first.
      </>
    ),
  },
  {
    label: "Add the shipment details",
    description: (
      <>
        <img src={shipmentDetails} alt="Image 2" />
        <br />
        Now add the properties of your package and select the package size so
        the transporter could know if he can pick it or not
      </>
    ),
  },
  {
    label: "Reviews",
    description: (
      <>
        <img src={review} alt="Image 3" />
        <br />
        After receiving their package, customers can provide feedback form
        covering delivery punctuality, package condition, and carrier
        professionalism. This feedback helps us identify areas for improvement
        and recognize strengths, ensuring continuous service optimization.
      </>
    ),
  },
];

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
