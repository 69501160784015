import SidebarLayout from "../components/layout/SidebarLayout";

import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { userInfoUserStore } from "../store/useUserInfoStore";

const Alerts = () => {
  const { user_type, user_id } = userInfoUserStore((state) => state);
  return (
    <SidebarLayout>
      <div className="max-w-4xl mr-auto">
        <h1 className="text-sm font-semibold border-b-2 pb-3">
          My Email Alerts
        </h1>
        <p>
          Note.To add email alerts simply click the "Save search as email alert"
          link above the results{" "}
          <span className="underline">searching for freight</span>
        </p>
        <div className="flex flex-col mt-10">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Categories</TableHead>
                <TableHead>Origin Location</TableHead>
                <TableHead>Destination Location</TableHead>
                <TableHead>Delete</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody></TableBody>
          </Table>
        </div>
      </div>
    </SidebarLayout>
  );
};

export default Alerts;
