import { create } from "zustand";
import { InstantFreight } from "../types/types";

export type ModalType = "editInstantService";

type Edit = "direction" | "package" | "pet" | null;

interface ModalStore {
  type: ModalType | null;
  data: InstantFreight | null;
  editType: Edit;
  isOpen: boolean;
  onOpen: (type: ModalType, data: InstantFreight, editType: Edit) => void;
  onClose: () => void;
}

export const useEditInstantService = create<ModalStore>((set) => ({
  type: null,
  data: null,
  editType: null,
  isOpen: false,
  onOpen: (type, data, editType) => set({ isOpen: true, type, data, editType }),
  onClose: () => set({ type: null, isOpen: false }),
}));
