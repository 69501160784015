import { useEffect, useState } from "react";
import Header from "../components/header/Header";
import useLocalStorage from "../hooks/use-local-storage";
import { BASE_URL, CUSTOMER_STOP } from "../api/config";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { Button } from "../components/ui/button";
import { useNavigate } from "react-router-dom";
import { cn } from "../lib/utils";

const formDeliverySchema = z.object({
  packageSize: z.string().min(1),
  price: z.string().min(1),
  packageWeight: z.string().min(1),
});

const PackageDeliverySelection = () => {
  const [packageDelivery, setPackageDelivery] =
    useLocalStorage("package_delivery");
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const [loading, setLoading] = useState(false);
  const [originZipcode, setOriginZipcode] = useState("");
  const [destinationZipcode, setDestinationZipcode] = useState("");
  const navigate = useNavigate();

  async function calculateRoute() {
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: {
        lat: packageDelivery.originLat,
        lng: packageDelivery.originLng,
      },
      destination: {
        lat: packageDelivery.destinationLat,
        lng: packageDelivery.destinationLng,
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    //@ts-ignore
    setDistance(results.routes[0].legs[0].distance.text);
    //@ts-ignore
    setDuration(results.routes[0].legs[0].duration.text);

    setOriginZipcode(
      //@ts-ignore
      `${results.routes[0].legs[0].start_address}`.match(/\b\d{5}\b/g)[0]
    );

    setDestinationZipcode(
      //@ts-ignore
      `${results.routes[0].legs[0].end_address}`.match(/\b\d{5}\b/g)[0]
    );
  }

  useEffect(() => {
    calculateRoute();
  }, []);

  const createStop = async (
    packageSize: string,
    packageWeight: string,
    price: string
  ) => {
    setLoading(true);
    const response = await fetch(`${BASE_URL}${CUSTOMER_STOP}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        origin_lat: packageDelivery.originLat,
        origin_lng: packageDelivery.originLng,
        destination_lat: packageDelivery.destinationLat,
        destination_lng: packageDelivery.destinationLng,
        package_size: packageSize,
        package_weight: packageWeight,
        price: price,
        delivery_distance: distance,
        delivery_duration: duration,
        origin_address: packageDelivery.originAddress,
        destination_address: packageDelivery.destinationAddress,
        origin_zipcode: originZipcode,
        destination_zipcode: destinationZipcode,
        origin_city: packageDelivery.origin,
        destination_city: packageDelivery.destination,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Customer stop created successfully") {
          navigate("/package-delivery/success");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

    return response;
  };

  const formDelivery = useForm<z.infer<typeof formDeliverySchema>>({
    resolver: zodResolver(formDeliverySchema),
    defaultValues: {
      packageSize: "",
      packageWeight: "",
      price: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formDeliverySchema>) => {
    createStop(values.packageSize, values.packageWeight, values.price);
  };

  return (
    <div className="bg-gray-50 h-screen">
      <Header />
      <div className="flex flex-col py-10 max-w-4xl mx-auto w-full h-[94vh] no-scrollbar">
        <div className=" w-full relative flex items-center justify-between ">
          <div className="w-full border-b h-1 absolute top-4 z-10" />
          {window.location.pathname === `/package-delivery` && (
            <div className="w-1/3 border-b h-1 absolute top-4 z-10 border-[#333]" />
          )}
          {window.location.pathname === `/package-delivery/selection` && (
            <div className="w-2/3 border-b h-1 absolute top-4 z-10 border-[#333]" />
          )}
          <div className="flex flex-col z-20">
            <div
              className={cn(
                "bg-gray-100 z-20 h-10 w-10 rounded-full text-center items-center justify-center flex",

                window.location.pathname === `/package-delivery` &&
                  "bg-[#333] text-white"
              )}
            >
              <p className="text-sm font-medium">1</p>
            </div>
            <p
              className={cn(
                "text-xs w-20 mt-2 font-medium text-gray-500",
                window.location.pathname === `/pakcage-delivery` &&
                  "text-[#333]"
              )}
            >
              Delivery Address
            </p>
          </div>
          <div className="flex flex-col z-20 items-center">
            <div
              className={cn(
                "bg-gray-100 z-20 h-10 w-10 rounded-full text-center items-center justify-center flex",
                window.location.pathname === `/package-delivery/selection` &&
                  "bg-[#333]"
              )}
            >
              <p
                className={cn(
                  "text-sm font-medium",

                  window.location.pathname === `/package-delivery/selection` &&
                    "text-white"
                )}
              >
                2
              </p>
            </div>
            <p
              className={cn(
                "text-xs text-center w-20 mt-2 font-medium text-gray-500",
                window.location.pathname === `/package-delivery/selection` &&
                  "text-[#333]"
              )}
            >
              Package Selection
            </p>
          </div>
          <div className="flex flex-col z-20 items-end  ">
            <div className="bg-gray-100 z-20 h-10 w-10 rounded-full text-center items-center justify-center flex">
              <p className="text-sm font-medium">3</p>
            </div>
            <p className="text-xs w-20 mt-2  justify-end items-end text-right font-medium text-gray-500">
              Submit
            </p>
          </div>
        </div>
        <h1 className="text-2xl font-medium text-gray-500 mt-10">
          Select Package
        </h1>
        <div className="bg-white shadow mt-10 p-4">
          <div className="w-full pt-10">
            <Form {...formDelivery}>
              <form
                className="flex flex-col w-full space-y-10"
                onSubmit={formDelivery.handleSubmit(onSubmit)}
              >
                <div className="sm:flex items-center justify-between">
                  <div className="flex flex-col items-start">
                    <h1 className="text-sm font-medium mb-2">Size</h1>
                    <Controller
                      control={formDelivery.control}
                      name="packageSize"
                      render={({ field }) => (
                        <Select
                          {...field}
                          onValueChange={(val) => {
                            formDelivery.setValue("packageSize", val);
                          }}
                        >
                          <SelectTrigger className="w-60">
                            <SelectValue placeholder="Size" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="small">Small</SelectItem>
                            <SelectItem value="medium">Medium</SelectItem>
                            <SelectItem value="large">Large</SelectItem>
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </div>

                  <FormField
                    control={formDelivery.control}
                    name="packageWeight"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Weight</FormLabel>
                        <div className="flex items-center space-x-2">
                          <FormControl>
                            <Input {...field} name="packageWeight" />
                          </FormControl>
                          <p className="text-sm font-medium">kg</p>
                        </div>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={formDelivery.control}
                    name="price"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Price</FormLabel>
                        <div className="flex items-center space-x-2">
                          <FormControl>
                            <Input {...field} name="price" />
                          </FormControl>
                          <p className="text-sm font-medium">€</p>
                        </div>
                      </FormItem>
                    )}
                  />
                </div>
                <Button variant={"freightBlue"} className="w-60 ml-auto ">
                  Create Stop
                </Button>
              </form>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageDeliverySelection;
