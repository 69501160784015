import { useEffect, useState } from "react";
import { BASE_URL, LIST_CUSTOMER_PAYMENTS, USER_EXISTS } from "../api/config";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { userStripeCustomerStore } from "../store/useStripeStore";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { useQuery } from "@tanstack/react-query";
import SidebarLayout from "../components/layout/SidebarLayout";
import { Button } from "../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { useAddPaymentMethod } from "../hooks/use-add-payment-method";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { MoreHorizontal } from "lucide-react";
import { useViewPayment } from "../hooks/use-view-payment";
import { Skeleton } from "../components/skeleton/Skeleton";
import { CustomerPayment } from "../types/types";

//@ts-ignore
import { ReactComponent as EmptySvg } from "src/assets/images/data-not-found.svg";

const CarrierHistoryPayments = () => {
  const { user_id, user_type, email } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const [listPayments, setListPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userNotRegistered, setUserNotRegistered] = useState(true);
  const [predCard, setPredCard] = useState();
  const modalPaymentMethod = useAddPaymentMethod();
  const modalViewPayment = useViewPayment();

  const [loadingPayments, setLoadingPayments] = useState(false);
  const addCustomerId = userStripeCustomerStore((state) => state.addCustomerId);
  const doesUserExist = async () => {
    setIsLoading(true);
    const response = await fetch(`${BASE_URL}${USER_EXISTS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        email: email,
        user_id: user_id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "User is not registered in stripe.") {
          setUserNotRegistered(true);
        } else {
          getListPayments(data.data[0][0].id);
          setPredCard(data.data[0][0].invoice_settings.default_payment_method);
          setUserNotRegistered(false);
          addCustomerId(data.data[0][0].id);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return response;
  };

  useEffect(() => {
    doesUserExist();
  }, []);

  const getListPayments = async (customerId: string) => {
    setLoadingPayments(true);
    const response = await fetch(
      `${BASE_URL}${LIST_CUSTOMER_PAYMENTS}?user_id=${user_id}&customer_id=${customerId}`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.data?.length > 0) {
          setListPayments(data?.data);
        }
      })
      .finally(() => {
        setLoadingPayments(false);
      });

    return response;
  };

  return (
    <SidebarLayout>
      <div className="flex items-center justify-between space-x-2 border-b border-border pb-3 mb-4">
        <h1 className="text-lg font-semibold">My Payments</h1>
      </div>
      <div className=" bg-white p-4 shadow rounded">
        {/* <TableHeader>
          <TableRow>
            <TableHead>Card</TableHead>
            <TableHead>Default</TableHead>
            <TableHead>Amount</TableHead>
            <TableHead>Date Created</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader> */}
        {userNotRegistered ? (
          <div className="flex flex-col items-center w-full mx-auto space-y-4 justify-center py-3">
            {isLoading ? (
              <Skeleton className="h-6 w-40 mt-4" />
            ) : (
              <div className="flex flex-col items-center">
                <EmptySvg />

                <p className="text-sm font-medium text-gray-500 mt-4">
                  <>
                    {user_type === "customer" ? (
                      <span>You have not made any payments.</span>
                    ) : (
                      <span>You have not received any payments.</span>
                    )}
                  </>
                </p>
              </div>
            )}
          </div>
        ) : (
          <>
            {/* <Table> */}
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Card</TableHead>
                  <TableHead>Default</TableHead>
                  <TableHead>Amount</TableHead>
                  <TableHead>Date Created</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell>
                      <Skeleton className="h-3 w-24 rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="h-3 w-24 rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="h-3 w-24 rounded" />
                    </TableCell>
                    <TableCell>
                      <Skeleton className="h-3 w-3 rounded" />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {/* {loadingPayments ? (
                      <div className="flex items-center justify-between">
                        <Skeleton className="h-4 w-20 mt-4" />
                        <Skeleton className="h-4 w-20 mt-4" />
                        <Skeleton className="h-4 w-28 mt-4" />
                        <Skeleton className="h-4 w-4 mt-4 rounded-full" />
                      </div>
                    ) : ( */}
                    <>
                      {!loadingPayments && listPayments?.length === 0 ? (
                        <h1 className="p-4 text-sm font-medium text-gray-400">
                          No receipts found
                        </h1>
                      ) : (
                        <>
                          {listPayments
                            ?.filter(
                              (payment: { status: string }) =>
                                payment.status !== "requires_payment_method"
                            )
                            .map((payment: CustomerPayment) => (
                              <TableRow>
                                <TableCell>
                                  <div className="flex items-center space-x-2">
                                    <img
                                      src={require("../assets/visa.png")}
                                      className="h-10 w-10 object-contain"
                                      alt={`visa-${payment.client_secret}`}
                                    />
                                    {payment.charges.data.map((charge) => (
                                      <p className="font-semibold text-sm">
                                        {charge.payment_method_details.card.brand
                                          .slice(0, 1)
                                          .toUpperCase() +
                                          charge.payment_method_details.card.brand.slice(
                                            1
                                          )}
                                      </p>
                                    ))}
                                    <p className="font-semibold text-sm">
                                      ****
                                    </p>
                                    {payment.charges.data.map((charge) => (
                                      <p className="text-sm font-semibold">
                                        {
                                          charge.payment_method_details.card
                                            .last4
                                        }
                                      </p>
                                    ))}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  {predCard === payment.payment_method && (
                                    <div className="bg-gray-100 text-gray-400 font-medium px-4">
                                      <p className="text-center">Default</p>
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell>{payment.amount}€</TableCell>

                                <TableCell>
                                  {new Date(
                                    payment?.created * 1000
                                  ).toDateString()}
                                </TableCell>
                                {/* <TableCell> */}
                                <div className="hover:bg-gray-100 h-7 w-7 rounded-full  cursor-pointer flex items-center justify-center transition ">
                                  <DropdownMenu>
                                    <DropdownMenuTrigger>
                                      <MoreHorizontal className="h-5 w-5 text-gray-600" />
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                      <DropdownMenuLabel
                                        onClick={() =>
                                          modalViewPayment.onOpen(
                                            "viewPayment",
                                            payment
                                          )
                                        }
                                      >
                                        View Details
                                      </DropdownMenuLabel>
                                      <DropdownMenuSeparator />
                                      <DropdownMenuLabel>
                                        Cancel
                                      </DropdownMenuLabel>
                                    </DropdownMenuContent>
                                  </DropdownMenu>
                                </div>
                                {/* </TableCell> */}
                              </TableRow>
                            ))}
                        </>
                      )}
                    </>
                  </>
                )}
              </TableBody>
            </Table>
          </>
        )}
      </div>
    </SidebarLayout>
  );
};

export default CarrierHistoryPayments;
