import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";

import { Button } from "../ui/button";
import { BASE_URL, DRIVERS_DELETE } from "src/api/config";
import toast from "react-hot-toast";
import { useDeleteDrivers } from "src/hooks/use-delete-driver";
import { AlertCircle } from "lucide-react";

interface ModalDeleteDriversProps {
  refetchDrivers: () => void;
}

const ModalDeleteDrivers: React.FC<ModalDeleteDriversProps> = ({
  refetchDrivers,
}) => {
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const modalDeleteDrivers = useDeleteDrivers();

  const deleteDriverData = new FormData();

  deleteDriverData.append("user_id", user_id);
  deleteDriverData.append("driver_id", modalDeleteDrivers.driverId);

  deleteDriverData.append("status", "0");
  deleteDriverData.append("is_deleted", "1");

  const deleteDriver = async () => {
    const response = await fetch(`${BASE_URL}${DRIVERS_DELETE}`, {
      method: "POST",
      headers: {
        AccessToken: token,
      },
      body: deleteDriverData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Driver deleted successfully.") {
          toast.success(data.message);
          modalDeleteDrivers.onClose();
          refetchDrivers();
        }
      });
    return response;
  };

  return (
    <Dialog
      open={modalDeleteDrivers.isOpen}
      onOpenChange={modalDeleteDrivers.onClose}
    >
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-start space-y-4  w-full">
              <div className="flex flex-col items-center w-full">
                <AlertCircle className="h-28 w-28 text-red-400" />
                <h1 className="text-xl font-medium">Are you sure?</h1>
                <h3 className="text-sm">You want to delete driver</h3>
              </div>
              <div className="flex items-center justify-end space-x-2 w-full ">
                <Button
                  onClick={() => modalDeleteDrivers.onClose()}
                  variant={"outline"}
                >
                  Cancel
                </Button>
                <Button onClick={() => deleteDriver()} variant={"freightBlue"}>
                  Ok
                </Button>
              </div>
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalDeleteDrivers;
