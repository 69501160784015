import { create } from "zustand";

export type ModalType = "deleteInstantService";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  instantServiceId: string;
  onOpen: (type: ModalType, instantServiceId: string) => void;
  onClose: () => void;
}

export const useDeleteInstantService = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  instantServiceId: "",
  onOpen: (type, instantServiceId) =>
    set({ isOpen: true, type, instantServiceId }),
  onClose: () => set({ type: null, isOpen: false }),
}));
