export const listProvinces = [{ id: 1, province_name: "Álava", province_short_name: "VIT" },
{ id: 2, province_name: "Albacete", province_short_name: "AB " },
{ id: 3, province_name: "Alicante", province_short_name: "ALC" },
{ id: 4, province_name: "Almería", province_short_name: "LEI" },
{ id: 5, province_name: "Asturias", province_short_name: "OVD" },
{ id: 6, province_name: "Ávila", province_short_name: "AVI" },
{ id: 7, province_name: "Badajoz", province_short_name: "BJZ" },
{ id: 8, province_name: "Barcelona", province_short_name: "BCN" },
{ id: 9, province_name: "Burgos", province_short_name: "RGS" },
{ id: 10, province_name: "Cáceres", province_short_name: "CQR" },
{ id: 11, province_name: "Cádiz", province_short_name: "XRY" },
{ id: 12, province_name: "Cantabria", province_short_name: "SDR" },
{ id: 13, province_name: "Castellón", province_short_name: "CDT" },
{ id: 14, province_name: "Ciudad Real", province_short_name: "CQM" },
{ id: 15, province_name: "Córdoba", province_short_name: "ODB" },
{ id: 16, province_name: "La Coruña", province_short_name: "LCG" },
{ id: 17, province_name: "Cuenca", province_short_name: "CEJ" },
{ id: 18, province_name: "Gerona", province_short_name: "GRO" },
{ id: 19, province_name: "Granada", province_short_name: "GRX" },
{ id: 20, province_name: "Guadalajara", province_short_name: "GDU" },
{ id: 21, province_name: "Guipúzcoa", province_short_name: "EAS" },
{ id: 22, province_name: "Huelva", province_short_name: "HEV" },
{ id: 23, province_name: "Huesca", province_short_name: "HSK" },
{ id: 24, province_name: "Islas Baleares", province_short_name: "PMI" },
{ id: 25, province_name: "Jaén", province_short_name: "JEA" },
{ id: 26, province_name: "León", province_short_name: "LEN" },
{ id: 27, province_name: "Lérida", province_short_name: "ILD" },
{ id: 28, province_name: "Lugo", province_short_name: "LCG" },
{ id: 29, province_name: "Madrid", province_short_name: "MAD" },
{ id: 30, province_name: "Málaga", province_short_name: "AGP" },
{ id: 31, province_name: "Murcia", province_short_name: "MJV" },
{ id: 32, province_name: "Navarra", province_short_name: "PNA" },
{ id: 33, province_name: "Orense", province_short_name: "ODB" },
{ id: 34, province_name: "Palencia", province_short_name: "PLC" },
{ id: 35, province_name: "Las Palmas", province_short_name: "LPA" },
{ id: 36, province_name: "Pontevedra", province_short_name: "PTE" },
{ id: 37, province_name: "La Rioja", province_short_name: "	RJA" },
{ id: 38, province_name: "Salamanca", province_short_name: "SLM" },
{ id: 39, province_name: "Segovia", province_short_name: "SGJ" },
{ id: 40, province_name: "Sevilla", province_short_name: "SVQ " },
{ id: 41, province_name: "Soria", province_short_name: "XJO" },
{ id: 42, province_name: "Tarragona", province_short_name: "REU" },
{ id: 43, province_name: "Santa Cruz de Tenerife", province_short_name: "TCI" },
{ id: 44, province_name: "Teruel", province_short_name: "TEV" },
{ id: 45, province_name: "Toledo", province_short_name: "TOL" },
{ id: 46, province_name: "Valencia", province_short_name: "VLC" },
{ id: 47, province_name: "Valladolid", province_short_name: "VLL" },
{ id: 48, province_name: "Vizcaya", province_short_name: "BIO" },
{ id: 49, province_name: "Zamora", province_short_name: "ZAM" },
{ id: 50, province_name: "Zaragoza", province_short_name: "	ZAZ " },
{ id: 51, province_name: "Ceuta", province_short_name: " CEU" },
{ id: 52, province_name: "Melilla", province_short_name: " MEL" }]