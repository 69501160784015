import { useQuery } from "@tanstack/react-query";
import { BASE_URL, BOOKING_FREIGHT_STATISTICS } from "../api/config";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { useEffect, useState } from "react";
import Header from "../components/header/Header";
import CustomerSidebar from "../components/sidebar/CustomerSidebar";
import Sidebar from "../components/sidebar/Sidebar";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "../components/ui/form";
import { CalendarDays } from "lucide-react";
import { formatDate } from "../lib/utils";
import { Button } from "../components/ui/button";
import { Calendar } from "../components/ui/calendar";
import SidebarLayout from "../components/layout/SidebarLayout";

const customerStatistics = z.object({
  fromDate: z.date(),
  toDate: z.date(),
});

const CustomerStatistics = () => {
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const [showFormDate, setShowFormDate] = useState(false);
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [showToDate, setShowToDate] = useState(false);
  const [toDate, setToDate] = useState<Date>(new Date());

  const customerStatisticsDetails = useForm<z.infer<typeof customerStatistics>>(
    {
      resolver: zodResolver(customerStatistics),
      defaultValues: {
        fromDate: new Date(),
        toDate: new Date(),
      },
    }
  );
  const getCustomerStatistics = async () => {
    const response = await fetch(`${BASE_URL}${BOOKING_FREIGHT_STATISTICS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id,
        from_date: "2024-01-01",
        to_date: "2024-02-01",
      }),
    }).then((res) => res.json());

    return response;
  };

  const { data: customerFreightStatisticss } = useQuery({
    queryKey: ["customer_statistics"],
    queryFn: getCustomerStatistics,
  });

  const onSubmit = (values: z.infer<typeof customerStatistics>) => {};
  return (
    <SidebarLayout>
      <div className="flex items-center justify-between w-full border-border border-b pb-3 space-x-2 mb-4">
        <h1 className="text-lg font-semibold">Statistics</h1>
      </div>
      <div className=" bg-white shadow mb-4 p-4">
        <div className="sm:flex h-32 items-center space-x-2 border-b  mb-4">
          <Button className="bg-freightOrange mb-2 mr-2 text-white rounded-t">
            <h1>Quotes Requested</h1>
          </Button>
          <Button className="bg-freightBlue  mb-2 mr-2 text-white rounded-t">
            <h1>Quotes</h1>
          </Button>
          <Button className="bg-freightBlue mb-2 mr-2 text-white rounded-t">
            <h1>Amount Spent</h1>
          </Button>
        </div>
        <div className="flex flex-wrap items-center sm:space-x-2 gap-4 border-b mb-4">
          <Button className="bg-freightBlue px-6 py-2 text-white rounded-t">
            <h1>1D</h1>
          </Button>
          <Button className="bg-freightBlue px-6 py-2 text-white rounded-t">
            <h1>1W</h1>
          </Button>
          <Button className="bg-freightBlue px-6 py-2 text-white rounded-t">
            <h1>1M</h1>
          </Button>
          <Button className="bg-freightBlue px-6 py-2 text-white rounded-t">
            <h1>1Y</h1>
          </Button>
        </div>
        <div className="flex items-center space-x-2 border-b mb-4">
          <Form {...customerStatisticsDetails}>
            <form onSubmit={customerStatisticsDetails.handleSubmit(onSubmit)}>
              <div className="sm:flex items-center sm:space-x-4">
                <div className="relative flex items-center space-x-4">
                  <div
                    onClick={() => setShowFormDate(!showFormDate)}
                    className="border border-border w-60 flex items-center rounded py-2 px-2 justify-between"
                  >
                    <h3>{formatDate(fromDate)}</h3>
                    <CalendarDays />
                  </div>

                  {showFormDate && (
                    <Controller
                      name="fromDate"
                      control={customerStatisticsDetails.control}
                      render={({ field }) => (
                        <div className="absolute top-10 bg-white shadow">
                          <Calendar
                            {...field}
                            mode="single"
                            selected={fromDate}
                            onSelect={(val: any) => {
                              customerStatisticsDetails.setValue(
                                "fromDate",
                                val
                              );
                              setFromDate(val);
                              setShowFormDate(false);
                            }}
                            className="rounded border bg-white border-border"
                          />
                        </div>
                      )}
                    />
                  )}
                </div>
                <div className="relative flex items-center space-x-4">
                  <div
                    className="border border-border w-60 py-2 px-2 rounded flex items-center justify-between"
                    onClick={() => setShowToDate(!showToDate)}
                  >
                    <h3>{formatDate(toDate)}</h3>

                    <CalendarDays />
                  </div>
                  {showToDate && (
                    <Controller
                      control={customerStatisticsDetails.control}
                      name="toDate"
                      render={({ field }) => (
                        <div className="absolute top-10 bg-white shadow">
                          <Calendar
                            {...field}
                            mode="single"
                            selected={toDate}
                            onSelect={(val: any) => {
                              customerStatisticsDetails.setValue("toDate", val);
                              setToDate(val);
                              setShowToDate(false);
                            }}
                            className="rounded border bg-white border-border"
                          />
                        </div>
                      )}
                    />
                  )}
                </div>
                <Button>Submit</Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </SidebarLayout>
    //   </div>
    // </div>
  );
};

export default CustomerStatistics;
