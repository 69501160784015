import { FormDataFreights } from "@/src/types/types";
import { Loader2 } from "lucide-react";
import React, { useState } from "react";
import { GOOGLE_API_KEY } from "../../../api/config";
import small from "../../../assets/images/books-box.png";
import large from "../../../assets/images/moving-box.png";
import medium from "../../../assets/images/shoes-box.png";

interface FreightSummaryProps {
  formData: FormDataFreights;
  onCreateFreight: () => Promise<void>;
  sizes: any;
  onEdit: () => void;
}

const FreightSummary: React.FC<FreightSummaryProps> = ({
  formData,
  onCreateFreight,
  sizes,
  onEdit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const getImage = () => {
    switch (formData.size_id) {
      case 1:
        return small;
      case 2:
        return medium;
      case 3:
        return large;
      default:
        return small;
    }
  };

  const handleCreateFreight = async () => {
    setIsLoading(true);
    try {
      await onCreateFreight();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto  relative ">
      <h2 className="text-xl font-semibold pb-4">Freight Summary</h2>
      <div className="bg-white border shadow-lg rounded-lg overflow-hidden">
        <div className="flex items-start p-6">
          <div className="w-1/2">
            <div className="relative mb-4">
              <img
                src={getImage()}
                alt="Freight Size"
                className="w-full h-48 object-contain rounded-lg shadow-md"
              />
              <div className="absolute top-4 left-4 bg-white bg-opacity-75 px-3 py-2 rounded-md shadow-md">
                <p className="font-semibold text-gray-700 capitalize">
                  {sizes[formData.size_id! - 1].name}
                </p>
                <p className="text-gray-600">
                  {sizes[formData.size_id! - 1].length} cm x{" "}
                  {sizes[formData.size_id! - 1].width} cm x{" "}
                  {sizes[formData.size_id! - 1].height} cm
                </p>
                <p className="text-gray-600">
                  {sizes[formData.size_id! - 1].weight} kg
                </p>
              </div>
            </div>
            <p className="text-gray-700 mb-6">
              <strong>Description:</strong> {formData.description}
            </p>
            <div className="bg-gray-50 p-4 rounded-lg shadow-md mb-4">
              <div className="border-b pb-3 mb-3">
                <p className="text-gray-700 font-semibold">From:</p>
                <p className="text-gray-600">{formData.origin_address}</p>
                <p className="text-sm text-gray-500">{formData.start_date}</p>
              </div>
              <div>
                <p className="text-gray-700 font-semibold">To:</p>
                <p className="text-gray-600">{formData.destination_address}</p>
                <p className="text-sm text-gray-500">{formData.end_date}</p>
              </div>
            </div>
          </div>
          <div className="w-1/2 h-96 ml-10">
            <div className="w-full h-full rounded-lg shadow-md overflow-hidden">
              <Map
                origin={{
                  lat: formData.origin_lat!,
                  lng: formData.origin_lng!,
                }}
                destination={{
                  lat: formData.destination_lat!,
                  lng: formData.destination_lng!,
                }}
              />
            </div>
            <div className="mt-2 pt-2 border-t">
              <p>
                <strong>Delivery Distance:</strong> Aprox.{" "}
                {formData.delivery_distance}
              </p>
              <p>
                <strong>Delivery Duration:</strong> Aprox.{" "}
                {formData.delivery_duration}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-4 mt-4">
        <button
          onClick={onEdit}
          className="p-2 bg-gray-400 text-white rounded-md"
        >
          Edit
        </button>
        <button
          onClick={handleCreateFreight}
          className="p-2 w-[150px] bg-[#1889ca] text-white rounded-md flex items-center justify-center"
          disabled={isLoading} // Desactivar el botón mientras carga
        >
          {isLoading ? <Loader2 className="animate-spin" /> : "Create Freight"}
        </button>
      </div>
    </div>
  );
};

const Map: React.FC<{
  origin: { lat: number; lng: number };
  destination: { lat: number; lng: number };
}> = ({ origin, destination }) => {
  const googleMapsApiKey = GOOGLE_API_KEY; // Reemplaza con tu API key

  return (
    <div className="w-full h-full flex justify-center items-center">
      <iframe
        className="w-full h-full max-w-2xl rounded-md"
        frameBorder="0"
        src={`https://www.google.com/maps/embed/v1/directions?key=${googleMapsApiKey}&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}`}
        allowFullScreen
        title="map"
      ></iframe>
    </div>
  );
};

export default FreightSummary;
