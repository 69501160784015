export let BASE_URL = `http://192.168.1.170:8080/api/`
export let BASE_IMG_URL = ``
let prod = 1
export let BASE_URL_IMG_2 = "http://192.168.1.170:8080/storage/app/public/pet_images/"
if (prod === 0) {
    BASE_URL = `http://192.168.1.170:8080/api/`
    BASE_IMG_URL = `http://192.168.1.170:8080/storage/app/public/category_images/`
    // export const BASE_URL_IMG_2 = "http://192.168.1.170:8080/storage/pet_images/"
    BASE_URL_IMG_2 = "http://192.168.1.170:8080/storage/app/public/pet_images/"

} else if (prod === 1) {
    BASE_URL = `https://api.runnme.com/api/`
    BASE_IMG_URL = `https://api.runnme.com/storage/category_images/`
    BASE_URL_IMG_2 = "https://api.runnme.com/storage/app/public/pet_images/"
} else if (prod === 2) {
    BASE_URL = `https://apitest.runnme.com/api/`
    BASE_IMG_URL = `https://apitest.runnme.com/storage/category_images/`
    BASE_URL_IMG_2 = "https://apitest.runnme.com/storage/app/public/pet_images/"
}

export const CUSTOMER_LOGIN_URL = "login";
export const VERIFY_EMAIL = "checkUsernameEmail"
export const VERIFY_PHONENUMBER = "checkPhoneNumber"
export const LOGIN_CHECK = "loginCheck"
export const REGISTER_DEVICE = "registerDevice"
export const REGISTER_USER = "registerUser"
export const LOGOUT_USER = "logout"
export const TRANSPORTER_FREIGHT_LISTING = "freight/list";
export const CHANGE_PASSWORD_URL = "changePassword"
export const FORGOT_PASSWORD_EMAIL_URL = "forgotPassword"
export const FREIGHTADD = "freight/add";
export const EXPIRE_DAYS_URL = "expireDays"
export const VERIFY_OTP = "otpverify"
export const LOCATION_TYPE = "getLocationType"
export const CANCEL_FREIGHT = "freight/cancel"
export const MARKALL_NOTIFICATIONS_AS_READ = "markallRead";
export const GET_NOTIFICATION = "getNotifications"
export const contactSubject = "contactSubmit";
export const GETSUBJECTS = "getSubject";
export const GET_COUNT = "getCounts"
export const CHANGE_PASSWORD = "changePassword"
export const CUSTOMER_GETPROFILE_URL = "getProfile";
export const UPDATE_PROFILE = "updateProfile"
export const GETCARRIERACCOUNT = "getCarrierAccount";
export const GETCARRIERPROFILE = "getCarrierProfile";
export const LIST_TICKETS = "ticket/list"
export const GET_REVIEW = "booking/getReviews";
export const GETYEARS = "getYears";
export const TRANSPORTERMYQUOTE = "booking/myQuote";
export const CUSTOMERBOOKINGSLIST = "booking/customer";
export const EDITFREIGHT = "freight/freightdetailEdit"
export const BOOKINGBYID = "booking/search";

// export const GOOGLE_API_KEY = "AIzaSyBVVB1wKD3PcRZwVbZo2ZqyXPrw6wq2lrI";
// export const GOOGLE_API_KEY = "AIzaSyAC0EZ6WZ2qhpxJr8jl9ugX6-lluarwH1M";
export const GOOGLE_API_KEY = "AIzaSyCZrRxNGqvDU6bMRiot76YQKyDcERCoqDA";

export const TRANSPORTERBOOKINGSLIST = "booking/transporters";
export const LOGOUTURL = "logout";
export const GETCATEGORY_SUBCATEGORY = "freight/getAllCategories";
export const GET_INBOX = "myInbox"
export const FAQ = "faqs";
export const UPLOAD_IMAGE = "freight/uploadImage"
export const FREIGHT_LIST = "freight/list"
export const FREIGHT_DETAIL = "freight/details"

export const GET_ALL_FREIGHT = "freight/getAllFreight"

export const GET_ALL_INSTANT = "freight/listAllInstant"
export const INSTANT_FREIGHT_DETAILS = "freight/instant-details"
export const GET_ALL_CATEGORIES = "freight/getAllCategories"
export const GET_ALL_SIZES = "freight/getAllSizes"


export const ADD_QUOTE_QUESTION = "freight/addQuestion";


export const VEHICLE_TYPE = "quote/vehicleType"
export const ADD_FREIGHT = "quote/add"

export const REVISEQUOTES = "quote/edit"
export const TRANSPORTERCANCELQUOTE = "freight/cancelQuote";

export const FREIGHT_QUESTIONS = "freight/freighQueAns"
export const QUOTE_LIST = "quote/list"

export const FREIGHT_QUOTELIST = "freight/quotelist"

export const BOOKING_STATISTICS = "booking/myBookingStatics"
export const BOOKING_VS_QUOTES = "booking/quoteVsBookings"
export const BOOKING_VS_CANCEL = "booking/myBookingVsCancel"
export const SALES_FREIGHT = "booking/salesFreight"


export let BASE_URL_IMG = "http://192.168.1.170:8080"

if (prod === 0) {
    BASE_URL_IMG = `http://192.168.1.42:8080`
} else if (prod === 1) {
    BASE_URL_IMG = `https://api.runnme.com`
} else if (prod === 2) {
    BASE_URL_IMG = `https://apitest.runnme.com`
}

export const PROD_URL_IMG = "https://freightseek.com.au/"
export const PAYMENT_INTENT = "createPaymentIntent"

export const CREATE_CUSTOMER = "createCustomer"
export const CONFIRM_PAYMENT_INTENT = "confirmPaymentIntent"


export const DECLINE_QUOTE = "freight/declineQuote"

export const USER_EXISTS = "checkUserExists"

export const PAYMENT_METHOD = "createPaymentMethod"

export const FREIGHT_EXTEND = "freight/extend"

export const BOOKING_ACCEPT = "booking/accept"

export const TICKET_CATEGORY = "ticket/category"

export const BOOKING_CANCEL_QUOTE = "booking/cancelBookingQuote"

export const BOOKING_TSTATUS = "booking/myBookingTStatus"

export const BOOKING_MYQUOTE = "booking/myQuote"

export const BOOKING_FREIGHT_STATISTICS = "booking/myFreightStatistics"

export const CUSTOMER_PAYMENT_METHODS = "getCustomerPaymentMethods"

export const JOB_COMPLETE = "booking/jobComplete"

export const BOOKING_REVIEWS = "booking/getReviews"

export const CANCEL_CONFIRM_BOOKING = "booking/cancelConfirmBooking"

export const RECENT_BOOKING_QUOTES = "booking/myRecentBookingQuotes"

export const FLEET_ALL = "fleet/all"

export const FLEET_ADD = "fleet/add"

export const FLEET_DRIVERS = "fleet/drivers"

export const ATTACH_PAYMENT_METHOD = "attachUserPaymentMethod"

export const LIST_CUSTOMER_PAYMENTS = "listCustomerPayments"

export const MESSAGE_INBOX = "message"

export const VIEW_CARRIER_PROFILE = "viewCarrierProfile"
export const SUBMIT_REVIEW = "booking/submitReview"


export const DRIVERS_ADD = "driver/add"
export const DRIVERS_UPDATE = "driver/update"

export const DRIVERS_LIST = "driver/list"


export const DRIVERS_DELETE = "driver/delete"

export const READ_MESSAGE = "readMessage"
export const UPDATE_STATUS_MESSAGES = "updateStatusMessages"

export const DELETE_MESSAGE = "deleteMessages"


export const CONFIRM_OTP = "freight/confirmOtp"
export const EMAIL_OTP = "freight/emailOtp"

export const RESEND_OTP = "resendOtpVerify"

export const REGISTER_BOOKING_PAYMENT = "registerBookingPayment"

export const REGISTER_FREIGHT_PAYMENT = "registerFreightPayment"

export const LIST_ALL_PAYMENTS = "listAllPayments"

export const SUBCATEGORIES = "getSubCategories"

export const CALCULATE_QUOTE = "freight/calculateQuote"
export const CALCULATE_QUOTE_WITH_PET = "freight/calculatePetDeliveryQuote"
export const REGISTER_CARRIER = "registerCarrier"

export const UPDATE_CARRIER_PROFILE = "updateUserCarrierAccount"

export const CREATE_INSTANT_FREIGHT = "freight/createNewInstantFreight"

export const INSTANT_FREIGHTS = "freight/listInstantFreightsByCustomerId"
export const INSTANT_FREIGHTS_CARRIER = "freight/listInstantFreightsByCarrierId"

export const FINISH_INSTANT_FREIGHT = "freight/finishInstantFreight"


export const MARK_INSTANT_AS_PAID = "freight/markInstantFreightAsPaid"

export const MARK_INSTANT_AS_PICKING_UP = "freight/markInstantFreightAsPickingUp"

export const MARK_INSTANT_IN_TRANSIT = "freight/markInstantFreightAsInTransit"

export const REGISTER_INSTANT_FREIGHT_PAYMENT = "registerInstantFreightPayment"

export const DELETE_ACCOUNT = "delete-user-account"

export const ARCHIVE_MESSAGE = "archiveMessage"


export const GET_CHATS = "getChats"

export const GET_CHATS_USER = "getChatsByUserId"

export const SAVE_CHATS = "saveChats"

export const GET_CHATS_QUOTE = "getChatsByQuoteId"

export const UPDATE_USER_PROFILE_IMAGE = "updateUserProfileImage"

export const GET_CONVERSATIONS = "getChatLastMessage"

export const BOOKING_QUOTE_CUSTOMER = "booking/myQuoteCustomer"

export const AVAILABLE_PACKAGES = "getAvailablePackages"

export const ASSIGN_CARRIER = "freight/assignCarrierToInstantFreight"

export const GET_AVAILABLE_VEHICLES = "freight/getAvailableVehice"

export const PRE_LOGIN = "prelogin"

export const INSTANT_FREIGHT_CANCEL = "freight/markInstantFreightAsCancelled"

export const INSTANT_FREIGHT_REVIEWS = "freight/getReviewByInstantFreightId"

export const REVIEW_INSTANT_FREIGHT = "freight/submitReviewInstantFreight"

export const PAYMENT_INTENT_INSTANT = "createPaymentIntentInstant"

export const GET_IMAGE = "images"

export const UPDATE_CARRIER_DOCS = "updateCarrierDocs"

export const TRANSPORTER_ROUTE = "route/create"

export const ALL_ROUTES = "route/all"

export const ROUTE_REMOVE = "route/remove"

export const UPDATE_STOP_STATUS = "stop/status"

export const CUSTOMER_STOP = "stop/create"

export const GET_CUSTOMER_STOPS = "stop/getStopByUserId"

export const GET_SINGLE_STOP = "stop/getStopById"

export const STOP_REMOVE = "stop/remove"

export const STOP_UPDATE = "stop/update"

export const ASSIGN_STOP_TO_ROUTE = "stop/assignRoute"

export const GET_STOPS_BY_ROUTE = "stop/getStopByRouteId"

export const TRANSPORTER_CREATE_STOP = "stop/create"

export const BOOK_STOP = "stop/book"

export const BOOK_CONFIRM_OTP = "stop/confirmOtp"

export const REGISTER_STOP_PAYMENT = "registerStopPayment"

export const ROUTE_UPDATE = "route/update"
export const ROUTE_STATUS = "route/status"

export const SUBMIT_STOP_REVIEW = "stop/submitStopReview"

export const GET_STOP_REVIEW = "stop/getStopReviewByStopId"

export const DELETE_INSTANT_FREIGHT = "freight/deleteInstantFreight"

export const UPDATE_INSTANT_FREIGHT = "freight/updateInstantFreight"

export const GET_CHATS_CARRIER = "getChats"

export const GET_USER_DATA = "getUserData"

export const UPDATE_USER_DATA = "updateUserData"

export const ALL_B2B_TRIPS = "orders/list-orders"

export const ORDERS_UPDATE = "orders/update-state"

export const ORDERS_TRANSPORTER = "orders/orders-by-transporter"

export const ORDERS_COMPLETED_TRANSPORTER = "orders/orders-completed-by-transporter"
export const ORDERS_DETAILS = "orders/order-details"

export const ALL_CITIES = "allCities"



export const CARBON_FOOTPRINT = "carbonPrint"