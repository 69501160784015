import React, { useState } from "react";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import Header from "../header/Header";
import CustomerSidebar from "../sidebar/CustomerSidebar";
import Sidebar from "../sidebar/Sidebar";
import Footer from "./Footer";
import { ButtonBase } from "@mui/material";
import { Button } from "../ui/button";
import { IoCloseCircle } from "react-icons/io5";
import { LuGrip } from "react-icons/lu";

interface SidebarLayoutProps {
  children: React.ReactNode;
}

const SidebarLayout: React.FC<SidebarLayoutProps> = ({ children }) => {
  const { user_type } = userInfoUserStore((state) => state);
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const closeSidebar = () => {
    setShowSidebar(false);
  };

  return (
    <div className="">
      <Header />
      <div className="md:flex md:flex-row  items-start bg-gray-50 lg:flex-row  space-y-4 lg:space-y-0 h-[94vh] overflow-hidden   ">
        <div
          className={` md:block ${showSidebar ? "block" : "hidden"}`}
        >
          <button
            className="md:hidden pl-4 pt-4 pb-4 flex items-center max-w-72"
            onClick={closeSidebar}
          >
            <IoCloseCircle size={25} color="red" />
            <p className="ml-2">Close</p>
          </button>
          {user_type === "customer" ? <CustomerSidebar /> : <Sidebar />}
        </div>
        <div className="md:block pb-10 no-scrollbar pt-4 sm:px-10 px-4 h-[94vh] overflow-y-auto flex-grow">
          <button
            className="md:hidden flex items-center -ml-4 -mt-6 pb-4 max-w-72"
            onClick={toggleSidebar}
          >
            {showSidebar ? "" : <LuGrip color="blue" size={30} />}
            <span className="ml-2">Menu</span>
          </button>
          {children}
        </div>
      </div>
    </div>
  );
};

export default SidebarLayout;
