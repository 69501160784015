import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FcExpand } from "react-icons/fc";

export default function FrequentQuestions() {
  return (
    <div className="mb-8">
      <Accordion>
        <AccordionSummary
          expandIcon={<FcExpand />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          HOW DO I BECOME A TRANSPORTER?
        </AccordionSummary>
        <AccordionDetails>
          By just going to the sign up button at the top left of the navbar
          selecting "Transporter Signup".
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<FcExpand />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          WHAT IS A COMMISSION?{" "}
        </AccordionSummary>
        <AccordionDetails>
          Is the percentage of the total prize that the transporter will
          recieve.
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<FcExpand />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          CAN I CANCEL A ORDER?{" "}
        </AccordionSummary>
        <AccordionDetails>
          To make changes, cancels or any specific aditional information you
          would have access to the live chat with the customer and talk to solve
          directly the problem
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
