import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, GET_IMAGE, TRANSPORTERMYQUOTE } from "../api/config";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { BookingQuote } from "../types/types";

import {
  ArrowLeft,
  Ban,
  Mail,
  MapPin,
  Package,
  Pencil,
  Phone,
  Settings,
} from "lucide-react";
import SidebarLayout from "../components/layout/SidebarLayout";
import ModalCancelQuote from "../components/modal/ModalCancelQuote";
import ModalSendMessage from "../components/modal/ModalSendMessage";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import small from "../assets/images/books-box.png";
import large from "../assets/images/moving-box.png";
import medium from "../assets/images/shoes-box.png";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheets";
import { useCancelQuoteModal } from "../hooks/use-cancel-quote";
import { useSendMessage } from "../hooks/use-send-message";

const ViewCarrierQuote = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const modalCancelQuote = useCancelQuoteModal();
  const modalSendMessage = useSendMessage();

  const token = useTokenStoreStorage((state) => state.token);

  const getQuotes = async () => {
    const response = await fetch(`${BASE_URL}${TRANSPORTERMYQUOTE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id.toString(),
        status: params.type,
        quote_id: params.quoteId,
      }),
    }).then((res) => res.json());

    return response;
  };

  const {
    data: quotes,
    isLoading,
    refetch,
  } = useQuery<any>({
    queryKey: ["quotes", params.quoteId, params.type],
    queryFn: getQuotes,
  });

  return (
    <SidebarLayout>
      <div className="flex items-center justify-between">
        <div
          onClick={() => {
            navigate("/quote/active");
          }}
          className="flex items-center space-x-4 pl-4 py-4"
        >
          <ArrowLeft className="h-4 w-4 text-freightBlueDark cursor-pointer" />
          <h1 className="text-sm hover:underline text-freightBlueDark  cursor-pointer">
            Back to Quotes
          </h1>
        </div>
        <p className="text-sm font-medium text-gray-500 capitalize bg-white rounded px-4 py-2">
          {quotes?.data[0].status}
        </p>
      </div>
      {isLoading ? (
        <div className="flex items-start space-x-4 bg-white m-4 p-4 border-border border justify-between">
          <div className="flex flex-col items-start bg-gray-50 rounded border p-4">
            <Skeleton className="h-5 w-20" />
            <Skeleton className="h-5 w-28 mt-4" />
            <Skeleton className="h-5 w-28 mt-4" />
          </div>
          <div className="flex flex-col items-start">
            <Skeleton className="h-5 w-20" />
            <Skeleton className="h-5 w-40 mt-4" />
            <Skeleton className="h-5 w-40 mt-4" />
          </div>
          <Skeleton className="h-5 w-40" />
        </div>
      ) : (
        <div>
          <div className="flex items-start md:space-x-4 bg-white md:m-4 md:p-4 border-border border justify-between">
            {quotes?.data
              .filter(
                (quote: BookingQuote) =>
                  quote.quote_id.toString() === params.quoteId
              )
              .map((quote: BookingQuote) => (
                <div className="sm:flex flex-col space-y-2 items-start  w-full relative">
                  <Sheet>
                    <SheetTrigger asChild>
                      <Button variant="outline" className="ml-auto ">
                        <Settings className="h-4 w-4 mr-2" />
                        Settings{" "}
                      </Button>
                    </SheetTrigger>
                    <SheetContent className="flex flex-col justify-between">
                      <SheetHeader>
                        <SheetTitle>Quote Settings</SheetTitle>
                        <SheetDescription>
                          Make changes to your quote here.
                        </SheetDescription>
                        <div className="grid gap-4 py-4">
                          <div className="grid grid-cols-4 items-center gap-4 ">
                            <Button className="col-span-4" variant={"outline"}>
                              <Pencil className="h-4 w-4 mr-2" />
                              Edit Quote
                            </Button>
                          </div>

                          <div className="grid grid-cols-4 items-center gap-4">
                            {user_type !== "customer" &&
                              quote.status === "active" && (
                                <Button
                                  className="col-span-4"
                                  variant={"destructive"}
                                  onClick={() =>
                                    modalCancelQuote.onOpen(
                                      "cancelQuote",
                                      quote.quote_id
                                    )
                                  }
                                >
                                  <Ban className="h-4 w-4 mr-2" />
                                  Cancel Quote
                                </Button>
                              )}
                          </div>
                        </div>
                      </SheetHeader>

                      <SheetFooter>
                        {user_type === "carrier" && (
                          <div className="grid grid-cols-4 items-center gap-4">
                            <Button className="col-span-1" variant={"outline"}>
                              <Phone className="h-4 w-4 " />
                            </Button>
                            <Button
                              className="col-span-3"
                              onClick={() =>
                                modalSendMessage.onOpen("sendMessage", {
                                  quoteId: quote.quote_id,
                                  customerId: quote.customerId,
                                  carrierId: quote.accepted_carr_id,
                                  receiverId: Number(user_id),
                                  senderId: quote.accepted_carr_id,
                                  freightId: quote.freight_id,
                                  transporterImage: quote.transporter_name,
                                  transporterName: quote.transporter_name,
                                  customerName: quote.customer_name,
                                })
                              }
                              variant={"outline"}
                            >
                              <Mail className="h-4 w-4 mr-2" />
                              <p>Send Message</p>
                            </Button>
                          </div>
                        )}
                      </SheetFooter>
                    </SheetContent>
                  </Sheet>
                  <div className=" items-start sm:space-y-2 w-full flex-grow">
                    <div className=" bg-gray-50  rounded border p-4  flex flex-col items-start space-y-4 ">
                      <div className="sm:flex w-full  items-start sm:space-x-10">
                        <div className="flex flex-col items-start">
                          <h1 className=" text-sm font-medium">Freight</h1>
                          <p
                            onClick={() =>
                              navigate(
                                `/freight/freightDetails/${quote.freight_id}`
                              )
                            }
                            className="hover:underline text-freightBlueDark cursor-pointer"
                          >
                            {quote.title}
                          </p>
                        </div>
                        <div className="flex flex-col  items-start space-y-2 w-40">
                          <div className="flex flex-col  items-start space-y-1">
                            <p className="font-medium text-sm">Pickup date</p>{" "}
                            <p className="text-sm font-medium text-gray-500">
                              {quote.pickup_date1}
                            </p>
                          </div>

                          <div className="flex flex-col  items-start space-y-1">
                            <p className="font-medium text-sm">Dropoff date</p>
                            <p className="text-sm font-medium text-gray-500">
                              {quote.dropoff_date1}
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <div className="flex items-start space-x-2">
                            <div className="w-6">
                              <MapPin className="text-green-500 h-5 w-5" />
                            </div>
                            <div className="">
                              <p className="text-sm font-medium">Origin</p>
                              <p className="text-sm font-medium text-gray-500">
                                {quote.origin_address}
                              </p>
                            </div>{" "}
                          </div>
                          <div className="flex items-start space-x-2">
                            <div className="w-6">
                              <MapPin className="text-freightBlueDark h-5 w-5" />
                            </div>

                            <div className="">
                              <p className="text-sm font-medium">Destination</p>{" "}
                              <p className="text-sm font-medium text-gray-500">
                                {quote.destination_address}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-t border-border w-full pt-4">
                        {quote.animal_img.length > 0 ? (
                          <div>
                            {quote.animal_img.map(
                              (freightImage: { freight_img: string }) => (
                                <img
                                  src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${freightImage.freight_img}&image_type=freight`}
                                  className=" w-60 object-contain rounded"
                                  alt="freightImage"
                                />
                              )
                            )}
                          </div>
                        ) : (
                          <>
                            {quote?.freight_type_info.freight_size.id === 1 && (
                              <img
                                src={small}
                                className=" w-24 rounded-lg object-cover cursor-pointer"
                                alt="small package"
                              />
                            )}
                            {quote?.freight_type_info.freight_size.id === 2 && (
                              <img
                                src={medium}
                                className=" w-24 rounded-lg object-cover cursor-pointer"
                                alt="medium package"
                              />
                            )}
                            {quote?.freight_type_info.freight_size.id === 3 && (
                              <img
                                src={large}
                                className=" w-24 rounded-lg object-cover cursor-pointer"
                                alt="large package"
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col items-start sm:min-w-80 bg-gray-50 border-border border rounded ">
                      <div className="  p-4 flex items-center justify-between w-full">
                        <h1 className="text-sm font-medium">Amount</h1>
                        <p className="text-sm font-medium text-gray-500">
                          {quote.amount}€
                        </p>
                      </div>

                      <div className="   p-4 flex items-center justify-between w-full">
                        <h1 className="text-sm font-medium ">Expires in</h1>
                        <p className="text-sm font-medium text-gray-500">
                          {quote.expire_date}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col items-start pl-2 w-full  ">
                    <div className="flex flex-col items-start ">
                      <div className="flex flex-wrap whitespace-nowrap gap-3"></div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}

      {modalCancelQuote.isOpen && <ModalCancelQuote refetch={refetch} />}
      {modalSendMessage.isOpen && <ModalSendMessage />}
    </SidebarLayout>
  );
};

export default ViewCarrierQuote;
