import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";

import {
  CheckCircle,
  ChevronLeft,
  CircleOff,
  Loader2,
  Mail,
  Pencil,
  Phone,
  Plus,
  Settings,
  Trash,
} from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import {
  ALL_ROUTES,
  BASE_URL,
  GOOGLE_API_KEY,
  ROUTE_STATUS,
} from "../api/config";
import Header from "../components/header/Header";
import ModalConfirmStop from "../components/modal/ModalConfirmStop";
import ModalDeleteRoute from "../components/modal/ModalDeleteRoute";
import ModalDeleteStop from "../components/modal/ModalDeleteStop";
import ModalUpdateStatusStop from "../components/modal/ModalUpdateStatusStop";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import { useConfirmStop } from "../hooks/use-confirm-stop";
import { useDeleteRoute } from "../hooks/use-delete-route";
import { useDeleteStop } from "../hooks/use-delete-stop";
import { useUpdateStatusStop } from "../hooks/use-update-status-stop";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { RouteType, StopType } from "../types/types";

//@ts-ignore
import { ReactComponent as EmptySvg } from "../assets/images/data-not-found.svg";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheets";
import { cn } from "../lib/utils";

const containerStyle = {
  Maxwidth: "540px",
  height: "400px",
  borderRadius: "4px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const ViewRoute = () => {
  const params = useParams();
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const navigate = useNavigate();
  const [directionResponse, setDirectionsResponse] = useState<any>(null);
  const [distance, setDistance] = useState<any>();
  const [duration, setDuration] = useState<any>();
  const [map, setMap] = useState(null);
  const modalDeleteRoute = useDeleteRoute();
  const modalUpdateStatus = useUpdateStatusStop();
  const [showOtp, setShowOtp] = useState(false);
  const modalConfirmStop = useConfirmStop();
  const modalDeleteStop = useDeleteStop();

  // {
  //   modalRouteInTransit.isOpen && (
  //     <ModalInstantFreightInTransit refetch={refetch} />
  //   );
  // }

  // {
  //   modalRoutePickingUp.isOpen && (
  //     <ModalInstantFreightPickingUp refetch={refetch} />
  //   );
  // }
  // {
  //   modalRouteComplete.isOpen && (
  //     <ModalCompleteInstantFreight refetch={refetch} />
  //   );
  // }

  const getRoute = async () => {
    const response = await fetch(
      `${BASE_URL}${ALL_ROUTES}?user_id=${user_id}&route_id=${params.routeId}&sort=desc&page=1`,
      {
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };
  const {
    data: routes,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["routes_user", params.routeId],
    queryFn: getRoute,
  });

  useEffect(() => {
    refetch();
  }, []);

  const setRouteAsCompleted = async () => {
    const response = await fetch(`${BASE_URL}${ROUTE_STATUS}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        route_id: routes?.data.routes[0]?.id,
        user_id: user_id,
        status: "confirmed",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Route status updated successfully") {
          refetch();
          // modal.onClose();
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return response;
  };

  const onLoad = useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });

  async function calculateRoute() {
    const directionsService = new google.maps.DirectionsService();

    //@ts-ignore
    const results = await directionsService.route({
      origin: {
        lat: Number(routes?.data.routes[0]?.origin_lat),
        lng: Number(routes?.data.routes[0]?.origin_lng),
      },
      destination: {
        lat: Number(routes?.data.routes[0]?.destination_lat),
        lng: Number(routes?.data.routes[0]?.destination_lng),
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance!.text);
    setDuration(results.routes[0].legs[0].duration!.text);

    console.log("results", results);
  }

  useEffect(() => {
    if (!isLoading) {
      calculateRoute();
    }
  }, [isLoading, routes]);

  return (
    <div className="h-screen bg-gray-50">
      <Header />
      <div className="max-w-7xl mx-auto p-16 h-[94vh] overflow-y-auto no-scrollbar">
        <div className="flex items-center justify-between">
          <div
            onClick={() => navigate("/carrier/trips")}
            className="flex items-center space-x-2 group cursor-pointer mb-4"
          >
            <ChevronLeft className="text-freightBlueDark h-5 w-5" />
            <h1 className="text-freightBlueDark text-sm group-hover:underline ">
              Back
            </h1>
          </div>
          <div className="bg-white border border-border mb-4 rounded">
            <p className="capitalize text-sm font-medium text-[#333] px-4 py-2">
              {routes?.data.routes[0].status}
            </p>
          </div>
        </div>
        {isLoading ? (
          <div className="bg-white shadow border border-border p-4 grid grid-cols-2 gap-4">
            <div>
              <div className="w-[540px] h-[400px] bg-gray-50 flex items-center justify-center">
                <Loader2 className="h-5 w-5 animate-spin" />
              </div>
              <div className="flex items-center justify-around mt-4">
                <Skeleton className=" h-20 w-20" />
                <Skeleton className=" h-20 w-20" />
                <Skeleton className=" h-20 w-20" />
              </div>
            </div>
            <div className="flex flex-col items-start">
              <div className="flex items-center justify-between w-full">
                <Skeleton className="h-10 w-10" />
                <Skeleton className="h-10 w-10" />
              </div>
            </div>
          </div>
        ) : (
          <div>
            {routes?.data?.routes.map((route: RouteType) => (
              <>
                <div className="bg-white shadow border-border border p-4 grid sm:grid-cols-2 gap-4">
                  <div>
                    {isLoaded ? (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={{
                          lat: Number(route?.origin_lat),
                          lng: Number(route?.origin_lng),
                        }}
                        options={{
                          streetViewControl: false,
                        }}
                        zoom={15}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                      >
                        {route.stops.map((stop) => (
                          <Marker
                            key={stop.id}
                            icon={{
                              // path: google.maps.SymbolPath.CIRCLE,
                              url: require("./../assets/images/box.png"),
                              fillColor: "#EB00FF",
                              scale: 7,
                            }}
                            title={stop.destination_address}
                            position={{
                              lat: Number(stop.destination_lat),
                              lng: Number(stop.destination_lng),
                            }}
                          />
                        ))}

                        {directionResponse && (
                          <DirectionsRenderer directions={directionResponse} />
                        )}
                      </GoogleMap>
                    ) : null}
                  </div>
                  <div className="flex flex-col items-start space-y-10 relative">
                    <div className="flex items-center justify-between w-full">
                      <div className="">
                        {route.is_one_way ? (
                          <div className="flex items-center space-x-2">
                            <CheckCircle className=" text-green-500 rounded-full h-5 w-5" />
                            <p className="text-sm font-medium">One way trip</p>
                          </div>
                        ) : (
                          <div className="flex items-center space-x-2">
                            <CircleOff className="text-red-500 rounded-full h-5 w-5 " />
                            <p className="text-sm font-medium">
                              Not One Way Trip
                            </p>
                          </div>
                        )}
                      </div>
                      <Sheet>
                        <SheetTrigger asChild>
                          <Button
                            variant="outline"
                            className="ml-auto absolute top-0 right-0"
                          >
                            <Settings className="h-4 w-4 mr-2" />
                            Settings{" "}
                          </Button>
                        </SheetTrigger>
                        <SheetContent className="flex flex-col justify-between">
                          <SheetHeader>
                            <SheetTitle>Route Settings</SheetTitle>
                            <SheetDescription>
                              Make changes to your route here.
                            </SheetDescription>
                            <div className="grid gap-4 py-4">
                              <div className="grid grid-cols-4 items-center gap-4">
                                <Button
                                  onClick={() => {
                                    navigate(`/carrier/trips/edit/${route.id}`);
                                  }}
                                  className="col-span-4"
                                  disabled={route.stops.length > 0}
                                  variant={"freightGreen"}
                                >
                                  <BiPencil className="h-4 w-4 mr-2" />
                                  <p className="text-sm font-medium ">
                                    Update Route
                                  </p>
                                </Button>
                              </div>
                              <div className="grid grid-cols-4 items-center gap-4 ">
                                <Button
                                  className="col-span-4"
                                  disabled={route.stops.length > 0}
                                  variant={"outline"}
                                  onClick={() =>
                                    modalDeleteRoute.onOpen(
                                      "deleteRoute",
                                      route.id.toString()
                                    )
                                  }
                                >
                                  <BiTrash className="h-4 w-4 text-[#333] mr-2" />
                                  <p className="text-sm font-medium text-[#333]">
                                    Delete Route
                                  </p>
                                </Button>
                              </div>
                              <div className="grid grid-cols-4 mt-10 items-center gap-4">
                                <Button
                                  variant={"freightGreen"}
                                  className="col-span-4"
                                  onClick={() => setRouteAsCompleted()}
                                >
                                  Set Route as Complete
                                </Button>
                              </div>
                            </div>
                          </SheetHeader>

                          <SheetFooter>
                            {user_type === "carrier" && (
                              <div className="grid grid-cols-4 items-center gap-4">
                                <Button
                                  className="col-span-1"
                                  variant={"outline"}
                                >
                                  <Phone className="h-4 w-4 " />
                                </Button>
                                <Button
                                  className="col-span-3"
                                  // onClick={() =>
                                  //   modalSendMessage.onOpen("sendMessage", {
                                  //     quoteId: quote.quote_id,
                                  //     customerId: quote.customerId,
                                  //     carrierId: quote.accepted_carr_id,
                                  //     receiverId: Number(user_id),
                                  //     senderId: quote.accepted_carr_id,
                                  //     freightId: quote.freight_id,
                                  //     transporterImage: quote.transporter_name,
                                  //     transporterName: quote.transporter_name,
                                  //     customerName: quote.customer_name,
                                  //   })
                                  // }
                                  variant={"outline"}
                                >
                                  <Mail className="h-4 w-4 mr-2" />
                                  <p>Send Message</p>
                                </Button>
                              </div>
                            )}
                          </SheetFooter>
                        </SheetContent>
                      </Sheet>
                      <div className="flex items-center space-x-2"></div>
                    </div>

                    <div className="flex flex-col space-y-4 w-full">
                      <div className="flex items-start justify-between w-full">
                        <p className="text-sm font-medium">Start Date</p>
                        <p className="text-sm font-medium text-gray-500">
                          {new Date(route.start_date).toDateString()}{" "}
                          {new Date(route.start_date).getHours()}:
                          {new Date(route.start_date).getMinutes()}
                        </p>
                      </div>
                      <div className="flex items-start justify-between w-full">
                        <p className="text-sm font-medium">End Date</p>
                        <p className="text-sm font-medium text-gray-500">
                          {new Date(route.end_date).toDateString()}{" "}
                          {new Date(route.end_date).getHours()}:
                          {new Date(route.end_date).getMinutes()}
                        </p>
                      </div>
                      <div className="flex items-start justify-between w-full">
                        <p className="text-sm font-medium">Origin</p>
                        <p className="text-sm font-medium text-gray-500">
                          {route.origin_address}, {route.origin_zipcode}
                        </p>
                      </div>
                      <div className="flex items-start justify-between w-full">
                        <p className="text-sm font-medium">Destination</p>
                        <p className="text-sm font-medium text-gray-500">
                          {route.destination_address},{" "}
                          {route.destination_zipcode}
                        </p>
                      </div>
                      <div className="flex items-start justify-between w-full">
                        <p className="text-sm font-medium">Nº stops</p>
                        <p className="text-sm font-medium text-gray-500">
                          {route.stops.length}
                        </p>
                      </div>
                      <div className="flex items-start justify-between w-full">
                        <p className="text-sm font-medium">Distance</p>
                        <p className="text-sm font-medium text-gray-500">
                          {distance}
                        </p>
                      </div>
                      <div className="flex items-start justify-between w-full">
                        <p className="text-sm font-medium">Duration</p>
                        <p className="text-sm font-medium text-gray-500">
                          {duration}
                        </p>
                      </div>
                    </div>
                    {/* {route.stops.length > 0 ? (
                    <div className="w-full">
                      <h1 className="text-lg font-semibold">Stops</h1>
                      <div className="mt-4 flex flex-col space-y-3">
                        <></>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center space-x-4 rounded  bg-gray-50 py-4 px-2">
                      <RouteOff className="h-5 w-5 text-gray-500" />
                      <p className="text-sm font-medium text-gray-500">
                        No stops requested
                      </p>
                    </div>
                  )} */}
                  </div>
                </div>
                {user_type !== "carrier" && (
                  <div className="flex items-end justify-between w-full mt-4">
                    <div className="ml-auto"></div>
                  </div>
                )}
              </>
            ))}
          </div>
        )}
        <div className=" mt-10">
          <div className="flex items-center justify-between">
            <h1 className="text-lg font-medium pb-3">Stops</h1>
            <Button
              className="mb-2"
              variant={"outline"}
              onClick={() =>
                navigate(`/trip-package/stops/${routes?.data.routes[0]?.id}`)
              }
            >
              <Plus className="h-4 w-4" />
              Add stop
            </Button>
          </div>
          <div>
            {routes?.data.routes[0].stops.length === 0 && (
              <div className="flex flex-col w-full items-center justify-center py-2 border-border border bg-white rounded">
                <EmptySvg />
                <p className="text-sm font-medium text-gray-500">
                  No stops found.
                </p>
              </div>
            )}
          </div>

          <div className="  grid grid-cols-3 gap-4">
            {routes?.data.routes[0].stops.map((route: StopType) => (
              <div className="bg-white border border-border p-4 rounded flex flex-col w-full">
                <div className="flex flex-col space-y-3">
                  <div className="flex items-start justify-between">
                    <p className="text-sm font-medium">Status</p>
                    <p
                      className={cn(
                        "text-sm font-medium text-white capitalize bg-statusCreated px-4 py-1 rounded",
                        route.status === "booked" && "bg-statusPaid",
                        route.status === "available" && "bg-statusCreated",
                        route.status === "in-transit" && "bg-statusInTransit",
                        route.status === "picking-up" && "bg-statusPickingUp",
                        route.status === "confirmed" && "bg-statusConfirmed",
                        route.status === "cancelled" && "bg-gray-500"
                      )}
                    >
                      {route.status}
                    </p>
                  </div>
                  <div className="flex items-start justify-between">
                    <p className="text-sm font-medium">Customer</p>
                    <div className="flex items-center space-x-2 text-sm font-medium text-gray-500">
                      {route.customer_data.profile_img === null ? (
                        <></>
                      ) : (
                        <img
                          alt="profile"
                          className="h-10 w-10 rounded-full object-cover"
                          src={`${BASE_URL}images?user_id=${user_id}&file_name=${route.customer_data.profile_img}&image_type=profile`}
                        />
                      )}
                      <p>
                        {route.customer_id !== 0
                          ? `${route.customer_data.firstname} ${route.customer_data.lastname}`
                          : `Not Assigned`}
                      </p>
                    </div>
                  </div>
                  <div className=" flex items-start justify-between">
                    <p className="text-sm font-medium">Package</p>
                    <p className="text-sm font-medium text-gray-500 capitalize">
                      {route.package_size}
                    </p>
                  </div>
                  <div className="flex items-start justify-between">
                    <p className="text-sm font-medium">Price</p>
                    <p className="text-sm font-medium text-gray-500">
                      {route.price.toFixed(2)}€
                    </p>
                  </div>

                  <div className="flex items-start justify-between ">
                    <p className="text-sm font-medium">Origin</p>
                    <p className="text-sm font-medium text-gray-500">
                      {route.origin_address}
                    </p>
                  </div>
                  <div className="flex items-start justify-between ">
                    <p className="text-sm font-medium">Destination</p>
                    <p className="text-sm font-medium text-gray-500">
                      {route.destination_address}
                    </p>
                  </div>
                  <div className="flex items-start justify-between ">
                    {route.status === "confirmed" && (
                      <>
                        <p className="text-sm font-medium">Reviewed</p>
                        <div className="text-sm font-medium text-gray-500">
                          {route.is_reviewed !== 0 ? <p>Yes</p> : <>No</>}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap gap-4 items-center space-x-4 mt-4 justify-end ml-auto">
                  {route.status === "booked" && (
                    <Button
                      onClick={() =>
                        modalUpdateStatus.onOpen(
                          "updateStopStatus",
                          route.id.toString(),
                          route.status,
                          "picking-up",
                          "Picking up"
                        )
                      }
                      variant="default"
                      className="w-full"
                    >
                      Set to Picking Up
                    </Button>
                  )}
                  {route.status === "in-transit" && (
                    <Button
                      variant="default"
                      onClick={() => {
                        modalUpdateStatus.onOpen(
                          "updateStopStatus",
                          route.id.toString(),
                          route.status,
                          "confirmed",
                          "Complete"
                        );
                      }}
                    >
                      Confirm Delivery
                    </Button>
                  )}
                  {route.status === "picking-up" && (
                    <Button
                      variant="default"
                      onClick={() =>
                        modalUpdateStatus.onOpen(
                          "updateStopStatus",
                          route.id.toString(),
                          route.status,
                          "in-transit",
                          "In Transit"
                        )
                      }
                      className="w-full"
                    >
                      Set to Transit
                    </Button>
                  )}

                  {route.status === "available" && (
                    <div className="flex items-center space-x-2">
                      {user_type === "carrier" && (
                        <Button
                          variant={"outline"}
                          onClick={() =>
                            navigate(
                              `/carrier/trips/edit/${route.id.toString()}`
                            )
                          }
                        >
                          <Pencil className="h-4 w-4 " />
                          {/* Update Stop */}
                        </Button>
                      )}
                      {route.status === "available" && (
                        <Button
                          onClick={() =>
                            modalDeleteStop.onOpen(
                              "deleteStop",
                              route.id.toString()
                            )
                          }
                          variant={"outline"}
                        >
                          <Trash className="h-4 w-4 text-gray-500 " />
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {modalDeleteRoute && <ModalDeleteRoute />}
      {modalUpdateStatus && <ModalUpdateStatusStop refetch={refetch} />}
      {modalConfirmStop && <ModalConfirmStop refetch={refetch} />}
      {modalDeleteStop && <ModalDeleteStop refetch={refetch} />}
    </div>
  );
};

export default ViewRoute;
