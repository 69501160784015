import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/layout/Footer";
import VerticalLinearStepper from "../components/howitworks/VerticalLinearStepper";
//@ts-ignore
import photo from "../assets/images/stepsimg.jpg";
import { useNavigate } from "react-router-dom";

export const HowItWorks = () => {
  const navigate = useNavigate();

  return (
    <div className="overflow-hidden h-screen">
      <div>
        <Header />
      </div>
      <div className="h-[94vh] overflow-y-auto">
        <div className="flex justify-center pt-20 pb-20">
          <p className="font-bold text-4xl">HOW IT WORKS</p>
        </div>
        <div className="lg:flex lg:flex-row justify-center pb-20 flex-col">
          <div className="flex justify-center px-8 pb-20 border-gray-200 border max-w-[1000px]">
            <VerticalLinearStepper />
          </div>
          <div className="border-gray-200 border h-[800px] max-w-[800px] px-20">
            <div className="flex justify-center pb-4">
              <img src={photo} className="max-w-[550px]" alt="" />
            </div>
            <p>
              RUNNME provides to the customer and the transporter an easy way to use the web app in terms of accesibility and design so you can use our services easily and not wasting time on it.
            </p>

            <button
              onClick={() => navigate("/")}
              className="h-[40px] w-[200px] mt-20 bg-[#ff380f] shadow-lg hover:bg-[#ff846c] hover:shadow-4xl text-white font-bold rounded-2xl transition duration-300 ease-in-out"
            >
              ask for a delivery
            </button>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
