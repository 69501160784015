import { create } from "zustand";
import { OrdersEcom } from "../types/types";

export type ModalType = "viewItemsOrder";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  order: OrdersEcom | null;
  onOpen: (type: ModalType, order: OrdersEcom) => void;
  onClose: () => void;
}

export const useViewItemsOrder = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  order: null,
  onOpen: (type, order) => set({ isOpen: true, type, order }),
  onClose: () => set({ type: null, isOpen: false }),
}));
