import { useQuery } from "@tanstack/react-query";
import { ArrowLeft, Folder, X } from "lucide-react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { FaSpinner } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import {
  ARCHIVE_MESSAGE,
  BASE_URL,
  DELETE_MESSAGE,
  GET_COUNT,
  MESSAGE_INBOX,
} from "../api/config";
import SidebarLayout from "../components/layout/SidebarLayout";
import MessageDetails from "../components/message/MessageDetails";
import { Skeleton } from "../components/skeleton/Skeleton";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { MessageType } from "../types/types";

const InboxDetails = () => {
  const { user_type, user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((state) => state.token);
  const [isLoadingArchive, setIsLoadingArchive] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const getInboxMessage = async () => {
    const response = await fetch(
      `${BASE_URL}${MESSAGE_INBOX}?user_id=${user_id}&message_id=${params.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    await refetchCount();
    return response;
  };

  const {
    data: inboxMessage,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ["inbox_message", params.id],
    queryFn: getInboxMessage,
  });
  const getCount = async () => {
    const response = await fetch(`${BASE_URL}${GET_COUNT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },

      body: JSON.stringify({
        user_id: user_id,
        user_type: user_type,
      }),
    }).then((res) => res.json());

    return response;
  };

  const {
    data: count,
    isLoading: isLoadingCount,
    refetch: refetchCount,
  } = useQuery({
    queryKey: ["count"],
    queryFn: getCount,
  });

  const deleteMessage = async () => {
    setIsLoadingDelete(true);
    const response = await fetch(`${BASE_URL}${DELETE_MESSAGE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        message_id: params.id,
        status: "read",
      }),
    }).then((res) => res.json());

    if (response.data[0] === "Messages are marked as read!") {
      await refetchCount();
      setIsLoadingDelete(false);
      toast.success("Message deleted successfully!");
      navigate("/myInbox/unread");
    } else {
      toast.error("Failed to archive the message.");
    }

    return response;
  };

  const archiveMessage = async () => {
    setIsLoadingArchive(true);
    try {
      const response = await fetch(`${BASE_URL}${ARCHIVE_MESSAGE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
        body: JSON.stringify({
          user_id: user_id,
          message_id: params.id,
          // status: "archived",
        }),
      }).then((res) => res.json());

      if (response.data[0] === "Message marked as archived!") {
        await refetchCount();
        setIsLoadingArchive(false);
        toast.success("Message archived successfully!");
        navigate("/myInbox/archived");
      } else {
        toast.error("Failed to archive the message.");
      }

      return response;
    } catch (error) {
      toast.error("An error occurred while archiving the message.");
      console.error("Error:", error);
    }
  };

  return (
    <SidebarLayout>
      <div className="flex items-center justify-between w-full border-b border-border pb-3 mb-4">
        <h1 className="text-2xl font-semibold text-gray-900">My Messages</h1>
      </div>

      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="flex items-center justify-between mb-4">
          <div
            onClick={() => navigate("/myInbox/unread")}
            className="flex items-center space-x-2 cursor-pointer hover:text-freightBlueDark"
          >
            <ArrowLeft className="h-5 w-5 text-freightBlueDark" />
            <h3 className="text-sm font-medium">Back to messages</h3>
          </div>

          <div className="flex space-x-4">
            <div
              onClick={() => deleteMessage()}
              className="flex items-center space-x-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-md cursor-pointer transition ease-in-out duration-150"
            >
              {isLoadingDelete ? (
                <>
                  <FaSpinner className="h-5 w-5 animate-spin" />
                  <span className="text-sm font-medium text-red-600">
                    Deleting...
                  </span>
                </>
              ) : (
                <>
                  <X className="text-red-500 h-5 w-5" />
                  <span className="text-sm font-medium text-red-600">
                    Delete
                  </span>
                </>
              )}
            </div>

            {inboxMessage?.data[0]?.status !== "archived" && (
              <div
                onClick={() => archiveMessage()}
                className="flex items-center space-x-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-md cursor-pointer transition ease-in-out duration-150"
              >
                {isLoadingArchive ? (
                  <>
                    <FaSpinner className="h-5 w-5 animate-spin" />
                    <span className="text-sm font-medium text-yellow-600">
                      Archiving...
                    </span>
                  </>
                ) : (
                  <>
                    <Folder className="h-5 w-5 text-yellow-400" />
                    <span className="text-sm font-medium text-yellow-600">
                      Archive
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        {isLoading || isRefetching ? (
          <div className="space-y-4">
            <Skeleton className="h-6 w-64" />
            <Skeleton className="h-6 w-32" />
            <div className="flex justify-center border-t border-border pt-4">
              <Skeleton className="h-40 w-48 rounded-lg" />
            </div>
            <div className="space-y-4 mt-4">
              <Skeleton className="h-6 w-64" />
              <Skeleton className="h-6 w-80" />
              <Skeleton className="h-6 w-96" />
            </div>
          </div>
        ) : (
          <div className="flex flex-col space-y-4">
            {inboxMessage?.data.map((message: MessageType) => (
              <MessageDetails key={message.id} message={message} />
            ))}
          </div>
        )}
      </div>
    </SidebarLayout>
  );
};

export default InboxDetails;
