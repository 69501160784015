import { useDeleteStop } from "src/hooks/use-delete-stop";
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog";
import { AlertCircle } from "lucide-react";
import { Button } from "../ui/button";
import { BASE_URL, STOP_REMOVE } from "src/api/config";
import { useTokenStoreStorage } from "src/store/useTokenStore";
import { userInfoUserStore } from "src/store/useUserInfoStore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface ModalDeleteStopProps {
  refetch: () => void;
}

const ModalDeleteStop: React.FC<ModalDeleteStopProps> = ({ refetch }) => {
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const modalDeleteStop = useDeleteStop();
  const [loadingDeletingStop, setLoadingDeletingStop] = useState(false);
  const navigate = useNavigate();

  const deleteStop = async () => {
    setLoadingDeletingStop(true);
    const response = await fetch(`${BASE_URL}${STOP_REMOVE}`, {
      method: "DELETE",

      headers: {
        "Content-Type": "application/json",

        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
        stop_id: modalDeleteStop.stopId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Stop deleted successfully") {
          modalDeleteStop.onClose();
          if (user_type !== "carrier") {
            navigate("/customer/trips");
          } else {
            navigate("/carrier/trips");
          }
          refetch();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingDeletingStop(false);
      });

    return response;
  };
  return (
    <Dialog
      open={modalDeleteStop.isOpen}
      onOpenChange={modalDeleteStop.onClose}
    >
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-start w-full space-y-10">
              <div className="flex flex-col items-center w-full">
                <AlertCircle className="text-red-500 h-20 w-20" />
                <h1 className="text-xl font-medium">Are you sure?</h1>
                <h3 className="text-sm ">You want to remove this stop?</h3>
              </div>
              <div className="flex items-center justify-end space-x-2 w-full mt-4">
                <Button
                  variant={"outline"}
                  onClick={() => modalDeleteStop.onClose()}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => deleteStop()}
                  disabled={loadingDeletingStop}
                >
                  Delete Stop
                </Button>
              </div>
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ModalDeleteStop;
