import { useQuery } from "@tanstack/react-query";
import {
  BASE_URL,
  BASE_URL_IMG_2,
  INSTANT_FREIGHTS_CARRIER,
} from "../api/config";
import SidebarLayout from "../components/layout/SidebarLayout";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";

import { ArrowLeft, ArrowRight, Loader2 } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalCompleteInstantFreight from "../components/modal/ModalCompleteInstantFreight";
import ModalInstantFreightInTransit from "../components/modal/ModalInstantFreightInTransit";
import ModalInstantFreightPickingUp from "../components/modal/ModalInstantFreightPickingUp";
import { Skeleton } from "../components/skeleton/Skeleton";
import { Button } from "../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { useComplteInstantFreight } from "../hooks/use-complete-instant-freight";
import { useInstantFreightInTransit } from "../hooks/use-instant-freight-in-transit";
import { useInstantFreightPickingUp } from "../hooks/use-instant-freight-picking-up";
import { cn } from "../lib/utils";
import { InstantFreight } from "../types/types";
//@ts-ignore
import SmallBox from "../assets/images/books-box.png";
//@ts-ignore
import MediumBox from "../assets/images/shoes-box.png";
//@ts-ignore
import LargeBox from "../assets/images/moving-box.png";
//@ts-ignore
import { ReactComponent as EmptySvg } from "../assets/images/data-not-found.svg";

const CarrierInstantBookings = () => {
  const token = useTokenStoreStorage((state) => state.token);
  const { user_id } = userInfoUserStore((state) => state);
  const modalCompelteFreight = useComplteInstantFreight();
  const modalFreightPickingUp = useInstantFreightPickingUp();
  const modalFreightInTransit = useInstantFreightInTransit();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState<"ASC" | "DESC">("DESC");

  const getInstantBookings = async () => {
    const response = await fetch(
      `${BASE_URL}${INSTANT_FREIGHTS_CARRIER}?user_id=${user_id}&page=${page}&items_per_page=5&sort=${sortBy}`,
      {
        method: "GET",
        headers: {
          AccessToken: token,
        },
      }
    ).then((res) => res.json());
    return response;
  };

  const {
    data: instantBookings,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["instant_bookings", page, sortBy],
    queryFn: getInstantBookings,
  });

  const sortOptions = [
    {
      value: "ASC",
      label: "Ascendent",
    },
    {
      value: "DESC",
      label: "Descendent",
    },
  ];

  return (
    <SidebarLayout>
      <div className="flex items-center justify-between w-full border-b border-border pb-3 mb-4">
        <p className="text-lg font-semibold ">Instant Bookings</p>
      </div>
      <div className=" bg-white shadow rounded p-4">
        {instantBookings?.message !==
          "No instant freights found for this carrier" && (
          <div className="mt-4 ml-auto w-full justify-end sm:flex space-x-2 mb-4">
            <Select
              onValueChange={(val: any) => {
                setSortBy(val);
              }}
              value={sortBy}
            >
              <SelectTrigger className="w-40 h-8 mt-2">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent>
                {sortOptions.map((sort) => (
                  <SelectItem value={sort.value}>{sort.label}</SelectItem>
                ))}
              </SelectContent>
            </Select>
            <div className="flex gap-2 pt-2">
              <Button
                disabled={page === 1}
                onClick={() => setPage(1)}
                className={cn(
                  "h-8 w-8 bg-white border border-border rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                  page === 1 && "bg-[#333] text-white"
                )}
              >
                <p className="text-sm font-medium">1</p>
              </Button>
              <button
                onClick={() => {
                  if (page > 1) {
                    setPage((page) => page - 1);
                  }
                }}
                className="h-8 w-8 bg-white border border-border rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]"
              >
                <ArrowLeft className="h-4 w-4 text-[#333]" />
              </button>
              <div className="h-8 w-8 bg-white border border-border rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition">
                {" "}
                <p className="text-sm font-medium">{page}</p>
              </div>
              <button
                disabled={page === instantBookings?.data?.total_pages}
                onClick={() => {
                  if (page < instantBookings?.data?.total_pages) {
                    setPage((page) => page + 1);
                  }
                }}
                className="h-8 w-8 bg-white text-[#333] border border-border rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition"
              >
                <ArrowRight className="h-4 w-4 text-[#333]" />
              </button>
              <Button
                disabled={page === instantBookings?.data?.total_pages}
                onClick={() => setPage(instantBookings?.data?.total_pages)}
                className={cn(
                  "h-8 w-8 bg-white border border-border rounded flex items-center justify-center cursor-pointer hover:bg-gray-50 transition text-[#333]",
                  page === instantBookings?.data?.total_pages &&
                    "bg-[#333] text-white"
                )}
              >
                {isLoading ? (
                  <p>
                    <Loader2 className="h-5 w-5 animate-spin" />
                  </p>
                ) : (
                  <p className="text-sm font-medium">
                    {instantBookings?.data?.total_pages}
                  </p>
                )}
              </Button>
            </div>
          </div>
        )}

        {isLoading ? (
          <Table className="min-w-full bg-white divide-y divide-gray-200">
            <TableHeader className="bg-gray-100">
              <TableRow>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Package to Deliver
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Origin/Destination
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Last Updated
                </TableHead>
                <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="bg-white divide-y divide-gray-200">
              {[...Array(5)].map((_, index) => (
                <TableRow key={index} className="hover:bg-gray-50">
                  <TableCell className="px-6 py-4 whitespace-nowrap">
                    <Skeleton className="h-5 w-20 bg-gray-200 rounded" />
                  </TableCell>
                  <TableCell className="px-6 py-4 whitespace-nowrap">
                    <Skeleton className="h-5 w-60 bg-gray-200 rounded" />
                    <Skeleton className="h-5 w-60 mt-4 bg-gray-200 rounded" />
                  </TableCell>
                  <TableCell className="px-6 py-4 whitespace-nowrap">
                    <Skeleton className="h-5 w-24 bg-gray-200 rounded" />
                  </TableCell>
                  <TableCell className="px-6 py-4 whitespace-nowrap">
                    <Skeleton className="h-5 w-32 bg-gray-200 rounded" />
                  </TableCell>
                  <TableCell className="px-6 py-4 whitespace-nowrap">
                    <Skeleton className="h-5 w-28 bg-gray-200 rounded" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <>
            {instantBookings?.message ===
            "No instant freights found for this carrier" ? (
              <div className="flex flex-col items-center w-full mx-auto space-y-4 justify-center py-3">
                <EmptySvg />

                <p className="text-sm font-medium text-gray-500 mt-4">
                  No Instant Freight found
                </p>
              </div>
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Weight/Dimensions
                    </TableHead>
                    <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Origin/Destination
                    </TableHead>
                    <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Last Updated
                    </TableHead>
                    <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </TableHead>
                    <TableHead className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {instantBookings?.data?.[0].length > 0 &&
                    instantBookings?.data?.[0].map(
                      (freight: InstantFreight) => (
                        <TableRow className="border-b border-gray-200 hover:bg-gray-50 transition-colors">
                          <TableCell className="p-4">
                            <div className="flex items-start space-x-4">
                              <div className="flex flex-col space-y-3">
                                <p className="text-sm font-semibold">
                                  {freight.package_weight} kg
                                </p>
                                <p className="text-sm text-gray-600 capitalize">
                                  {freight.package_size}
                                </p>
                                <p className="text-sm font-medium text-gray-800">
                                  Est. {freight.estimated_price}€
                                </p>
                                {["small", "medium", "large"].includes(
                                  freight.package_size
                                ) && (
                                  <div
                                    onClick={() =>
                                      navigate(
                                        `/instant-bookings/${freight.id}`
                                      )
                                    }
                                    className="bg-gray-100 border border-gray-300 rounded-lg p-2 hover:bg-gray-200 transition cursor-pointer"
                                  >
                                    <img
                                      src={
                                        freight.package_size === "small"
                                          ? SmallBox
                                          : freight.package_size === "medium"
                                          ? MediumBox
                                          : LargeBox
                                      }
                                      className="h-16 w-16 object-contain"
                                      alt={freight.package_size}
                                    />
                                  </div>
                                )}
                              </div>
                              {freight.trip_type === "pet" && (
                                <div className="flex flex-col space-y-3">
                                  <p className="text-sm font-semibold">
                                    {freight.pet_weight} kg
                                  </p>
                                  <p className="text-sm text-gray-600 capitalize">
                                    {freight.pet_type}
                                  </p>
                                  <p className="text-sm font-medium text-gray-800">
                                    {freight.has_cage === 1
                                      ? "Available Cage"
                                      : "Unavailable Cage"}
                                  </p>
                                  <img
                                    onClick={() =>
                                      navigate(
                                        `/instant-bookings/${freight.id}`
                                      )
                                    }
                                    alt="pet"
                                    className="cursor-pointer h-20 w-20 object-cover rounded-lg border border-gray-300"
                                    src={`${BASE_URL_IMG_2}${freight?.pet_img}`}
                                  />
                                </div>
                              )}
                            </div>
                          </TableCell>

                          <TableCell className="p-4">
                            <div className="space-y-2">
                              <div
                                onClick={() =>
                                  navigate(`/instant-bookings/${freight.id}`)
                                }
                                className="bg-gray-100 rounded-lg p-3 border border-gray-300 cursor-pointer hover:bg-gray-200 transition"
                              >
                                <p className="text-xs font-semibold text-gray-600">
                                  Origin
                                </p>
                                <p className="text-sm font-medium text-gray-800 truncate">
                                  {freight.origin_address}
                                </p>
                              </div>
                              <div
                                onClick={() =>
                                  navigate(`/instant-bookings/${freight.id}`)
                                }
                                className="bg-gray-100 rounded-lg p-3 border border-gray-300 cursor-pointer hover:bg-gray-200 transition"
                              >
                                <p className="text-xs font-semibold text-gray-600">
                                  Destination
                                </p>
                                <p className="text-sm font-medium text-gray-800 truncate">
                                  {freight.destination_address}
                                </p>
                              </div>
                              <div className="bg-gray-100 rounded-lg p-3 border border-gray-300">
                                <p className="text-xs font-semibold text-gray-600">
                                  {freight.delivery_distance}
                                </p>
                                <p className="text-xs font-semibold text-gray-600">
                                  {freight.delivery_duration}
                                </p>
                              </div>
                            </div>
                          </TableCell>

                          <TableCell className="p-4 text-gray-600">
                            {freight.updated_at}
                          </TableCell>

                          <TableCell className="p-4">
                            <div
                              className={cn(
                                "rounded-lg px-4 py-2 text-xs font-semibold text-white text-center capitalize",
                                freight.status === "created" && "bg-blue-500",
                                freight.status === "assigned" &&
                                  "bg-yellow-500",
                                freight.status === "paid" && "bg-green-500",
                                freight.status === "picking-up" &&
                                  "bg-orange-500",
                                freight.status === "in-transit" &&
                                  "bg-teal-500",
                                freight.status === "finished" &&
                                  "bg-purple-500",
                                freight.status === "confirmed" &&
                                  "bg-indigo-500",
                                freight.status === "cancelled" && "bg-gray-500"
                              )}
                            >
                              {freight.status}
                            </div>
                          </TableCell>

                          <TableCell className="p-4 space-y-2">
                            {freight.status === "paid" && (
                              <Button
                                onClick={() =>
                                  modalFreightPickingUp.onOpen(
                                    "instantFreightPickingUp",
                                    freight.id.toString()
                                  )
                                }
                                variant="default"
                                className="w-full"
                              >
                                Set to Picking Up
                              </Button>
                            )}
                            {freight.status === "picking-up" && (
                              <Button
                                variant="default"
                                onClick={() =>
                                  modalFreightInTransit.onOpen(
                                    "instantFreightInTransit",
                                    freight.id.toString()
                                  )
                                }
                                className="w-full"
                              >
                                Set to Transit
                              </Button>
                            )}
                            {freight.status === "in-transit" && (
                              <Button
                                onClick={() =>
                                  modalCompelteFreight.onOpen(
                                    "ComplteInstantFreight",
                                    freight.id
                                  )
                                }
                                variant="default"
                                className="w-full"
                              >
                                Complete Freight
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </TableBody>
              </Table>
            )}
          </>
        )}
      </div>
      {modalFreightInTransit.isOpen && (
        <ModalInstantFreightInTransit refetch={refetch} />
      )}
      {modalCompelteFreight.isOpen && (
        <ModalCompleteInstantFreight refetch={refetch} />
      )}
      {modalFreightPickingUp.isOpen && (
        <ModalInstantFreightPickingUp refetch={refetch} />
      )}
    </SidebarLayout>
  );
};

export default CarrierInstantBookings;
