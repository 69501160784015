import { useLocation, useNavigate } from "react-router-dom";

export const FourTab = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="bg-white bg-opacity-100 border rounded-xl shadow-xl  w-[400px] overflow-hidden sm:w-[800px] sm:h-[360px] sm:flex">
      <div className="p-10">
        <p className="text-3xl sm:text-4xl font-extrabold font-paragraph">
          More security, higher{" "}
        </p>
        <p className="text-3xl sm:text-4xl font-extrabold font-paragraph sm:mt-4">
          quality and a city within your
        </p>
        <p className="text-3xl sm:text-4xl font-extrabold font-paragraph sm:mt-4">
          reach
        </p>
        <p className=" text-lg mt-4">
          Became one of our transporters and start earning money!
        </p>
        <button
          // onClick={() => navigate("/register/carrier")}
          onClick={() => navigate("/preregister")}
          className="h-[40px] w-[200px] mt-4 bg-[#ff380f] shadow-lg hover:bg-[#f4775efb] hover:shadow-4xl text-white font-bold rounded-2xl transition duration-300 ease-in-out"
        >
          Distribute with us
        </button>
        <p
          onClick={() => navigate("/contact-us")}
          className="mt-4 text-blue-500 hover:underline cursor-pointer"
        >
          Are you a delivery company?
        </p>
      </div>
    </div>
  );
};
