import { create } from "zustand";
import { StopType } from "../types/types";

export type ModalType = "bookTripStop";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  stop: StopType | null;
  customerId: string;
  onOpen: (type: ModalType, stop: StopType, customerId: string) => void;
  onClose: () => void;
}

export const useBookTripStop = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  stop: null,
  customerId: "",
  onOpen: (type, stop, customerId) =>
    set({ isOpen: true, type, stop, customerId }),
  onClose: () => set({ type: null, isOpen: false }),
}));
