import { useParams } from "react-router-dom";
import SidebarLayout from "../components/layout/SidebarLayout";
import { ALL_B2B_TRIPS, BASE_URL, ORDERS_DETAILS } from "../api/config";
import { useQuery } from "@tanstack/react-query";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { useEffect } from "react";
import { Loader2 } from "lucide-react";
import { ProductOrderTypes } from "../types/types";
import { FaLocationPin } from "react-icons/fa6";
import { Button } from "../components/ui/button";
import { useAssignOrder } from "../hooks/use-assign-order";
import ModalAssignOrder from "../components/modal/ModalAssignEcomOrder";

const SingleEcomOrder = () => {
  const params = useParams();
  const { user_id } = userInfoUserStore((state) => state);
  const token = useTokenStoreStorage((s) => s.token);
  const modalAssignOrder = useAssignOrder();

  const getOrders = async () => {
    const response = await fetch(
      `${BASE_URL}${ORDERS_DETAILS}?user_id=${user_id}&order_id=${params.orderId}`,
      {
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response?.data;
  };
  const {
    data: ordersEcom,
    isLoading: isLoadingOrders,
    refetch: refetchOrders,
  } = useQuery({
    queryKey: ["order_transporter", params.orderId],
    queryFn: getOrders,
  });

  return (
    <SidebarLayout>
      <div className="flex items-center justify-between w-full border-b border-border pb-3 mb-4">
        <p className="text-lg font-semibold ">
          Store Delivery - Order {params.orderId}
        </p>
      </div>
      {isLoadingOrders ? (
        <div className="bg-white shadow h-20 w-full flex items-center justify-center">
          <Loader2 className="animate-spin h-5 w-5" />
        </div>
      ) : (
        <div className="bg-white p-4">
          <div className="flex items-center justify-between">
            <div className="flex flex-col items-start">
              <h4 className="text-gray-500 text-xs">
                Created at {ordersEcom.created_at}
              </h4>
              <h1 className="capitalize text-sm font-semibold">
                {ordersEcom.company}
              </h1>
            </div>
            <div className="flex flex-col items-end space-y-3">
              <p className="text-xs text-gray-400">
                Expires in {ordersEcom.expiration_date}
              </p>
              <div className="bg-green-500 text-white px-3 py-1 rounded">
                <h3>{ordersEcom.price}€</h3>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <h3 className="text-sm font-medium flex items-center space-x-2">
              <span className="text-gray-500 w-5">
                <FaLocationPin className="text-green-500" />
              </span>{" "}
              {ordersEcom.origin}
            </h3>
            <h3 className="text-sm font-medium flex items-center space-x-2">
              <span className="text-gray-500 w-5">
                <FaLocationPin className="text-blue-500" />
              </span>{" "}
              {ordersEcom.destination}
            </h3>
          </div>
          <div className="mt-3 ">
            <h3 className="text-sm font-medium border-b pb-2">Products</h3>
            {ordersEcom.products.map((product: ProductOrderTypes) => (
              <div className="flex items-center justify-between mb-2 border-b py-2 px-4  bg-gray-50 rounded mt-2">
                {" "}
                <div className="flex flex-col items-start">
                  <p className="text-sm font-medium capitalize pb-1">
                    {product.name}
                  </p>
                  <img
                    alt={product.name}
                    src={product.image}
                    className="h-10 w-20"
                  />
                  <p className="text-xs text-gray-500 mt-2">
                    Quantity: {product.quantity}
                  </p>
                </div>
                <div className="relative h-14 w-24 ">
                  <img
                    alt="package-dimensions"
                    src={require("../assets/images/moving-box.png")}
                    className="h-full w-full"
                  />
                  <p className="text-[10px] absolute right-14 top-10 font-medium">
                    {Math.round(Number(product.width))}cm(w)
                  </p>
                  <p className="text-[10px] absolute right-20 top-4 font-medium">
                    {Math.round(Number(product.height))}cm(h)
                  </p>
                  <p className="text-[10px] absolute right-0 top-10 font-medium">
                    {Math.round(Number(product.length))}cm(l)
                  </p>
                </div>
              </div>
            ))}
          </div>
          {ordersEcom.state === 1 && (
            <div className=" w-full flex flex-col justify-end">
              <Button
                onClick={() =>
                  modalAssignOrder.onOpen("assignOrder", params.orderId!, "1")
                }
                variant={"freightGreen"}
                className="w-fit ml-auto"
              >
                Complete Trip
              </Button>
            </div>
          )}
          {ordersEcom.state === 2 && (
            <div className=" w-full flex flex-col justify-end">
              <Button className="w-fit ml-auto" variant={"outline"}>
                Completed
              </Button>
            </div>
          )}
        </div>
      )}
      <ModalAssignOrder refetchOrders={refetchOrders} />
    </SidebarLayout>
  );
};

export default SingleEcomOrder;
