import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useQuery } from "@tanstack/react-query";
import { ChevronLeft, MapPin } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { IoChevronForward } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import {
  BASE_URL,
  GET_ALL_FREIGHT,
  GET_IMAGE,
  GOOGLE_API_KEY,
} from "../api/config";
import Header from "../components/header/Header";
import { Skeleton } from "../components/skeleton/Skeleton";
import { cn } from "../lib/utils";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";

const center = {
  lat: -3.745,
  lng: -38.523,
};

const containerStyle = {
  width: "540px",
  height: "400px",
  borderRadius: "4px",
};

const ViewSearchFreight = () => {
  const { user_id, user_type } = userInfoUserStore((state) => state);
  const [page, setPage] = useState(1);
  const token = useTokenStoreStorage((state) => state.token);
  const params = useParams();
  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [directionResponse, setDirectionsResponse] = useState<any>(null);
  const [distance, setDistance] = useState<any>();
  const [duration, setDuration] = useState<any>();

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });

  const onLoad = useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const getAllFreight = async () => {
    const response = await fetch(
      `${BASE_URL}${GET_ALL_FREIGHT}?user_id=${user_id}&page=${page}&items_per_page=10&sort=DESC&freight_id=${params.id}`,
      {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const { data: allFreight, isLoading } = useQuery({
    queryKey: ["allFreight", params.id],
    queryFn: getAllFreight,
  });
  useEffect(() => {
    if (
      isLoaded &&
      allFreight?.data?.freights[0]?.origin_lat !== "" &&
      allFreight?.data?.freights[0]?.destination_lat !== ""
    ) {
      calculateRoute();
    } else {
      return;
    }
  }, [isLoaded, allFreight]);

  async function calculateRoute() {
    const directionsService = new google.maps.DirectionsService();

    //@ts-ignore
    const results = await directionsService.route({
      origin: {
        lat: Number(allFreight?.data?.freights[0]?.origin_lat),
        lng: Number(allFreight?.data?.freights[0]?.origin_lng),
      },
      destination: {
        lat: Number(allFreight?.data?.freights[0]?.destination_lat),
        lng: Number(allFreight?.data?.freights[0]?.destination_lng),
      },
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance?.text);
    setDuration(results.routes[0].legs[0].duration?.text);
  }

  if (isLoading) {
    return (
      <div className="">
        <Header />
        <div className="max-w-7xl mx-auto p-16">
          <div
            onClick={() => navigate("/myFreight/active")}
            className="flex items-center space-x-2 group cursor-pointer mb-4"
          >
            <ChevronLeft className="text-freightBlueDark h-5 w-5" />
            <h1 className="text-freightBlueDark text-sm group-hover:underline ">
              Back
            </h1>
          </div>
          <div className="flex flex-col space-y-2 my-4">
            <Skeleton className=" h-5 w-40" />
            <Skeleton className=" h-5 w-20" />
          </div>
          <div className="bg-gray-50 border-border border">
            <div className="flex items-start space-x-4 bg-white m-4 p-4 border-border border justify-between">
              <div>
                <Skeleton className="h-5 w-24" />
                <Skeleton className="h-5 w-20 mt-4" />
                <Skeleton className="h-5 w-16 mt-4" />
              </div>
              <div>
                <Skeleton className="h-5 w-40 mt-4" />
                <Skeleton className="h-5 w-32 mt-4" />
                <Skeleton className="h-5 w-60 mt-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Header />
      <div className="max-w-7xl mx-auto p-16">
        <div>
          <div
            onClick={() => navigate("/searchFreight")}
            className="flex items-center space-x-2 group cursor-pointer mb-4"
          >
            <ChevronLeft className="text-freightBlueDark h-5 w-5" />
            <h1 className="text-freightBlueDark text-sm group-hover:underline ">
              Back
            </h1>
          </div>
          <h1 className="text-lg">
            <span className="font-bold text-xl">
              {allFreight?.data?.freights[0]?.title}
            </span>{" "}
            from{" "}
            <span className="font-semibold text-lg">
              {allFreight?.data?.freights[0].origin_address}
            </span>{" "}
            to{" "}
            <span className="font-semibold text-lg">
              {allFreight?.data?.freights[0].destination_address}
            </span>
          </h1>{" "}
          <div className="bg-gray-50 border-border border">
            <div className="flex items-start space-x-4 bg-white m-4 p-4 border-border border justify-between">
              <div className="flex flex-col space-y-2">
                <div className="bg-gray-50  rounded border p-4 flex items-center justify-between">
                  <p className="text-sm font-medium">Number of Quotes</p>{" "}
                  <p className="text-sm font-medium text-gray-500 pl-2">
                    {allFreight?.data?.freights[0]?.quotes}
                  </p>
                </div>
                <div className="bg-gray-50  rounded border p-4 flex items-center justify-between">
                  <p className="text-sm font-medium">Questions</p>{" "}
                  <p className="text-sm font-medium text-gray-500 pl-2">
                    {allFreight?.data?.freights[0]?.questions}
                  </p>
                </div>
                <div className="bg-gray-50  rounded border p-4 flex items-center justify-between">
                  <p className="font-medium text-sm">Views</p>{" "}
                  <p className="text-sm font-medium text-gray-500 pl-2">
                    {allFreight?.data.freights[0]?.views}
                  </p>
                </div>
              </div>
              <div className="flex flex-col space-x-2">
                <div className="flex flex-col space-y-2">
                  <div className="bg-gray-50  rounded border p-4 flex items-center space-x-4">
                    <p className="text-sm font-medium">Status:</p>
                    <p
                      className={cn(
                        "bg-gray-400 text-white px-2 rounded uppercase ml-4 text-sm font-medium",
                        allFreight?.data?.freights[0]?.status === "active" &&
                          "bg-green-500",
                        allFreight?.data?.freights[0]?.status === "booked" &&
                          "bg-freightBlueDark"
                      )}
                    >
                      {allFreight?.data?.freights[0]?.status}
                    </p>
                  </div>
                  <div className="bg-gray-50  rounded border p-4 flex items-center space-x-4">
                    <p className="text-sm font-medium">Date Posted:</p>
                    <p className="text-sm font-medium text-gray-500">
                      {new Date(
                        allFreight?.data?.freights[0]?.created_at
                      ).toLocaleString()}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="bg-gray-50  rounded border p-4 flex items-center space-x-4">
                    <p className="text-sm font-medium">Expires:</p>
                    <p className="text-sm font-medium text-gray-500">
                      {new Date(
                        allFreight?.data?.freights[0]?.expire_date
                      ).toLocaleString()}
                    </p>
                  </div>
                  {/* {allFreight?.data?.freights[0]?.customer_name !== null && (
                    <div className="bg-gray-50  rounded border p-4 flex items-center space-x-4">
                      <p className="text-sm font-medium">Customer:</p>{" "}
                      <p className="text-sm font-medium text-gray-500">
                        {allFreight?.data?.freights[0]?.customer_name}
                      </p>
                    </div>
                  )} */}
                </div>
              </div>

              {/* {user_type === "carrier" &&
                quoteList?.data?.filter(
                  (quote: QuoteDetailType) => quote.status === "accepted"
                ).length === 0 && (
                  <div>
                    <Button
                      onClick={() =>
                        navigate(
                          `/freight/quoteFreight/${allFreight?.data?.freights[0]?.id}`
                        )
                      }
                      variant={"freightBlue"}
                    >
                      Provide Quote to move this Freight
                    </Button>
                  </div>
                )} */}
            </div>
            <div className="grid grid-cols-2 w-full p-4 ">
              <div className="p-4 col-span-1 bg-white border border-border">
                <div className="bg-gray-50  rounded border p-4 flex items-center justify-between">
                  <h1 className="font-medium">Freight Description:</h1>

                  <p className="text-sm font-medium text-gray-500">
                    {allFreight?.data?.freights[0]?.description}
                  </p>
                </div>
                <div className="bg-gray-50  rounded border p-4 flex items-center justify-between">
                  <h1 className="font-medium">Pickup Date Range</h1>
                  <div className="flex items-center space-x-2">
                    <p className="text-sm font-medium text-gray-500">
                      {
                        new Date(allFreight?.data?.freights[0]?.start_date)
                          .toLocaleString()
                          .split(",")[0]
                      }
                    </p>
                    <IoChevronForward className="h-5 w-5 text-gray-500" />
                    <p className="text-sm font-medium text-gray-500">
                      {
                        new Date(allFreight?.data?.freights[0]?.end_date)
                          .toLocaleString()
                          .split(",")[0]
                      }
                    </p>
                  </div>
                </div>
                <div className="bg-gray-50  rounded border p-4 flex items-center justify-between">
                  <h1 className="font-medium">Freight Category</h1>

                  <p className="text-sm font-medium text-gray-500">
                    {allFreight?.data?.freights[0]?.subcategory_name}
                  </p>
                </div>
                {/* <div className="flex flex-col items-start mb-5 mt-4">
                  <div className="flex items-center space-x-2 mb-5 bg-white rounded  px-4 py-2 shadow">
                    <ArrowDown className="h-4 w-4" />
                    <h3 className="font-medium text-xs">Description</h3>
                  </div>
                  <h3 className="text-sm">
                    {allFreight?.data?.freights[0]?.description}
                  </h3>
                </div> */}
                {/* <div className="flex flex-col items-start">
                  <div className="flex items-center space-x-2 mb-5 bg-white rounded  px-4 py-2 shadow">
                    <ArrowDown className="h-4 w-4" />
                    <h3
                      className="font-medium  text-sm
                  "
                    >
                      Items
                    </h3>
                  </div>
                  <div>
                    {allFreight?.data?.freights[0]?.items?.map(
                      (item: {
                        catid: string;
                        created_at: string;
                        freightid: number;
                        id: number;
                        item: {
                          Make: string;
                          Model: string;
                          Year: string;
                          drivable: string;
                          modifications: string;
                          undrivable_details: string;
                        }[];
                      }) => (
                        <>
                          {item.item?.map((item) => (
                            <div className=" rounded p-4 bg-white border border-border flex flex-col space-y-2 w-full">
                              <div className="flex items-center justify-between">
                                <h1 className="text-sm font-medium">Make:</h1>
                                <p className="text-sm font-medium text-gray-500">
                                  {item.Make}
                                </p>
                              </div>
                              <div className="flex items-center justify-between">
                                <h1 className="text-sm font-medium">Model:</h1>
                                <p className="text-sm font-medium text-gray-500">
                                  {item.Model}
                                </p>
                              </div>
                              <div className="flex items-center justify-between">
                                <h1 className="text-sm font-medium">Year:</h1>
                                <p className="text-sm font-medium text-gray-500">
                                  {item.Year}
                                </p>
                              </div>
                              <div className="flex items-center justify-between">
                                <h1 className="text-sm font-medium">
                                  Drivable:
                                </h1>
                                <p className="text-sm font-medium text-gray-500">
                                  {item.drivable}
                                </p>
                              </div>
                              <div className="flex items-center justify-between">
                                <h1 className="text-sm font-medium">
                                  Modifications:
                                </h1>
                                <p className="text-sm font-medium text-gray-500">
                                  {item.modifications}
                                </p>
                              </div>
                              <div className="flex items-center justify-between">
                                <h1 className="text-sm font-medium">
                                  Undrivable Details:
                                </h1>
                                <p className="text-sm font-medium text-gray-500">
                                  {item.undrivable_details}
                                </p>
                              </div>
                            </div>
                          ))}
                        </>
                      )
                    )}
                  </div>
                </div> */}
                <div className="mt-4">
                  <h1 className="text-sm mb-2 font-medium">
                    Freight Image Gallery
                  </h1>
                  {allFreight?.data?.freights[0]?.image?.length === 0 && (
                    <div>
                      <p className="text-sm font-medium text-gray-400">
                        No Images
                      </p>
                    </div>
                  )}
                  <div className="flex items-center space-x-2">
                    {allFreight?.data?.freights[0]?.freight_images?.map(
                      (freightImage: { freight_img: string }) => (
                        <div className="">
                          <img
                            alt=""
                            src={`${BASE_URL}${GET_IMAGE}?user_id=${user_id}&file_name=${freightImage}&image_type=freight`}
                            className=" w-40 rounded object-contain"
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-1 bg-white">
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{
                      lat: Number(allFreight?.data?.freights[0]?.origin_lat),
                      lng: Number(allFreight?.data?.freights[0]?.origin_lng),
                    }}
                    options={{
                      streetViewControl: false,
                    }}
                    zoom={15}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                  >
                    <Marker
                      position={{
                        lat: Number(allFreight?.data?.freights[0]?.origin_lat),
                        lng: Number(allFreight?.data?.freights[0]?.origin_lng),
                      }}
                    />
                    <Marker
                      position={{
                        lat: Number(
                          allFreight?.data?.freights[0]?.destination_lat
                        ),
                        lng: Number(
                          allFreight?.data?.freights[0]?.destination_lng
                        ),
                      }}
                    />
                    {directionResponse && (
                      <DirectionsRenderer directions={directionResponse} />
                    )}
                  </GoogleMap>
                ) : null}
                <div className="flex flex-col items-start bg-white  py-4 px-4 w-full space-y-2">
                  <div className="flex items-center justify-between  w-full ">
                    <h1>Driving Distance: </h1>
                    <div className="bg-green-500 text-white px-4 py-1 rounded">
                      <p className="text-sm font-medium">{distance}</p>
                    </div>
                  </div>
                  <div className="flex items-center justify-between  w-full ">
                    <h1>Approximate driving time: </h1>
                    <div className="bg-freightBlueDark text-white px-4 rounded py-1">
                      <p className="text-sm font-medium">{duration}</p>
                    </div>
                  </div>
                </div>

                <div className="sm:flex flex-col items-start bg-white  rounded-t   py-4 px-4 w-full space-y-2">
                  <div className="flex items-start justify-between py-2 px-2">
                    <div className="flex items-center space-x-2 w-52">
                      <MapPin className="h-6 w-6 text-green-500" />
                      <h1 className="font-medium text-sm">Pickup Location</h1>
                    </div>
                    <div className="flex flex-col ">
                      <h1 className="text-sm font-semibold">
                        {allFreight?.data?.freights[0].origin_address}
                      </h1>
                      <p className="text-[#333] text-sm font-medium mt-2">
                        Location:{" "}
                        {allFreight?.data?.freights[0].origin_location_type}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start justify-between py-2 px-2">
                    <div className="flex items-center space-x-2 w-52">
                      <MapPin className="h-6 w-6 text-red-500" />
                      <h1 className="font-medium text-sm">Dropoff Location</h1>
                    </div>
                    <div className="flex flex-col">
                      <h1 className="text-sm font-semibold">
                        {allFreight?.data?.freights[0].destination_address}
                      </h1>
                      <p className="text-[#333] text-sm font-medium mt-2">
                        Location:{" "}
                        {
                          allFreight?.data?.freights[0]
                            .destination_location_type
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSearchFreight;
