import { useForm } from "react-hook-form";
import CustomerSidebar from "../components/sidebar/CustomerSidebar";
import Sidebar from "../components/sidebar/Sidebar";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { BASE_URL, CHANGE_PASSWORD } from "../api/config";
import { useTokenStoreStorage } from "../store/useTokenStore";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../components/ui/form";
import { Input } from "../components/ui/input";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Button } from "../components/ui/button";
import Header from "../components/header/Header";
import toast from "react-hot-toast";
import Footer from "../components/layout/Footer";
import SidebarLayout from "../components/layout/SidebarLayout";
import { Loader2 } from "lucide-react";

const changePasswordFormSchema = z.object({
  oldPassword: z.string().min(1),
  newPassword: z.string().min(1),
  confirmPassword: z.string().min(1),
});

const ChangePassword = () => {
  const { email, name, user_id, user_type } = userInfoUserStore(
    (state) => state
  );
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const token = useTokenStoreStorage((s) => s.token);
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);

  const form = useForm<z.infer<typeof changePasswordFormSchema>>({
    resolver: zodResolver(changePasswordFormSchema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof changePasswordFormSchema>) => {
    setLoadingChangePassword(true);
    const response = await fetch(
      `${BASE_URL}${CHANGE_PASSWORD}?user_id=${user_id}&oldPassword=${values.oldPassword}&newPassword=${values.newPassword}&confirmNewPassword=${values.confirmPassword}`,
      {
        method: "POST",
        headers: {
          AccessToken: token,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Password updated successfully.") {
          toast.success(data.message);
          form.reset({
            oldPassword: "",
            confirmPassword: "",
            newPassword: "",
          });
          // navigation.goBack();
        }
      })
      .finally(() => {
        setLoadingChangePassword(false);
      });

    return response;
  };

  return (
    <SidebarLayout>
      {/* <Header />
      <div className="grid grid-cols-7 mt-20 px-10">
        <div className="col-span-2">
          {user_type === "customer" ? <CustomerSidebar /> : <Sidebar />}
        </div>
        <div className="col-span-5"> */}
      <div className="flex items-center justify-between w-full border-border border-b pb-3 space-x-2 mb-4">
        <h1 className="text-lg font-semibold ">Change Password</h1>
      </div>
      <div className=" bg-white shadow p-4">
        <div className="flex flex-col mt-10">
          <Form {...form}>
            <form
              className="w-full flex flex-col"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <div className="w-full mb-4 relative">
                <FormField
                  control={form.control}
                  name="oldPassword"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm -mb-1  dark:text-black">
                        Old Password *
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          name="password"
                          type={!showOldPassword ? "password" : "text"}
                          className="w-full dark:bg-transparent"
                        />
                      </FormControl>
                      <div className="absolute top-9 right-2">
                        {showOldPassword ? (
                          <div
                            onClick={() => setShowOldPassword(false)}
                            className=""
                          >
                            <FaEyeSlash className="h-4 w-4 cursor-pointer" />
                          </div>
                        ) : (
                          <div onClick={() => setShowOldPassword(true)}>
                            <FaEye className="h-4 w-4 cursor-pointer" />
                          </div>
                        )}
                      </div>
                    </FormItem>
                  )}
                />
              </div>
              <div className="w-full mb-4 relative">
                <FormField
                  control={form.control}
                  name="newPassword"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm -mb-1  dark:text-black">
                        New Password *
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          name="password"
                          type={!showNewPassword ? "password" : "text"}
                          className="w-full dark:bg-transparent"
                        />
                      </FormControl>
                      <div className="absolute top-9 right-2">
                        {showNewPassword ? (
                          <div
                            onClick={() => setShowNewPassword(false)}
                            className=""
                          >
                            <FaEyeSlash className="h-4 w-4 cursor-pointer" />
                          </div>
                        ) : (
                          <div onClick={() => setShowNewPassword(true)}>
                            <FaEye className="h-4 w-4 cursor-pointer" />
                          </div>
                        )}
                      </div>
                    </FormItem>
                  )}
                />
              </div>
              <div className="w-full mb-4 relative">
                <FormField
                  control={form.control}
                  name="confirmPassword"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm -mb-1 dark:text-black">
                        Confirm Password *
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          name="password"
                          type={!showConfirmPassword ? "password" : "text"}
                          className="w-full dark:bg-transparent"
                        />
                      </FormControl>
                      <div className="absolute top-9 right-2">
                        {showConfirmPassword ? (
                          <div
                            onClick={() => setShowConfirmPassword(false)}
                            className=""
                          >
                            <FaEyeSlash className="h-4 w-4 cursor-pointer" />
                          </div>
                        ) : (
                          <div onClick={() => setShowConfirmPassword(true)}>
                            <FaEye className="h-4 w-4 cursor-pointer" />
                          </div>
                        )}
                      </div>
                    </FormItem>
                  )}
                />
              </div>
              <div className="justify-end ml-auto">
                <Button variant={"freightBlue"}>
                  {loadingChangePassword ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                    </>
                  ) : (
                    <p>Change Password</p>
                  )}
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </SidebarLayout>
  );
};

export default ChangePassword;
