import { OrdersEcom } from "@/src/types/types";
import { TableCell, TableRow } from "../ui/table";
import { useViewItemsOrder } from "src/hooks/use-view-items-order";
import { useNavigate } from "react-router-dom";
import { Eye } from "lucide-react";

interface CarrierB2BTripCardProps {
  order: OrdersEcom;
}
const CarrierB2BTripCard: React.FC<CarrierB2BTripCardProps> = ({ order }) => {
  const modalViewItemsOrder = useViewItemsOrder();
  const navigate = useNavigate();
  return (
    <TableRow className="bg-white border-border border">
      <TableCell>
        <p
          onClick={() => navigate(`/store-order/${order.id}`)}
          className="hover:underline cursor-pointer"
        >
          {order.id}/ {order.company}
        </p>
      </TableCell>
      <TableCell>
        {order.origin}/{order.destination}
      </TableCell>
      <TableCell>{order.created_at}</TableCell>
      <TableCell>
        <div className="relative ">
          <div className="flex items-center justify-between">
            {order.products.length}{" "}
            <p
              onClick={() =>
                modalViewItemsOrder.onOpen("viewItemsOrder", order)
              }
              className="text-sm hover:underline hover:text-blue-500 cursor-pointer"
            >
              <Eye className="h-5 w-5" />
            </p>
          </div>
        </div>
      </TableCell>
      <TableCell>{order.price}</TableCell>
      <TableCell>
        {order.state === 1 && (
          <div className="bg-green-500 text-white px-4 text-center rounded py-1">
            Assigned
          </div>
        )}
        {order.state === 2 && (
          <div className="bg-freightBlue text-white px-4 text-center rounded py-1">
            Completed
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

export default CarrierB2BTripCard;
