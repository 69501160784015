import { create } from "zustand";
import { QuoteDetailType } from "../types/types";

export type ModalType = "acceptQuote";

interface ModalStore {
  type: ModalType | null;
  isOpen: boolean;
  data: QuoteDetailType | null;
  onOpen: (type: ModalType, data: QuoteDetailType) => void;
  onClose: () => void;
}

export const useAcceptQuote = create<ModalStore>((set) => ({
  type: null,
  isOpen: false,
  data: null,
  onOpen: (type, data) => set({ isOpen: true, type, data }),
  onClose: () => set({ type: null, isOpen: false }),
}));
