import { useQuery } from "@tanstack/react-query";
import { BASE_URL, GET_REVIEW } from "../api/config";
import Header from "../components/header/Header";
import CustomerSidebar from "../components/sidebar/CustomerSidebar";
import Sidebar from "../components/sidebar/Sidebar";
import { useTokenStoreStorage } from "../store/useTokenStore";
import { userInfoUserStore } from "../store/useUserInfoStore";
import { useEffect } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { Checkbox } from "../components/ui/checkbox";
import Footer from "../components/layout/Footer";
import SidebarLayout from "../components/layout/SidebarLayout";

const Feedback = () => {
  const token = useTokenStoreStorage((state) => state.token);
  const { email, name, user_id, user_type } = userInfoUserStore(
    (state) => state
  );

  const getFeedback = async () => {
    const response = await fetch(`${BASE_URL}${GET_REVIEW}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AccessToken: token,
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    }).then((res) => res.json());

    return response;
  };

  const { data: feedback } = useQuery({
    queryKey: ["feedback_account"],
    queryFn: getFeedback,
  });

  return (
    <SidebarLayout>
      {/* <Header />
      <div className="grid grid-cols-7 mt-20 px-10">
        <div className="col-span-2">
          {user_type === "customer" ? <CustomerSidebar /> : <Sidebar />}
        </div>
        <div className="col-span-5"> */}
      <div className="max-w-4xl mr-auto">
        <h1 className="text-sm font-semibold border-b-2 pb-3">Feedback List</h1>
      </div>
    </SidebarLayout>
  );
};

export default Feedback;
